import React from 'react'

const Firewallinstanceform = ({setHsModal}) => {
  return (
    <div>
        <form className='px-4 pt-4'>
        <label className='fs-16 font-w600 pt-1 text-dark-0'>Instances</label>
        <div className='fs-14 py-1 text-dark-0'>You can assign one or more Instances to this Firewall.Each Instances can only be assigned to a single Firewall.</div>
        <select className='w-100 form-control text-black-50'>
          <option>Select a instance or type of Search...</option>
        </select>
        <div className='justify-content-end d-flex pt-4 firewall_btns'>
          <div>
          <button className="border-0 bg-white font-w500 text-blue fs-16 px-5 pt-2">Cancel</button>
          </div>
          <div>
          <button className='border-0 btn-hslineargradient p-2 px-4 rounded-0' onClick={() => setHsModal(false)}>Add</button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Firewallinstanceform