import { SEVERNAME } from "./API_ENDPOINTS";

export function getPaymentCheckStatusURL(orderid,userid){
    // return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/checkpaymentstatus/reforderid/${orderid}/contactid/${userid}`;
     return `${SEVERNAME}/apis/v4/bizgaze/integrations/hyperfusionpaymentgateway/gethyperfusionpaymentdetails/orderid/${orderid}`;
}

export function postCheckPaymentStatusURL(orderid,userid,isTrue){
    // return `${SEVERNAME}/apis/v4/bizgaze/hyperfusion/servers/checkpaymentstatus/reforderid/${reforderid}/contactid/${userid}`;
    return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/checkpaymentstatus/reforderid/${orderid}/contactid/${userid}/isrenewal/${isTrue}`;

  
}

export function getPaymentWalletAmountURL(contactid){
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getwalletbalance/contactid/${contactid}`;
}

export function getpaymentInfoURL(userid){
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getbillinginfo/contactid/${userid}`;
}

export function transactionWalletURL(contactid){
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getwallettransactions/contactid/${contactid}`;
}

export function postPaymentAddToWalletURL(){
    return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/generatepaymentlink`;
}

export function postPaymentAddToWalletTriggerURL(){
    return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/addamounttowallet`;
}

export function confirmWalletAddedURL(){
    return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/addamounttowallet`;
}