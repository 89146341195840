class ErrorClass{
    static dispatch;
    static history;
    constructor(){
       
    }

    static setDispatch(dispatch){
        this.dispatch = dispatch
    }

    static setHistory(history){
        this.history = history;
    }

}

export default ErrorClass;