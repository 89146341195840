import React from 'react'
import SpacerHS_TWO from '../../Spaces/SpacerHS_TWO';
import CustomTableItem from './CustomTableItem';
import SearchInputCustomTable from './SearchInputCustomTable/SearchInputCustomTable';

function CustomTable({ data }) {
    React.useEffect(function () {
        // console.log(data)
    }, [data]);
    return (
        <div>
            <SearchInputCustomTable data={data} />
            {/* <SpacerHS_TWO /> */}
            <div>
                <table className='w-100 customTable'>
                    <tr>
                       
                        <th>
                            Machine Name
                        </th>
                        <th className='text-center'>
                            OS
                        </th>
                        <th className='text-center'>
                            Location
                        </th>
                        <th className='text-center'>
                            Charges
                        </th>
                        <th className='text-center'>
                            Console
                        </th>
                        <th className='text-center'>
                            Status
                        </th>
                        <th>

                        </th>
                    </tr>

                    {data.map(function (val, i) {
                        return <tr className='mb-4'>
                            <CustomTableItem itemDataCurr={val} />

                        </tr>
                    })}

                </table>
            </div>
        </div>
    )
}

export default CustomTable