import React from 'react'
import InputLabelUp from '../../../../hireserver/controls/InputLabelUp/InputLabelUp';
import DropDownWithImgGroup from '../../../../hireserver/components/dropdowns/DropDownWithImgGroup';
import SelectOptionHS from '../../../../hireserver/controls/SelectOptionHS';
import DropDownSearch from '../../../../hireserver/controls/DropDownSearch';
import { connect } from 'react-redux';
import CurrencyFC from '../../../../hireserver/components/Currency/CurrencyFC';
import Dropdownselectimg from '../../../../hireserver/controls/dropdownsearch/Dropdownselectimg';
import JustCurrencyFC from '../../../../hireserver/components/Currency/JustCurrencyFC';
import FormInputError from '../../../../hireserver/components/FormInputError';
import { postServeravailAbilityClustersService } from '../../../../../../services/ClusterServices/ClusterServices';
import { cloneDeep } from 'lodash';
import { extractNumberAfterServerId } from '../../../../../../utils/Helpers';

function ClusterItem({ nodename, locationData, parentLocation, allLocations, selectLocation, alertReplicaModalOpen, setIndividualError, fullData, amountofnode, price, id, handler, blockscount, deleteHandler, currencyname, errors, specs, parentCore ,removeIndividualError}) {

    const [nodenameState, setNodeName] = React.useState('');
    const [node, setNode] = React.useState(1);

  


    function nameChangerhandler(e) {
        setNodeName(e.target.value)
        handler(id, e.target.value, 'nodename');

    }

    async function checkAvalServer(data, itemData, callBack) {
        let payload = {
            "blockid": specs?.blockid,
            "branchid": parentLocation,
            "numberofblocks": parentCore,
            "ReplicaNodes": []
        }


        let ReplicaNodes = [];
        let newFullData = { ...data }
        for (let item in newFullData) {
            let curr = newFullData[item];
            if (curr.selectLocation == null) {
              //  alert("sachin");
                ReplicaNodes.push({
                    "branchid": 0,
                    "numberofblocks": parentCore,
                    "count": node
                });
            } else {
                debugger;
                ReplicaNodes.push({
                    "branchid": curr.selectLocation.value,
                    "numberofblocks": parentCore,
                    "count": curr.nodevalues.num
                })
            }
        }

        ReplicaNodes.unshift({
            "branchid": parentLocation,
            "numberofblocks": parentCore,
            "count": 1
        })


        payload["ReplicaNodes"] = ReplicaNodes;

        console.log("payload", payload);

        const res = await postServeravailAbilityClustersService(payload);

        if (res.data.code != '0') {
            if (res.data.message.includes("Server not available at location")) {
                const serverId = extractNumberAfterServerId(res.data.message);
                console.log(serverId);

                setIndividualError(id, 'selectLocation', "Server not available at this location")
            }
        } else {


            callBack();
        }

        console.log("resfull", res);
    }

    async function locationHandler(e) {

        function callBack() {
            handler(id, e, 'selectLocation');
        }
debugger;
let newData = cloneDeep(fullData);
       if( newData[id]['selectLocation']?.value  == e.value){
       
        return;
       }


       
        newData[id]['selectLocation'] = e;
        debugger;
        for (let data in fullData) {
            let curr = fullData[data];
            if (curr.selectLocation && curr.selectLocation.value == e.value) {

                alertReplicaModalOpen();
                return;
            }

        }

        await checkAvalServer(newData, e, callBack)
    }

    function setLocation() {

        // if(!locationData?.length) return '';
        return <Dropdownselectimg selectClasses="font-12" placeholder="Select Location" parentClass="p-0" value={selectLocation} setValue={locationHandler} data={locationData} />

    }

    // React.useEffect(function (){
    //     debugger;
    //     let pricePayload = {
    //         num:node,
    //         price:(node) * (price?.price)
    //     }
    //     handler(id,pricePayload,'nodevalues');
    // },[price.price]);

    function increaseBtn(e) {


        if (node >= 3) return;
        let newData = cloneDeep(fullData);
        newData[id]['nodevalues'] = {
            num: node + 1,
            price: (node + 1) * (price?.price)
        }
        checkServerLocations(newData);

        handler(id, {
            num: node + 1,
            price: (node + 1) * (price?.price)
        }, 'nodevalues');
        setNode(node + 1);
    }

    function decreaseBtn(e) {
        // handler(id,e,'nodevalues');
        if (node <= 1) return;
        let newData = cloneDeep(fullData);
        newData[id]['nodevalues'] = {
            num: node - 1,
            price: (node - 1) * (price?.price)
        }
        checkServerLocations(newData);

        handler(id, {
            num: node - 1,
            price: (node - 1) * (price?.price)
        }, 'nodevalues');
        setNode(node - 1);
    }

    async function checkServerLocations(data,callBack) {
        let payload = {
            "blockid": specs?.blockid,
            "branchid": parentLocation,
            "numberofblocks": parentCore,
            "ReplicaNodes": []
        }


        let ReplicaNodes = [];
        let newFullData = { ...data }
        for (let item in newFullData) {
            let curr = newFullData[item];
            if (curr.selectLocation == null) {
               // alert("sachin");
                ReplicaNodes.push({
                    "branchid": 0,
                    "numberofblocks": parentCore,
                    "count": node
                });
            } else {
                debugger;
                ReplicaNodes.push({
                    "branchid": curr.selectLocation.value,
                    "numberofblocks": parentCore,
                    "count":curr.nodevalues.num
                    //curr.nodevalues.num
                })
            }
        }

        ReplicaNodes.unshift({
            "branchid": parentLocation,
            "numberofblocks": parentCore,
            "count": 1
        })


        payload["ReplicaNodes"] = ReplicaNodes;

        console.log("payload", payload);

        const res = await postServeravailAbilityClustersService(payload);
        console.log(res,"alright alright alright");

        if(res.data.code != '0'){
            if (res.data.message.includes("Server not available at location")) {
                const serverId = extractNumberAfterServerId(res.data.message);
                console.log(serverId);

                setIndividualError(id, 'selectLocation', `Servers not available for selected nodes.`)
            }
        }else{
            if(newFullData[id]['errors']['selectLocation'].isError){
                removeIndividualError(id,'selectLocation');
            }
           
        }
    }


    async function locationClick(data) {


        let payload = {
            "blockid": specs?.blockid,
            "branchid": parentLocation,
            "numberofblocks": parentCore,
            // "ReplicaNodes": [{
            //     "branchid": parentLocation,
            //     "numberofblocks": parentCore,
            //     "count": 1
            // },{
            //     "branchid": 0,
            //     "numberofblocks": parentCore,
            //     "count": 1
            // }]
        }

        let ReplicaNodes = [];
        let newFullData = { ...data }
        for (let item in newFullData) {
            let curr = newFullData[item];
            if (curr.selectLocation == null) {
                ReplicaNodes.push({
                    "branchid": 0,
                    "numberofblocks": parentCore,
                    "count": node
                });
            } else {
                ReplicaNodes.push({
                    "branchid": curr.selectLocation.value,
                    "numberofblocks": parentCore,
                    "count": curr.nodevalues.num
                })
            }
        }

        ReplicaNodes.unshift({
            "branchid": parentLocation,
            "numberofblocks": parentCore,
            "count": node
        })

        payload['ReplicaNodes'] = ReplicaNodes;

        const res = await postServeravailAbilityClustersService(payload);
        if (res.data.result.includes('Server available')) {


            return;
        }
        let jsonRes = JSON.parse(res.data.result);
        jsonRes = JSON.parse(jsonRes);
        jsonRes = JSON.parse(jsonRes);
        console.log("all location", allLocations);
        console.log(jsonRes);
        console.log("parentLocation", parentLocation);
        console.log(node, specs);

        let newArrLocation = {};

        for (let i = 0; i < allLocations.length; i++) {

            for (let j = 0; j < jsonRes.length; j++) {

                let isBreak = false;
                if (jsonRes[j].branchid == allLocations[i].branchid) {
                    let curr = allLocations[i];
                    curr.isNeedShow = true;
                    let obj = {
                        label: curr.branchname,
                        value: curr.branchid,
                        img: curr.icon,
                        isDisabled: !curr.isNeedShow
                    }
                    newArrLocation[curr.branchid] = obj;

                }

                if (isBreak) break;

            }

        }

        handler(id, newArrLocation, "locationData");


    }

    return (
        <div className="row pb-2">
            <div className="col-sm-3">
                <InputLabelUp placeholder="Node Name" setValue={nameChangerhandler} value={nodenameState} />
                {errors.nodename.isError ? <FormInputError message={errors.nodename.message} /> : null}

            </div>
            <div className="col-sm-3 " onClick={() => locationClick(fullData)}>

                {setLocation()}

                {errors.selectLocation.isError ? <FormInputError message={errors.selectLocation.message} /> : null}
            </div>

            <div className='align-items-center col-sm-1 d-flex justify-content-center text-center  form-control hs_borderRadius-4'>
                {blockscount}
            </div>
            <div className="col-sm-2">
                <div className=" ">
                    <div className=" text-center d-flex justify-content-around  form-control hs_borderRadius-4   align-items-center">
                        <div onClick={decreaseBtn} className=" border-right">-</div>
                        <div className="">{node}</div>
                        <div onClick={increaseBtn} className=" border-left">+</div>
                    </div>
                </div>
                {errors.amountofnode.isError ? <FormInputError message={errors.amountofnode.message} /> : null}
            </div>

            <div className="col-sm-2 text-center">
                <CurrencyFC name={currencyname} val={node * (price?.price)} />
                <div className='font-10 text-body'> Blocks (<JustCurrencyFC name={currencyname} />  {price?.price}) *  Node({node})  </div>
            </div>
            <div onClick={() => deleteHandler(id)} className='col-sm-1 text-right'>
                <span><i className='fa fa-trash text-danger'></i></span>
            </div>
        </div>
    )
}


const mapStatesToProps = ({ auth, PaymentInfoReducer }) => {
    return {

        currencyname: PaymentInfoReducer?.paymentData?.currencyname,
        paymentData: PaymentInfoReducer?.paymentData,
    };
};

export default connect(mapStatesToProps)(React.memo(ClusterItem));