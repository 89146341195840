import React from "react";

export default function OutputView({ output, currScriptLogId, setOutputpage, outputPage,setOutput }) {
    const [outputView, setOutputView] = React.useState([]);

  
    React.useEffect(function () {
      console.log(output,"ouuttttttttt");
      setOutputView([output])


    }, [output]);
  
    React.useEffect(function (){
  
      return ()=>{
        setOutputView(null);
        setOutput(null)
        console.log("hello");
      }
    },[]);
  

  
    function redirectToUrl(url){
      debugger;
      window.open(url, '_blank');

    }
  
  
    return (
      <div>

        <div className='w-100 text-break border  areaScriptModalMax' >
  
          {outputView ? <div className='font-12' style={{ whiteSpace: "pre-wrap" }}>{
            outputView?.map(function (item, index) {
              debugger;
              return <React.Fragment key={index}>
               <span onClick={()=>redirectToUrl(item)}><u>{item}</u></span>
              </React.Fragment>;
            })
  
          }</div> : <div className="spinner-border text-light" role="status"></div>}
  
  
  
        </div>
  
      </div>
    )
  }
  