export const GET_BAREMETAL = 'GET_BAREMETAL';
export const SET_BAREMETAL = 'SET_BAREMETAL';
export const VM_ACTION_TYPE_BAREMETAL = 'VM_ACTION_TYPE';
export const START_BAREMETAL_LOADING = 'START_BAREMETAL_LOADING';
export const FINISH_BAREMETAL_LOADING = 'FINISH_BAREMETAL_LOADING';
export const UPDATE_BAREMETAL_ITEM = 'UPDATE_BAREMETAL_ITEM';
export const SHOW_DELETE_CONFIRM_MODAL_BAREMETAL = 'SHOW_DELETE_CONFIRM_MODAL_BAREMETAL';
export const DELETE_CONFIRM_MODAL_BAREMETAL = 'DELETE_CONFIRM_MODAL_BAREMETAL';
export const PUT_CURRENT_ITEM_DELETE_BAREMETAL = 'PUT_CURRENT_ITEM_DELETE_BAREMETAL';
export const SELECT_CURRENT_LIST_ITEM_BAREMETAL = 'SELECT_CURRENT_LIST_ITEM_BAREMETAL';
export const SELECT_ALL_LIST_ITEM_BAREMETAL = 'SELECT_ALL_LIST_ITEM_BAREMETAL';
export const UN_SELECT_CURRENT_LIST_ITEM_BAREMETAL = 'UN_SELECT_CURRENT_LIST_ITEM_BAREMETAL';
export const UN_SELECT_ALL_LIST_ITEM_BAREMETAL = 'UN_SELECT_ALL_LIST_ITEM_BAREMETAL';
export const CLOSE_STOP_HS_MODAL_BAREMETAL = 'CLOSE_STOP_HS_MODAL_BAREMETAL';
export const OPEN_STOP_HS_MODAL_BAREMETAL = 'OPEN_STOP_HS_MODAL_BAREMETAL';
export const CURR_SELECT_ITEM_BAREMETAL = 'CURR_SELECT_ITEM_BAREMETAL';
export const CLEAR_CURR_SELECT_ITEM_BAREMETAL = 'CLEAR_CURR_SELECT_ITEM_BAREMETAL';
export const REBOOT_HS_SHOWMODAL_BAREMETAL = 'REBOOT_HS_SHOWMODAL_BAREMETAL';
export const REBOOT_HS_HIDEMODAL_BAREMETAL = 'REBOOT_HS_HIDEMODAL_BAREMETAL';


export const STOP_ALL_MODAL_START_BAREMETAL = 'STOP_ALL_MODAL_START_BAREMETAL';
export const STOP_ALL_MODAL_FINISH_BAREMETAL = 'STOP_ALL_MODAL_FINISH_BAREMETAL';

export const SHOW_DELETE_ALL_MACHINES_MODAL_BAREMETAL = 'SHOW_DELETE_ALL_MACHINES_MODAL_BAREMETAL';
export const HIDE_DELETE_ALL_MACHINES_MODAL_BAREMETAL = 'HIDE_DELETE_ALL_MACHINES_MODAL_BAREMETAL';

export const UPDATE_SELECTED_ITEMS_STATE_BAREMETAL  = 'UPDATE_SELECTED_ITEMS_STATE_BAREMETAL';