import React from 'react';
import { Card } from 'react-bootstrap';
import ButtonHS from '../../../../hireserver/controls/ButtonHS';
import StickyFooter from '../../../../hireserver/StickyFooter';

export default function NodeBalancerFooter() {
    function handleFormClick(e) {
        // console.log(e)
        
    }
    return (
        <div>
            <Card className='d-lg-none d-block'>
                <Card.Body className='d-flex d-gap-20 flex-column displaycreatefooterform'>
                    <div className='d-flex text-primary justify-content-between w-100 align-items-center'>
                        <div>
                        NodeBalancer Summary
                        </div>
                        <div>
                        <div className='font-w700'>$10.00/mo</div>
                        </div>
                    </div>
                    <div className='text-center py-1'>
                    <ButtonHS isDisable={false} styles={{ padding: "15px 100px" }} handler={handleFormClick} >
                    Deploy
                </ButtonHS>
                    </div>
                </Card.Body>
            </Card>
            <StickyFooter>
                <div className='d-flex flex-column text-primary align-items-center '>
                    <div>
                        NodeBalancer Summary
                    </div>
                    <div className='font-w700'>$10.00/mo</div>
                </div>
                <ButtonHS isDisable={false} styles={{ padding: "15px 100px" }} handler={handleFormClick} >
                    Deploy
                </ButtonHS>

            </StickyFooter>
        </div>
    )
}
