import React from 'react'
import HeadingHS from '../../../../hireserver/controls/HeadingHS';
import DisplayCard from '../../../../hireserver/DisplayCard';

export default function NodeBalanceRegion() {
    const [fakeArr, setFakeArr] = React.useState([
        {
            img: 'https://bs-uploads.toptal.io/blackfish-uploads/components/skill_page/content/logo_file/logo/195568/sql-64a6e0f07773cf17581e76ca09e17dbc.png',
            name: 'SQL'
        },
        {
            img: 'https://assets-global.website-files.com/6064b31ff49a2d31e0493af1/610d75bbd917363ac7a1fec0_mongoDB.svg',
            name: 'NoSQL'
        }
    ]);


    function handleClick(e){
        // console.log('click')
    }

  return (
    <React.Fragment>
            <div className='col-12 col-lg-7 '>
                <div>
                    <HeadingHS title="Region" as="h3" />
                    <div className='hs_text-light'>You can use out <span className='text-primary'>speedtest page</span> to find region for your current location.</div>
                </div>

                <DisplayCard clickHandler={handleClick} classNames="d-flex d-gap-10 hs_text-light" heading="Region" arr={fakeArr} cardName="Region" />
            </div>
            
        </React.Fragment>
  )
}
