import React from 'react'
import ModalHs from '../../ModalHs'
import { imageReplaceHashHelper, percentageHelper } from '../../../../../utils/Helpers';
import ImageHs from '../../ImageHs';
//import beeStand from '../../../../../../images/hireserver/imgs/bee/deploy.png';
import beeStand from '../../../../../images/hireserver/imgs/bee/deploy.png';
import { isMobile } from 'react-device-detect';
import SpacerHS_TWO from '../../Spaces/SpacerHS_TWO';
import CurrencyFC from '../Currency/CurrencyFC';
function DepolyPopup({summaryMachineInfo,deployOpen,closeDeployModal,renderCustomHTML,totalPrice,submitHandler}) {
    
    let disableClose = false;
    let loading = false;
        let paymentData = {};
        let price = 0;
        let walletValue = 0;
    function getDeployHTML() {
       
        // if (submitPayload == undefined || submitPayload == null || submitPayload == '') return;

        // const {
        //     location,
        //     os,
        //     machineName,
        //     blocks,
        //     tenure,
        //     specs,
        //     database
        // } = summaryMachineInfo;
        let  {location,
        os,
        machineName,
        blocks,
        tenure,
        specs,
        database} = summaryMachineInfo;
       
       
        const tenureJsonRes = tenure? JSON.parse(tenure?.taxPercentage):null;
      
        let loading = false;
        let paymentData = {};
        let price = totalPrice;
        let walletValue = 0;
        return <div className=''>

            <div className='p-3 px-5 border-bottom font-16'>
                <span className='text-primary'> Order Summary</span>
            </div>

            <div className='p-3 px-5 row border-bottom font-16 font-w500'>

                <div className="col-sm-7 d-flex align-items-center d-gap-40">
                    <ImageHs src={beeStand} alt={'orderDesState?.machinename'} width="100px" />

                    <div className=''>
                        <div className=' m-0'>
                            <span>Instance</span> <span>:</span> <span>{machineName}</span>
                        </div>
                        <hr className='my-1' />
                        <div className='font-12'>
                            {blocks * 1} CPU, {blocks * specs?.ram}  Gb Ram, {blocks * specs?.storage} Gb Storage
                        </div>
                        <hr className='my-1' />
                        <div className='d-flex d-gap-10 font-12'>
                            <ImageHs src={imageReplaceHashHelper(location?.image)} width="15px" />
                            <div>
                                {location?.name}
                            </div>
                        </div>
                        {
                            database === 0 && <React.Fragment>
                                <hr className='my-1' />
                                <div className='d-flex d-gap-10 font-12'>
                                    <ImageHs src={imageReplaceHashHelper(os?.image)} width="15px" />
                                    <div>
                                        {os?.groupname} {os?.name}
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        {
                            database != 0 &&
                            <React.Fragment>
                                <hr className='my-1' />
                                <div className='d-flex d-gap-10 font-12'>
                                    <ImageHs src={imageReplaceHashHelper(database?.icon)} width="15px" />
                                    <div>
                                        {database.appname} {database.appversion}
                                    </div>
                                    <span className='font-12 font-w700 hsBack'>+</span>
                                    <ImageHs src={imageReplaceHashHelper(os?.image)} width="15px" />
                                    <div>
                                        {os?.groupname} {os?.name}
                                    </div>

                                </div>
                            </React.Fragment>
                        }
                    </div>
                </div>

              
                {isMobile ? <SpacerHS_TWO /> : null}
                <div className="col-sm-5 ">
                    <div className="row">
                        <div className="col-sm-6 d-flex flex-column align-items-center">
                            <div className='hs_text-light'>Blocks</div>
                            <div>{blocks}</div>
                        </div>
                        <div className="col-sm-6  d-flex flex-column align-items-center">
                            <div className='hs_text-light'>
                                Tenure
                            </div>
                            <div>
                                {/* <CurrencyFC name={paymentData?.currencyname} val={price} /> */}
                                <span className='font-14'>{tenure?.tenure}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='p-3 px-5'>
                    {appendRenderCustomHTML()}
                </div>
            <div className='p-3 px-5 border-bottom'>

                <div className='d-flex justify-content-between'>
                    <span>Blocks x {blocks}</span>
                    <span><CurrencyFC name={paymentData?.currencyname} val={price} /></span>

                </div>
                <div className='d-flex justify-content-between'>
                    <span>SGST {tenureJsonRes?.SGST}%</span>
                    <span>  <CurrencyFC name={paymentData?.currencyname} val={percentageHelper(tenureJsonRes?.SGST, price)} />  </span>

                </div>
                <div className='d-flex justify-content-between'>
                    <span>CGST {tenureJsonRes?.CGST}%</span>
                    <span>  <CurrencyFC name={paymentData?.currencyname} val={percentageHelper(tenureJsonRes?.CGST, price)} />  </span>

                </div>

                {/* <div className='d-flex justify-content-between'>
              <span>Amount Charged</span>
              <span>  <CurrencyFC name={paymentData?.currencyname} val={Math.abs(walletValue-price)} /></span>
            </div> */}

                {/* <span className='text-primary mr-2'>-20%</span> */}
                {/* <div className='d-flex justify-content-between'>
              <span>Taxes</span>
              <span className=''>
          
                <span> -  {getCurrencySymbol('INR', 100)}</span>
              </span>
            </div> */}
            </div>
            <div className='p-3 px-5 border-bottom'>
                <div className='d-flex justify-content-between'>


                    <span>{Boolean(walletValue) && walletValue < parseInt(price + parseInt(percentageHelper(tenureJsonRes.CGST * 2, price))) && 'Sub'}Total</span>
                    <span>   <CurrencyFC name={paymentData?.currencyname} val={Math.abs((parseInt(price + parseInt(percentageHelper(tenureJsonRes?.SGST, price)) + parseInt(percentageHelper(tenureJsonRes?.CGST, price)))))} /></span>
                </div>

                {Boolean(walletValue) && walletValue < parseInt(price + parseInt(percentageHelper(tenureJsonRes.CGST * 2, price))) &&
                    <React.Fragment>
                        <div className='d-flex justify-content-between'>
                            <span className='text-danger'>wallet</span>
                            <span className='text-danger'>   <span className='px-1'>-</span><CurrencyFC name={paymentData?.currencyname} val={walletValue} /></span>
                        </div>

                        <div className='d-flex justify-content-between'>
                            <span>Total</span>
                            <span className=''>  <CurrencyFC name={paymentData?.currencyname} val={Math.abs(walletValue - parseInt(price + parseInt(percentageHelper(tenureJsonRes?.SGST, price)) + parseInt(percentageHelper(tenureJsonRes?.CGST, price))))} /></span>
                        </div>
                    </React.Fragment>
                }
                {/* <div className='d-flex justify-content-between'>
              <span>wallet</span>
              <span className=''>  - <CurrencyFC name={paymentData?.currencyname} val={walletValue-(walletValue-(parseInt(price+parseInt(percentageHelper(tenure.taxPercentage,price)))))} /></span>
            </div> */}

            </div>


            {walletValue < Math.abs((parseInt(price + parseInt(percentageHelper(tenureJsonRes?.SGST, price)) + parseInt(percentageHelper(tenureJsonRes?.CGST, price))))) && <div className='px-5 py-3'>
                <div>Your wallet doesn't have enough money. You will redirect to payment gateway page.</div>
            </div>}
            {walletValue >= Math.abs((parseInt(price + parseInt(percentageHelper(tenureJsonRes?.SGST, price)) + parseInt(percentageHelper(tenureJsonRes?.CGST, price))))) && <div className='px-5 py-3'>
                <div>Your have <span className='text-primary font-w500'><CurrencyFC name={paymentData?.currencyname} val={walletValue} /></span>  in your wallet. Your machine creations start when you click proceed.</div>
            </div>}

        </div>
    }

    function closeDeploy(){
        closeDeployModal();
    }

    function okayHandler(e){
        submitHandler(e,summaryMachineInfo.outputData);
    }

    function appendRenderCustomHTML(){
        if(typeof renderCustomHTML == 'function'){
            return renderCustomHTML();
        }
       
    }

    return (
        <ModalHs bodyClasses="p-0 overflow-hidden" size="lg" backdrop="static" isNeed={false} handleClose={closeDeploy} okayHandler={okayHandler} showModalHS={deployOpen} btnTitle="Deploy Machine" >
            <div className='w-100'>
                <div className='align-item-center d-flex flex-column justify-content-center w-100'>

                    {getDeployHTML()}
        

                </div>
                <div className=' justify-content-end d-gap-10 d-flex p-3 px-5 font-12'>
                    <button disabled={disableClose} className={`bg-danger border-0 btn-sm hs_borderRadius-4 text-white ${disableClose ? 'd-none' : ''}`} onClick={closeDeploy}>
                        Cancel
                    </button>
                    <button className='w-150p hs-signin-btn border-0 btn-sm hs_borderRadius-4 text-white' onClick={okayHandler}>
                        {loading && <React.Fragment>
                            <div className="spinner-border text-white text-light" role="status"></div> </React.Fragment>}
                        {!loading && 'Proceed'}
                    </button>
                </div>
                <div className=' p-3 px-5 font-12'>
                    By checking out you agree with our Terms of Service. We will process your personal data for the fulfillment of your order and other purposes as per our Privacy Policy. You can cancel recurring payments at any time.
                </div>
            </div>
            <div>

            </div>
        </ModalHs>
    )
}

export default DepolyPopup