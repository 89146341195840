import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";
import ThresholdsNotfi from "./SettingTabComponents/ThresholdsNotfi";
import { getThresholdsService, postNotifsConfigService } from "../../../../../../../services/InstancesServices/InstancesListService";
import { checkResData } from "../../../../../../../utils/Helpers";
import { handlerAlert } from "../../../InstancesListComponents/DropDownInstancesList";
import { connect } from "react-redux";

function SettingsTab({currDetailsItem}) {
  const [thresholdsState,setThresholdsState] = React.useState({
    cpuusage:{
      isChecked:false,
      val:'',
      name:'CPU Usage',
      des:'Average CPU Usage over 2 hours exceeding this value triggers this alert',
      inputTop:'Usage Threshold',
      inputSide:'%',
      dataname:"cpuusage",
      error:false,
      errorMessage:''
    },
    diskiorate:{
      isChecked:false,
      val:'',
      name:'Memory usage IO Rate',
      des:'Average Memory Usage over 2 hours exceeding this value triggers this alert',
      inputTop:'Usage Threshold',
      inputSide:'%',
      dataname:"diskiorate",
      error:false,
      errorMessage:''
    },
    // incomingtraffic:{
    //   isChecked:false,
    //   val:'',
    //   name:'Incoming Traffic',
    //   des:'Average incoming traffic over 2 hour period exceeding this value triggers this alert',
    //   inputTop:'Traffic Threshold',
    //   inputSide:'Mb/s',
    //   dataname:"incomingtraffic",
    //   error:false,
    //   errorMessage:''
    // },
    // outboundtraffic:{
    //   isChecked:false,
    //   val:'',
    //   name:'Outbound Traffic',
    //   des:'Average outbound traffic over a 2 hour period exceeding this value triggers this alert',
    //   inputTop:'Traffic Threshold',
    //   inputSide:'Mb/s',
    //   dataname:"outboundtraffic",
    //   error:false,
    //   errorMessage:''
    // },
    // transferquota:{
    //   isChecked:false,
    //   val:'',
    //   name:'Transfer Quota',
    //   des:'Percentage of network transfer quotaused being greater than this value triggers this alert',
    //   inputTop:'Quota Threshold',
    //   inputSide:'%',
    //   dataname:"transferquota",
    //   error:false,
    //   errorMessage:''
    // },
  



  });
  const [saveServerData,setSaveServerData] = React.useState({});
  const [submitLoading,setSubmitloading] = React.useState(false);

  function handleChange(e){
    
    
    

    const type = e.target.getAttribute('data-type')
    const isChecked = e.target.checked;

    const newObj = {...thresholdsState};

    if(type=="transferquota"){
      newObj['transferquota']['isChecked'] = isChecked;
    }else if(type=="outboundtraffic"){

      newObj['outboundtraffic']['isChecked'] = isChecked;
    }else if(type=="incomingtraffic"){
      newObj['incomingtraffic']['isChecked'] = isChecked;
    
    }else if(type=="diskiorate"){
      newObj['diskiorate']['isChecked'] = isChecked;
    }
    else if(type=="cpuusage"){
      newObj['cpuusage']['isChecked'] = isChecked;
    }

    setThresholdsState(newObj);
  }

  function getThresholdsHTML(){
    const threHold = [];
    for(let item in thresholdsState){
      const {isChecked,val,name,des,error,errorMessage,inputTop,inputSide,dataname} = thresholdsState[item];
      threHold.push(  <ThresholdsNotfi error={error} errorMessage={errorMessage} onInputHandler={onInputHandler} isChecked={isChecked} name={name} des={des} inputVal={val} inputTop={inputTop} handleChange={handleChange} dataname={dataname} inputSide={inputSide} />) 
    }

    return threHold;
  }


  function onInputHandler(e){


    const type = e.target.getAttribute('data-type')
    const val = e.target.value;
    // if(e.nativeEvent.data == 'e') return;
    const newObj = {...thresholdsState};

    if(type=="transferquota"){
      newObj['transferquota']['val'] = val;
      newObj['transferquota']['error'] = false;
      newObj['transferquota']['errorMessage'] = '';
    }else if(type=="outboundtraffic"){

      newObj['outboundtraffic']['val'] = val;
      newObj['outboundtraffic']['error'] = false;
      newObj['outboundtraffic']['errorMessage'] = '';
    }else if(type=="incomingtraffic"){
      newObj['incomingtraffic']['val'] = val;
      newObj['incomingtraffic']['error'] = false;
      newObj['incomingtraffic']['errorMessage'] = '';
    
    }else if(type=="diskiorate"){
      newObj['diskiorate']['val'] = val;
      newObj['diskiorate']['error'] = false;
      newObj['diskiorate']['errorMessage'] = '';
    }
    else if(type=="cpuusage"){
      newObj['cpuusage']['val'] = val;
      newObj['cpuusage']['error'] = false;
      newObj['cpuusage']['errorMessage'] = '';
    }

    setThresholdsState(newObj);
  }



  React.useEffect(function(){
   getThresHoldData();
  },[currDetailsItem]);

  async function getThresHoldData(){

    const res = await getThresholdsService(currDetailsItem?.machineid);
    const isValid = checkResData(res);
    if(!isValid){
      handlerAlert("danger","Can't get threshold values","danger");
      return;
    }
    const JSONRes = JSON.parse(res.data.result)[0];
    if(!JSONRes){
      //handlerAlert("danger","NO data","danger");
      return;
    }
    
    const {contactid,cpuusage,diskiorate,incomingtraffic,machineid,machinename,outboundtraffic,transferquota} = JSONRes;
    const newObj = {...thresholdsState};
    

    newObj['cpuusage']['val'] = cpuusage;
    newObj['cpuusage']['isChecked'] = cpuusage === 0 ? false : true;

    newObj['diskiorate']['val'] = diskiorate;
    newObj['diskiorate']['isChecked'] = diskiorate === 0 ? false : true;

    newObj['incomingtraffic']['val'] = incomingtraffic;
    newObj['incomingtraffic']['isChecked'] = incomingtraffic === 0 ? false : true;

    newObj['outboundtraffic']['val'] = outboundtraffic;
    newObj['outboundtraffic']['isChecked'] = outboundtraffic === 0 ? false : true;

    newObj['transferquota']['val'] = transferquota;
    newObj['transferquota']['isChecked'] = transferquota === 0 ? false : true;

    setThresholdsState(newObj);
    setSaveServerData(JSONRes);
  }

  async function handleSubmit(){
  
    setSubmitloading(true);
    const {contactid,machineid,notificationthresholdid} = saveServerData;

    const names = ['cpuusage','diskiorate','incomingtraffic','outboundtraffic','transferquota'];

    

    const newObj =  {...thresholdsState};
    let isError = false;
    for(let i=0;i<names.length;i++){
      let currItem = names[i];

      if(newObj[currItem]['isChecked']){
       
        if(newObj[currItem]['val'] == '' || newObj[currItem]['val'] == 0){
          isError = true;
          newObj[currItem]['error'] = true;
          newObj[currItem]['errorMessage'] = 'value should be greater than 0';
        }
      }

    }
    
    if(false){
      setSubmitloading(false)
      handlerAlert("danger","Enable to save","danger")
      return;
    }

    if(isError){
      setSubmitloading(false);
      setThresholdsState(newObj);

      return;
    }



    let config = {
    

      "machineid": `${currDetailsItem?.machineid}`,
       "notificationthresholdid":notificationthresholdid?`${notificationthresholdid}`:"0",
      "cpuusage": `${thresholdsState['cpuusage']['val'] === ''?0:thresholdsState['cpuusage']['val']}`,
  
      "diskiorate": `${thresholdsState['diskiorate']['val'] === ''?0:thresholdsState['diskiorate']['val']}`,
  
      "incomingtraffic": `${thresholdsState['incomingtraffic']['val'] === ''?0:thresholdsState['incomingtraffic']['val']}`,
  
      "outboundtraffic": `${thresholdsState['outboundtraffic']['val'] === ''?0:thresholdsState['outboundtraffic']['val']}`,
  
      "transferquota": `${thresholdsState['transferquota']['val'] === ''?0:thresholdsState['transferquota']['val']}`
    }


    const res = await postNotifsConfigService(config);

  
    setSubmitloading(false);

    if(res.data.code != '0'){
      handlerAlert("danger",res.data.message,'danger');
      return;
    }else{
      handlerAlert("success",res.data.message);
    }

  }

 

  return (
    <div className="App">
      {/* <Card style={{ width: "100rem" }} className="detail-card">
        <ListGroup variant="flush">
          <ListGroup.Item>
            <div className="mb-2">
              <p className="setting_name font-16 font-w600">Instance Label</p>
            </div>
            <label className="mb-2">Label</label>
            <input type="text" className="form-control w-25 mb-3"></input>
            <button className="p-2 border-0 update-btn text-white">Save</button>
          </ListGroup.Item>
        </ListGroup>
      </Card> */}

      {/* <Card style={{ width: "100rem" }} className="detail-card">
        <ListGroup variant="flush">
          <ListGroup.Item>
            <div className="mb-2">
              <p className="setting_name font-16 font-w600">
                Reset Root Password
              </p>
            </div>
            <label>Disk</label>
            <div>
              <select className="form-control w-25 mb-3">
                <option>Ubuntu 17:04 Disk</option>
                <option>Ubuntu 17:04 Disk1</option>
                <option>Ubuntu 17:04 Disk2</option>
              </select>
            </div>
            <label>New Root Password</label>
            <input type="password" className="form-control w-25 mb-3"></input>
            <div className="mb-1">
              <span className="strength"></span>&nbsp;
              <span className="strength"></span>&nbsp;
              <span className="strength"></span>&nbsp;
              <span className="threshold-content">Strength : week</span>
            </div>
            <div className="d-flex">
              <div>
                <button className="p-2 border-0 update-btn text-white">Save</button>
              </div>
              <div>
                <img src="../question-mark.png"className="setting_disk_detail" ></img>
              </div>
            </div>
          </ListGroup.Item>
        </ListGroup>
      </Card> */}

      <Card style={{ width: "100rem" }} className="detail-card">
        <ListGroup variant="flush">
          <ListGroup.Item>
            <div className="mb-2">
              <p className="setting_name font-16 font-w600">
                Notification Thresholds
              </p>
            </div>
            <ul>
              {getThresholdsHTML()}
            </ul>
            <div className="mb-1 mt-2 py-1 ">
              {/* <div onClick={handleSubmit} className="px-3 text-white cursor-pointer">
                Save
              </div> */}
              <button  onClick={handleSubmit} className="px-3 cursor-pointer btn hs-signin-btn w-100p py-2 hs_borderRadius-4">
              {submitLoading ? <React.Fragment>
                                    <div className="spinner-border text-light" role="status">

                                    </div> Save
                                  </React.Fragment> : 'Save'}
              </button>
            </div>
          </ListGroup.Item>
        </ListGroup>
      </Card>

      {/* <Card style={{ width: "100rem" }} className="detail-card">
        <ListGroup variant="flush">
          <ListGroup.Item>
            <div className="mb-2">
              <p className="setting_name font-16 font-w600">
                Shutdown Watchdog
              </p>
            </div>
            <ul>
              <li className="row pt-2">
                <div className="col-lg-2 d-flex mb-2">
                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div className="align-items-center row m-0">
                      <div className="p-0">
                        <label className="switch">
                          <input type="checkbox"></input>
                          <span className="slider"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="pl-3">
                    <p className="threshold-content">Enabled</p>
                  </div>
                </div>
                <div className="col-lg-10">
                  <p className="threshold-content">
                    Shutdown Watchdog, also know as Lassie, is a Instance
                    Manager feature capable of automatically rebooting your
                    instance if it powers off unexpectedly. Lassie is not
                    technically an availability monitoring tool, but it can help
                    get your instance back online fast if it's accidentally
                    powered off.
                  </p>
                </div>
              </li>
            </ul>
          </ListGroup.Item>
        </ListGroup>
      </Card> */}

      {/* <Card style={{ width: "100rem" }} className="detail-card">
        <ListGroup variant="flush">
          <ListGroup.Item>
            <div className="mb-2">
              <p className="setting_name font-16 font-w600">Delete Instance</p>
            </div>
            <div>
              <div className="mb-1 delete_instnace py-1">
                <a className="px-3 text-white" href="/vmdetails">
                  Delete
                </a>
              </div>
              <div>
                <p className="threshold-content">
                  Deleting a instance will result in permanent data loss
                </p>
              </div>
            </div>
          </ListGroup.Item>
        </ListGroup>
      </Card> */}
    </div>
  );
}


const mapStateToProps = ({ instanceDetails }) => {
  return {
    currDetailsItem: instanceDetails.currDetailsItem,
  };
};
export default connect(mapStateToProps)(SettingsTab);