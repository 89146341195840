
export const isAuthenticated = (state) => {
    
    if (state.auth.auth?.sessionId) return true;
    return false;
};
// import Cookies from "js-cookie";
// import { checkIsSessionValid } from "../../services/AuthService";
// import { USER_COOKIE } from "../types/AuthTypes/AuthTypes";

// export  const isAuthenticated =  () => {
//     '';
//     const userCookie = Cookies.get(USER_COOKIE);
//     if (!userCookie) {
//       //setIsLoading(false);
//       return false;
//     };
//     // const { sessionId } = JSON.parse(userCookie);
//     // let isTrue = await checkIsSessionValid(sessionId);
//     return true;

// }