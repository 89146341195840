import React from 'react';
import { Link } from 'react-router-dom';
import beeImg from '../../../../../images/hireserver/imgs/bee.png';
import SpacerHS_TEN from '../../Spaces/SpacerHS_TEN';
import SpacerHS_TWO from '../../Spaces/SpacerHS_TWO';

function ResErrorPayment({paymentDone}) {
  return (
    <div className='d-flex justify-content-center align-items-center flex-column'>
        <div className='w-200p h-200p'>
            <img className='w-100 h-100' src={beeImg} alt="error page" />
        </div>
        <SpacerHS_TEN/>
        <div className=''>
        Aaaah! Something went wrong
        </div>

        {paymentDone && <div>Payment Successful but Machine Creation failed.</div>}
      
        {!paymentDone&&<div>
          <SpacerHS_TWO/>
          <Link className='hs-signin-btn btn hs_borderRadius-4 py-2' to="/instances">
        View Instances
        </Link>
        </div>}

       
    </div>
  )
}

export default ResErrorPayment