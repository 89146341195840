import React, { useState, Link, Button } from "react";
import Table from "react-bootstrap/Table";
import { createBackupInstanceService, getBackupDBListService, getbackupjobsService, getBackupListService, getInstancesDescriptionService, postCreateBackupService } from "../../../../../../../services/InstancesServices/InstancesListService";
import { connect } from "react-redux";
import { handlerAlert } from "../../../InstancesListComponents/DropDownInstancesList";

import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";
import { MDBDataTable } from "mdbreact";
import { checkResData, unixDateToNormalDate } from "../../../../../../../utils/Helpers";
import LoaderHS from "../../../../../hireserver/components/LoaderHS";
import DropDownBackup from "./BackUpComponents/DropDownBackup";
import BackUpRemoveModal from "./BackUpComponents/BackUpRemoveModal";
import { TABLE_ENTIRES } from "../../../../../../../utils/Emuns";

function BackupsTab({ currDetailsItem }) {

  const [backups, setbackups] = useState(false);
  const [loading, setLoading] = React.useState(true);
  const [removeModal, setRemoveModal] = React.useState(false);

  const [isBackupApiState, setisBackupApiState] = React.useState(null);

  const [instanceData, setInstanceData] = React.useState(true);
  const [headers, setHeaders] = React.useState([

    {
      label: "Name",
      field: "name",
      sort: "asc",
    },

    {
      label: "Date",
      field: "date",
      sort: "asc",
    },



    {
      label: "",
      field: "button",
      sort: "asc",
      // attributes: { className: "text-center" },
    },


  ]);
  const [formatData, setFormatData] = React.useState({
    columns: headers,
    rows: [],
  });

  React.useEffect(function () {
    callBackupList();
  }, [])

  async function callBackupList() {


    if (Object.keys(currDetailsItem).length === 0) {
      setLoading(false);
      return;
    };
    const { branchid, machineid } = currDetailsItem;


    //getInstancesDescriptionService

    // check is backup is enable

    const isCheckBackEnable = await getInstancesDescriptionService(machineid);
    console.log(isCheckBackEnable);
    const isValid = checkResData(isCheckBackEnable);
    if (!isValid) {
      setLoading(false);
      setbackups(true);
      handlerAlert("danger", "something went wrong", "danger");
      return;
    }

    const dataCheck = JSON.parse(isCheckBackEnable.data.result)[0];
    if(!Boolean(dataCheck)){
      setLoading(false);
      setbackups(true);
      return
    }
    const isEnableBackup = dataCheck.isbackupenabled;
    setisBackupApiState(dataCheck);

    if (!isEnableBackup) {
      setLoading(false);
      setbackups(true);
      return;
    }

    renderList();


  }

  async function renderList(){
    const { machineid } = currDetailsItem;
    const listData = await getBackupDBListService(machineid);
    if (listData.data.message === 'No Data Available') {
      setLoading(false);
      //setbackups(false);
      return;
    }
   
    if(!checkResData(listData)){
      setLoading(false);
      
      return;
    }
    const listAll = JSON.parse(listData.data.result);

    console.log(listAll);
    let newRows = [];
    for (let i = 0; i < listAll.length; i++) {
      const { backupdate, backupfilename, pk_id } = listAll[i];


      let newRow = {

        name: backupfilename,
        date: backupdate,

        button: <DropDownBackup setRemoveModal={setRemoveModal} />

      }
      newRows.push(newRow);

    }
    if (newRows.length <= 0) {
      setFormatData({
        columns: headers,
        rows: []
      });
      setbackups(false);

      setLoading(false);
      return;
    }

    setbackups(false);
    setFormatData({
      columns: headers,
      rows: newRows
    });
    setLoading(false);
  }

  function handleClickCreate() {
    
    // createBackUp();
    createBackupDB();
    //setbackups(false);
  }

  async function createBackupDB() {
    // payload : {"machineId":100015340000677,"description":"<p>test</p>","userid":100011370000363,"isbackupenabled":true}
    const { machineid } = currDetailsItem;
    const payload = { ...isBackupApiState, isbackupenabled: true, machineId: machineid };
    const res = await postCreateBackupService(payload)
    console.log(res);
    const message = res.data.message;
    if (res.data.code != 0) {
      handlerAlert("danger", message, "danger");
      return;
    }


    handlerAlert("success", message);
    renderList();
    
  }

  async function createBackUp() {
    
    const { branchid, vmid } = currDetailsItem;
    setLoading(true);
    const res = await getbackupjobsService(branchid);
    if (res.data.error != null) {
      setLoading(false);
      return;
    };
    if (res.data === '') {
      handlerAlert("danger", 'No Data Found', "danger");
      setLoading(false);
      return;
    }
    if (res.data.code != "0") {
      handlerAlert("danger", res.data.code, "danger");
      setLoading(false);

      return;
    }

    if (res.data.errors !== null) {
      handlerAlert("danger", res.data.errors[0], "danger");
      setLoading(false);
      return;
    }

    if (res.data.result === "") {
      handlerAlert("danger", "Empty Data", "danger");
      setLoading(false);
      return;
    }

    const result = JSON.parse(res.data.result)?.data;
    let idNode = '';
    let vmidsArr = [];
    let vmidsStr = '';
    // console.log(result)
    for (let i = 0; i < result.length; i++) {
      const { id } = result[i];

      if (i === 0) {
        idNode = id;
      }
      vmidsArr.push(result[i].vmid);
      if (result[i].vmid != vmid) {
        if (i === result.length - 1) {
          vmidsStr = vmidsStr + `${result[i].vmid},${vmid}`;

        } else {
          vmidsStr = vmidsStr + `${result[i].vmid},`
        }
      }
    }

    // console.log(vmidsStr)
    if (vmidsStr == '') {
      vmidsStr = `${vmid}`;
    }
    const resBackup = await createBackupInstanceService(branchid, idNode, {
      "vmid": vmidsStr
    });
    await callBackupList();


    // console.log(resBackup,'Createdback')


  }

  if (loading) {
    return <LoaderHS />
  }


  return (
    <div>
      {backups ?
        <div className="container text-center py-3">
          <div className="bg-light ustify-content-center align-items-center rounded-circle mx-auto backups">
            <div className="backup-dot position-relative top-head">
              <div className="position-absolute backup_dot"></div>
            </div>
            <div className="backup-dot position-relative">
              <div className="position-absolute backup_dot"></div>
            </div>
          </div>
          <div className="mt-3 backup-head font-w600">Backups</div>
          <div className="contentContainer d-flex flex-column mt-3">
            <div className="text-dark mb-3">
              <p className="text-center m-auto backup-text">
                Three backup slots are executed and rotated automatocally: a daily
                backup, a 2-7 day old backup, and an 8-14 day old backup. To
                enable backups for just $2.00 per month, click below.
              </p>
            </div>
            <div className="mb-4 enable_instnace py-1">
              <button className="  btn-hslineargradient px-4" onClick={handleClickCreate}>
                Enable Backups
              </button>
            </div>
          </div>
        </div>
        :
        <React.Fragment>
          <div className="table-flow border-top">
            <Card style={{ width: "100rem" }} className="detail-table">
              <ListGroup variant="flush">
                <ListGroup.Item className="p-0">
                  <div>
                    <MDBDataTable paging={Boolean(formatData.rows.length>TABLE_ENTIRES)} paginationLabel={['‹', '›']} noRecordsFoundLabel="You have enabled backups. Currently your backups are empty. Your next backup will be taken today 12PM." className='snapshotTable' searching={false} disableRetreatAfterSorting={true} entriesOptions={[1, 5, 20, 25]} entries={TABLE_ENTIRES} data={formatData} options={{
                      textLabels: {
                        body: {
                          noMatch: "Sorry, no matching records found",
                        }
                      }
                    }} />
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </Card>

          </div>
          <BackUpRemoveModal showHsModal={removeModal} setRemoveModal={setRemoveModal} />
        </React.Fragment>
      }
    </div>
  );
}

const mapStateToProps = ({ instanceDetails }) => {
  return {
    currDetailsItem: instanceDetails.currDetailsItem,
  };
};
export default connect(mapStateToProps)(BackupsTab)
