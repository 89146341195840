export function genrateDotKeyFile(data,fileName){
    let tempLink = document.createElement('a');
    // tempLink.href = csvURL;
    // tempLink.setAttribute('download', 'filename.csv');
    // tempLink.click();
  
        let 
            blob = new Blob([data], {type: "key"}),
            url = window.URL.createObjectURL(blob);
            tempLink.href = url;
            tempLink.download = fileName;
        tempLink.click();
        window.URL.revokeObjectURL(url);
    
}



