import React from 'react'
import { Card } from 'react-bootstrap'
import { connect } from 'react-redux';
import { getCurrencySymbol } from '../../../../../utils/Currency';
import { mbToGbHelper } from '../../../../../utils/Helpers';
import CurrencyFC from '../../../hireserver/components/Currency/CurrencyFC';
import SelectCard from '../../../hireserver/SelectCard'

function ServerSideCard(props) {
    const { setSelectServer,item:{name,price,value,cpu,ram,memory},paymentData } = props;
    function handler(){

        setSelectServer({
            name,price,
            value
        });
  
        //// console.log(name)
    }
    return (
        <SelectCard {...props} handler={handler} cardName="serverNames" isHeight="auto">
            <div className='d-flex flex-column align-items-center justify-content-center p-4 h-100'>
                <div className='d-flex flex-column align-items-center'>
                    <div className='font-w500'> 
                        {name}
                    </div>
                    <div className='hs_secondaryColor'>
                        <span style={{fontSize:'25px'}} className="font-w500">  </span> <span>/month</span>
                    </div>
                </div>
                <div className='my-3' style={{ width: '50px', height: '2px', backgroundColor: '#068D8A' }}></div>
                <div className='d-flex flex-column align-items-center font-12'>
                    <div>
                        <span className='text-dark font-w700 pr-1'> {mbToGbHelper(memory)} GB </span><span>NVMe</span>
                        
                    </div>
                    {/* <div>
                        <span className='text-dark font-w700 pr-1'>6</span> cores / <span className='text-dark font-w700 pr-1'>{cpu?.cores}</span> threads @4.0 GHz
                    </div> */}
                    {/* <div>
                        <span className='text-dark font-w700 pr-1'>6</span> cores / <span className='text-dark font-w700 pr-1'>{cpu?.cores}</span> threads @4.0 GHz
                    </div> */}
                    <div>
                        <span className='text-dark font-w700 pr-1'>
                           {cpu?.cores} 
                        </span>
                        <span>
                        Cores
                        </span>
                    </div>
                    <div>
                        <span className='text-dark font-w700 pr-1'>
                           {mbToGbHelper(ram)}
                        </span>
                        <span>
                            Ram
                        </span>
                    </div>
                    {/* <div>
                        <span className='text-dark font-w700 pr-1'>
                            {mbToGbHelper(memory)} GB 
                        </span>
                        <span>
                            Memory
                        </span>
                    </div> */}
                    <div>
                        <span className='text-dark font-w700 pr-1'>10 TB</span>
                        <span>Bandwidth</span>
                    </div>
                    {/* <div>
                        <span className='text-dark font-w700 pr-1'>10 Gbps</span>
                        <span>Network</span>
                    </div> */}
                </div>
            </div>
        </SelectCard>
    )
};

const mapStatesToProps = ({ auth, PaymentInfoReducer }) => {
    return {
      userDetails: auth.auth,
      currencyname: PaymentInfoReducer?.paymentData?.currencyname,
      paymentData: PaymentInfoReducer?.paymentData,
    };
  };
  
  export default connect(mapStatesToProps)(ServerSideCard);
  

