import produce from "immer";
import { CLOSE_PAYMENT_MODAL, OPEN_PAYMENT_MODAL, PUT_PAYMENT_INFO } from "../../types/PaymentInfoTypes";


const initialState = {
   isPayment:false,
   paymentData:null
};

function PaymentInfoReducer(state = initialState,action) {
    switch (action.type) {
        case PUT_PAYMENT_INFO: return{
            ...state,          
            paymentData:action.payload
        }
        case CLOSE_PAYMENT_MODAL:{
            return {
                ...state,
                isPayment:false
            }
        }
        case OPEN_PAYMENT_MODAL:{
            return {
                ...state,
                isPayment:true
            }
        }
        

        default:
            return state;
    }

}




export default PaymentInfoReducer;
