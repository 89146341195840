import { cloneDeep } from 'lodash';
import { MDBDataTable } from 'mdbreact';
import React from 'react'
import { Link, useRouteMatch, withRouter } from 'react-router-dom';
import { getScriptTableService } from '../../../../../../services/InstancesServices/InstancesListService';
import { paginationLabel, TABLE_ENTIRES } from '../../../../../../utils/Emuns';
import { getTokenFilter } from '../../../../../../utils/Helpers';
import BackButton from '../../../../hireserver/BackButton';
import FormInputError from '../../../../hireserver/components/FormInputError';
import JustInputText from '../../../../hireserver/components/JustInput/JustInputText';
import ButtonHS from '../../../../hireserver/controls/ButtonHS';
import SpacerHS_TWO from '../../../../hireserver/Spaces/SpacerHS_TWO';
import AreaScriptOutput from './AreaScriptOutput/AreaScriptOutput';
import ScriptMachineAuto from './autoselectmachine/ScriptMachineAuto';
import ButtonOptionsMarket from './ButtonOptionsMarket/ButtonOptionsMarket';
import SaveAndExecModal from './SaveAndExecModal/SaveAndExecModal';
import ScriptMarketContext from './ScriptMarketContext/ScriptMarketContext';
import TemplateScripts from './viewoutputtable/TemplateScripts';

function ScriptTextArea({ history }) {
    const { defaultValues, saveHandler, editorVal, setEditorVal,
        nameVal, setNameVal, loadingCreate, desError,
        nameError,onAutoMachineTagChange,resetCreateInputs,
        editorError,getUserMachinesList,selectMachineTag,
        desVal, setDesVal, setDesError, callHandlerDeleteOption,
        setNameError, innerList, setInnerList, getScriptInnerList,
        setEditorError, callDetailScript, setTokenOptions, setDefaultValue,setMachineTagState, machinetagState,getScriptList, autoCompleteMachineError,setAutoCompleteMachineError,setInitDataTextArea } = React.useContext(ScriptMarketContext);
    const [createMode, setCreateMode] = React.useState(false);
    let { path, url, params: { id } } = useRouteMatch();

    const [output, setoutput] = React.useState('');
  

    const [fullData, setFullData] = React.useState(null);
    const [col, setCols] = React.useState([
        {
            label: "Start Time",
            field: "starttime",
            sort: "asc",

            attributes: { className: " col-3" },
        }, {
            label: "End Time",
            field: "endtime",
            sort: "asc",
            attributes: { className: "col-3" },

        },
        {
            label: "output",
            field: "output",
            sort: "asc",
            attributes: { className: "col-3 text-truncate" },

        },
        {
            label: "status",
            field: "status",
            sort: "asc",
            attributes: { className: "col-3 text-truncate, text-center" },

        }



    ]);

    const [formatData, setFormatData] = React.useState({
        columns: col,
        rows: []
    });

    //modal
    const [showOutModal, setOutModal] = React.useState(false);

    



    // React.useEffect(function(){
    //       {/* <MDBDataTable paging={Boolean(formatData?.rows.length > TABLE_ENTIRES)} paginationLabel={paginationLabel} onSearch={onSearchHandler} disableRetreatAfterSorting={true} onPageChange={onPageChangeHandler} className='instanceListTable' entriesOptions={[1, 5, 20, 25]} entries={TABLE_ENTIRES} data={formatData} /> */}

    //       console.log(formatData,'formatData454545');
    //       console.log(paginationLabel,'formatData454545');
    //       console.log(TABLE_ENTIRES,'formatData454545');
    
 
    // },[formatData]);





    React.useEffect(function () {

        if (path.includes('create')) {
            setCreateMode(true);
debugger;

     

        } else {
             
             callDetailScript(id)
        // getScriptInnerList(id);
        // getUserMachinesList();
        }
        resetCreateInputs();
        return () => {
       
            setDefaultValue(null)
            setMachineTagState(null);
        }
    }, [path]);

//     React.useEffect(function () {
//         if (path.includes('create')) return;
//         const rowComps = [];
//         console.log("dasdasdsds",innerList);
//         for (let i = 0; i < innerList.length; i++) {

//             const { scriptid, starttime, endtime, output, scriptstatus } = innerList[i];

//             let rowData = {
//                 starttime: starttime,
//                 endtime: scriptstatus != 1 ? endtime : '',
//                 output: scriptstatus != 1 ? <div onClick={() => openOutputModal(output, innerList[i])} className=' cursor-pointer'><u className='text-primary'>View</u></div> : '',
//                 status: getStatus(innerList[i])


//             }
//             // let rowData = {
//             //     starttime: starttime,
//             //     endtime: scriptstatus != 1 ? endtime : '',
//             //     output: scriptstatus != 1 ? <div onClick={() => openOutputModal(output, innerList[i])} className=' cursor-pointer'><u className='text-primary'>View</u></div> : '',
//             //     status: getStatus(innerList[i])


//             // }

//             rowComps.push(rowData);
//         }
// debugger;
//         setFormatData({
//             columns: cloneDeep(col),
//             rows: cloneDeep(rowComps)
//         });
//     }, [innerList]);

    React.useEffect(function () {

        if (defaultValues) {
         
            const { name, description, createddata, script, tokens } = defaultValues;
            setEditorVal(script);
            setNameVal(name);
            setDesVal(description);
            console.log(defaultValues, 'defaultValuesdefaultValuesdefaultValues');
         
            if (tokens) {
           
                const tokenRes = JSON.parse(tokens);
                console.log(tokenRes,'tokenRestokenRestokenRes');
                const tokenData = tokenRes?.params;
                const tokenArr = getTokenFilter(script);
                
                if (tokenArr && tokenData) {
                    console.log(tokenData);
                    for (let token in tokenArr) {
                        for (let i = 0; i < tokenData.length; i++) {
                            if (tokenData[i].key == tokenArr[token].name) {
                                tokenArr[token]['val'] = tokenData[i].value;
                            }
                        }
                    }
                    setTokenOptions(tokenArr,'scucss');
                    setInitDataTextArea({
                        ...tokenRes,
                        params:tokenArr
                    });
                }else{
                    setInitDataTextArea({
                        ...tokenRes,
                       
                    });
                }

            }

        }else{
            setInitDataTextArea({});
        }
        console.log("defaultValues");

        return () => {
            setEditorVal('');
            setNameVal('');
            setDesVal('')
        }
    }, [defaultValues]);

    function handlerEditorChange(e) {

        setEditorVal(e.target.value)
        setEditorError({
            isError: false,
            message: ""
        })
    }

    
    function openOutputModal(o, full) {
        setoutput(o);
        setFullData(full);
        setOutModal(true);
    }

    function closeOutputModal() {
        setOutModal(false);

    }

    function handlerDesChange(e) {

        setDesVal(e.target.value)
        setDesError({
            isError: false,
            message: ""
        })
    }


    function handlerNameVal(e) {
        setNameVal(e.target.value);
        setNameError({
            isError: false,
            message: ""
        })
    }

    //list call
    // async function scriptTable(){
    //    const res =  await getScriptTableService(id);
    //    const dataRes = JSON.parse(res.data.result);

    //    let rowComps = [];



    //    console.log(dataRes);
    // }







    function onSearchHandler(value) {
        const newData = cloneDeep(formatData);
        setFormatData(newData);
    }

    function onPageChangeHandler({ activePage, pagesAmount }) {
        const newData = cloneDeep(formatData);
        setFormatData(newData);

        // const ls = document.querySelectorAll('.instanceListTable .instacesClass');
        // // console.log(ls, 'ldsa')
    }


    function getStatus(item) {
        if (!item) return null;

        let { scriptstatus } = item;
        let text = 'Fail'
        let isSuccess = 'danger'
        if (scriptstatus == '1') {
            text = 'Inprogress'
            isSuccess = 'warning'
        } else if (scriptstatus == '2') {
            text = 'Success';
            isSuccess = 'success'
        }
        return <div className='text-center d-flex justify-content-center align-items-center d-gap-10'>
            <div className={`bg-${isSuccess}`} style={{
                width: '10px',
                height: '10px',
                borderRadius: '25px'
            }}></div>
            <span>  {text}</span>
        </div>

    }

    function getBackURL(){
        if(window.location.pathname.includes('myscripts/details')){
            return '/myscripts/templates'
        }
        return '/myscripts/scripts';
    }

    return (
        <React.Fragment>
            <div className='position-relative'>
                <div className='d-flex justify-content-between align-items-center'>

                    <BackButton callback={() => getScriptList()} />

                </div>
                <hr />
                <div className='d-flex flex-column d-gap-10'>
                    <div className=''>
                        <span>Name</span>
                        <JustInputText changeHandler={handlerNameVal} className="w-md-35" value={nameVal} />
                        {nameError.isError && <FormInputError message={nameError.message} />}
                    </div>
                    <div className=''>
                        <span>Description</span>
                        <JustInputText changeHandler={handlerDesChange} className="w-md-35" value={desVal} />
                        {desError.isError && <FormInputError message={desError.message} />}
                    </div>

                  {/* {!createMode ?  <div className='w-md-35'>
                        <span>Machine List</span>
                        <ScriptMachineAuto  values={machinetagState} changeHandler={onAutoMachineTagChange} options={selectMachineTag}  />
                        {autoCompleteMachineError.isError && <FormInputError message={autoCompleteMachineError.message} />}
                    </div>:null} */}


                    <div>
                        <span>Script</span>
                        <textarea value={editorVal} onChange={handlerEditorChange} className="form-control border-radius-hs h-200p" />
                        {editorError.isError && <FormInputError message={editorError.message} />}
                    </div>
                    <div className='w-100 text-right d-flex justify-content-end d-gap-10'>


                        {createMode ? <ButtonHS isDisable={loadingCreate} handler={()=>saveHandler(true)} classes="p-1 w-100p" >
                            {loadingCreate ? <div className="spinner-border text-light" role="status">

                            </div> : 'Save'}
                        </ButtonHS> : <ButtonOptionsMarket />}



                    </div>
                </div>
            </div>
            <SaveAndExecModal />
                    
            <div>

                {/* {!createMode ? <React.Fragment>
                    <hr />
                    <div>
                        <div className='font-16 px-3 pb-2 text-primary '>

                            <span>Output</span>
                        </div>
                        <div className='scriptAreatable display mb-4 dataTablesCard customer-list-table  instances-table-card'>
                            <MDBDataTable paging={Boolean(5)} paginationLabel={paginationLabel} onSearch={onSearchHandler} disableRetreatAfterSorting={true} onPageChange={onPageChangeHandler} className='instanceListTable' entriesOptions={[1, 5, 20, 25]} entries={TABLE_ENTIRES} data={formatData} />
                        </div>
                    </div>
                    <AreaScriptOutput fullData={fullData} output={output} handleOutputClose={closeOutputModal} showOutModal={showOutModal} />
                </React.Fragment> : null} */}
            </div>
        </React.Fragment>
    )
}

export default withRouter(ScriptTextArea);