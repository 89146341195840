import React from 'react'
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { callAPI } from '../../../../../../services/API_SERVICES';
import { ClearAllNotificationService } from '../../../../../../services/InstancesServices/InstancesListService';
import { closeClearAllModalAction, getCountNotifs, getNotifsAction } from '../../../../../../store/reducers/NotificationReducer';
import { handlerAlert } from '../../../../../components/Dashboard/Instances/InstancesListComponents/DropDownInstancesList';
import ModalHs from '../../../../../components/hireserver/ModalHs'
import SpacerHS_TWO from '../../../../../components/hireserver/Spaces/SpacerHS_TWO'

function ClearAllModal({clearAllModal}) {
    const dispatch  = useDispatch();
    const [isLoading,setIsLoading] = React.useState(false);

    function onCloseHandler(){
        dispatch(closeClearAllModalAction())
    }

    async function onClearHandler(){
        setIsLoading(true);
      
        const {isError,result} = await   callAPI(ClearAllNotificationService)
        
        if(isError){
            onCloseHandler();
            handlerAlert("danger","Something went wrong","danger")
            return;
        }
        dispatch(getNotifsAction());
        dispatch(getCountNotifs());
        onCloseHandler();
        setIsLoading(false);
    }   
  return (
    <ModalHs isNeed={false} footerNone={true}  showModalHS={clearAllModal}>
       <div className='w-100'>
       <div>
            <div>Are you sure? You want to clear all notifications?</div>
        </div>
        <SpacerHS_TWO/>
        <div className='d-flex justify-content-end d-gap-15'>
            <button onClick={onCloseHandler} className='btn-sm hs_borderRadius-4 bg-white text-dark btn btn-secondary'>
                close
            </button>
            <button onClick={onClearHandler} className='btn-sm hs_borderRadius-4 danger btn btn-danger'>
            {isLoading ? <React.Fragment>
                      <div className="spinner-border text-light" role="status">

                      </div> 
                    </React.Fragment> : 'Clear All'}
            </button>
        </div>
       </div>
    </ModalHs>
  )
}


const mapStatesToProps = ({notificationReducer }) => {
    return {
        markAsRead:notificationReducer.markAsRead,
        isAllMark:notificationReducer.selectAllNoti,
        isClose:notificationReducer.showNotfiModal,
        data:notificationReducer.notifs,
        count:notificationReducer.count,
        clearAllModal:notificationReducer.clearAllModal,

    };
  };
  
export default connect(mapStatesToProps)(withRouter(ClearAllModal));
