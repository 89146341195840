import React from 'react';
import OtpInput from 'react-otp-input';
import { Link } from 'react-router-dom';
import InputErrorBackground from '../../components/InputErrorBackground';
import SpacerHS_TWO from '../../Spaces/SpacerHS_TWO';
import { useHistory } from "react-router-dom";
import { postOtpSendService, postResendOTPService } from '../../../../../services/AuthService';
import InputSuccessBackground from '../../components/InputSuccessBackground';

function OTPComponent({otpActive=true,setOtpId,otpId,otpError,userId, backFalse = false,setOTP_Active,otp_loading,setOtpLoading,CompState,onOTPChange,handleSubmit,setServerError,email}) {
    const [sendOtpState,setSendingOtpState] = React.useState(false);
    const [emailMask,setEmailMask] = React.useState('');
    const [successMessage,setSuccessMessage] = React.useState(false);
    const [clearTimeOut,setClearTimeOut] = React.useState(null);

    function handlerGoBack(e){
        setOTP_Active(false);
    }

    React.useEffect(function (){
        if(email != '' && email != undefined){
            let emailVal  = email;
            let emailSplit = emailVal.split('@')[0]
            let letterone = emailSplit[0]
            let letterTwo = emailSplit[emailSplit.length-1];

            let newStr = letterone;    

            for(let i=1;i<emailSplit.length-1;i++){
                newStr = newStr + 'x'
            }

            newStr = newStr + letterTwo + emailVal.split('@')[1];
            setEmailMask(newStr);

        }
    },[email]);

    React.useEffect(function (){

        return () => {
            if(clearTimeOut){
               clearTimeout(clearTimeOut)
            }
        }
    },[]);


    async function reSendOTPHandler(){
       debugger;
        const payload = 
        {"FirstName":"","LastName":"","ContactNumber":"","Email":"","TenantName":"","ContactOrEmail":email,"IsSignup":false,"IsRegisterUser":true,"IsForgotPswd":false,"UnibaseId":userId,"OtpId":otpId,"UserOtp":""}
    
        setOtpLoading(true);
        setSendingOtpState(true);
        const OTPCODE = await postResendOTPService(payload);

        if(!OTPCODE){
            if(setServerError){
                setServerError({
                    isError: true,
                    message: 'Server Error'
                })
            }
            setOtpLoading(false);
            // setSendingOtpState(false);
            return;
        }else{
            setSuccessMessage(true);
            let timer =  setTimeout(function (){
                setSuccessMessage(false);
            },4000)
            setClearTimeOut(timer)
        }


        setOtpId(OTPCODE.data.result);
        setSendingOtpState(false);
        setOtpLoading(false);
    }

    return (
        otpActive && <div className="d-flex justify-content-center align-items-center flex-column ">
         {!backFalse&&   <div className='w-100 text-right d-flex align-items-end justify-content-end'>
            <span  onClick={handlerGoBack} className='font-30 hsBack cursor-pointer text-center d-flex align-items-center'><i className='fa fa fa-arrow-circle-o-left'></i></span>
            </div>}
            <div className='w-100 text-center p-3'>

                <div className=' font-21 font-w600 text-darkgrey'>
                    Enter OTP
                </div>
                <SpacerHS_TWO />
            
                <div className='font-w400 text-darkgrey'>
                We have sent an OTP to <br/> <span className='font-w600 text-darkgrey'>{emailMask}</span>
                </div>
                <SpacerHS_TWO />
                {otpError?.isError && <React.Fragment>
                    <SpacerHS_TWO /> <InputErrorBackground message={otpError.message} />
                    </React.Fragment>}
                    {successMessage && <InputSuccessBackground message="OTP sent successfully"  />}
                <div className='p-3'>
                    {/* <InputBorderDown userOTP={userOTP} setUserOTP={setUserOTP} setOtpLoading={setOtpLoading}  /> */}
                    <OtpInput

                        containerStyle="justify-content-between"
                        className="inputBorderDown mx-2"
                        value={CompState.otp}
                        onChange={onOTPChange}
                        numInputs={6}

                    />

                </div>
                <SpacerHS_TWO />
               
                <div>
                   {!sendOtpState &&  <button onClick={handleSubmit} className='btn-block hs-signin-btn  py-3 hs_borderRadius-4 btn-block border-0'>

                  
                            
                                {(otp_loading) ? <React.Fragment>
                                    <div className="spinner-border text-light" role="status"></div>
                                     <span className='mx-1'>Validate</span>
                                </React.Fragment> : "Validate"}
                            
                        
                       
                    </button>}
                   {sendOtpState && <button className='btn-block hs-signin-btn  py-3 hs_borderRadius-4 btn-block border-0'>
                    {
                            sendOtpState &&  <React.Fragment>
                            <div className="spinner-border text-light" role="status"></div>
                             <span className='mx-1'>Sending OTP</span>
                        </React.Fragment>
                        }
                    </button>}
                </div>
                <SpacerHS_TWO />
              
                <div className=' text-darkgrey cursor-pointer'>
                    <div className='font-12'>Didn't received OTP?</div>
                    <div className='text-primary font-w600'  onClick={reSendOTPHandler}>
                        Resend OTP
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OTPComponent;