import { cloneDeep } from 'lodash';
import React from 'react'
import { connect } from 'react-redux';
import uuid from 'react-uuid';
import { getScriptMarketService, postscriptmarketService } from '../../../../../../../../../services/InstancesServices/InstanceCurrListNewDropdownService';
import { getDetailsScriptService } from '../../../../../../../../../services/InstancesServices/InstancesListService';
import { blockInvalidChar, getTokenFilter } from '../../../../../../../../../utils/Helpers';
import useInputHook from '../../../../../../../../hooks/InputHook/useInputHook';
import useDynamicInputs from '../../../../../../../../hooks/useDynamicInputs/useDynamicInputs';
import FormInputError from '../../../../../../../hireserver/components/FormInputError';
import ModalHs from '../../../../../../../hireserver/ModalHs';
import SpacerHS_TWO from '../../../../../../../hireserver/Spaces/SpacerHS_TWO';
import ScriptMachineAuto from '../../../../../../MarketPlace/Components/ScriptsMarket/autoselectmachine/ScriptMachineAuto';
import { handlerAlert } from '../../../../../InstancesListComponents/DropDownInstancesList';
import CreateScriptModal from './CreateScriptModal'

function CreateScriptModalParent({ show, setShow, getScripts, currDetailsItem }) {
    // to render options
    const [selectMachineTag, setSelectMachineTag] = React.useState([]);
    // to select value
    const [machinetagState, setMachineTagState] = React.useState(null);

    const [autoCompleteMachineError, setAutoCompleteMachineError] = React.useState({
        isError: false,
        message: ''
    });

    const { value: passwordVal, setValue: setPasswordVal, error: passwordError, setError: setPasswordError, resetAll: passwordReset } = useInputHook();
    const { value: portVal, setValue: setPortVal, error: portError, setError: setPortError, resetAll: portReset } = useInputHook();
    const { value: shhUserVal, setValue: setSshUserval, error: shhuserError, setError: setSshUserError, resetAll: sshValReset } = useInputHook();
    const { value: shhIpVal, setValue: setSshIpval, error: shhIpError, setError: setSshIpError, resetAll: sshipvalReset } = useInputHook();

    const { value: nameVal, setValue: setName, error: nameError, setError: setNameError, resetAll: setNameReset } = useInputHook();

    const { value: desVal, setValue: setDes, error: desError, setError: setDesError, resetAll: setDesReset } = useInputHook();

    const [mainLoading, setMainLoading] = React.useState(false);


    const { inputs, setInputs, putValues, updateItem, resetAll } = useDynamicInputs();

    const [currItemData, setCurrItemData] = React.useState(null);

    React.useEffect(function () {
        if (show) {
            getScriptList();
        } else {
            setNameReset();
            setDesReset();
            passwordReset();
            portReset();
            sshValReset();
            sshipvalReset();
            setMachineTagState(null);
            setAutoCompleteMachineError({
                isError: false,
                message: ''
            });
            resetAll();
        }
    }, [show]);

    React.useEffect(function () {
        if (machinetagState) {
            getScriptById(machinetagState.value);
        }
    }, [machinetagState])

    async function getScriptById(scriptId) {
        const res = await getDetailsScriptService(scriptId);
        console.log(res, 'scriptidres');
        const result = JSON.parse(res.data.result)[0];
        console.log(result);
        setCurrItemData(result);
        let tokensMeta = {};
    
        const { script } = result;
        const tokenArr = getTokenFilter(script);
        console.log(tokenArr);
    
        if (Object.keys(tokenArr).length) {
            // tokensMeta = JSON.parse(result.tokens)
            // console.log(tokensMeta);

            let hashObj = {};
            for (let item in tokenArr) {
                let currItem = tokenArr[item];
                hashObj[currItem.name] = {
                    ...currItem,
                    value: currItem.val
                }
            }
            setInputs(hashObj);

            // setInputs(tokenArr);


        }
        // if (Object.keys(result.tokens).length&&result.tokens) {
        //     tokensMeta = JSON.parse(result.tokens)
        //     console.log(tokensMeta);

        //     if (Object.keys(tokensMeta).length  &&tokensMeta.params.length) {
        //     
        //         let tokens = tokensMeta.params;
        //         let hashObj = {};
        //         for (let i = 0; i < tokens.length; i++) {
        //             const { key, value } = tokens[i];
        //             hashObj[key] = {
        //                 key, value, id: uuid()
        //             };
        //         }
        //         setInputs(hashObj);
        //     }
        //     const { sship, sshpassword, sshport, sshuser } = tokensMeta;
        //     setSshIpval(sship);
        //     setSshUserval(sshuser);
        //     setPortVal(sshport)

        // }

    }

    async function getScriptList() {
        const res = await getScriptMarketService();
        if (res.data.result == '') return;

        const dataList = JSON.parse(res.data.result);
        console.log(dataList, 'dataList');

        if (res.data.code != '0') {
            handlerAlert("danger", res.data.message, true);
            return;
        }

        let newArr = [];
        for (let i = 0; i < dataList.length; i++) {

            if (dataList[i].machineid == '0') {
                let val = {
                    value: dataList[i]?.scriptid,
                    label: dataList[i]?.name
                }

                newArr.push(val);

            }

        }

        setSelectMachineTag(newArr)

    }


    function handlerClose() {
        setShow(false);
    }

    function formatInputs(values) {
        let arr = [];
        for (let item in values) {
            arr.push({
                key: item,
                value: values[item].value
            })
        }

        return arr;
    }

    function validScriptForm() {
        let isError = false;
    
        if (!nameVal) {
            setNameError({
                isError: true,
                message: 'Name cannot be empty'
            });
            isError = true;
        }

        if (!desVal) {
            setDesError({
                isError: true,
                message: 'Description cannot be empty'
            });
            isError = true;
        }

        if (!machinetagState) {
            setAutoCompleteMachineError({
                isError: true,
                message: 'Please select the machine'
            });
            isError = true;
        }

        if (!shhIpVal) {
            setSshIpError({
                isError: true,
                message: 'SSH IP cannot be empty'
            });
            isError = true;
        }

        if (!portVal) {
            setPortError({
                isError: true,
                message: 'Port cannot be empty'
            });
            isError = true;
        }

        if (!shhUserVal) {
            setSshUserError({
                isError: true,
                message: 'User cannot be empty'
            });
            isError = true;
        }

        if (!passwordVal) {
            setPasswordError({
                isError: true,
                message: 'Password cannot be empty'
            });
            isError = true;
        }

        let newTokens = cloneDeep(inputs);
        let isChanged = false;
        for (let token in newTokens) {
            let currItem = newTokens[token];
            if (!currItem.value) {
                isChanged = true;
                newTokens[token] = {
                    ...currItem,
                    error: {
                        isError: true,
                        message: `Value cannot be empty`
                    }
                }
            }
        }

        if (isChanged) {
            isError = true;
            setInputs(newTokens);
        }
        return isError;
    }

    async function handlerOpen() {
    
        setMainLoading(true);
        const error = validScriptForm();
        if (error) {
            setMainLoading(false);

            return;
        }


        console.log(currDetailsItem);
        console.log(inputs, 'inputsinputsinputs');
        const { machineid, machinename } = currDetailsItem
        const formattedInputs = formatInputs(inputs);
        let smallTokens = {};
        if (currItemData.tokens) {
            smallTokens = JSON.parse(currItemData.tokens);
        }
        let payload = {
            ...currItemData,
            scriptid: 0,
            description: desVal,
            name: nameVal,
            machineid_machinename: machinename,
            machineid,
            tokens: {
                ...smallTokens,
                params: formattedInputs,
                sshpassword: passwordVal,
                sshport: portVal,
                sshuser: shhUserVal,
                sship: shhIpVal
            }
        }



        try {
            const res = await postscriptmarketService(payload);
            console.log(res);
        } catch (error) {
            setMainLoading(false);
            getScripts();
            handlerClose();
            return;
        }
        setMainLoading(false);

        getScripts();
        handlerClose();
        console.log(payload);
    }

    function onAutoMachineTagChange(e) {
        console.log(e);
        setMachineTagState(e);
        if (autoCompleteMachineError.isError) {
            setAutoCompleteMachineError({
                isError: false,
                message: ''
            })
        }
    }

    function handlerpasswordVal(e) {
        setPasswordVal(e.target.value);
        setPasswordError({
            isError: false,
            message: ""
        })
    }

    function handlerPortVal(e) {

        if (e.which != 8 && e.which != 0 && e.which < 48 || e.which > 57) {
            e.preventDefault();
            return;
        }
        setPortVal(e.target.value);
        setPortError({
            isError: false,
            message: ""
        })
    }

    function handlerShhUserVal(e) {
        setSshUserval(e.target.value);
        setSshUserError({
            isError: false,
            message: ""
        })
    }

    function handlerSshIpChange(e) {
        setSshIpval(e.target.value);
        setSshIpError({
            isError: false,
            message: ""
        })
    }

    function handlerInput(name, e) {
    
        updateItem(name, e.target.value)
    }

    function renderTokens() {
        let inputsHTML = [];
        for (let item in inputs) {
            const { name, value: val, id, error } = inputs[item];
        
            inputsHTML.push(<div className='col-sm-4 pb-2' key={id}>
                <div>
                    {name}
                </div>
                <input className='form-control hs_borderRadius-4' value={val} id={id} onChange={(e) => handlerInput(name, e)} />
                {error.isError ? <FormInputError message={error.message} /> : null}
            </div>);
        }
        console.log(inputsHTML);
        return inputsHTML;
    }

    function handleTempName(e) {
        setName(e.target.value)
        if (nameError.isError) {
            setNameError({
                isError: false,
                message: ""
            })
        }

    }

    function handleTempDes(e) {
        setDes(e.target.value)
        if (desError.isError) {
            setDesError({
                isError: false,
                message: ''
            })
        }
    }


    return (
        <div>
            <ModalHs size="lg" footerNone={true} isNeed={false} showModalHS={show} >
                <div className='w-100'>
                    <div>
                        <div className='row'>
                            <div className='col-sm-4'>
                                <div>Name</div>
                                <input value={nameVal} onChange={handleTempName} className='form-control hs_borderRadius-4' type="text" />
                                {nameError.isError && <FormInputError message={nameError.message} />}
                            </div>
                            <div className='col-sm-8'>
                                <div>Description</div>
                                <input value={desVal} onChange={handleTempDes} className='form-control hs_borderRadius-4' type="text" />
                                {desError.isError && <FormInputError message={desError.message} />}
                            </div>
                        </div>
                        <hr />
                        <div>
                            <div>Script Template List</div>
                            <ScriptMachineAuto values={machinetagState} changeHandler={onAutoMachineTagChange} options={selectMachineTag} />
                            {autoCompleteMachineError.isError && <FormInputError message={autoCompleteMachineError.message} />}
                        </div>


                        <SpacerHS_TWO />
                        <div>
                            <div>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div>SSH</div>
                                        <input onChange={handlerSshIpChange} value={shhIpVal} type="text" className="form-control hs_borderRadius-4" />
                                        {shhIpError.isError && <FormInputError message={shhIpError.message} />}
                                    </div>
                                    <div className='col-sm-6'>
                                        <div>PORT</div>
                                        <input onKeyDown={blockInvalidChar} onChange={handlerPortVal} value={portVal} type="number" className="form-control hs_borderRadius-4" />
                                        {portError.isError && <FormInputError message={portError.message} />}
                                    </div>
                                </div>
                                <SpacerHS_TWO />
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div>User</div>
                                        <input onChange={handlerShhUserVal} value={shhUserVal} type="text" className="form-control hs_borderRadius-4" />
                                        {shhuserError.isError && <FormInputError message={shhuserError.message} />}
                                    </div>
                                    <div className='col-sm-6'>
                                        <div>Password</div>
                                        <input placeholder='Enter your password' value={passwordVal} type="password" onChange={handlerpasswordVal} className="form-control hs_borderRadius-4" />
                                        {passwordError.isError && <FormInputError message={passwordError.message} />}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {Object.keys(inputs).length ?
                            <React.Fragment>
                                <hr />
                                <div>
                                    <div>Tokens</div>

                                    <div className='row'>
                                        {renderTokens()}
                                    </div>
                                </div>
                            </React.Fragment> : <SpacerHS_TWO />
                        }

                    </div>
                    <div className='d-flex justify-content-end d-gap-10'>
                        <button className='btn btn-danger px-3 py-1 hs_borderRadius-4' onClick={handlerClose}>Close</button>
                        <button disabled={mainLoading} className='btn w-120p hs_borderRadius-4 hs-signin-btn py-1' onClick={handlerOpen}>
                            {mainLoading ? <div className="spinner-border  text-light" role="status">

                            </div> : 'Create'}
                        </button>
                    </div>
                </div>
            </ModalHs>
        </div>
    )
}


const mapStateToProps = ({ instanceDetails }) => {
    return {
        currDetailsItem: instanceDetails.currDetailsItem,
    };
};
export default connect(mapStateToProps)(CreateScriptModalParent);
