import React from 'react'

function StartHsIcon({width}) {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width={width}  viewBox="0 0 24.000000 24.000000"
    preserveAspectRatio="xMidYMid meet">
   
   <g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
   fill="#000000" stroke="none">
   <path d="M80 120 c0 -33 3 -60 7 -60 13 0 93 51 93 60 0 9 -80 60 -93 60 -4 0
   -7 -27 -7 -60z"/>
   </g>
   </svg>

   
  )
}

export default StartHsIcon;