import React from 'react'

function StageShowDetails({status,classes,danger}) {
  const [stageBG,setStageBG] = React.useState(null);

  React.useEffect(function (){
    if(danger){
      setStageBG('bg-danger-alt');
    }
    else if(status === "UnAssigned"){
      setStageBG('bg-warning-alt');
    }else{
      setStageBG('bg-warning-alt');
    }
  },[status]);

  return (
    <div>
        <span className={`badge  ${classes} text-dark hs_borderRadius-4 font-12 ${stageBG}`}>{status}</span>
    </div>
  )
}

export default StageShowDetails