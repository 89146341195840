import { ClearAllNotificationURL, createBackupDBURL, createBackupInstanceURL, createInstancesShapShotURL, createMachineInstanceFormURL, deleteInstanceFailURL, getAllScriptBymachineIdURL, getBackupDBListURL, getbackupjobsURL, getBackupListURL, getBareMetalListURL, GetBranchURL, getDetailsScriptURL, getGraphInstancesDetailsURL, getInstanceBizgazeURL, getInstanceDetailsURL, getInstanceLeftOutBizgazeURL, getInstancesDescriptionURL, getInstanceSnapShotURL, getInstancesProxmoxURL, getListInstancesURL, getLogOutputByIdLog_Page_URL, getLogOutputByIdLogURL, getOrderRefIdURL, getScriptOutputByIdURL, getScriptsByParentIdURL, getScriptTableURL, getServersInstancesURL, getStatusByCode, getThresholdsURL, getTimeLineDataURL, getViewOutputMachineORScriptURL, getVmTaskHistory, instancesSnapShotRemoveURL, notifsConfigURL, postExecScriptURL, postInstancesDescriptionURL, postInstanceSnapShotRollBackURL, postSaveCronJobURL, reInstallMachineUrl, twoViewURL } from '../../API_ENDPOINTS/API_ENDPOINTS';
import { getUserCookieAuth } from '../../store/actions/AuthActions';
import { API_CALL, API_SERVICES } from '../API_SERVICES';
import ErrorClass from '../ServiceErrorHandlers/ErrorClass';

export function getSessionAndUserId(){
   let cookieData = getUserCookieAuth();

   if(cookieData == null || cookieData == undefined){
  
    ErrorClass.history.push(0);
    return null;
   }
   let { userId, sessionId } = cookieData;
//    let cookie = JSON.parse(localStorage.getItem('userDetails'));
//    let userId ='',sessionId = 
//     if(cookie?.userId){
//         userId = cookie?.userId;
//     }

//     if(cookie?.sessionId){
//         sessionId = cookie?.sessionId;
//     }
   return { userId, sessionId };
}

export default function getInstancesListService(page,dispatch){
    let  { userId, sessionId } = getSessionAndUserId();
    let pageNum = page?page:1;
    let payload = JSON.stringify({
        "ReportId": 100010820001925,
        "page": pageNum,
        "pageSize":20,
        "Filter": [],
        "FilterId": 0,
        "InputParameters": [],
        "SearchTerm": null,
        "DateFilter": [],
        "DisplayModeTypeId": 1,
        "FromDate": "2022/04/01",
        "ToDate": "2023/03/31",
        "IsPortletWidget": false,
        "IsShowAll": false,
        "SortColumn": ""
    });

    let config = {
        method: 'post',
        url:getBareMetalListURL(),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
        data: payload
    };



     //return API_CALL(config);
   return API_SERVICES(config,dispatch);
}

export function getInstancesListProxmoxService(id,dispatch){
    
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'get',
        url:getInstancesProxmoxURL(id),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
    };
    return API_SERVICES(config,dispatch);
}


export function getInstancesBizgazeService(dispatch){
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'get',
        url:getInstanceBizgazeURL(userId),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
    };
    return API_SERVICES(config,dispatch);
}

export function getOrderRefIdService(orderid){
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'get',
        url:getOrderRefIdURL(orderid),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
    };
    return API_SERVICES(config);
}

export function getAllScriptBymachineService(instanceId){
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'get',
        url:getAllScriptBymachineIdURL(instanceId),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
    };
    return API_SERVICES(config);
}

export function deleteInstanceFailService(orderid){
    
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'post',
        url:deleteInstanceFailURL(orderid,userId),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
    };
    return API_SERVICES(config);
}

export function getInstanceLeftOutBizgazeService(dispatch){
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'get',
        url:getInstanceLeftOutBizgazeURL(userId),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
    };
    return API_SERVICES(config,dispatch);
}
export function postInstancesDescriptionService(data){
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'post',
        url:postInstancesDescriptionURL(),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
        data:{
            ...data,
            userid:userId
        }
    };
    return API_SERVICES(config);
}
export function getInstancesDescriptionService(machineid){
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'get',
        url:getInstancesDescriptionURL(machineid),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
        
    };
    return API_SERVICES(config);
}

export function postCreateBackupService(payload){
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'post',
        url:createBackupDBURL(),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
        data:payload
        
    };
    return API_SERVICES(config);
}



export function getStatusDetailsService(url){
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'get',
        url,
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
    };



    return API_SERVICES(config);
}

export function getServersListInstances(){
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'get',
        url:getServersInstancesURL(),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
    };

    return API_SERVICES(config)
}

export function GetBranchService(clusterid){
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'get',
        url:GetBranchURL(clusterid),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
    };

    return API_SERVICES(config);
}

export function  getInstanceDetailsService(machineId){
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'get',
        url:getInstanceDetailsURL(machineId),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
    };

    return API_SERVICES(config);
}

export function getActivityFeedService(vmid,node,location,since){
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'get',
        url:getVmTaskHistory(vmid,node,location,since),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
    };

    return API_SERVICES(config);
}

export function getGraphInstancesDetailsService(vmid,node,clusterid){
    let  { userId, sessionId } = getSessionAndUserId(vmid,node);
    
    let config = {
        method: 'get',
        url:getGraphInstancesDetailsURL(vmid,node,clusterid),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
    };

    return API_SERVICES(config);
}


export function createBackupInstanceService(loaction,id,payload){
    let  { userId, sessionId } = getSessionAndUserId();
    
    let config = {
        method: 'post',
        url:createBackupInstanceURL(loaction,id),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
        data:payload
    };

    return API_SERVICES(config);
}


export function getInstanceSnapShotService(vmid,node,location){
    let  { userId, sessionId } = getSessionAndUserId();
    
    let config = {
        method: 'get',
        url:getInstanceSnapShotURL(vmid,node,location),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
    };

    return API_SERVICES(config);
}

export function postInstanceSnapShotRollBackService(vmid,node,location,snapname){
    let  { userId, sessionId } = getSessionAndUserId();
    
    let config = {
        method: 'post',
        url:postInstanceSnapShotRollBackURL(vmid,node,location,snapname),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
    };

    return API_SERVICES(config);
}


export function createInstancesSnapShotService(vmid,node,location,payload){
    let  { userId, sessionId } = getSessionAndUserId();
    
    let config = {
        method: 'post',
        url:createInstancesShapShotURL(vmid,node,location),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
        data:payload
    };

    return API_SERVICES(config);
}

export function instancesSnapShotRemoveService(vmid,node,location,snapname){
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'delete',
        url:instancesSnapShotRemoveURL(vmid,node,location,snapname),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
    };

    return API_SERVICES(config);
}


export function getbackupjobsService(location){
    let  { userId, sessionId } = getSessionAndUserId();
    
    let config = {
        method: 'get',
        url:getbackupjobsURL(location),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
    };

    return API_SERVICES(config);
}

export function getBackupListService(location){
    let  { userId, sessionId } = getSessionAndUserId();
    
    let config = {
        method: 'get',
        url:getBackupListURL(location),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
    };

    return API_SERVICES(config);
}
export function getBackupDBListService(machineid){
    let  { userId, sessionId } = getSessionAndUserId();
    
    let config = {
        method: 'get',
        url:getBackupDBListURL(machineid),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
    };

    return API_SERVICES(config);
}

export function postReinstallInstanceService(machineid,osid,mpid){
    let  { userId, sessionId } = getSessionAndUserId();
    
    let config = {
        method: 'post',
        url:reInstallMachineUrl(machineid,osid,mpid),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
    };

    return API_SERVICES(config);
}

export function twoViewService(location){
    let  { userId, sessionId } = getSessionAndUserId();
 
    let config = {
        method: 'post',
        url:twoViewURL(location),
        headers: {
            'Authorization': `Basic ${sessionId}`,
        },
    };

    return API_SERVICES(config);
}

export function getThresholdsService(machineid){
    let  { userId, sessionId } = getSessionAndUserId();
 
    let config = {
        method: 'get',
        url:getThresholdsURL(machineid),
        headers: {
            'Authorization': `Basic ${sessionId}`,
        },
    };

    return API_SERVICES(config);
}


export function postNotifsConfigService(payload){
    let  { userId, sessionId } = getSessionAndUserId();
 
    let config = {
        method: 'post',
        url:notifsConfigURL(),
        headers: {
            'Authorization': `Basic ${sessionId}`,
        },
        data:{
            ...payload,
            contactid:userId
        }
    };

    return API_SERVICES(config);
}

// timeline starts

export function getTimeLineDataService(versionguid,installedappid){
    let  { userId, sessionId } = getSessionAndUserId();
 
    let config = {
        method: 'get',
        url:getTimeLineDataURL(versionguid,installedappid),
        headers: {
            'Authorization': `Basic ${sessionId}`,
        },

    };

    return API_SERVICES(config);
}


// timeline ends

export function getScriptTableService(scriptId){
    let  { userId, sessionId } = getSessionAndUserId();
 
    let config = {
        method: 'get',
        url:getScriptTableURL(userId),
        headers: {
            'Authorization': `Basic ${sessionId}`,
        },

    };

    return API_SERVICES(config);
}


export function getScriptsByParentIdService(templateParentId){
    let  { userId, sessionId } = getSessionAndUserId();
 
    let config = {
        method: 'get',
        url:getScriptsByParentIdURL(templateParentId),
        headers: {
            'Authorization': `Basic ${sessionId}`,
        },

    };

    return API_SERVICES(config);
}

export function getScriptOutputByIdService(logid){
    
    let  { userId, sessionId } = getSessionAndUserId();
 
    let config = {
        method: 'get',
        url:getScriptOutputByIdURL(logid),
        headers: {
            'Authorization': `Basic ${sessionId}`,
        },

    };

    return API_SERVICES(config);
}
export function getLogOutputByIdLogService(logid){
    
    let  { userId, sessionId } = getSessionAndUserId();
 
    let config = {
        method: 'get',
        url:getLogOutputByIdLogURL(logid),
        headers: {
            'Authorization': `Basic ${sessionId}`,
        },

    };

    return API_SERVICES(config);
}

export function getLogOutputByIdLog_Page_Service(logid,row){
    let url = getLogOutputByIdLog_Page_URL(logid,row);

    let  { userId, sessionId } = getSessionAndUserId();
 
    let config = {
        method: 'get',
        url,
        headers: {
            'Authorization': `Basic ${sessionId}`,
        },

    };

    return API_SERVICES(config);

}

export function postSaveCronJobService(scriptId,data){
    let  { userId, sessionId } = getSessionAndUserId();
 
    let config = {
        method: 'post',
        url:postSaveCronJobURL(scriptId),
        headers: {
            'Authorization': `Basic ${sessionId}`,
        },
        data:{
            ...data,'authorization':`Basic ${sessionId}`
        }
    };

    return API_SERVICES(config);
}

export function postExecScriptService(scriptId,data){
    let  { userId, sessionId } = getSessionAndUserId();
 
    let config = {
        method: 'post',
        url:postExecScriptURL(scriptId),
        headers: {
            'Authorization': `Basic ${sessionId}`,
        },
        data:{
            ...data,'authorization':`Basic ${sessionId}`
        }
    };

    return API_SERVICES(config);
}
export function getDetailsScriptService(scriptId){
    let  { userId, sessionId } = getSessionAndUserId();
 
    let config = {
        method: 'get',
        url:getDetailsScriptURL(scriptId),
        headers: {
            'Authorization': `Basic ${sessionId}`,
        },
 
    };

    return API_SERVICES(config);
}


export function getViewOutputMachineORScriptService(pageIndex,scriptid){
    let  { userId, sessionId } = getSessionAndUserId();
 
    let config = {
        method: 'get',
        url:getViewOutputMachineORScriptURL(pageIndex,scriptid),
        headers: {
            'Authorization': `Basic ${sessionId}`,
        },
 
    };

    return API_SERVICES(config);
}

export function ClearAllNotificationService(){
    let  { userId, sessionId } = getSessionAndUserId();
 
    let config = {
        method: 'post',
        url:ClearAllNotificationURL(),
        headers: {
            'Authorization': `Basic ${sessionId}`,
        },
 
    };

    return API_SERVICES(config);
}

