import React from 'react'
import HeadingHS from './controls/HeadingHS'
import SelectCard from './SelectCard'

function DisplayCard({ heading, arr, classNames, cardName,clickHandler }) {
   function HandlerClick(e){
       let name = e.target.getAttribute('data-name');
    clickHandler(name);
    // console.log(name)
   }
    function getDatabaseEg() {
        return arr.map(function (val, i) {
            const { img, name } = val;
            return <SelectCard handler={HandlerClick} dataName={i} cardName={cardName} >
                <div className='w-100 h-100 d-flex flex-column justify-content-between'>
                <img src={img} alt={name} style={{ width: '80px' }} />
                <div className='font-16'>
                    {name}
                </div>
                </div>
            </SelectCard>
        })
    }
    return (
        <div className='pt-2'>
            <HeadingHS title={heading} />
            <div className={classNames}>
                {getDatabaseEg()}
            </div>
        </div>
    )
}

export default React.memo(DisplayCard)
