import React from 'react'
import EmptyDatabase from './DatabaseItems/EmptyDatabase';
import ListDatabases from './DatabaseItems/ListDatabases';
import DatabaseCreateForm from './DatabaseItems/DatabaseCreateForm'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";
import DatabaseContext from './DatabaseContext/DatabaseContext';

export default function Database() {
  let { path } = useRouteMatch();
    const {isDatabases,setDatabases} = React.useContext(DatabaseContext);
    const [emptyDatabaseContent,setEmptyDatabaseContent] = React.useState({
      icon:' fa fa-database',
      svg:<svg viewBox="0 0 76.5 83.5" xmlns="http://www.w3.org/2000/svg"><g><path d="M66.89 48.9c-7.7 2.1-17.7 3.3-28.4 3.3s-20.8-1.2-28.4-3.3c-2.2-.6-4.2-1.3-5.9-2v22c0 5.2 15.3 9.4 34.3 9.4s34.3-4.2 34.3-9.4v-22c-1.8.8-3.7 1.4-5.9 2zm-6.3 19.8c-2.6 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2 4.6-4.6 4.6zM38.49 5.1c-19 0-34.3 4.2-34.3 9.4v25.4c3.6 2.4 14.8 6.1 34.3 6.1 19.4 0 30.7-3.7 34.3-6.1V14.5c-.1-5.2-15.4-9.4-34.3-9.4zm22.1 31.7c-2.6 0-4.6-2.1-4.6-4.6 0-2.6 2.1-4.6 4.6-4.6s4.6 2.1 4.6 4.6c.1 2.5-2 4.6-4.6 4.6zm-22.1-14.3c-20.9 0-32.3-4.9-32.3-7.4s11.4-7.4 32.3-7.4 32.3 4.9 32.3 7.4-11.5 7.4-32.3 7.4z"></path></g></svg>,
      title:'Databases',
      content:[
        {
          text:'Fully managed and highly scalable Database Clusters. Choose your Linide plan, select a database engine, and deploy in minutes.',
          color:'dark'
        },
        {
          text:'Need help getting started? Browse database guide.',
          color:'blue'
        }
      ],
      button:{
        isTrue:true,
        text:'Create Database Cluster'
      }
    });
  
  return (
    <React.Fragment>
    
     <Switch>
        <Route exact path={path}>
        {isDatabases === false ? <EmptyDatabase emptyDatabaseContent={emptyDatabaseContent}/> : <ListDatabases/>}
        </Route>
        <Route exact path={`${path}/create`}>
          <DatabaseCreateForm />
        </Route>
      </Switch>
    </React.Fragment>
  )
}
