import { cloneDeep } from 'lodash';
import React from 'react'
import { profileOrderListService } from '../../../../../../services/Profile/ProfileOrdersServices';
import { handlerAlert } from '../../../Instances/InstancesListComponents/DropDownInstancesList';
import { MDBDataTable } from 'mdbreact';
import { getCurrencySymbol } from '../../../../../../utils/Currency';

import { checkResData } from '../../../../../../utils/Helpers';
import LoaderHS from '../../../../hireserver/components/LoaderHS';
import { getPaymentWalletAmountService, getTransactionWalletService, postconfirmWalletAddedService } from '../../../../../../services/paymentServices/PaymentService';
import { paginationLabel, TABLE_ENTIRES } from '../../../../../../utils/Emuns';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { amountAddToWalletAction_HIDE } from '../../../../../../store/actions/RedirectPageActions/RedirectPageAction';
import CurrencyFC from '../../../../hireserver/components/Currency/CurrencyFC';

function TransactionProfile({ userDetails, isAmountAdded, orderidWallet, getWalletHandler,currencycode, currencyidWallet }) {

  const [loading, setLoading] = React.useState(true);
  const dispatch = useDispatch();
  const [col, setCols] = React.useState([
    {
      label: "Data & Time",
      field: "createddate",
      sort: "asc",

      attributes: { className: " col-3" },
    }, {
      label: "details",
      field: "details",
      sort: "asc",
      attributes: { className: "col-3" },

    },
    {
      label: "Amount",
      field: "amount",
      sort: "asc",
      attributes: { className: "text-center col-3" },

    },
    {
      label: "Closing Balance",
      field: "closingbalance",
      sort: "asc",

      attributes: { className: "text-right col-3" },
    },


  ]);

  const [formatData, setFormatData] = React.useState({
    columns: [],
    rows: []
  })

  React.useEffect(function () {

    getOrdersList();
  }, []);


  React.useEffect(function () {

    if (currencyidWallet) {
      if (isAmountAdded) {
        handlerAddMoreWallet();
       
      }
    }
  }, [currencyidWallet]);





  async function getOrdersList() {
    const res = await getTransactionWalletService();
   
    if (!res.data.result) {
      setLoading(false);
    }

    const isValid = checkResData(res, true);
    if (isValid === false) {
      setLoading(false);
      setFormatData({
        columns: cloneDeep(col),
        rows: []
      });
      return;
    }

    let resData = JSON.parse(res.data.result);



    const parseData = resData
    // console.log(parseData);

    let rowComps = [];

    for (let i = 0; i < parseData.length; i++) {

      const { amount, charges, contactid, createddate, currencyname, payoutrequestid,code, previousbalance, requesttype, updatedbalance, description } = parseData[i];
      debugger;
      let dateandvalue = createddate.split(' ')
      let data = dateandvalue[0];
      let time = dateandvalue[1];
      let rowData = {
        createddate: <div className=''>
          <div className='font-w700'>{data}</div>
          <div>{time}</div>
        </div>,
        details: <div className=''>
          <div className={`${requesttype != 2 ? 'text-success':'text-danger'} `}>
            {description}
          </div>
          <div>
            Transction Id - {code?code:'NA'}
          </div>
        </div>,
        amount: <div className={`text-center ${requesttype != 2 ? 'text-success':'text-danger'}`}>{`${requesttype === 2 ? '-' : '+'}`}<CurrencyFC name={currencycode} val={amount} /></div>,
        search_id:code?code:'',
        closingbalance: <div className='text-right'>
          {/* {`${getCurrencySymbol(currencyname, parseInt(updatedbalance).toFixed(2))}`} */}
          <CurrencyFC name={currencycode} val={updatedbalance} />
        </div>,


      }

      rowComps.push(rowData);
    }

    setFormatData({
      columns: cloneDeep(col),
      rows: cloneDeep(rowComps)
    });
    setLoading(false);

  }

  function onSearchHandler(value) {
    const newData = cloneDeep(formatData);
    setFormatData(newData);
  }

  function onPageChangeHandler({ activePage, pagesAmount }) {
    const newData = cloneDeep(formatData);
    setFormatData(newData);

    // const ls = document.querySelectorAll('.instanceListTable .instacesClass');
    // // console.log(ls, 'ldsa')
  }


  if (loading) {
    return <LoaderHS />
  }

  async function handlerAddMoreWallet() {
    dispatch(amountAddToWalletAction_HIDE());
    let walletDetails = await getPaymentWalletAmountService();
    let isValidWalletDetails = checkResData(walletDetails, true);
    if (!isValidWalletDetails) {
      handlerAlert("danger", "No Data", "danger");
      return;
    }
    const userId = userDetails?.userId

    let dataWalletFinal = JSON.parse(walletDetails.data.result);
    dataWalletFinal = dataWalletFinal[0];
   
    let payload = {
      "reforderid": orderidWallet?.orderidVal,
      "currencytypeid": currencyidWallet,
      "contactid": userId,
      "walletgroupid": dataWalletFinal.walletgroupid
    }



    let postConfirmPaymentRes = await postconfirmWalletAddedService(payload);

    
    getWalletHandler();
   
    if (postConfirmPaymentRes.data.code != '0' || postConfirmPaymentRes.data.errors?.length >= 1) {
      handlerAlert('danger', postConfirmPaymentRes.data.message, 'danger');
    } else {
      handlerAlert('success', postConfirmPaymentRes.data.message);
    }
    // if (postConfirmPaymentRes.data.code != '0' || postConfirmPaymentRes.data.result == null) {
    //   handlerAlert('danger', postConfirmPaymentRes.data.message, 'danger');
    // } else {
    //   handlerAlert('success', 'Your amount is added');
    // }
    setLoading(true);
    getOrdersList();

  }
  return (
    <div className="row">
      <div className="col-xl-12" style={{ position: 'relative' }}>
        <div className="table-responsive">
          <div className="display mb-4 dataTablesCard customer-list-table  instances-table-card">
            <MDBDataTable paging={Boolean(formatData.rows.length>TABLE_ENTIRES)} paginationLabel={paginationLabel}  onSearch={onSearchHandler} disableRetreatAfterSorting={true} onPageChange={onPageChangeHandler} className='instanceListTable' entriesOptions={[1, 5, 20, 25]} entries={10} data={formatData} />


          </div>
        </div>

      </div>
    </div>
  )
}


const mapsStateToProps = ({ PaymentInfoReducer, auth, PaymentRedirectPage }) => {
  return {
    userDetails: auth.auth,
    isAmountAdded: PaymentRedirectPage?.isAmountAdded,
    orderidWallet: PaymentRedirectPage?.orderId,
    currencyidWallet: PaymentInfoReducer?.paymentData?.currencyid,
    currencycode: PaymentInfoReducer?.paymentData?.currencyname,
  }
}

export default connect(mapsStateToProps)(TransactionProfile);
