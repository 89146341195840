import React from 'react';
import { cloneDeep } from 'lodash';
import { MDBDataTable } from 'mdbreact';
import _sortBy from 'lodash/sortBy';
import { TABLE_ENTIRES } from '../../../../utils/Emuns';
import { getFirewallListAction, setFirewallList, startLoadingFirewall, stopLoadingFirewall } from '../../../../store/actions/FirewallActions/FirewallAction';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getBoundRulesService, getFireWallMachinesByIdService } from '../../../../services/FirewallServices/FirewallOverviewService';
import { postdeleteFirewallGroupService } from '../../../../services/FirewallServices/FirewallListService';
import { saveGroupInfoService, saveGroupRulesService } from '../../../../services/FirewallServices/FirewallFormService';
import { handlerAlert } from '../Instances/InstancesListComponents/DropDownInstancesList';
import DeleteModalFirewallListItem from './components/Modals/DeleteModalFirewallListItem';

function FirewallList({data,showFirewallModal,setShowFirewallModal}) {
  const dispatch = useDispatch();
 
  const [col, setCols] = React.useState([
    {
      label: "Name",
      field: "name",
      sort: "asc",
      width: 100,
      attributes: { className: "col-3" },
    },
    {
      label: "Description",
      field: "description",
      sort: "asc",
      width: 100,
      attributes: { className: "col-6" },
    },
    {
      label: "delete",
      field: "delete",
      sort: "asc",
      width: 100,
      attributes: { className: "col-3 text-center" },
    },

   
  ]);


  const [formatData, setFormatData] = React.useState({
    columns: [],
    rows: []
  })
  React.useEffect(function () {
    // '';
     //deleteAll();
     
     
     let rowComps = [];
     for (let i = 0; i < data?.length; i++) {
        const {description,groupname,firewallgroupid} = data[i];
       let newCol = {
        name:<Link  to={`/firewall/${firewallgroupid}`}>{groupname}</Link>,
        nameSearch:groupname,
        description,
        delete:<div onClick={()=>setShowFirewallModal({
          isShow:true,
          firewallId:firewallgroupid,
          groupname:groupname
        })} className='w-100 text-center'><i className='cursor-pointer fa fa-trash'></i></div>
        // delete:<div onClick={()=>deleteFireWallItem(firewallgroupid)} className='w-100 text-center'><i className='cursor-pointer fa fa-trash'></i></div>
       }
       rowComps.push(newCol)
     }
 

     
     setFormatData({
       columns: cloneDeep(col),
       rows: cloneDeep(rowComps)
     });
     
 
   }, [data]);

   async function deleteFireWallItem(firewallgroupid){

    try {
      dispatch(startLoadingFirewall());
      const getMachinesGET = await getFireWallMachinesByIdService(firewallgroupid);
      const getMachineResData = JSON.parse(getMachinesGET.data.result);
      if(getMachineResData?.length!=0){
        let machinesIDs = [];
        for(let i=0;i<getMachineResData.length;i++){
          let machineIDItem = getMachineResData[i]?.machineid
          machinesIDs.push(`${machineIDItem}`);
        }
  
        
  
        const payloadMachine = {
          "groupname": 're',
          "description": 'ss',
          "machinesadded": [],
          "machinesremoved": machinesIDs,
          "firewallgroupid": getMachineResData[0]?.firewallgroupid
      }
      const res = await saveGroupInfoService(payloadMachine);
      }
     
      console.log('deleteFireWallItem',firewallgroupid);
      const boundRulesGet = await getBoundRulesService(firewallgroupid);
      const boundRulesGetResData = JSON.parse(boundRulesGet.data.result);
      if(boundRulesGetResData.length!=0){
        let rulesIds = [];
        for(let i=0;i<boundRulesGetResData.length;i++){
          rulesIds.push(`${boundRulesGetResData[i]?.firewallruleid}`);
        }
  
        const rulesDeleteRes = await saveGroupRulesService({
          "rulesadded":[],
          "rulesremoved":rulesIds
          });
  
          // console.log(rulesDeleteRes);
      } 
  
     
  
  
  
  
  
      const groupDeleteRes = await postdeleteFirewallGroupService(JSON.stringify({
        "groupid":[`${firewallgroupid}`]
      }));
  
      // console.log(groupDeleteRes);
      dispatch(getFirewallListAction())
  
      // console.log(boundRulesGetResData,'boundRulesGet')
    } catch (error) {
      dispatch(stopLoadingFirewall());
      console.log(error);
      handlerAlert("danger","Something went wrong","danger");
    }
   
   }

   function onSearchHandler(value){
    const newData =  cloneDeep(formatData);
    setFormatData(newData);
    // let isChecked = document.querySelector('[data-code=allitems]').checked;
    // if(isChecked){
    //   dispatch(unCheckAllInstances());
    // }
  }

  function handlerSort({ column, direction }) {
    if(column === undefined) return;
    if(column === 'console') return;


    

    let name = 'name';

   

    if(column === 'name'){
      name = 'groupname'
    }else if(column === 'description'){
      name = 'description'
    }

    if (direction == 'asc') {
      setListByNameASC(name);
    } else {
      setListByNameDESC(name);
    }


  }

  function setListByNameASC(sortbyname) {
    let newFirewallList = cloneDeep(data);
    let newa = _sortBy(newFirewallList, [sortbyname]);


    dispatch(setFirewallList(newa))
  }


  function setListByNameDESC(sortbyname){
    let newInstances = cloneDeep(data);
    let newa = _sortBy(newInstances, [sortbyname]);
    
    dispatch(setFirewallList(newa.reverse()))
  }

  function onPageChangeHandler({ activePage, pagesAmount }) {
    const newData =  cloneDeep(formatData);
    setFormatData(newData);
    
    //const ls = document.querySelectorAll('.instanceListTable .instacesClass');
    // console.log(ls, 'ldsa')
  }

  return (
    <React.Fragment>
      <div className="row mainListContainerhs  ">
        <div className="col-xl-12" style={{ position: 'relative' }}>
          <div className="table-responsive">
            <div className="display mb-4 dataTablesCard customer-list-table  instances-table-card">
              {/* <MDBDataTable  disableRetreatAfterSorting={true}  className='instanceListTable'   data={formatData}  /> */}
              <MDBDataTable paging={Boolean(formatData.rows.length>TABLE_ENTIRES)} paginationLabel={['‹','›']} onSearch={onSearchHandler} onSort={handlerSort} disableRetreatAfterSorting={true} onPageChange={onPageChangeHandler} className='instanceListTable' entriesOptions={[1, 5, 20, 25]} entries={TABLE_ENTIRES}  data={formatData} />

            </div>
          </div>

        </div>

      </div>
   
    </React.Fragment>
  )
}

export default FirewallList