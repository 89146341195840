import { CURR_SELECT_ITEM_BAREMETAL,SELECT_ALL_LIST_ITEM_BAREMETAL,CLOSE_STOP_HS_MODAL_BAREMETAL, FINISH_BAREMETAL_LOADING, OPEN_STOP_HS_MODAL_BAREMETAL, SET_BAREMETAL, START_BAREMETAL_LOADING, SHOW_DELETE_CONFIRM_MODAL_BAREMETAL, DELETE_CONFIRM_MODAL_BAREMETAL, REBOOT_HS_HIDEMODAL_BAREMETAL, REBOOT_HS_SHOWMODAL_BAREMETAL, UN_SELECT_ALL_LIST_ITEM_BAREMETAL, SELECT_CURRENT_LIST_ITEM_BAREMETAL, UN_SELECT_CURRENT_LIST_ITEM_BAREMETAL } from "../types/BareMetalTypes";


const initialState = {
    bareMetalList: {
        data: [],
        error: null,
    },
    loading: false,
    showDeleteModal: false,
    currDeleteItem: {
        url: "",
        method: "",
        machineid: "",
        num: "",
        statusId: "",
        machinename: "",
    },
    curSelectItem: {
        url: "",
        method: "",
        machineid: "",
        num: "",
        statusId: "",
        machinename: "",
    },
    selectedListItems: {
        selectAll: false,
        selectItems: {},
    },
    closeStopBareMetalModal: false,
    rebootShowModal: false,
    stopAllModal:{
        name:'Stop All Machines',
        isBool:false,
        namesArr:['vm1','vm2'],
        des:'Are you sure to stop all the Machines?'

    },
    deleteAllModal:{
        name:'Delete All Machines',
        isBool:false,
        namesArr:['vm1','vm2'],
        des:'Are you sure? You want to delete all the Machines??'
    }
};

function BareMetalReducer(state = initialState, action) {
    switch (action.type) {
        case SET_BAREMETAL:
            return {
                ...state,
                bareMetalList: action.payload
            }

        case START_BAREMETAL_LOADING:{
            return {
                ...state,
                loading:true
            }
        }

        case CURR_SELECT_ITEM_BAREMETAL:{
            return {
                ...state,
                curSelectItem:action.payload
            }

        }

        case SELECT_CURRENT_LIST_ITEM_BAREMETAL:{
            return {
                ...state,
                selectedListItems:{
                    selectAll:false,
                    selectItems:action.payload
                }
            }
        }

        case UN_SELECT_CURRENT_LIST_ITEM_BAREMETAL:{
            return {
                ...state,
                selectedListItems:{
                    selectAll:false,
                    selectItems:action.payload
                }
            }
        }

        case SELECT_ALL_LIST_ITEM_BAREMETAL:{
            return {
                ...state,
                selectedListItems:{
                    selectAll:true,
                    selectItems:action.payload
                }
            }
        }

        case UN_SELECT_ALL_LIST_ITEM_BAREMETAL:{
            return {
                ...state,
                selectedListItems:{
                    selectAll:false,
                    selectItems:[]
                }
            }
        }

        case REBOOT_HS_HIDEMODAL_BAREMETAL:{
            return{
                ...state,
                rebootShowModal:false
            }
        }

        case REBOOT_HS_SHOWMODAL_BAREMETAL:{
            return {
                ...state,
                rebootShowModal:true
            }
        }

        case SHOW_DELETE_CONFIRM_MODAL_BAREMETAL:{
            return {
                ...state,
                showDeleteModal:true
            }
        }

        case DELETE_CONFIRM_MODAL_BAREMETAL:{
            return {
                ...state,
                showDeleteModal:false
            }
        }

        case CLOSE_STOP_HS_MODAL_BAREMETAL:{
            return {
                ...state,
                closeStopBareMetalModal:false
            }
        }

        case OPEN_STOP_HS_MODAL_BAREMETAL:{
            return {
                ...state,
                closeStopBareMetalModal:true
            }
        }

        case FINISH_BAREMETAL_LOADING:{
            return {
                ...state,
                loading:false
            }
        }

        default:
            return state;
    }

}




export default BareMetalReducer;
