import React from 'react'
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUserOverviewService } from '../../../services/HomeService/HomeService';
import { getPaymentWalletAmountService } from '../../../services/paymentServices/PaymentService';
import { getDueDateDataService } from '../../../services/Profile/ProfileOrdersServices';
import { getSupportOpenListService } from '../../../services/support/SupportServices';
import { setDashboardDetailsAction } from '../../../store/actions/UserDashboardActions/UserDashboardActions';
import { checkResData } from '../../../utils/Helpers';
import { handlerAlert } from '../../components/Dashboard/Instances/InstancesListComponents/DropDownInstancesList';
import DueDataProfileTab from '../../components/Dashboard/Profile/components/DueDataProfileTab';
import InvoicesTable from '../../components/Dashboard/Profile/components/ProfileOrdersCompoments/InvoicesTable';
import TransactionProfile from '../../components/Dashboard/Profile/components/Transactions/TransactionProfile';
import LoaderHS from '../../components/hireserver/components/LoaderHS';
import SpacerHS_TWO from '../../components/hireserver/Spaces/SpacerHS_TWO';
import DueDataHome from '../../components/Home/duedatetable/DueDataHome';
import MachinesList from '../../components/Home/MachinesList/MachinesList';
import PaymentHomeModal from '../../components/Home/PaymentHomeModal';
import Totalbalance from '../../components/Home/Totalbalance';
import styles from './HomeInstance.module.css';

import emptyContainer from '../../../images/hireserver/imgs/bee/deploy.png';
import EmptyListContainer from '../../components/hireserver/SharedComponents/EmptyListContainer/EmptyListContainer';

function HomeInstances({history}) {


  const [walletValue, setWalletValue] = React.useState(0);
  const [dueTableLoading,setDueTableLoading] = React.useState(true);
  const [emptyListContainerData, setEmptyListContainerData] = React.useState({
    icon: ' fa fa-database',
    img:emptyContainer,
    svg: <svg height="24" viewBox="0 0 21 24" width="21" xmlns="http://www.w3.org/2000/svg"><path d="M9.75 5.238L5.044 7.995l4.706 2.638zm1.5 13.547l4.735-2.787-4.735-2.758zM4.338 9.374v5.245l4.588-2.668zm11.588-1.379L11.25 5.238v5.395zm-10.94 8.003l4.764 2.787V13.21zm11.646-1.38V9.375l-4.588 2.577zm4.339-.569a.8.8 0 00-.897-.66.814.814 0 00-.648.66l.03 2.218-2.824-1.648v.27c0 .48-.235.899-.647 1.138l2.706 1.559-1.823 1.049c-.353.24-.477.723-.236 1.079.144.212.353.36.618.36.147 0 .265-.03.382-.09l2.97-1.739a.783.783 0 00.369-.689zM9.75 18.785v3.117l-1.853-1.109c-.03-.03-.059-.03-.088-.06-.412-.12-.83.148-.941.54-.094.328.03.659.294.839l3 1.768c.117.06.235.12.382.12.147 0 .265-.03.382-.12l2.971-1.768c.03-.03.059-.03.088-.06a.808.808 0 00.383-1.05.78.78 0 00-1.03-.389c-.088.03-.147.09-.206.15l-1.882 1.139v-3.117l-.147.09c-.382.24-.882.24-1.265 0zm6.882-9.411l2.853-1.589-.03 2.188c0 .42.354.78.766.78.411 0 .779-.36.779-.78V6.466a.82.82 0 00-.368-.689S17.515 3.98 17.456 3.95c-.412-.12-.826.149-.941.57-.09.328.059.659.323.838l1.794 1.05-2.735 1.528.059.03c.382.24.647.66.647 1.109v.3zM4.338 14.589l-2.823 1.648.03-2.218a.752.752 0 00-.883-.63c-.323.066-.603.3-.633.63v3.507c0 .27.133.54.368.69l2.97 1.738c.118.06.236.12.383.09.412-.03.765-.39.735-.81 0-.24-.147-.48-.353-.629l-1.794-1.049 2.677-1.558a1.29 1.29 0 01-.647-1.14v-.27zm.706-6.594L2.28 6.466l1.795-1.049a.797.797 0 00.235-.21.783.783 0 00-.206-1.078.7.7 0 00-.794-.03L.515 5.717a.694.694 0 00-.265.18.729.729 0 00-.25.57v3.506c0 .42.368.78.78.78.411 0 .764-.36.764-.78l-.03-2.218 2.854 1.589v-.21c0-.48.235-.9.647-1.139zm6.088-2.817a1.202 1.202 0 00-1.264 0l-.089.06V2.12L7.956 3.229c-.294.3-.794.3-1.088-.03a.786.786 0 01.03-1.108c.06-.059.117-.12.205-.15.03-.03.059-.03.088-.06L10.132.112c.236-.15.53-.15.765 0l2.97 1.769c.354.24.477.723.236 1.079-.144.212-.353.36-.618.36a.583.583 0 01-.382-.12l-1.824-1.08v3.088z" fillRule="evenodd"></path></svg>,
    title: '',
    content: [ {
      text: 'You have 0 machines.',
      color: 'dark'
  },
        {
            text: 'Create you first machine now!',
            color: 'dark'
        }
    ],
    button: {
      isTrue: true,
      text: 'Create',
      isClickHandler:true,
      clickHandler:function (){
        history.push('/instances/create')
      }
  }
   
});

const [showInstances,setShowInstances] = React.useState(false);
  const dispatch = useDispatch();
  React.useEffect(function () {
    getData();
    getWallet();
    getOrdersList();
  }, []);

  const [tableData,setTableData] = React.useState([]);

  async function  getOrdersList(){
    const resData = await getDueDateDataService();
        
    
    const isValid = checkResData(resData, true);
    if (isValid === false) {
      setDueTableLoading(false)
        handlerAlert("danger","Something went wrong","danger");
        return;
    }


    const parseData = JSON.parse(resData.data.result);
    
    setDueTableLoading(false);
    setTableData(parseData);
  }

  async function getWallet() {
    let res = await getPaymentWalletAmountService();
    let isValid = checkResData(res, true)
    if (isValid === false) {
      setWalletValue(0);
      // setLoading(false);
      return;
    }

    let resJSON = JSON.parse(res.data.result)[0];
    if (!resJSON) {
      setWalletValue(0);
      // setLoading(false);
      return;
    }


    setWalletValue(resJSON?.walletvalue);

  }

  


  async function getData() {
    const res = await getUserOverviewService();
    let supportRes = await getSupportOpenListService();
 
    const supportCountData = supportRes.data.result?  JSON.parse(supportRes.data.result) : [];
    debugger;
    if(supportCountData.length===0||supportCountData[0].instancescount===0&&supportCountData[0].baremetalcount === 0){
      setShowInstances(true);
      return;
    }
    const data = JSON.parse(res.data.result);
    console.log(data,'support res');
    dispatch(setDashboardDetailsAction({
      ...data[0],
      support:supportCountData.length
    }));
  }

  if(!dueTableLoading){
    return (
      <React.Fragment>
        <div>
         <div className='row'>
           <div className='col-md-8'>
            {showInstances ? <React.Fragment>
            <MachinesList />
             <div className={`hs-boxshadow hs_borderRadius-4 ${styles.tableDownInvoice}`}>
             {/* <InvoicesTable isNeed={true}/> */}
              {/* <DueDataProfileTab  isNeed={false} /> */}
              <DueDataHome onlyLimit={true} data={tableData} />
             </div>
            </React.Fragment> :<div className='card mb-0 h-100'>
            <EmptyListContainer classes="h-100 m-0" emptyListContainerData={emptyListContainerData} />
              </div>}
           </div>
          {isMobile?<SpacerHS_TWO/>:null}
          <div className='col-md-4'>
          <div>
          <Totalbalance total={walletValue} />
          </div>
          </div>
         </div>
       </div>
       <PaymentHomeModal/>
      </React.Fragment>
     )
  }

  if(dueTableLoading){
    return <LoaderHS/>
  }

  

 
}

export default withRouter(HomeInstances);