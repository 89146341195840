import React from 'react'
import { containsSpecialChars, isLowerCase, isNumberContains, isUpperCase } from '../../../../../utils/Helpers';

function PasswordWrapper({ children,passwordCheckShow, classes,value,onlyLength=false }) {
    const [isLowerCaseState, setIsLowerCaseState] = React.useState(true);
    const [isUpperCaseState, setIsUpperCaseState] = React.useState(true);
    const [isNumberState, setIsNumberState] = React.useState(true);
    const [isLengthMinState, setIsLengthMinState] = React.useState(true);
    const [isSpecailState, setIsSpecailState] = React.useState(true);
   

    function handlerPasswordDotsUpload(handler, name, bool) {
        handler(bool);
    }

    React.useEffect(function(){
        isAllCheckPassword(value)
    },[value]);

    function isAllCheckPassword(value) {
        let specail = containsSpecialChars(value);
        if (specail) {
            handlerPasswordDotsUpload(setIsSpecailState, 'isSpecail', false);
        } else {
            handlerPasswordDotsUpload(setIsSpecailState, 'isSpecail', true);
        }

        let isNumberContainsVar = isNumberContains(value);

        if (isNumberContainsVar) {
            handlerPasswordDotsUpload(setIsNumberState, 'isnumber', false);
        } else {
            handlerPasswordDotsUpload(setIsNumberState, 'isnumber', true);
        }

        let isLowerCaseContains = isLowerCase(value);
        if (isLowerCaseContains) {
            handlerPasswordDotsUpload(setIsLowerCaseState, 'lowercase', false);
        } else {
            handlerPasswordDotsUpload(setIsLowerCaseState, 'lowercase', true);
        }

        let isUpperCaseContians = isUpperCase(value);
        if (isUpperCaseContians) {
            handlerPasswordDotsUpload(setIsUpperCaseState, 'uppercase', false);
        } else {
            handlerPasswordDotsUpload(setIsUpperCaseState, 'uppercase', true);
        }

        let isLength = value.length >= 8;
        if (isLength) {
            handlerPasswordDotsUpload(setIsLengthMinState, 'isLengthMin', false);
        } else {
            handlerPasswordDotsUpload(setIsLengthMinState, 'isLengthMin', true);
        }


    }

    return (
        <div className={`position-relative ${classes}`}>
        {/* <div className="mb-1 font-w600">
          Password
        </div> */}
       {onlyLength===false&&passwordCheckShow && <div className="passwordBoxHS">
          <div className="passwordBoxHSWHiteBox">

          </div>
          <div>
            Use at least
          </div>
          <div>
            <div  className={`${isLowerCaseState ? 'hs_text-light' : 'line-through text-primary'}`}>
            1 lowercase character
            </div>
            <div  className={`${isUpperCaseState ? 'hs_text-light' : 'line-through text-primary'}`}>
            1 uppercase character
            </div>
            <div className={`${isSpecailState ? 'hs_text-light' : 'line-through text-primary'}`}>
            1 special character
            </div>
            <div className={`${isNumberState ? 'hs_text-light' : 'line-through text-primary'}`}>
            1 number
            </div>
            <div  className={`${isLengthMinState ? 'hs_text-light' : 'line-through text-primary'}`}>
            8 characters minimum
            </div>
          </div>
        </div>}
        {onlyLength===true&&passwordCheckShow && <div className="passwordBoxHS passwordBoxHS_len">
          <div className="passwordBoxHSWHiteBox">

          </div>
          <div>
            Use at least
          </div>
          <div>
           
            <div  className={`${isLengthMinState ? 'hs_text-light' : 'line-through text-primary'}`}>
            8 characters minimum
            </div>
          </div>
        </div>}
            {children}
        </div>
    )
}

export default PasswordWrapper