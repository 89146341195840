import React from 'react'
import HeadingHS from '../../../../hireserver/controls/HeadingHS'
import LabelInput from '../../../../hireserver/controls/LabelInput'

function AddAccessControl({ inputValue, setInputValue }) {

  const [ipValues, setIpValues] = React.useState({});
  const [inputHTML, setInputHTML] = React.useState([])

  function handleAddClick(e) {
    let random = genRandom();
    let oldValues = ipValues;
    oldValues[random] = {
      id: random,
      value: ''
    }

    setIpValues(oldValues);
    let oldHTML = [...inputHTML, <HadlerInputComponent removeHandlerInput={removeHandlerInput} key={random} id={random} val={''} handlerInput={handlerInput} />];
    setInputHTML(oldHTML)
  }

  function handlerInput(e) {
    let oldValues = ipValues;
    let id = e.target.getAttribute('id');
    let val = e.target.value;

    oldValues[id] = {
      ...oldValues[id],
      value: val
    }
  }

  function genRandom() {
    return parseInt((Math.random() * (9999 - 99 + 1)) + 99);
  }

  function removeHandlerInput(e) {
    let id = e.target.getAttribute('data-id');
    // console.log("sd")
    let newHTML = [];
    let newValues = [];
    for(let i in ipValues){
      if(ipValues[i].id != id){
        newHTML.push(<HadlerInputComponent key={ipValues[i].id} removeHandlerInput={removeHandlerInput} id={ipValues[i].id} val={ipValues[i].value} handlerInput={handlerInput} />);
        newValues[ipValues[i].id] = ipValues[i];
      }
    }

    setIpValues(newValues);
    setInputHTML(newHTML)
  }

  return (
    <div className='hs_text-light'>
      <HeadingHS as="h3" title="Add Access Control" />
      <div>
        Add any IPv4 address or range tha should be authorized to access this cluster.
      </div>
      <div>
        By default, all public and private connections are denied. Learn more.
      </div>

      <div className='py-3'>
        You can add or modify access controls after your database cluster is active.
      </div>
      <div className='col-lg-4 pl-0'>
        <LabelInput inputValue={inputValue}  setInputValue={setInputValue} heading="Allowed IP Address(es) or Range(s)" id="any" />
        {inputHTML}
      </div>


      <div onClick={handleAddClick} className='text-primary cursor-pointer font-w600'>
        Add An IP
      </div>
    </div>
  )
}

export default React.memo(AddAccessControl);

function HadlerInputComponent({ id, inputValue, handlerInput, removeHandlerInput }) {
  return (<div className='form-group position-relative'>
    <div data-id={id} onClick={removeHandlerInput} className='position-absolute pr-2 pt-2' style={{ top: '0', right: '0' }}>X</div>
    <input
      className='form-control form-control-lg'
      type='text'
      id={id}
      value={inputValue}
      onInput={handlerInput}
      style={{ borderRadius: '4px' }}
    />
  </div>)
}