import React from 'react'

export default function LoaderHS({position}) {
    return (
        <div className='text-center loaderHs'>
            <div>
                <div className={`loaderWaiting  ${position}`}>
                    <ul className="hexagon-container">
                        <li className="hexagon hex_1"></li>
                        <li className="hexagon hex_2"></li>
                        <li className="hexagon hex_3"></li>
                        <li className="hexagon hex_4"></li>
                        <li className="hexagon hex_5"></li>
                        <li className="hexagon hex_6"></li>
                        <li className="hexagon hex_7"></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
