import React from 'react'

function ConsoleViewIcon({width}) {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width={width}  viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet">
   
   <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
   fill="#000000" stroke="none">
   <path d="M470 4744 c-221 -47 -408 -236 -455 -459 -22 -105 -22 -2545 0 -2650
   47 -225 235 -413 460 -460 59 -12 218 -15 978 -15 l907 0 0 -200 0 -200 -520
   0 -520 0 0 -200 0 -200 1240 0 1240 0 0 200 0 200 -520 0 -520 0 0 200 0 200
   908 0 c759 0 918 3 977 15 225 47 413 235 460 460 22 105 22 2545 0 2650 -47
   225 -235 413 -460 460 -102 21 -4075 21 -4175 -1z m4153 -414 c21 -14 50 -43
   65 -64 l27 -39 0 -1267 0 -1267 -27 -39 c-15 -21 -44 -50 -65 -64 l-37 -25
   -2026 0 -2026 0 -37 25 c-21 14 -50 43 -65 64 l-27 39 -3 1245 c-1 826 1 1257
   8 1282 12 46 80 116 124 129 18 6 854 9 2042 8 l2010 -2 37 -25z"/>
   </g>
   </svg>
   
  )
}

export default ConsoleViewIcon