import React, { Fragment, useState } from "react";
// import { Button, Dropdown, Modal } from "react-bootstrap";
import { Link, useRouteMatch } from "react-router-dom";
import RouteCustomTabs from "../../hireserver/Tabs/RouteCustomTabs";
import BillingInfoTab from "./components/BillingInfoTab";
import ProfileTab from "./components/ProfileTab";
import SettingTab from "./components/SettingTab";
import ProfileOrdersTab from "./components/ProfileOrdersCompoments/ProfileOrdersTab";
import { ToastContainer, toast } from "react-toastify";
import TransactionProfile from "./components/Transactions/TransactionProfile";
import DueDataProfileTab from "./components/DueDataProfileTab";
import HeadingHS from "../../hireserver/controls/HeadingHS";
import SpacerHS_TWO from "../../hireserver/Spaces/SpacerHS_TWO";
import { getPaymentWalletAmountService } from "../../../../services/paymentServices/PaymentService";
import { checkResData } from "../../../../utils/Helpers";
import { connect, useDispatch } from "react-redux";
import { getCurrencySymbol } from "../../../../utils/Currency";
import TopDetailsWallet from "./TopDetailsWallet";
import Confirmaddamount from "./confirmaddamount/Confirmaddamount";
import DueAmountTab from "./components/DueAmountTab";
import { openPaymentInfoFormAction } from "../../../../store/actions/PaymentInfoActions/PaymentInfoAction";

// export function handlerAlertProfile(variant, text,type) {
//   const fadeInAndOut = cssTransition({
//       enter: "alertFadein",
//       exit: "alertFadeout"
//   })

//   toast(<ToastCustomAlert_hs variant={variant} text={text} type={type} />, {
//       autoClose: true,
//       className: 'toasterCustomError',
//       bodyClassName: "toasterCustomBodyAlert",
//       closeOnClick: true,
//       transition: fadeInAndOut,
//       position: toast.POSITION.TOP_CENTER,
//       hideProgressBar: true
//   })

// }


const Profile = ({ currencyname, paymentData, username,isShowPayment }) => {
  const [activeToggle, setActiveToggle] = useState("posts");
  const { path } = useRouteMatch();
  const [walletValue, setWalletValue] = React.useState(null);
  const [isOpen,setIsOpen] = React.useState(false);
  const [walletDetails, setWalletDetails] = React.useState(null);
  const [addWalletPaymentValue, setAddWalletPaymentValue] = React.useState(null);
  const dispatch = useDispatch();
  React.useEffect(function () {
    if (isShowPayment == 0) {
      dispatch(openPaymentInfoFormAction());
      return;
    }
    getWallet();
  }, [])

  async function getWallet() {
    let res = await getPaymentWalletAmountService();
    let isValid = checkResData(res, true)
    if (isValid === false) {
      setWalletValue(0);
      // setLoading(false);
      return;
    }

    let resJSON = JSON.parse(res.data.result)[0];
    if(!resJSON){
      setWalletValue(0);
      // setLoading(false);
      return;
    }
    setWalletValue(resJSON?.walletvalue);
    setWalletDetails(resJSON);
  }

  const tabHeaders = React.useMemo(() => {
    return [{
      name: 'Profile',
      link: 'username',
      id: 9999
    },
    //  {
    //   name: 'Billing Info',
    //   link: 'billinginfo',
    //   id: 9991
    // },

      , {
      name: 'Orders',
      link: 'orders',
      id: 9992
    }
      , {
      name: 'Transaction',
      link: 'transaction',
      id: 9997
    }
      , {
      name: 'Renewal',
      link: 'renewal',
      id: 9998
    }
      // },{
      //   name:'Settings',
      //   link:'settings',
      //   id:9993
      // }
    ]
  }, []);
  const tabsContent = React.useMemo(() => {
    return [{
      component: <ProfileTab username={username} />,
      name: 'username',
      id: 1000,
    }, 
    // {
    //   component: <BillingInfoTab />,
    //   name: 'billinginfo',
    //   id: 2000,
    // },
     {
      component: <ProfileOrdersTab />,
      name: 'orders',
      id: 3000,
    }, {
      component: <TransactionProfile getWalletHandler={getWallet} />,
      name: 'transaction',
      id: 4000,        
    }
      , {
      component: <DueAmountTab/> ,
      name: 'renewal',
      id: 5000,
    }
      // },{
      //   component:<SettingTab/>,
      //   name:'settings',
      //   id:4000,
      // }
    ]
  }, []);


  //   const [sendMessage, setSendMessage] = useState(false);

  return (
    <Fragment>
      <div className="d-flex align-items-center">
        {/* <HeadingHS as="h2" title={'Balance'} />
        <span className="h3 px-3 text-primary mb-0">{getCurrencySymbol(currencyname,walletValue)}</span> */}
        <TopDetailsWallet addWalletPaymentValue={addWalletPaymentValue} setAddWalletPaymentValue={setAddWalletPaymentValue} isOpen={isOpen} setIsOpen={setIsOpen} walletDetails={walletDetails} walletValue={walletValue} />
      </div>
      <SpacerHS_TWO />
      <RouteCustomTabs activeTab="profile" parentPath={path} tabHeaders={tabHeaders} tabsContent={tabsContent} />

      <ToastContainer className="toast-container"
        toastClassName="dark-toast"
        autoClose={2000}
        closeButton={false}
      />
      <Confirmaddamount addWalletPaymentValue={addWalletPaymentValue} setAddWalletPaymentValue={setAddWalletPaymentValue} isOpen={isOpen} setIsOpen={setIsOpen} />
    </Fragment>
  );
};


const mapStatesToProps = ({ auth, PaymentInfoReducer }) => {
  return {
    currencyname: PaymentInfoReducer?.paymentData?.currencyname,
    paymentData: PaymentInfoReducer?.paymentData,
    username:PaymentInfoReducer?.paymentData?.organizationname,
    isShowPayment: PaymentInfoReducer?.paymentData?.defaultaddressid,
  }
};
export default connect(mapStatesToProps)(Profile);
