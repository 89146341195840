import React from 'react'
import FormInputError from '../../../hireserver/components/FormInputError'
import HeadingHS from '../../../hireserver/controls/HeadingHS'
import SectionInput from '../../../hireserver/SharedComponents/SectionInput/SectionInput'
import DropdownSelect from './InnerComponents/DropdownSelect'

function IPCFSection({ setPrivateKey, privateKey, privateIpErr }, ref) {
    return (
        <div className='' ref={ref}>
            {/* <SectionInput title="Private IP" secTitle="Private IP" subText="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, fugiat?" inputValue={privateKey} setInputValue={setPrivateKey} /> */}
            <HeadingHS title="Networking" as="h3 text-primary"  />
            <div>
                <DropdownSelect/>
            </div>
            {privateIpErr && <FormInputError message="Please Enter your Private IP" />}
        </div>
    )
}

export default React.forwardRef(IPCFSection)