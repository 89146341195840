import { toast } from "react-toastify";
import { BootVm } from "../../../../../../services/InstancesServices/InstanceCurrListNewDropdownService";
import { getInstanceDetailsService } from "../../../../../../services/InstancesServices/InstancesListService";
import { setDetailInstances } from "../../../../../../store/actions/InstancesActions/InstanceDetailsActions";
import { checkResData } from "../../../../../../utils/Helpers";
import StaticActionAlert from "../../../../../../utils/StaticsClasses.js/StaticActionAlert";
import StaticDetails from "../../../../../../utils/StaticsClasses.js/StaticDetails";
import { handlerAlert } from "../../InstancesListComponents/DropDownInstancesList";

export function preformDetailsAction(url, method, machineId, dispatch) {
    return new Promise(async function (resolve, reject) {
        try {
            StaticDetails.instanceDetails = window.location.href;
            const response = await BootVm(url, method);
         
            if(StaticDetails.instanceDetails !=  window.location.href){
                console.log('returining')
                return;
            }
            const data = await getInstanceDetailsService(machineId);
            if(StaticDetails.instanceDetails !=  window.location.href){
                console.log('returining')
                return;
            }
            const isValid = checkResData(data);
            if (!isValid) {
                return resolve({
                    error: true,
                    data: data
                });
                
            }
            let res = '';
            if (data.data.result != null && data.data.result != '') {
                res = JSON.parse(data.data?.result)[0];
                res['loadingInfo'] = {
                    loading: false,
                    statusId: res.statusid,
                    text: '',
                    color: '',
                    prev: "",
                }
                res['code'] = res.vmid;
                //res['code'] =res.VMID;
                res['machineid'] = res.machineid;
                //res['machinename'] =res['machinename'];
                res['regionname'] = res['branchname'];
                //res['machinetype'] = '2';

                if (res['stagename'] === 'Stopped') {
                    res['statusid'] = 2;
                } else if (res['stagename'] === 'Running') {
                    res['statusid'] = 1;
                } else {
                    res['statusid'] = res.refstatusid;
                }
            }
        
            if(StaticDetails.instanceDetails !=  window.location.href){
                console.log('returining')
                return;
            }
            dispatch(setDetailInstances(res));
            if (response.data.code != '0') {
               return resolve({
                    error: true,
                    data: response
                });
                
            }
           
            StaticDetails.instanceDetails = null;

            if(res?.refstatusid === StaticActionAlert.actionIds[res?.machineid]?.statusId){

                let currItem = StaticActionAlert.actionIds[res?.machineid];
                StaticActionAlert.cleanActionIds(res?.machineid);
                handlerAlert('success',`Your Machine as been ${currItem?.text}`)

            }
            // for(let item in StaticActionAlert.actionIds){
            //     let currItem = StaticActionAlert.actionIds[item];
            //     if(currItem['isTrigger']){
            //         toast.dismiss();
            //         StaticActionAlert.cleanActionIds(item);
            //         handlerAlert('success',`Your Machine as been ${currItem?.text}`)
            //     }
            // }
            return resolve({
                error: false,
                data: data.data.result
            });
          

        } catch (error) {
            resolve({
                error: true,
                data: error
            })
        }


    });
}