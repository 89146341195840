import React from 'react';
import osDefaultImg from '../../../../../../images/os/os1.svg';

function OSInstanceTable({icon,subtitle}) {
    const [iconImg,setIconImg] = React.useState('');
    React.useEffect(function (){
        if(icon){
            let url = `http://192.168.88.99:1002${icon}`.replace('~','')

        setIconImg(url);
        }
       
    },[icon]);
  return (
    <div className='d-flex flex-column justify-content-center align-items-center'>
       <div className='w-30p h-30p'>
       <img className='w-100 h-100' src={iconImg||osDefaultImg} alt="os" />
       </div>
       <div>{subtitle}</div>
    </div>
  )
}

export default OSInstanceTable