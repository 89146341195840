import { getFirewallListService } from "../../../services/FirewallServices/FirewallListService";
import AppError from "../../../services/ServiceErrorHandlers/CustomError";
import { EMPTY_STATE_FIREWALL, SET_FIREWALL_LIST, START_FIREWALL_LOADING, STOP_FIREWALL_LOADING } from "../../types/FirewallTypes/FirewallListTypes";

export function getFirewallListAction(history) {
    return async (dispatch, getState) => {
        try {

            dispatch(startLoadingFirewall());
            const { data } = await getFirewallListService();
            if (data.errors != null) {
                throw new AppError(data.errors[0].message, 999);
            }
            let fullData = [];
            if (data.result != '') {
                fullData = JSON.parse(data.result);
            }
        
            dispatch(setFirewallList(fullData));
          
            dispatch(stopLoadingFirewall());
        } catch (error) {
            
             dispatch(stopLoadingFirewall());
        }
    };
};

export function startLoadingFirewall(){
    return {
        type: START_FIREWALL_LOADING
    }
}

export function stopLoadingFirewall(){
    return{
        type: STOP_FIREWALL_LOADING
    }
}

export function setFirewallList(payload){
    return {
        type: SET_FIREWALL_LIST,
        payload
    }
}

export function clearFirewallState(){
    return{
        type: EMPTY_STATE_FIREWALL
    }
}