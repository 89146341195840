import React from 'react';
import './RadioOptionsTabs.css';

function RadioOptionsTabs({ optionsData, changeHandler, defaultOption, noDefault=false }) {
    const [displayComponent, setDisplayComponent] = React.useState(null);
    const [defaultState, setDefaultState] = React.useState(true);
    const [whichForm, setWhichForm] = React.useState(null);
    function handleChange(e) {
     
        const id = e.target.getAttribute('id');
        setDisplayComponent(optionsData.options[id]?.component)
        setWhichForm(id)
        if (changeHandler) {
            changeHandler();
        }
    }

    React.useEffect(function () {
        //// console.log(optionsData.options[defaultOption]['component'])
        setDisplayComponent(optionsData.options[defaultOption]['component'])
        if(!noDefault){
            setWhichForm(optionsData.options[defaultOption]['id'])
        }
        
    }, [])
    function getOptions() {
        const optionsName = optionsData.options;
        let names = [];
        for (let name in optionsName) {
            names.push(optionsName[name]);
        }
        let isChecked = false;
        if (defaultState) {
            setDefaultState(false);
        }

        let whichFormState = whichForm;
        return <div style={{

        }} className='text-center d-flex justify-content-center'>
            {names.map(function (el, i) {
                const isTrue = whichFormState == el.id
                return <div class="form-check d-flex align-items-center">
                    {/* {
                        isTrue && <div style={{
                            width:'5px',
                            height:'5px',
                            borderRadius:'50px',
                            background:'#37b5ae'
                        }}></div>
                    } */}
                    <label class={`form-check-label position-relative w-150p cursor-pointer font-21 m-0 ${isTrue && 'hs_secondaryColor'} `} htmlFor={`${el.id}`}>

                    <input className='form-check-input radiotabInput removecheckbox ' onChange={handleChange} name={`${optionsData.name}`} checked={isTrue} id={`${el.id}`} type="radio" />

                        <div className='tick_defaultMini_radio'>
                           
                        </div>
                        <div class="tick_Mini_radio text-center"><i class="las la-check pt-1"></i></div>
                       
                        <div className={`font-14 ${isTrue ? '' : ''}`}>{el.name}</div>
                    </label>
                </div>
            })}

        </div>
    }
    return (
        <div className=''>
            {getOptions()}
            {displayComponent && <div className='mt-1 addHeightAnimation'>
                {/* <hr /> */}
                {displayComponent}
            </div>}

        </div>
    )
}

export default RadioOptionsTabs