import React from 'react';
import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider';
import Select, { components } from "react-select";
import { ThemeContext } from '../../../../../context/ThemeContext';



const NoOptionsMessage = props => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className="custom-css-class">{props.noOptions}</span> 
    </components.NoOptionsMessage>
  );
};

function MultiSelectTag({isMulti=true,values,changeHandler,options,noOptions="No Options",isDisabledOptions=false}) {
  const {toggleTheme} = React.useContext(ThemeContext);
  console.log(toggleTheme,'toggleTheme');
  const msgStyles = {
    
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
     
      return {
        
        padding:10,
        
        ':hover': {
          ...styles[':active'],
          backgroundColor:toggleTheme?.includes('light')?'#effcee':'#02081e'
        },
       
      };
    },
  };

  const getOptionLabel = (option) => {
    // Add a classname to the option based on its value
    return (
      <span className={`option-${option.label.toLowerCase().replace(' ', '-')} option-hs-test-select2`}>
        {option.label}
      </span>
    );
  };
 
  return (
   <React.Fragment>
     <Select
        isMulti={isMulti}
        noOptionsMessage={() => noOptions}
        // components={{ NoOptionsMessage }}
        value={values}
        onChange={changeHandler}
        className={`selectClassnameReactive ${isDisabledOptions ? 'disableborder':''}`}
        options={options}
        styles={msgStyles}
        //getOptionLabel={getOptionLabel}
        dropdownCssClassName="hello"
        isDisabled={isDisabledOptions}
        
      />
   </React.Fragment>
  )
}

export default MultiSelectTag