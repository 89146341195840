import React from 'react'
import LoaderHS from '../../../../../hireserver/components/LoaderHS';
import { handlerAlert } from '../../../../Instances/InstancesListComponents/DropDownInstancesList';
import { getScriptMarketService } from '../../../../../../../services/InstancesServices/InstanceCurrListNewDropdownService';
import { CRON_ENABLE, CRON_TIME, TABLE_ENTIRES } from '../../../../../../../utils/Emuns';
import { MDBDataTable } from 'mdbreact';
import { cloneDeep } from 'lodash';
import ToggleCronJobList from './ToggleCronJobList';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { cronToHuman } from '../../../../../../../utils/Helpers';
import ConfirmCron from './ConfirmCron';
import useConfirmCronhook from './hooks/useConfirmCronhook';
import DeleteCronConfirm from './DeleteCronConfirm';


function CronJobList() {
  const { confirmCronModel, setConfirmCronModelHandlerClose, setConfirmCronModelHandlerOpen, confirmCronData } = useConfirmCronhook();
  const [scriptsList, setScriptsList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [cronDeleteShow, setCronDeleteShow] = React.useState(false);
  const [cronData, setCronData] = React.useState(null);

  const [col, setCols] = React.useState([
    {
      label: "Machine Name",
      field: "machinename",
      sort: "asc",
      width: 100,
      attributes: { className: "col-2" },
    },
    {
      label: "Script Name",
      field: "scriptname",
      sort: "asc",
      width: 100,
      attributes: { className: "col-2" },
    },

    {
      label: "Cron",
      field: "cronexp",
      sort: "asc",
      width: 100,
      attributes: { className: "col-2" },
    },
    {
      label: "Next Schedule",
      field: "nextexec",
      sort: "asc",
      width: 100,
      attributes: { className: "col-3 " },
    },
    {
      label: "",
      field: "togglecronjob",
      sort: "asc",
      width: 100,
      attributes: { className: "col-2 " },
    },
    {
      label: "",
      field: "deletecron",
      sort: "asc",
      width: 100,
      attributes: { className: "col-1 " },
    }


  ]);

  const [formatData, setFormatData] = React.useState({
    columns: col,
    rows: []
  })


  // React.useEffect(function () {
  //     getScriptList();
  // }, []);



  React.useEffect(function () {

    const script = document.createElement("script");
    script.src = process.env.PUBLIC_URL + "/later.js";
    script.async = true;
    document.body.appendChild(script);

    if (script.readyState) {  // only required for IE <9
      script.onreadystatechange = function () {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          getScriptList();
        }
      };
    } else {  //Others
      script.onload = function () {
        getScriptList();
      };
    }

  }, []);

  React.useEffect(function () {

    const script = document.createElement("script");
    script.src = process.env.PUBLIC_URL + "/cronstrue.js";
    script.async = true;
    document.body.appendChild(script);

    if (script.readyState) {  // only required for IE <9
      script.onreadystatechange = function () {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          console.log("cronstrue loaded")
        }
      };
    } else {  //Others
      script.onload = function () {
        console.log("cronstrue loaded")
      };
    }

  }, []);

  function cronDeleteHandler(item) {
    setCronDeleteShow(true);
    setCronData(item);
  }

  function getFormattedDate(cronvalue) {
    debugger
    if (cronvalue === 'custom') debugger;
    if (!window['later']) return;

    var s = window['later'].parse.cron(cronvalue);

    // Use local time to ensure that the schedules are based on the client's local timezone.
    window['later'].date.localTime();

    let dateStr = window['later'].schedule(s).next(1);
    let date = new Date(dateStr);

    // Ensure that you're only extracting the time in HH:MM:SS format
    let formattedDate = `${date.toDateString()} ${date.toTimeString().split(' ')[0]}`;

    return formattedDate; // This should now return "Thu Sep 28 2023 08:30:00" if the cronvalue is for 8:30 AM.


  }

  async function getScriptList() {  
    setLoading(true);
    try {

      const res = await getScriptMarketService();
      debugger;
      if (res.data.result == '') return;

      const dataList = JSON.parse(res.data.result);


      if (res.data.code != '0') {
        handlerAlert("danger", res.data.message, true);
        return;
      }

      let newData = [];
      let rowComps = [];
      for (let i = 0; i < dataList.length; i++) {
        let item = dataList[i];
        if (item.parentid != 0) {
          const { tokens } = item;
          if (!tokens) continue;
          let params = JSON.parse(tokens)['params'];

          let isThere = false;
          let cronenble;
          let cronTime;

          // if(i==68) debugger;

          try {
            for (let j = 0; j < params.length; j++) {
              const { key, value } = params[j];
              // if(j==15 && i==68) debugger;
              if (key === CRON_ENABLE) {
                isThere = true;
                newData.push(item);
                cronenble = params[j];

              } else if (CRON_TIME == key) {
                isThere = true;
                cronTime = params[j];
              }
            }
          } catch (err) {
            console.log(err, "errrr");
          }

          if (isThere) {
            if (cronTime?.value == 'custom') debugger;
            let newCol = {
              scriptname: <Link to={`/myscripts/scripts/details/${item?.scriptid}/scripts`}><u className='text-dark font-14'>{item.name}</u></Link>,
              machinename: <div>
                <div className='text-primary'>
                  {item.machinename}
                </div>
                <div>
                  {item.publicip}
                </div>
              </div>,
              search_name: item.name,
              search_machinename: item.machinename,
              cronexp: <div>

                <div>{cronToHuman(cronTime?.value)}</div>
                <div>{cronTime?.value}</div>
              </div>,
              nextexec: <span className='font-14'>{getFormattedDate(cronTime?.value)}</span>,
              togglecronjob: <ToggleCronJobList setModel={setConfirmCronModelHandlerOpen} fullItemDate={item} isEnable={cronenble?.value == 1 ? true : false} />,
              deletecron: <div onClick={() => cronDeleteHandler(item)}>
                <span className='fa fa-trash cursor-pointer'></span>
              </div>
            }
            rowComps.push(newCol)
          }
        }
      }

      setScriptsList(newData);
      setFormatData({
        columns: cloneDeep(col),
        rows: cloneDeep(rowComps)
      });

      console.log(newData, 'dataList');
    } catch (error) {
      handlerAlert("danger", error.message, true);
    } finally {
      setLoading(false);
    }
  }

  function renderList() {
    return scriptsList.map(function (item, i) {
      const { scriptid } = item;
      <div key={scriptid}>
        hellow
      </div>
    })
  }



  function onSearchHandler(value) {
    const newData = cloneDeep(formatData);
    setFormatData(newData);
    console.log(value);
    console.log(newData);
    // let isChecked = document.querySelector('[data-code=allitems]').checked;
    // if(isChecked){
    //   dispatch(unCheckAllInstances());
    // }
  }


  function onPageChangeHandler({ activePage, pagesAmount }) {
    const newData = cloneDeep(formatData);
    setFormatData(newData);

    const ls = document.querySelectorAll('.instanceListTable .instacesClass');
    // console.log(ls, 'ldsa')
  }



  return (
    <React.Fragment>

      {
        loading ? <LoaderHS /> : <React.Fragment>
          <div className="row mainListContainerhs  ">
            <div className="col-xl-12" style={{ position: 'relative' }}>
              <div className="table-responsive">
                <div className="display mb-4 dataTablesCard customer-list-table  instances-table-card">
                  {/* <MDBDataTable  disableRetreatAfterSorting={true}  className='instanceListTable'   data={formatData}  /> */}


                  {/* <MDBDataTable paging={Boolean(formatData.rows.length>TABLE_ENTIRES)}   paginationLabel={['‹', '›']} onSearch={onSearchHandler}  onPageChange={onPageChangeHandler} className='instanceListTable ascCurrClass' entriesOptions={[1, 5, 20, 25]} entries={TABLE_ENTIRES} data={formatData}  /> */}

                  <MDBDataTable onPageChange={onPageChangeHandler} paging={Boolean(formatData.rows.length > TABLE_ENTIRES)} paginationLabel={['‹', '›']} onSearch={onSearchHandler} disableRetreatAfterSorting={true} className='instanceListTable' entriesOptions={[1, 5, 20, 25]} entries={TABLE_ENTIRES} data={formatData} />

                </div>
              </div>

            </div>

          </div>


        </React.Fragment>
      }

      <DeleteCronConfirm callParentList={getScriptList} setData={setCronData} data={cronData} setShow={setCronDeleteShow} show={cronDeleteShow} />
      <ConfirmCron callParentList={getScriptList} confirmCronData={confirmCronData} closeHandler={setConfirmCronModelHandlerClose} show={confirmCronModel} setModel={setConfirmCronModelHandlerOpen} />


    </React.Fragment>

  )
}

export default CronJobList