import React from "react";
import "./SideModalNoRoute.css";

import Fade from 'react-reveal/Fade';
// function ContainerSideModalNoRoute({children}){
//     React.useEffect(function (){
     
//     },[]);
//     return (
//         children
//     )
// }

function SideModalNoRoute({ right=true, children, name,isClose,handlerClose }) {
    
    const [rightModal,setRightModal] = React.useState(0);
    const [closeState,setCloseState] = React.useState(false);
    const timerRef = React.useRef(null);

    React.useEffect(function (){
        if(!isClose){
            setRightModal('-100%')
            setTimeout(()=>{
                setCloseState(false);
            },300);
        }else{
            setRightModal('0')
            setCloseState(true);
        }
        return () => {
            if(timerRef){
                clearTimeout(timerRef.current);
            }
        }
    },[isClose])

    function handlerCloseHelp(){
       if(handlerClose){
        timerRef.current = setTimeout(()=>{
            handlerClose(); 
           },300);
       }

      
    }

    if(!closeState){
        return <React.Fragment></React.Fragment>
    }
  return (
    <Fade   right>
    <div className="sideModalNoRoute" style={{"right":rightModal}}>
         {right && <span className="closeSideModalNoRoute w-50p cursor-pointer closeHsModalRight" onClick={handlerCloseHelp}><span><i className="fa fa-close"></i></span></span>}
         {!right &&  <span className="closeSideModalNoRoute w-50p cursor-pointer closeHsModalLeft" onClick={handlerCloseHelp}><span><i className="fa fa-close"></i></span></span> }
        {children}
      </div>
      </Fade>
  );
}

export default SideModalNoRoute;

// import React, { Children } from 'react';
// import { createPortal } from 'react-dom';
// import './SideModalNoRoute.css';

// function SideModalNoRoute({children,idName,classes}) {

//     const [elementAppend,setElementAppend] = React.useState(null);

//     function createWrapperAndAppendToBody() {
//         const wrapperElement = document.createElement('div');
//         wrapperElement.setAttribute("id", idName);
//         wrapperElement.classList.add(classes);
//         document.body.appendChild(wrapperElement);
//         return wrapperElement;
//     }

//     React.useLayoutEffect(function (){
//         // let wrapperId = idName;
//         let element  = document.querySelector(`#${idName}`);
//         let systemCreated = false;

//         if(!element){
//             systemCreated = true;
//             element = createWrapperAndAppendToBody();

//         }
//         setElementAppend(element);

//         return () => {
//             if (systemCreated && element.parentNode) {
//                 element.parentNode.removeChild(element);
//             }
//         }
//     },[]);

//     if(elementAppend === null) return null;

//     return createPortal(children,elementAppend);
// }

// export default SideModalNoRoute
