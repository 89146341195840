import React from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import OsMarketPlace from "./Components/OsMarketPlace";

const Marketplacedetails = () => {
  return (
    <div>
      {/* <div className="fs-18 font-w600 text-dark-0 pb-4">
        <span className="font-w500 text-black-50">MarketPlace/ </span>
        <span>Create</span>
      </div> */}
      <OsMarketPlace/>
      <div className="running_tabs marketPlace-tabs">
        {/* <Tabs
          defaultActiveKey="distributions"
          id="uncontrolled-tab-example"
          className="mb-3 marketPlace-tabs"
        > */}
          {/* <Tab eventKey="marketplace" title="Marketplace">
            <Marketplacetab />
          </Tab> */}
          {/* <Tab eventKey="distributions" title="Distributions">
            <OsMarketPlace/>
          </Tab> */}
          {/* <Tab eventKey="stackscripts" title="StackScripts">
            <Marketplacestackstrip />
          </Tab>
          <Tab eventKey="images" title="Images">
            <Marketplaceimage />
          </Tab>
          <Tab eventKey="backups" title="Backups">
            <Marketplacebackup />
          </Tab>
          <Tab eventKey="cloneinstances" title="Clone Instances">
            <Marketplaceclone />
          </Tab> */}
        {/* </Tabs> */}
      </div>
    </div>
  );
};

export default Marketplacedetails;
