import React from 'react'
import { Editor } from "@tinymce/tinymce-react";

function EditorDes({handlerChange,defaultValue = "<p>This is the initial content of the editor</p>"}) {

    
    function handleEditorChange(content, editor){
        // console.log("Content was updated:", content);
        handlerChange(content,editor);
    };
    
  return (
    <Editor
        initialValue={defaultValue}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "advlist autolink lists link image code charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | formatselect | table | code | link | image | bold italic backcolor | alignleft aligncenter alignright alignjustify |  \n" +
            "bullist numlist outdent indent | removeformat ",
            content_style: 'body { color: #7e7e7e }'
        }}
        onEditorChange={handleEditorChange}
      />
  )
}

export default EditorDes