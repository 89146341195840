import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import BlocksCard from '../../../hireserver/BlocksCard';
import FormInputError from '../../../hireserver/components/FormInputError';
import MachineLocationsSkeleton from '../../../hireserver/components/Loaders/Machines/MachineLocationsSkeleton';
function TotalAvaCFSection({onChangeBlocksHandler,blockInputError,isMore,setIsMore,setCores, cores,totalCores,blocksInput,setBlocksInput,specs},ref) {
  // console.log(ref,'refrefrefrefrefrefrefrefrefrefrefrefref')

  if(!specs){
    return <MachineLocationsSkeleton times={4} />
  }

  return (
    <Form.Group ref={ref} className="m-0" controlId="formBasicEmail">
    <Form.Text className='d-flex my-1'>
       {/* <HeadingHS as="h3 text-primary hs_borderRadius-4" title="Total Available" />   */}
        </Form.Text>
    <Row>
      <Col className="" xs lg="12">
        <BlocksCard specs={specs} onChangeBlocksHandler={onChangeBlocksHandler} isMore={isMore} setIsMore={setIsMore} setCores={setCores} cores={cores} totalCores={totalCores} blocksInput={blocksInput} setBlocksInput={setBlocksInput} color="#FEF8ED" isAvailable />
      </Col>
    </Row>
    {blockInputError && <FormInputError message="Blocks must be atleast one to create a instance."/>}
  </Form.Group>
  )
}

export default  React.forwardRef(TotalAvaCFSection);