import { SEVERNAME } from "./API_ENDPOINTS";

export function profileOrderListURL(userid){
    return  `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getorders/contactid/${userid}`
}

export function orderRetryURL(reforderid,userid){
    return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/retrypaymentfororder/reforderid/${reforderid}/contactid/${userid}`;
}


export function getUserDetails(email){
    return `${SEVERNAME}/account/getuserbyphoneormail/${email}/${email}`;
}

export function getDocInstanceURL(orderid){
    return `${SEVERNAME}/apis/v4/unibase/platform/templates/generatetemplatepdf/printprovideruniquekey/Bizgaze_Extension_Transact_Transact_PrintProvider_InvoicePrint/pkid/${orderid}`
    // return `${SEVERNAME}/apis/v4/unibase/platform/templates/generatetemplatepdf/printprovideruniquekey/Bizgaze_Extension_Transact_Transact_PrintProvider_InvoicePrint/pkid/${orderid}`
    // return `${SEVERNAME}/apis/v4/bizgaze/transact/orders/getorderhtml/orderid/${orderid}`
    // return `https://test.bizgaze.app:8443/apis/v4/bizgaze/transact/invoices/getprintinvoice/invoiceid/${invoiceno}/isprintpreview/false/ispos/false`;
}

export function getInvoicesProfileURL(userid){
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getinvoices/contactid/${userid}`;
}
export function getOrderMethodURL(userid){
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getactiveorders/contactid/${userid}`;
   
}



export function getDueDateDataURL(userid){
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getduemachine/userid/${userid}`
}