import React from 'react'
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';





import HeadingHS from '../../../../hireserver/controls/HeadingHS';
import NoDataCard from '../../../../hireserver/SharedComponents/NoDataCard';
import SpacerHS_TWO from '../../../../hireserver/Spaces/SpacerHS_TWO';


import { getTenureInstanceaService } from '../../../../../../services/InstancesServices/InstancesFormService';
import { getpaymentInfoService } from '../../../../../../services/Profile/PaymentFormServices';
import { store_tenureData_Action } from '../../../../../../store/actions/InstancesActions/InstancesActions';
import { checkResData } from '../../../../../../utils/Helpers';
import MachineLocationsSkeleton from '../../../../hireserver/components/Loaders/Machines/MachineLocationsSkeleton';
import { handlerAlert } from '../../../Instances/InstancesListComponents/DropDownInstancesList';
import TenureCard from '../../../Instances/components/Tenure/TenureCard';

function Tenure({tenure,setTenure,blocksInput,blockidStage,paymentData,tenureDataStore,setSummaryPrice,summaryPrice}) {
  const [tenureInner,setTenureInner] = React.useState([]);
  const [loading,setLoading] = React.useState(false);
  const [isEmpty,  setIsEmpty] = React.useState(false);
  const [blocks,setBlocks] = React.useState(1);
  const dispatch = useDispatch();

  React.useEffect(function (){
    if(blocksInput === 0 || blocksInput === undefined || blocksInput === null || blocksInput === ''){
      setBlocks(1);
    }else{
      setBlocks(blocksInput);
    }
  
    if(tenureDataStore){
      

      let currPrice = 0;
      let taxPercentage = '';
      
      for(let i=0;i<tenureDataStore.length;i++){
        let currItem = tenureDataStore[i];
        if(currItem['tenuretypename'] == summaryPrice.tenure){
          currPrice = currItem['listprice'];
          taxPercentage = currItem['taxes'];
        }
      }


      setSummaryPrice({
        price:currPrice *blocksInput ,
        tenure:summaryPrice.tenure,
        taxPercentage:taxPercentage
      })
    }


  },[blocksInput]);

  React.useEffect(function (){
  
    if(tenureDataStore){
      setSummaryPrice({
        price:tenureDataStore[0]?.listprice,
        tenure:tenureDataStore[0]?.tenuretypename,
        taxPercentage:tenureDataStore[0]?.taxes
    })
    }
},[tenureDataStore]);


  
  React.useEffect(function (){

  
    if(tenureDataStore === null){
      getTenure();
    }else{
      setTenureInner(tenureDataStore);
      setTenure(tenureDataStore[0]?.tenuretypeid)
    }



    return () => {
      setTenure([]);
    }
  },[blockidStage]);




  async function getTenure(){
    if(blockidStage === null) return;
   let res;
   try {
    let result;
    let paymentInfoNew;
    setLoading(true);
   
    paymentInfoNew = await getpaymentInfoService();

    const PaymentValid = checkResData(paymentInfoNew);
    if(!PaymentValid){
      setLoading(false);
      setIsEmpty(true);
      return;
    }
    const paymentInfoNewJSON = JSON.parse(paymentInfoNew.data.result);
    res =  await getTenureInstanceaService(paymentInfoNewJSON[0]?.currencyid,blockidStage);

   
    if(res.data.result == '' || res.data.result == null){
      setLoading(false);
      setIsEmpty(true);
      handlerAlert("danger",res.data.message,"danger");
      return;
    }
    if(res.data.result != null || res.data.result != ''){
      result = JSON.parse(res.data.result);
     
     
    }
    let options = [];
    if(res.data.errors != null && res.data.errors?.length != 0 && res.data.errors?.length != undefined ){
      handlerAlert("danger",res.data.errors[0],"danger");
      return;
    }

    if(result === null || result.length === 0){
      setIsEmpty(true);
    }

    setTenureInner(result === null ? []  : result);
    setTenure(result[0]?.tenuretypeid);
    // console.log(result,'result Tenure')
    setLoading(false);
    dispatch(store_tenureData_Action(result))

    // console.log(res,'getTenure')
   } catch (error) {
    // console.log(res,'res')
    // console.log(error);
    setLoading(false);


   }
  }

  if(loading){
    return <MachineLocationsSkeleton times={2} />
  }

  // if(isEmpty){
  //   return 'No Tenures';
  // }

  function getTenures(){

    return tenureInner.map(function (el,i){
            
          
      let month = el.tenuretypename;
      
     let taxPercentage = el.taxes;
      // let days = el.noofdays;
      // let monthOrYearly = el.tenuretypename;
      // let month = 0;
       let isChecked = false;
       let isOffer = false;
      // if(monthOrYearly === 'Monthly'){
      //   month = Math.ceil(days/30);
      // }else{
      //   month = Math.floor(days/30);
      // }
      if(tenure == el.tenuretypeid){
        isChecked = true
      }

      if(i==1){
        isOffer = true;
      }
      
      // alert("ds")
     
  
      return <TenureCard taxPercentage={taxPercentage} fullData={tenureInner} setSummaryPrice={setSummaryPrice} isChecked={isChecked} id={el?.tenuretypeid} price={blocks<=0?Number(el.listprice):blocks * Number(el.listprice)} offer={false} month={month} setTenure={setTenure}  />
    })
  }

  return (
    <div>
        <div>
            <HeadingHS title={'Tenure'} as="h3 text-primary" />
        </div>
        <SpacerHS_TWO/>
       {
        isEmpty ? <NoDataCard title="No Tenures"  /> : <div className='d-flex d-gap-10 tenureListContainer'>
        {/* <TenureCard setTenure={setTenure} id="1" price="400"  month={1} />
        <TenureCard setTenure={setTenure} id="2" price="1200" offer={true} month={12} /> */}
        {
          getTenures()
        }
    </div>
       }
    </div>
  )
}

const mapStateToProps = ({PaymentInfoReducer, instances}) => {
  return {
    tenureDataStore: instances.tenureDataStore,
    paymentData:PaymentInfoReducer?.paymentData
  };
};

export default connect(mapStateToProps)(Tenure);