import { cloneDeep } from 'lodash';
import { MDBDataTable } from 'mdbreact'
import React from 'react'
import { getScriptTableService } from '../../../../../../../services/InstancesServices/InstancesListService';
import { paginationLabel, TABLE_ENTIRES } from '../../../../../../../utils/Emuns'
import AreaScriptOutput from '../AreaScriptOutput/AreaScriptOutput';

function ViewoutputTable({ outputList }) {
    // const [outputList,setOutputList] = React.useState([]);
    const [col, setCols] = React.useState([
        {
            label: "Name",
            field: "name",
            sort: "asc",

            attributes: { className: " col-3" },
        },
        {
            label: "Start Time",
            field: "starttime",
            sort: "asc",

            attributes: { className: " col-3" },
        }, {
            label: "End Time",
            field: "endtime",
            sort: "asc",
            attributes: { className: "col-3" },

        },
        {
            label: "output",
            field: "output",
            sort: "asc",
            attributes: { className: "col-3 text-truncate px-0 mx-0 text-center" },

        },
        {
            label: "status",
            field: "status",
            sort: "asc",
            attributes: { className: "col-3 text-truncate, " },

        }



    ]);

    const [formatData, setFormatData] = React.useState({
        columns: col,
        rows: []
    });

    const [showOutModal, setOutModal] = React.useState(false);
    const [fullData, setFullData] = React.useState(null);
    const [output, setoutput] = React.useState('');


    // React.useEffect(function (){
    //     getData();
    // },[]);
    React.useEffect(function () {

        setTableData();
    }, [outputList]);

    // async function getData(){
    //     const res = await getScriptTableService();
    //     const dataRes = JSON.parse(res.data.result);
    //     console.log(dataRes);
    //     setOutputList(dataRes)


    // } 

    function getStatus(item) {
        if (!item) return null;

        let { scriptstatus } = item;
        let text = 'Fail'
        let isSuccess = 'danger'
        if (scriptstatus == '1') {
            text = 'Inprogress'
            isSuccess = 'warning'
        } else if (scriptstatus == '2') {
            text = 'Success';
            isSuccess = 'success'
        }
        return <div className='d-flex align-items-center d-gap-10'>
            <div className={`bg-${isSuccess}`} style={{
                width: '10px',
                height: '10px',
                borderRadius: '25px'
            }}></div>
            <span>  {text}</span>
        </div>

    }

    function setTableData() {
        if(!outputList){
            return;
        }
        const rowComps = [];

        for (let i = 0; i < outputList.length; i++) {

            const { scriptid, name, starttime, endtime, output, scriptstatus } = outputList[i];

            let rowData = {
                name: name,
                search_name: name,
                starttime: starttime,
                endtime: scriptstatus != 1 ? endtime : '',
                output: scriptstatus  ? <div onClick={() => openOutputModal(output, outputList[i])} className=' cursor-pointer text-center'><u className='text-primary text-center'>View</u></div> : '',
                status: getStatus(outputList[i])


            }
            // let rowData = {
            //     starttime: starttime,
            //     endtime: scriptstatus != 1 ? endtime : '',
            //     output: scriptstatus != 1 ? <div onClick={() => openOutputModal(output, outputList[i])} className=' cursor-pointer'><u className='text-primary'>View</u></div> : '',
            //     status: getStatus(innerList[i])


            // }

            rowComps.push(rowData);
        }

        setFormatData({
            columns: cloneDeep(col),
            rows: cloneDeep(rowComps)
        });
    }


    function onSearchHandler(value) {
        const newData = cloneDeep(formatData);
        setFormatData(newData);
    }

    function onPageChangeHandler({ activePage, pagesAmount }) {
        const newData = cloneDeep(formatData);
        setFormatData(newData);

        // const ls = document.querySelectorAll('.instanceListTable .instacesClass');
        // // console.log(ls, 'ldsa')
    }

    function openOutputModal(o, full) {
        debugger;
        setoutput(o);
        setFullData(full);
        setOutModal(true);
    }

    function closeOutputModal() {
        setOutModal(false);

    }

    return (
        <React.Fragment>
            <div>
                <h5 className='text-primary'>Scripts Logs</h5>
            </div>
            <div className='scriptAreatable display mb-4 dataTablesCard customer-list-table  instances-table-card'>
                <MDBDataTable paging={Boolean(5)} paginationLabel={paginationLabel} disableRetreatAfterSorting={true} className='instanceListTable' entriesOptions={[1, 5, 20, 25]} entries={TABLE_ENTIRES} data={formatData} />
                {/* <MDBDataTable paging={Boolean(5)} paginationLabel={paginationLabel} disableRetreatAfterSorting={true} className='instanceListTable' entriesOptions={[1, 5, 20, 25]} entries={TABLE_ENTIRES} data={formatData} /> */}
            </div>
            <AreaScriptOutput  fullData={fullData} output={output} handleOutputClose={closeOutputModal} showOutModal={showOutModal} />
        </React.Fragment>
    )
}

export default ViewoutputTable