import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { ToastContainer, toast, cssTransition } from 'react-toastify'
import { finishLoadingInstances, getIntancesList, openHsModal, openHS_rebootModal, putCurrentInstanceDelete, showDeleteConfirmModal, startLoadingInstaces } from '../../../../../store/actions/InstancesActions/InstancesActions';
import CustomDropDown from '../../../hireserver/components/CustomDropDown';
import { instanceReinstallShowModal, openAllModal, putCurrSelectItem, show_deleteAllMachinesModal, update_all_status_start, VM_ActionCall, VM_ActionStarted, VM_CALL_ACTION_START_ALL } from '../../../../../store/actions/InstancesActions/InstanceCurrItemAction';
import { deleteInstanceFailURL, instancesActionURL, twodeleteURL, twoRebootURL, twoStartURL, twoStopURL } from '../../../../../API_ENDPOINTS/API_ENDPOINTS';
import { ToastCustomAlert_hs } from '../../../hireserver/ToastCustomAlert_hs';
import { Delete_VM, REBOOT_VM, START_VM, STOP_VM } from '../../../../../store/types/GobalTypes';
import { DELETE_STAGE, FAILED_STAGE, ONHOLD_STAGE, REBOOT_STAGE, REINSTALL_STAGE, START_STAGE, STOP_STAGE } from '../../../../../utils/Emuns';
import StaticActionAlert from '../../../../../utils/StaticsClasses.js/StaticActionAlert';
import StopHsIcon from '../../../hireserver/icons/StopHsIcon';
import RebootHsIcon from '../../../hireserver/icons/RebootHsIcon';
import ReInstallIcon from '../../../hireserver/icons/ReInstallIcon';
import DeleteHsIcon from '../../../hireserver/icons/DeleteHsIcon';
import StartHsIcon from '../../../hireserver/icons/StartHsIcon';
import { setDetailInstances } from '../../../../../store/actions/InstancesActions/InstanceDetailsActions';
import { callListRenewalToggle, openModalRenewalPayment } from '../../../../../store/reducers/RenewalPaymentReducer/RenewalPaymentReducer';
import SuspendIcon from '../../../hireserver/icons/SuspendIcon';



export function handlerAlert(variant, text, type) {
    const fadeInAndOut = cssTransition({
        enter: "alertFadein",
        exit: "alertFadeout"
    })

    toast(<ToastCustomAlert_hs variant={variant} text={text} type={type} />, {
        autoClose: true,
        className: 'toasterCustomError',
        bodyClassName: "toasterCustomBodyAlert",
        closeOnClick: true,
        transition: fadeInAndOut,
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true
    })

}

function DropDownInstancesList({ disabledClass = '', isSelectAll, machineid, nodeType, itemData, code, curSelectItem, statusid, machinetype, machinename, selectItems, getInstances }) {

    const dispatch = useDispatch();
    const [actionMachineData, setActionMachineData] = React.useState(
        [
            {
                title: 'Start', handler: (e) => startHandler(e, statusid), dataTags: { 'data-machineaction': START_STAGE }, Icon: <StartHsIcon width="15px" />
            },
            { title: 'Stop', handler: (e) => startHandler(e, statusid), dataTags: { 'data-machineaction': STOP_STAGE }, Icon: <StopHsIcon width="15px" /> },
            { title: 'Reboot', handler: (e) => startHandler(e, statusid), dataTags: { 'data-machineaction': REBOOT_STAGE }, Icon: <RebootHsIcon width="15px" /> },
            { title: 'Delete', handler: (e) => startHandler(e, statusid), dataTags: { 'data-machineaction': DELETE_STAGE }, Icon: <DeleteHsIcon width="15px" /> }, { title: 'ReInstall', handler: (e) => startHandler(e, statusid), dataTags: { 'data-machineaction': REINSTALL_STAGE }, Icon: <ReInstallIcon width="15px" /> }]);
    const [actionItemState, setActionItemState] = React.useState([]);

    React.useEffect(function () {


        if (statusid != FAILED_STAGE && statusid != ONHOLD_STAGE) {

            let newItems = [];
            for (let i = 0; i < actionMachineData.length; i++) {
                let currItem = actionMachineData[i];
                if (currItem.dataTags['data-machineaction'] != statusid) {

                    currItem['handler'] = (e) => startHandler(e, statusid);
                    newItems.push(currItem);
                }
            }

            setActionItemState(newItems)
        } else if (statusid == ONHOLD_STAGE) {
            let newItems = [{
                title: 'Renewal', handler: (e) => startHandler(e, statusid), dataTags: { 'data-machineaction': ONHOLD_STAGE }, Icon: <SuspendIcon width="15px" />
            },];
            setActionItemState(newItems)
        } else {
            let newItems = [];
            if (itemData?.isPendingTable) {
                // alert("hello")


                for (let i = 0; i < actionMachineData.length; i++) {
                    let currItem = actionMachineData[i];
                    if (currItem.dataTags['data-machineaction'] == DELETE_STAGE) {

                        currItem['handler'] = (e) => startHandler(e, statusid);
                        newItems.push(currItem);
                    }
                }
            } else {
                if (machineid == 0 || machineid == undefined) {
                    for (let i = 0; i < actionMachineData.length; i++) {
                        let currItem = actionMachineData[i];
                        if (currItem.dataTags['data-machineaction'] == DELETE_STAGE) {

                            currItem['handler'] = (e) => startHandler(e, statusid);
                            newItems.push(currItem);
                        }
                    }
                } else {
                    for (let i = 0; i < actionMachineData.length; i++) {
                        let currItem = actionMachineData[i];
                        if (currItem.dataTags['data-machineaction'] == REINSTALL_STAGE || currItem.dataTags['data-machineaction'] == DELETE_STAGE) {

                            currItem['handler'] = (e) => startHandler(e, statusid);
                            newItems.push(currItem);
                        }
                    }
                }

            }



            setActionItemState(newItems)
        }
    }, [statusid, selectItems]);

    // React.useEffect(function () {
    //     let newItems = [];
    //     for (let i = 0; i < actionMachineData.length; i++) {
    //         let currItem = actionMachineData[i];
    //         if (currItem.title === "ReInstall") {
    //             if (!isSelectAll) {
    //                 if (currItem.dataTags['data-machineaction'] != statusid) {

    //                     currItem['handler'] = (e) => startHandler(e, statusid);
    //                     newItems.push(currItem);
    //                 }
    //             }
    //         } else {
    //             if (currItem.dataTags['data-machineaction'] != statusid) {

    //                 currItem['handler'] = (e) => startHandler(e, statusid);
    //                 newItems.push(currItem);
    //             }
    //         }
    //     }

    //     setActionItemState(newItems)
    // }, [isSelectAll])


    function callMultiActionVM_Check(num) {
        // console.log(num, 'num')
        // 
        if (num === START_STAGE) {
            for (let item in selectItems) {
                const { statusid } = selectItems[item];
                if (statusid == 1) {
                    handlerAlert('danger', "Please Stop all the machines before starting", "danger")
                    return false;
                }
            }
        } else if (num == STOP_STAGE) {
            for (let item in selectItems) {
                const { statusid } = selectItems[item];
                if (statusid == 2) {
                    handlerAlert('danger', "Please Start all the machines before Stop", "danger")
                    return false;
                }
            }
        } else if (num == REBOOT_STAGE) {
            for (let item in selectItems) {
                const { statusid } = selectItems[item];
                if (statusid != 1) {
                    handlerAlert('danger', "Please Start all the machines before Rebooting", "danger")
                    return false;
                }
            }
        } else if (num == DELETE_STAGE) {
            for (let item in selectItems) {
                const { statusid } = selectItems[item];
                if (statusid != 2) {
                    handlerAlert('danger', "Please Stop all the machines before Deleting", "danger")
                    return false;
                }
            }
        } else if (num == REINSTALL_STAGE) {
            for (let item in selectItems) {
                const { statusid } = selectItems[item];
                if (statusid != 2) {
                    handlerAlert('danger', "Please Stop all the machines before Deleting", "danger")
                    return false;
                }
            }
        }
        return true;
    }

    // function callMultiActionVM(selectItemsList, actionNumber) {
    //     dispatch(startLoadingInstaces());
    //     // console.log('2');
    //     if (actionNumber == START_VM) {
    //         dispatch(startAllInstancesVM(selectItemsList, START_VM, handlerAlert))
    //     } else if (actionNumber == STOP_VM) {
    //         dispatch(stopAllInstancesVM(selectItemsList, STOP_VM, handlerAlert))
    //     } else if (actionNumber == Delete_VM) {
    //         // dispatch(finishLoadingInstances());
    //         dispatch(deleteAllInstancesVM(selectItemsList, Delete_VM, handlerAlert))
    //         // dispatch(showDeleteConfirmModal());
    //         // dispatch(putCurrentInstanceDelete({
    //         //     url:twodeleteURL(code),
    //         //     method:'delete',
    //         //     machineid:machineid,
    //         //     num:3,
    //         //     statusid:statusid,
    //         //     machinename:machinename
    //         // }))
    //     } else {
    //         dispatch(rebootAllInstancesVM(selectItemsList, 4, handlerAlert))
    //     }

    // }



    async function startHandler(e, statusC) {
        // // console.log("dasdsss")
        debugger;
        let machineAction = parseInt(e.target.getAttribute('data-machineaction'));


        // 
        if (Object.keys(selectItems).length) {
            // const isTrueVM = callMultiActionVM_Check(machineAction);
            const isTrueVM = true;
            if (!isTrueVM) return;

            if (machineAction == 1) {
                dispatch(update_all_status_start(selectItems, 1));
                dispatch(VM_CALL_ACTION_START_ALL(selectItems));

                return;
            }

            if (machineAction == 2) {
                dispatch(openAllModal({
                    name: 'Stop All Machines',
                    isBool: true,
                    namesArr: ['vm1', 'vm2'],
                    des: 'Are you sure to stop all the Machines?'
                }));
                return;
            }

            if (machineAction == 4) {
                dispatch(show_deleteAllMachinesModal());
                return;
            }



            return;
        }

        if (machineAction === 0 || machineAction === NaN) {
            return;
        }

        if (machineAction == ONHOLD_STAGE) {

            dispatch(callListRenewalToggle())
            dispatch(setDetailInstances(itemData))
            dispatch(openModalRenewalPayment());
            return;
        }


        if (machineAction == REINSTALL_STAGE) {
            let startObj = {
                url: instancesActionURL(itemData['machineid'], REINSTALL_STAGE),
                method: 'post',
                machineid: machineid,
                num: REINSTALL_STAGE,
                statusid: REINSTALL_STAGE,
                machinename: machinename,
                nodeType,
                itemData,

            }
            dispatch(putCurrSelectItem(startObj))
            dispatch(instanceReinstallShowModal());
            return;
        }

        //set loading true
        //dispatch(startLoadingInstaces());

        // console.log(itemData,'test------------itemData')
        if (machineAction === START_STAGE) {
            let startObj = {
                url: instancesActionURL(machineid, START_STAGE),
                // url: twoStartURL(code,itemData['hostname'],itemData['branchid']),
                method: 'post',
                machineid: machineid,
                num: START_STAGE,
                statusid: START_STAGE,
                machinename: machinename,
                nodeType,
                itemData
            }
            StaticActionAlert.actionIds[machineid] = {
                statusId: START_STAGE,
                isTrigger: false,
                text: 'started',
                touched: false
            };
            dispatch(putCurrSelectItem(startObj))
            dispatch(VM_ActionStarted(startObj));
            //dispatch(getIntancesList());

            dispatch(
                VM_ActionCall(
                    instancesActionURL(machineid, START_STAGE),
                    nodeType,
                    "post",
                    machineid,
                    START_STAGE,
                    statusC,
                    handlerAlert,
                    startObj
                )
            );
        } else if (machineAction === STOP_STAGE) {
            // dispatch(VM_ActionCall(twoStopURL(code), 'post', machineid, 2, statusC,handlerAlert))

            dispatch(putCurrSelectItem({
                url: instancesActionURL(machineid, STOP_STAGE),
                method: 'post',
                machineid: machineid,
                num: STOP_STAGE,
                statusid: STOP_STAGE,
                machinename: machinename,
                nodeType,
                itemData
            }))
            // dispatch(putCurrSelectItem({
            //     url: twoStopURL(code,itemData['hostname'],itemData['branchid']),
            //     method: 'post',
            //     machineid: machineid,
            //     num: STOP_VM,
            //     statusid: STOP_VM,
            //     machinename: machinename,
            //     nodeType,
            //     itemData
            // }))
            dispatch(openHsModal());
            // dispatch(finishLoadingInstances());

        } else if (machineAction === REBOOT_STAGE) {
            if (statusid === STOP_STAGE) {
                handlerAlert("danger", "Start the Machine before Rebooting it.", "danger");
                return;
            }
            let rebootObj = {
                url: instancesActionURL(machineid, REBOOT_STAGE),
                method: 'post',
                machineid: machineid,
                num: REBOOT_STAGE,
                statusid: REBOOT_STAGE,
                machinename: machinename,
                nodeType,
                itemData
            }
            dispatch(putCurrSelectItem(rebootObj));
            dispatch(openHS_rebootModal());

            // let rebootObj = {
            //     url: twoRebootURL(code,itemData['branchid']),
            //     method: 'post',
            //     machineid: machineid,
            //     num: START_VM,
            //     statusid: START_VM,
            //     machinename: machinename,
            //     nodeType,
            //     itemData
            // }
            // if (statusC != 1) {
            //     const fadeInAndOut = cssTransition({
            //         enter: "alertFadein",
            //         exit: "alertFadeout"
            //     })

            //     toast(<ToastCustomAlert_hs variant="danger" text="Start the Machine Before Reboot" />, {
            //         autoClose: true,
            //         className: 'toasterCustomError',
            //         bodyClassName: "toasterCustomBodyAlert",
            //         closeOnClick: true,
            //         transition: fadeInAndOut,
            //         position: toast.POSITION.TOP_CENTER,
            //         hideProgressBar: true
            //     })

            //     dispatch(finishLoadingInstances());
            //     return;
            // }



            // dispatch(VM_ActionStarted(rebootObj));
            // dispatch(VM_ActionCall(twoRebootURL(code), 'post', machineid, 4, statusid, handlerAlert, rebootObj))
            //dispatch(VM_ActionCall(twoRebootURL(code), 'post', machineid, 4, statusid, handlerAlert, curSelectItem))
        } else if (machineAction === DELETE_STAGE) {
            
            if (statusC == START_STAGE) {
                // console.log('Please stop the Machine');
                const fadeInAndOut = cssTransition({
                    enter: "alertFadein",
                    exit: "alertFadeout"
                })

                toast(<ToastCustomAlert_hs variant="danger" type="delete" text="Stop the Machine Before Delete" />, {
                    autoClose: true,
                    className: 'toasterCustomError',
                    bodyClassName: "toasterCustomBodyAlert",
                    closeOnClick: true,
                    transition: fadeInAndOut,
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                })

                //dispatch(finishLoadingInstances());
                return;
            }
            //dispatch(finishLoadingInstances());
            debugger;

            let deleteURL = instancesActionURL(machineid, DELETE_STAGE);
            if (!machineid) {
                deleteURL = deleteInstanceFailURL(itemData['orders_orderid'])
            }
            dispatch(putCurrSelectItem({
                url: deleteURL,
                method: 'post',
                machineid: machineid,
                num: DELETE_STAGE,
                statusid: DELETE_STAGE,
                machinename: machinename,
                nodeType,
                itemData
            }))
            dispatch(showDeleteConfirmModal());
            // dispatch(putCurrSelectItem({
            //     url: twodeleteURL(itemData['hostname'],code,itemData['branchid']),
            //     method: 'delete',
            //     machineid: machineid,
            //     num: 4,
            //     statusid: 4,
            //     machinename: machinename,
            //     nodeType,
            //     itemData
            // }))


            // dispatch(VM_ActionCall(twodeleteURL(code), 'delete', machineid, 4, statusid))
        }
    }

    return (
        <CustomDropDown classNameForDom={`instacesClass ${disabledClass} listDropdownHS data-class-${code}`} statusid={null} items={actionItemState} >
            <Dropdown.Toggle variant className="table-dropdown i-false" >
                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" >
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                    </g>
                </svg>
            </Dropdown.Toggle>
        </CustomDropDown>
    )
}


const mapStateToProps = (state) => {
    return {
        selectItems: state.instances.selectedListItems.selectItems,
        curSelectItem: state.instances.curSelectItem,
        isSelectAll: state.instances.selectedListItems.selectAll

    };
};

export default connect(mapStateToProps)(DropDownInstancesList);