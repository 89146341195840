import React from 'react'
import { getDetailsScriptService, getScriptsByParentIdService } from '../../../../../../../services/InstancesServices/InstancesListService';
import { MDBDataTable } from 'mdbreact';
import { TABLE_ENTIRES } from '../../../../../../../utils/Emuns';
import { Link, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import ExecScriptCardItem from '../ExecScriptCardItem';
import NoDataCard from '../../../../../hireserver/SharedComponents/NoDataCard';
import ScriptMarketContext from '../ScriptMarketContext/ScriptMarketContext';
import BackButton from '../../../../../hireserver/BackButton';
import beetry from './../../../../../../../images/hireserver/imgs/bee/list.png'
import LoaderHS from '../../../../../hireserver/components/LoaderHS';
import SaveAndExecModal from '../SaveAndExecModal/SaveAndExecModal';
import ButtonHS from '../../../../../hireserver/controls/ButtonHS';

function TemplateScripts({templateid}) {
  const { setEditScript, handleDeleteOpen, ScriptExec,dataList, setDataList,saveAndExec } = React.useContext(ScriptMarketContext);
  let { path, url,  params: { id }} = useRouteMatch();
  
  const [col, setCols] = React.useState([
    {
      label: "Name",
      field: "name",
      sort: "asc",
      width: 100,
      attributes: { className: "col-2" },
    },
    {
      label: "Machine",
      field: "machine",
      sort: "asc",
      width: 100,
      attributes: { className: "col-2" },
    },
    {
      label: "Description",
      field: "description",
      sort: "asc",
      width: 100,
      attributes: { className: "col-3" },
    },
    {
      label: "Created Date",
      field: "createddate",
      sort: "asc",
      width: 100,
      attributes: { className: "col-2" },
    },
    {
      label: "",
      field: "action",
      sort: "asc",
      width: 100,
      attributes: { className: "col-2" },
    },

    {
      label: "",
      field: "delete",
      sort: "asc",
      width: 100,
      attributes: { className: "col-1" },
    },
   
    

  ]);


  const [formatData, setFormatData] = React.useState({
    columns: [],
    rows: []
  });

  const [justLoading,setJustLoading] = React.useState(true);

  const [templateSummary,setTemplateSummary] = React.useState({});

  const [tempData,setTempData] = React.useState({});
  

  React.useEffect(()=>{
    setTimeout(()=>{
      setJustLoading(false);
    },500);
    
  },[]);


  React.useEffect(function(){
    debugger;
    if(!dataList.length) return;
  

    let newRows = [];
    for (let i = 0; i < dataList.length; i++) {
      const { createddate, description, name,scriptid,publicip,machinename } = dataList[i];


      let newRow = {

        action:<div className='text-right'> <button onClick={()=>ScriptExec(dataList[i],true)} className='btn btn-sm hs-signin-btn hs_borderRadius-4 p-0 px-1'>Execute</button></div>,
        description: <div title={`${description}`} style={{
          width:'300px'
        }}  className='text-truncate'>{description}</div>,
        machine:<div>
          <div>{machinename}</div>
          <div className='hs-lightgrey'>{publicip}</div>
        </div>,
        name:
          <Link to={`/myscripts/scripts/details/${scriptid}/scripts`}>
          <h4>{name}</h4>
    
        </Link>,
        delete: <div className="text-right cursor-pointer" onClick={() => handleDeleteOpen(dataList[i])}>
        <i className="fa fa-trash"></i>
    </div>,
        createddate:createddate,
        hiddenname:name,
        hiddenmachinename:machinename,
        hiddenpublicip:publicip

      }
      newRows.push(newRow);

    }
    if (newRows.length <= 0) {
      setFormatData({
        columns: col,
        rows: []
      });

      setTemplateSummary({});
     
      return;
    }

    setFormatData({
      columns: col,
      rows: newRows
    });

    return () => {
      setJustLoading(false);
      setFormatData({
        columns: [],
        rows: []
      });
    }

  },[dataList]);

    async function getTemplateScriptsDate(templateid){
        const res = await getScriptsByParentIdService(templateid);
        if(!res.data.result) return;

        let parseDate = JSON.parse(res.data.result);
        //console.log(parseDate,"dsskadsjk");
        setDataList(parseDate)

        
    }

    async function getTemplateSummary(templateid){
      const res = await getDetailsScriptService(templateid);
      if(!res.data.result) return;
      let resData = JSON.parse(res.data.result)[0];
      setTempData(resData);
      setTemplateSummary({
        name:resData?.name,
        description:resData?.description

      })
    //  console.log(resData);

    }

    React.useEffect(function (){
     //   console.log(id);
        //debugger;
        if(id){
            getTemplateScriptsDate(id)
            getTemplateSummary(id);
        }
    },[]);

    if(justLoading){
      return <LoaderHS/>
    }

  return (
    <div>
       {/* <ul className='row'>
            {dataList.length > 0 ? dataList.map(function (val, i) {
              return <ExecScriptCardItem urlLink={`scripts/details/${val.scriptid}/scripts`} link={true} execIsNeed={true} key={val.scriptid} val={val} />
            }) : <NoDataCard title="No scripts" />}
          </ul> */}
      <div className='d-flex '>
      <BackButton/>
      <div className='text-primary'>{templateSummary?.name}</div>
      </div>
        <hr />
        <div className='pb-3'>
        Description: {templateSummary?.description}
        </div>
          {
            formatData.rows.length >= 1 ?
            <React.Fragment>
              <div className="row mainListContainerhs  ">
              <div className="col-xl-12" style={{ position: 'relative' }}>
                <div className="table-responsive">
                  <div className="display mb-4 dataTablesCard  customer-list-table  instances-table-card">

                    <MDBDataTable noRecordsFoundLabel="No record" paging={Boolean(formatData.rows.length > TABLE_ENTIRES)} paginationLabel={['‹', '›']} disableRetreatAfterSorting={true} className='instanceListTable' entriesOptions={[1, 5, 20, 25]} entries={TABLE_ENTIRES} data={formatData} options={{
                      textLabels: {
                        body: {
                          noMatch: "Sorry, no matching records found",
                        }
                      }
                    }} />

                  </div>
                </div>

              </div>
            </div>
            </React.Fragment>
            :<div className='d-flex flex-column align-items-center justify-content-center'>
               <div className='w-200p text-center'>
               <img className='w-100 h-100' src={beetry} alt="Something went wrong" />
               </div> 
               <div>
               Zero script
              </div>
             <div>
          
              <ButtonHS handler={() => saveAndExec(tempData)}>
              Click here to create script 🐱‍👤
              </ButtonHS>
           
             </div>
             
              </div>
          }

{/* <SaveAndExecModal/> */}
    </div>
  )
}

export default TemplateScripts