import React from 'react'
import { connect } from 'react-redux';
import DOMPurify from 'dompurify'
import { getInstancesDescriptionService, postInstancesDescriptionService } from '../../../../../../../../services/InstancesServices/InstancesListService';
import EditorDes from '../../../../../../hireserver/components/Editor/EditorDes'
import ButtonHS from '../../../../../../hireserver/controls/ButtonHS';
import SpacerHS_TWO from '../../../../../../hireserver/Spaces/SpacerHS_TWO';
import { handlerAlert } from '../../../../InstancesListComponents/DropDownInstancesList';
import { checkResData } from '../../../../../../../../utils/Helpers';



function EditorDesInstance({ currDetailsItem, userId }) {

    const [des, setDes] = React.useState('');
    const [defaultDes, setDefaultDes] = React.useState('');
    const [isEditMode, setEditMode] = React.useState(false);
    const [isEmpty, setIsEmpty] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);

    const containerDesRef = React.useRef(null);

    React.useEffect(function () {
        getDefaultDes();

    }, [currDetailsItem]);

    async function getDefaultDes() {



        const id = currDetailsItem?.machineid;
        if (!id) return;
        const res = await getInstancesDescriptionService(currDetailsItem?.machineid)
        const resJson = JSON.parse(res.data.result);

        if (resJson[0]?.description != '' && resJson[0]?.description != null) {
            setDefaultDes(resJson[0]?.description)

            // containerDesRef.current.danger
            containerDesRef.current.innerHTML = `${DOMPurify.sanitize(resJson[0]?.description)}`;
        } else {
            debugger
            setIsEmpty(true);
            setDefaultDes(null);
            containerDesRef.current.innerHTML = '';
        }

    }

    function handleEditorChange(content) {

        setDes(content)
    }

    // const sanitizedData = () => ({
    //     __html: DOMPurify.sanitize(des)
    // })


    async function handlerClick(e) {
        try {
            debugger;
            setLoading(true);
            const isCheckBackEnable = await getInstancesDescriptionService(currDetailsItem?.machineid);

            const isValid = checkResData(isCheckBackEnable);
            if (!isValid) {
                //   setLoading(false);
                //   setbackups(true);
                setLoading(false);
                handlerAlert("danger", "something went wrong", "danger");
                return;
            }
            debugger;
            const dataCheck = JSON.parse(isCheckBackEnable.data.result)[0];
            if (!Boolean(dataCheck)) {
                //   setLoading(false);
                //   setbackups(true);
                setLoading(false);
                handlerAlert("danger", "something went wrong", "danger");
                return
            }
            const isEnableBackup = dataCheck.isbackupenabled;
            if (!isEnableBackup) {
                handlerAlert("success", "Backup is not enable!");
                setLoading(false);
                return;
            }

            const payload = {
                "machineId": currDetailsItem?.machineid,
                "description": des,

                "isbackupenabled": isEnableBackup,
                "userid": userId,

            }
            const res = await postInstancesDescriptionService(payload);

            if (res.data.code != '0') {
                handlerAlert("danger", res.data.message, 'danger');
                setLoading(false);
                return;
            }

            getDefaultDes();
            setEditMode(false);
        } catch (error) {
            handlerAlert("danger", "Something went wrong", "danger");
        } finally {
            setLoading(false);
        }

    }

    function editHandler() {
        setDes(defaultDes)
        setEditMode(true);
    }

    return (

        <React.Fragment>
            {!isEmpty && Boolean(isEditMode) && <div className='d-flex flex-column flex-column-reverse'>
                <div className='d-flex justify-content-end  d-gap-10'>
                    <ButtonHS bgPrimary={false} handler={() => {

                        if (defaultDes) {
                            setEditMode(false)
                        } else {
                            setEditMode(false);
                            setIsEmpty(true);
                        }
                    }} classes="bg-danger btn cursor-pointer hs_borderRadius-4 w-120p py-2 px-2 font-13 text-white">
                        Close
                    </ButtonHS>
                    <ButtonHS handler={handlerClick} classes="w-120p py-2 px-2 font-13">
                        {isLoading ? <React.Fragment>
                            <div className="spinner-border text-light" role="status">

                            </div>
                        </React.Fragment> : 'Save'}
                    </ButtonHS>
                </div>
                <SpacerHS_TWO />
                <EditorDes defaultValue={defaultDes} handlerChange={handleEditorChange} />

                {/* <div className='w-100 d-flex justify-content-end'>

                <ButtonHS handler={handlerClick} >
                    Submit
                </ButtonHS>
            </div> */}

            </div>}

            {
                isEmpty && Boolean(!isEditMode) && <div className='d-flex flex-column flex-column-reverse'>
                    <div>
                        No description.
                    </div>
                    <div className='d-flex justify-content-end'>
                        <ButtonHS handler={() => {
                            setIsEmpty(false)
                            setEditMode(true);
                        }} classes="w-120p py-2 px-2 font-13">
                            Add
                        </ButtonHS>
                    </div>
                </div>
            }


            <div className={!(!isEmpty && !isEditMode) ? 'd-none' : 'd-flex     flex-column flex-column-reverse justify-content-end'}>
                <div className='editDesButton' onClick={editHandler}>
                    <span>
                        <i className='fa fa-pencil'></i>
                    </span>
                </div>
                <div className='d-flex justify-content-end'>
                    <ButtonHS handler={editHandler} classes="w-120p py-2 px-2 font-13">
                        Edit
                    </ButtonHS>
                </div>
                <div ref={containerDesRef}></div>
            </div>


        </React.Fragment>
    )
}

const mapStateToProps = ({ instanceDetails, auth }) => {
    return {
        currDetailsItem: instanceDetails.currDetailsItem,
        userId: auth.auth.userId
    };
};
export default connect(mapStateToProps)(EditorDesInstance);