import React from 'react';
import { blockInvalidChar } from '../../../../../../../../utils/Helpers';
import FormInputError from '../../../../../../hireserver/components/FormInputError';
import './ThresholdsNotfi.css';

function ThresholdsNotfi({handleChange,name,des,onInputHandler,inputSide,inputTop,dataname,inputVal,isChecked,error,errorMessage}) {
  return (
    <li className="row border-bottom pt-2">
                <div className="col-lg-6 d-flex mb-2">
                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div className="align-items-center row m-0">
                      <div className="p-0">
                        <label className="switch">
                          <input checked={isChecked} data-type={dataname} onChange={handleChange} type="checkbox"></input>
                          <span className="slider"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="pl-3">
                    <p className="font-16 font-w600">{name}</p>
                    <p className="threshold-content">
                      {des}
                    </p>
                    {error&& <div>
                    <FormInputError message={errorMessage}  />
                </div>}
                  </div>
                </div>
                <div className="col-lg-6 setting-thres">
                  <p className="font-14 font-w500">{inputTop}</p>
                  <div className="d-flex justify-content-between  w-200p position-relative px-2">
                    {/* <p>90</p>
                    <p>%</p> */}
                    <input disabled={!isChecked}  onKeyDown={blockInvalidChar} onInput={onInputHandler} value={inputVal}  data-type={dataname} className={`${!isChecked && 'bg-light'} form-control pr-4 hs_borderRadius-4`} type="number" />

                    <div className='rightAbsCenter'>
                        {inputSide}
                    </div>
                  </div>
                </div>
               
              </li>
  )
}

export default ThresholdsNotfi