import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function ShowPasswordInputHS({value,copyTrue=true}) {
    const [type,setType] = React.useState('password');
    const [click,setClicked] = React.useState(false);
    const [show,setShow] = React.useState(false);

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Copied!
        </Tooltip>
      );

    function toggleHandler(){
        if(type === 'password'){
            setType('text');
        }else{
            setType('password')
        }
    }

    function copyHandler(){
      
        navigator.clipboard.writeText(value);
        setShow(true);
        setTimeout(function(){
          setShow(false)
        },300);
    }

  return (
    <div className='d-flex'>
        <input className='text-right text-right w-100' style={{
            background: 'transparent',
            border: 'none',
          
        }} type={type} disabled value={value} />
        <div className='d-flex hs_text-light'>
            <span onClick={toggleHandler} className='px-2'><i className={`fa  text-black-50 ${type == 'password' ? 'fa-eye-slash':'fa-eye text-primary'}`}></i></span>
            <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
      show={show}
    >
      <span onClick={copyHandler} className='px-1'> <i className="fa fa-copy"></i></span>
    </OverlayTrigger>
            {/* <span onClick={copyHandler} className='px-1'> <i className="fa fa-copy"></i></span> */}
        </div>
    </div>
  )
}

export default ShowPasswordInputHS