
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  withRouter,
} from "react-router-dom";
// import EmptyDatabase from '../Database/DatabaseItems/EmptyDatabase';

import FirewallList from "./FirewallList";
import EmptyListContainer from "../../hireserver/SharedComponents/EmptyListContainer/EmptyListContainer";

import FirewallForm from "./components/FirewallForm/FirewallForm";
import { connect, useDispatch } from "react-redux";
import { openPaymentInfoFormAction } from "../../../../store/actions/PaymentInfoActions/PaymentInfoAction";
import CreateFireWallContext from "./components/FirewallForm/FirewallContext/CreateFireWallContext";
import listBee from '../../../../images/hireserver/imgs/bee/list.png';
import LoaderHS from "../../hireserver/components/LoaderHS";
import { getFirewallListAction } from "../../../../store/actions/FirewallActions/FirewallAction";
import FirewallOverview from "./components/FirewallOverview/FirewallOverview";
import { ToastContainer } from "react-toastify";
import { isMobile } from "react-device-detect";
import FirewallCardView from "./FirewallCard/FirewallCardView";
import DeleteModalFirewallListItem from "./components/Modals/DeleteModalFirewallListItem";


function FireWall({isShowPayment,history,firewallLoading,firewallListData}) {
  const dispatch = useDispatch();
  let { path, url } = useRouteMatch();
  const [hs_modal, setHsModal] = React.useState(true);

  const [showFirewallModal,setShowFirewallModal] = React.useState({
    isShow:false,
    firewallId:null,
    groupname:null
  });

  function handleClick(e) {
    setHsModal(true);
  }
  const [isImages, setImages] = React.useState([]);

  const [emptyFirewallContent, setEmptyDatabaseContent] = React.useState({
    icon: "fa fa-database",
    img:listBee,
    svg: (
      <svg viewBox="0 0 215 215" xmlns="http://www.w3.org/2000/svg">
        <path d="M214.84 136.33a31.58 31.58 0 00-28.76-28.18A51.33 51.33 0 0099.79 59.5v26.29h33.27v28.18h33.26v35.43H26v-28.18H10.75a39 39 0 0035.88 49.54H186.5a31.37 31.37 0 0028.34-34.43zM66.5 93.04h59.3v20.92H66.5zM33.26 64.86H92.5v20.92H33.26zM0 93.04h59.27v20.92H0zm33.26 28.18H92.5v20.92H33.26zm66.53 0h59.27v20.92H99.79z"></path>
      </svg>
    ),
    title: "Firewall",
    content: [
      {
        text: "Fully managed and highly scalable Firewall.",
        color: "dark",
      },
     
    ],
    button: {
      isTrue: true,
      text: "Create Firewall",
      isClickHandler:true,
      clickHandler:function (){
        handlerClickRoute()
      }
      
    },
  });
  function handlerClickRoute(){
    //alert("hello")

    if(isShowPayment == 0){
      dispatch(openPaymentInfoFormAction());
      return;
    }
  
    history.push('/firewall/create')
  }

  React.useEffect(function (){
    dispatch(getFirewallListAction())
  },[]);


  // <Emptyfirewall
  //   hs_modal={hs_modal}
  //   setHsModal={setHsModal}
  //   emptyFirewallContent={emptyFirewallContent}
  // />;
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={path}>
          {firewallLoading ? <LoaderHS/> :<React.Fragment>
          {firewallListData.length === 0 ? (
            <div className="position-relative">
              <EmptyListContainer
                emptyListContainerData={emptyFirewallContent}
              />
              <Route exact path={`${path}/create`}>
                {/* <SideModalHS backToRouteName="/firewall">
                  <SideBarModalFirewall/>
                </SideModalHS> */}
                
                 <FirewallList handleClick={handleClick} />
              </Route>
            </div>
          ) : (
           <React.Fragment>
            {isMobile?<FirewallCardView showFirewallModal={showFirewallModal} setShowFirewallModal={setShowFirewallModal}  />: <FirewallList showFirewallModal={showFirewallModal} setShowFirewallModal={setShowFirewallModal} data={firewallListData} handleClick={handleClick} />}
           </React.Fragment>
          )}
          </React.Fragment> }
         
        </Route>
        <Route exact path={`${path}/create`}>
            <CreateFireWallContext>
              <FirewallForm/>
            </CreateFireWallContext>
        </Route>
        <Route exact path={`${path}/:firewallId`}>
            <CreateFireWallContext>
              {/* <FirewallForm/> */}
              <FirewallOverview/>
            </CreateFireWallContext>
        </Route>
      </Switch>
      <ToastContainer className="toast-container"
                toastClassName="dark-toast"
                autoClose={3000}
                closeButton={false}
            />
               <DeleteModalFirewallListItem showFirewallModal={showFirewallModal} setShowFirewallModal={setShowFirewallModal} />
    </React.Fragment>
  );
}

const mapStateToProps = ({fireWallListReducer,PaymentInfoReducer}) => {
  return {
     isShowPayment:PaymentInfoReducer?.paymentData?.defaultaddressid,
     firewallListData:fireWallListReducer.firewallList,
     firewallLoading:fireWallListReducer.loading
  };
};
export default connect(mapStateToProps)(withRouter(FireWall));
{
  /* <React.Fragment>
<Switch>
  <Route exact path={path}>
    {isImages === false ? (
      <div className="position-relative">
        <EmptyListContainer
          emptyListContainerData={emptyFirewallContent}
        />
        {hs_modal && (
          <Offcanvas
            title={"Create Firewall"}
            setHsModal={setHsModal}
            form={<Createform setHsModal={setHsModal} />}
          />
        )}
      </div>
    ) : (
      <FirewallList handleClick={handleClick} />
    )}
  </Route>
  <Route exact path={`${path}/create`}></Route>
</Switch>
</React.Fragment> */
}
