import React, { Children } from "react";

import { withRouter } from "react-router-dom";
import {
  createMachineInstanceBizgaze,
  createMachineInstanceFormNEWURL,
  getAssestsURL,
  getListInstancesURL,
  getSEVERNAME,
  twoStartURL,
} from "../../../../../API_ENDPOINTS/API_ENDPOINTS";
import { MINUS_BTN, PLUS_BTN } from "../../../hireserver/BlocksCard";
import { toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InstancesFormContext from "./InstancesFormContext";
import { ToastCustomAlert_hs } from "../../../hireserver/ToastCustomAlert_hs";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getIntancesList,
  toggleCreatingMachine,
} from "../../../../../store/actions/InstancesActions/InstancesActions";
import {
  getAvailableInstanceFormLocations,
  getBlockIdService,

  getDatabasesService,
  getInstanceFormLocations,
  getInstancesOSList,
  getMarketPlacesService,

} from "../../../../../services/InstancesServices/InstancesFormService";
import { getUserCookieAuth } from "../../../../../store/actions/AuthActions";




import debianImg from '../../../../../images/hireserver/imgs/debian.png';

import _ from 'lodash';
import { handlerAlert } from "../InstancesListComponents/DropDownInstancesList";
import { Instances_SSH_Service } from "../../../../../services/SSH/InstanceSHHServies";

import { checkResData, containsSpecialChars, containsSpecialCharsExceptDotAndDash, hasWhiteSpace, isValidVMName } from "../../../../../utils/Helpers";
import { openPaymentInfoFormAction } from "../../../../../store/actions/PaymentInfoActions/PaymentInfoAction";
import StaticInstanceForm from "../../../../../utils/StaticsClasses.js/StaticsInstances";
import { putDeloyReducerAction, putSummaryInfoModal, showDeloyModalAction } from "../../../../../store/reducers/DeployReducer";
import { CREATE_STAGE } from "../../../../../utils/Emuns";



function InstancesCreateFormContext(props) {
  const dispatch = useDispatch();

  const [deployLoading, setDeployLoading] = React.useState(false);
  const [sshkeyHash, setSshkeyHash] = React.useState('');
  const [selectTagOption, setSelectTagOption] = React.useState([
    { value: "one", label: "One" },
    { value: "two", label: "Two" },
    { value: "dummy", label: "dummy" },
  ]);
  const [SShkey, setSShKey] = React.useState("");
  const [privateKey, setPrivateKey] = React.useState("nnull");

  const [osList, setOslist] = React.useState([]);
  const [rootPassword, setRootPassword] = React.useState("");
  const [codeCommandState, setCodeCommandState] = React.useState('');
  //const [rootPassword, setRootPassword] = React.useState("");
  const [rootPasswordAgain, setRootPasswordAgain] = React.useState("");
  //const [rootPasswordAgain, setRootPasswordAgain] = React.useState("");
  const [users, setUsers] = React.useState([]);
  const [cores, setCores] = React.useState(28);
  const [createInstanceName, setCreateInstanceName] = React.useState("");
  const [locationState, setLocationState] = React.useState(0);
  const [locations, setLocations] = React.useState([]);
  const [statusLoading, setStatusLoading] = React.useState(false);
  const [createMachineShow, setCreateMachineShow] = React.useState(false);
  const [listDataArr, setlistDataArr] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [specs, setSpecs] = React.useState(null);
  const [summaryPrice, setSummaryPrice] = React.useState({
    price: null,
    tenure: '',
    taxPercentage: ''
  })
  const [selectOs, setSelectOs] = React.useState({
    name: "",
    type: "win10",
    osfilename: '',
    idValue: '0',
    image: '',
    groupname: ''
  });
  const [selectOsDB, setSelectOsDB] = React.useState({
    name: "",
    type: "win10",
    osfilename: '',
    idValue: '0',
    image: '',
    groupname: ''
  });
  const [selectOsMarketPlace, setSelectMarketPlace] = React.useState({
    name: "",
    type: "win10",
    osfilename: '',
    idValue: '0',
    image: '',
    groupname: ''
  });
  const [blocksInput, setBlocksInputValueSet] = React.useState(1);
  const [isMore, setIsMore] = React.useState(false);
  const [totalCores, setTotalCores] = React.useState(28);
  const [isMachineList, setMachineList] = React.useState(true);
  const [isLoadingList, setIsLoadingList] = React.useState(false);
  const [storageState, setStorage] = React.useState(null);
  const [tenure, setTenure] = React.useState(0);
  const [databasesList, setDatabasesList] = React.useState([]);
  const [marketPlacesList,setMarketPlacesList] = React.useState([]);
  const [marketPlacesItemId,setMarketPlacesItemId] = React.useState(0);
  const [marketplaceStateid, setMarketPlaceStateId] = React.useState(0);

  const [locationDataLoading, setLocationDataLoading] = React.useState(false);
  const [isCreatedResCompleted, setIsCreatedResCompleted] =
    React.useState(true);
  const [timer, setTimer] = React.useState(null);
  const createInstanceNameRef = React.createRef();
  const createRootPasswordRef = React.createRef();
  const SShkeyRef = React.createRef();
  const privateIpRef = React.createRef();
  const blockInputRef = React.createRef();
  const locationRef = React.createRef();
  const [submitErrors, setSubmitErrors] = React.useState({
    instancesNameErr: false,
    rootPasswordErr: false,
    confirmPasswordErr: false,
    passwordNotMatchErr: false,
    passwordNotValid: false,
    sshKeyErr: false,
    privateIpErr: false,
    blocksErr: false,
    locationErr: false,

  });
  const [machineType, setMachineType] = React.useState({
    shared: {
      isChecked: false,
    },
    dedicated: {
      isChecked: false,
    },
  });

  const [staticLocation, setStaticLocation] = React.useState({
    image: '',
    name: ''
  });

  const [timerUnMount, setTimerUnMount] = React.useState(null);

  const [blockidStage, setBlockIdStage] = React.useState(null);

  //const [isUnMounted,setIsUnMounted] = React.useState(false);

  const optionsArr = [
    {
      name: "64 Bit os",
      items: ["Debian", "Ubuntu", "Fedora", "Freebsd", "Centos "],
    },
    {
      name: "34 Bit os",
      items: ["Ubuntu", "Windows"],
    },
    {
      name: "Market Place Apps",
      items: ["stream"],
    },
  ];

  // const debounceFn = React.useMemo(_.debounce(handleDebounceFn.bind(null,blocksInput), 1000), []);

  const debouncedChangeHandler = React.useMemo(
    () => _.debounce((userInput, bId, isLoading) => getLocationData(userInput, bId, isLoading, storageState), 1000),
    [storageState]
  );

  // React.useEffect(function (){
  //   StaticInstanceForm.isUnMounted = false;
  //   return () => {
  //     //setIsUnMounted(true);
  //     StaticInstanceForm.isUnMounted = true;
  //   }
  // },[]);

  React.useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();

    };
  }, [debouncedChangeHandler]);

  React.useEffect(function () {
    StaticInstanceForm.isUnMounted = false;
    initCall();
    setLocationDataLoading(true);

    return () => {

      StaticInstanceForm.isUnMounted = true;
      if (timerUnMount != null) {
        clearInterval(timerUnMount);
        setTimerUnMount(null);
      }

      setIsCreatedResCompleted(false);
      setCreateInstanceName("");
      setCreateMachineShow(true);
      setPrivateKey("");
      setSShKey("");
      setSshkeyHash("");
      setRootPassword("");
      setRootPasswordAgain("");
      setDeployLoading(false);
    };
  }, []);

  React.useEffect(function () {

    if (blockidStage != null && storageState != null) {
      getOsMachines();
      getDatabases();
      getMarketPlaces();
      getLocationData(1, blockidStage, true, storageState);
    }
  }, [blockidStage, storageState]);

  async function getMarketPlaces(){
    const res = await getMarketPlacesService();
    const isValid = checkResData(res);
    if (!isValid) {
      handlerAlert("danger", "Can't get Databases", "danger");
    return;
    }

    const jsonParse = JSON.parse(res.data.result);

    setMarketPlacesList(jsonParse)

 
  }

  async function getDatabases() {

    const res = await getDatabasesService();
    const isValid = checkResData(res);
    if (!isValid) {
      handlerAlert("danger", "Can't get Databases", "danger");
      console.log("Can't get Databases");
    }
    const jsonParse = JSON.parse(res.data.result);
    setDatabasesList(jsonParse)
  }

  async function initCall() {


    const blockId = await getBlockIdFromMachine();

    if (blockId.error) {
      handleClickErrorAlert("danger", "Block Id not Found", "danger");
      return;
    }
    setBlockIdStage(blockId.block);


  }


  function setBlocksInput(val) {

    setBlocksInputValueSet(val);
    let isLoading = false;
    if (locationDataLoading === false) {
      setLocationDataLoading(true);
      isLoading = true;
    }

    debouncedChangeHandler(val, blockidStage, isLoading);

  }


  function fliterLocationData(mainData, sideData) {
    //const data = [];
    let one = false;
    let remberBranchThere = {};

    for (let i = 0; i < sideData.length; i++) {
      let currItem = sideData[i].branchid;
      for (let j = 0; j < mainData.length; j++) {
        let mainCurrItem = mainData[j].branchid;
        if (currItem == mainCurrItem) {
          remberBranchThere[currItem] = 1;
        }
        if (currItem == mainCurrItem && one == false) {
          setLocationState(mainData[j].branchid);
          setStaticLocation({
            name: mainData[j].branchname,
            image: mainData[j].icon
          });
          one = true;
        }
        if (currItem == mainCurrItem) {

          mainData[j]['isNeedShow'] = true;
          // data.push(mainData[j]);
        }
      }
    }

    return { mainData, remberBranchThere };
  }



  async function getLocationData(numberBlocks = 1, bId, isLoading, storageInfo) {
    debugger;
    if (numberBlocks === "" || numberBlocks === null || numberBlocks === undefined) {
      numberBlocks = 1;

    }

 
    try {
 
      let response = await getInstanceFormLocations(numberBlocks);
      let avaLocationRes = await getAvailableInstanceFormLocations(numberBlocks, bId, storageInfo);


      setLocationDataLoading(false);

      if (response?.data?.result ) {
        let res = JSON.parse(response?.data?.result);
        let avRes = [];

        if(avaLocationRes?.data?.result != ''){
          avRes =  JSON.parse(avaLocationRes?.data?.result);
        }

        // console.log(res, "calling location api");
        let fliterDataFull = fliterLocationData(res, avRes);
        let remberFilterData = fliterDataFull.remberBranchThere;
        let fliterData = fliterDataFull?.mainData;
        setLocations(fliterData);
        if (fliterData.length === 0) {
debugger;
          setLocationState('');
 
          return;
        }

        if(Object.keys(remberFilterData).length===0){
          setLocationState('');

          return;
        }

        


      }
    } catch (error) {

      setLocationDataLoading(false);
    }
  }

  function handleOsClick(e, cardName, osfilename, idValue, OSImage, groupname) {
    // setSelectOs()

    let name = e.target.getAttribute("data-name");
    if (!name && cardName) {
      name = cardName;
    }
    let which = e.target.getAttribute("id");
    let type = "";
    if (name == "windows") {
      type = "win10";
    } else {
      type = "l26";
    }
    
    setSelectOs({
      name,
      type,
      osfilename: osfilename,
      id: idValue,
      image: OSImage,
      groupname
    });

    setMarketPlaceStateId(0);
    setMarketPlacesItemId(0);

    // // console.log(selectOs)
  }
  function handleOsClickDB(e, cardName, osfilename, idValue, OSImage, groupname) {
    // setSelectOs()
    setMarketPlacesItemId(0);
    let name = e.target.getAttribute("data-name");
    if (!name && cardName) {
      name = cardName;
    }
    // let which = e.target.getAttribute("id");
    let type = "";
    if (name == "windows") {
      type = "win10";
    } else {
      type = "l26";
    }
   debugger;
    setSelectOsDB({
      name,
      type,
      osfilename: osfilename,
      id: idValue,
      image: OSImage,
      groupname
    });

    //setMarketPlaceStateId(0)
   

    // // console.log(selectOs)
  }

  function handleOsClickMarket(e, cardName, osfilename, idValue, OSImage, groupname) {
    // setSelectOs()
    setMarketPlaceStateId(0);
    let name = e.target.getAttribute("data-name");
    if (!name && cardName) {
      name = cardName;
    }
    let which = e.target.getAttribute("id");
    let type = "";
    if (name == "windows") {
      type = "win10";
    } else {
      type = "l26";
    } 
   
    setSelectMarketPlace({
      name,
      type,
      osfilename: osfilename,
      id: idValue,
      image: OSImage,
      groupname
    });

    //setMarketPlaceStateId(0)
   

    // // console.log(selectOs)
  }

  async function getOsMachines() {

    setOslist({
      name: "",
      options: [],
      loading: true
    })
    let osListFetch = await getInstancesOSList();

    let result = null;

    if (osListFetch?.data.result == '') {
      handlerAlert("danger", osListFetch.data.message, "danger");
      return;
    }
    if (osListFetch?.data.result != null || osListFetch?.data.result != '') {
      result = JSON.parse(osListFetch?.data.result);
    }
    let options = [];
    if (osListFetch?.data.errors != null && osListFetch?.data.errors?.length != 0 && osListFetch?.data.errors?.length != undefined) {
      handlerAlert("danger", osListFetch?.data.errors[0], "danger");
      return;
    }
   
    let justSelect = 0;
    for (let i = 0; i < result?.length; i++) {
      if (justSelect === 0 && result[i]['ostype'] == "64bit") {
        justSelect++;
        setSelectOs({

          name: result[i]['operatingsystemname'],
          type: result[i]['ostype'],
          id: result[i]['operatingsystemid'],
          image: result[i]['osiconpath'],
          osfilename: result[i]['osfilename'],
          groupname: result[i]['groupname']
        })
      }
      let data = {
        dataName: result[i]['operatingsystemname'],
        type: result[i]['ostype'],
        id: result[i]['operatingsystemid'],
        image: result[i]['osiconpath'],
        osfilename: result[i]['osfilename'],
        groupname: result[i]['groupname']
      }
      if (result[i]['osiconpath'] == '') {
        data.image = debianImg;
      } else {
        data.image = `${getAssestsURL()}\\` + data.image?.replace('~', '');
      }
      
      options.push(data);
    }

    let customData = [{
      name: "64 Bit",
      options,
      loading: false
    }]

    setOslist(customData)
    // console.log(customData)

  }


  function onChangeLocation(e, a, b, c, d, f, isNeed) {

    if (isNeed === false) {
      setStaticLocation({
        image: staticLocation.image,
        name: staticLocation.name
      });
      return;
    };
    let num = e.target.getAttribute("data-location");
    // console.log(e, 'eeeee')
    for (let i = 0; i < locations.length; i++) {
      if (num == locations[i].branchid) {
        setStaticLocation({
          image: locations[i].icon,
          name: locations[i].branchname
        });

      }
    }

    setLocationState(num);

  }
  function onChangeMachine(e) {

    let name = e.target.getAttribute("data-machinetype");
    setMachineType(name);
  }

  function calCoreHireForm(blocks) {
    return {
      cpu: `${1 * blocks}`,
      ram: `${1000 * (8 * blocks)}`,
      storage: `${10 * blocks}`,
      transfer: `${1 * blocks}`,
    };
  }

  function blocksHandler(e) {
    let name = e.target.getAttribute("data-name");

    if (name === PLUS_BTN) {
      plusLogic(blocksInput);
    } else if (name === MINUS_BTN) {
      minusLogic(blocksInput);
    }
  }

  function plusLogic(num) {
    let TOTAL_BLOCKS = totalCores;
    num = num === "" ? 0 : num;

    if (cores >= 1) {
      let newCores = cores - 1;
      num = num + 1;
      setBlocksInput(num);
      setCores(newCores);
    }
  }

  function minusLogic(num) {
    let TOTAL_BLOCKS = totalCores;
    // 
    if (num <= 1) return;
    num = num === "" ? 1 : num;
    if (cores < TOTAL_BLOCKS) {
      let newCores = cores + 1;
      num = num - 1;
      setBlocksInput(num);
      setCores(newCores);
    }
  }

  function onChangeBlocksHandler(e) {

    let TOTAL_BLOCKS = totalCores;
    let currBlocks = e.target.value;
    if (currBlocks == "" || currBlocks <= -1) {
      setCores(TOTAL_BLOCKS);
      setBlocksInput(currBlocks);
      e.target.setAttribute("data-taprev", "");
      return;
    }
    currBlocks = Number(e.target.value);
    let newCores = totalCores;
    let prev = 0;
    if (e.target.getAttribute("data-taprev") != "") {
      prev = Number(e.target.getAttribute("data-taprev"));
    }
    prev = Number(e.target.getAttribute("data-taprev"));

    if (currBlocks > totalCores) {
      if (totalCores <= 0) {
        return;
      }
      setCores(totalCores);
      setBlocksInput(currBlocks);
      setIsMore(true);
      e.target.setAttribute("data-taprev", currBlocks);
      setTimeout(function () {
        setIsMore(false);
      }, 3000);
      return;
    }

    if (blocksInput === 0) {
      setCores(TOTAL_BLOCKS);
      return;
    }

    if (prev === currBlocks) return;

    e.target.setAttribute("data-taprev", currBlocks);

    if (currBlocks < prev && currBlocks < totalCores) {
      newCores = newCores - currBlocks;
      setCores(newCores);
      setBlocksInput(currBlocks);
    } else if (currBlocks >= prev && currBlocks <= TOTAL_BLOCKS) {
      newCores = newCores - currBlocks;
      setBlocksInput(currBlocks);
      setCores(newCores);
    }
  }



  function checkIsCreateFormValid() {

    if (createInstanceName == "") {
      createInstanceNameRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
      setSubmitErrors({
        ...submitErrors,
        instancesNameErr: true,
      });
      // console.log(createInstanceNameRef, "createInstanceNameRef");

      return {
        isError: true,
        message: "instancesNameErr",
      };
    }

    let isTrue = containsSpecialCharsExceptDotAndDash(createInstanceName);
    if (isTrue || hasWhiteSpace(createInstanceName)) {
    // if (!isValidVMName(createInstanceName)) {
      createInstanceNameRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
      setSubmitErrors({
        ...submitErrors,
        instancesNameErr: true,
      });
      return {
        isError: true,
        message: "okay",
      };
    }

    let num = parseInt(blocksInput);

    if (num <= 0 || blocksInput == "") {
      blockInputRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
      // handlerAlert("danger",'Block must be more than 0 and Empty Blocks are wrong','danger')
      setSubmitErrors({
        ...submitErrors,
        blocksErr: true,
      });
      return {
        isError: true,
        message: "Block must be more than 0 and Empty Blocks are wrong",
      };
    }

    if (locations.length <= 0) {
      handlerAlert("danger", "No Locations", "danger");
      return {
        isError: true,
        message: "You should select a Location",
      };
    }

    if (locationState == 0) {
      blockInputRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
      setSubmitErrors({
        ...submitErrors,
        locationErr: true,
      });
      return {
        isError: true,
        message: "No Avaabile Locations",
      };
    }

    if (selectOs == null) {
      return {
        isError: true,
        message: "You should select a OS",
      };
    }

    if (rootPassword == "") {
      createRootPasswordRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      setSubmitErrors({
        ...submitErrors,
        rootPasswordErr: true,
      });
      return {
        isError: true,
        message: "rootPasswordErr",
      };
    }

    if (rootPassword.length < 8) {
      createRootPasswordRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      setSubmitErrors({
        ...submitErrors,
        rootPasswordErr: true,
      });
      return {
        isError: true,
        message: "rootPasswordErrdasdasds",
      };
    }

    // if(checkPasswordValidHelper(rootPassword)){
    //   createRootPasswordRef.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "center",
    //     inline: "center",
    //   });
    //   setSubmitErrors({
    //     ...submitErrors,
    //     rootPasswordErr: true,
    //   });
    //   return {
    //     isError: true,
    //     message: "rootPasswordErr",
    //   };
    // }

    // if (rootPasswordAgain == "") {
    //   createRootPasswordRef.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "center",
    //     inline: "center",
    //   });
    //   setSubmitErrors({
    //     ...submitErrors,
    //     confirmPasswordErr: true,
    //   });

    //   return {
    //     isError: true,
    //     message: "confirmPasswordErr",
    //   };
    // }




    // if (rootPassword != rootPasswordAgain) {
    //   createRootPasswordRef.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "center",
    //     inline: "center",
    //   });
    //   setSubmitErrors({
    //     ...submitErrors,
    //     passwordNotMatchErr: true,
    //   });

    //   return {
    //     isError: true,
    //     message: "passwordNotMatchErr",
    //   };
    // }

    // const isValidPassword = checkPasswordValidHelper(rootPassword);
    // // console.log(isValidPassword,'isValidPassword')

    // if(isValidPassword === false){
    //   createRootPasswordRef.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "center",
    //     inline: "center",
    //   });
    //   setSubmitErrors({
    //     ...submitErrors,
    //     passwordNotValid: true,
    //   });

    //   return {
    //     isError: true,
    //     message: "passwordNotValid",
    //   };
    // }

    // if (SShkey == "") {
    //   SShkeyRef.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "center",
    //     inline: "center",
    //   });
    //   setSubmitErrors({
    //     ...submitErrors,
    //     sshKeyErr: true,
    //   });
    //   return {
    //     isError: true,
    //     message: "sshKeyErr",
    //   };
    // }

    // if (privateKey == "") {
    //   privateIpRef.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "center",
    //     inline: "center",
    //   });
    //   setSubmitErrors({
    //     ...submitErrors,
    //     privateIpErr: true,
    //   });
    //   return {
    //     isError: true,
    //     message: "privateIpErr",
    //   };
    // }

    return {
      isError: false,
      message: "",
    };
  }

  function handleClickAlert(e) {
    const fadeInAndOut = cssTransition({
      enter: "alertFadein",
      exit: "alertFadeout",
    });
    toast(
      <ToastCustomAlert_hs variant="success" text="Machine as been created!" />,
      {
        autoClose: true,
        className: "toasterCustom",
        bodyClassName: "toasterCustomBody",
        closeOnClick: true,
        transition: fadeInAndOut,
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      }
    );
  }

  function handleClickErrorAlert(e, errorMsg, type) {
    //   
    const fadeInAndOut = cssTransition({
      enter: "alertFadein",
      exit: "alertFadeout",
    });
    toast(
      <ToastCustomAlert_hs type={type} variant="danger" text={errorMsg} />,
      {
        autoClose: true,
        className: "toasterCustomError",
        bodyClassName: "toasterCustomBodyAlert",
        closeOnClick: true,
        transition: fadeInAndOut,
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      }
    );
  }

  function checkParseError(val, valnext, message, timerOut) {
    // JSON.parse(isStopRes.data.result)["data"]["status"]
    if (valnext) {
      let parseData = JSON.parse(valnext);
      if (parseData === '') {
        clearInterval(timerOut);
        dispatch(toggleCreatingMachine());
        dispatch(getIntancesList(props.history));
        setIsCreatedResCompleted(true);
        handleClickErrorAlert("danger", message, "danger");
      } else if (!parseData["data"]) {

      }
    }


    if (!val || !valnext) {
      clearInterval(timerOut);

      dispatch(toggleCreatingMachine());

      dispatch(getIntancesList(props.history));
      setIsCreatedResCompleted(true);
      handleClickErrorAlert("danger", message, "danger");
      return true;
    }

    return true;

  }

  async function getBlockIdFromMachine() {
    let blockid = null;

    const blockIdRes = await getBlockIdService();

    if (blockIdRes.data.code != '0') {
      setDeployLoading(false);
      handleClickErrorAlert('danger', "Block Id not found", "danger");
      return {
        block: null,
        error: true
      }
    }

    blockid = JSON.parse(blockIdRes.data.result)[0]
    setSpecs(blockid);
    let dataStorage = blockid['storage'];
    blockid = blockid['blockid'];


    setStorage(parseInt(dataStorage));
    return {
      block: blockid,
      error: false
    };

  }

  async function getPublickey() {

    const res = await Instances_SSH_Service();
    // console.log(res,'rescksjdhaskdhuk')

    if (res.data.result === null) {
      handlerAlert('danger', `Can't get SSH Key`, 'danger');
      return 'false';
    }
    let resData = JSON.parse(res.data.result);
    let publickey = resData.publicKey;
    return encodeURIComponent(publickey);
  }

  function getCurrentOsOptions(sos,dbos,mkos,dbosData,mkosData){

    let obj = {
      osiconpath:'',
      groupname:'',
      marketplaceid:0,
      operatingsystemname:'',
      operatingsystemid:''
    }

    if(dbos!=0){
      obj = {
        osiconpath:dbosData.image,
        groupname:dbosData.groupname,
        marketplaceid:dbos.marketplaceid,
        operatingsystemname:dbosData.name,
        operatingsystemid:dbosData.id,
        osObj:dbosData
      }
    }else if(mkos != 0){
      obj = {
        osiconpath:mkosData.image,
        groupname:mkosData.groupname,
        marketplaceid:mkos.marketplaceid,
        operatingsystemname:mkosData.name,
        operatingsystemid:mkosData.id,
        osObj:mkosData
      }
    }else{
      obj={
        osiconpath:sos.image,
        groupname:sos.groupname,
        marketplaceid:0,
        operatingsystemname:sos.name,
        operatingsystemid:sos.id,
        osObj:sos
      }
    }

    return obj;
  }

  async function handleFormClick(e) {
    e.preventDefault();

    if (deployLoading === true) {
      return;
    }

    if (props.isShowPayment == 0) {
      dispatch(openPaymentInfoFormAction());
      return;
    }


    setDeployLoading(true);

    let pass = checkIsCreateFormValid();

    if (pass.isError) {
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
      let time = setTimeout(function () {
        let { message } = pass;
        //handleClickErrorAlert(null,pass.message);
        let newSubmitErrors = { ...submitErrors };
        newSubmitErrors[message] = false;
        setSubmitErrors(newSubmitErrors);
      }, 3000);
      setTimer(time);
      setDeployLoading(false);
      return;
    }

    let userCookie = getUserCookieAuth();
    if (userCookie === null) {
      handleClickErrorAlert(null, "Session Error");
      return;
    }
    let { userId, sessionId } = userCookie;

    let sshKeyPublic = '';
    if (sshkeyHash === '') {

    } else {
      sshKeyPublic = sshkeyHash;
    }

   
    const opOPtions = getCurrentOsOptions(selectOs,marketplaceStateid,marketPlacesItemId,selectOsDB,selectOsMarketPlace);
    let data = JSON.stringify({
      "machinename": createInstanceName,
      "machinetype": 1,
      "noofblocks": blocksInput,
      "branchid": locationState,
      "contactid": Number(userId),
      //&nbsp"currencyid": "100015210000002",
      "isbaremetal": false,
      "itemid": 0,
      "operatingsystemid": Number(opOPtions.operatingsystemid),
      // "walletgroupid": "100015230000001",
      "tenuretypeid": tenure,
      // "username": `${props.username}`,
      "username": 'root',
      "password": rootPassword,
      "orderid": 0,
      "sshkey": `${sshKeyPublic}`,
      "blockid": Number(blockidStage),
      "serverid":0,
      "tempstatus": CREATE_STAGE,
      "icon": staticLocation.image||'',
      "osiconpath": opOPtions.osiconpath||'',
      "branchname": staticLocation.name,
      "groupname": opOPtions.groupname,
      "operatingsystemname": opOPtions.operatingsystemname,
      "customscript": codeCommandState,
      "marketplaceid": Number(opOPtions.marketplaceid),
      "ReplicaNodes":null
    })
    // debugger;
    // console.log(data)
    // return;

    const putInfo = {
      location: staticLocation,
      os: opOPtions.osObj,
      machineName: createInstanceName,
      blocks: blocksInput,
      tenure: summaryPrice,
      specs,
      database: opOPtions.marketplaceid

    }

    dispatch(putSummaryInfoModal(putInfo))

    // console.log(selectOsDB)
    // console.log(marketplaceStateid);

    //dispatch(startLoadingInstaces());
    dispatch(putDeloyReducerAction(data));
    dispatch(showDeloyModalAction());
    return;

  }

  return (
    <InstancesFormContext.Provider
      value={{
        handleClickErrorAlert,
        isCreatedResCompleted,
        setIsCreatedResCompleted,
        osList,
        setOslist,
        privateIpRef,
        SShkeyRef,
        blockInputRef,
        createRootPasswordRef,
        submitErrors,
        setSubmitErrors,
        createInstanceNameRef,
        selectTagOption,
        setSelectTagOption,
        setRootPasswordAgain,
        rootPasswordAgain,
        setRootPassword,
        rootPassword,
        setPrivateKey,
        privateKey,
        setSShKey,
        SShkey,
        handleClickAlert,
        statusLoading,
        setStatusLoading,
        createMachineShow,
        checkIsCreateFormValid,
        selectOs,
        setSelectOsHandler: setSelectOs,
        setSelectOs: handleOsClick,
        listDataArr,
        isMachineList,
        setMachineList,
        blockidStage,
        isMore,
        onChangeBlocksHandler,
        setIsMore,
        blocksHandler,
        blocksInput,
        setBlocksInput,
        totalCores,
        show,
        onChangeLocation,
        handleFormClick,
        locationState,
        onChangeMachine,
        cores,
        setCores,
        locations,
        optionsArr,
        createInstanceName,
        setCreateInstanceName,
        machineType,
        setMachineType,
        setTenure,
        tenure,
        deployLoading, setDeployLoading,
        setSshkeyHash,
        locationDataLoading, setLocationDataLoading,
        summaryPrice, setSummaryPrice,
        codeCommandState, setCodeCommandState,
        specs, setSpecs,
        databasesList, setDatabasesList,
        marketplaceStateid, setMarketPlaceStateId,

        setSelectOsDB,
        selectOsDB, handleOsClickDB,
        marketPlacesList,
        setMarketPlacesItemId,
        selectOsMarketPlace, setSelectMarketPlace,
        marketPlacesItemId,handleOsClickMarket
      }}
    >
      {props.children}
    </InstancesFormContext.Provider>
  );
}

const mapStateToProps = ({ instances, auth, PaymentInfoReducer }) => {
  return {
    instancesListRedux: instances.instancesList.data,
    username: auth.auth.name,
    userId: auth.auth.userId,
    isShowPayment: PaymentInfoReducer?.paymentData?.defaultaddressid,

  };
};

export default withRouter(connect(mapStateToProps)(InstancesCreateFormContext));
