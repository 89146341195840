import React from 'react'

function InputHs({placeholder,classes,value,setValue}) {
  return (
    <React.Fragment>
        <input type='text' value={value} onInput={setValue} className={`form-control hs_borderRadius-4 ${classes}`} placeholder={placeholder} />
    </React.Fragment>   
  )
}

export default InputHs