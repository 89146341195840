import axios from "axios";
import { createMachineInstanceBizgaze, getAvailableInstancesURL, getBlockIdURL, getDatabasesURL, getListInstancesURL, getLocationURL, getMarketPlacesURL, getOSMachinesURL, getTenureInstanceURL, getVMCreateCodeURL, instanceConfigCreateMachine, INSTANCES_SSH_URL, setCoresAfterCreateURL } from "../../API_ENDPOINTS/API_ENDPOINTS";
import { getUserCookieAuth } from "../../store/actions/AuthActions";
import { API_SERVICES } from "../API_SERVICES";
import { CatchAsync } from "../ServiceErrorHandlers/CatchAsync";
import AppError from "../ServiceErrorHandlers/CustomError";

// export const getCreateVmCode = CatchAsync(async ()=>{
//     let userCookie = null;
//     if(userCookie === null) {
//         throw AppError(`Couldn't get the vm code`,"506")
//     };
//     return {};
// });

export async function getCreateVMCode() {
    let userCookie = getUserCookieAuth();
    let { userId, sessionId } = userCookie;
    const url = getVMCreateCodeURL();
    let config = {
        url,
        method: 'get',
        headers: { 'Authorization': `Basic ${sessionId}` }
    }

    return API_SERVICES(config);
    try {
        const respone = await axios(config);
        return {
            error: false,
            respone
        }
    } catch (error) {
        return {
            error: true
        };
    }

}


export function getInstancesOSList() {
    let userCookie = getUserCookieAuth();
    if (userCookie === null) return;
    let { userId, sessionId } = userCookie;
    let url = getOSMachinesURL();
    let config = {
        method: 'get',
        url,
        headers: { 'Authorization': `Basic ${sessionId}` }
    };

    return API_SERVICES(config);
}


export function getInstanceFormLocations(blocks = 1) {
    let userCookie = getUserCookieAuth();
    if (userCookie === null) return;
    let { userId, sessionId } = userCookie;

    let url = getLocationURL(blocks);
    let config = {
        method: 'get',
        url,
        headers: { 'Authorization': `Basic ${sessionId}` }
    };

    return axios(config);
}

export function getDatabasesService() {

    let userCookie = getUserCookieAuth();
    if (userCookie === null) return;
    let { userId, sessionId } = userCookie;

    let url = getDatabasesURL();
    let config = {
        method: 'get',
        url,
        headers: { 'Authorization': `Basic ${sessionId}` }
    };

    return axios(config);
}

export function getMarketPlacesService() {

    let userCookie = getUserCookieAuth();
    if (userCookie === null) return;
    let { userId, sessionId } = userCookie;

    let url = getMarketPlacesURL();
    let config = {
        method: 'get',
        url,
        headers: { 'Authorization': `Basic ${sessionId}` }
    };

    return axios(config);
}


export function getAvailableInstanceFormLocations(blocks = 1, blockidStage, storageInfo) {
    let userCookie = getUserCookieAuth();
    if (userCookie === null) return;
    let { userId, sessionId } = userCookie;

    let url = getAvailableInstancesURL(blocks, blockidStage, userId, storageInfo);
    let config = {
        method: 'get',
        url,
        headers: { 'Authorization': `Basic ${sessionId}` }
    };

    return axios(config);
}
export function getBlockIdService() {
    let userCookie = getUserCookieAuth();
    if (userCookie === null) return;
    let { userId, sessionId } = userCookie;

    let url = getBlockIdURL();
    let config = {
        method: 'get',
        url,
        headers: { 'Authorization': `Basic ${sessionId}` }
    };

    return axios(config);
}

export function setCoresInstances(machineid) {
    let userCookie = getUserCookieAuth();
    if (userCookie === null) return;
    let { userId, sessionId } = userCookie;
    let url = instanceConfigCreateMachine(machineid);
    // let url = setCoresAfterCreateURL();

    let config = {
        method: 'post',
        url,
        headers: { 'Authorization': `Basic ${sessionId}` },

    };

    return axios(config);

}


export function getTenureInstanceaService(currenyid, blockidStage) {

    let userCookie = getUserCookieAuth();
    if (userCookie === null) return;
    let { userId, sessionId } = userCookie;
    let url = getTenureInstanceURL(userId, currenyid, blockidStage);

    let config = {
        method: 'get',
        url,
        headers: { 'Authorization': `Basic ${sessionId}` }
    };

    return axios(config);
}

export function createMachineService(payload) {
    let userCookie = getUserCookieAuth();
    if (userCookie === null) return;
    let { userId, sessionId } = userCookie;
    let url = createMachineInstanceBizgaze();
    let config = {
        method: 'post',
        url,
        headers: { 'Authorization': `Basic ${sessionId}`, "Content-Type": "application/json", },
        data: payload
    };

    return axios(config);

}


// export function getINSTANCES_SSHService(){
//     let sessionId = "fce2a44c-4794-45d2-ba6c-2346f8424119"
//     let url = INSTANCES_SSH_URL();

//     let config = {
//         method: 'get',
//         url,
//         headers: { 'Authorization': `Basic ${sessionId}` }
//     };

//     return axios(config);
// }

