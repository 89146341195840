import { SEVERNAME } from "./API_ENDPOINTS";

export function getSupportOpenListURL(contactid){
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getopentickets/contactid/${contactid}`;
}
export function getSupportCloseListURL(contactid){
    return`${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getclosedtickets/contcatid/${contactid}`;
}

export function getTicketGoupsURL(){
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getticketgoups`;
}

export function getTicketPrioritiesURL(){
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getticketpriorities`
}


export function postSupportTicketURL(){
    return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/saveticket`;
}

export function getSupportItemByIdURL(ticketid){
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getticketdetails/ticketid/${ticketid}`;
}