import React from 'react'
import { connect, useDispatch } from 'react-redux';
import { createMachineService } from '../../../../../../services/InstancesServices/InstancesFormService';
import { getPaymentWalletAmountService } from '../../../../../../services/paymentServices/PaymentService';
import { putRedirectLinkAction, showPagementGateWayAction } from '../../../../../../store/actions/RedirectPageActions/RedirectPageAction';
import { clearDeloyReducerAction, hideBareMetalModalAction, hideDeloyModalAction } from '../../../../../../store/reducers/DeployReducer';
import { checkResData } from '../../../../../../utils/Helpers';
import StaticInstanceForm from '../../../../../../utils/StaticsClasses.js/StaticsInstances';
import ModalHs from '../../../../hireserver/ModalHs';
import { handlerAlert } from '../../../Instances/InstancesListComponents/DropDownInstancesList';

import walletPng from '../../../../../../images/hireserver/imgs/wallet.png';
import { getCurrencySymbol } from '../../../../../../utils/Currency';
import { withRouter } from 'react-router-dom';
import { getBareMetalList } from '../../../../../../store/actions/BareMetalActions/BareMetalAction';

function DeployBareMetal({isOpen,setDeployLoading,history,price,paymentData,submitPayload}) {
    const dispatch = useDispatch();
    const [walletValue,setWalletValue] = React.useState(0);
    const [loading,setLoading] = React.useState(false);

    async function fetchDetailsValues(){
        let res = await getPaymentWalletAmountService();
        let isValidPayment = checkResData(res,true)
        if(isValidPayment === false){
          setWalletValue(0);
         // setLoading(false);
          return;
        }

      let resJSON = JSON.parse(res.data.result)[0];
        if(!resJSON){
          setWalletValue(0);
          // setLoading(false);
          return;
        }
        setWalletValue(resJSON?.walletvalue);
    }

    function closeDeploy() {
       
        dispatch(clearDeloyReducerAction());
        setDeployLoading(false);
      }

    async function okayHandler(){
        setLoading(true);
      
        const response = await createMachineService(submitPayload);
        if(StaticInstanceForm.isUnMounted) return;

      
        if (response.data.result == null) {
            handlerAlert("danger", "Bad Response Create Again", "danger");
            // console.log(response)
            //setIsCreatedResCompleted(true);
            //dispatch(getIntancesList(props.history));
            //dispatch(finishLoadingInstances());
            setDeployLoading(false);
            setLoading(false);
            dispatch(hideBareMetalModalAction());
            return;
          }
          let data = JSON.parse(response.data.result);
           //console.log(data,'instainstainstainstainstainstainstainstainsta')
          const {paymentlink} = data;
          if(paymentlink){
            dispatch(hideBareMetalModalAction());
          
            dispatch(putRedirectLinkAction(paymentlink));
            dispatch(showPagementGateWayAction());
           
            return;
          }
  
          //dispatch(toggleCreatingMachine());
          // dispatch(
          //   setInstances({
          //     data: newInst,
          //     error: null,
          //   })
          // );  
          dispatch(hideBareMetalModalAction());
          dispatch(getBareMetalList(history));
          history.push("/baremetal");
    }

    React.useEffect(function (){
        fetchDetailsValues();   
    },[]);

    return (
    <ModalHs isNeed={false} handleClose={closeDeploy} okayHandler={okayHandler} showModalHS={isOpen} btnTitle="Deploy Machine" >
    <div className='w-100'>
      <div className='align-item-center d-flex flex-column justify-content-center w-100'>
        <div className='d-flex justify-content-center'>
           <div className='w-50p'>

            <img className='w-100 h-100' src={walletPng} alt="wallet img" />
           </div>
        </div>
           <div>
           You have {getCurrencySymbol(paymentData?.currencyname,walletValue)}  in your wallet balance. You still need {getCurrencySymbol(paymentData?.currencyname,Math.abs((walletValue -price).toFixed(2)))} 
           </div>
        
        </div>
        <div className='d-flex justify-content-end d-gap-10'>
            <button className='bg-danger border-0 btn-sm hs_borderRadius-4 text-white' onClick={closeDeploy}>
                Close
            </button>
            <button className='w-150p hs-signin-btn border-0 btn-sm hs_borderRadius-4 text-white' onClick={okayHandler}>
                {loading && <React.Fragment>
                    <div className="spinner-border text-white text-light" role="status"></div> Proceed</React.Fragment>}
                {!loading && 'Proceed'}
            </button>
        </div>
    </div>
  </ModalHs>
  )
}


const mapStateToProps = ({PaymentInfoReducer,deployReducer}) => {
    return {
        isOpen: deployReducer.bareMetalModal,
        paymentData: PaymentInfoReducer?.paymentData,
        submitPayload:deployReducer?.submitPayload
       
    };
};

export default connect(mapStateToProps)(withRouter(DeployBareMetal));
