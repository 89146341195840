import React from 'react';
import { getAssestsURL, getSEVERNAME } from '../../../../../API_ENDPOINTS/API_ENDPOINTS';
import european from '../../../../../images/hireserver/locations/india.png'
export default function LocationList({ locationName, imgSrc }) {
    // let imgURl = imgSrc.replace('~', '');
    // let [image,setImage] = React.useState('');
    // React.useEffect(function (){
    //     '';
    //    if(typeof image === 'string' && typeof imgSrc === 'string' ){
    //     if(imgSrc === ''){
    //         setImage(european);
    //     }else{
    //         setImage(getSEVERNAME()+imgSrc.replace('~',''));
    //     }
    //    }
    // },[imgSrc])
    let [image,setImage] = React.useState('');
    React.useEffect(function (){
        // '';
        //// console.log(imgSrc,'imgSrcimgSrcimgSrcimgSrcimgSrc')
       if(typeof image === 'string' && typeof imgSrc === 'string' ){
        if(imgSrc === ''){
            setImage('windows');
        }else{
            setImage(getAssestsURL()+'/'+imgSrc.replace('~',''));
        }
       }
    },[imgSrc])
    return (
        <div className="text-center listimgWrapper location">
            <div>
                <img src={image}></img>
            </div>
            <div className='hs-lightgrey'>{locationName}</div>
        </div>
    )
}