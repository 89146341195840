import React from 'react'
import { Form } from 'react-bootstrap'
import LabelInput from '../../../../hireserver/controls/LabelInput';
import TagsComponent from '../../../../hireserver/SharedComponents/TagsComponent';
import NodeBalanceContext from '../../NodeBalanceContext/NodeBalanceContext';



export default function NodeBalanceHeader() {
    const { selectTagOption, setSelectTagOption } = React.useContext(NodeBalanceContext);

    function logChange(val) {
        // console.log("Selected: " + val);
    }

    return (
        <Form.Group className='col-12 col-lg-4'>
            <LabelInput heading="NodeBalancer Label" id="NodeBalancerLabel" />
            <TagsComponent  selectTagOption={selectTagOption} setSelectTagOption={setSelectTagOption} />
        
        </Form.Group>
    )
}
