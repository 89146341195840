import React from 'react'
import Toggle from 'react-toggle'
import { postscriptmarketService } from '../../../../../../../services/InstancesServices/InstanceCurrListNewDropdownService';
import { CRON_ENABLE } from '../../../../../../../utils/Emuns';
import { handlerAlert } from '../../../../Instances/InstancesListComponents/DropDownInstancesList';
import { postSaveCronJobService } from '../../../../../../../services/InstancesServices/InstancesListService';
import ToggleButton from 'react-bootstrap/ToggleButton'



function ToggleCronJobList({ isEnable, fullItemDate,setModel }) {
    const [enableCronJob, setEnableCronJob] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(function () {
        setEnableCronJob(isEnable)
    }, [isEnable]);

    // async function toggleCronHandler() {
    //     // setEnableCronJob(true);
    //     // return;
    //     debugger;
    //     setLoading(true);
    //     let isEnableToggle = !enableCronJob;
    //     console.log(isEnableToggle);

    //     const { tokens } = fullItemDate;

    //     if (!tokens) {
    //         setLoading(false)
    //         return;
    //     };

    //     fullItemDate.tokens = typeof (tokens) === 'string' ? JSON.parse(tokens) : tokens;
    //     let params = fullItemDate.tokens['params'];
    //     console.log(params);
    //     let newParmas = [];
    //     for (let i = 0; i < params.length; i++) {
    //         const { key, value } = params[i];
    //         if (key != CRON_ENABLE) {
    //             newParmas.push(params[i]);
    //         }
    //     }
    //     if (isEnableToggle) {
    //         newParmas.push({
    //             "key": "_cronstatus",
    //             "value": 1
    //         });
    //         fullItemDate['tokens']['params'] = newParmas;
    //         console.log(fullItemDate);
    //         debugger;
    //         try {
    //             // const res = await postscriptmarketService(fullItemDate);
    //             const cronres = await postSaveCronJobService(fullItemDate.scriptid, fullItemDate);


    //             if (cronres.data.message == 'Data saved successfully.' && cronres.data.code == '0') {
    //                 handlerAlert("success", "Cron Job Enable");
    //                 const res = await postscriptmarketService(fullItemDate);
    //                 setEnableCronJob(true);
    //             } else {
    //                 // newParmas.pop();
    //                 // newParmas.push({
    //                 //     "key": "_cronstatus",
    //                 //     "value": 0
    //                 // });
    //                 // fullItemDate['tokens']['params'] = newParmas;
    //                 //  const res = await postscriptmarketService(fullItemDate);
    //                 handlerAlert("danger", "Something went wrong", "danger");
    //                 setEnableCronJob(false);
    //             }
    //         } catch (err) {
    //             setEnableCronJob(false);
    //             handlerAlert("danger", "Something went wrong", "danger");
    //         } finally {
    //             setLoading(false)
    //         }

    //     } else {
    //         // newParmas.push({
    //         //     "key": "_cronstatus",
    //         //     "value": 0
    //         // });
    //         fullItemDate['tokens']['params'] = newParmas;
    //         console.log(fullItemDate); debugger;
    //         try {

    //             // console.log(res);
    //             // const res = await postscriptmarketService(fullItemDate);
    //             const cronres = await postSaveCronJobService(fullItemDate.scriptid, fullItemDate);
    //             if (cronres.data.message == 'Data saved successfully.' && cronres.data.code == '0') {
    //                 handlerAlert("success", "Cron Job Disabled");
    //                 const res = await postscriptmarketService(fullItemDate);
    //                 setEnableCronJob(false);
    //             } else {
    //                 //     newParmas.pop();
    //                 //     console.log(cronres);
    //                 //     newParmas.push({
    //                 //         "key": "_cronstatus",
    //                 //         "value": 1
    //                 //     });
    //                 //     fullItemDate['tokens']['params'] = newParmas;
    //                 //    const res = await postscriptmarketService(fullItemDate);
    //                 setEnableCronJob(true)
    //                 handlerAlert("danger", "Something went wrong", "danger");
    //             }
    //         } catch (err) {
    //             setEnableCronJob(true)
    //             handlerAlert("danger", "Something went wrong", "danger");
    //         } finally {

    //             setLoading(false)
    //         }

    //         //console.log(fullItemDate);
    //     }
    // }

    return (
        <div className='text-center  justify-content-between align-content-center'>

            {/* <Toggle
                value={enableCronJob}
                id='cheese-status'
                defaultChecked={isEnable}
                onChange={() => toggleCronHandler()} /> */}
            {/* <div>
                    {enableCronJob?}
                </div> */}
            {/* <button className={enableCronJob?'btn bg-success text-white hs_borderRadius-4 px-3 py-1':'btn bg-danger text-white hs_borderRadius-4 px-3 py-1'} onClick={toggleCronHandler}>
                {enableCronJob?'Enable':'Disable'}
               </button> */}

            <div style={{ width: '200px' }} class={`btn position-relative  btn-sm d-flex align-items-center  p-1   bgl-${enableCronJob?'success':'dark'} justify-content-between  text-${enableCronJob?'success':'black'} rounded-sm `} >
                <span className='px-2 font-14'> {loading ? <div>
                <div style={{
                    top:'15px',
                    left:'30px'
                }} className="spinner-border position-absolute  text-dark" role="status">

                </div>
            </div> : enableCronJob ? 'Running' : 'Stopped'}</span>   
            
            <div onClick={()=>setModel(fullItemDate,enableCronJob)} className={`px-3 p-2 font-12 hs-signin-btn hs_borderRadius-4 align-self-end ${enableCronJob?'hs-grey-btn':''}`}>{enableCronJob ? 'Disable Cron' : 'Enable Cron'}</div> </div>
            {/* </div> : enableCronJob ? 'Running' : 'Stopped'}</span>   <div onClick={toggleCronHandler} className='px-3 p-2 hs-signin-btn hs_borderRadius-4 align-self-end'>{enableCronJob ? 'Disable' : 'Enable'}</div> </div> */}



        </div>
    )
}

export default ToggleCronJobList