import produce from "immer";
import { DELETE_CONFIRM_MODAL,CLOSE_HS_MODAL, OPEN_HS_MODAL, FINISH_INSTANCES_LOADING, GET_INSTANCES, PUT_CURRENT_ITEM_DELETE, SELECT_ALL_LIST_ITEM_IN, SELECT_CURRENT_LIST_ITEM_IN, SET_INSTANCES, SHOW_DELETE_CONFIRM_MODAL, START_INSTANCES_LOADING, UN_SELECT_ALL_LIST_ITEM_IN, UN_SELECT_CURRENT_LIST_ITEM_IN, CURR_SELECT_ITEM, CLEAR_CURR_SELECT_ITEM, REBOOT_HS_SHOWMODAL, REBOOT_HS_HIDEMODAL, STOP_ALL_MODAL_START, STOP_ALL_MODAL_FINISH, SHOW_DELETE_ALL_MACHINES_MODAL, HIDE_DELETE_ALL_MACHINES_MODAL, UPDATE_SELECTED_ITEMS_STATE, CREATING_MACHINE_INSTANCE_TOGGLE, INSTANCE_REINSTALL_HIDE, INSTANCE_REINSTALL_SHOW, TOGGLE_FREEZE_SELECT_ALL_ACTION, TENURE_DATA_STORE,CLEAR_INSTANCES_STORE,  } from "../types/InstancesTypes";

const CREATE_INSTANCE = 'CREATE';
const UPDATE_INSTANCE = 'UPDATE';

const initialState = {
    tenureDataStore:null,
    creatingMachine:false,

    instancesList:{
        data:[],
        error:null
    },
    loading:false,
    showDeleteModal:false,
    currDeleteItem:{
        url : '',
        method:'',
        machineid:'',
        num:'',
        statusId:'',
        machinename:''
    },
    curSelectItem:{
        url : '',
        method:'',
        machineid:'',
        num:'',
        statusId:'',
        machinename:''
    },
    selectedListItems:{
        selectAll:false,
        selectItems:{},
        freezeSelectAll:false
    },
    closeInstanceModal:false,
    instanceReinstall:false,
    rebootShowModal:false,
    stopAllModal:{
        name:'Stop All Machines',
        isBool:false,
        namesArr:['vm1','vm2'],
        des:'Are you sure to stop all the Machines?'

    },
    deleteAllModal:{
        name:'Delete All Machines',
        isBool:false,
        namesArr:['vm1','vm2'],
        des:'Are you sure? You want to delete all the Machines??'
    }  
    
};

export default function InstancesReducer(state = initialState, action) {

    switch (action.type) {
        case SHOW_DELETE_ALL_MACHINES_MODAL:{
           return {
            ...state,
            deleteAllModal:{
                name:'Delete All Machines',
                isBool:true,
                namesArr:['vm1','vm2'],
                des:'Are you sure? You want to delete all the Machines??'
            }
           }
        }

       

        case CLEAR_INSTANCES_STORE:{
            return initialState;
        }

        case TENURE_DATA_STORE:{
            return {
                ...state,
                tenureDataStore:action.payload
            }
        }

        case HIDE_DELETE_ALL_MACHINES_MODAL:{
            return {
                ...state,
                deleteAllModal:{
                    name:'Delete All Machines',
                    isBool:false,
                    namesArr:['vm1','vm2'],
                    des:'Are you sure? You want to delete all the Machines??'
                }
            }
        }

        case CREATING_MACHINE_INSTANCE_TOGGLE:{
            return {
                ...state,
                creatingMachine:!state.creatingMachine
            }
        }


        case INSTANCE_REINSTALL_SHOW:{
            return {
                ...state,
                instanceReinstall:true
            }
        }
        case INSTANCE_REINSTALL_HIDE:{
            return {
                ...state,
                instanceReinstall:false
            }
        }



        case UPDATE_INSTANCE:
            return {
                ...state,
                instancesList: action.payload
            }
        case CREATE_INSTANCE:
            return {
                ...state,
                instancesList: action.payload
            };
        case GET_INSTANCES:{
            return state.instances;
        }
        case SET_INSTANCES:{
            // return {
            //     ...state,
            //     instancesList:action.payload
            // }

           
           return produce(state,draft => {
                draft.instancesList = action.payload
            });
        }
        case START_INSTANCES_LOADING:{
            return {
                ...state,
                loading:true
            }
        }

        case FINISH_INSTANCES_LOADING:{
            return {
                ...state,
                loading:false
            }
        }

        case 'DO NOTHING':{
            return {
                ...state
            }
        }

        case STOP_ALL_MODAL_START:{
            return{
                ...state,
                stopAllModal:action.payload
            }
        }

        case STOP_ALL_MODAL_FINISH:{
            return {
                ...state,
                stopAllModal:{
                    name:'Stop All Machines',
                    isBool:false,
                    namesArr:['vm1','vm2'],
                    des:'Are you sure to stop all the Machines?'
            
                },
            }
        }

        case SHOW_DELETE_CONFIRM_MODAL:{
            return {
                ...state,
                showDeleteModal:true,
            }
        }

        case DELETE_CONFIRM_MODAL:{
            return {
                ...state,
                showDeleteModal:false
            }
        }

        case PUT_CURRENT_ITEM_DELETE:{
            return {
                ...state,
                currDeleteItem:action.payload
            }
        }

        case TOGGLE_FREEZE_SELECT_ALL_ACTION:{
            return produce(state,draft => {
                draft.selectedListItems.freezeSelectAll =  !draft.selectedListItems.freezeSelectAll
            })
            
                
                    // ...state,
                // selectedListItems:{
                //     selectAll:false,
                //     selectItems:action.payload,
                //     freezeSelectAll:true
                // },
                // selectedListItems.freezeSelectAll = true
            
        }

        case SELECT_CURRENT_LIST_ITEM_IN:{
            
            return {
                ...state,
                selectedListItems:{
                    selectAll:false,
                    selectItems:action.payload
                }
            }
        }

        case UPDATE_SELECTED_ITEMS_STATE:{
            return produce(state,draft => {
                draft.selectedListItems.selectItems = action.payload
            })
        }

        case SELECT_ALL_LIST_ITEM_IN:{
            return {
                ...state,
                selectedListItems:{
                    selectAll:true,
                    selectItems:action.payload
                }
            }
        }

        case UN_SELECT_CURRENT_LIST_ITEM_IN:{
            // return {
            //     ...state,
            //     selectedListItems:{
            //         selectAll:false,
            //         selectItems:action.payload
            //     }
            // }
            
            return produce(state,draft => {
                draft.selectedListItems.selectAll = false;
                draft.selectedListItems.selectItems = action.payload
            });
        }

        case UN_SELECT_ALL_LIST_ITEM_IN:{
            return {
                ...state,
                selectedListItems:{
                    selectAll:false,
                    selectItems:{}
                }
            }
        }

        case CLOSE_HS_MODAL:{
            return{
                ...state,
                closeInstanceModal:false
            }
        }

        case OPEN_HS_MODAL:{
            return{
                ...state,
                closeInstanceModal:true
            }
        }

        case CURR_SELECT_ITEM:{
            return {
                ...state,
                curSelectItem:action.payload
            }
        }

        case CLEAR_CURR_SELECT_ITEM:{
            return {
                ...state,
                curSelectItem:{
                    url : '',
                    method:'',
                    machineid:'',
                    num:'',
                    statusId:'',
                    machinename:''
                }
            }
        }
        
        case REBOOT_HS_SHOWMODAL:{
            return {
                ...state,
                rebootShowModal:true
            }
        }

        case REBOOT_HS_HIDEMODAL:{
            return {
                ...state,
                rebootShowModal:false
            }
        }

        default:
            return state;
    }

}