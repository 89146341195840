import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom';
import { getpaymentCheckStatusService } from '../../../../../services/paymentServices/PaymentService';
import LoaderHS from '../LoaderHS';
import PaymentInner from './PaymentInner';

function PaymentMain() {
    
  return (
    <Switch>
    <Route path="/payment/:orderid/:ordertoken/:from" children={<PaymentInner/>} />
  </Switch>
  
  )
}

export default withRouter(PaymentMain)