import React from 'react';
import Form from 'react-bootstrap/Form'
import { getCurrenciesListURL } from '../../../../API_ENDPOINTS/PAYEMENT_FORM_ENDPOINTS';
import {getCurrenciesListService} from '../../../../services/Profile/PaymentFormServices'
import { checkResData } from '../../../../utils/Helpers';
import StaticPaymentDetails from '../../../../utils/StaticsClasses.js/StaticPaymentDetails';
import FormInputError from '../../../components/hireserver/components/FormInputError';
import DropDownSearch from '../../../components/hireserver/controls/DropDownSearch';

// function CurrenciesList({value,setValue}) {

//   const [currenciesList,setCurrenciesList] = React.useState([]);
//   //const [loading,setLoading] = React.useState(false);

//   React.useEffect(function (){
//     getCurrenciesList();
//   },[]);

//   async function getCurrenciesList(){
//     '';
//    //setLoading(true);
//     const res = await getCurrenciesListService();
//     const isValid = checkResData(res);
//     if(!isValid) return;


//     const parseResData = JSON.parse(res.data.result);
//     setCurrenciesList(parseResData);
//     //setLoading(false);
    
//   }


//     function handleValue(e){
//         // onChange={e => {
//         //     // console.log("e.target.value", e.target.value);
//         //     setType(e.target.value);
//         //   }}
//         //''
//        // // console.log(e.target.value)
//         if(setValue){
//             setValue(e.target.value);
//         }
//     }
    
//   return (
//     <Form.Group className='w-100' controlId="formcountry">
//           <Form.Label>Select Your Curreny</Form.Label>
//             <Form.Control
//             as="select"
            
//             className="hs_borderRadius-4 "
//             onChange={handleValue}
//           >
//            {
//             currenciesList.map(function (el,i){
//               return  <option value={`${el?.currencyid}`}>{el?.currencyname}</option>
//             })
//            }


//           </Form.Control>
          
//         {/* {
//           loading && <div className='form-control hs_borderRadius-4 d-flex justify-content-center align-items-center'>
//           <div class="spinner-border text-primary" role="status">
//     <span class="sr-only">Loading...</span>
//   </div>
//           </div>
//         } */}
//         </Form.Group>
//   )
// }

// export default CurrenciesList


function CurrenciesList({currencyHandler,isDisable,currencyState,setCurrencyStateError,currencyStateError,country}) {
  const [values, setValues] = React.useState([])
  React.useEffect(function () {
    getCurrencies();
  }, []);

  React.useEffect(function (){
    //console.log(country,'countrycountrycountry')
    if(StaticPaymentDetails.isEditing === false){
      setDollarFun();
    }
  
  },[country]);

  function setDollarFun(){
    if(country){
      let countryName = country.label;
      if(countryName.toLowerCase()!='india' && currencyHandler){
        let currValues = values.find(el=>el.label?.toLowerCase() === "usd");
        handleChange(currValues);
        return;
      }
      if(countryName.toLowerCase()=='india' && currencyHandler){
        let currValues = values.find(el=>el.label?.toLowerCase() === "inr");
        handleChange(currValues);
        return;
      }
    }
  }


  function formatData(data) {
    
    const arr = [];
    for (let i = 0; i < data.length; i++) {
      arr.push({
        value: data[i].currencyid,
        label: data[i].shortcode
      })
    }
    return arr;
  }

  function handleChange(e) {
    if(currencyHandler){
      currencyHandler(e)
      setCurrencyStateError({
        isError:false,
        messsage:''
      })
    }
  }

  function selectTwoGetClass(dataObj,dataMainObj){
    return `${dataObj?.isError && 'inputAlertValSelect2'} ${dataObj?.isError===false && dataMainObj!=0 && 'validFormInputSelect2'}`
  }

  async function getCurrencies() {
    try {
      const res = await getCurrenciesListService();
     // const isValid = checkResData(res);
      //if (!isValid) return;
      let parseData = [];
      if(res.data.result == '' || res.data.result == null){
        parseData =[];
      }else{
        parseData = JSON.parse(res.data.result);
      }
    
      // console.log(parseData);



      const formattedData = formatData(parseData);
      // console.log(formattedData, 'formattedDataformattedData')
      setValues(formattedData);
      // currencyHandler({
      //   value:0,
      //   label:'Currency'
      // })

    } catch (error) {
      // console.log(error);
    }
  }
  return (
    <Form.Group className='mb-0 d-flex billingFormFlex' controlId="formBasicSelect">
      {/* <Form.Label className='col-sm-5 p-0 hs_sub_font'>Select Your Curreny<sup><i className='fa fa-asterisk text-danger font-5 px-1'></i></sup></Form.Label> */}
    
      <div className='col-sm-12 p-0'>
      <DropDownSearch  placeholder="Select Currency"  isDisable={isDisable} parentClass="" selectClasses={`${selectTwoGetClass(currencyStateError,currencyState)} hs_sub_font`}    value={currencyState} setValue={handleChange} data={values} />
      {currencyStateError.isError && <FormInputError message={currencyStateError.errorMessage} />}
      </div>

    </Form.Group>

  )
}

export default CurrenciesList