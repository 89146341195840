import { EMPTY_STATE_FIREWALL, SET_FIREWALL_LIST, START_FIREWALL_LOADING, STOP_FIREWALL_LOADING } from "../../types/FirewallTypes/FirewallListTypes"

const initialState = {
    loading:false,
    firewallList:[]
}

function FireWallListReducer(state = initialState, {type,payload}) {
    switch (type) {
        case START_FIREWALL_LOADING:{
            return {
                ...state,
                loading:true
            }
        }
        case STOP_FIREWALL_LOADING:{
            return{
                ...state,
                loading:false
            }
        }

        case SET_FIREWALL_LIST:{
            return {
                ...state,
                firewallList:payload
            }
        }

        case EMPTY_STATE_FIREWALL:{
            return initialState;
        }
        
        default:
            return state;
    }

}

export default FireWallListReducer;