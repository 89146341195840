import React from 'react'
import FormInputError from '../../../../../hireserver/components/FormInputError'
import BoundsRulesLoaders from '../../../../../hireserver/components/Loaders/Firewall/BoundsRulesLoaders'
import HeadingHS from '../../../../../hireserver/controls/HeadingHS'
import LabelInput from '../../../../../hireserver/controls/LabelInput'
import TagsComponent from '../../../../../hireserver/SharedComponents/TagsComponent'
import SpacerHS_TWO from '../../../../../hireserver/Spaces/SpacerHS_TWO'
import FirewallContext from '../FirewallContext/FirewallContext'
import InputDesHs from './components/InputDesHs'
import SelectMachinesFire from './components/SelectMachinesFire'

function BasicDetails() {
  const {boundDetailsLoading,setBoundDetailsLoading,firewallErrors,selectMachineTag,setSelectMachineTag,firesDescription,setFireDescription,securityGroupName,setSecurityGroupName,selectMachineTagRef,firesDescriptionRef,securityGroupNameRef,machinesTagSelect,setMachinesTagSelect} = React.useContext(FirewallContext);
    // const [selectTagOption, setSelectTagOption] = React.useState([
    //     { value: "one", label: "One" },
    //     { value: "two", label: "Two" },
    //     { value: "dummy", label: "dummy" },
    //   ]);
    if(boundDetailsLoading){
  
        return <div className='d-flex flex-column d-gap-10'>
          <BoundsRulesLoaders times={1} width={500} />
          <BoundsRulesLoaders times={1} width={500} />
          <BoundsRulesLoaders times={1} width={500} />
        </div>
      
    }
  return (
    <div>
        <HeadingHS title="Basic Details" as="h3 text-primary" />
        <SpacerHS_TWO/>
        <InputDesHs ref={securityGroupNameRef} value={securityGroupName} setValue={setSecurityGroupName}   title="Security group name" parentClasses="col-12 col-md-6 col-lg-6 px-0" placeholder="MyWebServerGroup" />
        {
          firewallErrors?.firewallGroupName.isError &&  
<FormInputError message={firewallErrors?.firewallGroupName.message} />
        
      
        }
        <SpacerHS_TWO/>
        <InputDesHs  ref={firesDescriptionRef} value={firesDescription} setValue={setFireDescription} title="Description"  parentClasses="col-12 col-md-6 col-lg-6 px-0" placeholder="Allows SSH access to developers" />
        {
          firewallErrors?.description.isError &&  

        
            <FormInputError message={firewallErrors?.description.message} />
       
        }
        <SpacerHS_TWO/>
        {/* <div className='col-12 col-md-6 px-0'> */}
        {/* <TagsComponent title="Select Machine"  titleClasses="text-primary h4" selectTagOption={selectMachineTag}
            setSelectTagOption={setSelectMachineTag} />
        </div> */}
        <div  className='col-12 col-md-6 px-0'>
        <HeadingHS title="Select Machine" as="h3 text-primary" />
          <SelectMachinesFire changeHandler={setMachinesTagSelect} values={machinesTagSelect} options={selectMachineTag} />
        </div>
    </div>
  )
}

export default BasicDetails