import React from 'react'
import { connect, useDispatch } from 'react-redux';
import { postAddToWalletService } from '../../../../../services/paymentServices/PaymentService';
import { openPaymentInfoFormAction } from '../../../../../store/actions/PaymentInfoActions/PaymentInfoAction';
import { putRedirectLinkAction, showPagementGateWayAction } from '../../../../../store/actions/RedirectPageActions/RedirectPageAction';
import ModalHs from '../../../hireserver/ModalHs';
import sureImg from '../../../../../images/hireserver/imgs/bee/forgotpassword.png';
import ImageHs from '../../../hireserver/ImageHs';
import CurrencyFC from '../../../hireserver/components/Currency/CurrencyFC';
import { LazyLoadImage } from 'react-lazy-load-image-component';


export const sureImgBee  = sureImg;

function Confirmaddamount({paymentData,addWalletPaymentValue,userDetails,walletDetails,setIsOpen,isOpenisShowPayment,isOpen,currencyname}) {
    const [walletValue, setWalletValue] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [disableClose, setDisableClose] = React.useState(false);

    const dispatch = useDispatch();

    //const [loadingAddAmount, setLoadingAddAmount] = React.useState(false);
 

    async function handlerAddAmountToWalletSubmit() {

    
  
       debugger
  
  
        setLoading(true);
         
          const wId = walletDetails?.walletid ? walletDetails?.walletid : 0;
          const payload = {
            "orderamount": addWalletPaymentValue,
            "currencytypeid": paymentData?.currencyid,
            "contactid": userDetails?.userId,
            "walletid":wId,
            "iswalletcredit": true
          }
         

      
          const res = await postAddToWalletService(payload);
      
          if (res.data.code != '0') {
            setLoading(false);
            return;
          }
      
      
          //handleCloseAddAmount();
          const paymentLinkJSON = JSON.parse(res.data.result);
          const paymentlink = paymentLinkJSON?.paymentlink;
          setIsOpen(false);
          dispatch(putRedirectLinkAction(paymentlink));
          dispatch(showPagementGateWayAction());

      
        }

    function closeDeploy() {

 
        setIsOpen(false);
        setLoading(false);
      }

      async function okayHandler() {

      }
    
    return (
        <ModalHs bodyClasses="p-0 overflow-hidden" size="" backdrop="static" isNeed={false} handleClose={closeDeploy} okayHandler={okayHandler} showModalHS={isOpen} btnTitle="Deploy Machine" >
            <div className='w-100'>
                <div className='align-item-center d-flex flex-column justify-content-center w-100'>
                    <div className='px-3 py-4'>
                        <div className='align-item-center d-flex justify-content-center'>
                        <ImageHs src={sureImg} height="150px" width="150px" />
                    
                        </div>
                        <div className='text-center'>
                            <div>Are you sure? </div>
                           <div className=''>
                           You want to add amount of <span className='text-primary font-w500' >{<CurrencyFC name={currencyname} val={addWalletPaymentValue} />} </span> to your wallet?
                           </div>
                        </div>
                    </div>
                </div>
                <div className=' justify-content-end d-gap-10 d-flex p-3'>
                    <button disabled={disableClose} className={`bg-danger border-0 btn-sm hs_borderRadius-4 text-white ${disableClose ? 'd-none' : ''}`} onClick={closeDeploy}>
                        Cancel
                    </button>
                    <button className='w-150p hs-signin-btn border-0 btn-sm hs_borderRadius-4 text-white' onClick={handlerAddAmountToWalletSubmit}>
                        {loading && <React.Fragment>
                            <div className="spinner-border text-white text-light" role="status"></div> Proceed</React.Fragment>}
                        {!loading && 'Proceed'}
                    </button>
                </div>
            </div>
        </ModalHs>
    )
}
const mapStatesToProps = ({ auth, PaymentInfoReducer }) => {
    return {
      currencyname: PaymentInfoReducer?.paymentData?.currencyname,
      paymentData: PaymentInfoReducer?.paymentData,
      userDetails: auth.auth,
      isShowPayment:PaymentInfoReducer?.paymentData?.defaultaddressid,
    }
  };

  
export default connect(mapStatesToProps)(Confirmaddamount);
