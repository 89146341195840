import React from 'react';
import './TimeLineItem.css';

function TimeLineItem({groupName,createdDate,arr,userName}) {
    return (
        <div className="d-flex pb-3 d-gap-10">
            <div className="iconTime">
                <div className='timeline_icon_wrap bg-primary font-10'>
                    <i className='fa fa-cogs'></i>
                </div>
                <div className='timelineBorder d-flex justify-content-center align-items-center h-100'>
                    <div className='timelineBorderClass h-100'>

                    </div>
                </div>
            </div>
            <div className="contentTime w-100">
                <h6>{groupName}</h6>
                <ul className="timelineContainer">
                    {arr.map(function (val,i){
                        return <li key={i*8} class="py-1"><div class="font-w400 timeline_data font-14">{val}</div></li>
                    })}
                </ul>
                <p class=" d-flex justify-content-between"><span class="mr-1 font-w400 timeline_username">By <span class="font-14 font-w600">{userName}</span></span><span class=" font-w600 timeline_date font-14">{createdDate}</span></p>
            </div>

        </div>
    )
}

export default TimeLineItem