import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Select, { components, OptionProps } from 'react-select';
import SelectOptionHS from '../../../../hireserver/controls/SelectOptionHS';
import './DropdownSelect.css';


export default function DropdownSelect() {
    const [colourOptions, setColourOptions] = React.useState([
        {
            isActive: true, id: 99, singleValue: true, name: '1 Gbit/s', price: 'Free', isHeading: true
        },
        {
            id: 55, singleValue: false, isActive: false, name: '10 Gbit/s port', price: '$129.99', subTitle: '1PCle Slot', subPrice: 'Setup fee $129.99', isHeading: false
        }
    ]);

    const [firewallOption,setFireWallOptions] = React.useState([
        {
            isActive: true, id: 99, singleValue: true, name: 'Managed', price: 'Free', isHeading: true
        }
    ])
    const [ipAbs,setIpAbs] = React.useState([
        {
            isActive: true, id: 99, singleValue: true, name: '1 IP Address', price: 'Free', isHeading: true
        }
    ])
    function selecthandler(e, item) {
        const { id } = item;
        let newOptions = [...colourOptions];
        for (let i = 0; i < newOptions.length; i++) {
            const currItem = newOptions[i];
            if (currItem.id === id) {
                currItem.isHeading = true
                currItem.isActive = true
            } else {
                currItem.isHeading = false
                currItem.isActive = false
            }
        }

        setColourOptions(newOptions);
    }
    return (
        <Row>
            <Col xs lg={7}>
                <div className='d-flex flex-column d-gap-10'>
                    <SelectOptionHS question={false} disabled={true} name="Port" selecthandler={selecthandler} data={colourOptions} />
                    <SelectOptionHS question={false} disabled={true} name="IP4/IP6" selecthandler={setIpAbs} data={ipAbs} />
                    <SelectOptionHS question={false} disabled={true} name="FireWall" selecthandler={setFireWallOptions} data={firewallOption} />
                </div>
            </Col>
        </Row>
    )
}
