import React from 'react'
import { getCountriesListService, getStatesListService } from '../../../../services/Profile/PaymentFormServices';
import { checkResData } from '../../../../utils/Helpers';
import DropDownSearch from '../../../components/hireserver/controls/DropDownSearch';
import Form from 'react-bootstrap/Form'
import FormInputError from '../../../components/hireserver/components/FormInputError';
import StaticPaymentDetails from '../../../../utils/StaticsClasses.js/StaticPaymentDetails';
import DDInputSearch from '../../../components/hireserver/controls/dropdowninputsearch/DDInputSearch';
import DropDownSelectSearch from '../../../components/hireserver/controls/dropdownselectsearch/DropDownSelectSearch';

function StatesList({ states, setStates, isUpdate, isDisable, country, setErrorState, stateError }) {
  const [values, setValues] = React.useState([])
  const [loading, setLoading] = React.useState(false);


  function formatData(data) {

    const arr = [];
    for (let i = 0; i < data.length; i++) {
      arr.push({
        value: data[i].cityid,
        label: data[i].cityname,

      })
    }
    return arr;
  }

  function handleChange(e) {

    setStates(e)
    setErrorState({
      isError: false,
      message: ''
    })
  }

  function selectTwoGetClass(dataObj, dataMainObj) {
    return `${dataObj?.isError && 'inputAlertValSelect2'} ${dataObj?.isError === false && dataMainObj != 0 && 'validFormInputSelect2'}`
  }

  React.useEffect(function (){
  
    let c = states;
    console.log(states);
  },[states]);

  React.useEffect(function () {


    getStatesList()
    // console.log(StaticPaymentDetails.isCountry,'StaticPaymentDetails.isCountry')
    debugger;
    if (StaticPaymentDetails.isCountry != true && !StaticPaymentDetails.isEditing) {
     console.log("dasd");
      setStates(null)

    }

  }, [country]);



  async function getStatesList() {
    if (!country?.value) return;
    try {
      setLoading(true);
      debugger;
      const res = await getStatesListService(country.value);
      // const isValid = checkResData(res);
      //if (!isValid) return;

      let parseData = [];
      if (res.data.result == '' || res.data.result == null) {
        parseData = [];
      } else {
        parseData = JSON.parse(res.data.result);
      }

      // console.log(parseData);



      const formattedData = formatData(parseData);
      // console.log(formattedData, 'formattedDataformattedData')
      setLoading(false);
      setValues(formattedData);

      // setStates({
      //   value:0,
      //   label:'City'
      // })

    } catch (error) {
      // console.log(error);
    }
  }
  return (
    <Form.Group className='mb-0 d-flex billingFormFlex' controlId="formBasicSelect">
      {/* <Form.Label  className='col-sm-5 p-0 hs_sub_font'>Select Your City<sup><i className='fa fa-asterisk text-danger font-5 px-1'></i></sup></Form.Label> */}

      <div className='col-sm-12 p-0'>
        {/* <DropDownSearch isSearch={true} placeholder="Select City" isDisable={isDisable} parentClass="p-0" selectClasses={`${selectTwoGetClass(stateError,states)} hs_sub_font stateListBilling`}   value={states} setValue={handleChange} data={values} /> */}
        {/* <DDInputSearch data={values} defaultPlaceholder="Select City" /> */}
        <DropDownSelectSearch dependValue={true} dependField={country} isUpdate={isUpdate} loading={loading} placeholder={states?.label || "Select City"} selectClasses={`${selectTwoGetClass(stateError, states)} hs_sub_font stateListBilling`} isDisable={isUpdate ? '' : isDisable} searchValue={states} valueSetter={handleChange} name="hello" data={values} />

        {stateError.isError && <FormInputError message={stateError.errorMessage} />}
      </div>

    </Form.Group>

  )
}

export default StatesList