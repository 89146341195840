import React from 'react'
import { Card } from 'react-bootstrap';
import HeadingHS from '../../../controls/HeadingHS';
import SpacerHS_ONE from '../../../Spaces/SpacerHS_ONE';
import SpacerHS_TWO from '../../../Spaces/SpacerHS_TWO';
import SmallTickLoader from '../../Loaders/SmallTickLoader/SmallTickLoader';


function SuccessSkeleton({children}) {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center'>
    <div className='d-flex  align-items-center'>
      <div>
        {/* <TickerLoader /> */}
        <SmallTickLoader />
      </div>
      <div>
        <div>
          <HeadingHS title={'Thank you! Your order is placed'} as="h2" />
        </div>
        <SpacerHS_ONE />
        <div>You will receive confirmation email within 24 hours.</div>
      </div>
    </div>
    <SpacerHS_TWO />
    <Card className='shadow-lg paymentInnerCard'>
      <Card.Body className='p-0 font-14'>
       {children}
      </Card.Body>
    </Card>
  </div>
  )
}

export default SuccessSkeleton;