import { cloneDeep } from 'lodash';
import React from 'react';

function useDynamicInputs(){
    const [inputs,setInputs] = React.useState({});
    function putValues(obj){
        setInputs(obj);
    }

    function updateItem(id,val){
        let items = cloneDeep(inputs);
        items[id].value = val;
        items[id].error = {
            isError:false,
            message:''
        }
        setInputs(items);
    }

    function resetAll(){
        setInputs({});
    }



    return {
        inputs,setInputs,putValues,updateItem,resetAll
    }
}


export default useDynamicInputs;