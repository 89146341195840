import React from 'react'
import Table from 'react-bootstrap/Table';
import ButtonHS from '../controls/ButtonHS';
import { getSearchByTableService } from '../../../../services/SearchTableServices/SearchTableServices';
import _ from 'lodash';

function HsTable({data,moreHandler,paging,searchHandler}) {

  const [pageIndex,setPageIndex] = React.useState(1);
  const [doneItems,setDoneItems] = React.useState(false);
  const [searchURL,setSearchURL] = React.useState('');

  const debouncedChangeHandler = React.useMemo(
    () => _.debounce((value,page) => searchHandler(value,page), 1000),
    []
  );
  
    React.useEffect(() => {
      return () => {
        debouncedChangeHandler.cancel();
  
      };
    }, [debouncedChangeHandler]);


  function getHeaders(){
 
    const {cols} = data;
    return cols.map(function (col,i){
      return <th className='' key={col?.id}>{col.label}</th>
    });
  }
  function getRows(){
    const {rows} = data;
 
    if(!rows.length) return;
 
    return rows.map(function (row,i){
      const rowLength = Object.keys(rows[i]).length;
      let item = rows[i];
      let html = [];
     

      for(let j in item){
        html.push(<td>
          {item[j]}
        </td>)
      }

      return <tr>
        {html}
      </tr>
    });
  }

 async function moreHandlerButton(){
  const res = await moreHandler(pageIndex+1);
  if(res){
    const {done} = res;
    if(done) setDoneItems(true);
  }
  setPageIndex((prev)=>{
    return prev+1;
  })
 }

 function searchByValue(e){

  // searchHandler
  debouncedChangeHandler(e.target.value,pageIndex);
  // getSearchByTableService(apiFun,e.target.value,...argsSearchInputs)
 }






  return (
    <div>
      {/* <div>
        <input onChange={searchByValue} type="text" className='bg-lightgrey-100 border-0 inputsearchtable py-4 form-control hs_borderRadius-4 pl-5' placeholder='Search' />
      </div> */}
    <Table responsive="sm">
      <thead>
        <tr className='font-weight-bolder text-dark'>
         {getHeaders()}
        </tr>
      </thead>
      <tbody>
        {getRows()}
      </tbody>
    </Table>
    {
      (doneItems||data.rows.length == 0) ? <div className='text-center'>No More Items</div> : 
      <React.Fragment>
        <div className='d-flex justify-content-end'>
      <button onClick={moreHandlerButton} className='btn btn-outline-primary px-2 py-0 hs_borderRadius-4'>
        More
      </button>
    </div>
      </React.Fragment>
    }
  </div>
  )
}

export default HsTable