import React from 'react'
import { Badge } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getCurrencySymbol } from '../../../../../../utils/Currency';
import CurrencyFC from '../../../../hireserver/components/Currency/CurrencyFC';
import MiniCard, { SelectCardMini } from '../../../../hireserver/SharedComponents/MiniCard/MiniCard';
import OsSelectionCard from '../../../../hireserver/SharedComponents/OsSelectionCard';

function TenureCard({fullData, month,taxPercentage,setSummaryPrice, id, price, offer = false,setTenure,isChecked,paymentData}) {

    const [monthPrice,setMonthyPrice] = React.useState('');

   

    React.useEffect(function (){
        if(month === 'Yearly'){
            setMonthyPrice(price/12);
        }else{
            setMonthyPrice(price);
        }
    },[month,price]);
 
    function handlerSelect(e, nu, ll, idValue) {
        // console.log(e)
     
        setTenure(idValue);
       
        setSummaryPrice({
            price:price,
            tenure:month,
            taxPercentage:taxPercentage
        })
    }

    function calculatorDiff(num1,num2){
     
        const diff = Math.abs((num1*12)-num2);
        const dividBy = diff/num2;
        const ans = (dividBy);
     return ans;
    }

    return (
        <div>
            {/* <MiniCard id="1" cardName="tenureInstanceCreate">
        hello
    </MiniCard>
    <MiniCard id="2"  cardName="tenureInstanceCreate">
        bye
    </MiniCard> */}
            {/* <SelectCardMini idValue="1" cardName="tenureInstanceCreate">
    tata
    </SelectCardMini>
    <OsSelectionCard idValue="2" cardName="tenureInstanceCreate">
    hola
    </SelectCardMini> */}
            <SelectCardMini isTickOption={true} isPadding="p-0" isChecked={isChecked} handler={handlerSelect} idValue={id} cardName="tenureInstanceCreate">
                <div className=' position-relative  d-gap-5 d-flex flex-column justify-content-center align-items-center'>
                    <div className={`w-200p tenureCardBoxWidth h-50 p-3 ${isChecked ?'hs-lightGreen  text-primary':'bg-lightgrey-100'} font-18 text-center`}>{month} 
                    {/* {
                        month > 1 ? <div>Months</div> : <div>Month</div>
                    } */}
                    </div>
                    <div className='d-flex tenureCardBoxWidth w-200p h-100p p-3 flex-column justify-content-center align-items-center'>
                        {/* <div>{price}.00</div> */}
                        {/* {getCurrencySymbol(paymentData?.currencyname,price)} */} 
                        {/* {getCurrencySymbol(paymentData?.currencyname,monthPrice)} */}
                        <div className={`priceTenure font-16 font-w500 ${isChecked?'text-primary':''}`}><span><CurrencyFC name={paymentData?.currencyname} val={price} /></span></div>
                       {month === 'Yearly'  && <div className={'font-12'}>
                       <CurrencyFC name={paymentData?.currencyname} val={monthPrice} />/mon</div>}
                        {/* <div className='hs_text-light font-14 font-w700'>
                            INR / month
                        </div> */}
                    </div>
                    {/* <div className='d-flex flex-column justify-content-center align-items-center font-14 hs_text-light '>
                        <div className='text-center'>
                            Plan renews at
                        </div>
                        <div className='text-center'>
                            Plan renews at regular rate on 3/9/2022
                        </div>
                        <div>


                        </div>

                    </div> */}
                    {
                        offer && <div className='badgeTenure text-center'>
                            <Badge className='bg-primary text-white'>
                                {/* {calculatorDiff(fullData[0].listprice,fullData[1].listprice)} */}
                                50% Offer
                            </Badge>
                        </div>
                    }
                </div>
            </SelectCardMini>
            {/* <SelectCardMini handler={handlerSelect} idValue="2" cardName="tenureInstanceCreate">
    hola
    </SelectCardMini>  */}
        </div>
    )
}

const mapStatesToProps = ({ auth, PaymentInfoReducer }) => {
    return {
      paymentData:PaymentInfoReducer?.paymentData
    };
  };
  export default connect(mapStatesToProps)(TenureCard);
