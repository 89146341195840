import { openAuthModal } from "../../store/actions/AuthAlertActions/AuthAlertActions";
import ErrorClass from "./ErrorClass";
import { AUTHORIZATION_STATUS_CODE, INSTACES_CREATED_ERROR } from "./StutusCodes";

export function ErrorHandler(err){
    const {statusCode} = err;
    let dispatch =  ErrorClass.dispatch;
   let history = ErrorClass.history;

    // console.log(err)
   
    if(statusCode == AUTHORIZATION_STATUS_CODE){
        dispatch(openAuthModal());
        return;
    }

    if(statusCode == INSTACES_CREATED_ERROR){
       const {callback} = err;
       callback.call();
        return;
    }
    
    
}