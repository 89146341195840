import React from 'react'
import DropDownWithImgGroup from '../../../../hireserver/components/dropdowns/DropDownWithImgGroup'

function DropdownOs({ handler, itemData,selectOsDB }) {
    const [formatedData,setFormatedData] = React.useState({});
    React.useEffect(function () {
        formatDataFun(itemData);
     
    }, [itemData]);


  

    function preProcessGroupOS(data) {
        let processData = [];
        let hashObj = {};
        for (let i = 0; i < data.length; i++) {
            if (data[i].GroupName === null || data[i].GroupName === '') {
                hashObj[data[i].GroupName.toLowerCase()] = [data[i]];
            } else if (!hashObj[data[i].GroupName.toLowerCase()]) {
                hashObj[data[i].GroupName.toLowerCase()] = [data[i]];
            } else {
                hashObj[data[i].GroupName.toLowerCase()].push(data[i]);
            }
        }
        return hashObj;
    }



    function formatDataFun(data) {
        if (data == 0 || data == undefined) return;

        if (data?.osobj) {
            const osData = JSON.parse(data?.osobj);
            let processData = preProcessGroupOS(osData);

            let osHTML = [];
            
            setFormatedData(processData)
        }

    }

    return (
       <React.Fragment>
        <div className='row'>
            <div className="col-sm-4">
            <DropDownWithImgGroup currItem={selectOsDB} handler={handler} placeholder="Choose a image" data={formatedData} />
            </div>
        </div>
       </React.Fragment>
    )
}

export default DropdownOs