import React, { Suspense, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import {
  loadingToggleAction,
  loginAction,
  loginConfirmedAction,
  loginFailedAction,
  logout,
  passwordRemoveChangedAuthAction,
  setUserCookieAuth,
  signUpFalseAction,
} from "../../store/actions/AuthActions";
import ReCAPTCHA from "react-google-recaptcha";



import { checkIsSessionValid, formatError, getUserDetailsService, loginService, postOtpSendService, postOTPValidService, saveTokenInLocalStorage } from "../../services/AuthService";
import Cookies from "js-cookie";
import { USER_COOKIE } from "../../store/types/AuthTypes/AuthTypes";

import MainLoader from "../components/hireserver/components/Loaders/MainLoader";
import ErrorClass from "../../services/ServiceErrorHandlers/ErrorClass";

import { cssTransition, toast, ToastContainer } from "react-toastify";
import { ToastCustomAlert_hs } from "../components/hireserver/ToastCustomAlert_hs";
import PasswordInputHS from "../components/hireserver/controls/PasswordInputHS";




import { getpaymentInfoService } from "../../services/Profile/PaymentFormServices";

import { openPaymentInfoFormAction, putPaymentInfoStatus } from "../../store/actions/PaymentInfoActions/PaymentInfoAction";

import FormInputError from "../components/hireserver/components/FormInputError";

import { checkEmailValidHelper } from "../../utils/Helpers";

import InputErrorBackground from "../components/hireserver/components/InputErrorBackground";



import LoginSkeletonScreen from "../components/hireserver/components/LoginSkeletonScreen";

import TickerLoader from "../components/hireserver/components/Loaders/TickerLoader/TickerLoader";

import SpacerHS_ONE from "../components/hireserver/Spaces/SpacerHS_ONE";
import SpacerHS_TWO from "../components/hireserver/Spaces/SpacerHS_TWO";
import CookieHelper from "../../utils/StaticsClasses.js/CookieHelper";

// import LoginBox from "./LoginComponents/LoginBox/LoginBox";

// import InputLabelUp from "../components/hireserver/controls/InputLabelUp/InputLabelUp";

// import OTPComponent from "../components/hireserver/SharedComponents/OTP/OTPComponent";

const OTPComponent = React.lazy(() => import('../components/hireserver/SharedComponents/OTP/OTPComponent'));

const LoginBox = React.lazy(() => import('./LoginComponents/LoginBox/LoginBox'));

const InputLabelUp = React.lazy(() => import('../components/hireserver/controls/InputLabelUp/InputLabelUp'));

const ForgotPassword = React.lazy(() => import('./ForgotComponents/ForgotPassword'));

function Login(props) {

  // const [email, setEmail] = useState('waterboy1@o0i.es');
  // const [password, setPassword] = useState('Zxcv@123');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');



  const [serverError, setServerError] = React.useState({
    isError: false,
    message: ''
  })

  const [loginError, setLoginError] = React.useState({
    isError: false,
    message: ''
  })

  const [isLoading, setIsLoading] = React.useState(false);


  const [emailError, setEmailError] = React.useState({
    touched: false,
    isError: false,
    errorMessage: ''
  })

  const [passwordError, setPasswordError] = React.useState({
    touched: false,
    isError: false,
    errorMessage: ''
  })

  const [timerLoader, setTimerLoader] = React.useState(false);
  const [mainLoading, setMainLoading] = React.useState(true);

  const [recaptchaResToken,setRecaptchaResToken] = React.useState('');
  const [recaptchaValidError,setrecaptchaValidError] = React.useState({
    isError:false,
    message:''
  })

  const [otpLoadingVerify, setOTPLoadingVerify] = React.useState(false);
  const [showOtp, setShowOtp] = React.useState(false);

  const [libOtp, setLibOtp] = React.useState({ otp: '' });
  const [otpError, setOtpError] = React.useState({
    isError: false,
    message: 'Something went wrong'
  })
  const [otpId, setOtpId] = React.useState(0);
  const [otp_loading, setOtpLoading] = React.useState(false);

  const [success, setSuccess] = React.useState(false);
  const [passwordChangedState,setPasswordChangedState] = React.useState(false);
  const [userIdState,setUserIdState] = React.useState(null);

  const dispatch = useDispatch();
  let { path, url } = useRouteMatch();

  React.useEffect(function (){
    if(props.isPasswordChanged){
      setPasswordChangedState(true);
      dispatch(passwordRemoveChangedAuthAction());
    }
  },[props.isPasswordChanged]);

  React.useEffect(function () {
    let timer = null;
    if (timerLoader) {
      timer = setTimeout(function () {
        setMainLoading(false);
      }, 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [timerLoader])

  React.useEffect(function () {
    CheckUser();
    checkIsSignUp();
   return () => {
    setPasswordChangedState(false);
    setRecaptchaResToken('');
   }
  }, []);

  if (mainLoading) {
    return <MainLoader />
  }

  function checkIsSignUp() {
    let isSignTrue = props.isSignUp;
    if (isSignTrue) {
      handleClickErrorAlert('success', "Your Account has been Created.");
      dispatch(signUpFalseAction())
    }
  }

  function onChangeRecaptcha(value) {
    // console.log("Captcha value:", value);
    const api = `https://www.google.com/recaptcha/api/siteverify`;

    setRecaptchaResToken(value);

   
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setOtpLoading(true);

    const otp_Valid = await postOTPValidService({
      "email": email,
      "otpid": otpId,
      "userotp": libOtp.otp
    });

    // console.log(otp_Valid);
    if (otp_Valid.data.result != 'Otp verified successfully') {
      // console.log(otp_Valid,'otp_Validotp_Validotp_Validotp_Valid')
      setOtpError({
        isError: true,
        message: otp_Valid.data.message
      });
      setOtpLoading(false);
      return;
    }

    setShowOtp(false);
    setServerError({
      isError: false,
      message: ''
    })

    setEmail('');
    setPassword('');



    setMainLoading(true);
    setTimeout(function () {
      setMainLoading(false);
    }, 1000);
debugger
    setSuccess(true);

    // if(autoLogin){
    //   setTimeout(function (){
    //     setShowOtp(false);
    //   },3000)
    // }
  }



  function handleClickErrorAlert(e, errorMsg, type) {
    //   '';
    const fadeInAndOut = cssTransition({
      enter: "alertFadein",
      exit: "alertFadeout",
    });
    toast(
      <ToastCustomAlert_hs type={type} variant="danger" text={errorMsg} />,
      {
        autoClose: true,
        className: "toasterCustomError",
        bodyClassName: "toasterCustomBodyAlert",
        closeOnClick: true,
        transition: fadeInAndOut,
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      }
    );
  }


  function successOkayHandler() {
    setMainLoading(true);
    setSuccess(false);
    setShowOtp(false);

    setTimeout(function () {
      setMainLoading(false);
    }, 1000);
  }



  async function CheckUser() {

    // const {sessionId} = JSON.parse(Cookies.get(USER_COOKIE));
    const userCookie = Cookies.get(USER_COOKIE);
    
    // console.log(userCookie)
    if (!userCookie) {
      setIsLoading(false);
      setTimerLoader(true);
      return;
    };
    setMainLoading(false);
    //
    dispatch(loadingToggleAction())
    const { sessionId } = JSON.parse(userCookie);

    let isTrue = await checkIsSessionValid(sessionId);
    let tokenDetails = '';

    let isFilled = false;
    let paymentData = null;
    const checkPaymentStatus = await getpaymentInfoService();
    if(!checkPaymentStatus){
      dispatch(loadingToggleAction())
      dispatch(logout(props.history));
      return;
    }
  
    if (checkPaymentStatus.data.result != '') {
      paymentData = JSON.parse(checkPaymentStatus.data.result);
      isFilled = paymentData[0]?.defaultaddressid == 0;
      dispatch(putPaymentInfoStatus(paymentData[0]));
      if (isFilled) {
        dispatch(openPaymentInfoFormAction())
      }
    }
    if (!isTrue) {

      dispatch(loadingToggleAction())
      dispatch(logout(props.history));
      return;
    }

    props.history.push('/');
    dispatch(loadingToggleAction())

  }


  function loginInputFields() {
    let error = false;
    let emailEdit = false;
    if (email === "") {
      // errorObj.email = "Email is Required";
      error = true;
      emailEdit = true;
      // setEmail({
      //   val: '',
      //   isError: true,
      //   message: 'Email Address is required'
      // })

      setEmailError({
        touched: true,
        isError: true,
        errorMessage: 'Email Address is required'
      })

      //handleClickErrorAlert('danger', "Please enter your Email", 'danger');
      //return error;
    }
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
    if (!email.match(mailformat) && emailEdit == false) {
      error = true;
      // setEmail({
      //   val: email.val,
      //   isError: true,
      //   message: 'Password is required'
      // })
      setEmailError({
        touched: true,
        isError: true,
        errorMessage: 'Email Address must be valid'
      })
    }

    if (password === "") {
      // errorObj.password = "Password is Required";
      error = true;
      // setPassword({
      //   val: '',
      //   isError: true,
      //   message: 'Please enter your Password'
      // })
      setPasswordError({
        touched: true,
        isError: true,
        errorMessage: 'Password is required'
      })
      // handleClickErrorAlert('danger', "Please enter your Password", 'danger');
      //return error;
    }
    // setErrors(errorObj);

    // setTimeout(function(){
    //   setErrors( { email: "", password: "" });

    // },3000);


    return error;
  }

  function otpLibChange(otp) {
    setLibOtp({ otp })
  }

  async function onLogin(e) {
    e.preventDefault();

    // dispatch(loadingToggleAction());
    setIsLoading(true);

  //   let  config = {
  //     method: 'post',
  //     url:`https://www.google.com/recaptcha/api/siteverify?secret=${'6Lcqm_YiAAAAAN3JnWIBBELEII-Vl20MiDLSg9sa'}&response=${recaptchaResToken}`,
  //   };

  // const recaptchRes = await axios(config);
  // const {success} = recaptchRes.data;
  // if(!success) {
  //   setrecaptchaValidError({
  //     isError:true,
  //     message:''
  //   })
  //   setIsLoading(false);
  //   return;
  // }
  debugger;


    const isValid = loginInputFields();
    if (isValid) {
      //dispatch(loadingToggleAction());
      setIsLoading(false);
      return;
    }
    // if (error) {
    //   return;
    // }

    const errorClass = new ErrorClass();
    errorClass.dispatch = dispatch;
    errorClass.history = props.history;

    try {
      debugger;
      const response = await loginService(email, password, props.history);
debugger;
      if (response.data.errors?.length >= 1) {
        //dispatch(loadingToggleAction())
        // setLoginError({
        //   isError:true,
        //   message:response.data.message
        // })

        // setTimeout(function (){
        //   setLoginError({
        //     isError:false,
        //     message:''
        //   })
        // },3000);
        setIsLoading(false);
        let message = response.data.message
        if (response.data.message == 'Email is not verified') {
          message = `${response.data.message}`

        }
        setServerError({
          isError: true,
          message: message
        })
        //handleClickErrorAlert('danger', response.data.message, 'danger')
        // handlerAlert("danger", 'errrror', 'danger')
        return;
      }
      debugger;
      if(response.data === ''){
        setIsLoading(false);
        let message = "Something went wrong"
        
        setServerError({
          isError: true,
          message: message
        })
        //handleClickErrorAlert('danger', response.data.message, 'danger')
        // handlerAlert("danger", 'errrror', 'danger')
        return;
      }
      response.data.result['userEmail'] = email;
      let { unibaseId, sessionId, userId } = response.data.result;
      debugger;
      //CookieHelper.setCookie({unibaseId, sessionId, userId,userEmail:email,email});
    setUserCookieAuth({unibaseId, sessionId, userId,userEmail:email,email});
      // let cookieValue = CookieHelper.getCookie();
      // console.log(cookieValue);
      debugger;
      // // console.log(response.data.result);
      saveTokenInLocalStorage(response.data.result);

      //runLogoutTimer(dispatch, 1800000, history);
      dispatch(loginConfirmedAction(response.data.result));

      let isFilled = false;
      let paymentData = null;
      const checkPaymentStatus = await getpaymentInfoService();
      paymentData = JSON.parse(checkPaymentStatus.data.result);
      isFilled = paymentData[0]?.defaultaddressid == 0;
      dispatch(putPaymentInfoStatus(paymentData[0]));
      if (isFilled) {
        dispatch(openPaymentInfoFormAction())
      }

      props.history.push("/");
      isLoading(false);
      // dispatch(loadingToggleAction())
     
      //dispatch(loginAction(email, password, props.history));
    } catch (error) {
      setIsLoading(false);
      const errorMessage = formatError(error);
      // console.log(error);
      if (error.message === 'Network Error') {
        props.history.push('no-network');
      }
      dispatch(loginFailedAction(errorMessage));

    }


  }

  if (props.showLoading) {

    return <React.Fragment>

    </React.Fragment>
  }

  function handleEmail(e) {
    let checkValidEmail = checkEmailValidHelper(e.target.value.trim());
   
    setEmail(e.target.value);
    
    if (e.target.value == '') {
      setEmailError({
        touched: true,
        isError: true,
        errorMessage: 'Email Address is required'
      })
      return;
    }

   
    if (!checkValidEmail) {
      setEmailError({
        touched: true,
        isError: true,
        errorMessage: 'Email Address must be valid'
      })
    } else {
      setEmailError({
        touched: true,
        isError: false,
        errorMessage: ''
      })
    }

  }

  function handlePassword(e) {
    let value = e.target.value;
    setPassword(value);

    if (value == '') {
      setPasswordError({
        touched: true,
        isError: true,
        errorMessage: 'Password is required'
      })
    } else {
      setPasswordError({
        touched: true,
        isError: false,
        errorMessage: ''
      })
    }

    if (email === '') {
      setEmailError({
        touched: true,
        isError: true,
        errorMessage: 'Email Address is required'
      })
    }

  }

  async function handleClickOTP(e) {
    // // console.log(e)
    setOTPLoadingVerify(true);
    const res = await getUserDetailsService(email);
    //// console.log(res);

    if (res.data.result === '') {
      setServerError({
        isError: true,
        message: 'No Data'
      })
      // console.log(res);
    };

    const resData = res.data.result;
    const userId = resData.unibaseId;

    setUserIdState(userId);
    const payload =
      { "FirstName": "", "LastName": "", "ContactNumber": "", "Email": "", "TenantName": "", "ContactOrEmail": email, "IsSignup": false, "IsRegisterUser": false, "IsForgotPswd": true, "UnibaseId": userId, "OtpId": otpId, "UserOtp": "" }

    const OTPCODE = await postOtpSendService(payload);
    setOtpId(res.data.result?.OtpId)
    if(!OTPCODE){
      return;
    }
    if (OTPCODE.data === '') {
      setServerError({
        isError: true,
        message: 'No Data'
      })
      // console.log(OTPCODE);
    }
    setOTPLoadingVerify(false);
    setShowOtp(true);
    //// console.log(OTPCODE,'OTPCODEOTPCODEOTPCODEOTPCODE')
    setOtpId(OTPCODE.data)
    // console.log(OTPCODE,'OTPCODE.data.resultOTPCODE.data.resultOTPCODE.data.resultOTPCODE.data.result')

    // // console.log(resData)
  }
  return (
    <LoginSkeletonScreen>
      <div className='containerLoginSkeletonScreen h-100 d-flex flex-column'>
      <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                }
                >
        <Switch>
          <Route exact path="/login" >
           {!showOtp && !success &&  <LoginBox>
              {/* <div className="h2">
                Login
              </div> */}
              {/* <div>
                <h4 className="border-bottom-hs-back pb-3 "><span className="border-bottom-hs pb-3 px-4">Login</span></h4>
              </div> */}
              <SpacerHS_TWO /> 
              <div className="pt-2">
                <h3 className="text-dark mb-1 font-21">
                  Welcome to <span className="text-primary">Hireserver</span>
                </h3>

                {
                 passwordChangedState &&  <>
                 <SpacerHS_TWO/>
                 <div className="text-primary hs-lightGreen py-2">
                  <span className="pl-2">
                    <i className="fa fa-check-circle-o"></i>
                  </span>
                  <span className="font-12 px-1">Password reset successfully !</span>
                 </div>
                 </>
                }
                <SpacerHS_TWO />
                <p className="text-dark font-14">
                  Login into your account
                </p>
                
              </div>
              {/* <SpacerHS_TWO /> */}
              {
                serverError.isError && serverError.message != 'Email is not verified' &&
                <InputErrorBackground message={serverError.message} />
              }

              {
                serverError.isError && serverError.message == 'Email is not verified' && <InputErrorBackground classesChild="font-12 text-primary font-w600" clickAction={{ handleClick: handleClickOTP, isLoading: otpLoadingVerify, text: 'Verfiy Here' }} message={serverError.message} />
              }
              {props.errorMessage && (
                <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                  {props.errorMessage}
                </div>
              )}
              {props.successMessage && (
                <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                  {props.successMessage}
                </div>
              )}
              {loginError.isError && <div className="text-center text-danger">{loginError.message}</div>}


              <form onSubmit={onLogin}>

                <div className="form-group ">
                  {/* email */}
                  {/* <label className="mb-2 ">
                    <div className="text-dark font-w600">Email</div>
                  </label>
                  <input
                    type="text"
                    className={`form-control py-4 p-2 hs_borderRadius-4 ${emailError.isError ? 'inputAlertVal' : ''} ${!emailError.isError && emailError.touched ? 'validFormInput' : ''}`}
                    value={email}
                    onChange={handleEmail}
                    placeholder="Please enter Email"
                  /> */}
                  <InputLabelUp classNames={`${emailError.isError ? 'inputAlertVal' : ''} ${!emailError.isError && emailError.touched ? 'validFormInput' : ''}`} setValue={handleEmail}  value={email} placeholder="Enter your email" id="idInput" type="text"  />
               
                  {/* <input
                                  type="text"
                                  className={`form-control ${email.isError && 'inputAlertVal'}`}
                                  value={email.val}
                                  onChange={handleEmail}
                                  placeholder="Please enter Email"
                                /> */}

                  {emailError.isError &&
                    <FormInputError message={emailError.errorMessage} />
                    //   <div className="text-danger fs-12">
                    //   {email.message}
                    // </div>
                  }

                </div>
                <SpacerHS_TWO />
                <div className="form-group">
                  {/* password */}
                  {/* <div>
                    <div className="text-dark font-w600">Password</div>

                  </div> */}
                  <PasswordInputHS value={password} changeHandler={handlePassword} placeholder={"Enter your password"} labelStyles={` w-100`} inputStyles={`${passwordError.isError ? 'inputAlertVal' : ''} ${!passwordError.isError && passwordError.touched ? 'validFormInput' : ''}`} error={passwordError.isError} />
                  {/* <PasswordInputHS value={password} changeHandler={handlePassword} placeholder={"Please Enter Password"} labelStyles={` w-100`} inputStyles={`${passwordError.isError ? 'inputAlertVal' : ''} ${!passwordError.isError && passwordError.touched ? 'validFormInput' : ''} py-4`} error={passwordError.isError} /> */}

                  {/* <InputLabelUp classNames={`${passwordError.isError ? 'inputAlertVal' : ''} ${!passwordError.isError && passwordError.touched ? 'validFormInput' : ''}`} setValue={handlePassword}  value={password} placeholder="Enter your email" id="idInput" type="text"  /> */}

                  {passwordError.isError && (
                    <FormInputError message={passwordError.errorMessage} />
                  )}
                </div>

                <div className="form-row d-none  justify-content-between mt-2 ">
                  <div className="form-group">
                    <div className="custom-control custom-checkbox ml-1 ">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="basic_checkbox_1"

                      />
                      <label
                        className="form-check-label text-dark"
                        htmlFor="basic_checkbox_1"

                      >
                        Remember my preference
                      </label>
                    </div>
                  </div>
                </div>

                <div className="font-sm-14 font-14 text-right">
                  <Link className="text-primary" to="login/forgotpassword">
                  Forgot password?
                  </Link>
                </div>
                {/* <ReCAPTCHA
              
                className="d-flex justify-content-around py-1"
    sitekey="6Lcqm_YiAAAAADGCwb3UXyuYoA2vd7CzMjbixumA"
    onChange={onChangeRecaptcha}
  /> */}
  {/* { recaptchaValidError.isError && <FormInputError message="Please verify the captcha" />} */}
                <div className="text-center pt-3 d-flex justify-content-center">
                  <button
                    disabled={otpLoadingVerify||isLoading}
                    type="submit"
                    className="btn btn-block hs-signin-btn hs_borderRadius-4"
                  >
                    {isLoading ? <React.Fragment>
                      <div className="spinner-border text-light" role="status">

                      </div> Login
                    </React.Fragment> : 'Login'}
                  </button>
                </div>
              </form>
              <SpacerHS_ONE/>
              <div className="d-flex font-14 justify-content-between">
                {/* <div className="new-account mt-2">
                                <p className="text-dark">

                                  <Link className="text-dark" to="login/forgotpassword">
                                    Forgot Passsword?
                                  </Link>
                                </p>
                              </div> */}
                <div className="new-account mt-2">
                  <p className="font-sm-14 font-14">
                  New to Hireserver?
                    <Link className="text-dark pl-1 text-primary" to="signup">
                      Create an account here.
                    </Link>
                  </p>
                </div>

              </div>
            </LoginBox>}
            {/* otp */}
          {showOtp && !success &&  
           <OTPComponent setOtpId={setOtpId} otpId={otpId} userId={userIdState} setServerError={setServerError} setOTP_Active={setShowOtp} otpError={otpError} otp_loading={otp_loading} setOtpLoading={setOtpLoading} handleSubmit={handleSubmit} CompState={libOtp} onOTPChange={otpLibChange} email={email} otpActive={showOtp} />
           }
           
           {
            success && 
              <div className="w-100 h-400p d-flex flex-column justify-content-center align-items-center">
                <TickerLoader/>
                <div>
                  Your Email has been verified!
                </div>
                <SpacerHS_ONE/>
              
                <button onClick={successOkayHandler} className="btn-sm hs-signin-btn border-0">
                  Login
                </button>
               
              </div>
         
           }
          </Route>
          <Route exact path={`${path}/forgotpassword`}>
          <ForgotPassword />
        </Route>
        </Switch>
        </Suspense>
      </div>
    </LoginSkeletonScreen>
  )
}


const mapStateToProps = ({ auth, authAlertReducer }) => {
  return {
    errorMessage: auth.errorMessage,
    successMessage: auth.successMessage,
    showLoading: auth.showLoading,
    isSignUp: authAlertReducer.isSignUp,
    isPasswordChanged: auth.passwordChanged
  };
};

export default connect(mapStateToProps)(Login);
