import React from 'react';
import ClusterFormContext from '../clusterscontext/ClusterFormContext';
import uuid from 'react-uuid';
import { cloneDeep } from 'lodash';

export default function useClusterCapacityhook() {

    const [locationCapacity, setLocationCapacity] = React.useState([]);

    const [filterLocation, setFilterLocation] = React.useState([]);

    const [clustercapacityData, setClustercapacityData] = React.useState({});

    const [nodesclustervalues, setnodeclusterValues] = React.useState({
        num: 0,
        price: null
    });

    function setIndividualError(id,errorName,message){
        debugger;
        let newObj = cloneDeep(clustercapacityData);
        if(!newObj[id]["errors"][errorName]) return;
        newObj[id]["errors"][errorName] = {
            isError:true,
            message
        }

        setClustercapacityData(newObj);
    }


    function removeIndividualError(id,errorName){
        let newObj = cloneDeep(clustercapacityData);
        if(!newObj[id]["errors"][errorName]) return;
        newObj[id]["errors"][errorName] = {
            isError:false,
            message:''
        }

        setClustercapacityData(newObj);
    }

    React.useEffect(function () {
        if (!locationCapacity?.length) return;
        let ansArr = [];

        for (let i = 0; i < locationCapacity.length; i++) {
            const { branchname, icon, branchid, isNeedShow } = locationCapacity[i];
            ansArr.push({
                label: branchname,
                value: branchid,
                img: icon,
                isDisabled: !isNeedShow
            });
        }

        setFilterLocation(ansArr);
    }, [locationCapacity]);

    function genrateNewItem() {
   
        let uid = uuid();
        let defaultOption = {};
        defaultOption[uid] = {
            nodename: '',
            locationData: [],
            selectLocation: null,
            amountofnode: 0,
            nodevalues: {
                num: 1,
                price: 0
            },

            id: uid,
            errors: {
                nodename: {
                    isError: false,
                    message: ``
                },
                selectLocation: {
                    isError: false,
                    message: ``
                },
                amountofnode: {
                    isError: false,
                    message: ``
                }

            }
        }

        return defaultOption;
    }

    React.useEffect(function () {
        if (!filterLocation?.length) return;

        if (Object.keys(clustercapacityData).length == 0) {
            let defaultOption = genrateNewItem();
            setClustercapacityData(defaultOption);
        }



    }, [filterLocation]);

    function deleteItemCluster(id) {
        if (!Object.keys(clustercapacityData).length) return;
        if (!id) return;

        let newObj = cloneDeep(clustercapacityData);
        let newData = {};
        for (let item in newObj) {

            if (item != id) {
                newData[item] = newObj[item];
            }
        }

        setClustercapacityData(newData);


    }

    function addNewItemCluster() {
        let data = genrateNewItem();
        setClustercapacityData((prev) => {
            return { ...prev, ...data }
        });
    }

    function editClusterItem(id, propValue, propName) {
        console.log(clustercapacityData)
        if (!Object.keys(clustercapacityData).length) return;

        let newObj = cloneDeep(clustercapacityData);

       
        if (propName == 'locationData') {
            let fullLocations = filterLocation;
            let locationArr = [];
            for (let i = 0; i < fullLocations.length; i++) {
                if (propValue[fullLocations[i].value]) {
                    fullLocations[i].isDisabled = false;
                    locationArr.push(fullLocations[i])
                } else {
                    fullLocations[i].isDisabled = true;
                    locationArr.push(fullLocations[i])
                }
            }
            newObj[id][propName] = locationArr;
        } else {
            newObj[id][propName] = propValue;
        }



        // Errors type
        // { 
        //     "nodename": {
        //         "isError": false,
        //         "message": ""
        //     },
        //     "selectLocation": {
        //         "isError": false,
        //         "message": ""
        //     },
        //     "amountofnode": {
        //         "isError": false,
        //         "message": ""
        //     }
        // }

        if (propName == 'nodename' && propValue && newObj[id]['errors']['nodename'].isError) {
            newObj[id]['errors']['nodename'] = {
                "isError": false,
                "message": ""
            }
        } else if (propName == 'amountofnode' && propValue && newObj[id]['errors']['amountofnode'].isError) {

            newObj[id]['errors']['amountofnode'] = {
                "isError": false,
                "message": ""
            }
        } else if (propName == 'selectLocation' && propValue && newObj[id]['errors']['selectLocation'].isError) {
            newObj[id]['errors']['selectLocation'] = {
                "isError": false,
                "message": ""
            }
        }

        setClustercapacityData(newObj)

    }

    // function setServerError(serverId){
    //     let newObj = cloneDeep(clustercapacityData);
    //     for(let item in newObj){
    //         let curr = newObj[item];
    //         if(curr.selectLocation==null) continue;

    //         if(curr.selectLocation.value == serverId){
    //             curr.errors.selectLocation = {
    //                 isError:true,
    //                 message:'Server Not Avabil'
    //             }
    //         }

    //     }
    // }



    return {
        clustercapacityData,
        setClustercapacityData,
        setLocationCapacity,
        editClusterItem,
        deleteItemCluster,
        addNewItemCluster,
        nodesclustervalues,
        setnodeclusterValues,
        setIndividualError,
        removeIndividualError
    }
}

