import React from 'react'
import { getSEVERNAME } from '../../../../../API_ENDPOINTS/API_ENDPOINTS';
import { getDatabasesService, getInstancesOSList, getMarketPlacesService } from '../../../../../services/InstancesServices/InstancesFormService';
import { checkResData } from '../../../../../utils/Helpers';
import SelectionsOptions from '../../../hireserver/SelectionsOptions'
import { handlerAlert } from '../../Instances/InstancesListComponents/DropDownInstancesList';
import debianImg from '../../../../../images/hireserver/imgs/debian.png';
import { Tab } from 'react-bootstrap';
import ScriptsMarket from './ScriptsMarket/ScriptsMarket';

function OsMarketPlace() {

  const [selectOs, setSelectOs] = React.useState({
    name: "",
    type: "win10",
    osfilename: '',
    idValue: '0',
    image: '',
    groupname: ''
  });
  const [selectOsDB, setSelectOsDB] = React.useState({
    name: "",
    type: "win10",
    osfilename: '',
    idValue: '0',
    image: '',
    groupname: ''
  });
  const [selectOsMarketPlace, setSelectMarketPlace] = React.useState({
    name: "",
    type: "win10",
    osfilename: '',
    idValue: '0',
    image: '',
    groupname: ''
  });

  const [osList, setOslist] = React.useState([]);
  const [databasesList, setDatabasesList] = React.useState([]);
  const [marketPlacesList,setMarketPlacesList] = React.useState([]);
  const [marketPlacesItemId,setMarketPlacesItemId] = React.useState(0);
  const [marketplaceStateid, setMarketPlaceStateId] = React.useState(0);


  React.useEffect(function (){
    getOsMachines();
    getDatabases();
    getMarketPlaces();
  },[]);

  async function getMarketPlaces(){
    const res = await getMarketPlacesService();
    const isValid = checkResData(res);
    if (!isValid) {
      handlerAlert("danger", "Can't get Databases", "danger");
      console.log("Can't get Databases");
    }

    const jsonParse = JSON.parse(res.data.result);
    let newArr = [];
    // for(let i=0;i<jsonParse.length;i++){
   
    //   if(jsonParse[i]['appname']!="Cpanel" && jsonParse[i]['appname']!="Wordpress" ){
    //     newArr.push(jsonParse[i]);
    //   }
    // }
    setMarketPlacesList(jsonParse)
    //setMarketPlaceStateId(jsonParse[0]);
   
  }

  function handleOsClickMarket(e, cardName, osfilename, idValue, OSImage, groupname) {
    // setSelectOs()
    setMarketPlaceStateId(0);
    let name = e.target.getAttribute("data-name");
    if (!name && cardName) {
      name = cardName;
    }
    let which = e.target.getAttribute("id");
    let type = "";
    if (name == "windows") {
      type = "win10";
    } else {
      type = "l26";
    } 
    console.log(e.target, 'onChangeMachine')
    setSelectMarketPlace({
      name,
      type,
      osfilename: osfilename,
      id: idValue,
      image: OSImage,
      groupname
    });

    //setMarketPlaceStateId(0)
   

    // // console.log(selectOs)
  }

  function handleOsClickMarket(e, cardName, osfilename, idValue, OSImage, groupname) {
    // setSelectOs()
    setMarketPlaceStateId(0);
    let name = e.target.getAttribute("data-name");
    if (!name && cardName) {
      name = cardName;
    }
    let which = e.target.getAttribute("id");
    let type = "";
    if (name == "windows") {
      type = "win10";
    } else {
      type = "l26";
    } 

    setSelectMarketPlace({
      name,
      type,
      osfilename: osfilename,
      id: idValue,
      image: OSImage,
      groupname
    });

    //setMarketPlaceStateId(0)
   

    // // console.log(selectOs)
  }

  async function getDatabases() {

    const res = await getDatabasesService();
    const isValid = checkResData(res);
    if (!isValid) {
      handlerAlert("danger", "Can't get Databases", "danger");
      console.log("Can't get Databases");
    }

    const jsonParse = JSON.parse(res.data.result);
    setDatabasesList(jsonParse)
    //setMarketPlaceStateId(jsonParse[0]);



  }


  async function getOsMachines() {

    setOslist({
      name: "",
      options: [],
      loading: true
    })
    let osListFetch = await getInstancesOSList();

    let result = null;

    if (osListFetch?.data.result == '') {
      handlerAlert("danger", osListFetch.data.message, "danger");
      return;
    }
    if (osListFetch?.data.result != null || osListFetch?.data.result != '') {
      result = JSON.parse(osListFetch?.data.result);
    }
    let options = [];
    if (osListFetch?.data.errors != null && osListFetch?.data.errors?.length != 0 && osListFetch?.data.errors?.length != undefined) {
      handlerAlert("danger", osListFetch?.data.errors[0], "danger");
      return;
    }
   
    let justSelect = 0;
    for (let i = 0; i < result?.length; i++) {
      if (justSelect === 0 && result[i]['ostype'] == "64bit") {
        justSelect++;
        // setSelectOs({

        //   name: result[i]['operatingsystemname'],
        //   type: result[i]['ostype'],
        //   id: result[i]['operatingsystemid'],
        //   image: result[i]['osiconpath'],
        //   osfilename: result[i]['osfilename'],
        //   groupname: result[i]['groupname']
        // })
      }
      let data = {
        dataName: result[i]['operatingsystemname'],
        type: result[i]['ostype'],
        id: result[i]['operatingsystemid'],
        image: result[i]['osiconpath'],
        osfilename: result[i]['osfilename'],
        groupname: result[i]['groupname']
      }
      if (result[i]['osiconpath'] == '') {
        data.image = debianImg;
      } else {
        data.image = `${getSEVERNAME()}\\` + data.image?.replace('~', '');
      }
    
      options.push(data);
    }

    let customData = [{
      name: "64 Bit",
      options,
      loading: false
    }]

    setOslist(customData)
    // console.log(customData)

  }

  function handleOsClick(e, cardName, osfilename, idValue, OSImage, groupname) {
    // setSelectOs()

    let name = e.target.getAttribute("data-name");
    if (!name && cardName) {
      name = cardName;
    }
    let which = e.target.getAttribute("id");
    let type = "";
    if (name == "windows") {
      type = "win10";
    } else {
      type = "l26";
    }

    setSelectOs({
      name,
      type,
      osfilename: osfilename,
      id: idValue,
      image: OSImage,
      groupname
    });

    setMarketPlaceStateId(0);
    setMarketPlacesItemId(0);

    // // console.log(selectOs)
  }

  function handleOsClickDB(e, cardName, osfilename, idValue, OSImage, groupname) {
    // setSelectOs()
    setMarketPlacesItemId(0);
    let name = e.target.getAttribute("data-name");
    if (!name && cardName) {
      name = cardName;
    }
    let which = e.target.getAttribute("id");
    let type = "";
    if (name == "windows") {
      type = "win10";
    } else {
      type = "l26";
    }
  
    setSelectOsDB({
      name,
      type,
      osfilename: osfilename,
      id: idValue,
      image: OSImage,
      groupname
    });

    //setMarketPlaceStateId(0)
   

    // // console.log(selectOs)
  }

  return (
    <div>
      <SelectionsOptions
    dontshowheading={true}
    addComponenetTabs={[
      <Tab  eventKey={"sciptskeymarket"} key={"sciptskeymarket"} title={"Scripts"}>
      <ScriptsMarket/>
  </Tab>
    ]}
    currentOs={selectOs}
    clickHandler={handleOsClick}
    title="Software Selections"
    OptionsCardArr={osList}
    databasesList={databasesList} setDatabasesList={setDatabasesList} marketplaceStateid={marketplaceStateid} setMarketPlaceStateId={setMarketPlaceStateId} setSelectOsHandler={setSelectOs} selectOsDB={selectOsDB} setSelectOsDB={setSelectOsDB} handleOsClickDB={handleOsClickDB}
    marketPlacesList={marketPlacesList}
    marketPlacesItemId={marketPlacesItemId} setMarketPlacesItemId={setMarketPlacesItemId}
    selectOsMarketPlace={selectOsMarketPlace} setSelectMarketPlace={setSelectMarketPlace}
    handleOsClickMarket={handleOsClickMarket}
  />

    </div>
  )
}

export default OsMarketPlace
