import React from 'react'
import { getCurrencySymbol } from '../../../../../utils/Currency'
import { CURRENCY_FONT } from './CurrencyFont';

function CurrencyFC({name,val}) {
  const [money,setMoney] = React.useState({
    currencySymbol:null,
    amount:null
  });
  React.useEffect(function (){

    const [currencySymbol, amount] = getCurrencySymbol(name,val);
    setMoney({
      currencySymbol:currencySymbol,
      amount:amount
    })
  },[name,val])


  return (
    <span><span  style={CURRENCY_FONT}>{money.currencySymbol}</span><span>{money.amount}</span></span>
  )
}

export default CurrencyFC;