import React from 'react'
import { useRouteMatch, withRouter } from 'react-router-dom';
import { getSupportItemByIdService } from '../../../../../../../services/support/SupportServices';
import { checkResData } from '../../../../../../../utils/Helpers';
import BackButton from '../../../../../hireserver/BackButton';
import LoaderHS from '../../../../../hireserver/components/LoaderHS';
import StageShowDetails from '../../../../../hireserver/components/StagesDetails/StageShowDetails';

import HeadingHS from '../../../../../hireserver/controls/HeadingHS';
import SpacerHS_ONE from '../../../../../hireserver/Spaces/SpacerHS_ONE';
import SpacerHS_TWO from '../../../../../hireserver/Spaces/SpacerHS_TWO';
import ColorCode from '../../ColorCode/ColorCode';

function TicketDetails({history}) {
    const [ticketData, setTicketData] = React.useState({});
    const [loading,setLoading] = React.useState(true);
    const { path, url, params: { ticketid } } = useRouteMatch();
    React.useEffect(function () {

        if (ticketid != '' && ticketid != null && ticketid != undefined) {
            getTicketData(ticketid);
        }
    }, [ticketid]);


    async function getTicketData(id) {
        if(!loading) setLoading(true);
        const resData = await getSupportItemByIdService(id);
        const isValid = checkResData(resData);
        if (!isValid) {
            return;
        }
        const resJson = JSON.parse(resData.data.result);
        setTicketData(resJson[0]);

        setLoading(false);
  
    }

    function handlerGoBack(){
    
        history.goBack();
    }

    if(loading){
        return <LoaderHS/>
    }else{
        return (
            <div className=''>
                <div className=''>
                    <BackButton/>
                    <SpacerHS_TWO/>
                    <div className='  d-flex justify-content-between'>
                        <HeadingHS as="h3" title="Ticket Information" />
                        {/* <div>
                        <span  onClick={handlerGoBack} className='font-30 hsBack cursor-pointer text-center d-flex align-items-center'><i className='fa fa fa-arrow-circle-o-left'></i></span>
                        </div> */}
                    </div>
                    <SpacerHS_TWO />
    
                    <div className='font-w400 hs_text-light'>
                        <div><span className='font-w500 hs_text-light'>Ticket Id</span><span className='px-1'>:</span>{ticketData?.ticketno}</div>
                    </div>
                    <SpacerHS_TWO />
                    <div className='row'>
                        <div className='col-sm-7'>
                            <div>
                                <div className='font-w400 hs_text-light d-flex'>
                                    <span className='font-w500 hs_text-light'>
                                        Priority
                                    </span>
                                    <span className='px-1'>:</span>
                                    <span>
                                        {/* {ticketData?.PriorityName} */}
                                        {/* <ColorCode status={ticketData?.PriorityName} /> */}
                                        <StageShowDetails danger status={ticketData?.PriorityName} />
                                    </span>
                                </div>
                                <div className='font-w400 hs_text-light'>
                                    <span className='font-w500 hs_text-light'>
                                        Regrading
                                    </span>
                                    <span className='px-1'>:</span>
                                    <span>
                                        {ticketData?.ticketgroupname}
                                    </span>
                                </div>
    
                            </div>
                            <SpacerHS_TWO />
                            <div>
                                <div>
                                    <span className='font-w500 hs_text-light'>
                                        Ticket Description :
                                    </span>
                                </div>
                                <div className='font-w400 hs_text-light'>
                                    {ticketData?.Description}
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-5 font-w400 hs_text-light'>
                            <div className='d-flex d-gap-5'>
                                <div className='font-w500 hs_text-light'>
                                    Stage
                                </div>
                                <span className='mx-1'>:</span>
                                <StageShowDetails status={ticketData?.stagename} />
                            </div>
                          
                            <div className='d-flex d-gap-5'>
                                <div className='font-w500 hs_text-light'>
                                    Created Data
                                </div>
                                <span className='mx-1'>:</span>
                                <div>
                                    {ticketData?.createddate}
                                </div>
                            </div>
                        </div>
    
                    </div>
                </div>
                <hr/>
                <div>
                    <HeadingHS as="h3" title="Support Ticket Audit" />
                    <SpacerHS_TWO />
                    <div>
                  
                    {/* <div className='font-w400 hs_text-light'>
                        <span className='font-w500 hs_text-light'>
                        Accepted Date
                        </span>
                        <span className='px-1'>:</span>
                        <span>
                            {ticketData?.accepteddate}
                        </span>
                    </div>
                    <div className='font-w400 hs_text-light'>
                        <span className='font-w500 hs_text-light'>
                        Closed Date
                        </span>
                        <span className='px-1'>:</span>
                        <span>
                            {ticketData?.closeddate}
                        </span>
                    </div> */}
                        <SpacerHS_TWO/>
                    <div className='font-w400 hs_text-light'>
                        <span className='font-w500 hs_text-light'>
                        Accepted Name
                        </span>
                        <span className='px-1'>:</span>
                        <span>
                            {ticketData?.acceptedname}
                        </span>
                    </div>
                    <div className='font-w400 hs_text-light'>
                        <span className='font-w500 hs_text-light'>
                            Allocated To
                        </span>
                        <span className='px-1'>:</span>
                        <span>
                            {ticketData?.allocatedtoname}
                        </span>
                    </div>
                </div>
                </div>
              
            </div>
        )
    }
}

export default withRouter(TicketDetails);