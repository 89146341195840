import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { getPastNotifationService, getUnReadNoticeCountService } from '../../../../../services/Profile/NotifationsService';
import { getCountNotifs, getNotifsAction, showNotifiModal } from '../../../../../store/reducers/NotificationReducer';
import { checkResData } from '../../../../../utils/Helpers';
import { handlerAlert } from '../../../../components/Dashboard/Instances/InstancesListComponents/DropDownInstancesList';
import SideModalNoRoute from '../../../../components/hireserver/components/SideModal/SideModalNoRoute/SideModalNoRoute';
import NotificationSidebar from './NotificationSidebar';





function Notification({count,notifs}) {
    const dispatch = useDispatch();
    const [notices, setNotices] = React.useState([]);
    const [isClose,setIsClose] = React.useState(false);
    const [noticeCount,setNoticeCount] = React.useState(0);

    React.useEffect(function () {
        //getNotics();
    
        callNoticesCount()
       // dispatch(getCountNotifs());
    }, []);

    React.useEffect(function (){

    
        if(count == 0){
            setNoticeCount(null);
            return;
        }
        setNoticeCount(count);
    },[count]);

    React.useEffect(function (){
        setNotices(notifs);
    },[notifs])

    function getNotics() {
        //    const notice =  await getPastNotifationService();
        //    let isValid = checkResData(notice);
        //    if(!isValid){
        //     return;
        //    }

        //    const data = JSON.parse(notice.data.result);
        //    console.log(data)

        //    console.log(notice)

        // setNotices(data);
        // console.log(data)

        dispatch(getNotifsAction());
    }

    async function callNoticesCount(){
        // const noticeDataCount = await getUnReadNoticeCountService();
        // const isValid  = checkResData(noticeDataCount);
        // if(!isValid){
        //     return;
        // }

        // let dataJSON = JSON.parse(noticeDataCount.data.result)
        // const count = dataJSON.NotificationCount;
        // console.log(noticeDataCount.data.result)
        // setNoticeCount(count);
       
         dispatch(getCountNotifs());
    }



    return (
        <React.Fragment>
            <div
                className="nav-link  ai-icon  text-center justify-content-center align-items-center"
                onClick={() => dispatch(showNotifiModal())}
                
            >
               {/* <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="25" viewBox="0 0 32.000000 32.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
fill="grey" stroke="none">
<path d="M22 183 l3 -98 28 -3 c22 -3 27 -7 22 -23 -8 -23 -3 -24 38 -2 18 10
64 20 107 23 l75 5 3 98 3 97 -141 0 -141 0 3 -97z m236 25 c-3 -9 -31 -13
-93 -13 -62 0 -90 4 -92 13 -4 9 19 12 92 12 73 0 96 -3 93 -12z m-28 -48 c0
-6 -28 -10 -65 -10 -37 0 -65 4 -65 10 0 6 28 10 65 10 37 0 65 -4 65 -10z"/>
</g>
</svg> */}
<span className='text-gray'>
    <i className='fa fa-bell'></i>
</span>
               {noticeCount && noticeCount >= 1 && <span style={{
                width:'30px',height:'30px'
               }} className="badge d-flex justify-content-center align-items-center light text-white bg-primary font-9">{noticeCount == 0 ? null :(noticeCount>100?'99+':noticeCount) } </span>}
            </div>
          {/* <NotificationSidebar getNotics={getNotics} callNoticesCount={callNoticesCount} noticeCount={noticeCount} isClose={isClose} setIsClose={setIsClose} data={notices} /> */}
          <ToastContainer className="toast-container"
                toastClassName="dark-toast"
                autoClose={3000}
                closeButton={false}
            />
        </React.Fragment>
    )
}

const mapStatesToProps = ({notificationReducer }) => {
    return {
      count:notificationReducer.count,
      notifs:notificationReducer.notifs
    };
  };
  

export default withRouter(connect(mapStatesToProps)(Notification));

