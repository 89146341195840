import React from 'react'
import { Card } from 'react-bootstrap'
import BEE_IMAGE from '../../../../images/hireserver/imgs/bee.png';
function NoDataCard({title,img = null}) {
    const [imgSrc,setImgSrc] = React.useState(null);

    React.useEffect(function (){
        if(img === null){
            setImgSrc(BEE_IMAGE)
        }else{
            setImgSrc(img)
        }
    },[img])
  return (
    <div className="w-100 d-flex justify-content-center align-items-center flex-column">
              <Card className=" p-4 d-flex justify-content-center align-items-center flex-column" style={{
                width:'250px'
              }}>
            <div>
            <img style={{
                width:'100px',
                height:'100px'
              }} src={imgSrc} />
            </div>
      <div>{title}</div>
            </Card>
      </div>
  )
}

export default NoDataCard