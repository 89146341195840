import { cloneDeep } from 'lodash';
import { MDBDataTable } from 'mdbreact';
import React from 'react'
import { connect } from 'react-redux';
import { getInvoicesProfileService, profileOrderListService } from '../../../../../../services/Profile/ProfileOrdersServices';
import { paginationLabel, TABLE_ENTIRES } from '../../../../../../utils/Emuns';
import { checkResData } from '../../../../../../utils/Helpers';
import CurrencyFC from '../../../../hireserver/components/Currency/CurrencyFC';
import LoaderHS from '../../../../hireserver/components/LoaderHS';
import SpacerHS_TWO from '../../../../hireserver/Spaces/SpacerHS_TWO';
import DownloadInvoice from './Components/DownloadInvoice';
import ProfileViewInvoice from './Components/ProfileViewInvoice';

function InvoicesTable({currencyname,isNeed}) {
    const [loading,setLoading] = React.useState(true);

    const [col, setCols] = React.useState([
      {
        label:'Created Date',
        field: "createddate",
        sort: "asc",
       
        attributes: {className: " col-3"  },
      },
      {
        label: "Invoice Id",
        field: "invoiceid",
        sort: "asc",
       
        attributes: {className: "text-center col-3"  },
      },
      
      {
        label: "Total Amount",
        field: "totalamount",
        sort: "asc",
        attributes: {className: "text-center col-3"  },
       
      },
      
      {
        label: "Invoice",
        field: "invoicedownload",
        sort: "asc",
       
        attributes: {className: "text-center col-3"  },
      },
      
     
    ]);
  
    const [formatData, setFormatData] = React.useState({
      columns: [],
      rows: []
    })
    
    React.useEffect(function (){
      getOrdersList();
    },[]);
  
  
  
    
  
    async function getOrdersList(){
      const resData = await getInvoicesProfileService();
      
      const isValid = checkResData(resData,true);
      if(isValid === false){
        setLoading(false);
        setFormatData({
          columns: cloneDeep(col),
          rows: []
        });
        return;
      }
     
  
  
      const parseData = JSON.parse(resData.data.result);
     debugger;
  console.log(parseData,'Invoicessss');
      let rowComps = [];
   
      for(let i=0;i<parseData.length;i++){
       
        const {pendingamount,createddate,orderid,nettotal} = parseData[i];
        let rowData = {
          createddate:<div className=''>{parseData[i]['Created On']}</div>,
            invoiceid:<div className='text-center'>{parseData[i]['Invoice No']}</div>,
            searchInvoiceid:parseData[i]['Invoice No'],
           
            totalamount:<div className='text-center'>{<CurrencyFC name={currencyname} val={parseData[i]['Total Amount']} />}</div>,
           
            invoicedownload:<span className='d-flex d-gap-5 align-item-center justify-content-center'>
              <ProfileViewInvoice orderid={parseData[i]['invoiceid']} />
              <DownloadInvoice orderid={parseData[i]['invoiceid']} />
              
            </span>
        }
        // let rowData = {
        //     invoiceid:<div className='text-center'>{orderid}</div>,
        //     createddate:<div className='text-center'>{createddate}</div>,
        //     totalamount:<div className='text-center'>{nettotal}</div>,
           
        //     invoicedownload:<DownloadInvoice orderid={orderid} />
        // }
  
        rowComps.push(rowData);
      }
  
      setFormatData({
        columns: cloneDeep(col),
        rows: cloneDeep(rowComps)
      });
      setLoading(false);
  
    }
  
    function onSearchHandler(value){
      const newData =  cloneDeep(formatData);
      setFormatData(newData);
    }
  
    function onPageChangeHandler({ activePage, pagesAmount }) {
      const newData =  cloneDeep(formatData);
      setFormatData(newData);
      
      // const ls = document.querySelectorAll('.instanceListTable .instacesClass');
      // // console.log(ls, 'ldsa')
    }
  
  
    if(loading){
      return <LoaderHS/>
    }
  
    return (

        <React.Fragment>
           {!isNeed ?  <div className='font-16 text-primary'>
                Invoices
            </div>:null}
            <SpacerHS_TWO/>
            <div style={{ position: 'relative' }}>
                <div className="table-responsive">
                <div className="display mb-4 dataTablesCard customer-list-table  instances-table-card">
                    <MDBDataTable paging={Boolean(formatData.rows.length>TABLE_ENTIRES)}   paginationLabel={paginationLabel}  onSearch={onSearchHandler} disableRetreatAfterSorting={true} onPageChange={onPageChangeHandler} className='instanceListTable' entriesOptions={[1, 5, 20, 25]} entries={TABLE_ENTIRES} data={formatData} />


                </div>
            </div>
        </div>
        </React.Fragment>
    )
}



const mapStatesToProps = ({ auth, PaymentInfoReducer }) => {
  return {
    currencyname: PaymentInfoReducer?.paymentData?.currencyname,

  }
};
export default connect(mapStatesToProps)(InvoicesTable);
