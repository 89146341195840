import {  AUTH_ALERT_ERROR, AUTH_ALERT_SUCCESS, TOGGLE_AUTH_ALERT_MODAL} from "../types/AuthTypes/AuthAlertTypes";
import {  IS_SIGN_UP_FALSE, IS_SIGN_UP_TRUE } from "../types/AuthTypes/AuthTypes";

const initialState = {
   isAlert:false,
   authAlert_Error:{
    isError:false,
    message:''
   },
   authAlert_Success:{
    isDone:true,
    message:''
   },
   isSignUp:false

};

function AuthAlertReducer(state = initialState, {type,payload}) {
    switch (type) {
        case TOGGLE_AUTH_ALERT_MODAL:{
            return {
                ...state,
                isAlert:payload
            }
        }
        case AUTH_ALERT_ERROR:{
            return{
                ...state,
                authAlert_Error:payload
            }
        }
        case AUTH_ALERT_SUCCESS:{
            return{
                ...state,
                authAlert_Success:payload
            }
        } 

        case IS_SIGN_UP_TRUE:{
            return {
                ...state,
                isSignUp:true
            }
        }
        case IS_SIGN_UP_FALSE:{
            return {
                ...state,
                isSignUp:false
            }
        }
        default:
            return state;
    }

}




export default AuthAlertReducer;
