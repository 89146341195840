import React from 'react'
import Database from '../Database'
import DatabaseContext from './DatabaseContext'

export default function CreateDatabaseContext(props) {
  const [isDatabases,setDatabases] = React.useState(false);
  const [custerLabel,setCusterLabel] = React.useState('dasd');
  const [DatabaseEngine,setDatabaseEngine] = React.useState('');
  const [region,setRegion] = React.useState('');
  const [nodeHandler,setNodeHandler] = React.useState(0);
  const [ipaddress,setIpaddress] = React.useState('');

  function onClickBtnSubmit(e){
    // console.log("submit")
    // console.log(isDatabases,'isDatabases');
    // console.log(custerLabel,'custerLabel');
    // console.log(DatabaseEngine,'DatabaseEngine');
    // console.log(region,'region');
    // console.log(nodeHandler,'nodeHandler');
    // console.log(ipaddress,'ipaddress');

    props.history.push('/databases');
    setDatabases(true);

  }
  
  return (
    <DatabaseContext.Provider value={{ipaddress,setIpaddress,nodeHandler,setNodeHandler,isDatabases,setDatabases,custerLabel,setCusterLabel,onClickBtnSubmit,DatabaseEngine,setDatabaseEngine,region,setRegion}}>
        <Database/>
    </DatabaseContext.Provider>
  )
}
