import { saveGroupInfoURL, saveGroupRulesURL } from "../../API_ENDPOINTS/FIREWALL_ENDPOINTS";
import { API_SERVICES } from "../API_SERVICES";
import { getSessionAndUserId } from "../InstancesServices/InstancesListService";

export function saveGroupInfoService(payload){
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'post',
        url:saveGroupInfoURL(),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
        data:payload
    };

    return API_SERVICES(config);
}
export function saveGroupRulesService(payload){
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'post',
        url:saveGroupRulesURL(),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
        data:{...payload,"contactid":userId}
    };

    return API_SERVICES(config);
}
