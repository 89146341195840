import React from 'react';

import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';

import LoaderHS from '../../../../hireserver/components/LoaderHS';
import ScriptCardItem from './ScriptCardItem';
import ScriptMarketContext from './ScriptMarketContext/ScriptMarketContext';
import _, { cloneDeep } from 'lodash';
import SaveAndExecModal from './SaveAndExecModal/SaveAndExecModal';
import SpacerHS_TWO from '../../../../hireserver/Spaces/SpacerHS_TWO';
import AreaScriptOutput from './AreaScriptOutput/AreaScriptOutput';
import ExecScriptCardItem from './ExecScriptCardItem';
import NoDataCard from '../../../../hireserver/SharedComponents/NoDataCard';
import DetailScripts from './detailsscripts/DetailScripts';
import ParentViewOutput from './viewoutputtable/ParentViewOutput';
import { MDBDataTable } from 'mdbreact';
import { TABLE_ENTIRES, paginationLabel } from '../../../../../../utils/Emuns';
import TemplateScripts from './viewoutputtable/TemplateScripts';
import TemplateCard from './TemplateCard';

function JustScriptList() {
  const { scriptsList, mainLoading, getScriptList } = React.useContext(ScriptMarketContext);
  const [dataList, setDataList] = React.useState([]);
  const [searchInput, setSearchInput] = React.useState('');

  let { path, url, params: { id } } = useRouteMatch();

  const [col, setCols] = React.useState([
    {
      label: "Name",
      field: "name",
      sort: "asc",
      width: 100,
      attributes: { className: "col-3" },
    },
    {
      label: "Description",
      field: "description",
      sort: "asc",
      width: 100,
      attributes: { className: "col-6" },
    },
    {
      label: "Created Date",
      field: "createddate",
      sort: "asc",
      width: 100,
      attributes: { className: "col-3" },
    },


  ]);


  const [formatData, setFormatData] = React.useState({
    columns: [],
    rows: []
  });

  const debouncedChangeHandler = React.useMemo(
    () => _.debounce((val) => filterData(val), 200),
    [scriptsList]
  );

  React.useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();

    };
  }, [debouncedChangeHandler]);



  React.useEffect(function () {

    if (scriptsList.length) {
      const data = scriptsList.filter(function (val, i) {

        if (val.machineid == '0') {
          return val;
        }
      })
      // console.log(data,'datadata');
      setDataList(data);
    //  console.log(scriptsList);

    }
  }, [scriptsList]);

  // React.useEffect(function () {
  //   if (!dataList.length) {
  //     setFormatData({
  //       columns: col,
  //       rows: []
  //     });
  //     return;
  //   };
  //   console.log(dataList, "this");

  //   let newRows = [];
  //   for (let i = 0; i < dataList.length; i++) {
  //     const { createddate, description, name, scriptid } = dataList[i];


  //     let newRow = {

  //       createddate: createddate,
  //       description: description,

  //       name: <Link to={`/myscripts/scripts/template/${scriptid}`}>
  //         <h4>{name}</h4>
  //       </Link>,
  //       hiddenname: name

  //     }
  //     newRows.push(newRow);

  //   }
  //   if (newRows.length <= 0) {
  //     setFormatData({
  //       columns: col,
  //       rows: []
  //     });

  //     return;
  //   }

  //   setFormatData({
  //     columns: col,
  //     rows: newRows
  //   });

  // }, [dataList]);


  function callbackHandler() {
    getScriptList();
  }



  if (mainLoading) {
    return <LoaderHS />
  }



  function handleInputSearch(e) {
    let val = e.target.value;
    setSearchInput(val);
    debouncedChangeHandler(val);
  }

  function filterData(val) {
    debugger;
    if (val === '') {
      let newArr = [];
      for (let i = 0; i < scriptsList.length; i++) {
        if (scriptsList[i].machineid == '0') {
          newArr.push(scriptsList[i])
        }
      }
      setDataList(newArr);
      return;
    }
    let data = [];
    for (let i = 0; i < scriptsList.length; i++) {
      let currItem = scriptsList[i];
      if (currItem.machineid == '0') {
        let name = currItem.name?.toLowerCase()
        if (name.includes(val.toLowerCase())) {
          data.push(currItem);
        }
      }

    }

    setDataList(data);
  }


  return (
    <React.Fragment>




      <Switch>

        <Route exact path={`${path}/details/:scriptid/scripts`}>
          <DetailScripts toShow={true} callback={callbackHandler} />
        </Route>
        <Route exact path={`${path}/template/:id/:templatename/scripts`}>
          <TemplateScripts />
        </Route>
        <Route path={path}>
          
          <div className='w-100 text-right d-flex flex-column d-gap-20 align-items-end pb-4'>

            <div className='hs-grey w-100'>

              <input value={searchInput} onChange={handleInputSearch} placeholder='search' type="text" className="form-control hs_borderRadius-4 bg-transparent border-0 p-4 customInputSearch" />
            </div>

          </div>

      

            {/* <div className="row">
              <div className="col-xl-12" style={{ position: 'relative' }}>
                <div className="table-responsive">
                  <div className="display mb-4 dataTablesCard customer-list-table  instances-table-card">
                    <MDBDataTable paging={Boolean(formatData.rows.length > TABLE_ENTIRES)} paginationLabel={paginationLabel} onSearch={onSearchHandler} disableRetreatAfterSorting={true} onPageChange={onPageChangeHandler} className='instanceListTable' entriesOptions={[1, 5, 20, 25]} entries={10} data={formatData} />


                  </div>
                </div>

              </div>
            </div> */}

<ul className='row '>
            {dataList.length > 0 ? dataList.map(function (val, i) {
             
              return <TemplateCard key={`${val?.scriptid}id`} item={val} />
            }) : <NoDataCard title="No scripts" />}
          </ul> 
          
          {/* <ul className='row'>
            {dataList.length > 0 ? dataList.map(function (val, i) {
              return <ExecScriptCardItem urlLink={`scripts/details/${val.scriptid}/scripts`} link={true} execIsNeed={true} key={val.scriptid} val={val} />
            }) : <NoDataCard title="No scripts" />}
          </ul> */}
          <SpacerHS_TWO />
          {/* <ParentViewOutput /> */}
        </Route>

      </Switch>


    </React.Fragment>
  )
}

export default JustScriptList