import { BootVm, checkMachineStop, updateCurrentMachine } from "../../../services/InstancesServices/InstanceCurrListNewDropdownService";
import { DO_NOTHING } from "../InstancesActions/InstanceCurrItemAction";
import { finishLoadingBareMetal, setBareMetalList_action } from "./BareMetalAction";
import { openAuthModal } from '../AuthAlertActions/AuthAlertActions';
export function VM_ActionCall_BAREMETAL(url, method, machineidC, type,statusid,handlerAlert,curSelectItem) {
  
    return async (dispatch) => {
       
        try {
            // console.log('start')
          
            const response = await BootVm(url, method);
            // console.log(response,'responseresponseresponseresponse')
            if(response.data.code === 'Error'){
                handlerAlert('danger',response.data.message,'danger');
                dispatch(VM_ActionFinished_BAREMETAL(curSelectItem))
                return;
            }

            if(response.data.code === "417" && response.data.errors[0] === "User Authentication Failed"){
                // dispatch(openAuthModal);
                dispatch(openAuthModal())
                return;
            
              
            }
           
            // console.log(response)
            // dispatch(DO_NOTHING());
            
            if (response.data.result === '' || response.data.result === null) {
               // handlerAlert('danger',`${response.data.code} - ${response.data.message}` )
                dispatch(finishLoadingBareMetal());
                return;
            }
            if(type != 4){
                const updateResponse = await updateCurrentMachine(machineidC, type);
            }
            if(type == 2 || type ==3){
                let code = url.split('/');
                code = code[code.length-1];
              
                let resJson = JSON.parse(response.data.result);
               
               
                let isStopRes ='';
                let newRes = ``;
                let timeId = setInterval(async function(params) {
                    isStopRes = await checkMachineStop(resJson.data);
                    newRes = JSON.parse(isStopRes.data.result)['data']['status'];
                    // console.log(isStopRes,'isStopRes')
                    if(newRes == 'stopped' || newRes == ''){
                        // console.log("DONE")
                        clearInterval(timeId);
                       if(type == 3){
                        dispatch(VM_ActionFinished_BAREMETAL(curSelectItem,true))
                       }else{
                        dispatch(VM_ActionFinished_BAREMETAL(curSelectItem))
                       }
                    }
                },2000)
                
                //// console.log(res)
                
            }else{
                dispatch(VM_ActionFinished_BAREMETAL(curSelectItem))
            }
         //dispatch(VM_ActionFinished(curSelectItem))
            //dispatch(getIntancesList());
            //dispatch(finishLoadingInstances());
        } catch (error) {
            // console.log(error,'error')
           // dispatch(finishLoadingInstances());
        }

    }
}

export function VM_ACTION_BARE_STARTED(payload){
    return (dispatch,getState) => {
        const { url, method, machineid, num, statusId, machinename } = payload;
      
        const listData = [...getState().bareMetal.bareMetalList.data];
        for(let i=0;i<listData.length;i++){
            const mId = listData[i].machineid;
            if(mId === machineid){
                let text = '';
                let color = '';
                if(num === 1){
                    text = 'Starting'
                    color = 'bgl-success'
                }else if(num === 2){
                    text = 'Stopping'
                    color = 'bgl-danger'
                }else if(num === 3){
                    text = 'Destorying'
                    color = 'bgl-danger'
                }else if(num == 4){
                    text = 'Rebooting'
                    color = 'bgl-success'
                }
                //listData[i]['loadingCurr'] = true;
                listData[i]['loadingInfo'] = {
                    loading:true,
                    statusId:num,
                    text,
                    color

                }
                // listData[i].statusid = 2;
            }
        }

        dispatch(setBareMetalList_action({
            data:listData,
            error:null
        }))        
    }
}

export function VM_ActionFinished_BAREMETAL(payload,isDelete=false){
    // console.log('VM_ActionFinished_BAREMETALVM_ActionFinished_BAREMETAL')
    return (dispatch,getState) => {
       
        const { url, method, machineid, num, statusId, machinename } = payload;
        const dataList = [...getState().bareMetal.bareMetalList.data];
        if(isDelete){
            let newdataList = [];
            for(let i=0;i<dataList.length;i++){
                const mId = dataList[i].machineid;
                if(machineid != mId){
                   newdataList.push(dataList[i])

                }
            }
            dispatch(setBareMetalList_action({
                data:newdataList,
                error:null
            }));
            return
        }
       
        for(let i=0;i<dataList.length;i++){
            const mId = dataList[i].machineid;
            if(machineid == mId){
                dataList[i].loadingInfo.loading = false;
                dataList[i].statusid = num;

            }
        }
        dispatch(setBareMetalList_action({
            data:dataList,
            error:null
        }))  
    }
    
    
}
