import React from 'react'

function BareMetalSVG({color}) {
  return (
    <React.Fragment>
         <svg style={{fill:`${color}`}} width="32px" height="32px" viewBox="0 0 32 32" id="icon" xmlns="http://www.w3.org/2000/svg">
                <defs>

                </defs>
                <circle cx="7" cy="23" r="1" />
                <rect x="2" y="6" width="4" height="2" />
                <rect x="8" y="6" width="4" height="2" />
                <rect x="14" y="6" width="4" height="2" />
                <rect x="20" y="6" width="4" height="2" />
                <rect x="26" y="6" width="4" height="2" />
                <path d="M28,28H4a2.0021,2.0021,0,0,1-2-2V20a2.0021,2.0021,0,0,1,2-2H28a2.0021,2.0021,0,0,1,2,2v6A2.0021,2.0021,0,0,1,28,28ZM4,20v6H28V20Z" transform="translate(0 0)" />
                <rect x="2" y="12" width="28" height="2" />

              </svg>
    </React.Fragment>
  )
}

export default BareMetalSVG