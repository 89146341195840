import { checkMachineStopURL, twodeleteURL, twoRebootURL, twoStartURL, twoStopURL } from "../../../API_ENDPOINTS/API_ENDPOINTS";
import { BootVm, checkMachineStop, updateCurrentMachine } from "../../../services/InstancesServices/InstanceCurrListNewDropdownService";
import { CLEAR_CURR_SELECT_ITEM, CURR_SELECT_ITEM, HIDE_DELETE_ALL_MACHINES_MODAL, INSTANCE_REINSTALL_HIDE, INSTANCE_REINSTALL_SHOW, SHOW_DELETE_ALL_MACHINES_MODAL, STOP_ALL_MODAL_FINISH, STOP_ALL_MODAL_START, VM_ACTION_TYPE } from "../../types/InstancesTypes";
import { finishLoadingInstances, getIntancesList, setInstances, unCheckAllInstances, updateCurrentInstanceItem, updateSelectedItem } from "./InstancesActions";
import produce from "immer";
import { cloneDeep } from "lodash";
import CatchAsync from '../../../services/ServiceErrorHandlers/CatchAsync';
import { openAuthModal } from "../AuthAlertActions/AuthAlertActions";
import { handlerAlert } from "../../../jsx/components/Dashboard/Instances/InstancesListComponents/DropDownInstancesList";
import { checkMachinePromise } from "../../../services/API_SERVICES";
import { CREATE_STAGE, DELETE_STAGE, REBOOT_STAGE, REINSTALL_STAGE, START_STAGE, STOP_STAGE } from "../../../utils/Emuns";
import StaticActionAlert from "../../../utils/StaticsClasses.js/StaticActionAlert";
export function VM_Action(payload) {
    return {
        type: VM_ACTION_TYPE,
        payload
    }
}

export async function VM_ACTION_PERFORM(url, method, branchid, hostname, machineid, type) {
    '';
    const response = await BootVm(url, method);
    let resJson = JSON.parse(response.data.result)?.data;
    const isValid = await checkMachinePromise(resJson, hostname, branchid)
    // if(isValid.error === false){
    //     return false;
    // }
    const updateResponse = await updateCurrentMachine(machineid, type);

    return resJson;

}

export function VM_ActionCall(url, nodeType, method, machineidC, type, statusid, handlerAlert, curSelectItem, callback) {

    return async (dispatch) => {
        '';
        try {
            // handlerAlert('sas')
            const response = await BootVm(url, method);

            // console.log(response, 'response')
            const { itemData } = curSelectItem;
            dispatch(getIntancesList());
          
            if (response.data.result === '' || response.data.result === null) {
                handlerAlert('danger', `${response.data.code} - ${response.data.message}`, "danger");
                const machineid = itemData['machineid'];
                // console.log('============================');
                // console.log(StaticActionAlert.actionIds);
                StaticActionAlert.cleanActionIds(machineid);
                // console.log(StaticActionAlert.actionIds);
                // dispatch(finishLoadingInstances());
                // console.log(curSelectItem,'curSelectItemcurSelectItemcurSelectItemcurSelectItemcurSelectItemcurSelectItemcurSelectItemcurSelectItemcurSelectItem')
                //dispatch(VM_ActionFinished(curSelectItem,null,true))
                return;
            }
            if (response.data.code === "417" && response.data.errors[0] === "User Authentication Failed") {
                // dispatch(openAuthModal);
                dispatch(openAuthModal())
                return;


            }

            if (response.data.result === '' || response.data.result === null) {
                // handlerAlert('danger',`${response.data.code} - ${response.data.message}` )
                dispatch(finishLoadingInstances());
                return;
            }

            //  dispatch(getIntancesList());

            //  const updateResponse = await updateCurrentMachine(machineidC, type);
            //dispatch(getIntancesList());
            //  if (type == 2 || type == 3 || type == 1|| type==4) {
            //     // let code = url.split('/');
            //     // code = code[code.length - 1];
            //     // let resJson = JSON.parse(response.data.result);
            //     // let isStopRes = '';
            //     // let newRes = ``;
            //     // let timeId = setInterval(async function () {
            //     //     isStopRes = await checkMachineStop(resJson.data,itemData['hostname'],itemData['branchid']);
            //     //     '';
            //     //     let parseObj = JSON.parse(isStopRes.data.result)
            //     //     newRes = parseObj['data']['status'];
            //     //     // console.log(isStopRes, 'isStopRes')
            //     //     // console.log(JSON.parse(isStopRes.data.result), 'ew')
            //     //     if (newRes == 'stopped' || newRes == '') {
            //     //         // console.log("DONE")
            //     //         clearInterval(timeId);
            //     //         '';
            //     //         if (parseObj['data']['exitstatus'] != 'OK') {
            //     //             handlerAlert('Failed',parseObj['data']['exitstatus'],'danger');
            //     //             dispatch(VM_ActionFinished(curSelectItem,null,true))
            //     //         } else {
            //     //             // if (type != 4) {
            //     //                 if(!callback){

            //     //                     dispatch(getIntancesList());
            //     //                 }
            //     //                 if(callback){
            //     //                     callback();
            //     //                 }

            //     //                 const updateResponse = await updateCurrentMachine(machineidC, type);
            //     //             //}
            //     //             // if (type == 4) {
            //     //             //     dispatch(VM_ActionFinished(curSelectItem, true))
            //     //             // } else {
            //     //             //     dispatch(VM_ActionFinished(curSelectItem))
            //     //             // }
            //     //             //ispatch(getIntancesList());

            //     //         }

            //     //     }
            //     // }, 2000)
            //     // // console.log(newRes, 'ew')
            //     // //// console.log(res)

            //  } 
            // else {
            //    dispatch(VM_ActionFinished(curSelectItem))
            // }
        } catch (error) {
            // console.log(error, 'error')
        }

    }
}

// export function VM_ActionCall_ALL(url, method, machineidC, type, handlerAlert) {
//     return async (dispatch) => {

//         try {
//             const response = await BootVm(url, method);
//             '';
//             // console.log(response, 'response')
//             if (response.data.result === '' || response.data.result === null) {
//                 handlerAlert('danger', `${response.data.code} - ${response.data.message}`)
//                 dispatch(finishLoadingInstances());
//                 return;
//             }
//             if (type != 4) {
//                 const updateResponse = await updateCurrentMachine(machineidC, type);
//             }
//             dispatch(unCheckAllInstances());
//             dispatch(getIntancesList());

//         } catch (error) {
//             // console.log(error, 'error');
//             dispatch(unCheckAllInstances());
//             dispatch(finishLoadingInstances());
//         }

//     }
// }

export function DO_NOTHING() {
    return {
        type: 'DO NOTHING',
        payload: null
    }
}

export function update_all_status_start(selectedItem, num) {
    return (dispatch, getState) => {
        const { instancesList: { data } } = getState().instances;
        let newData = produce(data, draft => {
            for (let i = 0; i < draft.length; i++) {
                const mId = draft[i].code;
                if (selectedItem[mId]) {
                    let text = '';
                    let color = '';
                    if (num === START_STAGE) {
                        text = 'Starting'
                        color = 'bgl-success'
                    } else if (num === STOP_STAGE) {
                        text = 'Stopping'
                        color = 'bgl-danger'
                    } else if (num === DELETE_STAGE) {
                        text = 'Destorying'
                        color = 'bgl-danger'
                    } else if (num == REBOOT_STAGE) {
                        text = 'Rebooting'
                        color = 'bgl-success'
                    } else if (num == REINSTALL_STAGE) {
                        text = 'ReInstalling'
                        color = 'bgl-success'
                    }
                    //instances[i]['loadingCurr'] = true;
                    draft[i]['loadingInfo'] = {
                        loading: true,
                        statusId: num,
                        text,
                        color
                    }
                    draft[i]['consoleDisabled'] = 'viewConsoleDisable';
                }
            }
        });


        dispatch(setInstances({
            data: newData,
            error: null
        }));
    }
}

export function update_all_status_end(num, isDelete = false, isError = false) {
    return (dispatch, getState) => {

        const { instancesList: { data } } = getState().instances;
        const { selectedListItems: { selectItems } } = getState().instances;
        let newData = cloneDeep(data);
        let newSelectItems = cloneDeep(selectItems);
        if (isDelete) {
            newData = newData.filter(function (item, i) {
                const mId = newData[i].code;
                if (!selectItems[mId]) {
                    return item;
                }
            });
            dispatch(updateSelectedItem({}));
        } else {
            for (let i = 0; i < newData.length; i++) {
                const mId = newData[i].code;
                if (selectItems[mId]) {
                    newData[i].loadingInfo.loading = false;

                    if (!isError) {
                        newData[i].statusid = num;
                        newSelectItems[mId].statusid = num;

                    }
                }
            }
            dispatch(updateSelectedItem(newSelectItems));
        }

        dispatch(setInstances({
            data: newData,
            error: null
        }));
    }
}



export function VM_CALL_ACTION_START_ALL(data) {
    return async (dispatch) => {
        let promiseAll = [];
        let updatePromiseAll = [];

        for (let item in data) {

            const itemData = data[item]

            const url = twoStartURL(itemData['vmid'], itemData['hostname'], itemData['branchid'])

            const response = BootVm(url, 'post');
            // const updateResponse = await updateCurrentMachine(machineidC, type);
            const updateResponse = updateCurrentMachine(data[item]['machineid'], 1);
            updatePromiseAll.push(updateResponse);
            promiseAll.push(response);
        }

        const resAll = await Promise.all(promiseAll);
        const updateResAll = await Promise.all(updatePromiseAll);
        // '';
        dispatch(getIntancesList());
        // console.log(resAll, 'resAll');
        // console.log(updateResAll, 'updateResAll');

        // dispatch(DO_NOTHING());

    }
}

export function VM_CALL_ACTION_STOP_ALL(data) {
    return async (dispatch) => {
        // let promiseAll = [];
        let updatePromiseAll = [];
        let isAllStop = [];
        for (let item in data) {

            const itemData = data[item]
            const nodeType = data[item]['hostname'];
            const url = twoStopURL(itemData['vmid'], itemData['hostname'], itemData['branchid']);
            const response = await BootVm(url, 'post');
            let { result, code } = response['data'];
            let updateResponse = '';
            if (result == null && code == 'Error') {
                // console.log('Something Went Wrong')
                // console.log(response);
                handlerAlert("danger", response.data.message, "danger");
                dispatch(getIntancesList());
                return;

            }
            // updateResponse = updateCurrentMachine(data[item]['machineid'], 2);

            let resJson = JSON.parse(result);

            let lengthStop = Object.keys(data).length;
            let isStopRes = '';
            let newRes = ``;
            let timeId = setInterval(async function (params) {
                isStopRes = await checkMachineStop(resJson.data, nodeType, itemData['branchid']);
                newRes = JSON.parse(isStopRes.data.result)['data']['status'];
                // console.log(isStopRes, 'isStopRes')
                if (newRes == 'stopped' || newRes == '') {
                    // console.log("DONE")
                    clearInterval(timeId);
                    isAllStop.push(newRes);
                    const updateResponse = await updateCurrentMachine(itemData['machineid'], 2);
                    if (isAllStop.length == lengthStop) {
                        const updateResAll = await Promise.all(updatePromiseAll);
                        // '';
                        // dispatch(update_all_status_end(2));
                        dispatch(getIntancesList());
                        //// console.log(isAllStop.length == lengthStop,'isAllStop.length == lengthStop')
                        return;
                    }
                }
            }, 2000)
            updatePromiseAll.push(updateResponse);
            //promiseAll.push(response);
        }
        // const resAll = await Promise.all(promiseAll);
        //// console.log(resAll, 'resAll');
        //// console.log(updateResAll, 'updateResAll');
        // dispatch(DO_NOTHING());

    }
}


//working function
export function VM_CALL_ACTION_DELETE_ALL(data) {
    return async (dispatch) => {
        // let promiseAll = [];
        '';
        let updatePromiseAll = [];
        let isAllStop = [];
        for (let item in data) {
            const itemData = data[item]
            const nodeType = data[item]['hostname'];
            const url = twodeleteURL(itemData['hostname'], itemData['vmid'], itemData['branchid']);
            const response = await BootVm(url, 'delete');
            let { result, code } = response['data'];
            let updateResponse = '';
            if ((result == null && (code == 'Error' || code == 'InternalServerError')) || code == 'Erro Code: 400 In DataApiManager Set') {
                dispatch(update_all_status_end(3, false, true));
                // console.log('Something Went Wrong')
                // console.log(response)
                handlerAlert("danger", code, 'danger');
                return;

            }
            // updateResponse = updateCurrentMachine(data[item]['machineid'], 3);

            let resJson = JSON.parse(result);

            let lengthStop = Object.keys(data).length;
            let isStopRes = '';
            let newRes = ``;
            let timeId = setInterval(async function (params) {
                // '';
                isStopRes = await checkMachineStop(resJson.data, nodeType, itemData['branchid']);
                newRes = JSON.parse(isStopRes.data.result)['data']['status'];
                // console.log(isStopRes, 'isStopRes')
                if (newRes == 'stopped' || newRes == '') {
                    // console.log("DONE")
                    clearInterval(timeId);
                    isAllStop.push(newRes);
                    const updateResponse = await updateCurrentMachine(itemData['machineid'], 4);
                    if (isAllStop.length == lengthStop) {
                        const updateResAll = await Promise.all(updatePromiseAll);
                        dispatch(getIntancesList());
                        //// console.log(isAllStop.length == lengthStop,'isAllStop.length == lengthStop')
                        return;
                    }
                }
            }, 2000)
            updatePromiseAll.push(updateResponse);
            //promiseAll.push(response);
        }
        // const resAll = await Promise.all(promiseAll);
        //// console.log(resAll, 'resAll');
        //// console.log(updateResAll, 'updateResAll');
        // dispatch(DO_NOTHING());

    }
}


export function closeAllModal() {
    return {
        type: STOP_ALL_MODAL_FINISH
    }
}

export function openAllModal(payload) {
    return {
        type: STOP_ALL_MODAL_START,
        payload
    }
}

export function show_deleteAllMachinesModal() {
    return {
        type: SHOW_DELETE_ALL_MACHINES_MODAL
    }
}

export function hide_deleteAllMachinesModal() {
    return {
        type: HIDE_DELETE_ALL_MACHINES_MODAL
    }
}

export function startAllInstancesVM(payload, type, handlerAlert) {
    // return (dispatch) => {
    //     const promiseAll = [];
    //     '';
    //     for(let item in payload){

    //         const {machineid,code} = payload[item];
    //         let url = twoStartURL(code);
    //         promiseAll.push(dispatch(VM_ActionCall_ALL(url,'post',machineid,type,handlerAlert)));
    //     }

    //     Promise.all(promiseAll).then(()=>{
    //         // console.log('donone')
    //         dispatch(finishLoadingInstances());
    //     })

    // }
}

// export function stopAllInstancesVM(payload, type, handlerAlert) {
//     return (dispatch) => {
//         const promiseAll = [];
//         '';
//         for (let item in payload) {

//             const { machineid, code } = payload[item];
//             let url = twoStopURL(code);
//             promiseAll.push(dispatch(VM_ActionCall_ALL(url, 'post', machineid, type, handlerAlert)));
//         }

//         Promise.all(promiseAll).then(() => {
//             // console.log('donone')
//             dispatch(finishLoadingInstances());
//         })

//     }
// }

// export function deleteAllInstancesVM(payload, type, handlerAlert) {
//     return (dispatch) => {
//         const promiseAll = [];
//         '';
//         for (let item in payload) {

//             const { machineid, code } = payload[item];
//             let url = twodeleteURL(code);
//             promiseAll.push(dispatch(VM_ActionCall_ALL(url, 'delete', machineid, type, handlerAlert)));
//         }

//         Promise.all(promiseAll).then(() => {
//             // console.log('donone')
//             dispatch(finishLoadingInstances());
//         })

//     }
// }

// export function rebootAllInstancesVM(payload, type, handlerAlert) {
//     return (dispatch) => {
//         const promiseAll = [];
//         '';
//         for (let item in payload) {

//             const { machineid, code } = payload[item];
//             let url = twoRebootURL(code);
//             promiseAll.push(dispatch(VM_ActionCall_ALL(url, 'post', machineid, type, handlerAlert)));
//         }

//         Promise.all(promiseAll).then(() => {
//             // console.log('donone')
//             dispatch(finishLoadingInstances());
//         })

//     }
// }

export function putCurrSelectItem(payload) {
    return {
        type: CURR_SELECT_ITEM,
        payload
    }
}

export function clearCurrSelectItem() {
    return {
        type: CLEAR_CURR_SELECT_ITEM
    }
}

export function instanceReinstallShowModal() {
    return {
        type: INSTANCE_REINSTALL_SHOW,
        payload: true
    }
}

export function instanceReinstallHideModal() {
    return {
        type: INSTANCE_REINSTALL_HIDE,
        payload: false
    }
}

export function VM_ActionStarted(payload) {

    return (dispatch, getState) => {
        const { url, method, machineid, num, statusId, machinename } = payload;

        const instances = [...getState().instances.instancesList.data];

        const newInstances = produce(instances, draft => {
            for (let i = 0; i < draft.length; i++) {
                const mId = draft[i].machineid;
                if (mId === machineid) {
                    let text = '';
                    let color = '';
                    // if (num === 1) {
                    //     text = 'Starting'
                    //     color = 'bgl-success'
                    // } else if (num === 2) {
                    //     text = 'Stopping'
                    //     color = 'bgl-danger'
                    // } else if (num === 3) {
                    //     text = 'Destorying'
                    //     color = 'bgl-danger'
                    // } else if (num == 4) {
                    //     text = 'Rebooting'
                    //     color = 'bgl-success'
                    // }else if(num == 5){
                    //     text = "Creating"
                    //     color = 'bgl-success'
                    // }

                    if (num === START_STAGE) {
                        text = 'Starting'
                        color = 'bgl-success'
                    } else if (num === STOP_STAGE) {
                        text = 'Stopping'
                        color = 'bgl-danger'
                    } else if (num === DELETE_STAGE) {
                        text = 'Destorying'
                        color = 'bgl-danger'
                    } else if (num == REBOOT_STAGE) {
                        text = 'Rebooting'
                        color = 'bgl-success'
                    } else if (num == CREATE_STAGE) {
                        text = "Creating"
                        color = 'bgl-success'
                    } else if (num == REINSTALL_STAGE) {
                        text = 'ReInstalling'
                        color = 'bgl-success'
                    }
                    //draft[i]['loadingCurr'] = true;
                    draft[i]['loadingInfo'] = {
                        loading: true,
                        statusId: num,
                        text,
                        color: '',
                        prev: draft[i].statusid
                    }
                    draft[i]['consoleDisabled'] = 'viewConsoleDisable';
                    // draft[i].statusid = 2;
                }
            }

            return draft;
        });

        dispatch(setInstances({
            data: newInstances,
            error: null
        }))
    }
}


export function VM_ActionFinished(payload, isDelete = false, isError = false) {
    return (dispatch, getState) => {
        '';
        const { url, method, machineid, num, statusId, machinename } = payload;
        const instances = [...getState().instances.instancesList.data];
        if (isDelete) {
            let newinstances = [];
            for (let i = 0; i < instances.length; i++) {
                const mId = instances[i].machineid;
                if (machineid != mId) {
                    newinstances.push(instances[i])

                }
            }
            dispatch(setInstances({
                data: newinstances,
                error: null
            }));
            return
        }
        const newInstances = produce(instances, draft => {
            for (let i = 0; i < draft.length; i++) {
                const mId = draft[i].machineid;
                if (machineid == mId) {
                    draft[i].loadingInfo.loading = false;
                    if (!isError) {
                        draft[i].statusid = num
                        draft[i].RefStatusId = num;
                    }
                    draft[i]['consoleDisabled'] = '';

                }
            }
        });

        dispatch(setInstances({
            data: newInstances,
            error: null
        }))
    }


}
