import React from 'react'

function SuspendIcon({width}) {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width={width}  viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2325 5108 c-217 -18 -515 -91 -734 -179 -200 -82 -448 -227 -626
-367 -90 -71 -336 -317 -407 -407 -630 -801 -733 -1882 -264 -2780 68 -131
174 -296 264 -410 71 -90 317 -336 407 -407 801 -630 1882 -733 2780 -264 131
68 296 174 410 264 90 71 336 317 407 407 630 801 733 1882 264 2780 -68 131
-174 296 -264 410 -71 90 -317 336 -407 407 -522 410 -1161 601 -1830 546z
m600 -192 c238 -39 469 -112 690 -222 625 -308 1077 -866 1255 -1549 52 -203
65 -316 65 -585 0 -189 -4 -271 -19 -360 -66 -417 -240 -810 -503 -1137 -41
-51 -79 -95 -84 -98 -19 -12 -729 678 -2009 1952 -366 364 -828 822 -1028
1019 l-362 357 22 25 c13 13 64 57 114 97 325 262 741 445 1135 499 63 9 125
18 139 20 61 11 490 -3 585 -18z m-894 -1937 c1160 -1152 1798 -1779 2056
-2020 l122 -114 -32 -33 c-113 -118 -355 -284 -562 -386 -226 -112 -450 -183
-695 -222 -163 -27 -557 -27 -720 0 -245 39 -469 110 -695 222 -625 307 -1077
866 -1255 1549 -52 203 -65 316 -65 585 0 189 4 271 19 360 61 383 216 756
439 1055 78 103 167 207 177 203 4 -2 549 -541 1211 -1199z"/>
</g>
</svg>

  )
}

export default SuspendIcon