import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ClusterForm from './form/ClusterForm';
import ClusterCreateFormContext from './clusterscontext/ClusterCreateFormContext';
import { openPaymentInfoFormAction } from '../../../../store/actions/PaymentInfoActions/PaymentInfoAction';
import { connect, useDispatch } from 'react-redux';
import EmptyListContainer from '../../hireserver/SharedComponents/EmptyListContainer/EmptyListContainer';
import listBee from '../../../../images/hireserver/imgs/bee/list.png';

function Clusters({isShowPayment,history}) {
    let { path, url } = useRouteMatch();
    const dispatch = useDispatch();

    const [emptyListContainerData, setEmptyListContainerData] = React.useState({
        icon: ' fa fa-database',
        img:listBee,
        svg: <svg height="24" viewBox="0 0 21 24" width="21" xmlns="http://www.w3.org/2000/svg"><path d="M9.75 5.238L5.044 7.995l4.706 2.638zm1.5 13.547l4.735-2.787-4.735-2.758zM4.338 9.374v5.245l4.588-2.668zm11.588-1.379L11.25 5.238v5.395zm-10.94 8.003l4.764 2.787V13.21zm11.646-1.38V9.375l-4.588 2.577zm4.339-.569a.8.8 0 00-.897-.66.814.814 0 00-.648.66l.03 2.218-2.824-1.648v.27c0 .48-.235.899-.647 1.138l2.706 1.559-1.823 1.049c-.353.24-.477.723-.236 1.079.144.212.353.36.618.36.147 0 .265-.03.382-.09l2.97-1.739a.783.783 0 00.369-.689zM9.75 18.785v3.117l-1.853-1.109c-.03-.03-.059-.03-.088-.06-.412-.12-.83.148-.941.54-.094.328.03.659.294.839l3 1.768c.117.06.235.12.382.12.147 0 .265-.03.382-.12l2.971-1.768c.03-.03.059-.03.088-.06a.808.808 0 00.383-1.05.78.78 0 00-1.03-.389c-.088.03-.147.09-.206.15l-1.882 1.139v-3.117l-.147.09c-.382.24-.882.24-1.265 0zm6.882-9.411l2.853-1.589-.03 2.188c0 .42.354.78.766.78.411 0 .779-.36.779-.78V6.466a.82.82 0 00-.368-.689S17.515 3.98 17.456 3.95c-.412-.12-.826.149-.941.57-.09.328.059.659.323.838l1.794 1.05-2.735 1.528.059.03c.382.24.647.66.647 1.109v.3zM4.338 14.589l-2.823 1.648.03-2.218a.752.752 0 00-.883-.63c-.323.066-.603.3-.633.63v3.507c0 .27.133.54.368.69l2.97 1.738c.118.06.236.12.383.09.412-.03.765-.39.735-.81 0-.24-.147-.48-.353-.629l-1.794-1.049 2.677-1.558a1.29 1.29 0 01-.647-1.14v-.27zm.706-6.594L2.28 6.466l1.795-1.049a.797.797 0 00.235-.21.783.783 0 00-.206-1.078.7.7 0 00-.794-.03L.515 5.717a.694.694 0 00-.265.18.729.729 0 00-.25.57v3.506c0 .42.368.78.78.78.411 0 .764-.36.764-.78l-.03-2.218 2.854 1.589v-.21c0-.48.235-.9.647-1.139zm6.088-2.817a1.202 1.202 0 00-1.264 0l-.089.06V2.12L7.956 3.229c-.294.3-.794.3-1.088-.03a.786.786 0 01.03-1.108c.06-.059.117-.12.205-.15.03-.03.059-.03.088-.06L10.132.112c.236-.15.53-.15.765 0l2.97 1.769c.354.24.477.723.236 1.079-.144.212-.353.36-.618.36a.583.583 0 01-.382-.12l-1.824-1.08v3.088z" fillRule="evenodd"></path></svg>,
        title: '',
        content: [
            {
                text: 'Start your cluster today',
                color: 'dark'
            }
        ],
        button: {
            isTrue: true,
            text: 'Create',
            isClickHandler:true,
            clickHandler:function (){
              handlerClickRoute();
            }
        }
    });

    function handlerClickRoute(){
        //alert("hello")
      
        if(isShowPayment == 0 || isShowPayment == undefined){
          dispatch(openPaymentInfoFormAction());
          return;
        }
  
        history.push('/clusters/create')
      }
    return (
        <React.Fragment>
            <Switch>
                <Route exact path={`${path}`}>
                    
                    <EmptyListContainer emptyListContainerData={emptyListContainerData} />
                </Route>
                <Route exact path={`${path}/create`}>
                    <ClusterCreateFormContext>
                        <ClusterForm/>
                    </ClusterCreateFormContext>
                </Route>
            </Switch>

        </React.Fragment>
    )
}
const mapStateToProps = (state) => {
    return {

       isShowPayment:state.PaymentInfoReducer?.paymentData?.defaultaddressid,
    };
};
export default connect(mapStateToProps)(Clusters);
