import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { FAILED_STAGE } from '../../../../../utils/Emuns';


export default function ViewDetailsLink({  statusid,disabledClass = '',machinename, machineId,publicip,privateip,children,noofblocks,searchvalue,code,item,isBare }) {
    const dispatch = useDispatch();
    const {path,url} = useRouteMatch();
    const [sitePath,setSitePath] = React.useState('instances');
    const [blocks,setBlocks] = React.useState('0 Blocks');
    const [isDisable,setIsDisable] = React.useState('');
    React.useEffect(function(){
        // '';
        //'';
        if(path != '/'){
           
            setSitePath(path)
        }
    },[]);
    React.useEffect(function(){
        let num = Number(noofblocks);
        if(num>1){
            setBlocks(`${noofblocks} Blocks`)
        }else if(!num){
            setBlocks(``)
        }else{
            setBlocks(`${noofblocks} Block`)
        }
    },[noofblocks])
    function detailsHandler(e){
        // console.log("workinnggg")
    }

    function handleItem(){
        //// console.log(item)
    }

    React.useEffect(function (){
       if(statusid == FAILED_STAGE){
        setIsDisable('viewConsoleDisable')
       }
    },[statusid]);

    return (
        // <div className={`viewBtnParent `}>
        <div className={`viewBtnParent pt-2 ${isDisable} ${disabledClass}`}>
            <div>
                <Link className='cursor-pointer' onClick={handleItem} to={`${sitePath}/${machineId}/vmdetails/overview`}>
                    <h4 className='text-truncate w-100p' title={machinename}>{machinename}</h4>
                </Link>
            </div>
          { !isBare && <div className='hs-lightgrey'>{ publicip && ` ${publicip}`}</div>}
          {  isBare && <div>{`${blocks.split(' ')[0]} Cores `} { publicip && ` -  ${publicip}`}</div>}
          {/* { !isBare && <div>{`${blocks} `}{ publicip && `- ${publicip}`}</div>} */}
          {/* {  isBare && <div>{`${blocks.split(' ')[0]} Cores `} { publicip && ` -  ${publicip}`}</div>} */}
            <div className='d-none'>{children}</div>
        </div>
    )
}