import { CLEAR_PAYMENT_REDIRECT_PAGE, CREATED_MACHINE_ALERT, HIDE_AMOUNT_ADDED_TO_WALLET, HIDE_PAYMENT_REDIRECT_PAGE, PUT_ORDER_TOKEN, PUT_REDIRECT_PAGE, SHOW_AMOUNT_ADDED_TO_WALLET, SHOW_PAYMENT_REDIRECT_PAGE } from "../../types/RedirectPageTypes/PaymentRedirectPageTypes";

export function hidePagementGateWayAction(data) {
    return {
      type: HIDE_PAYMENT_REDIRECT_PAGE,
      payload: null,
    };
  }

  export function showPagementGateWayAction(data) {
    return {
      type: SHOW_PAYMENT_REDIRECT_PAGE,
      payload: null,
    };
  }

  export function putRedirectLinkAction(link){
    return {
        type:PUT_REDIRECT_PAGE,
        payload:link
    }
  }
  

  export function putOrderIdAction(payload){
    return {
        type:PUT_ORDER_TOKEN,
        payload
    }
  }
  
  export function amountAddToWalletAction_SHOW(){
    return {
        type:SHOW_AMOUNT_ADDED_TO_WALLET
    }
  }
  export function amountAddToWalletAction_HIDE(){
    return {
        type:HIDE_AMOUNT_ADDED_TO_WALLET
    }
  }

  export function createdActionMachine_alert(){
    return {
      type:CREATED_MACHINE_ALERT
    }
  }

  export function clearRedirectPageAction(){
    return {
      type: CLEAR_PAYMENT_REDIRECT_PAGE
    }
  }
  