import React from 'react'

function ImageHs({src,alt,width,height,classes}) {
  return (
    <div style={{
        width:width,
      
        height
    }}>
        <img placeholder='https://www.askgalore.com/wp-content/uploads/2020/06/placeholder.png' className={`w-100 h-100 ${classes}`} src={src} alt={alt} />
    </div>
  )
}

export default ImageHs