import React from 'react';
import { Row, Col, Card, Tab, Tabs, Nav } from 'react-bootstrap'
import SelectCard from './SelectCard';
function CustomTabs({ tabData,className }) {
    //// console.log(tabData)
    return (

        <div className='tabsSelection customTabsSelection'>
            <Tabs defaultActiveKey={tabData[0].name} id="uncontrolled-tab-example" className="mb-3">
                {
                    tabData.map(function ({ name, component }) {
                        return <Tab  eventKey={name} key={name} title={name}>
                            <div className={className}>
                                {component}
                            </div>
                        </Tab>
                    })
                }
            </Tabs>
        </div>

    )
}

export default React.memo(CustomTabs);
