import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast, cssTransition } from 'react-toastify'
import { twodeleteURL, twoStartURL, twoStopURL } from '../../../../../API_ENDPOINTS/API_ENDPOINTS';
import { openDeleteModalBareMetal, openStopBareMetal, putCurrSelectItemBareMetal } from '../../../../../store/actions/BareMetalActions/BareMetalAction';
import { VM_ActionCall_BAREMETAL, VM_ACTION_BARE_STARTED } from '../../../../../store/actions/BareMetalActions/BareMetalCurrItemAction';
import { VM_ActionStarted } from '../../../../../store/actions/InstancesActions/InstanceCurrItemAction';
import { START_VM, STOP_VM } from '../../../../../store/types/GobalTypes';
import { DELETE_STAGE, REBOOT_STAGE, START_STAGE, STOP_STAGE } from '../../../../../utils/Emuns';
import CustomDropDown from '../../../hireserver/components/CustomDropDown';
import { ToastCustomAlert_hs } from '../../../hireserver/ToastCustomAlert_hs';

function DropdownBareMetal({ machineid, code, curSelectItem, statusid, machinetype, machinename, handlerAlert,selectItems,disabledClass='viewConsoleDisable' }) {

    const dispatch = useDispatch();
    const [actionMachineData,setActionMachineData] = React.useState([{ title: 'Start', handler: (e) => actionVmHandler(e,statusid), dataTags: { 'data-machineaction': START_STAGE } },
    { title: 'Stop', handler: actionVmHandler, dataTags: { 'data-machineaction': STOP_STAGE } },
    { title: 'Reboot', handler: actionVmHandler, dataTags: { 'data-machineaction': REBOOT_STAGE } },
    { title: 'Delete', handler: actionVmHandler, dataTags: { 'data-machineaction': DELETE_STAGE } }]);
    const [actionItemState,setActionItemState] = React.useState([]);

    function actionVmHandler(e,statusidC){
        let machineAction = parseInt(e.target.getAttribute('data-machineaction'));
    
        if (machineAction >= 5 || machineAction === 0 || machineAction === NaN) {
            return;
        }

        if (machineAction === 1) {
            let startObj = {
                url: twoStartURL(code),
                method: 'post',
                machineid: machineid,
                num: START_VM,
                statusid: statusidC,
                machinename: machinename
            }
            dispatch(putCurrSelectItemBareMetal(startObj));

            dispatch(VM_ACTION_BARE_STARTED(startObj));

            dispatch(VM_ActionCall_BAREMETAL(twoStartURL(code), 'post', machineid, 1, statusidC, handlerAlert, startObj))
        }else if(machineAction === 2){
            
            let startObj = {
                url: twoStopURL(code),
                method: 'post',
                machineid: machineid,
                num: STOP_VM,
                statusid: statusidC,
                machinename: machinename
            }
            dispatch(putCurrSelectItemBareMetal(startObj));
            dispatch(openStopBareMetal());
           
        }else if(machineAction === 3){
         
            if (statusidC == 1) {
                // console.log('Please stop the Machine');
                const fadeInAndOut = cssTransition({
                    enter: "alertFadein",
                    exit: "alertFadeout"
                })

                toast(<ToastCustomAlert_hs variant="danger" type="delete" text="Stop the Machine Before Delete" />, {
                    autoClose: true,
                    className: 'toasterCustomError',
                    bodyClassName: "toasterCustomBodyAlert",
                    closeOnClick: true,
                    transition: fadeInAndOut,
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                })

                //dispatch(finishLoadingInstances());
                return;
            }
            //dispatch(finishLoadingInstances());
            dispatch(putCurrSelectItemBareMetal({
                url: twodeleteURL(code),
                method: 'delete',
                machineid: machineid,
                num: 3,
                statusid: statusidC,
                machinename: machinename
            }))

            dispatch(openDeleteModalBareMetal());
            //dispatch(showDeleteConfirmModal());
           
        }
        



    }

    React.useEffect(function (){
        let newItems = [];
        for(let i =0;i<actionMachineData.length;i++){
            let currItem = actionMachineData[i];
            if(currItem.dataTags['data-machineaction'] != statusid){
               
                currItem['handler'] = (e) => actionVmHandler(e,statusid);
                newItems.push(currItem);
            }
        }

        setActionItemState(newItems)
    },[statusid])
    
  return (
    <CustomDropDown classNameForDom={`instacesClass listDropdownHS ${disabledClass}  data-class-${code}`} statusid={null} items={actionItemState} >
    <Dropdown.Toggle variant className="table-dropdown i-false" >
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" >
                <rect x="0" y="0" width="24" height="24"></rect>
                <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
        </svg>
    </Dropdown.Toggle>
</CustomDropDown>
  )
}

export default DropdownBareMetal;