import axios from 'axios';
import https from 'https';
import {
    getUserCookieAuth,
    loginConfirmedAction,
    logout,
    setUserCookieAuth,
} from '../store/actions/AuthActions';
import {
    API_CALL,
    API_SERVICES,
    getCommonHeaders
} from './API_SERVICES';
import {
    getOtpValidURL,
    getSendOTPURL,
    getSessionIdValidURL,
    getUpdatePasswordURL,
    getUserDetailsURL,
    reSendOTPURL,
    SEVERNAME,
    signOutURL,
    updateProfilePasswordURL,
    userLoginInURL,
    userSignUpURL
} from '../API_ENDPOINTS/API_ENDPOINTS';
import Cookies from 'js-cookie';
import { USER_COOKIE } from '../store/types/AuthTypes/AuthTypes';
import ErrorClass from './ServiceErrorHandlers/ErrorClass';
import { getpaymentInfoService } from './Profile/PaymentFormServices';
import { openPaymentInfoFormAction, putPaymentInfoStatus } from '../store/actions/PaymentInfoActions/PaymentInfoAction';


export function signUp(email, password) {
    const agent = new https.Agent({
        rejectUnauthorized: false,
    });

    const postData = {
        email,
        password,
        returnSecureToken: true,
        httpsAgent: agent
    };

    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export function login(email, password) {

    const postData = {
        "username": email,
        "Password": password,
        "UnibaseId": "",
        "RememberMe": false,
    }

    return axios.post(
        `${SEVERNAME}/account/login`,
        postData,
    );
}


export function signUpService(payload){
    let config = {
        method: 'post',
        url:userSignUpURL(),
        headers: {
            'Content-Type': 'application/json'
        },
        data:payload
    };

    return API_SERVICES(config);
}

export async function signOutService(){
    let userCookie = Cookies.get(USER_COOKIE)
    let url = signOutURL();

    let config = {
        method: 'get',
        url,
        headers: {
            'Authorization': `Basic ${userCookie}`,
            'Content-Type': 'application/json'
        },
    };

    return API_CALL(config);
}

export function formatError(errorResponse) {
    

}

export function saveTokenInLocalStorage(tokenDetails) {
    // tokenDetails.expireDate = new Date(
    //     new Date().getTime() + tokenDetails.expiresIn * 1000,
    // );
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
    localStorage.setItem('vmCreate',JSON.stringify({
        num:"1"
    }));
}

export function getVmId(max){
    return Math.floor(Math.random() * ((max-1) - 0 + 1) + 0);
}

export function runLogoutTimer(dispatch, timer, history) {
    // let logouttimer = setTimeout(() => {
    //     dispatch(logout(history));
    // }, timer);
}

export async function checkIsSessionValid(sessionId) {

    let config = {
        // ...commonOption,
        method: 'get',
        url: getSessionIdValidURL(),
        headers: {
            'Authorization': `Basic ${sessionId}`
        }
    }

    const req = await axios(config);
    
    return req['data'];
}



export async function checkAutoLogin(dispatch, history) {
   
  //  const errorClass = new ErrorClass();
    ErrorClass.setHistory(history);
    ErrorClass.setDispatch(dispatch);
    //  errorClass.dispatch = dispatch;

    window.customhistory = history;

    let userDetails =Cookies.get(USER_COOKIE)
    if (!userDetails) {
        // dispatch(logout(history));
        
        if(history.location.pathname === '/signup'){
            return;
        }
        if(history.location.pathname === '/login/forgotpassword'){
            return;
        }
        // if(history.location.pathname.includes('redirectwebsite')){
        //     return;
        // }
        history.push('/login')
        return;
    }
    
    let isTrue = null;
    let isFilled = false;
    let paymentData =  null;
    userDetails = JSON.parse(userDetails);
    try {
        
        isTrue = await checkIsSessionValid(userDetails.sessionId);
        // isTrue = isTrue?.data;
     
    //     const checkPaymentStatus = await getpaymentInfoService();
    //    paymentData = JSON.parse(checkPaymentStatus.data.result);
    //   isFilled = paymentData[0]?.defaultaddressid  == 0;
       
    //     // console.log(checkPaymentStatus,'checkPaymentStatus')
    } catch (error) {

        // console.log(error);
        history.push('/no-network')
    }

    if (!isTrue) {
       
        dispatch(logout(history));
        return;
    }
    
    let {unibaseId,sessionId,userId} = userDetails;
    //setUserCookieAuth({...userDetails});
    // saveTokenInLocalStorage(userDetails);
    dispatch(loginConfirmedAction(userDetails));

    try {
        const checkPaymentStatus = await getpaymentInfoService();
        paymentData = JSON.parse(checkPaymentStatus.data.result);
      isFilled = paymentData[0]?.defaultaddressid  == 0;
      // console.log(checkPaymentStatus,'checkPaymentStatus')
    } catch (error) {
        // console.log(error)
    }
    if(paymentData?.length>=1){
        dispatch(putPaymentInfoStatus(paymentData[0]));
    }
    if(isFilled){
        dispatch(openPaymentInfoFormAction())
    }
    
}


export function postOTPValidService(payload){
    let config = {
        method: 'post',
        url:getOtpValidURL(),
        headers: {
            'Content-Type': 'application/json'
        },
        data:payload
    };

    return API_SERVICES(config);
}

export function loginService(email, password, history) {

    const postData = {
        "username": email,
        "Password": password,
        "UnibaseId": "",
        "RememberMe": false,
    }

    return axios.post(
        userLoginInURL(),
        postData,
    );

}

export async function getCurrentPassword(emailOrPhone) {
    const url = getUserDetailsURL(emailOrPhone);

    let config = {
        method: 'get',
        url,

    }

    try {
        const userDetailsWithPass = await axios(config);
        let userHashPasswordBase64 = userDetailsWithPass.data.result['passwordHash']?.split("!")[0];
        //// console.log( userDetailsWithPass.data.result)
        let userNumName = userDetailsWithPass.data.result['userName'];
        let base64ToString = Buffer.from(userHashPasswordBase64, "base64").toString()?.replaceAll('\u0000', '');
        return {
            userPassword: base64ToString,
            userNumName
        }

    } catch (error) {
        // console.log(error)
    }

}


export function updateUserPasswordService(data){
   
    let url = getUpdatePasswordURL();

    let config = {
        method: 'post',
        url,
     data
    };

   return API_SERVICES(config)
}

export async function updateUserPassword(newPassword, userNumId) {
    let url = getUpdatePasswordURL();
    let commonHeader = getCommonHeaders();

    let data = JSON.stringify({
        "username": userNumId,
        "password": newPassword
    });

    let config = {
        headers: { 
            'Content-Type': 'application/json'
          },
        method: 'post',
        data,
        url
    }

    try{
        let res = await axios(config);
        // console.log(res);
        //let isSuccessfully = res.data.message === 'Password Updated Successfully';
        return res;

    }catch(err){
        // console.log(err)
    }
    
}

export function updateProfilePasswordService(data){
    let url = updateProfilePasswordURL();
    let config = {
        headers: { 
            'Content-Type': 'application/json'
          },
        method: 'post',
        data,
        url
    }

    return API_SERVICES(config);
}

export function getUserDetailsService(email){
    let config = {
        method: 'get',
        url:getUserDetailsURL(email),
        headers: {
            'Content-Type': 'application/json'
        },
        
    };

    return API_SERVICES(config);
}


export function postOtpSendService(payload){
    let config = {
        method: 'post',
        url:getSendOTPURL(),
        headers: {
            'Content-Type': 'application/json'
        },
        data:payload
        
    };

    return API_SERVICES(config);
}
export function postResendOTPService(payload){
    let config = {
        method: 'post',
        url:reSendOTPURL(),
        headers: {
            'Content-Type': 'application/json'
        },
        data:payload
        
    };

    return API_SERVICES(config);
}
