import { SEVERNAME } from "./API_ENDPOINTS";

export function getCurrenciesListURL(){
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getcurrencies`;
}

export function getCountriesURL(){
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getcountries`;
}
export function getStatesURL(countryid){
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getcities/countryid/${countryid}`;
}

export function submitPaymentFormURL(){
    return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/savebillinginfo`
}

export function getOrderDetailsURL(orderid){
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getorderinfo/reforderid/${orderid}`;
}

export function postPayRenewalURL(machineid){
    return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/machinerenewal/machineid/${machineid}`
} 