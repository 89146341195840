import React from 'react'
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { closePaymentInfoFormAction } from '../../store/actions/PaymentInfoActions/PaymentInfoAction';
import HeadingHS from '../components/hireserver/controls/HeadingHS';
import ModalHs from '../components/hireserver/ModalHs';
import SpacerHS_TWO from '../components/hireserver/Spaces/SpacerHS_TWO';
import RadioOptionsTabs from '../components/hireserver/Tabs/RadioOptionsTabs';
import IndividualPaymentInfo from './PaymentInfoFormComponents/IndividualPaymentInfo';
import PaymentInfoSuccessFull from './PaymentInfoFormComponents/PaymentInfoSuccessFull';
import PrivatePaymentInfo from './PaymentInfoFormComponents/PrivatePaymentInfo';
import AutoAnimateHeight from 'react-auto-animate-height'

function PaymentInfoForm({isPaymentInfo,organizationtype,isShowPayment}) {
  const dispatch = useDispatch();
  const [paymentDone,setPaymentDone] = React.useState(false);
  const [isUpdate,setUpdate] = React.useState(false);
  const billingInfoRef = React.createRef(null);

  React.useEffect(function (){
    return () => {
      setPaymentDone(false);
    }
  },[]);
  
    function handleModalClose(){
      dispatch(closePaymentInfoFormAction())
    }

    function handleDone(){

    }

    function changeHandler(e){
    //   console.log(billingInfoRef.current);
    //   let x  = ReactDOM.findDOMNode(billingInfoRef)
    //  console.log(x,'xx')
    // console.log(billingInfoRef.current);
    // setTimeout(function (){
    //   const modalHeight = billingInfoRef.current?.firstChild.getBoundingClientRect().height;
    //   console.log(modalHeight)
    //   billingInfoRef.current.style.maxHeight = `${modalHeight}px`;
    //   console.log(billingInfoRef.current.querySelector('.privatePaymentInfo'))
    // },100);
    }

    const [paymentInfoOptions,setPaymentInfoOptions] = React.useState({
        name:'paymentInfo',
        // options:[
        //     {
        //         name:'Individual', 
        //         component:<h1>Individual</h1>
        //     },
        //     {
        //         name:"Private",
        //         component:<h1>Private</h1>
        //     }
        // ],
        options:{
            '6574':{
              id:6574,
              component:<IndividualPaymentInfo setUpdateHandler={setUpdate} successHandler={setPaymentDone}  />,
              name:'Individual'
            },
            "6578":{
              id:6578,
              component:<PrivatePaymentInfo setUpdateHandler={setUpdate} successHandler={setPaymentDone}  />,
              name:'Business'
            }
        }

    })


  return (
    <ModalHs
    backdrop="static"
      size={"lg"}
      footerNone={true}
      classNameMain="authModalAlert"
      handleClose={handleModalClose}
      okayHandler={handleDone}
      showModalHS={isPaymentInfo}
      btnTitle="Submit"
      okayHandlerBtnColor="hs-signin-btn"
      isNeed={false}
      
    >
      <AutoAnimateHeight className='w-100'>
      <div ref={billingInfoRef} className='w-100  billingFormParent  d-flex flex-column justify-content-center align-items-center'>
        {/* <div className='text-center'>
            <label htmlFor='individual'>
            
                <input name='paymentinfo' id='individual' type="radio"  />
                Individual
            </label>
            <label htmlFor='private'>
            
                <input name='paymentinfo' id='private' type="radio"  />
                Private
            </label>
        </div> */}
       {!paymentDone && isShowPayment === 0 && <RadioOptionsTabs defaultOption={"6574"} changeHandler={changeHandler} optionsData={paymentInfoOptions} noDefault={true} />}

      {!paymentDone && organizationtype && isShowPayment !=0 && <div className='billingFromDiv py-2 pb-3 text-center' >
        <HeadingHS as="h3 text-primary" title="Edit Billing Form" />
       </div>
}
       {!paymentDone && organizationtype === 1  && isShowPayment !=0 && <PrivatePaymentInfo setUpdateHandler={setUpdate} successHandler={setPaymentDone}  /> }

       {!paymentDone && organizationtype === 2 && isShowPayment !=0 &&  <IndividualPaymentInfo setUpdateHandler={setUpdate} successHandler={setPaymentDone}  /> }


       {/* don't touch this one */}
       {/* {!paymentDone && <div className='hs_sub_heading text-primary'>Billing Info</div>}
       <SpacerHS_TWO/>
     {!paymentDone &&  <PrivatePaymentInfo setUpdateHandler={setUpdate} successHandler={setPaymentDone}  />}

       {
        paymentDone && <PaymentInfoSuccessFull setUpdateHandler={setUpdate} isUpdate={isUpdate} successHandler={setPaymentDone} />
       } */}
        
      </div>
      </AutoAnimateHeight>
      
    </ModalHs>
  )
}

const mapStatesToProps = ({ authAlertReducer,auth,PaymentInfoReducer }) => {
    return {
     
      isPaymentInfo:PaymentInfoReducer.isPayment,
      isShowPayment:PaymentInfoReducer?.paymentData?.defaultaddressid,
      organizationtype:PaymentInfoReducer?.paymentData?.organizationtype,
    };
  };
  
  export default connect(mapStatesToProps)(withRouter(PaymentInfoForm));