import React from 'react';
import { Card } from 'react-bootstrap';

import temperature from '../../../../../images/hireserver/imgs/temperature.png';

function TempDetailsCard({data}) {
    const {ReadingCelsius,Name,Status} = data;
    const {Health,State} = Status;
   
    return (
        <div className='col-sm-12 col-md-6 col-lg-4 col-xxl-4 '>
            <Card className='d-flex flex-column d-gap-20 shadow px-4 py-3'>
            <div className='d-flex justify-content-between'>
                <div>
                    {Name}
                </div>
                <div className='text-success'>
                    {Health}
                </div>
            </div>
            <div className='d-flex justify-content-between align-items-baseline'>

                <div className='d-flex d-gap-10 align-items-end'>
                    <div className='w-50p'>
                        <img className='w-100 h-100' src={temperature} alt="temperatureImg" />

                    </div>
                    <div className='font-30 font-w500'>
                        {ReadingCelsius}<sup>°</sup>F
                    </div>
                </div>



                <div className='font-w500'>{State}</div>
            </div>
            </Card>
        </div>
    )
}

export default React.memo(TempDetailsCard)