import React from 'react'
import { Dropdown } from 'react-bootstrap';
import ThreeDots from '../ThreeDots';
import './ButtonOptions.css';

function ButtonOptions({options,text}) {
    return (
        <React.Fragment>
            <div className='buttonOptions d-flex p-1'>
                <div className='textbuttonOptions BOItem d-flex justify-content-center align-items-center'>
                    {text}
                </div>

                <div className='flex-grow-1 BOItem d-flex justify-content-center align-items-center'>
                    {/* <span className='dropdownbuttonoption'>
                        <i className="fa fa-angle-down"></i>
                    </span> */}
                    <Dropdown>

                    <Dropdown.Toggle className='hs_borderRadius-4 toggleButtonOps border-0 p-0' id="dropdown-basic">
                    <span className='dropdownbuttonoption'>
                        <i className="fa fa-angle-down"></i>
                    </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {options.length >=1 ? options?.map(function (el,i){
                            return <Dropdown.Item onClick={el.handler} key={i} className='font-09 py-1 '>{el.name}</Dropdown.Item>
                        }):null}
                    
                    </Dropdown.Menu>
                    </Dropdown>
                </div>

                
            </div>
            {/* <Dropdown>
      <Dropdown.Toggle className='hs_borderRadius-4 hsButton-bg border-0' id="dropdown-basic">
       Send
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}
        </React.Fragment>
    )
}

export default ButtonOptions