import { getSupportCloseListURL, getSupportItemByIdURL, getSupportOpenListURL, getTicketGoupsURL, getTicketPrioritiesURL, postSupportTicketURL } from "../../API_ENDPOINTS/SUPPORT_ENDPOINTS";
import { getUserCookieAuth } from "../../store/actions/AuthActions";
import { API_SERVICES } from "../API_SERVICES";
import { getSessionAndUserId } from "../InstancesServices/InstancesListService";

export function getSupportOpenListService(callbackURL){
    let  { userId, sessionId } = getSessionAndUserId();
    let url = null;
    if(!callbackURL){
        url = getSupportOpenListURL(userId);
    }else{
        url = callbackURL(userId);
    }
    // let url = getSupportOpenListURL(userId);

    let config = {
        method: 'get',
        url,
        headers: { 'Authorization': `Basic ${sessionId}` }
    };

   return API_SERVICES(config)
}
export function getSupportCloseListService(callbackURL){
    let  { userId, sessionId } = getSessionAndUserId();
    let url = callbackURL(userId);
    // let url = getSupportCloseListURL(userId);

    let config = {
        method: 'get',
        url,
        headers: { 'Authorization': `Basic ${sessionId}` }
    };

   return API_SERVICES(config)
}


export function getTicketGoupsService(){
    let  { userId, sessionId } = getSessionAndUserId();
    let url = getTicketGoupsURL();
    let config = {
        method: 'get',
        url,
        headers: { 'Authorization': `Basic ${sessionId}` }
    };

   return API_SERVICES(config)

}
export function getTickePrioritiesService(){
    let  { userId, sessionId } = getSessionAndUserId();
    let url = getTicketPrioritiesURL();
    let config = {
        method: 'get',
        url,
        headers: { 'Authorization': `Basic ${sessionId}` }
    };

   return API_SERVICES(config)

}

export function postSupportTicketService(payload){
    let  { userId, sessionId } = getSessionAndUserId();
    let url = postSupportTicketURL();
    //console.log({...payload,contactid:userId})
    let config = {
        method: 'post',
        url,
        data:{...payload,contactid:userId},
        headers: { 'Authorization': `Basic ${sessionId}` }
    };

   return API_SERVICES(config)

}

export function getSupportItemByIdService(ticketId){
  
    let  { userId, sessionId } = getSessionAndUserId();
    let url =   getSupportItemByIdURL(ticketId)
    //console.log({...payload,contactid:userId})
    let config = {
        method: 'get',
        url,
        headers: { 'Authorization': `Basic ${sessionId}` }
    };

   return API_SERVICES(config);
}
