import React from 'react'

function InputInfoFormLabel({type,label,disable=false,value,onChange}) {
  return (
    <div>
        <div className='card-title font-16'>
            {label}
        </div>
        <input onChange={onChange} value={value} disabled={disable} type={type} className=" py-4 form-control input-default hs_borderRadius-4 form-control-sm p-2 font-14" />
    </div>
  )
}

export default InputInfoFormLabel