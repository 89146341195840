import Cookies from "js-cookie";
import { handlerAlert } from "../../jsx/components/Dashboard/Instances/InstancesListComponents/DropDownInstancesList";
import {
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signOutService,
  signUp,
} from "../../services/AuthService";
import { getpaymentInfoService } from "../../services/Profile/PaymentFormServices";
import ErrorClass from "../../services/ServiceErrorHandlers/ErrorClass";
import { IS_SIGN_UP_FALSE, IS_SIGN_UP_TRUE, SIGNOUT_FAILED, USER_COOKIE, USER_COOKIE_OPTIONS } from "../types/AuthTypes/AuthTypes";
import { openPaymentInfoFormAction, putPaymentInfoStatus } from "./PaymentInfoActions/PaymentInfoAction";



export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const AUTH_LOADING = "AUTH_LOADING";

export const PASSWORD_CHANGED_SUCCESSFUL = 'PASSWORD_CHANGED_SUCCESSFUL';
export const PASSWORD_REMOVE_CHANGED_SUCCESSFUL = 'PASSWORD_REMOVE_CHANGED_SUCCESSFUL';

export function passwordChangedAuthAction(){
  return {
    type:PASSWORD_CHANGED_SUCCESSFUL,
    payload:true
  }
}

export function passwordRemoveChangedAuthAction(){
  return {
    type:PASSWORD_REMOVE_CHANGED_SUCCESSFUL,
    payload:false
  }
}

//  user cookies
export function getUserCookieAuth(){

    let userCookie = Cookies.get(USER_COOKIE);
    if(userCookie == null || userCookie == undefined){
  
     ErrorClass.history.go(0);
     return;
    }
    if(userCookie){
        return JSON.parse(userCookie);
    }
    return null;
}

export function setUserCookieAuth(data) {
  // let inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000);
  // console.log("setttt cookieeeeeeeeeeeeeeee")
debugger;
  Cookies.set(USER_COOKIE, JSON.stringify(data), {
    // expires: inFifteenMinutes,
    ...USER_COOKIE_OPTIONS,
  });
}

export function removeUserCookieAuth(){

  // console.log('removing cookie')
    Cookies.remove(USER_COOKIE,USER_COOKIE_OPTIONS);
}

//  user cookies end

export function signupAction(email, password, history) {
  return (dispatch) => {
    signUp(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        setUserCookieAuth(response.data.result);
        runLogoutTimer(dispatch, response.data.expiresIn * 1000, history);
        dispatch(confirmedSignupAction(response.data));
        history.push("/");
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export async  function logOut(){
  let url = `http://machines.bizgaze.com/Account/Domain`;
  let config = {
    method:'get',
    url,
    headers: { 
      'Authorization': 'Basic fc4cc4f8-bf98-414c-b355-1512370f4416'
    }
  }
}

export function signUpTrueAction(){
  return {
    type:IS_SIGN_UP_TRUE
  }
}

export function signUpFalseAction(){
  return {
    type:IS_SIGN_UP_FALSE
  }
}

export function logout(history) {
  return async (dispatch,state) => {
    try {
      
      //dispatch(loadingToggleAction())
      dispatch({
        type: LOGOUT_ACTION,
      });
      dispatch(AuthLoading(true))
    
    
    
      
     
      let res = await signOutService();
      const {message,errors} = res.data;
      // let {data:{errors,message}} = await signOutService();
      if( message != '' && errors!= null){
        dispatch({
          type:SIGNOUT_FAILED,
          payload:message
        });
        return;
      }
      
      removeUserCookieAuth();
      localStorage.clear();
      Object.keys(Cookies.get()).forEach(function(cookieName) {
        var neededAttributes = {
          // Here you pass the same attributes that were used when the cookie was created
          // and are required when removing the cookie
        };
        
        // console.log('removing cookie')
        Cookies.remove(cookieName, neededAttributes);
      });
     
      //history.push("/login");
      // window.customhistory.push('/login')
      ErrorClass.history.push('/login');
      dispatch(AuthLoading(false))
      
      
     
    
    } catch (error) {
      // return {
      //   type: LOGOUT_ACTION,
      // };
      dispatch({
        type: LOGOUT_ACTION,
      });
     if(error.message == 'Network Error'){
      // history.push('/no-network');
      ErrorClass.history.push('/no-network');
     }
    }
  }
}


export function loginAction(email, password, history) {

  return (dispatch) => {
    
    const errorClass = new ErrorClass();
    errorClass.dispatch = dispatch;
    errorClass.history = history;
    
   
  
    login(email, password)
      .then(async (response) => {
       
        // if (response.data.status === 2) {
        //   const errorMessage = formatError(response.data.errors[0]);
        //   dispatch(loginFailedAction(errorMessage));
        // }
      // response.data.result['sessionId']="fc851785-5b4a-4ca7-9f88-3be2701aedee";
       
        if(response.data.errors?.length >= 1){
          //dispatch(loadingToggleAction())
          handlerAlert("danger",'errrror','danger')
          return;
        }
        response.data.result['userEmail'] = email;
        let {unibaseId,sessionId,userId} = response.data.result;
        setUserCookieAuth({...response.data.result});
       
        // // console.log(response.data.result);
        saveTokenInLocalStorage(response.data.result);

        //runLogoutTimer(dispatch, 1800000, history);
        dispatch(loginConfirmedAction(response.data.result));
        let isFilled = false;
      let paymentData =  null;
      const checkPaymentStatus = await getpaymentInfoService();
      paymentData = JSON.parse(checkPaymentStatus.data.result);
      isFilled = paymentData[0]?.defaultaddressid  == 0;
      dispatch(putPaymentInfoStatus(paymentData[0]));
      if(isFilled){
        dispatch(openPaymentInfoFormAction())
      }
   
       history.push("/");
       dispatch(loadingToggleAction())
      })
      .catch((error) => {
    
        const errorMessage = formatError(error);
        // console.log(error);
        if(error.message === 'Network Error'){
          history.push('no-network');
        }
        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}


export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}

export function AuthLoading(payload){
  return {
    type:AUTH_LOADING,
    payload
  }
}
