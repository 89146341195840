/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import { ToggleSideContextState } from "../../../context/toggleSideBar/ToggleSideContext";
import { isMobile } from 'react-device-detect';
import { logout } from "../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";
import { resetNotice } from "../../../store/reducers/NotificationReducer";
import { clearInstancesReducer } from "../../../store/actions/InstancesActions/InstancesActions";
import { ThemeContext } from "../../../context/ThemeContext";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() { }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}



function SideBar() {
  const dispatch = useDispatch();
  let path = window.location.pathname;
  const { toggle, setToggle } = React.useContext(ToggleSideContextState);
  const { toggleTheme, toggleThemeSet, logoutHandlerTheme } = React.useContext(ThemeContext);
  // path = path.split("/");
  // path = path[path.length - 1];
  // let { path, url } = useRouteMatch();

  /// Active menu
  let dashBoard = [
    "",
    "analytics",
    "review",
    "order",
    "order-list",
    "customer-list",
    "task",
  ],

    home = [""],
    support = ["/support", "/support/opentickets/create", "/support/opentickets", "/support/closedtickets"],

    settings = ["/widget-basic"],
    profile = ["/profile", "/profile/username", "/profile/billinginfo", "/profile/settings", "/profile/orders", "/profile/transaction", "/profile/renewal"],
    DATABASES = ["/databases", "/databases/create"],
    NODEBALANCER = ["/nodebalance", "/nodebalance/create"],
    FIREWALL = ["/firewall", "/firewall/create", "/firewalldetails"],
    HS_BLOCKS = ["HS_BLOCKS"],
    // BRAW_MENTAL = ["/baremetal"],
    marketplace = ["/marketplace"],
    scriptsPage = ["/scripts"],
    BRAW_MENTAL = ["/baremetal", "/baremetal/create"],
    clusters = ['/clusters']
  React.useEffect(function () {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }, []);

  function toggleItemnav() {
    if (isMobile) {
      document.querySelector("#main-wrapper").classList.toggle("menu-toggle");
      setToggle(!toggle)
    }
  }

  function onLogout() {
    //     '';
   
    dispatch(clearInstancesReducer());
    dispatch(logout());
    logoutHandlerTheme();
    localStorage.setItem('theme', JSON.stringify('light'));
    dispatch(resetNotice())
    //  dispatch(clearInstancesReducer());
    // console.log('logout')
    // window.location.reload();
  }


  return (
    <div className="deznav">
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          {/* <li className={` ${window.location.pathname === '/' ? "mm-active" : ""}`}>
            <Link onClick={toggleItemnav} to="/" className="ai-icon">
              <i className="flaticon-381-networking"></i>
              <img src="https://192.168.89.28/tenants/themes/compact/imgs/dashboard.svg" alt="Home"/>
              <span className="nav-text">Home</span>
            </Link>
          </li> */}
          <li className={`${path.includes('instances')  ? "mm-active" : ""}`}>
            <Link to="/instances" onClick={toggleItemnav} className="ai-icon">
              <i className="flaticon-381-layer-1"></i>
              {/* <img src="https://192.168.89.28/tenants/themes/compact/imgs/dashboard.svg" alt="Home"/> */}
              <span className="nav-text">Instances</span>
            </Link>
          </li>

          

          <li className={`${path.includes('baremetal') ? "mm-active" : ""}`}>
            <Link onClick={toggleItemnav} to="/baremetal" className="ai-icon">


              {/* <svg id="Layer_1"  height="24"
                viewBox="0 0 21 24"
                width="21" data-name="Layer 1" xmlns="http://www.w3.org/2000/ */}



              <svg width="32px" height="32px" viewBox="0 0 32 32" id="icon" xmlns="http://www.w3.org/2000/svg">
                <defs>

                </defs>
                <circle cx="7" cy="23" r="1" />
                <rect x="2" y="6" width="4" height="2" />
                <rect x="8" y="6" width="4" height="2" />
                <rect x="14" y="6" width="4" height="2" />
                <rect x="20" y="6" width="4" height="2" />
                <rect x="26" y="6" width="4" height="2" />
                <path d="M28,28H4a2.0021,2.0021,0,0,1-2-2V20a2.0021,2.0021,0,0,1,2-2H28a2.0021,2.0021,0,0,1,2,2v6A2.0021,2.0021,0,0,1,28,28ZM4,20v6H28V20Z" transform="translate(0 0)" />
                <rect x="2" y="12" width="28" height="2" />

              </svg>


              <span className="nav-text">Bare Metal</span>
            </Link>
          </li>

          <li className={`${path.includes('firewall') ? "mm-active" : ""}`}>
            <Link onClick={toggleItemnav} to="/firewall" className="ai-icon">
              <svg viewBox="0 0 215 215" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M214.84 136.33a31.58 31.58 0 00-28.76-28.18A51.33 51.33 0 0099.79 59.5v26.29h33.27v28.18h33.26v35.43H26v-28.18H10.75a39 39 0 0035.88 49.54H186.5a31.37 31.37 0 0028.34-34.43zM66.5 93.04h59.3v20.92H66.5zM33.26 64.86H92.5v20.92H33.26zM0 93.04h59.27v20.92H0zm33.26 28.18H92.5v20.92H33.26zm66.53 0h59.27v20.92H99.79z"
                  fill="currentColor"
                ></path>
              </svg>
              <span className="nav-text">Firewalls</span>
            </Link>
          </li>

          <li className={`${clusters.includes(path) ? "mm-active" : ""}`}>
            <Link onClick={toggleItemnav} to="/clusters" className="ai-icon">
              <svg
                viewBox="0 0 25.397 23.5"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.699 0a12.68 12.68 0 00-6.63 23.5v-3.44a6.63 6.63 0 1113.26 0v3.44A12.68 12.68 0 0012.699 0zm0 12.08a4.34 4.34 0 114.34-4.34 4.34 4.34 0 01-4.34 4.34z"
                  fill="currentColor"
                ></path>
              </svg>
              <span className="nav-text">Clusters</span>
            </Link>
          </li>

          <li className={`${marketplace.includes(path) ? "mm-active" : ""}`}>
            <Link onClick={toggleItemnav} to="/marketplace" className="ai-icon">
              <svg
                height="24"
                viewBox="0 0 20 24"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.325 12.817L10 9.867l-5.325 2.95v5.901L10 21.668l5.325-2.95zM.5 23.5h19V8.035H.5zM15.437 6.64l-1.111-.043c.076-1.783-.74-3.455-2.13-4.363a3.965 3.965 0 00-4.392 0c-1.39.908-2.206 2.58-2.13 4.363l-1.111.044c-.091-2.15.911-4.176 2.616-5.289a5.095 5.095 0 015.642 0c1.705 1.113 2.707 3.14 2.616 5.289z"
                  fill="currentColor"
                  fillRule="evenodd"
                ></path>
              </svg>
              <span className="nav-text">Marketplace</span>
            </Link>
          </li>

          <li className={`${path.includes('scripts')&&!path.includes('instances') ? "mm-active" : ""}`}>
            <Link onClick={toggleItemnav} to="/myscripts/scripts" className="ai-icon">
            <svg fill="#000000" width="800px" height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path d="M18,18 L18,4.5 C18,3.67157288 17.3284271,3 16.5,3 L5.73243561,3 C5.90260571,3.29417337 6,3.63571286 6,4 L6,20 C6,20.5522847 6.44771525,21 7,21 C7.55228475,21 8,20.5522847 8,20 L8,18.5 C8,18.2238576 8.22385763,18 8.5,18 L18,18 Z M19,18 L21.5,18 C21.7761424,18 22,18.2238576 22,18.5 L22,19.5 C22,20.8807119 20.8807119,22 19.5,22 L7,22 C5.8954305,22 5,21.1045695 5,20 L5,6 L4,6 C2.8954305,6 2,5.1045695 2,4 C2,2.8954305 2.8954305,2 4,2 L16.5,2 C17.8807119,2 19,3.11928813 19,4.5 L19,18 Z M9,19 L9,20 C9,20.3642871 8.90260571,20.7058266 8.73243561,21 L19.5,21 C20.3284271,21 21,20.3284271 21,19.5 L21,19 L9,19 Z M5,5 L5,4 C5,3.44771525 4.55228475,3 4,3 C3.44771525,3 3,3.44771525 3,4 C3,4.55228475 3.44771525,5 4,5 L5,5 Z M8.5,7 C8.22385763,7 8,6.77614237 8,6.5 C8,6.22385763 8.22385763,6 8.5,6 L15.5,6 C15.7761424,6 16,6.22385763 16,6.5 C16,6.77614237 15.7761424,7 15.5,7 L8.5,7 Z M8.5,10 C8.22385763,10 8,9.77614237 8,9.5 C8,9.22385763 8.22385763,9 8.5,9 L15.5,9 C15.7761424,9 16,9.22385763 16,9.5 C16,9.77614237 15.7761424,10 15.5,10 L8.5,10 Z M8.5,13 C8.22385763,13 8,12.7761424 8,12.5 C8,12.2238576 8.22385763,12 8.5,12 L15.5,12 C15.7761424,12 16,12.2238576 16,12.5 C16,12.7761424 15.7761424,13 15.5,13 L8.5,13 Z M8.5,16 C8.22385763,16 8,15.7761424 8,15.5 C8,15.2238576 8.22385763,15 8.5,15 L13.5,15 C13.7761424,15 14,15.2238576 14,15.5 C14,15.7761424 13.7761424,16 13.5,16 L8.5,16 Z"/>
</svg>
              <span className="nav-text">Scripts</span>
            </Link>
          </li>

          <li className={`${path.includes('support') ? "mm-active" : ""}`}>
            <Link onClick={toggleItemnav} to="/support/opentickets" className="ai-icon">
              {/* <Link  onClick={toggleItemnav}  to="/support/opentickets" className="ai-icon"> */}
              <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="32.000000pt" height="32.000000pt" viewBox="0 0 32.000000 32.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
                  fill="#fffff" stroke="none">
                  <path d="M46 253 c-24 -24 -27 -33 -22 -68 4 -22 7 -49 7 -60 1 -18 8 -20 60
-20 75 1 104 24 104 86 0 81 -92 119 -149 62z m85 -29 c11 -14 10 -18 -6 -30
-24 -18 -46 -18 -22 0 23 18 22 29 -3 21 -21 -7 -28 10 -7 18 20 9 24 8 38 -9z
m-21 -64 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z"/>
                  <path d="M210 185 c0 -38 -35 -85 -65 -85 -23 0 -18 -16 11 -39 36 -28 138
-29 129 -1 -3 10 -1 21 5 25 5 3 10 21 10 40 0 38 -31 79 -67 90 -21 6 -23 3
-23 -30z"/>
                </g>
              </svg>
              <span className="nav-text">Support</span>
            </Link>
          </li>
          <li className={`${profile.includes(path) ? "mm-active" : ""}`}>
            <Link onClick={toggleItemnav} to="/profile/username" className="ai-icon">
              <svg
                viewBox="0 0 25.397 23.5"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.699 0a12.68 12.68 0 00-6.63 23.5v-3.44a6.63 6.63 0 1113.26 0v3.44A12.68 12.68 0 0012.699 0zm0 12.08a4.34 4.34 0 114.34-4.34 4.34 4.34 0 01-4.34 4.34z"
                  fill="currentColor"
                ></path>
              </svg>
              <span className="nav-text">Profile</span>
            </Link>
          </li>
     
          {/* logout */}
          <li>
            <Link to="#" onClick={onLogout} className="ai-icon">
              <svg width="25px" height="25px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" class="icon">
                <path d="M868 732h-70.3c-4.8 0-9.3 2.1-12.3 5.8-7 8.5-14.5 16.7-22.4 24.5a353.84 353.84 0 0 1-112.7 75.9A352.8 352.8 0 0 1 512.4 866c-47.9 0-94.3-9.4-137.9-27.8a353.84 353.84 0 0 1-112.7-75.9 353.28 353.28 0 0 1-76-112.5C167.3 606.2 158 559.9 158 512s9.4-94.2 27.8-137.8c17.8-42.1 43.4-80 76-112.5s70.5-58.1 112.7-75.9c43.6-18.4 90-27.8 137.9-27.8 47.9 0 94.3 9.3 137.9 27.8 42.2 17.8 80.1 43.4 112.7 75.9 7.9 7.9 15.3 16.1 22.4 24.5 3 3.7 7.6 5.8 12.3 5.8H868c6.3 0 10.2-7 6.7-12.3C798 160.5 663.8 81.6 511.3 82 271.7 82.6 79.6 277.1 82 516.4 84.4 751.9 276.2 942 512.4 942c152.1 0 285.7-78.8 362.3-197.7 3.4-5.3-.4-12.3-6.7-12.3zm88.9-226.3L815 393.7c-5.3-4.2-13-.4-13 6.3v76H488c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h314v76c0 6.7 7.8 10.5 13 6.3l141.9-112a8 8 0 0 0 0-12.6z" />
              </svg>

              <span className="nav-text">Logout</span>
            </Link >
          </li>
          {/* <li className={`${settings.includes(path) ? "mm-active" : ""}`}>
            <Link to="widget-basic" className="ai-icon" >
              <i className="flaticon-381-internet"></i>
              <img src="https://192.168.89.28/tenants/themes/compact/imgs/dashboard.svg" alt="Home"/>
              <span className="nav-text">Settings</span>
            </Link>
          </li> */}
        </MM>
      </PerfectScrollbar>
    </div>
  );
}

export default SideBar

