import React from 'react'
import Dedicatedcpuselection from './Dedicatedcpuselection'

const Distributiondedicatedcpu = () => {
  
  const [selectServer,setSelectServer] = React.useState({name:'',price:'',perhour:''})
  return (
    <div>
        <Dedicatedcpuselection selectServer={selectServer} setSelectServer={setSelectServer}/>
    </div>
  )
}

export default Distributiondedicatedcpu