import React from 'react';
import './InputLabelUp.css';

function InputLabelUp({ type, id, placeholder, value, setValue, classNames, onKeyDown, disabled, focusHandler, focusOutHandler }) {
  const inputRef = React.createRef(null);


  React.useEffect(function () {

    

    if (value != '') {
      inputRef.current.classList.add('inputLabelValueThere')
    } else {
      inputRef.current.classList.remove('inputLabelValueThere')
    }

  }, [value, classNames]);

  function handlerClickLabel(e) {
    
    if (focusHandler) {
      focusHandler(e);
    }
  }
  if (onKeyDown) {
    return <div className='inputLabelUpContainer w-100'>
      <input autoComplete="new-password" onKeyDown={onKeyDown} disabled={disabled} ref={inputRef} value={value} onChange={setValue} onFocus={handlerClickLabel} id={id} className={`${classNames} pt-3 font-14 form-control  hs_borderRadius-4 inputLabelUpInput w-100 h-49`} type={type} />
      <label className='inputLabelUpInputLabel' htmlFor={id}>
        {placeholder}
      </label>
    </div>
  }
  if (focusOutHandler) {
    return (
      <div className='inputLabelUpContainer w-100'>
        <input autoComplete="new-password" disabled={disabled} ref={inputRef} value={value} onChange={setValue} onBlur={focusOutHandler} id={id} className={`${classNames} pt-3 font-14 form-control hs_borderRadius-4  inputLabelUpInput w-100 h-49`} type={type} />
        <label className='inputLabelUpInputLabel' htmlFor={id}>
          {placeholder}
        </label>
      </div>
    )
  }
  return (
    <div className='inputLabelUpContainer w-100'>
      <input autoComplete="new-password" disabled={disabled} ref={inputRef} value={value} onChange={setValue} onFocus={handlerClickLabel} id={id} className={`${classNames} pt-3 font-14 form-control hs_borderRadius-4  inputLabelUpInput w-100 h-49`} type={type} />
      <label className='inputLabelUpInputLabel' htmlFor={id}>
        {placeholder}
      </label>
    </div>
  )
}

export default InputLabelUp