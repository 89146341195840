import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import logo from '../../../images/hireserver/svg/logo_single.svg';
import { blockInvalidChar } from '../../../utils/Helpers';
import FormInputError from './components/FormInputError';
import InputErrorBackground from './components/InputErrorBackground';
import SpacerHS_ONE from './Spaces/SpacerHS_ONE';


let styles = {
  logoStyles: {
    width: '50px',
    height: '50px'
  }
}

export const PLUS_BTN = 'PLUS_BTN';
export const MINUS_BTN = 'MINUS_BTN';

export function MiniShowUseAgeCard({ imgSrc, title, num, type, blocksInput,specs }) {
  const [newNum, setNewNum] = React.useState(4);
  // const [storage,setStorage] = React.useState(60);
  const [leftValues, setLeftValues] = React.useState(0);
  const [typeVal,setTypeVal] = React.useState('');
  const [name, setName] = React.useState('');
  const [cTitle, setCTitle] = React.useState('');
  const [Svg, setSvg] = React.useState('');
  let isLeft;
  React.useEffect(function () {
  
    if(!specs) return;
    
    let color = "#068D8A"
    setName(capitalizeFirstLetter(type));
    setCTitle(capitalizeFirstLetter(title));
    const {storage} = specs;
    switch (type) {
      case 'cores':
        setNewNum(blocksInput * 1);
        setLeftValues(num);
        setTypeVal('cores');
        setSvg(<svg xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 54.488 54.488">
          <g id="cpu" transform="translate(0 0)">
            <g id="Group_6218" data-name="Group 6218" transform="translate(12.384 12.384)">
              <g id="Group_6217" data-name="Group 6217">
                <circle id="Ellipse_35" data-name="Ellipse 35" cx="1.238" cy="1.238" r="1.238" fill={color} />
              </g>
            </g>
            <g id="Group_6220" data-name="Group 6220" transform="translate(39.628 12.384)">
              <g id="Group_6219" data-name="Group 6219">
                <circle id="Ellipse_36" data-name="Ellipse 36" cx="1.238" cy="1.238" r="1.238" fill={color} />
              </g>
            </g>
            <g id="Group_6222" data-name="Group 6222" transform="translate(12.384 39.628)">
              <g id="Group_6221" data-name="Group 6221">
                <circle id="Ellipse_37" data-name="Ellipse 37" cx="1.238" cy="1.238" r="1.238" fill={color} />
              </g>
            </g>
            <g id="Group_6224" data-name="Group 6224" transform="translate(39.628 39.628)">
              <g id="Group_6223" data-name="Group 6223">
                <circle id="Ellipse_38" data-name="Ellipse 38" cx="1.238" cy="1.238" r="1.238" fill={color} />
              </g>
            </g>
            <g id="Group_6226" data-name="Group 6226" transform="translate(0 0)">
              <g id="Group_6225" data-name="Group 6225">
                <path id="Path_11525" data-name="Path 11525" d="M53.25,24.767a1.238,1.238,0,0,0,0-2.477H47.058V17.337H53.25a1.238,1.238,0,0,0,0-2.477H47.058V13.622A6.2,6.2,0,0,0,40.866,7.43H39.628V1.238a1.238,1.238,0,0,0-2.477,0V7.43H32.2V1.238a1.238,1.238,0,0,0-2.477,0V7.43H24.767V1.238a1.238,1.238,0,0,0-2.477,0V7.43H17.337V1.238a1.238,1.238,0,0,0-2.477,0V7.43H13.622A6.2,6.2,0,0,0,7.43,13.622V14.86H1.238a1.238,1.238,0,0,0,0,2.477H7.43v4.953H1.238a1.238,1.238,0,0,0,0,2.477H7.43v4.953H1.238a1.238,1.238,0,0,0,0,2.477H7.43v4.953H1.238a1.238,1.238,0,0,0,0,2.477H7.43v1.238a6.2,6.2,0,0,0,6.192,6.192H14.86V53.25a1.238,1.238,0,0,0,2.477,0V47.058H22.29V53.25a1.238,1.238,0,0,0,2.477,0V47.058h4.953V53.25a1.238,1.238,0,1,0,2.477,0V47.058h4.953V53.25a1.238,1.238,0,1,0,2.477,0V47.058h1.239a6.2,6.2,0,0,0,6.192-6.192V39.628H53.25a1.238,1.238,0,1,0,0-2.477H45.82a1.238,1.238,0,0,0-1.238,1.238v2.477a3.72,3.72,0,0,1-3.715,3.715H13.622a3.72,3.72,0,0,1-3.715-3.715V13.622a3.72,3.72,0,0,1,3.715-3.715H40.866a3.72,3.72,0,0,1,3.715,3.715V33.436a1.238,1.238,0,1,0,2.477,0V32.2H53.25a1.238,1.238,0,0,0,0-2.477H47.058V24.768H53.25Z" fill={color} />
              </g>
            </g>
            <g id="Group_6228" data-name="Group 6228" transform="translate(14.862 14.86)">
              <g id="Group_6227" data-name="Group 6227">
                <path id="Path_11526" data-name="Path 11526" d="M148.315,144.59a3.706,3.706,0,0,0-1.238,7.2v10.135h-3.715a1.239,1.239,0,0,1-1.237-1.238V143.352a1.24,1.24,0,0,1,1.238-1.238h7.429a1.238,1.238,0,0,0,0-2.477h-7.429a3.72,3.72,0,0,0-3.715,3.715v17.337a3.719,3.719,0,0,0,3.714,3.715h4.953a1.238,1.238,0,0,0,1.238-1.238V151.793a3.706,3.706,0,0,0-1.238-7.2Zm0,4.953a1.238,1.238,0,1,1,1.238-1.238A1.24,1.24,0,0,1,148.315,149.544Z" transform="translate(-139.648 -139.637)" fill={color} />
              </g>
            </g>
            <g id="Group_6230" data-name="Group 6230" transform="translate(27.244 14.86)">
              <g id="Group_6229" data-name="Group 6229">
                <path id="Path_11527" data-name="Path 11527" d="M264.666,139.636h-4.952a1.238,1.238,0,0,0-1.238,1.238v11.373a3.715,3.715,0,1,0,2.477,0V142.113h3.714a1.24,1.24,0,0,1,1.238,1.238v17.337a1.24,1.24,0,0,1-1.238,1.238h-7.429a1.238,1.238,0,1,0,0,2.477h7.429a3.72,3.72,0,0,0,3.715-3.715V143.351A3.719,3.719,0,0,0,264.666,139.636Zm-4.952,17.337a1.238,1.238,0,1,1,1.238-1.238A1.24,1.24,0,0,1,259.714,156.973Z" transform="translate(-255.999 -139.636)" fill={color} />
              </g>
            </g>
          </g>
        </svg>
        );
        break;
      case 'gb':
        let newaa = blocksInput * specs?.ram;
        if(newaa>=1000){
          newaa = parseFloat(newaa/1000).toFixed(2);
          setTypeVal('Tb');
        }else{
          setTypeVal('Gb');
        }
        setNewNum(newaa);
        // setNewNum(blocksInput * );
        isLeft = ( specs?.ram * 28) - (blocksInput *  specs?.ram)
        setLeftValues(isLeft);
        setSvg(<svg xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 73.91 39.265">
          <g id="ram" transform="translate(0 0)">
            <g id="Group_6234" data-name="Group 6234" transform="translate(0 0)">
              <g id="Group_6233" data-name="Group 6233">
                <path id="Path_11544" data-name="Path 11544" d="M71.6,0H2.31A2.309,2.309,0,0,0,0,2.31v23.1a2.309,2.309,0,0,0,2.31,2.31H6.929V35.8a3.574,3.574,0,0,0,3.665,3.465H63.315A3.574,3.574,0,0,0,66.981,35.8V27.716H71.6a2.309,2.309,0,0,0,2.31-2.31V2.31A2.309,2.309,0,0,0,71.6,0ZM64.671,35.8a1.27,1.27,0,0,1-1.356,1.155H10.595A1.27,1.27,0,0,1,9.239,35.8V27.716H64.671ZM69.29,23.1H4.619V4.619H69.29Z" transform="translate(0 0)" fill={color} />
                <path id="Path_11545" data-name="Path 11545" d="M1.155,13.858H8.084A1.155,1.155,0,0,0,9.239,12.7V1.155A1.155,1.155,0,0,0,8.084,0H1.155A1.155,1.155,0,0,0,0,1.155V12.7A1.155,1.155,0,0,0,1.155,13.858ZM2.31,2.31H6.929v9.239H2.31Z" transform="translate(11.548 6.929)" fill={color} />
                <path id="Path_11546" data-name="Path 11546" d="M1.155,13.858H8.084A1.155,1.155,0,0,0,9.239,12.7V1.155A1.155,1.155,0,0,0,8.084,0H1.155A1.155,1.155,0,0,0,0,1.155V12.7A1.155,1.155,0,0,0,1.155,13.858ZM2.31,2.31H6.929v9.239H2.31Z" transform="translate(25.406 6.929)" fill={color} />
                <path id="Path_11547" data-name="Path 11547" d="M1.155,13.858H8.084A1.155,1.155,0,0,0,9.239,12.7V1.155A1.155,1.155,0,0,0,8.084,0H1.155A1.155,1.155,0,0,0,0,1.155V12.7A1.155,1.155,0,0,0,1.155,13.858ZM2.31,2.31H6.929v9.239H2.31Z" transform="translate(39.265 6.929)" fill={color} />
                <path id="Path_11548" data-name="Path 11548" d="M1.155,13.858H8.084A1.155,1.155,0,0,0,9.239,12.7V1.155A1.155,1.155,0,0,0,8.084,0H1.155A1.155,1.155,0,0,0,0,1.155V12.7A1.155,1.155,0,0,0,1.155,13.858ZM2.31,2.31H6.929v9.239H2.31Z" transform="translate(53.123 6.929)" fill={color} />
                <path id="Path_11549" data-name="Path 11549" d="M1.155,4.619A1.155,1.155,0,0,0,2.31,3.464V1.155a1.155,1.155,0,1,0-2.31,0v2.31A1.155,1.155,0,0,0,1.155,4.619Z" transform="translate(13.858 30.026)" fill={color} />
                <path id="Path_11550" data-name="Path 11550" d="M1.155,4.619A1.155,1.155,0,0,0,2.31,3.464V1.155a1.155,1.155,0,1,0-2.31,0v2.31A1.155,1.155,0,0,0,1.155,4.619Z" transform="translate(18.477 30.026)" fill={color} />
                <path id="Path_11551" data-name="Path 11551" d="M1.155,4.619A1.155,1.155,0,0,0,2.31,3.464V1.155a1.155,1.155,0,0,0-2.31,0v2.31A1.155,1.155,0,0,0,1.155,4.619Z" transform="translate(23.097 30.026)" fill={color} />
                <path id="Path_11552" data-name="Path 11552" d="M1.155,4.619A1.155,1.155,0,0,0,2.31,3.464V1.155a1.155,1.155,0,1,0-2.31,0v2.31A1.155,1.155,0,0,0,1.155,4.619Z" transform="translate(27.716 30.026)" fill={color} />
                <path id="Path_11553" data-name="Path 11553" d="M1.155,4.619A1.155,1.155,0,0,0,2.31,3.464V1.155a1.155,1.155,0,1,0-2.31,0v2.31A1.155,1.155,0,0,0,1.155,4.619Z" transform="translate(32.336 30.026)" fill={color} />
                <path id="Path_11554" data-name="Path 11554" d="M1.155,4.619A1.155,1.155,0,0,0,2.31,3.464V1.155a1.155,1.155,0,1,0-2.31,0v2.31A1.155,1.155,0,0,0,1.155,4.619Z" transform="translate(39.265 30.026)" fill={color} />
                <path id="Path_11555" data-name="Path 11555" d="M1.155,4.619A1.155,1.155,0,0,0,2.31,3.464V1.155a1.155,1.155,0,0,0-2.31,0v2.31A1.155,1.155,0,0,0,1.155,4.619Z" transform="translate(43.884 30.026)" fill={color} />
                <path id="Path_11556" data-name="Path 11556" d="M1.155,4.619A1.155,1.155,0,0,0,2.31,3.464V1.155a1.155,1.155,0,0,0-2.31,0v2.31A1.155,1.155,0,0,0,1.155,4.619Z" transform="translate(48.503 30.026)" fill={color} />
                <path id="Path_11557" data-name="Path 11557" d="M1.155,4.619A1.155,1.155,0,0,0,2.31,3.464V1.155a1.155,1.155,0,0,0-2.31,0v2.31A1.155,1.155,0,0,0,1.155,4.619Z" transform="translate(53.123 30.026)" fill={color} />
                <path id="Path_11558" data-name="Path 11558" d="M1.155,4.619A1.155,1.155,0,0,0,2.31,3.464V1.155a1.155,1.155,0,1,0-2.31,0v2.31A1.155,1.155,0,0,0,1.155,4.619Z" transform="translate(57.742 30.026)" fill={color} />
              </g>
            </g>
          </g>
        </svg>);
        break;
      case 'storage':
        let newa = blocksInput * storage;
        if(newa>=1000){
          newa = parseFloat(newa/1000).toFixed(2);
          setTypeVal('Tb');
        }else{
          setTypeVal('Gb');
        }
        setNewNum(newa);
        isLeft = (storage * 28) - (blocksInput * storage)
        setLeftValues(isLeft);
        setSvg(<svg xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 36.512 45.625">
          <g id="database_2_" data-name="database (2)" transform="translate(0 0)">
            <path id="Path_11559" data-name="Path 11559" d="M21.173,18.074c-8.567,0-15.746-2.517-17.706-5.91a3.712,3.712,0,0,0-.546,1.862v5.553c0,4.29,8.174,7.77,18.251,7.77s18.261-3.48,18.261-7.77V14.026a3.689,3.689,0,0,0-.552-1.862C36.922,15.555,29.75,18.074,21.173,18.074Z" transform="translate(-2.922 6.803)" fill={color} />
            <path id="Path_11560" data-name="Path 11560" d="M21.173,25.429c-8.567,0-15.746-2.52-17.706-5.907a3.691,3.691,0,0,0-.546,1.857v5.557c0,4.291,8.174,7.77,18.251,7.77s18.261-3.477,18.261-7.77V21.379a3.659,3.659,0,0,0-.552-1.856C36.922,22.909,29.75,25.429,21.173,25.429Z" transform="translate(-2.922 10.919)" fill={color} />
            <path id="Path_11561" data-name="Path 11561" d="M38.935,5.042C38.577,8.186,30.777,10.7,21.173,10.7S3.775,8.186,3.418,5.044a3.688,3.688,0,0,0-.5,1.779v5.553c0,4.291,8.174,7.77,18.251,7.77s18.261-3.477,18.261-7.77V6.823A3.7,3.7,0,0,0,38.935,5.042Z" transform="translate(-2.922 2.82)" fill={color} />
            <path id="Path_11562" data-name="Path 11562" d="M21.007,11.759c9.828,0,17.792-2.631,17.792-5.88S30.836,0,21.007,0,3.219,2.634,3.219,5.88,11.185,11.759,21.007,11.759Z" transform="translate(-2.756 0)" fill={color} />
          </g>
        </svg>
        );
        break;
      case 'tb':
        setNewNum(blocksInput * 1);
        isLeft = (1 * 40) - (blocksInput * 1)
        setLeftValues(isLeft);
        
          setTypeVal('Tb');
        
        setSvg(<svg xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 47.698 41.459">
          <path id="bandwidth" d="M33.055,48.125a1.592,1.592,0,0,0-2.251,0l-5.047,5.045a4.581,4.581,0,1,0-1.908,8.745,4.579,4.579,0,0,0,4.163-6.491l5.042-5.045A1.589,1.589,0,0,0,33.055,48.125ZM12.7,69.413,7.6,74.944,6.431,73.7A23.984,23.984,0,0,1,.008,59.041L0,58.927H7.552a16.479,16.479,0,0,0,4.172,9.41Zm27.4,5.53-5.106-5.53.978-1.078a16.419,16.419,0,0,0,4.172-9.409H47.7l-.008.116A24.01,24.01,0,0,1,41.268,73.7ZM41.832,41.6l.074.086A23.834,23.834,0,0,1,47.69,55.627l.008.113H40.143a16.276,16.276,0,0,0-3.649-8.8Zm-16.39-8.119v7.553a16.3,16.3,0,0,1,8.8,3.653l5.338-5.338-.086-.075a23.813,23.813,0,0,0-13.937-5.784ZM8.118,39.35l.085-.073a23.827,23.827,0,0,1,13.941-5.783l.113-.008v7.553a16.286,16.286,0,0,0-8.8,3.653ZM0,55.741H7.551a16.314,16.314,0,0,1,3.654-8.8L5.866,41.6l-.074.085A23.8,23.8,0,0,0,.008,55.627Z" transform="translate(0 -33.485)" fill={color} fillRule="evenodd" />
        </svg>
        )
        break;
      default:
        break;
    }

  }, [num,specs]);
  function capitalizeFirstLetter(string) {

    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <Card className=' boxlocation_hireserver shadow-none m-0 hs_borderRadius-4' style={{ Border: '1px solid black', height: 'auto' }}>
      <Card.Body style={{ padding: '10px' }}>
        <div className="d-flex d-gap-10">
          <div className='d-flex align-items-start pt-1'>
            <div>

              {Svg}
            </div>
          </div>
          <div className='d-flex flex-column align-items-start font-14'>
            <div>
              <span className='font-w700 pr-1'>{newNum}</span>
              <span className='text-black-50'>{typeVal}</span>
              {/* <span className='text-black-50'>{name == 'Storage' ? 'Gb' : name}</span> */}
            </div>
            <div>
              <div>{cTitle}</div>
            </div>
          </div>
          <div className='justify-content-end d-none' style={{ color: 'rgb(9 188 182)' }}>
            {leftValues} {type} Left
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

export default function BlocksCard({ onChangeBlocksHandler, color, isAvailable, setCores, cores, blocksInput, setBlocksInput, totalCores, isMore, setIsMore,specs }) {
  const { logoStyles } = styles;
  let isAva = isAvailable == true ? 'text-green' : 'text-danger';



  function plusLogic(num) {
    let TOTAL_BLOCKS = totalCores;
    num = num === '' ? 0 : num;

    // if (cores >= 1) {
      let newCores = cores - 1;
      num = num + 1;
      setBlocksInput(num);
      setCores(newCores);
    // }
  }

  function minusLogic(num) {
    '';
    let TOTAL_BLOCKS = totalCores;
    if (num <= 1) return;
    num = num === '' ? 1 : num;
    if (cores < TOTAL_BLOCKS) {
      let newCores = cores + 1;
      num = num - 1;
      setBlocksInput(num);
      setCores(newCores);
    }
  }



  function blocksHandler(e) {
    let name = e.target.getAttribute('data-name');
    
    if (name === PLUS_BTN) {
      if(blocksInput >= 28){
        return;
      }
      plusLogic(blocksInput);
    } else if (name === MINUS_BTN) {
      minusLogic(blocksInput)
    }
  }

  function handleKeyDown(e){
    let num = e.which;
  
    if(37 == num || 39 == num || 38 == num || 40 == num){
      e.preventDefault();
    }
  }

  return (
    <React.Fragment>
      <Card className="d-none">
        <Card.Body  className='d-flex flex-column d-gap-10 align-items-center'>
          <Card.Img style={logoStyles} src={logo} />
          <Card.Title>
            Blocks
          </Card.Title>

          <div>
          <div style={{ background: `${color} 0% 0% no-repeat padding-box` }} className='d-flex inputNumberChange_hireServer p-3'>
            <span data-name={MINUS_BTN} onClick={blocksHandler} className=' control_block_hs d-flex justify-content-center align-items-center'>
              <span className='pointer_events_none'>-</span>
            </span>
            <input onKeyDown={blockInvalidChar} onWheel={ event => event.currentTarget.blur() }  onChange={onChangeBlocksHandler} data-taprev="0" value={blocksInput} className='totalAvaWidget_hs removenumbercontrols valueinstantname_hireserver_valuenum' type="number" />
            <span data-name={PLUS_BTN} onClick={blocksHandler} className='control_block_hs d-flex justify-content-center align-items-center'>

              <span className='pointer_events_none'>+</span>
            </span>
          </div>
          </div>
          {isMore && <div className="alert alertcores alert-warning" role="alert">
            The Limit is 40 only
          </div>}
          <div className=' w-100 d-flex align-items-baseline justify-content-between'>
            <div className="d-flex">
              <Card.Text className='text-dark cpu'>
                <span className='pr-2'>{cores}</span>
              </Card.Text>
              <Card.Text className='text-dark cpu'>
                <span>Cores</span>
              </Card.Text>
            </div>
            <div className={isAva}>
              Available
            </div>
          </div>

        </Card.Body>
      </Card>

      <div>
        <div className='col-lg-7 blocksInputControl py-2 hs_borderRadius-4' style={{ background: "rgb(209,239,238)"}}>
          <div className="row justify-content-between align-items-center font-14">
            <div className='col-lg-4 pb-2 pb-lg-0'>
            Select Blocks
            </div>
            <div className='col-lg-5'>
              <div className='d-flex  p-1' style={{
                background: 'rgb(242, 252, 251)',
                borderRadius: '18px'
              }}>
                <span data-name={MINUS_BTN} onClick={blocksHandler} className='rounded-btn-hs d-flex justify-content-center align-items-center'>
                  <span className='pointer_events_none'>-</span>
                </span>
                <input onKeyDown={blockInvalidChar} onWheel={ event => event.currentTarget.blur() } onChange={onChangeBlocksHandler} data-taprev="0" value={blocksInput} className='totalAvaWidget_hs removenumbercontrols valueinstantname_hireserver_valuenum font-14 py-2' type="number" />
                <span data-name={PLUS_BTN} onClick={blocksHandler} className='rounded-btn-hs d-flex justify-content-center align-items-center'>

                  <span className='pointer_events_none'>+</span>
                </span>
              </div>

            </div>
          </div>
        </div>
        {isMore &&  <React.Fragment>
  <SpacerHS_ONE/>
  <FormInputError  message="Max numbers of block are 28" /></React.Fragment>}

        <div className="col-lg-7 pt-2">
        <div className="row">
        <div className='col-12 mb-2 mb-lg-0 col-lg-3 p-lg-0 '>
            <MiniShowUseAgeCard specs={specs}  title="cpu" blocksInput={blocksInput} num={cores} type="cores" />
          </div>
          <div className='col-12 mb-2 mb-lg-0 col-lg-3 '>
            <MiniShowUseAgeCard specs={specs} title="ram" blocksInput={blocksInput} num={cores} type="gb" />
          </div>
          <div className='col-12 mb-2 mb-lg-0 col-lg-3'>
            <MiniShowUseAgeCard specs={specs}  title="storage"  blocksInput={blocksInput} num={cores} type="storage" />
          </div>
          <div className='col-12 mb-2 mb-lg-0 col-lg-3'>
            <MiniShowUseAgeCard specs={specs} title="transfer" blocksInput={blocksInput} num={cores} type="tb" />
          </div>
        </div>
        </div>

        {/* {isMore && <div className="alert alertcores alert-warning" role="alert">
            The Limit is 40 only
          </div>} */}


      </div>
    </React.Fragment>
  )
}
