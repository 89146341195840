import axios from "axios";
import { INSTANCES_SSH_URL } from "../../API_ENDPOINTS/API_ENDPOINTS";
import { getUserCookieAuth } from "../../store/actions/AuthActions";
import { API_SERVICES } from "../API_SERVICES";

export function Instances_SSH_Service(){
    let userCookie = getUserCookieAuth();
    let { userId, sessionId } = userCookie;
    let url = INSTANCES_SSH_URL;

    let config = {
        method: 'get',
        url,
        headers: { 'Authorization': `Basic ${sessionId}` }
    };

   return API_SERVICES(config)
}
