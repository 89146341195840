import React from 'react';
import { Link } from 'react-router-dom';
import beeDown from '../../../../../../images/hireserver/imgs/bee/fail.png';
import SpacerHS_TEN from '../../../Spaces/SpacerHS_TEN';

function PaymentFail({which}) {
    const [whichLink,setWhichLink] = React.useState('');
    React.useEffect(function (){
        setPath();
    },[which]);

    function setPath(){
        if(which === 'instance'){
            setWhichLink('/instances/create');
        }else if(which === 'renewal'){
            setWhichLink('/profile/transaction');
        }else{
            setWhichLink('/');
        }
    }
  return (
    <div className='d-flex justify-content-center align-items-center flex-column'>
    <div className='w-200p h-200p'>
        <img className='w-100 h-100' src={beeDown} alt="error page" />
    </div>
    <SpacerHS_TEN/>
    <div className=''>
    Aaaah! Payment failed
    </div>

<Link to={whichLink}>Try again</Link>
  
    
    </div>
  )
}

export default PaymentFail