import React from 'react'
import { connect, useDispatch } from 'react-redux';
import ImageHs from '../../hireserver/ImageHs';
import ModalHs from '../../hireserver/ModalHs';

// import deployImage from './../../../../../../../images/hireserver/imgs/bee/deploy.png';
import deployImage from '../../../../images/hireserver/imgs/bee/deploy.png';
import { checkResData, getCurrentDate, getNextDate, imageReplaceHashHelper, percentageHelper } from '../../../../utils/Helpers';
import CurrencyFC from '../../hireserver/components/Currency/CurrencyFC';
import { closeDueTableRenewalAction } from '../../../../store/actions/renewalActions/renewalActions';
import { handlerAlert } from '../../Dashboard/Instances/InstancesListComponents/DropDownInstancesList';
import { getPaymentWalletAmountService, postPayRenewalService } from '../../../../services/paymentServices/PaymentService';
import { closeModalRenewalPayment } from '../../../../store/reducers/RenewalPaymentReducer/RenewalPaymentReducer';
import { putRedirectLinkAction, showPagementGateWayAction } from '../../../../store/actions/RedirectPageActions/RedirectPageAction';
import { getBlockIdService, getTenureInstanceaService } from '../../../../services/InstancesServices/InstancesFormService';
import { isMobile } from 'react-device-detect';
import SpacerHS_TWO from '../../hireserver/Spaces/SpacerHS_TWO';

function RenewalModal({ show, currItemData, currencyid, currencyname }) {
  const dispatch = useDispatch();
  const [walletValue, setWalletValue] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [taxState, setTaxState] = React.useState(null);
  const [price, setPrice] = React.useState(0);
  const [spec, setSpecs] = React.useState(null);



  React.useEffect(function () {
    getWallet();
    getTaxes();
  }, [currItemData]);

  React.useEffect(function () {
    if (currItemData && taxState) {
      setPrice(currItemData?.totalamount);
    }
  }, [currItemData, taxState]);

  function handleModalClose() {
    dispatch(closeDueTableRenewalAction());
  }

  async function getWallet() {
    let res = await getPaymentWalletAmountService();
    let isValid = checkResData(res, true)
    if (isValid === false) {
      setWalletValue(0);
      // setLoading(false);
      return;
    }

    let resJSON = JSON.parse(res.data.result)[0];
    if (!resJSON) {
      setWalletValue(0);
      // setLoading(false);
      return;
    }
    setWalletValue(resJSON?.walletvalue);
    // setWalletDetails(resJSON);
  }

  async function getTaxes() {
    const blockIdRes = await getBlockIdService();

    if(!blockIdRes.data){
    handlerAlert("danger","Something went wrong");
      return;
    }

    let blockid = JSON.parse(blockIdRes.data.result)[0]

    setSpecs(blockid);
    blockid = blockid['blockid'];
    const newRes = await getTenureInstanceaService(currencyid, blockid);

    if (newRes.data.result) {
      const taxesData = JSON.parse(JSON.parse(newRes.data.result)[0]?.taxes);
      console.log(taxesData, 'taxesDatataxesDatataxesData');
      setTaxState(taxesData);
    }


  }

  async function handleModalOkay() {

    setLoading(true);
    try {
      const response = await postPayRenewalService(currItemData?.machineid);



      if (response.data.result == null) {
        handlerAlert("danger", "Bad Response Create Again", "danger");
        setLoading(false);
        dispatch(closeModalRenewalPayment());
        return;
      }
      let data = JSON.parse(response.data.result);
      //console.log(data,'instainstainstainstainstainstainstainstainsta')
      setLoading(false)

      const { paymentlink } = data;
      if (paymentlink) {
        handleModalClose();

        dispatch(putRedirectLinkAction(paymentlink));
        dispatch(showPagementGateWayAction());

        return;
      }
      // if (callListRenewal) {
      //   dispatch(startLoadingInstaces());
      //   dispatch(getIntancesList());
      //   dispatch(callListRenewalToggle());
      // }

      handlerAlert("success", 'Renewal was successfull');
      handleModalClose();
    } catch (error) {

      setLoading(false);
      console.log(error);
      handlerAlert("danger", "Something went wrong", "danger");
    }
  }

  // console.log(Math.abs((parseInt(price + parseInt(percentageHelper(taxState?.SGST, price)) ))),'Math.abs((currItemData?.totalamount)+(parseInt(price + parseInt(percentageHelper(taxState?.SGST, price)) + parseInt(percentageHelper(taxState?.CGST, price)))))');
  return (
    <ModalHs size="lg" bodyClasses="p-0 overflow-hidden" isNeed={false} okayHandler={() => console.log("k")} showModalHS={show} btnTitle="Renewal Machine" >
      <div className='w-100'>
        <div>
          <div className='p-3 px-5 border-bottom text-primary'>
            Renewal Summary
          </div>
          <div className='border-bottom row py-3'>
            <div className="col-sm-6  px-5 d-flex align-items-center d-gap-40 ">

              <ImageHs width="100px" height="100px" src={deployImage} />

              <div className='font-w500'>
                <div className=' m-0'>
                  <span>Machine</span> <span>:</span> <span>{currItemData?.machinename}</span>
                </div>
                <hr className='my-1' />
                <div className='font-12'>
                  {currItemData?.noofblocks * 1} CPU, {currItemData?.noofblocks * parseInt(spec?.ram)} Ram, {currItemData?.noofblocks * parseInt(spec?.storage)} Gb Storage
                </div>
                <hr className='my-1' />
                <div className='d-flex d-gap-10 font-12'>
                  <ImageHs src={imageReplaceHashHelper(currItemData?.icon)} width="15px" />
                  <div>
                    {currItemData?.branchname}
                  </div>
                </div>
                <hr className='my-1' />
                <div className='d-flex d-gap-10 font-12'>
                  <ImageHs src={imageReplaceHashHelper(currItemData?.osiconpath)} width="15px" />
                  <div>
                    {currItemData?.groupname} {currItemData?.operatingsystemname}
                  </div>
                </div>
              </div>
            </div>
            {isMobile? <SpacerHS_TWO/> : null}
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-6 d-flex flex-column align-items-center font-14">
                  <div className='hs_text-light'>Period</div>
                  <div className='font-w500'>
                    {/* {currItemData?.tenuretypename?.toLowerCase() === 'annually' ? `${getCurrentDate()} - ${getNYearDate(1)}` : `${getCurrentDate()} - ${getNMonthDate(1)}`} */}
                    {currItemData?.tenuretypename?.toLowerCase() === 'annually' ? `${currItemData?.duedate} - ${getNextDate(currItemData?.duedate?currItemData?.duedate:'10/09/1001','year')}` : `${currItemData?.duedate} - ${getNextDate(currItemData?.duedate?currItemData?.duedate:'10/09/1001','month')}`}

                    {/* {currItemData?.duedate} */}

                  </div>
                  {/* <div className='hs_text-light'>Blocks</div>
            <div className='font-w500'>{currItemData?.noofblocks}</div> */}
                </div>
                <div className="col-sm-6  d-flex flex-column align-items-center font-14">
                  <div className='hs_text-light'>
                    Tenure
                  </div>
                  <div className='font-w500'>
                    {/* <CurrencyFC name={paymentData?.currencyname} val={price} /> */}
                    {currItemData?.tenuretypename}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex px-5 font-14 flex-column p-3 justify-content-between border-bottom'>

            <div className='d-flex w-100 justify-content-between'>
              <span>Blocks x {currItemData?.noofblocks}</span>
              <span><CurrencyFC name={currencyname} val={currItemData?.totalamount} /></span>
            </div>

            <div className='d-flex  justify-content-between'>
              <span>SGST {taxState?.SGST}%</span>
              <span>  <CurrencyFC name={currencyname} val={percentageHelper(taxState?.SGST, price)} />  </span>

            </div>
            <div className='d-flex  justify-content-between'>
              <span>CGST {taxState?.CGST}%</span>
              <span>  <CurrencyFC name={currencyname} val={percentageHelper(taxState?.CGST, price)} />  </span>

            </div>

            {/* <span>Due Amount
            <span className='font-12'>(Tax Included 18%)</span>\
          </span> 
          <span>
            <CurrencyFC val={currDetailsItem?.dueamount} name={currencyname} />
            </span> */}
          </div>

        </div>

        <div className='p-3 px-5 font-14 border-bottom'>
          <div className='d-flex justify-content-between'>


            <span>{Boolean(walletValue) && walletValue < parseInt(price + parseInt(percentageHelper(taxState?.CGST * 2, price))) && 'Sub'}Total</span>
            <span>   <CurrencyFC name={currencyname} val={parseInt(percentageHelper(taxState?.SGST, price)) + parseInt(percentageHelper(taxState?.CGST, price)) + (currItemData?.totalamount)} /></span>
            {/* <span>   <CurrencyFC name={currencyname} val={Math.abs((parseInt(price + parseInt(percentageHelper(taxState?.SGST, price)) + parseInt(percentageHelper(taxState?.CGST, price)))))} /></span> */}
          </div>

          {Boolean(walletValue) && walletValue < parseInt(price + parseInt(percentageHelper(taxState?.CGST * 2, price))) &&
            <React.Fragment>
              <div className='d-flex justify-content-between'>
                <span className='text-danger'>wallet</span>
                <span className='text-danger'>   <span className='px-1'>-</span><CurrencyFC name={currencyname} val={walletValue} /></span>
              </div>

              <div className='d-flex justify-content-between'>
                <span>Total</span>
                <span className=''>  <CurrencyFC name={currencyname} val={parseInt(percentageHelper(taxState?.SGST, price)) + parseInt(percentageHelper(taxState?.CGST, price)) + (currItemData?.totalamount - walletValue)} /></span>
                {/* <span className=''>  <CurrencyFC name={currencyname} val={Math.abs(walletValue - parseInt(price + parseInt(percentageHelper(taxState?.SGST, price)) + parseInt(percentageHelper(taxState?.CGST, price))))} /></span> */}

              </div>
            </React.Fragment>
          }
        </div>


        {walletValue < Math.abs(parseInt(percentageHelper(taxState?.SGST, price)) + parseInt(percentageHelper(taxState?.CGST, price)) + (currItemData?.totalamount - walletValue)) && <div className='px-5 py-3'>
          <div>Your wallet doesn't have enough money. You will redirect to payment gateway page.</div>
        </div>}
        {walletValue >= Math.abs(parseInt(percentageHelper(taxState?.SGST, price)) + parseInt(percentageHelper(taxState?.CGST, price)) + (currItemData?.totalamount - walletValue)) && <div className='px-5 py-3'>
          <div>Your have <span className='text-primary font-14 font-w500'><CurrencyFC name={currencyname} val={walletValue} /></span>  You have enough balance in your wallet. By clicking renewal your machine will be renewed.</div>
        </div>}
        <div className='mt-3 px-3 px-5 pb-5 d-flex justify-content-end d-gap-15'>
          <button onClick={handleModalClose} className='bg-danger border-0 btn-sm hs_borderRadius-4 text-white '>Close</button>
          <button onClick={handleModalOkay} className='w-150p hs-signin-btn border-0 btn-sm hs_borderRadius-4 text-white'>
            {loading ? <React.Fragment>
              <div className="spinner-border text-light" role="status">

              </div> Renewal
            </React.Fragment> : 'Renewal'}
          </button>
        </div>
      </div>

    </ModalHs>
  )
}



const mapStateToProps = ({ renewalPaymentReducer, instanceDetails, PaymentInfoReducer }) => {
  return {
    currItemData: renewalPaymentReducer.dueTableData,
    show: renewalPaymentReducer?.dueTableRenewal,
    currencyid: PaymentInfoReducer.paymentData?.currencyid,
    currencyname: PaymentInfoReducer.paymentData?.currencyname,
    callListRenewal: renewalPaymentReducer.callListRenewal,
  };
};

export default connect(mapStateToProps)(RenewalModal);