import React from 'react';
import { capitalizeFirstLetter, getObjKeyLengthHelper, imageReplaceHashHelper } from '../../../../../utils/Helpers';
import ImageHs from '../../ImageHs';
import './DropDownWithImgGroup.css';
import GroupItem from './groupItem/GroupItem';
import _ from 'lodash';

function DropDownWithImgGroup({ data, placeholder, handler, currItem }) {
  const [searchVal, setSearchVal] = React.useState(null);
  const [customData, setCustomData] = React.useState({});
  const [showOption, setShowOption] = React.useState(false);
  const [showAllOptions, setShowAllOptions] = React.useState(false);


  // const debouncedChangeHandler = React.useMemo(
  //   () => _.debounce((value) => searchValueFun(value), 300),
  //   []
  // );

  React.useEffect(function () {

    document.addEventListener('click', windowClickHandler, false);
    return () => {

      document.removeEventListener('click', windowClickHandler);
    }
  }, []);

  // React.useEffect(() => {
  //   return () => {
  //     debouncedChangeHandler.cancel();

  //   };
  // }, [debouncedChangeHandler]);


  React.useEffect(function () {
    if (!data) {
      return;
    }
    setCustomData(data)
  }, [data]);

  React.useEffect(function () {
    // debugger;
    // console.log(currItem,'currItemcurrItemcurrItem')

    if (currItem) {
     
      setShowOption(true);
    }
  }, [currItem])

  function searchInputFun(e) {
    
    const value = e.target.value;
    setSearchVal(value);

    if (value === '') {
      if (currItem) {
        setShowOption(true);
      }
      setCustomData(data)
      handler(null);
      return;
    }
    searchValueFun(value);


   
  }

  function searchValueFun(value) {
    let cus = {};

    for (let item in data) {
      let currItem = data[item];
      for (let i = 0; i < currItem.length; i++) {
        let { OperatingSystemName } = currItem[i];
        if (OperatingSystemName.includes(value) || item.includes(value) || `${item} ${OperatingSystemName}`.includes(value)) {
          if (cus[item]) {
            cus[item].push(currItem[i]);
          } else {
            cus[item] = [currItem[i]];
          }
        }
      }
    }
    setCustomData(cus);
  }

  const setDefaultData = React.useCallback(function () {
    setCustomData(data)
  }, [data]);

  function getGroupItems() {
    // <GroupItem name="ubuntu" data={data['ubuntu']} />

    if (!customData) return;
    let html = [];
    for (let item in customData) {

      html.push(<GroupItem currItemData={currItem} setShowAllOptions={setShowAllOptions} setDefaultData={setDefaultData} inputSearchHandler={setSearchVal} handler={handler} name={item} data={customData[item]} />)
    }

    return html;
  }


  function handleKeyDown(e) {
    const value = e.target.value;
    // if(value === '') return;
    if (searchVal === null) {
      handler(null);
    }
    setShowOption(false);
  }

  function onFocusHandler() {
    // setShowOption(false);
    setShowAllOptions(true);
    // window.addEventListener('click',windowClickHandler);
  }

  function windowClickHandler(e) {

    e = e || window.event;
    var target = e.target || e.srcElement,
      text = target.textContent || target.innerText;


    if (target.classList.contains('inputContainerControlDropdown')||target.classList.contains('dropDownWithImgGroupContainer')) {
      return;
    }

    if (!target.classList.contains('dropdownItemCustomImgEvent')) {
      setShowAllOptions(false);
    }

  }




  return (
    <div className='dropDownWithImgGroupContainer'>
      <div className='w-100 inputContainerDropDownWithImgGroup'>

        {showOption && <InputCurrentOptionDropDownImg placeholder={placeholder} currItem={currItem} textValue={searchVal} />}
     
        <input disabled={getObjKeyLengthHelper(data)===0?true:false} value={searchVal||''} onChange={searchInputFun}  onFocus={onFocusHandler} onKeyUp={handleKeyDown} type="text" className='w-100 form-control border-radius-0 inputContainerControlDropdown' placeholder={!showOption ? placeholder : ''} />
      </div>
      {showAllOptions && <div className='dropDownWithImgGroupBox card border-radius-0 border-top-0 border p-3'>
        {getGroupItems()}
      </div>}
   
    </div>
  )
}

export default DropDownWithImgGroup;

function InputCurrentOptionDropDownImg({ currItem, textValue,placeholder }) {

  if (textValue === null && currItem.name == '') return <React.Fragment>
       <div className='font-10 arrowDropDownWithImg'>
        <span><i className='fa fa-arrow-down'></i></span>
      </div>
      <div className='pointerEventNone d-flex align-items-center  px-3 position-absolute w-100 h-100 inputDropDownWithImgGroupPlaceholder'>
      {placeholder}
      </div>
  </React.Fragment>
  const { image, name, id,groupname } = currItem;
  
  return <div className='overlayImgOptionDropDown p-1 px-3'>
    <ImageHs src={imageReplaceHashHelper(image)} alt={name} height="30px" width="30px" />
    <div>
     {capitalizeFirstLetter(groupname)} {name}
    </div>   <div className='font-10 arrowDropDownWithImg'>
        <span><i className='fa fa-arrow-down'></i></span>
      </div>

  </div>
}