
import { cloneDeep } from 'lodash';
import React from 'react'
import uuid from 'react-uuid';
import { getDetailsScriptService } from '../../../../../../../../../services/InstancesServices/InstancesListService';
import { blockInvalidChar, checkResData, getTokenFilter } from '../../../../../../../../../utils/Helpers';
import useInputHook from '../../../../../../../../hooks/InputHook/useInputHook';
import useDynamicInputs from '../../../../../../../../hooks/useDynamicInputs/useDynamicInputs';
import FormInputError from '../../../../../../../hireserver/components/FormInputError';
import InputErrorBackground from '../../../../../../../hireserver/components/InputErrorBackground';
import ModalHs from '../../../../../../../hireserver/ModalHs'
import SpacerHS_TWO from '../../../../../../../hireserver/Spaces/SpacerHS_TWO';


function ScriptExecModal({formErrors, setFormErrors,setParentValues, loadingExec, showModal, closeModal, okayModal, item }) {
    const [options, setOption] = React.useState(null);
    const [password, setPassword] = React.useState('');
    const { inputs,setInputs,putValues,updateItem} = useDynamicInputs(); 
    const [tokensState,setTokensState] = React.useState([]);

    // const { value: passwordVal, setValue: setPasswordVal, error: passwordError, setError: setPasswordError } = useInputHook();
    // const { value: portVal, setValue: setPortVal, error: portError, setError: setPortError } = useInputHook();
    // const { value: shhUserVal, setValue: setSshUserval, error: shhuserError, setError: setSshUserError } = useInputHook();
    // const { value: shhIpVal, setValue: setSshIpval, error: shhIpError, setError: setSshIpError } = useInputHook();

    const { value: passwordVal, setValue: setPasswordVal, error: passwordError, setError: setPasswordError, resetAll: resetPass } = useInputHook();
    const { value: portVal, setValue: setPortVal, error: portError, setError: setPortError, resetAll: resetPort } = useInputHook();
    const { value: shhUserVal, setValue: setSshUserval, error: shhuserError, setError: setSshUserError, resetAll: resetSshUser } = useInputHook();
    const { value: shhIpVal, setValue: setSshIpval, error: shhIpError, setError: setSshIpError, resetAll: resetSshIp } = useInputHook();
    
    React.useEffect(function (){
        if(!showModal){
            // setInputs({});
            setSshIpError({
                isError: false,
                message: ''
            })
            setPortError({
                isError: false,
                message: ''
            })
            resetPass();
            setSshUserError({
                isError: false,
                message: ''
            })
            setFormErrors({
                isError:false,
                message:''
            })
        }
    },[showModal]);

    function formatGettingData(parentTokens){
        let obj = {

        };

        for(let i=0;i<parentTokens.length;i++){
            let {key,value} = parentTokens[i];
            obj[key] = {
                error:{
                    "isError": false,
                    "message": ""
                },
                id:uuid(),
                key:key,
                value:value
            } 
        }

        return obj;
    }

    async function setInit(){
      
        console.log(item);

        if (item) {
        
            const { tokens } = item;
            if (tokens) {
               
                const res = await getDetailsScriptService(item.parentid);

                const isValid = checkResData(res);
                if (!isValid) {
        
                }
        
                let parentData = JSON.parse(res.data.result)[0];
                setParentValues(parentData);
                let parentScript = parentData.script;
                const parentScriptTokens = getTokenFilter(parentScript);
                let parentToken = [];
                for(let item in parentScriptTokens){
                    const {name,val} = parentScriptTokens[item];
                    parentToken.push({key:name,value:val});
                } 

                let parentTokenObj = formatGettingData(parentToken);

                const data = JSON.parse(tokens);

               
                if(data.params.length){
                 
                    let tokens = data.params;
                    let hashObj = {};
                    let filterFinal = {};
                    for(let i=0;i<tokens.length;i++){
                        const {key,value} = tokens[i];
                        hashObj[key] = {
                            key,value,id:uuid(),
                            error:{
                                isError:false,
                                message:''
                            }
                        };
                    }

                    debugger;
            for(let token in parentTokenObj){
                let curr = hashObj[token];
                if(curr){
                    filterFinal[token] = {
                        ...curr,
                        
                    }
                }else{
                    filterFinal[token] = parentTokenObj[token];
                }
            }
                    setInputs({...filterFinal});
                }else{
                    setInputs({});
                }
                setTokensState(data.params);
                console.log(data);
                const {sship,sshpassword,sshport,sshuser} = data;
                setSshIpval(sship);
                setSshUserval(sshuser);
                setPortVal(sshport)
                setOption(data);

            }
        }
    }

    React.useEffect(function () {
        setInit();
    }, [item,showModal]);

    function handlerInput(name,e){
     
       updateItem(name,e.target.value)
    }

    function renderTokens(){
        let inputsHTML = [];
        for(let item in inputs){
            const {key:name,value:val,id,error} = inputs[item];
            
            inputsHTML.push(<div className='col-sm-4 pb-2' key={id}>
                <div>
                    {name}
                </div>
                <input className='form-control hs_borderRadius-4' value={val} id={id} onChange={(e)=>handlerInput(name,e)}  />
                {error.isError ? <FormInputError message={error.message} />:null}
            </div>);
        }
        console.log(inputsHTML);
        return inputsHTML;
    }

    function handlerpasswordVal(e) {
        setPasswordVal(e.target.value);
        setPasswordError({
            isError: false,
            message: ""
        })
    }

    function handlerPortVal(e) {

        if (e.which != 8 && e.which != 0 && e.which < 48 || e.which > 57) {
            e.preventDefault();
            return;
        }
        setPortVal(e.target.value);
        setPortError({
            isError: false,
            message: ""
        })
    }

    function handlerShhUserVal(e) {
        setSshUserval(e.target.value);
        setSshUserError({
            isError: false,
            message: ""
        })
    }

    function handlerSshIpChange(e) {
        setSshIpval(e.target.value);
        setSshIpError({
            isError: false,
            message: ""
        })
    }

    function validScriptForm() {
        let isError = false;
     
      

        if (!shhIpVal) {
            isError = true;
            setSshIpError({
                isError: true,
                message: 'SSH IP cannot be empty'
            })
        }

        if (!portVal) {
            isError = true;
            setPortError({
                isError: true,
                message: 'Port cannot be empty'
            })
        }

        if (!shhUserVal) {
            isError = true;
            setSshUserError({
                isError: true,
                message: 'User cannot be empty'
            })
        }

        if (!passwordVal) {
            isError = true;
            setPasswordError({
                isError: true,
                message: 'Password cannot be empty'
            })
        }
        let newInputs = cloneDeep(inputs);
        let isChanged = false;
        for(let token in newInputs){
            let currItem = newInputs[token];
           if(!currItem.value){
            isChanged = true;
            newInputs[token] = {
                ...currItem,
                error:{
                    isError:true,
                    message:`Value cannot be empty`
                }
            }
           }
        }

       if(isChanged){
        isError = true;
        setInputs(newInputs);
       }
        console.log(inputs,'dasdsd');

    //     let newTokens = structuredClone(tokenOptions);
    //     let isChanged = false;
    //     for(let token in newTokens){
    //         let currItem = newTokens[token];
    //        if(!currItem.val){
    //         isChanged = true;
    //         newTokens[token] = {
    //             ...currItem,
    //             error:{
    //                 isError:true,
    //                 message:`Value cannot be empty`
    //             }
    //         }
    //        }
    //     }

    //    if(isChanged){
    //     isError = true;
    //     setTokenOptions(newTokens);
    //    }
        return isError;
    }

    function handleOkay(){
       const error =  validScriptForm();
        if(error){
            return;
        }
        okayModal(inputs,{shhIpVal,shhUserVal,portVal,passwordVal})
    }


    


    return (
        <ModalHs size="lg" footerNone={true} isNeed={false} showModalHS={showModal} btnTitle="Yes" >
            <div className='w-100'>
                <div>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <div>SSH</div>
                            <input disabled onChange={handlerSshIpChange} value={shhIpVal} type="text"  className="form-control hs_borderRadius-4" />
                            {shhIpError.isError && <FormInputError message={shhIpError.message} />}
                        </div>
                        <div className='col-sm-6'>
                            <div>PORT</div>
                            <input onKeyDown={blockInvalidChar} onChange={handlerPortVal} value={portVal} type="number"  className="form-control hs_borderRadius-4" />
                            {portError.isError && <FormInputError message={portError.message} />}
                        </div>
                    </div>
                    <SpacerHS_TWO />
                    <div className='row'>
                        <div className='col-sm-6'>
                            <div>User</div>
                            <input onChange={handlerShhUserVal} value={shhUserVal} type="text"  className="form-control hs_borderRadius-4" />
                            {shhuserError.isError && <FormInputError message={shhuserError.message} />}
                        </div>
                        <div className='col-sm-6'>
                            <div>Password</div>
                            <input autoComplete='new-password' placeholder='Enter your password' value={passwordVal} type="password" onChange={handlerpasswordVal} className="form-control hs_borderRadius-4" />
                            {passwordError.isError && <FormInputError message={passwordError.message} />}
                        </div>
                    </div>
                </div>
                {Object.keys(inputs).length?<React.Fragment>
                    <SpacerHS_TWO />
                <div>
                    <div>Tokens</div>
                    <div className='row'>
                        {renderTokens()}
                    </div>
                </div></React.Fragment>:null}
                <SpacerHS_TWO />
               {formErrors.isError ?  <InputErrorBackground message={formErrors.message} /> :null}
                <div className='align-items-end d-flex d-gap-10 justify-content-end'>
                    <button onClick={closeModal} className='btn bg-dark-alt text-black hs_borderRadius-4 px-2 py-1'>Close</button>
                    <button disabled={loadingExec} onClick={handleOkay} className='btn hs_borderRadius-4 px-2 w-100p py-1 bg-danger text-white'>

                        {loadingExec ? <div className="spinner-border  text-light" role="status">

                        </div> : 'Execute'}
                    </button>
                </div>
            </div>
        </ModalHs>
    )
}

export default ScriptExecModal