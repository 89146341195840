import React, { Fragment } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import HeadingHS from "./controls/HeadingHS";
import alalinuxImga from "../../../images/hireserver/imgs/almaLinuxLogo.png";
import centosImga from "../../../images/hireserver/imgs/centOS.png";
import VzImga from "../../../images/hireserver/imgs/almaLinuxLogo.png";
import windowImga from "../../../images/hireserver/imgs/windows.png";
// import freeImga from '../../../images/hireserver/imgs/freeBSD.png';
import OsSelectionCard from "./SharedComponents/OsSelectionCard";
import LoaderHS from './../hireserver/components/LoaderHS';

import MiniCard from "./SharedComponents/MiniCard/MiniCard";
import InstanceFormDatabase from "../Dashboard/Instances/components/databases/InstanceFormDatabase";
import MarketPlacesInstances from "../Dashboard/Instances/components/marketPlaces/MarketPlacesInstances";

function ServerCard({ OSImage, id, name, nextname }) {
  return (
    <div
      style={{ padding: "12px 16px", width: "280px" }}
      className="d-flex justify-content-between  border d-gap-40"
    >
      <div className="d-flex justify-content-between d-gap-15">
        <div style={{ width: "18px", height: "18px" }}>
          <img className="w-100 h-100" src={OSImage} alt={name} />
        </div>
        <div>{name}</div>
      </div>
      <div className="font-14 hs_text-light">{nextname}</div>
    </div>
  );
}

function SelectionsOptions({
  title,
  OptionsCardArr,
  clickHandler,
  currentOs,
  typeOS = "64bit",
  databasesList, setDatabasesList, marketplaceStateid, setMarketPlaceStateId, setSelectOsHandler, selectOsDB, setSelectOsDB, handleOsClickDB,
  marketPlacesList,
  marketPlacesItemId,setMarketPlacesItemId,selectOsMarketPlace, setSelectMarketPlace,handleOsClickMarket,
  dontshowheading,
  addComponenetTabs

}) {
  function getOS() {

    // console.log(OptionsCardArr,'isOSlist??')
    if (OptionsCardArr?.loading === true) {
      return <LoaderHS />

    }

    let osData = OptionsCardArr[0].options;
    if (osData) {

      let processData = preProcessGroup(osData);
      console.log(osData, 'osDataosDataosDataosDataosData');
      let osHTML = [];

      for (let os in processData) {

        //// console.log(processData[os]);
        let itemCurr = processData[os];

        let itm = null;

       
        if (itemCurr.length === 1) {

          let checkIs = currentOs["groupname"]?.trim()?.toLowerCase() == itemCurr[0]['groupname']?.toLowerCase() ? true : false;
          const { id, dataName, groupname, image, osfilename, type } = itemCurr[0]

          itm = <MiniCard
            isChecked={checkIs}

            groupname={groupname.charAt(0).toUpperCase() + groupname.slice(1)}
            key={id}
            name={OptionsCardArr[0].name}
            dataName={dataName}
            isDropdown
            id={id}
            osfilename={osfilename}
            otherattr={{ 'data-type': type }}
            cardName={dataName}
            handler={clickHandler}
            OSImage={image}
          // nextname="64 Bit"
          />
        } else {
          let ids = [];
          const { id, dataName, groupname, image, osfilename, type } = itemCurr[0];
          let checkIs = false;
          //currentOs["id"] == itemCurr[0]['id'] ? true : false;
          //let checkIs = currentOs["id"] == itemCurr['id'] ? true : false;

          for (let i = 0; i < itemCurr.length; i++) {
            ids.push({ nameV: itemCurr[i].dataName, id: itemCurr[i].id });

            if (currentOs["groupname"] == itemCurr[i]?.groupname) {
              checkIs = true;
            }
          }

          itm = <MiniCard
            isChecked={checkIs}
            key={id}
            groupname={groupname}
            name={OptionsCardArr[0].name}
            dataName={dataName}
            isDropdown
            dropDownList={ids}
            id={id}
            osfilename={osfilename}
            otherattr={{ 'data-type': type }}
            cardName={groupname}
            handler={clickHandler}
            OSImage={image}
          // nextname="64 Bit"
          />
        }




        osHTML.push(itm)

      }
      
      return <>
        {osHTML}
      </>
      return osData.map(function ({ dataName, id, image, type, osfilename }, i) {
        let checkIs = currentOs["id"] == id ? true : false;
        let imgC = image ? image : windowImga;

        return (
          <MiniCard
            isChecked={checkIs}
            key={i * 61}
            name={OptionsCardArr[0].name}
            dataName={dataName}
            isDropdown
            id={id}
            osfilename={osfilename}
            otherattr={{ 'data-type': type }}
            cardName={dataName}
            handler={clickHandler}
            OSImage={imgC}
          />
        );
      });
    }

    function preProcessGroup(data) {
      //// console.log(data,'ossssssssssssssssssssssssssssss');
      let processData = [];
      let hashObj = {};
      for (let i = 0; i < data.length; i++) {
        if (data[i]?.type == typeOS) {
          if (data[i].groupname === null || data[i].groupname === '') {
            hashObj[data[i].groupname] = [data[i]];
          } else if (!hashObj[data[i].groupname]) {
            hashObj[data[i].groupname] = [data[i]];
          } else {
            hashObj[data[i].groupname].push(data[i]);
          }
        }
      }


      return hashObj;
    }

    return (
      <>

        <div>Something Went Wrong</div>
      </>
    );
  }

  return (
    <div className="softwareSelectionPart position-relative">
    {!dontshowheading &&  <HeadingHS as="h3 text-primary" title="Software Selections" />}
      {OptionsCardArr.length === 0 && <div>No OS Found</div>}
      {OptionsCardArr.length >= 1 && (
        <div className="tabsSelection">
          <Tabs
          onSelect={(e)=>console.log(e)}
            defaultActiveKey={OptionsCardArr[0].name}
            id="uncontrolled-tab-example"
            className="mb-3 w-100 softwareTabsOver"
          >
            <Tab
            className="no-word-break"
              eventKey={OptionsCardArr[0].name}
              key={OptionsCardArr[0].name}
              title={"OS"}
            >
              <div className="text-center row d-gap-15 m-0 justify-content-lg-start justify-content-center">
             
                {getOS()}
              
              </div>
            </Tab>
            <Tab className="no-word-break" eventKey="databases" key="databases" title="Databases">
              <InstanceFormDatabase databasesList={databasesList} setDatabasesList={setDatabasesList} marketplaceStateid={marketplaceStateid} setMarketPlaceStateId={setMarketPlaceStateId} setSelectOsHandler={setSelectOsHandler} selectOsDB={selectOsDB} setSelectMarketPlace={setSelectMarketPlace} setSelectOsDB={setSelectOsDB} handleOsClickDB={handleOsClickDB} setMarketPlacesItemId={setMarketPlacesItemId}  />
            </Tab>
            <Tab className="no-word-break" eventKey="marketplaces" key="marketplace" title="Apps & Panels">
             <MarketPlacesInstances  setMarketPlaceStateId={setMarketPlaceStateId}  selectOsMarketPlace={selectOsMarketPlace} setSelectMarketPlace={setSelectMarketPlace} setSelectOsHandler={setSelectOsHandler} handleOsClickMarket={handleOsClickMarket} setSelectOsDB={setSelectOsDB}  marketPlacesItemId={marketPlacesItemId} setMarketPlacesItemId={setMarketPlacesItemId} marketPlacesList={marketPlacesList} />
            </Tab>
            {addComponenetTabs.length >=1  ? addComponenetTabs.map((val,i)=>{
              return val;
            }):null}
          </Tabs>
        </div>
      )}
    </div>
  );
}

SelectionsOptions.defaultProps = {
  addComponenetTabs:[]
}

export default React.memo(SelectionsOptions);

