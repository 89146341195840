import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  loadingToggleAction,
  loginAction,
  loginConfirmedAction,
  loginFailedAction,
  logout,
  setUserCookieAuth,
  signUpTrueAction,
} from "../../store/actions/AuthActions";
import OtpInput from 'react-otp-input';

import logo from "../../images/login-logo.png";

import login_left from "../../images/login-left.png";
import Cookies from "js-cookie";
import { USER_COOKIE } from "../../store/types/AuthTypes/AuthTypes";
import { checkIsSessionValid, formatError, loginService, postOTPValidService, saveTokenInLocalStorage, signUpService } from "../../services/AuthService";
import MainLoader from "../components/hireserver/components/Loaders/MainLoader";
import PasswordInputHS from "../components/hireserver/controls/PasswordInputHS";
import { cssTransition, toast, ToastContainer } from "react-toastify";
import { ToastCustomAlert_hs } from "../components/hireserver/ToastCustomAlert_hs";
import ModalHs from "../components/hireserver/ModalHs";
import OTP_Modal from "./RegistrationComponents/OTP_Modal";
import SpacerHS_TWO from "../components/hireserver/Spaces/SpacerHS_TWO";
import InputBorderDown from "./RegistrationComponents/InputBorderDown";
import ErrorClass from "../../services/ServiceErrorHandlers/ErrorClass";
import OTPComponent from "../components/hireserver/SharedComponents/OTP/OTPComponent";
import { getpaymentInfoService } from "../../services/Profile/PaymentFormServices";
import { openPaymentInfoFormAction, putPaymentInfoStatus } from "../../store/actions/PaymentInfoActions/PaymentInfoAction";
import { checkEmailValidHelper, checkPasswordValidHelper, containsSpecialChars, isLowerCase, isNumberContains, isUpperCase } from "../../utils/Helpers";
import FormInputError from "../components/hireserver/components/FormInputError";
import InputErrorBackground from "../components/hireserver/components/InputErrorBackground";
import PasswordDot from "../components/hireserver/components/Static/PasswordDot";
import ImageHs from "../components/hireserver/ImageHs";
import LoginSkeletonScreen from "../components/hireserver/components/LoginSkeletonScreen";
import InputLabelUp from "../components/hireserver/controls/InputLabelUp/InputLabelUp";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

function ErrorMessageDisplay({ message, center }) {
  return <p className={`font-14 text-danger ${center && 'text-center'}`}>{message}</p>
}

function Registration(props) {
  // signUp start
  let errorsObj = { email: "", password: "" };
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = React.useState({
    val: '',
    error: false,
    errorMessage: ''
  });

  const [emailError, setEmailError] = React.useState({
    touched: false,
    isError: false,
    errorMessage: ''
  })

  const [passwordError, setPasswordError] = React.useState({
    touched: false,
    isError: false,
    errorMessage: ''
  })

  const [termsChecked, setTermsChecked] = React.useState(false);
  const [termsError, setTermError] = React.useState({
    isError: false,
    message: ''
  })

  // signUp end

  const [loginError, setLoginError] = React.useState({
    isError: false,
    message: ''
  })


  const [phoneNumber, setPhoneNumber] = React.useState({
    val: '',
    error: false,
    errorMessage: ''
  })
  const [errorSign, setErrorSign] = React.useState({
    message: 'hello world',
    classes: 'd-none'
  })

  const recaptchaRef = React.useRef(null);
  const [showCap,setShowCap] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [otp_loading, setOtpLoading] = React.useState(false);
  const [resErrorMessage, setResErrorMessage] = React.useState({
    error: false,
    message: ''
  });
  //const [OTP_ModalState, setOTP_ModalState] = React.useState(true);
  const [otpActive, setOTP_Active] = React.useState(false);
  const [libOtp, setLibOtp] = React.useState({ otp: '' });
  const [otpError, setOtpError] = React.useState({
    isError: false,
    message: 'Something went wrong'
  })
  const [otpId, setOtpId] = React.useState(0);



  const dispatch = useDispatch();
  const [mainLoading, setMainLoading] = React.useState(true);
  const [timerLoader, setTimerLoader] = React.useState(false);

  const [passwordAllCheck, setPasswordAllCheck] = React.useState({
    lowercase: true,
    uppercase: true,
    isnumber: true,
    isLengthMin: true,
    isSpecail: true
  });

  const [isLowerCaseState, setIsLowerCaseState] = React.useState(true);
  const [isUpperCaseState, setIsUpperCaseState] = React.useState(true);
  const [isNumberState, setIsNumberState] = React.useState(true);
  const [isLengthMinState, setIsLengthMinState] = React.useState(true);
  const [isSpecailState, setIsSpecailState] = React.useState(true);
  const [passwordCheckShow,setPasswordCheckShow] = React.useState(false);
  const [recaptchaResToken,setRecaptchaResToken] = React.useState('');
  const [recaptchaValidError,setrecaptchaValidError] = React.useState({
    isError:false,
    message:''
  })

  const [userIdState,setUserIdState] = React.useState(null);

  React.useEffect(function () {
    CheckUser();
    return () => {
      setRecaptchaResToken('');
    }
  }, []);

  React.useEffect(function () {
    let timer = null;
    if (timerLoader) {
      timer = setTimeout(function () {
        setMainLoading(false);
      }, 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [timerLoader])

  if (mainLoading) {
    return <MainLoader />
  }


  function showError(message) {
    setErrorSign({
      message: `${message}`,
      classes: ''
    })

    setTimeout(function () {
      setErrorSign({
        message: ``,
        classes: 'd-none'
      })
    }, 3000)
  }

  function clearInputStateTimer(handler, val) {
    if (!handler) return;
    setTimeout(function () {
      handler(val);
    }, 3000);
  }

  function onChangeRecaptcha(value) {
    // console.log("Captcha value:", value);
    const api = `https://www.google.com/recaptcha/api/siteverify`;

    setRecaptchaResToken(value);

   
  }


  function checkPasswordValid(password, passwordConfirm) {
    '';
    let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,}$/;
    if (password === '') {
      setPasswordError({
        touched: true,
        isError: true,
        errorMessage: 'Password is required'
      })
      return true;
    }

    if (!regex.test(password)) {
      setPasswordError({
        touched: true,
        isError: true,
        errorMessage: 'password must be at least 8 characters long one uppercase with one lowercase and one numeric character'
      })
      setIsLoading(false);

      return true;
    }

    return false;
  }



  function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }

    if (error) {
      return;
    }
    dispatch(loadingToggleAction(true));
    dispatch(loginAction(email, password, props.history));
  }

  function handleClickErrorAlert(e, errorMsg, type) {
    //   '';
    const fadeInAndOut = cssTransition({
      enter: "alertFadein",
      exit: "alertFadeout",
    });
    toast(
      <ToastCustomAlert_hs type={type} variant="danger" text={errorMsg} />,
      {
        autoClose: true,
        className: "toasterCustomError",
        bodyClassName: "toasterCustomBodyAlert",
        closeOnClick: true,
        transition: fadeInAndOut,
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      }
    );
  }

  async function autoLogin() {
    setOtpLoading(true);
    const errorClass = new ErrorClass();
    errorClass.dispatch = dispatch;
    errorClass.history = props.history;

    try {
      const response = await loginService(email, password, props.history);

      if (response.data.errors?.length >= 1) {
        //dispatch(loadingToggleAction())
        setLoginError({
          isError: true,
          message: response.data.message
        })

        setTimeout(function () {
          setLoginError({
            isError: false,
            message: ''
          })
        }, 3000);
        setOtpLoading(false);
        // setServerError({
        //   isError:true,
        //   message:response.data.message
        // })
        //handleClickErrorAlert('danger', response.data.message, 'danger')
        // handlerAlert("danger", 'errrror', 'danger')
        return;
      }
      response.data.result['userEmail'] = email;
      let { unibaseId, sessionId, userId } = response.data.result;
      setUserCookieAuth({unibaseId, sessionId, userId,userEmail:email,email});

      // // console.log(response.data.result);
      saveTokenInLocalStorage(response.data.result);

      //runLogoutTimer(dispatch, 1800000, history);
      dispatch(loginConfirmedAction(response.data.result));
      let isFilled = false;
      let paymentData = null;
      const checkPaymentStatus = await getpaymentInfoService();
      paymentData = JSON.parse(checkPaymentStatus.data.result);
      isFilled = paymentData[0]?.defaultaddressid == 0;
      dispatch(putPaymentInfoStatus(paymentData[0]));
      if (isFilled) {
        dispatch(openPaymentInfoFormAction())
      }

      props.history.push("/");
      // dispatch(loadingToggleAction())
      setOtpLoading(false);
      //dispatch(loginAction(email, password, props.history));
    } catch (error) {

      const errorMessage = formatError(error);
      // console.log(error);
      if (error.message === 'Network Error') {
        props.history.push('no-network');
      }
      dispatch(loginFailedAction(errorMessage));


    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setOtpLoading(true);

    const otp_Valid = await postOTPValidService({
      "email": email,
      "otpid": otpId,
      "userotp": libOtp.otp
    });

    // console.log(otp_Valid);
    if (otp_Valid.data.result != 'Otp verified successfully') {
      // console.log(otp_Valid,'otp_Validotp_Validotp_Validotp_Valid')
      setOtpError({
        isError: true,
        message: otp_Valid.data.message
      });
      setOtpLoading(false);
      return;
    }

    if (autoLogin) {
      setTimeout(function () {
        autoLogin();
      }, 3000)
    }
  }

  function otpLibChange(otp) {
    setLibOtp({ otp })
  }

  async function signUp(e) {
    e.preventDefault();

    // // console.log(phoneNumber.val)
    // // console.log(username.val)
    // // console.log(passwordConfirm.val)
    // // console.log(password.val)
    // // console.log(email.val)
    let editError = false;


    if (email === '') {
      editError = true;
      setEmailError({
        touched: true,
        isError: true,
        errorMessage: 'Email Address is required'
      })



    }

    if (editError === false) {
      let mailformat = checkEmailValidHelper(email)
      if (!mailformat) {
        editError = true;
        // setEmail({
        //   val: email.val,
        //   error: true,
        //   errorMessage: 'Please enter your valid Email ID'
        // });
        setEmailError({
          touched: true,
          isError: true,
          errorMessage: 'Please enter your valid Email ID'
        })
      }
    }

    // if (termsChecked === false) {
    //   editError = true
    //   setTermError({
    //     isError: true,
    //     message: ""
    //   })
    // } else {
    //   setTermError({
    //     isError: false,
    //     message: ""
    //   })
    // }

    if (checkPasswordValid(password, passwordConfirm)) {
      setIsLoading(false)
      return;
    }

  //   if(showCap === false){
  //     setShowCap(true);
  //     return;
  //   }

  //   let  config = {
  //     method: 'post',
  //     url:`https://www.google.com/recaptcha/api/siteverify?secret=${'6Lcqm_YiAAAAAN3JnWIBBELEII-Vl20MiDLSg9sa'}&response=${recaptchaResToken}`,
  //   };

  // const recaptchRes = await axios(config);
  // const {success} = recaptchRes.data;
  // if(!success) {
  //   setrecaptchaValidError({
  //     isError:true,
  //     message:''
  //   })
  //   setIsLoading(false);
  //  // recaptchaRef.current.classList.remove('d-none');
  //   setShowCap(true);
  //   return;
  // }


    if (editError) {

      return;
    }
    setIsLoading(true);

    let pNumber = '';
    if (phoneNumber.val === '') {
      pNumber = '0';
    } else {
      pNumber = phoneNumber.val
    }

    let name = email.split('@')[0];
    const payload = JSON.stringify({
      "organizationtypeid": "2",
      "organizationid": "0",
      "contactid": "0",
      "userid": "0",
      "username": "0",
      "users_phonenumber": "0",
      "password": password,
      "users_emailaddress": "0",
      "emailaddress": email,
      "contactname": name,
      // "phonenumber": `${phoneNumber.val}`,
      "phonenumber": `0`,
      "branchid": "0",
      "tenantname": "Test_Tenant",
      // "customerformuniqueid":"Bizgaze_Platform_Crm_CreateCustomer",
      "rolename": "Customer Admin",
      "currencyid": "100015210000002",
      "customerformuniqueid": "Bizgaze_Hyperfuison_CreateCustomer"

    })


    try {
      // dispatch(loadingToggleAction())
      const res = await signUpService(payload);

      // console.log(res, 'sign up');
      if (res.data.code != '0' && res.data != '') {
        // errorRef.current
        // setErrorSign({
        //   message:res.data.message,
        //   classes:''
        // })
        //handleClickErrorAlert("danger", res.data.message, "danger");
        setShowCap(false); 
        setResErrorMessage({
          error: true,
          message: res.data.message
        })
        // dispatch(loadingToggleAction())
        setIsLoading(false);
      
        setShowCap(true);

        // setTimeout(function (){
        //   setResErrorMessage({
        //     error:false,
        //     message: ''
        //   })

        // },3000)
        return;
      }

      if (res.data == '') {
        // setErrorSign({
        //   message:'Something went wrong. Try again.',
        //   classes:''
        // })
        // handleClickErrorAlert("danger", 'Something went wrong. Try again.', "danger");
        setResErrorMessage({
          error: true,
          message: 'Something went wrong. Try again.'
        })
        setIsLoading(false);
        // setTimeout(function (){
        //   setResErrorMessage({
        //     error:false,
        //     message: ''
        //   })

        // },3000)

        return;
      }
      //dispatch(loadingToggleAction())

      setOtpId(res.data.result?.OtpId)
      setUserIdState(res.data.result?.UnibaseId);
      setIsLoading(false);
      //dispatch(signUpTrueAction());


      setOTP_Active(true);
      setResErrorMessage({
        error: false,
        message: ''
      })
      // props.history.push('/login');

    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }

  }

  // React.useEffect(function (){
  //   return () => {
  //     setIsLoading(false);
  //   }
  // },[]);

  async function CheckUser() {
    //const {sessionId} = JSON.parse(Cookies.get(USER_COOKIE));
    const userCookie = Cookies.get(USER_COOKIE);

    if (!userCookie) {
      setIsLoading(false);
      setTimerLoader(true);
      return;
    };
    // '';
    dispatch(loadingToggleAction(true))
    let cookieParse = JSON.parse(userCookie)
    let isTrue = await checkIsSessionValid(cookieParse.sessionId);

    let tokenDetails = '';

    if (!isTrue) {
      dispatch(loadingToggleAction(false))
      dispatch(logout(props.history));
      return;
    }
    setMainLoading(false);
    dispatch(loadingToggleAction(false))
    props.history.push('/');

  }

  function handleEmail(e) {

    setEmail(e.target.value);
    if (e.target.value == '') {
      setEmailError({
        touched: true,
        isError: true,
        errorMessage: 'Email Address is required'
      })
      return;
    }

    let checkValidEmail = checkEmailValidHelper(e.target.value);
    if (!checkValidEmail) {
      setEmailError({
        touched: true,
        isError: true,
        errorMessage: 'Email Address must be valid'
      })
    } else {
      setEmailError({
        touched: true,
        isError: false,
        errorMessage: ''
      })
    }

  }

  function handlerPasswordDotsUpload(handler, name, bool) {
    handler(bool);
  }

  function isAllCheckPassword(value) {


    let specail = containsSpecialChars(value);
    if (specail) {
      handlerPasswordDotsUpload(setIsSpecailState, 'isSpecail', false);
    } else {
      handlerPasswordDotsUpload(setIsSpecailState, 'isSpecail', true);
    }

    let isNumberContainsVar = isNumberContains(value);

    if (isNumberContainsVar) {
      handlerPasswordDotsUpload(setIsNumberState, 'isnumber', false);
    } else {
      handlerPasswordDotsUpload(setIsNumberState, 'isnumber', true);
    }

    let isLowerCaseContains = isLowerCase(value);
    if (isLowerCaseContains) {
      handlerPasswordDotsUpload(setIsLowerCaseState, 'lowercase', false);
    } else {
      handlerPasswordDotsUpload(setIsLowerCaseState, 'lowercase', true);
    }

    let isUpperCaseContians = isUpperCase(value);
    if (isUpperCaseContians) {
      handlerPasswordDotsUpload(setIsUpperCaseState, 'uppercase', false);
    } else {
      handlerPasswordDotsUpload(setIsUpperCaseState, 'uppercase', true);
    }

    let isLength = value.length >= 8;
    if (isLength) {
      handlerPasswordDotsUpload(setIsLengthMinState, 'isLengthMin', false);
    } else {
      handlerPasswordDotsUpload(setIsLengthMinState, 'isLengthMin', true);
    }


  }

  function handlePassword(e) {
    let value = e.target.value;
    setPassword(value);
    if(value != '' ){
      setPasswordCheckShow(true);
    }
    isAllCheckPassword(value)
    if (value == '') {
      setPasswordError({
        touched: true,
        isError: true,
        errorMessage: 'Password is required'
      })
      return;
    }

    if (email === '') {
      setEmailError({
        touched: true,
        isError: true,
        errorMessage: 'Email Address is required'
      })
    }

    // if (!checkPasswordValidHelper(value)) {
    //   setPasswordError({
    //     touched: true,
    //     isError: true,
    //     errorMessage: 'Password Must be Aleast contain One Captial,Small, Number and Special Character and length should be 8'
    //   })

    //   return;
    // }

    setPasswordError({
      touched: true,
      isError: false,
      errorMessage: ''
    })

  }

  function focusOutHandler(e){

    setPasswordCheckShow(false);
  }

  function loginHereHandler(){
    props.history.push('/login');
  }
  

  return (
    <LoginSkeletonScreen>
    <React.Fragment>
      {
        
        <React.Fragment>
          {/* <div className="col-xl-6 d-none d-md-block p-0">
            <div className="banner-img-container" style={{ height: "100vh" }}>
              <img src={login_left} alt="img" className="h-100 w-100" />
            </div>
          </div>
          <div className="col-xl-6 p-0 d-none d-md-block bg-login-right">
            <div className="float-right banner_img_down_container_hs">
              <img src={logo} className="banner-img-container_hs" alt="img" />
            </div>
          </div> */}

       
            {/* <div className="login-aside-left d-none" style={{backgroundImage:"url("+ login +")"}}> */}
           
            {
              !otpActive && <div className="login-aside-right  pt-4 ">
                {/* <div className="row m-0 justify-content-center h-100 align-items-center">
                  <div className="col-xl-12 col-xxl-12 h-100">
                    <div className="authincation-content h-100">
                      <div className="row no-gutters h-100">
                        <div className="col-xl-12 h-100">
                          <div className="auth-form-1 h-100 d-flex flex-column justify-content-around">
                       

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              
                <div className="">
                              {/* <h3 className="text-dark mb-1">
                                Welcome to Hireserver
                              </h3> */}
                              {/* <p className="text-dark">
                                Create your Hireserver account. Enter your email and create a password to get started!
                              </p> */}
                              <p className="h3">
                                Sign up

                              </p>

                            </div>
                            {props.errorMessage && (
                              <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                                {props.errorMessage}
                              </div>
                            )}
                            {props.successMessage && (
                              <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                                {props.successMessage}
                              </div>
                            )}
                            {/* {resErrorMessage.error && <InputErrorBackground classes="mt-3" message={resErrorMessage.message} />} */}

                            {
                resErrorMessage.error && resErrorMessage.message != 'User Already Exists!' &&
                <InputErrorBackground message={resErrorMessage.message} />
              }

              {
                resErrorMessage.error && resErrorMessage.message == 'User Already Exists!' && <InputErrorBackground classesChild="font-12 text-primary font-w600" clickAction={{ handleClick: loginHereHandler, isLoading: false, text: 'Login Here' }} message={resErrorMessage.message} />
              }
                            {/* <div ref={errorRef} className={`${errorSign.classes} text-center text-danger`}>
                            {errorSign.message}
                          </div> */}
                            <form onSubmit={signUp}>
                              {/* username */}
                              {/* <div className="form-group">
                              <label className="mb-2 ">
                                <strong className="text-dark">Username</strong>
                                
                              </label>
                              <input
                                type="text"
                                className={`form-control ${username.error && 'inputAlertVal'} hs_borderRadius-4`}
                                value={username.val}
                                placeholder="Please enter your name"
                                onChange={(e) => setuserName({
                                  val:e.target.value,
                                  error:false,
                                  errorMessage:''
                                })}
                              /> */}
                              {/* {username.error &&  <p className="font-12 text-danger ">{ username.errorMessage}</p>} */}
                              {/* {username.error &&  <ErrorMessageDisplay message={username.errorMessage} /> } */}
                              {/* </div> */}
                              {/* username end */}
                              <div className="form-group">
                                <div className="d-flex justify-content-between align-items-baseline">
                                  <label className="mb-2 ">
                                    {/* <strong className="text-dark">Email</strong> */}
                                  </label>
                                  <div className="new-account mt-2 mb-1">
                                    <p className="text-dark font-14">
                                      Already have an account?
                                      <Link className="text-primary px-1" to="/login">
                                        Login
                                      </Link>
                                    </p>
                                  </div>
                                  
                                </div>
                              
                                {/* <input
                                  type="text"
                                  className={`form-control p-2 hs_borderRadius-4 ${emailError.isError ? 'inputAlertVal' : ''} ${!emailError.isError && emailError.touched ? 'validFormInput' : ''}`}
                                  value={email}
                                  onChange={handleEmail}
                                  placeholder="Please enter Email"
                                /> */}
                                 <InputLabelUp classNames={`${emailError.isError ? 'inputAlertVal' : ''} ${!emailError.isError && emailError.touched ? 'validFormInput' : ''}`} setValue={handleEmail}  value={email} placeholder="Enter your email" id="idInput" type="text"  />
                                {emailError.isError && <FormInputError message={emailError.errorMessage} />}
                              </div>

                              <div className="position-relative">
                                {/* <div className="mb-1 font-w600">
                                  Password
                                </div> */}
                               {passwordCheckShow && <div className="passwordBoxHS">
                                  <div className="passwordBoxHSWHiteBox">

                                  </div>
                                  <div>
                                    Use at least
                                  </div>
                                  <div>
                                    <div  className={`${isLowerCaseState ? 'hs_text-light' : 'line-through text-primary'}`}>
                                    1 lowercase character
                                    </div>
                                    <div  className={`${isUpperCaseState ? 'hs_text-light' : 'line-through text-primary'}`}>
                                    1 uppercase character
                                    </div>
                                    <div className={`${isSpecailState ? 'hs_text-light' : 'line-through text-primary'}`}>
                                    1 special character
                                    </div>
                                    <div className={`${isNumberState ? 'hs_text-light' : 'line-through text-primary'}`}>
                                    1 number
                                    </div>
                                    <div  className={`${isLengthMinState ? 'hs_text-light' : 'line-through text-primary'}`}>
                                    8 characters minimum
                                    </div>
                                  </div>
                                </div>}
                                <PasswordInputHS focusOutHandler={focusOutHandler} value={password} changeHandler={handlePassword} placeholder={"Please Enter Password"} labelStyles={` w-100`} inputStyles={`${passwordError.isError ? 'inputAlertVal' : ''} ${!passwordError.isError && passwordError.touched ? 'validFormInput' : ''} `}
                                  error={passwordError.isError} />

                                {passwordError.isError && (
                                  <FormInputError message={passwordError.errorMessage} />
                                )}
                                <SpacerHS_TWO />
                               {/* {
                               showCap && <React.Fragment>
                                <ReCAPTCHA
             
             className=" justify-content-around d-flex py-1"
 sitekey="6Lcqm_YiAAAAADGCwb3UXyuYoA2vd7CzMjbixumA"
 onChange={onChangeRecaptcha}
 />
 { recaptchaValidError.isError && <FormInputError message="Please verify the captcha" />}
                                </React.Fragment>
                               } */}
                                {/* <div className="font-12">
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <div>
                                        <span className={`passValidDot`}>
                                          <PasswordDot fillColor={isLowerCaseState ? 'grey' : "#068d8a"} />
                                        </span>  <span className={`${isLowerCaseState ? 'hs_text-light' : 'line-through hs_text-light'}`}>One lowercase character</span>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div>
                                        <span className="passValidDot">
                                          <PasswordDot fillColor={isSpecailState ? 'grey' : "#068d8a"} />
                                        </span>
                                        <span className={`${isSpecailState ? 'hs_text-light' : 'line-through hs_text-light'}`}> One special character</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <span className="passValidDot">
                                        <PasswordDot fillColor={isUpperCaseState ? 'grey' : "#068d8a"} />
                                      </span>
                                      <span className={`${isUpperCaseState ? 'hs_text-light' : 'line-through hs_text-light'}`}> One uppercase character</span>
                                    </div>
                                    <div className="col-sm-6">
                                      <span className="passValidDot">
                                        <PasswordDot fillColor={isLengthMinState ? 'grey' : "#068d8a"} />
                                      </span>
                                      <span className={`${isLengthMinState ? 'hs_text-light' : 'line-through hs_text-light'}`}> 8 characters minimum</span>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <span className="passValidDot">
                                        <PasswordDot fillColor={isNumberState ? 'grey' : "#068d8a"} />
                                      </span>
                                      <span className={`${isNumberState ? 'hs_text-light' : 'line-through hs_text-light'}`}> 1 number</span>
                                    </div>

                                  </div>
                                </div> */}
                              </div>


                              {/* <div className="form-row d-flex justify-content-between mt-4 d-none">
                                <div className="form-group mb-0">
                                  <div className="custom-control m-0 pl-0 d-flex justify-content-center align-items-center d-gap-10 custom-checkbox ml-1 ">
                                    <input
                                      type="checkbox"
                                      checked={termsChecked}
                                      onChange={(e) => setTermsChecked(!termsChecked)}
                                    /> */}
                              {/* <label
                                    className="form-check-label text-dark"
                                    htmlFor="basic_checkbox_1"
                                  >
                                    Terms and Conditions
                                  </label> */}
                              {/* <div>
                                      <a className="text-primary" target="_blank" href="https://hireserver.com/index.html" >Terms and Conditions</a>
                                    </div>
                                  </div>
                                </div>
                              </div> */}

                              {/* {
                                termsError.isError && <FormInputError message="Required to Agree" />
                              } */}
                              <SpacerHS_TWO />
                              <div className="text-center mt-2">
                                <button
                                  type="submit"
                                  className="btn btn-block hs-signin-btn hs_borderRadius-4"
                                >
                                  {isLoading ? <React.Fragment>
                                    <div className="spinner-border text-light" role="status"></div> Sign Up
                                  </React.Fragment> : "Sign Up"}
                                </button>
                              </div>
                            </form>
                            {/* <div className="new-account mt-2">
                              <p className="text-dark">
                                Have an account?{" "}
                                <Link className="text-dark" to="/login">
                                  Login
                                </Link>
                              </p>
                            </div> */}
                               <SpacerHS_TWO />
                            <div className="font-12">
                              By clicking the "Sign Up" button, you are creating an account, and agree to Hireserver'  <a className="text-primary" target="_blank" href="https://hireserver.com/index.html" >Terms and Conditions</a>
                            </div>
              
              </div>
        

            }



            {/* otp */}
            <OTPComponent userId={userIdState} setOTP_Active={setOTP_Active} otpId={otpId} setOtpId={setOtpId} backFalse={false} otpError={otpError} otp_loading={otp_loading} setOtpLoading={setOtpLoading} handleSubmit={handleSubmit} CompState={libOtp} onOTPChange={otpLibChange} email={email} otpActive={otpActive} />
        

            </React.Fragment>
      }


      {/* <OTP_Modal OTP_Modal={OTP_ModalState} setOTP_Modal={setOTP_ModalState} email={email.val} /> */}

      <ToastContainer className="toast-container"
        toastClassName="dark-toast"
        autoClose={2000}
        closeButton={false}
      />
    </React.Fragment>
    </LoginSkeletonScreen>
  );
}


const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(withRouter(Registration));
