import React from 'react'

function useConfirmCronhook() {

    const [confirmCronModel,setConfirmCronModel] = React.useState(false);
    const [confirmCronData,setConfirmCronData] = React.useState({});

    function setConfirmCronModelHandlerOpen(fullItemDate,enableCronJob){
        setConfirmCronData({fullItemDate,enableCronJob});
        setConfirmCronModel(true);
    } 

    function setConfirmCronModelHandlerClose(){
        setConfirmCronData({});
        setConfirmCronModel(false);
    }

    function justCloseConfirmCron(){
        setConfirmCronModel(false);
    }


  return {confirmCronModel,setConfirmCronModelHandlerOpen,setConfirmCronModelHandlerClose,confirmCronData,justCloseConfirmCron};
}

export default useConfirmCronhook;