import React from 'react';
import Select from 'react-select';
import { components } from "react-select";
import CreatableSelect from 'react-select/creatable';



function Control(props){
  return <div>
    
    <components.Control {...props} />
  </div>
}

function DropDownSearch({placeholder,data,setValue,isSearch,isDisable=false,value,parentClass,selectClasses}) {
    function handleChange(e){
       if(setValue){
        setValue(e);
       }
    }

    if(isSearch){
      return (
        <div className={parentClass}>
         
         <CreatableSelect
              className={`basic-single form-control-color ${selectClasses}`}
              classNamePrefix="select"
              value={value}
              placeholder={<span> {placeholder} <sup><i className='fa fa-asterisk text-danger font-5'></i></sup></span>}
              isDisabled={isDisable}
              // components={{ Control }} 
                onChange={handleChange}
              isSearchable={true}
              name="color"
              options={data}
            />
        </div>
      )
    }
  return (
    <div className={parentClass}>
     
         <Select
          className={`basic-single form-control-color ${selectClasses}`}
          classNamePrefix="select"
          value={value}
          placeholder={<span> {placeholder} <sup><i className='fa fa-asterisk text-danger font-5'></i></sup></span>}
          isDisabled={isDisable}
          // components={{ Control }} 
            onChange={handleChange}
          isSearchable={true}
          name="color"
          options={data}
        />
    </div>
  )
}


export default DropDownSearch