import React from 'react'
import { Form } from 'react-bootstrap';
import HeadingHS from '../../../hireserver/controls/HeadingHS';
import NoDataCard from '../../../hireserver/SharedComponents/NoDataCard';
import ServerSideCard from './ServerSideCard';

export default function ServerSideSection({selectServer,setSelectServer,setTenure,bareMetalServers}) {
  function getServers(){

    if(bareMetalServers.length === 0){
      return <NoDataCard title="No Services Available" />
    }
    console.log(bareMetalServers,'bareMetalServersbareMetalServers')
    return bareMetalServers.map((item,i)=>{
      let isCheck = false
    
      if(item.value == selectServer.value){
        isCheck = true;
       //setTenure(item.price.id);
      }
     
      
     
      
      return <ServerSideCard item={item} isCheck={isCheck} selectServer={selectServer} setSelectServer={setSelectServer}  />
    });
  }
  return (
    <Form.Group>

          <Form.Text className='d-flex my-1'> <HeadingHS as='h3' title="Server Size" /> </Form.Text>
          <div className='d-flex d-gap-15 flex-wrap justify-content-center justify-content-lg-start'>
            {/* <ServerSideCard selectServer={selectServer} setSelectServer={setSelectServer} name="Intel E-2286G" price="175" />
            <ServerSideCard   selectServer={selectServer} setSelectServer={setSelectServer}  name="Intel E3-1270v6" price="185" />
            <ServerSideCard  selectServer={selectServer} setSelectServer={setSelectServer} name="Intel E-2288G" price="350" /> */}
            {getServers()}
          </div>
        </Form.Group>
  )
}
