import React from 'react'
import { Form } from 'react-bootstrap';
// import HeadingHS from '../hireserver/controls/HeadingHS';

import Dedicatedcpucard from './Dedicatedcpucard'

export default function Dedicatedcpuselection({selectServer,setSelectServer}) {
    const data = [{
        name:"Dedicated 4 GB",
        values:{
            'Ram':"4 GB",
            'Cpu':"2",
            'Storage':"80 GB",
            'Transfer':"4 TB",
            'Network In / Out':"40 Gbps / 4 Gbps",
            
        },
        prices:{
            'monthly':"$30",
            'hourly':"$0.045"
        }
    },
    {
        name:"Dedicated 8 GB",
        values:{
            'Ram':"8 GB",
            'Cpu':"4",
            'Storage':"160 GB",
            'Transfer':"5 TB",
            'Network In / Out':"40 Gbps / 5 Gbps",
            
        },
        prices:{
            'monthly':"$60",
            'hourly':"$0.09"
        }
    },
    {
        name:"Dedicated 16 GB",
        values:{
            'Ram':"16 GB",
            'Cpu':"8",
            'Storage':"320 GB",
            'Transfer':"6 TB",
            'Network In / Out':"40 Gbps / 6 Gbps",
            
        },
        prices:{
            'monthly':"$120",
            'hourly':"$0.018"
        }
    },
    {
        name:"Dedicated 32 GB",
        values:{
            'Ram':"32 GB",
            'Cpu':"16",
            'Storage':"640 GB",
            'Transfer':"7 TB",
            'Network In / Out':"40 Gbps / 7 Gbps",
            
        },
        prices:{
            'monthly':"$240",
            'hourly':"$0.36"
        }
    },{
        name:"Dedicated 64 GB",
        values:{
            'Ram':"64 GB",
            'Cpu':"32",
            'Storage':"1280 GB",
            'Transfer':"8 TB",
            'Network In / Out':"40 Gbps / 8 Gbps",
            
        },
        prices:{
            'monthly':"$480",
            'hourly':"$0.72"
        }
    }
]

function getDetailsCards(){
   return data.map(function(item,index){
    return <Dedicatedcpucard selectServer={selectServer} data={item} setSelectServer={setSelectServer}/> 
   });
}
    return (
      <Form.Group>
  
            <Form.Text className='d-flex my-1'> 
           {/* <HeadingHS as="h3" title="dedicatedCpus"/> */}
            </Form.Text>
            <div className='flex-wrap row'>
                {getDetailsCards()}
            </div>
          </Form.Group>
    )
  }
