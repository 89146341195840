import React from 'react'
import { KeyValueScript } from '../AreaScriptOutput/AreaScriptOutput';
import OutputView from './../AreaScriptOutput/OutputView';
import CustomTabs from '../../../../../hireserver/CustomTabs';
import LoaderHS from '../../../../../hireserver/components/LoaderHS';
import { getScriptOutputByIdService } from '../../../../../../../services/InstancesServices/InstancesListService';
import { handlerAlert } from '../../../../Instances/InstancesListComponents/DropDownInstancesList';

function SaveAndExecOutput({ scriptid,output, idscriptoutput,showoutputInExecModal, timerState,getScriptList,getOutputData,setShowOutputInExecModal,completeOutput,setCompleteOutput }) {
    const [outputFilter, setOutPutFilter] = React.useState(null);
    const [detailCom, setDetailsCom] = React.useState(null);
    
    const [firstRender,setFirstRender] = React.useState(false);

    const timerRef = React.useRef();
    const [loading, setLoading] = React.useState(false);

    const [tabData, setTabData] = React.useState([
        {
            name: 'Output',
            component: <OutputView setOutput={setOutPutFilter} output={outputFilter} />
        },
        {
            name: 'Tokens',
            component: <div>he;p</div>
        }
    ]);

    React.useEffect(()=>{
        return () => {
            setOutPutFilter(null);
            setDetailsCom(false)
        }
    },[]);

    

    function getTokenHTML(params = []) {
        return params.map(function ({ key, value }, i) {
            return <KeyValueScript parent={key} value={value} />
        })
    }

    function getDetailsHTML({ params, sshpassword, sshport, sshuser, sship }) {
   
        return <div>
            <ul>

                <KeyValueScript parent={'IP'} value={sship} />
                {/* <li>
            <div className="col-sm-4">User</div>
            <div className="col-sm-8">{{sshuser}}</div>
          </li> */}
                <KeyValueScript parent={'User'} value={sshuser} />
                {/* <li>
            <div className="col-sm-4">Port</div>
            <div className="col-sm-8">{{sshport}}</div>
          </li> */}
                <KeyValueScript parent={'Port'} value={sshport} />
            </ul>
            <div className='border-top'>Tokens</div>
            <ul>
                {getTokenHTML(params)}
            </ul>
        </div>
    }


    React.useEffect(function () {
      debugger;
        if (!output) {
           setOutPutFilter('');
            return;
        };

        // setLoading(true);
        const { scriptstatus, description } = output;
        const desRes = JSON.parse(description);
        const { params, sshpassword, sshport, sshuser, sship } = desRes;
        const detailHTML = getDetailsHTML({ params, sshpassword, sshport, sshuser, sship });
        setDetailsCom(detailHTML);
        if (scriptstatus == 3) {
            setOutPutFilter([<div className='font-12' style={{ whiteSpace: "pre-wrap" }}>{output.output}</div>]);
            return;
        }
        
        let str = [];
        if(!output.output) return;
        const res = output.output
        
      //  console.log(res, 'ressssssssssssssssssssssssssssss');
        // for (let i = 0; i <( res.length<5000 ? res.length : 5000); i++) {
        //   str.push(<div className='font-12' style={{ whiteSpace: "pre-wrap" }}>{res[i]}</div>);
        // }
        let codeOutput =  res
      

        // setLoading(false);
        setOutPutFilter(codeOutput);
        // setOutPutFilter(<div style={{ whiteSpace: "pre-wrap" }}>{completeOutput+codeOutput}</div>);
        // setCompleteOutput(prev => prev +codeOutput)

    }, [output]);

    React.useEffect(function () {
        setTabData([
            {
                name: 'Output',
                component: <OutputView setOutput={setOutPutFilter}  output={outputFilter} />
            },
            {
                name: 'Tokens',
                component: <div>
                    {detailCom}
                </div>
            }
        ])
    }, [outputFilter, detailCom]);


    // React.useEffect(function () {

    //     console.log(scriptid,idscriptoutput,timerState);
    //     if (!scriptid) return;
    //     if (!idscriptoutput) return;
    //     //if (!timerState) return;
    
    //     console.log(scriptid,idscriptoutput,timerState,"-----------------");
        

    //     //getOutputById(idscriptoutput);

    //     if(timerRef.current){
    //         clearInterval(timerRef.current);

    //     }
    //     timerRef.current = setInterval(function (){
    //         console.log("loggggingggggggggg");
    //         if(!timerState){
    //             clearInterval(timerRef.current);
    //             getOutputById(idscriptoutput,-1);
    //             return;
    //         }
    //         getOutputById(idscriptoutput);
    //     },3000);

    //     return () => {
    //         console.log('ckear');
    //         if(!timerRef) return;
    //         clearInterval(timerRef.current);
    //     } 
       
    // }, [scriptid, idscriptoutput,timerState]);

    // React.useEffect(function (){
    //     return () => {
    //         console.log('ckear');
    //         if(!timerRef) return;
    //         clearInterval(timerRef.current);
    //     } 
    // },[]);


//     async function getOutputById(id,isBool=null) {
//         let res = await getScriptOutputByIdService(id);
//         console.log(res.data);
//         res = JSON.parse(res.data.result)[0];
// debugger;
//         if(res!=''){
//             if(!firstRender){
//                 setFirstRender(true);
//                 setShowOutputInExecModal(true);

//             }
           
//         }

//         setOutput(res);
        

//         if(isBool==-1){
//             // alert("hello")
//             handlerAlert("success", "Script has been Execute");
//             getScriptList();
//             getOutputData();
//         }

//     }

    return (
        <div>
            {loading ? <LoaderHS /> : <div className='w-100'>
                <CustomTabs tabData={tabData} />

            </div>}
          
        </div>
    )
}

export default SaveAndExecOutput