import React from 'react';
export const ToggleSideContextState = React.createContext();


function ToggleSideContext({children}) {
    const [toggle, setToggle] = React.useState(false);

  return (
    <ToggleSideContextState.Provider value={{
        toggle:toggle,
        setToggle:setToggle
    }}>
        {children}
    </ToggleSideContextState.Provider>
   
  )
}

export default ToggleSideContext;
