import React from 'react'
import { Dropdown } from 'react-bootstrap';

import ScriptMarketContext from '../ScriptMarketContext/ScriptMarketContext';

import './ButtonOptionsMarket.css';

function ButtonOptionsMarket() {
    const { handlerOptionDelete,draftHandler,optionSave,saveAndExec,optionsLoading } = React.useContext(ScriptMarketContext);
    console.log(optionsLoading,'optionsLoadingoptionsLoadingoptionsLoading');
    return (
        <React.Fragment>
            <div className='buttonOptions d-flex p-1'>
                <button disabled={optionsLoading} onClick={optionSave} className='w-100 cursor-pointer BOItem d-flex justify-content-center align-items-center border-0 bg-transparent text-white'>
                {optionsLoading ? <React.Fragment>
                      <div className="spinner-border text-light" role="status">

                      </div> 
                    </React.Fragment> : 'Save'}
                
                </button>
                {/* <div onClick={saveAndExec} className='textbuttonOptions cursor-pointer BOItem d-flex justify-content-center align-items-center'>
              
                Save
                </div> */}

                {false && <div className='flex-grow-1  BOItem d-flex justify-content-center align-items-center'>
                    {/* <span className='dropdownbuttonoption'>
                        <i className="fa fa-angle-down"></i>
                    </span> */}
                    <Dropdown>

                    <Dropdown.Toggle className='hs_borderRadius-4 toggleButtonOps border-0 p-0' id="dropdown-basic">
                    <span className='dropdownbuttonoption'>
                        <i className="fa fa-angle-down"></i>
                    </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {/* {options.length >=1 ? options?.map(function (el,i){
                            return <Dropdown.Item onClick={el.handler} key={i} className='font-09 py-1 '>{el.name}</Dropdown.Item>
                        }):null} */}
                        <Dropdown.Item onClick={optionSave}  className='font-09 py-1 '>
                        Save
                        </Dropdown.Item>

                        <Dropdown.Item onClick={handlerOptionDelete} className='font-09 py-1 '>
                        Delete
                        </Dropdown.Item>                      
                    
                    </Dropdown.Menu>
                    </Dropdown>
                </div>}

                
            </div>
       
        </React.Fragment>
    )
}

export default ButtonOptionsMarket