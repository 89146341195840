import React from 'react'
import Select from 'react-select';
const msgStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  
    return {
   
      padding:10,
      ':hover': {
        ...styles[':active'],
        backgroundColor:'#33704E',
        color:'white'
      },
    };
  },
};

function TypeFirePro({ options, selectedOption, handleChange }) {
  return (
    <div className="">
      <Select
        options={options}
        value={selectedOption}
        onChange={handleChange}
        className="selectClassnameReactive"
        styles={msgStyles}
      />
    </div>
  )
}

export default TypeFirePro