import React from 'react'
import { Link, Route, Switch,useRouteMatch } from 'react-router-dom';

export default function RouteCustomTabs({ tabHeaders, parentPath, tabsContent, activeTab }) {
  const [currTab, setCurrTab] = React.useState('');

  React.useEffect(function () {
    let url = `${window.location.href}`.split('/')[0];
    setCurrTab(url);
  }, [activeTab]);

  function handleClickChangeTab(e) {
    let tagName = e.target.getAttribute('data-tabname')
    if (!tagName) return;
    tagName = tagName.toLowerCase();
    setCurrTab(tagName);
  }

  function getTabHeader() {
   
    let url = `${window.location.href}`.split('/');
    url = url[url.length-1]
  
    return tabHeaders.map(function ({ name, link ,id}, i) {
     
      let routerCustomTabsHeader = (url === (link.toLowerCase().trim().replace(' ','')))? 'routerCustomTabsHeader' : '';
      // let routerCustomTabsHeader = (window.location.href.includes(link.toLowerCase().trim().replace(' ','')))? 'routerCustomTabsHeader' : '';
     // // console.log(parentPath,'dasdashdahosidho')
      return   <Link to={`${parentPath}/${link}`} key={id} data-tabname={name} onClick={handleClickChangeTab}  className={`routerLinkTag cursor-pointer px-3 hs_maxContent routerCustomTabsHeaderMain ${routerCustomTabsHeader}  font-16`}>
      <div className='pb-2 pointerEventNone'>
        {name}
      </div>
    </Link>

      
    });
  }

  function getTabContent() {
    
    return tabsContent.map(function ({ id, component, name }, i) {
      return <Route key={id}  path={`${parentPath}/${name}`}>
        {component}
      </Route>
    });
  }

  return (
    <div>
      <div className='position-relative'>
        <ul className='d-flex border-bottom overflow-auto RouteCustomTabClass'>
          {getTabHeader()}
        </ul>
        <div className='mt-3'>
          <Switch>
            {getTabContent()}
           
          </Switch>
        </div>
      </div>
    </div>
  )
}
