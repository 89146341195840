import React from 'react'
import CustomInputLabelSubText from '../../../../../hireserver/controls/CustomInputLabelSubText';
import NodeBalanceContext from '../../../NodeBalanceContext/NodeBalanceContext';
import Select, { components } from 'react-select';

export default function PortConfiguration() {
    const { selectTagOption, setSelectTagOption } = React.useContext(NodeBalanceContext);
    function setSelectedOption(val){
        // console.log(val)
    }
  return (
    <div className='row'>
    <div className="col-12 col-lg-3">
        <CustomInputLabelSubText isReq={'Required'} title="Port" subText="Listen on this port">
        <input style={{borderRadius:'0',height:'40px'}} type="number" className='form-control' />
        </CustomInputLabelSubText>
    </div>
    <div className="col-12 col-lg-3">
    <CustomInputLabelSubText  title="Protocol">
            <Select
            defaultValue={selectTagOption[0]}
            onChange={setSelectedOption}
            options={selectTagOption}
            style={{
            lineHeight: '40px',
            color: '#7e7e7e',
            paddingLeft: ' 15px',
            }}
        />
        </CustomInputLabelSubText>
    </div>
    <div className="col-12 col-lg-3">
    <CustomInputLabelSubText  title="Algorithm" subText="Roundrobin. Least connections assigns connections to the backend with the least connections. Source uses the client's IPv4 address.">
        <Select
            defaultValue={selectTagOption[0]}
            onChange={setSelectedOption}
            options={selectTagOption}
            style={{
            lineHeight: '40px',
            color: '#7e7e7e',
            paddingLeft: ' 15px',
            }}
        />
        </CustomInputLabelSubText>
    </div>
    <div className="col-12 col-lg-3">
    <CustomInputLabelSubText  title="Session Stickiness" subText="Route subequent Requests from the client to the same backend">
        <Select
            defaultValue={selectTagOption[0]}
            onChange={setSelectedOption}
            options={selectTagOption}
            style={{
            lineHeight: '40px',
            color: '#7e7e7e',
            paddingLeft: ' 15px',
            }}
        />
        </CustomInputLabelSubText>
    </div>
</div>
  )
}
