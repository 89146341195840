import {
    AUTH_LOADING,
    LOADING_TOGGLE_ACTION,
    LOGIN_CONFIRMED_ACTION,
    LOGIN_FAILED_ACTION,
    LOGOUT_ACTION,
    PASSWORD_CHANGED_SUCCESSFUL,
    PASSWORD_REMOVE_CHANGED_SUCCESSFUL,
    SIGNUP_CONFIRMED_ACTION,
    SIGNUP_FAILED_ACTION,
} from '../actions/AuthActions';
import { SIGNOUT_FAILED } from '../types/AuthTypes/AuthTypes';

const initialState = {
    auth: {
        email: '',
        idToken: '',
        localId: '',
        expiresIn: '',
        refreshToken: '',
    },
    errorMessage: '',
    successMessage: '',
    showLoading: false,
    passwordChanged:false
};

export function AuthReducer(state = initialState, action) {
  
    if (action.type === SIGNUP_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: 'Signup Successfully Completed',
            showLoading: false,
        };
    }
    if (action.type === LOGIN_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: 'Login Successfully Completed',
            showLoading: false,
        };
    }

    if (action.type === LOGOUT_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: '',
            auth: {
                email: '',
                idToken: '',
                localId: '',
                expiresIn: '',
                refreshToken: '',
            },
        };
    }
    if(action.type === PASSWORD_CHANGED_SUCCESSFUL){
        return {
            ...state,
            passwordChanged:true
        }
    }

    if(action.type === PASSWORD_REMOVE_CHANGED_SUCCESSFUL){
        return {
            ...state,
            passwordChanged:false
        }
    }

    if(action.type === SIGNOUT_FAILED){
        return{
            ...state,
            errorMessage: action.payload,
        }
    }

    if (
        action.type === SIGNUP_FAILED_ACTION ||
        action.type === LOGIN_FAILED_ACTION
    ) {
        return {
            ...state,
            errorMessage: action.payload,
            successMessage: '',
            showLoading: false,
        };
    }

    if (action.type === LOADING_TOGGLE_ACTION) {
       
        return {
            ...state,
            showLoading: !state.showLoading,
        };
    }

    if(action.type === AUTH_LOADING){
        return {
            ...state,
            showLoading:action.payload
        }
    }
    return state;
}

    
