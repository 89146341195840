import { DUE_TABLE_DATA, DUE_TABLE_RENEWAL_CLOSE, DUE_TABLE_RENEWAL_OPEN } from "../../types/RenewalTypes/RenewalTypes"

export function openDueTableRenewalAction(){
    return {
        type:DUE_TABLE_RENEWAL_OPEN
    }
}
export function closeDueTableRenewalAction(){
    return {
        type:DUE_TABLE_RENEWAL_CLOSE
    }
}

export function putDueTableDataAction(payload){
    return {
        type:DUE_TABLE_DATA,
        payload
    }
}