import React from 'react'
import { useRouteMatch, withRouter } from 'react-router-dom';
import { getUserAllMachinesFirewallService } from '../../../../../../../services/FirewallServices/FirewallListService';
import { postscriptmarketService } from '../../../../../../../services/InstancesServices/InstanceCurrListNewDropdownService';
import { getDetailsScriptService, getViewOutputMachineORScriptService } from '../../../../../../../services/InstancesServices/InstancesListService';
import { checkResData, isJsonStringHelper } from '../../../../../../../utils/Helpers';
import useInputHook from '../../../../../../hooks/InputHook/useInputHook';
import BackButton from '../../../../../hireserver/BackButton';
import FormInputError from '../../../../../hireserver/components/FormInputError';
import SpacerHS_TWO from '../../../../../hireserver/Spaces/SpacerHS_TWO';
import { handlerAlert } from '../../../../Instances/InstancesListComponents/DropDownInstancesList';
import ScriptMachineAuto from '../autoselectmachine/ScriptMachineAuto';
import ViewoutputTable from '../viewoutputtable/ViewoutputTable';
import HsTable from '../../../../../hireserver/tables/HsTable';
import OutViewScriptTable from './OutViewScriptTable';
import TokensShow from './TokensShow';
import { CRON_ENABLE, CRON_TIME } from '../../../../../../../utils/Emuns';

function DetailScripts({ toShow, history, callback }) {
    let { path, url, params: { scriptid } } = useRouteMatch();

    const { value: tempNameVal, setValue: setTempNameVal, error: tempNameError, setError: setTempNameError, resetAll: resetTempName } = useInputHook();
    const { value: TempDesVal, setValue: setTempDesVal, error: TempDesError, setError: setTempDesError, resetAll: resetTempDes } = useInputHook();
    const { value: shhIpVal, setValue: setSshIpval, error: shhIpError, setError: setSshIpError, resetAll: resetSshIp } = useInputHook();

    const [scriptText, setScriptText] = React.useState('');
    const [tokens, setTokens] = React.useState([]);

    // to render options
    const [selectMachineTag, setSelectMachineTag] = React.useState([]);
    // to select value
    const [machinetagState, setMachineTagState] = React.useState(null);
    const [machineStateError, setMachineStateError] = React.useState({
        isError: false,
        message: ''
    });
    const [autoCompleteMachineError, setAutoCompleteMachineError] = React.useState({
        isError: false,
        message: ''
    });

    const [outputViewList, setOutputViewList] = React.useState([]);

    const [savingLoading, setSaveLoading] = React.useState(false);


    const [initData, setInitData] = React.useState(null);


    async function getScriptDetails() {

        const res = await getDetailsScriptService(scriptid);

        const isValid = checkResData(res);
        if (!isValid) {
            handlerAlert("danger", "Something went wrong", "danger");
        }

        let data = JSON.parse(res.data.result)[0];

        console.log(data, 'sdsd');

        const { name, description, machineid, machinename, refstatusid, publicip, script, tokens } = data;
        let newscript = script;

        let tokenarr = [];
        let tokarr = JSON.parse(tokens)?.params;
        debugger;
        for (let i = 0; i < tokarr.length; i++) {
            const { key, value } = tokarr[i];
            if (key == CRON_ENABLE || key == CRON_TIME) continue;
            newscript = newscript.replaceAll(`{{${key}}}`, value);
            tokenarr.push(tokarr[i]);
        }
        setTokens(tokenarr)
        setScriptText(newscript)
        setTempNameVal(name);
        setTempDesVal(description);
        setMachineTagState({
            label: machinename,
            value: machineid,
            ip: publicip,
            id: refstatusid
        })
        setSshIpval(publicip);

        setInitData(data);

    }


    React.useEffect(function () {
        getScriptDetails();
        getUserMachinesList();
        getOutTable();
    }, [scriptid]);

    React.useEffect(function () {
        if (machinetagState) {
            const { ip } = machinetagState;
            setSshIpval(ip);
        }
    }, [machinetagState]);

    async function getOutTable(pageIndex = 1) {
        const res = await getViewOutputMachineORScriptService(pageIndex, scriptid);
        debugger;

        const resParse = JSON.parse(res.data.result);
        if (resParse.length == 0) {
            return {
                done: true
            }
        }
        setOutputViewList((prev) => {
            return [...prev, ...resParse]
        });
        console.log(resParse);
    }

    async function getUserMachinesList() {

        const res = await getUserAllMachinesFirewallService();
        const isValid = checkResData(res);
        if (!isValid) {
            //alert("something went wrong");
            console.log(res);
            return;
        }

        const isSafe = isJsonStringHelper(res.data.result);
        if (!isSafe) {
            handlerAlert("danger", "Something Went Wrong", "danger");
            return;
        }

        const resJson = JSON.parse(res.data.result);
        console.log(resJson, 'resJsonresJsonresJsonresJson');
        let newArr = [];
        for (let i = 0; i < resJson.length; i++) {
            const { refstatusid, publicip } = resJson[i];

            let val = {
                value: resJson[i]?.machineid,
                label: resJson[i]?.machinename,
                id: refstatusid,
                ip: publicip
            }

            newArr.push(val);




        }

        setSelectMachineTag(newArr)



    }


    function handleTempName(e) {
        setTempNameVal(e.target.value)
        if (tempNameError.isError) {
            setTempNameError({
                isError: false,
                message: ""
            })
        }

    }

    function handleTempDes(e) {
        setTempDesVal(e.target.value)
        if (TempDesError.isError) {
            setTempDesError({
                isError: false,
                message: ''
            })
        }
    }

    function onAutoMachineTagChange(e) {
        console.log(e);
        setMachineTagState(e);
        if (autoCompleteMachineError.isError) {
            setAutoCompleteMachineError({
                isError: false,
                message: ''
            })
        }
    }

    async function saveHandler() {
        setSaveLoading(true);

        let isError = false;
        debugger;
        if (tempNameVal === '') {
            setTempNameError({
                isError: true,
                message: "Input is required"
            });
            isError = true;
        }
        if (TempDesVal === '') {
            setTempDesError({
                isError: true,
                message: "Input is required"
            });
            isError = true;
        }

        if (isError) {
            return;
        }

        // if(machinetagState.id != 4){
        //     setSaveLoading(false);
        //     return;
        // }
        console.log(initData, 'initData');
        let payload = {
            ...initData,
            machinename: machinetagState.label,
            machineid: machinetagState.value,
            name: tempNameVal,
            description: TempDesVal,
            publicip: machinetagState.ip,
            machineid_machinename: machinetagState.label,
        }

        console.log(payload);

        const res = await postscriptmarketService(payload);
        setSaveLoading(false);
        callback();
        history.goBack();
    }

    return (
        <div>
            <BackButton />
            <div className=''>
                <div className=''>
                    <div>Name</div>
                    <input autoComplete="new-password" value={tempNameVal} onChange={handleTempName} className='form-control hs_borderRadius-4' type="text" />
                    {tempNameError.isError && <FormInputError message={tempNameError.message} />}
                </div>
                {/* TempDesVal,setTempDesVal */}
                <SpacerHS_TWO />
                <div className=''>
                    <div>Description</div>
                    {/* <input  type="text" /> */}

                    <textarea autoComplete="new-password" value={TempDesVal} onChange={handleTempDes} className='form-control hs_borderRadius-4' />
                    {TempDesError.isError && <FormInputError message={TempDesError.message} />}
                </div>
                <SpacerHS_TWO />
                <div className=''>
                    <span>Machine List</span>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <ScriptMachineAuto values={machinetagState} changeHandler={onAutoMachineTagChange} options={selectMachineTag} />
                            {autoCompleteMachineError.isError && <FormInputError message={autoCompleteMachineError.message} />}
                        </div>
                        <div className='col-sm-6'>
                            <input value={shhIpVal} onChange={(e) => setSshIpval(e.target.value)} className="form-control hs_borderRadius-4 " type="text" disabled={true} />
                        </div>
                    </div>
                </div>
                <SpacerHS_TWO />
                <div className='text-right'>
                    <button disabled={savingLoading} onClick={saveHandler} className="btn w-120p  hs_borderRadius-4 hs-signin-btn py-1">
                        {savingLoading ? <React.Fragment>
                            <div className="spinner-border text-light" role="status">

                            </div>
                        </React.Fragment> : 'Save'}
                    </button>
                </div>
                <SpacerHS_TWO />
                {/* <div>
                    <ViewoutputTable outputList={outputViewList} />
                </div> */}

                {toShow ? <React.Fragment>
                    <hr />
                    <div className='row'>
                    <div className='col-sm-8'>
                    <div className='text-center text-primary'>Script</div>
                        <SpacerHS_TWO/>
                        <div class="border-radius-hs p-3  h-100 bg-light" type="text" style={{ "white-space": "pre-wrap", maxHeight: '250px', overflow: 'auto', fontSize: '10px' }}>{scriptText}</div>
                    </div>

                    <div className='col-sm-4'>
                        <div className='text-center text-primary'>Tokens</div>
                        <SpacerHS_TWO/>

                        <div className=' p-2' style={{ "white-space": "pre-wrap", maxHeight: '250px', overflowY: 'auto',overflowX:'hidden' }}>
                            <TokensShow tokens={tokens} />
                        </div>
                    </div>
                    </div>
                    
                </React.Fragment> : null}
                <div className='mt-5'>
                    <OutViewScriptTable getOutTable={getOutTable} outputList={outputViewList} />
                </div>

            </div>
        </div>
    )
}

export default withRouter(DetailScripts);