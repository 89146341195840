import React from 'react'
import { useDispatch } from 'react-redux';
import { getDueDateDataService } from '../../../../../services/Profile/ProfileOrdersServices';
import { checkResData } from '../../../../../utils/Helpers';
import LoaderHS from '../../../hireserver/components/LoaderHS';
import DueDataHome from '../../../Home/duedatetable/DueDataHome';
import { handlerAlert } from '../../Instances/InstancesListComponents/DropDownInstancesList';


function DueAmountTab() {

    const [dueTableLoading,setDueTableLoading] = React.useState(true);
    const [tableData,setTableData] = React.useState([]);
    const [loading,setLoading] = React.useState(false);

  const dispatch = useDispatch();
  React.useEffect(function () {
    getOrdersList();
 
  }, []);
  async function  getOrdersList(){
    debugger;
    setLoading(true);;
    try {
      const resData = await getDueDateDataService();
        
    
      const isValid = checkResData(resData, true);
      if (isValid === false) {
          handlerAlert("danger","Something went wrong","danger");
          setLoading(false);
          return;
      }
  
  
      const parseData = JSON.parse(resData.data.result);
      
      setDueTableLoading(false);
      setTableData(parseData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  // if(loading){
  //   return <LoaderHS/>
  // }
  if(!loading){
    return (
      <div>
          <DueDataHome data={tableData} />
      </div>
    )
  }else{
    return <LoaderHS/>
  }


}

export default DueAmountTab