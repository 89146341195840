import React from 'react'
import { Dropdown } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';

import styles from './createnewbtn/createNewbtn.module.css';





function CreateNewBtn({ history }) {
    const [toggleMenubtn, setToggleMenuBtn] = React.useState(false);
    const dropdownRef = React.useRef(null);

    function createHandler() {
        const path = window.location.pathname;
        if (path === '/instances') {
            history.push('/instances/create')
            return;
        }else if(path.includes('firewall')){
            history.push('/firewall/create')
            return;
        }

        // else if(path.includes('scripts')){
        //     history.push('/scripts/create')
        //     return;
        // }

        if (dropdownRef.current) {
            dropdownRef.current.querySelector('button').click();
            
          }
    }
    

    function instancesCreate(){
        history.push('/instances/create')
    }

    function firewallCreate(){
        history.push('/firewall/create')
    }

    function bareMetalCreate(){
        history.push('/baremetal/create');
    }

    function clustersCreate(){
        history.push('/clusters/create');
    }

    return (
        <React.Fragment>
            <div className='buttonOptions d-flex p-1'>
                <div onClick={createHandler} className='textbuttonOptions cursor-pointer BOItem d-flex justify-content-center align-items-center'>
                    Create
                </div>

                <div className='cursor-pointer'>
                    {/* <span className='dropdownbuttonoption'>
                        <i className="fa fa-angle-down"></i>
                    </span> */}
                    <Dropdown ref={dropdownRef} className='px-1'>

                        <Dropdown.Toggle className='hs_borderRadius-4 toggleButtonOps border-0 p-0' id="dropdown-basic">
                            <span className='dropdownbuttonoption'>
                                <i className="fa fa-angle-down"></i>
                            </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className={`${styles.createButtonList}`}  show={toggleMenubtn}>
                            {/* {options.length >=1 ? options?.map(function (el,i){
                            return <Dropdown.Item onClick={el.handler} key={i} className='font-09 py-1 '>{el.name}</Dropdown.Item>
                        }):null} */}
                            <Dropdown.Item onClick={instancesCreate} className='font-09 py-1 text-primary'>
                               
                                    Instances
                                
                            </Dropdown.Item>

                            <Dropdown.Item onClick={firewallCreate}  className='font-09 py-1 text-primary'>
                                Firewall
                            </Dropdown.Item>

                            <Dropdown.Item onClick={bareMetalCreate}  className='font-09 py-1 text-primary'>
                                Baremetal
                            </Dropdown.Item>

                            <Dropdown.Item onClick={clustersCreate}  className='font-09 py-1 text-primary'>
                                Clusters
                            </Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>
                </div>


            </div>

        </React.Fragment>
    )
}

export default withRouter(CreateNewBtn);