import { cloneDeep } from 'lodash';
import { MDBDataTable } from 'mdbreact';
import React from 'react'
import { connect } from 'react-redux';
import { getInvoicesProfileService, getOrderMethodService, profileOrderListService } from '../../../../../../services/Profile/ProfileOrdersServices';
import { paginationLabel, TABLE_ENTIRES } from '../../../../../../utils/Emuns';
import { checkResData } from '../../../../../../utils/Helpers';
import CurrencyFC from '../../../../hireserver/components/Currency/CurrencyFC';
import LoaderHS from '../../../../hireserver/components/LoaderHS';
import SpacerHS_TWO from '../../../../hireserver/Spaces/SpacerHS_TWO';
import DownloadInvoice from './Components/DownloadInvoice';
import ProfileOrderStatus from './Components/ProfileOrderStatus';
import ProfileViewInvoice from './Components/ProfileViewInvoice';

function OrderMethod({currencyname}) {
    const [loading,setLoading] = React.useState(true);

    const [col, setCols] = React.useState([
      {
        label: "Machine",
        field: "machinename",
        sort: "asc",  
       
        attributes: {className: " col-3"  },
      },
        {
          label: "Created",
          field: "createddate",
          sort: "asc",
         
          attributes: {className: "text-center col-2"  },
        },{
          label: "Order No",
          field: "orderid",
          sort: "asc",
          attributes: {className: "text-center col-3"  },
         
        },
        {
          label: "Net Total",
          field: "amount",
          sort: "asc",
          attributes: {className: "text-center col-3"  },
         
        },
        // {
        //     label: "Payment Type",
        //     field: "paymenttype",
        //     sort: "asc",
           
        //     attributes: {className: "text-center col-3"  },
        //   },
       
        {
          label: "Status",
          field: "orderstatus",
          sort: "asc",
         
          attributes: {className: "text-center col-2"  },
        },
        
       
      ]);
  
    const [formatData, setFormatData] = React.useState({
      columns: [],
      rows: []
    })
    
    React.useEffect(function (){
      getOrdersList();
    },[]);
  
  
  
    
  
    async function getOrdersList(){
      const resData = await getOrderMethodService();
      
      const isValid = checkResData(resData,true);
      if(isValid === false){
        setLoading(false);
        setFormatData({
          columns: cloneDeep(col),
          rows: []
        });
        return;
      }
     
  

      const parseData = JSON.parse(resData.data.result);
      
      
  
      let rowComps = [];
   
    
   for(let i=0;i<parseData.length;i++){
     let {paymentresponse} = parseData[i];
     
     let typePay = 'wallet';
     
     if(paymentresponse != null){
      typePay = JSON.parse(paymentresponse)['payment_group']
     }
    const {orderid,totalamount,Description,orderstatus,statusid,currencytype,createddate,reforderid} = parseData[i];
    
    let parseDataDes = null;
    if(Description){
      parseDataDes= JSON.parse(Description);
    }
    let rowData = {
      machinename:<div className=''>{parseDataDes?.machinename}</div>,
      createddate:<div className='text-center'>{ parseData[i]['Created Date']}</div>,
      orderid:<div className='text-center'>{parseData[i]['Order No']}</div>,
      amount:<div className='text-center'><CurrencyFC name={currencyname} val={parseData[i]['Net Total']} /></div>,
      // amount:<div className='text-center'>{`${getCurrencySymbol(currencytype,totalamount)}`}</div>,
      // paymenttype:<div className='text-center'>{typePay}</div>,
    
      orderstatus:<span class="bgl-success text-success rounded-sm  btn btn-sm w-120p justify-content-around  d-flex align-items-center">{'Success'}</span>,
      search_name:parseData[i]['Order No'],
      search_machine:parseDataDes?.machinename

    }

    rowComps.push(rowData);
  }
  
      setFormatData({
        columns: cloneDeep(col),
        rows: cloneDeep(rowComps)
      });
      setLoading(false);
  
    }
  
    function onSearchHandler(value){
      const newData =  cloneDeep(formatData);
      setFormatData(newData);
    }
  
    function onPageChangeHandler({ activePage, pagesAmount }) {
      const newData =  cloneDeep(formatData);
      setFormatData(newData);
      
      // const ls = document.querySelectorAll('.instanceListTable .instacesClass');
      // // console.log(ls, 'ldsa')
    }
  
  
    if(loading){
      return <LoaderHS/>
    }

  
    return (

        <React.Fragment>
            {/* <div className='font-16 text-primary'>
                Invoices
            </div> */}
            <SpacerHS_TWO/>
            <div style={{ position: 'relative' }}>
                <div className="table-responsive">
                <div className="display mb-4 dataTablesCard customer-list-table  instances-table-card">
                    <MDBDataTable paging={Boolean(formatData.rows.length>TABLE_ENTIRES)} paginationLabel={paginationLabel}  onSearch={onSearchHandler} disableRetreatAfterSorting={true} onPageChange={onPageChangeHandler} className='instanceListTable' entriesOptions={[1, 5, 20, 25]} entries={TABLE_ENTIRES} data={formatData} />


                </div>
            </div>
        </div>
        </React.Fragment>
    )
}


const mapStatesToProps = ({ auth, PaymentInfoReducer }) => {
    return {
      currencyname: PaymentInfoReducer?.paymentData?.currencyname,
  
    }
  };
  export default connect(mapStatesToProps)(OrderMethod);
  