import React from 'react';
import Select from 'react-select';
import { components } from "react-select";
import CreatableSelect from 'react-select/creatable';
import { imageReplaceHashHelper } from '../../../../../utils/Helpers';

const Option  = (props) => {
    return (
     <span onClick={(e)=>e.stopPropagation()}>
       <components.Option {...props}>
        <img style={{
            width:'30px'
        }} src={`${imageReplaceHashHelper(props?.img)}`} /> {props.label}
      </components.Option>
     </span>
    );
};

function Dropdownselectimg({placeholder,data,setValue,isSearch,isDisable=false,value,parentClass,selectClasses}) {
    function handleChange(e){
      debugger;
        if(setValue){
         setValue(e);
        }
     }
 
     if(isSearch){
      // console.log(data);
       return (
         <div className={parentClass}>
          
          <CreatableSelect
               className={`basic-single form-control-color ${selectClasses}`}
               classNamePrefix="select"
               value={value}
               placeholder={<span> {placeholder} <sup><i className='fa fa-asterisk text-danger font-5'></i></sup></span>}
               isDisabled={isDisable}
               components={{ Option  }} 
                 onChange={handleChange}
               isSearchable={true}
               name="color"
               options={data}
             />
         </div>
       )
     }
   return (
     <div className={parentClass}>
      
          <Select
           className={`basic-single form-control-color ${selectClasses}`}
           classNamePrefix="select"
           value={value}
           placeholder={<span> {placeholder} <sup><i className='fa fa-asterisk text-danger font-5'></i></sup></span>}
           isDisabled={isDisable}
           components={{ Option  }} 
             onChange={handleChange}
           isSearchable={true}
           name="color"
           options={data}
         />
     </div>
   )
}

export default Dropdownselectimg