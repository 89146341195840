import React from 'react';
import './RedirectPage.css';

function RedirectPage({children,showRedirect}) {
    if(showRedirect === false){
        return <React.Fragment></React.Fragment>
    }
  return (
    <div className='redirectPage'>
        {children}
    </div>
  )
}

export default RedirectPage;