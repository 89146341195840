import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom';

import LoaderHS from '../../../../hireserver/components/LoaderHS';
import ScriptCardItem from './ScriptCardItem';
import ScriptMarketContext from './ScriptMarketContext/ScriptMarketContext';
import _ from 'lodash';
import SaveAndExecModal from './SaveAndExecModal/SaveAndExecModal';
import NoDataCard from '../../../../hireserver/SharedComponents/NoDataCard';


function ScriptListMarket() {
  const { scriptsList, mainLoading } = React.useContext(ScriptMarketContext);
  const [dataList,setDataList] = React.useState([]);
  const [searchInput,setSearchInput] = React.useState('');



  React.useEffect(function(){

    if(scriptsList.length){
      const data = scriptsList.filter(function (val,i){
   
        if(val.machineid == '0'){
          return val;
        }
      })
      // console.log(data,'datadata');
      setDataList(data);
      console.log(scriptsList);
      
    }
  },[scriptsList]);

  const debouncedChangeHandler = React.useMemo(
    () => _.debounce((val) => filterData(val), 200),
    [scriptsList]
  );

  React.useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();

    };
  }, [debouncedChangeHandler]);

  

  function handleInputSearch(e){
    let val = e.target.value?.toLowerCase();
    setSearchInput(val);
    debouncedChangeHandler(val);
  }

  function filterData(val){
    debugger;
    if(val===''){
      let newArr = [];
      for(let i=0;i<scriptsList.length;i++){
        if(scriptsList[i].machineid =='0'){
          newArr.push(scriptsList[i])
        }
      }
      setDataList(newArr);
      return;
    }
    let data = [];
    for(let i=0;i<scriptsList.length;i++){
      let currItem = scriptsList[i];

      if(currItem.machineid == '0'){
        let name = currItem.name?.toLowerCase()
        if(name.includes(val?.toLowerCase())){
          data.push(currItem);
        }
      }
      
    }

    setDataList(data);
  }

  if (mainLoading) {
    return <LoaderHS />
  }


  return (
    <React.Fragment>

      <div className='w-100 text-right d-flex flex-column d-gap-20 align-items-end pb-4'>
     <span style={{
      position:'absolute',
      top:'-5px'
     }}>
     <Link  className='btn p-1 hsButton-bg hs_borderRadius-4 text-white' to={`create`}>
          Create Template
        </Link>
     </span>
        <div className='hs-grey w-100'>
        <input value={searchInput} onChange={handleInputSearch} placeholder='search' type="text" className="customInputSearch form-control hs_borderRadius-4 bg-transparent border-0 p-4 " />
        </div>
       
      </div>
      
      <ul className='row'>
        {dataList.length > 0 ? dataList.map(function (val, i) {
          return <ScriptCardItem  key={val.scriptid} val={val} />
        }) : <NoDataCard title="No Templates" />}
      </ul>

      
    
    </React.Fragment>
  )
}

export default ScriptListMarket