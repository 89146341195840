import React from 'react';
import { Dropdown, Modal, Button } from 'react-bootstrap';
export default function ModalHs({size, handleClose,footerNone, backdrop, showModalHS,children,okayHandler,btnTitle,disabled=false,isNeed=true,classNameMain,bodyClasses,okayHandlerBtnColor="danger" }) {
    return (
        <Modal centered className={`${classNameMain} position-fixed`} backdrop={backdrop}  size={size} onHide={handleClose} style={{borderRadius:'4px'}} show={showModalHS}>
       
            <Modal.Body className={`d-flex d-gap-5 hs_border_radius ${bodyClasses}`}>
            
                {children}
            </Modal.Body>
            {
                isNeed ? <Modal.Footer className={`${footerNone&&'p-0 d-none'}`} style={{ border: 'none' }}>


                {
                 isNeed && <React.Fragment>
                      <Button style={{ borderRadius: '4px' }} className="btn-sm bg-white text-dark" variant="secondary" onClick={handleClose}>
                     Close
                 </Button>
                 <Button disabled={disabled} style={{ borderRadius: '4px' }} className={`btn-sm w-130p ${okayHandlerBtnColor}`} variant={okayHandlerBtnColor}  onClick={okayHandler}>
                 {btnTitle}
             </Button>
                 </React.Fragment> 
                }
             </Modal.Footer> : null
            }
        </Modal>
    )
}
