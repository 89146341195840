import React from "react";
import BareMetal from "../BareMetal";
import BareMetalContext from "./BareMetalContext";
import axios from "axios";
import { createMachineInstanceFormURL, getLocationURL, getSEVERNAME } from "../../../../../API_ENDPOINTS/API_ENDPOINTS";
import { getBareMetalList } from '../../../../../store/actions/BareMetalActions/BareMetalAction';
import { MINUS_BTN, PLUS_BTN } from "../../../hireserver/BlocksCard";
import { withRouter } from "react-router-dom";
import { toast, cssTransition } from 'react-toastify';
import { ToastCustomAlert_hs } from "../../../hireserver/ToastCustomAlert_hs";
import https from 'https'
import { connect, useDispatch } from "react-redux";
import { createMachineService, getInstanceFormLocations, getInstancesOSList } from "../../../../../services/InstancesServices/InstancesFormService";
import { handlerAlert } from "../../Instances/InstancesListComponents/DropDownInstancesList";
import produce from "immer";
import debianImg from '../../../../../images/hireserver/imgs/debian.png';
import { getBareMetalLocationFilterService, getBareMetalServerService, getBareMetalServersTenureService } from "../../../../../services/BareMetalServices/BareMetalFormService";
import { checkResData, containsSpecialChars, containsSpecialCharsExceptDotAndDash, hasWhiteSpace } from "../../../../../utils/Helpers";
import { putDeloyReducerAction, showBareMetalModalAction, showDeloyModalAction } from "../../../../../store/reducers/DeployReducer";
import { getUserCookieAuth } from "../../../../../store/actions/AuthActions";
import StaticInstanceForm from "../../../../../utils/StaticsClasses.js/StaticsInstances";
import { openPaymentInfoFormAction } from "../../../../../store/actions/PaymentInfoActions/PaymentInfoAction";


function CreateBareMetalContext(props) {
  const dispatch = useDispatch();
  const [deployLoading,setDeployLoading] = React.useState(false);
  const [locations, setLocations] = React.useState([]);
  const [locationState, setLocationState] = React.useState("98842114511");
  const [isBareMetal, setBareMetal] = React.useState(false);
  const [blocksInput, setBlocksInput] = React.useState(1);
  const [serverName, setServerName] = React.useState("");
  const [rootPassword, setRootPassword] = React.useState("");
  const [SShkey, setSShKey] = React.useState("");
  const [rootPasswordAgain, setRootPasswordAgain] = React.useState("");
  const [timer, setTimer] = React.useState(null);
  const serverNameRef = React.createRef();
  const [staticLocation, setStaticLocation] = React.useState({
    image: '',
    name: ''
  });
  const [isCreatedResCompleted, setIsCreatedResCompleted] = React.useState(true);
  const rootPasswordErr = React.createRef();
  const [osList, setOslist] = React.useState([]);

  const [selectOs, setSelectOs] = React.useState({
    name: "SERV2019.ENU.JAN2021.ISO",
    type: "win10",
  });
  const [submitErrors, setSubmitErrors] = React.useState({
    serverNameErr: false,
    rootPasswordErr: false,
    confirmPasswordErr: false,
    passwordNotMatchErr: false,
    sshKeyErr: false,
    privateIpErr: false,
    passwordLength: 0,
  });
  const [selectServer, setSelectServer] = React.useState({
    name: null,
    price: 0,
    value: null,
  });
  const optionsArr = [
    {
      name: "32BitOs",
      options: [
        {
          dataName: "SERV2019.ENU.JAN2021.ISO",
          type: "win10",
          id: "windows",
        },
        {
          dataName: "ubuntu-20.04.3-live-server-amd64.iso",
          id: "ubuntu",
          type: "l26",
        },
      ],
    },
  ];

  const [locationDataLoading,setLocationDataLoading] = React.useState(true);

  const [bareMetalServers, setBareMetalServers] = React.useState([
    // {
    //   name: "Intel E3-1270v6",
    //   storage: "2 x 240 GB",
    //   cpu: {
    //     cores: 6,
    //     threads: 12,
    //   },
    //   Memory: 32,
    //   Bandwidth: 10,
    //   NVMe: "2 x 240 GB",
    //   value: 98842114511,
    //   price: "178",
    // },
    // {
    //   name: "Intel E-2286G",
    //   storage: "2 x 100 GB",
    //   cpu: {
    //     cores: 6,
    //     threads: 12,
    //   },
    //   Memory: 32,
    //   Bandwidth: 10,
    //   NVMe: "",
    //   value: 45582366822,
    //   price: 300,
    // },
  ]);

  const [bareMetalServerLocations, setBareMetalServerLocations] =
    React.useState({
      98842114511: [],
      45582366822: [
        {
          DisplayText: "USA",
          SelectText: "Gotham",
          Value: "123456",
        },
      ],
    });

  const [selectTagOption, setSelectTagOption] = React.useState([
    { value: "one", label: "One" },
    { value: "two", label: "Two" },
    { value: "dummy", label: "dummy" },
  ]);

  const [tenure, setTenure] = React.useState(0);
  const [summaryPrice,setSummaryPrice] = React.useState({
    price:null,
    tenure:''
  });


  React.useEffect(function (){
    StaticInstanceForm.isUnMounted = false;
    return () => {
      //setIsUnMounted(true);
      StaticInstanceForm.isUnMounted = true;
    }
  },[]);

  // function fliterLocationData(mainData,sideData){
  //   //const data = [];
  //   let one = false;
  //   for(let i=0;i<sideData.length;i++){
  //     let currItem = sideData[i].branchid;
  //    for(let j=0;j<mainData.length;j++){
  //     let mainCurrItem = mainData[j].branchid;

  //     if(currItem == mainCurrItem && one == false){
  //       setLocationState(mainData[j].branchid);
  //       setStaticLocation({
  //         name: mainData[j].branchname,
  //         image: sideData[j].icon
  //       });
  //       one = true;
  //     }
  //     if(currItem == mainCurrItem){
        
  //       mainData[j]['isNeedShow'] = true;
  //       mainData[j]['icon'] = sideData[j].icon;
  //       // data.push(mainData[j]);
  //     }
  //    }
  //   }

  //   return mainData;
  // }

  function fliterLocationData(mainData,sideData){
    //const data = [];
    let one = false;
    for(let i=0;i<sideData.length;i++){
      let currItem = sideData[i].branchid;
     for(let j=0;j<mainData.length;j++){
      let mainCurrItem = mainData[j].branchid;

      if(currItem == mainCurrItem && one == false){
        setLocationState(mainData[j].branchid);
        setStaticLocation({
          name: mainData[j].branchname,
          image: mainData[j].icon
        });
        one = true;
      }
      if(currItem == mainCurrItem){
        
        mainData[j]['isNeedShow'] = true;
        // data.push(mainData[j]);
      }
     }
    }

    return mainData;
  }


  async function getLocationData(itemId) {
    // let { userId, sessionId } = JSON.parse(localStorage.getItem("userDetails"));

    // let config = {
    //   method: "get",
    //   url,
    //   headers: { Authorization: `Basic ${sessionId}` },
    // };


    try {
      if(locationDataLoading === false){
        setLocationDataLoading(true);
       }
      let response = await getInstanceFormLocations();
      let filterRespone = await getBareMetalLocationFilterService(itemId);

      setLocationDataLoading(false);
      if(filterRespone?.data?.result == ''){
        setLocationState('');
        setLocations([]);
        return;
      }

      if (response?.data?.result && filterRespone?.data?.result) {
     
        let res = JSON.parse(filterRespone?.data?.result);
        let allLocations = JSON.parse(response?.data?.result);

        let fliterData = fliterLocationData(allLocations,res);
        // console.log(fliterData,'fliterDatafliterDatafliterDatafliterData')
        setLocations(fliterData);
        if (fliterData.length === 0) {

          setLocationState('');
          // setLocationState(res[0].BranchId);
          return;
        }




        // setLocations(allLocations);
        // setLocationState(allLocations[0].branchid);
        // // setStaticLocation({
        // //   name: fliterData[0].branchname,
        // //   image: fliterData[0].icon
        // // })
        // // setLocationState(res[0].Value);
        // setBareMetalServerLocations({
        //   ...bareMetalServerLocations,
        //   98842114511: allLocations,
        // });
      }
    } catch (error) {
      console.log(error);
      setLocationDataLoading(false);
    }
  }
  function handleOsClick(e, cardName, osfilename, idValue, OSImage) {
    // setSelectOs()

    let name = e.target.getAttribute("data-name");
    if (!name && cardName) {
      name = cardName;
    }
    let which = e.target.getAttribute("id");
    let type = "";
    if (name == "windows") {
      type = "win10";
    } else {
      type = "l26";
    }

    setSelectOs({
      name,
      type,
      osfilename: osfilename,
      id: idValue,
      image: OSImage
    });

    // // console.log(selectOs)
  }

  React.useEffect(function () {
    getOsMachines();
    getServersBareMetal();
  }, []);

  async function getServersBareMetal() {
    const res = await getBareMetalServerService();
    const isValid = checkResData(res);
    if (!isValid) {
      return;
    }


    const resData = JSON.parse(res.data.result);


    // console.log(resData,'priceRespriceRespriceRes');



    let prices = [];
    for (let i = 0; i < resData.length; i++) {
      const priceRes = await getBareMetalServersTenureService(resData[0].itemid);
      const isValidTenure = checkResData(priceRes);
      if (!isValidTenure) {
        return;
      }
      const priceResData = JSON.parse(priceRes.data.result);
      // console.log(priceResData);
      let jIndex = 0;
      for(let j=0;j<priceResData.length;j++){
        if(priceResData[j]?.tenuretypename === "Monthly"){
          jIndex = j;
          break;
        }
      }

      prices.push({
        price:priceResData[jIndex]?.price,
        id:priceResData[jIndex]?.tenuretypeid
      })
      
    }
    //   console.log(resData,'baremetalresDataresDataresDataresData')
    //  let dumm =  {
    //     name: "Intel E3-1270v6",
    //     storage: "2 x 240 GB",
    //     cpu: {
    //       cores: 6,
    //       threads: 12,
    //     },
    //     Memory: 32,
    //     Bandwidth: 10,
    //     NVMe: "2 x 240 GB",
    //     value: 98842114511,
    //     price: "178",
    //   }
    const FormatData = [];
    for (let i = 0; i < resData.length; i++) {
      const currItem = resData[i];
      const payload = {
        name: currItem?.labelname,
        id: currItem?.itemid,
        cpu: {
          cores: currItem?.numberofcores,
          threads: 12,
        },
        ram: currItem?.ram,
        bandwidth: 10,
        value: currItem?.itemid,
        NVMe: "2 x 240 GB",
        memory: currItem?.storage,
        price: prices[i]
      }
      FormatData.push(payload);
    }

    setSelectServer({
      name:FormatData[0]['name'],
      value:FormatData[0]['value'],
      price:FormatData[0]['price']
    });

    setBareMetalServers(FormatData);

    getLocationData(FormatData[0]['value']);



    // console.log(res,'serbersserbersserbersserbersserbersserbersserbersserbers')
  }

  async function getOsMachines() {
    setOslist({
      name: "",
      options: [],
      loading: true
    })
    let osListFetch = await getInstancesOSList();
    
    let result = null;
   
    if (osListFetch?.data.result == '') {
      handlerAlert("danger", osListFetch.data.message, "danger");
      return;
    }
    if (osListFetch?.data.result != null || osListFetch?.data.result != '') {
      result = JSON.parse(osListFetch?.data.result);
    }
    // console.log(result,'resultresultresultresultresultresult')
    let options = [];
    if (osListFetch?.data.errors != null && osListFetch?.data.errors?.length != 0 && osListFetch?.data.errors?.length != undefined) {
      handlerAlert("danger", osListFetch?.data.errors[0], "danger");
      return;
    }
   
  //  console.log(result,'resultresultresultresultos')
   let isBareMetal = 0;
    for (let i = 0; i < result?.length; i++) {
     
        if( result[i]['ostype'] === 'iso'){
          if (isBareMetal === 0) {
            setSelectOs({
    
              name: result[i]['operatingsystemname'],
              type: result[i]['ostype'],
              id: result[i]['operatingsystemid'],
              image: result[i]['osiconpath'],
              osfilename: result[i]['osfilename'],
              groupname: result[i]['groupname']
            })
            isBareMetal++;
          }
          let data = {
            dataName: result[i]['operatingsystemname'],
            type: result[i]['ostype'],
            id: result[i]['operatingsystemid'],
            image: result[i]['osiconpath'],
            osfilename: result[i]['osfilename'],
            groupname: result[i]['groupname']
          }
          if (result[i]['osiconpath'] == '') {
            data.image = debianImg;
          } else {
            data.image = getSEVERNAME() + data.image?.replace('~', '');
          }
    
          options.push(data);
        }
       
      
    }

    let customData = [{
      name: "ISO",
      options,
      loading: false
    }]

    setOslist(customData)
    // console.log(customData)

  }



  function handleClickAlert(e) {
    const fadeInAndOut = cssTransition({
      enter: "alertFadein",
      exit: "alertFadeout"
    })
    toast(<ToastCustomAlert_hs variant="success" text="Machine as been created." />, {
      autoClose: true,
      className: 'toasterCustom',
      bodyClassName: "toasterCustomBody",
      closeOnClick: true,
      transition: fadeInAndOut,
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true
    })

  }

  function checkValidForm() {
    if (serverName == "") {
      setSubmitErrors({
        ...submitErrors,
        serverNameErr: true,
      });
      serverNameRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
      return {
        isError: true,
        message: "serverNameErr",
      };
    }

    let isTrue = containsSpecialCharsExceptDotAndDash(serverName);
    if(isTrue || hasWhiteSpace(serverName)){
      serverNameRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
      setSubmitErrors({
        ...submitErrors,
        serverNameErr: true,
      });
      return {
        isError: true,
        message: "Name doesn't contains any special characters or spaces",
      };
    }

    // if (rootPassword == "") {
    //   setSubmitErrors({
    //     ...submitErrors,
    //     rootPasswordErr: true,
    //   });
    //   rootPasswordErr.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "center",
    //     inline: "nearest",
    //   });
    //   return {
    //     isError: true,
    //     message: "rootPasswordErr",
    //   };
    // }

    // if (rootPasswordAgain == "") {
    //   setSubmitErrors({
    //     ...submitErrors,
    //     confirmPasswordErr: true,
    //   });
    //   rootPasswordErr.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "center",
    //     inline: "nearest",
    //   });
    //   return {
    //     isError: true,
    //     message: "confirmPasswordErr",
    //   };
    // }

    // if (rootPassword.length < 6 - 1) {
    //   setSubmitErrors({
    //     ...submitErrors,
    //     passwordLength: true,
    //   });
    //   rootPasswordErr.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "center",
    //     inline: "nearest",
    //   });
    //   return {
    //     isError: true,
    //     message: "passwordLength",
    //   };
    // }

    // if (rootPassword != rootPasswordAgain) {
    //   // '';
    //   setSubmitErrors({
    //     ...submitErrors,
    //     passwordNotMatchErr: true,
    //   });
    //   rootPasswordErr.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "center",
    //     inline: "nearest",
    //   });
    //   return {
    //     isError: true,
    //     message: "passwordNotMatchErr",
    //   };
    // }

    return {
      isError: false,
    };
  }

  function handleClickErrorAlert(e, errorMsg) {
    // '';
    const fadeInAndOut = cssTransition({
      enter: "alertFadein",
      exit: "alertFadeout"
    })
    toast(<ToastCustomAlert_hs variant="danger" text={errorMsg} />, {
      autoClose: true,
      className: 'toasterCustomError',
      bodyClassName: "toasterCustomBodyAlert",
      closeOnClick: true,
      transition: fadeInAndOut,
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true
    })

  }


  async function handleFormClick(e) {
    e.preventDefault();
    if(deployLoading === true){
      return;
    }

    if(props.isShowPayment == 0){
      dispatch(openPaymentInfoFormAction());
      return; 
    }

    let userCookie = getUserCookieAuth();
    if (userCookie === null) {
      handleClickErrorAlert(null, "Session Error");
      return;
    }
    let { userId, sessionId } = userCookie;
    let pass = checkValidForm();

    if (pass.isError) {
      if (timer) {
        clearTimeout(timer);

        setTimer(null);
      }
      let time = setTimeout(function () {
        let { message } = pass;
        //handleClickErrorAlert(null,pass.message);
        let newSubmitErrors = { ...submitErrors };
        newSubmitErrors[message] = false;
        setSubmitErrors(newSubmitErrors);
      }, 3000);
      setTimer(time);
      return;
    }
    setDeployLoading(true);
    // // console.log(selectServer);
    // // console.log(locationState);
    // // console.log(blocksInput);
    //// console.log("windows", "static");
    // window.history.push('/baremetal');
    // setBareMetal(true);
    //props.history.push("/baremetal");\


    // let data = JSON.stringify({
    //   "serverid_assetname": "0",
    //   "seriesid_Name": "0",
    //   "userid_username": "0",
    //   "regionid_regionname": "0",
    //   "machinetype_DropDownName": "0",
    //   "ostype_ostypename": "0",
    //   "machineid": "0",
    //   "code": "0",
    //   "regionid": locationState,
    //   "machinetype": '1',
    //   "machinename": serverName,
    //   "cores": "1",
    //   "harddisk": "8000",
    //   "memory": "10",
    //   "userid": userId,
    //   "serverid": "100013670000046",
    //   "seriesid": "100010470000309",
    //   "description": "",
    //   // "osname":`server1:iso/${name},media=cdrom`,
    //   "osname": "local:iso/ubuntu-20.04.3-live-server-amd64.iso,media=cdrom",
    //   // "ostype":type,
    //   "ostype": 'l26'
    // });
    
    let data = JSON.stringify({
      "machinename": serverName,
      "machinetype": "2",
      "noofblocks": `0`,
      "branchid": `${locationState}`,
      "contactid": `${userId}`,
      // "currencyid": "100015210000002",
      "isbaremetal": "true",
      "itemid": `${selectServer.value}`,
      "operatingsystemid": `${selectOs.id}`,
      // "walletgroupid": "100015230000001",
      "tenuretypeid": `${tenure}`,
      // "username": `${props.username}`,
      "username":'root',
      "password": '0',
      "orderid": "0",
      "sshkey":`0`,
      "blockid":"100015240000001"
    })
    // const res = await createMachineService(data);
    // console.log(res,'datadatadata')
    dispatch(putDeloyReducerAction(data));
    dispatch(showBareMetalModalAction());
    return;
    const agent = new https.Agent({
      rejectUnauthorized: false
    });

    let config = {
      method: 'post',
      url: createMachineInstanceFormURL(),
      headers: {
        'Authorization': `Basic ${sessionId}`,
        'Content-Type': 'application/json'
      },
      data: data,
      httpsAgent: agent
    };

    axios(config)
      .then(function (response) {
        //// console.log(JSON.stringify(response.data));
        // setShow(true);
        // '';
        if (response.data.result == null) {
          handleClickErrorAlert(null, response.data.message);
          setIsCreatedResCompleted(true);
          return;
        }
        // console.log(response)
        handleClickAlert();

        dispatch(getBareMetalList())
        props.history.push('/baremetal')
      })
      .catch(function (error) {
        // console.log(error);
      });

  }

  // function onChangeLocation(e,z,a,b,c,d,isNeed) {
  //   if(isNeed === false) return;
  //   let num = e.target.getAttribute("data-location");
  //   setLocationState(num);

    
  // }

  function onChangeLocation(e,a,b,c,d,f,isNeed) {

    if(isNeed === false) {
      setStaticLocation({
        image:staticLocation.image,
        name:staticLocation.name
      });
      return;
    };
    let num = e.target.getAttribute("data-location");
    // console.log(e, 'eeeee')
    for (let i = 0; i < locations.length; i++) {
      if (num == locations[i].branchid) {
        setStaticLocation({
          image: locations[i].icon,
          name: locations[i].branchname
        });
        
      }
    }

    setLocationState(num);
  
  }

  function onChangeBlocksHandler(e) {
    let num = e.target.value;
    if (num < 0) return;
    setBlocksInput(num);
  }

  function blocksHandler(e) {
    let name = e.target.getAttribute("data-name");
    let num = Number(blocksInput);
    if (name == MINUS_BTN) {
      if (num == 0) return;

      setBlocksInput(num - 1);
    } else if (name == PLUS_BTN) {
      setBlocksInput(num + 1);
    }
  }

  function handleOsClick(e, cardName, osfilename, idValue, OSImage,groupname) {
    // setSelectOs()
    
    let name = e.target.getAttribute("data-name");
    if (!name && cardName) {
      name = cardName;
    }
    let which = e.target.getAttribute("id");
    let type = "";
    if (name == "windows") {
      type = "win10";
    } else {
      type = "l26";
    } 

    setSelectOs({
      name,
      type,
      osfilename: osfilename,
      id: idValue,
      image: OSImage,
      groupname
    });

    // // console.log(selectOs)
  }

  return (
    <BareMetalContext.Provider
      value={{
        isBareMetal,
        setBareMetal,
        optionsArr,
        blocksHandler,
        MINUS_BTN,
        PLUS_BTN,
        blocksInput,
        selectServer,
        setSelectServer,
        setBlocksInput,
        onChangeBlocksHandler,
        handleFormClick,
        onChangeLocation,
        locations,
        locationState,
        setLocationState,
        bareMetalServers,
        bareMetalServerLocations,
        selectOs,
        setSelectOs: handleOsClick,
        serverName,
        setServerName,
        selectTagOption,
        setSelectTagOption,
        rootPasswordAgain,
        setRootPasswordAgain,
        rootPassword,
        setRootPassword,
        SShkey,
        setSShKey,
        submitErrors,
        setSubmitErrors,
        serverNameRef,
        rootPasswordErr,
        osList,
        setOslist,
        tenure, setTenure,
        summaryPrice,setSummaryPrice,
        deployLoading,setDeployLoading,
        locationDataLoading
      }}
    >
      {props.children}
    </BareMetalContext.Provider>
  );
}
const mapsStateToProps = ({ authAlertReducer, PaymentInfoReducer }) => {
  return {
    authAlert: authAlertReducer,
    isShowPayment:PaymentInfoReducer?.paymentData?.defaultaddressid,
  }
}
export default connect(mapsStateToProps)(withRouter(CreateBareMetalContext));
