import React from 'react';
import './MachineInstanceCard.css';
import { Card } from 'react-bootstrap';
import ubunto from '../../../../../../images/os/os2.svg';
import germany from '../../../../../../images/hireserver/locations/germany.png';
import { Link } from 'react-router-dom';
import DropDownInstancesList from '../../InstancesListComponents/DropDownInstancesList';
import StatusShow from '../../InstancesListComponents/StatusShow';
import { imageReplaceHashHelper } from '../../../../../../utils/Helpers';
import ImageHs from '../../../../hireserver/ImageHs';

function MachineInstanceCard({ item }) {
    // console.log(item)
    function getStatusComponent(loadingInfo, refstatusid) {
        return <StatusShow isDetails={true} machineid={item?.machineid} statusid={refstatusid} loadingInfo={loadingInfo} isColor={loadingInfo.color} isText={loadingInfo.text} isSpinning={loadingInfo.loading} />
    }
    const { machinename, regionname, statusid, privateip, publicip, noofblocks, osiconpath, icon, groupname, operatingsystemname, refstatusid, machineid, loadingInfo } = item;

    return (
        <Card className="col-12 col-lg-4 p-4">
            <div className='row'>
                <div className='col-2 d-flex justify-content-center flex-column '>

                    <ImageHs height="35px" width="35px" alt={operatingsystemname} src={imageReplaceHashHelper(osiconpath)} />
                </div>


                <div className='px-0 d-flex col-10'>
                    <div className='w-100 d-flex justify-content-center flex-column col-8'>
                        <div className={`font-18 ${machineid ? '' : 'pointerEventNone'}`}>
                            <Link className='text-primary' to={`/instances/${machineid}/vmdetails/overview`}>{machineid ? <u>{machinename}</u> : machinename}</Link>
                        </div>
                      {publicip &&  <div>
                            {noofblocks} {noofblocks > 1 ? 'Blocks' : 'Block'} - {publicip}
                        </div>}
                    </div>

                    <div className='pr-2 pl-0 d-flex flex-column justify-content-center align-items-end col-4'>
                        {getStatusComponent(loadingInfo, refstatusid)}
                        <DropDownInstancesList disabledClass={machineid?'':'viewConsoleDisable'} itemData={item} machinename={item['machinename']} machinetype={item['machinetype']} statusid={item['statusid']} machineid={item['machineid']} code={item['code']} />
                    </div>
                </div>




            </div>
            {/* <div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className={`font-16 text-dark font-w600 ${machineid?'':'pointerEventNone'}`}>
                        <Link className='text-primary' to={`/instances/${machineid}/vmdetails/overview`}>{machineid?<u>{machinename}</u>:machinename}</Link>
                    </div>
                    {getStatusComponent(loadingInfo,refstatusid
                    )}

                </div>
                <div>
                  
                    <div>
                       {noofblocks} {noofblocks>1?'Blocks':'Block'} - {publicip}
                    </div>
                </div>
            </div> */}

            {/* <div className='d-flex justify-content-between pb-4'>
                <div className='d-flex-center d-gap-5 flex-column'>
                    <div className='h-30p w-30p'>
                        <img className='w-100 h-100' src={imageReplaceHashHelper(osiconpath)} />
                    </div>
                    <div>
                        {groupname} {operatingsystemname}
                    </div>
                </div>
                <div className='d-flex-center  d-gap-5 flex-column'>
                    <div className='h-30p w-30p'>
                        <img className='w-100 h-100' src={imageReplaceHashHelper(icon)} />
                    </div>
                    <div>
                        {regionname}
                    </div>
                </div>
                <div>
                
                   <DropDownInstancesList disabledClass={machineid?'':'viewConsoleDisable'} itemData={item} machinename={item['machinename']} machinetype={item['machinetype']} statusid={item['statusid']} machineid={item['machineid']} code={item['code']} />
                </div>
            </div> */}
        </Card>
    )
}

export default MachineInstanceCard;