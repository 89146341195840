import React from 'react'

function SupportSVG({color}) {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="32.000000pt" height="32.000000pt" viewBox="0 0 32.000000 32.000000"
    preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
      fill={`${color}`} stroke="none">
      <path d="M46 253 c-24 -24 -27 -33 -22 -68 4 -22 7 -49 7 -60 1 -18 8 -20 60
-20 75 1 104 24 104 86 0 81 -92 119 -149 62z m85 -29 c11 -14 10 -18 -6 -30
-24 -18 -46 -18 -22 0 23 18 22 29 -3 21 -21 -7 -28 10 -7 18 20 9 24 8 38 -9z
m-21 -64 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z"/>
      <path d="M210 185 c0 -38 -35 -85 -65 -85 -23 0 -18 -16 11 -39 36 -28 138
-29 129 -1 -3 10 -1 21 5 25 5 3 10 21 10 40 0 38 -31 79 -67 90 -21 6 -23 3
-23 -30z"/>
    </g>
  </svg>
  )
}

export default SupportSVG