import React from 'react';
import { MDBDataTable } from 'mdbreact';
import './HsTable.css';
export default function HsTable({columns,rows}) {
  return (
    <div>
           <MDBDataTable striped small data={{columns,rows}} />
    </div>
  )
}
