import React from 'react'
import { Form } from 'react-bootstrap';
import FormInputError from '../../../hireserver/components/FormInputError';
import LabelInput from '../../../hireserver/controls/LabelInput'
import TagsComponent from '../../../hireserver/SharedComponents/TagsComponent';
// import TagsComponent from '../../NodeBalance/NodeBalanceItems/Components/TagsComponent';

function ServerNameSelection({serverName,setServerName,selectTagOption, setSelectTagOption,isNameError},ref) {
   // const [serverName,setServerName] = React.useState('');
  return (
    <Form.Group ref={ref}>
        <div className='col-12 col-lg-4 pl-0'>
        <LabelInput headingFont="h3" inputValue={serverName} setInputValue={setServerName} placeholder="Enter Server Name" id="bareMetalLabel" heading="Server Name"  />
        {isNameError&& <FormInputError  message="Please enter the name without any spaces" />}
        {/* <LabelInput inputValue={serverName} setInputValue={setServerName} placeholder="Tags" id="bareMetalLabelTag" heading="Tags"  /> */}
       {/* <div className='pt-2'>
       <TagsComponent
            selectTagOption={selectTagOption}
            setSelectTagOption={setSelectTagOption}
          />
       </div> */}
        </div>
      

    </Form.Group>
  )
}

export default React.memo(React.forwardRef(ServerNameSelection));