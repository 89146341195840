import _ from 'lodash';
import React from 'react'
import { Dropdown } from 'react-bootstrap'
import DropDownMenuSelectSearch from './DropDownMenuSelectSearch';
import '../InputLabelUp/InputLabelUp.css';
import StaticPaymentDetails from '../../../../../utils/StaticsClasses.js/StaticPaymentDetails';

function DropDownSelectSearch({ name, isDisable,placeholder,isUpdate,loading, data,selectClasses,valueThere, valueSetter, searchValue,dependField="a",dependValue }) {

    const [displayData, setDisplayData] = React.useState([]);
    const [valueState, setValueState] = React.useState('');
    const [inputText, setInputText] = React.useState('');
    const [placeholderState,setPlaceHolderState] = React.useState('');

    const inputRef = React.createRef(null);

    React.useEffect(function(){
       if(!isUpdate){
        setPlaceHolderState(placeholder);
       }
    },[placeholder]);

    React.useEffect(function (){
        if(dependValue){
            
            
            if(StaticPaymentDetails.isEditing===false){
                setInputText('');
            }
        }
    },[dependField])

    React.useEffect(function (){
      
        if(!isUpdate) return;
        
        setPlaceHolderState(placeholder);
    },[isUpdate]);

    // React.useEffect(function (){
      
    //     // console.log(searchValue,'searchValuesearchValuesearchValuesearchValuesearchValuesearchValuesearchValuesearchValuesearchValuesearchValuesearchValuesearchValuesearchValuesearchValuesearchValuesearchValuesearchValuesearchValuesearchValuesearchValuesearchValuesearchValue')
    //     if(searchValue?.name != ''){
    //       inputRef.current.classList.add('inputLabelValueThere')
    //     }else{
    //       inputRef.current.classList.remove('inputLabelValueThere')
    //     }
    //     console.log(inputRef.current)
    // },[searchValue]);

    //   React.useEffect(function (){
      

    //     if(inputText != ''){
    //       inputRef.current.classList.add('inputLabelValueThere')
    //     }else{
    //       inputRef.current.classList.remove('inputLabelValueThere')
    //     }
    //     console.log(inputRef.current)
    //   },[inputText]);

    //   React.useEffect(function(){
    //     console.log(valueThere,'valueTherevalueTherevalueTherevalueThere')
    //     if(valueThere){
    //         setInputText(valueThere.label)
    //     }
    //   },[valueThere])
    
     

    React.useEffect(function () {
        setDisplayData(data);
      
    }, [data]);

    function changeHandler(e) {
        
        const value = e.target.value;
        setInputText(value);
        //setSearchValueFun(value,data)
        debouncedChangeHandler(value,data);
    }

    function setSearchValueFun(valueItem,closerData=[]) {
        if (valueItem === '' || !valueItem) {
            setDisplayData(closerData);
            valueSetter({
                name:'',
                value:null
            })
            return;
        }
        const filterData = [];
   
       
        for (let i = 0; i < closerData.length; i++) {
            const { label, value } = closerData[i];
            let toLower = label?.toLowerCase();
            let toAnsLower = valueItem?.toLowerCase();
            
            if (toLower.includes(toAnsLower)) {
                filterData.push(closerData[i]);
            }
        }
  
       
        setDisplayData(filterData);
       
    }

    const debouncedChangeHandler = React.useMemo(
        () => _.debounce((userInput,closeData) => setSearchValueFun(userInput,closeData), 200),
        []
    );

    React.useEffect(() => {
        return () => {
            debouncedChangeHandler.cancel();

        };
    }, [debouncedChangeHandler]);

    // function onSelectOption(e){
    //     const element = e.target;
    //     const value = element.dataset.label;
    //     const name =  element.dataset.name;
    //     valueSetter({value,name});
    //     setPlaceHolderState(name);
    //     setInputText('');

    // }

    const onSelectOption = React.useCallback((e) => {
        const element = e.target;
        const value = element.dataset.label;
        const name =  element.dataset.name;
        valueSetter({value,name});
        setPlaceHolderState(name);
        setInputText('');
      
      }, [data]);

    return (
        <Dropdown className={`${ isDisable ? 'pointerEventNone bg-light' : ''}`}>
            <Dropdown.Toggle className='border-0 removeDeafaultdropdown w-100'>
                <div className='inputLabelUpContainer w-100 arrowInputDropdownparent'>
                    <input   placeholder={placeholderState} disabled={isDisable} ref={inputRef} value={inputText} onChange={changeHandler} id={name} className={`${selectClasses}   font-14  form-control ${placeholderState!=placeholder?'borderFormSelect':''} hs_borderRadius-4   w-100 h-49`} type="text" />
                    {/* <label className='inputLabelUpInputLabel text-dark font-15' htmlFor={name}>
                        {name}
                    </label> */}
                    <div className='arrowInputDropdown'>
                    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-6q0nyr-Svg"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
                    </div>
                </div>
            </Dropdown.Toggle>
            <DropDownMenuSelectSearch loading={loading} onSelectOption={onSelectOption} displayData={displayData} />


        </Dropdown>
    )
}

export default React.memo(DropDownSelectSearch)