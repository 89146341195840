import React from 'react'
import HeadingHS from '../../../../../../hireserver/controls/HeadingHS'
import InputHs from '../../../../../../hireserver/controls/InputHs'
import SpacerHS_ONE from '../../../../../../hireserver/Spaces/SpacerHS_ONE'

function InputDesHs({placeholder,desText,parentClasses,title,value,setValue},ref) {
    return (
        <div ref={ref} className={`${parentClasses}`}>
            <div className='d-flex d-gap-10 align-items-center'>
                <HeadingHS as="h4 text-primary" title={title} />
                {/* <div className='text-info'>
                    Info
                </div> */}
            </div>
            <SpacerHS_ONE/>
            <div>
              <InputHs value={value} setValue={setValue} placeholder={placeholder}/>
            </div>
            <SpacerHS_ONE/>
            {desText && <div className='hs_sub_font hs_text-light'>
                {desText}
            </div>}
        </div>
    )
}

export default React.forwardRef(InputDesHs);