import React from 'react'

const Selectdedicatedcpu = ({state, country,idValue,children,handler,cardName,isHeight}) => {
    function onClickHandler(){
        handler()
      }
      return (
        <div className='col-lg-3 col-sm-6'>
        <label htmlFor={idValue} style={{height:isHeight,width:"100%"}} className="boxlocation_hireserver p-2 border-light ml-0 shadow " id={idValue+ '_location'} data-locationVal={idValue}>
        <input onClick={onClickHandler} data-location={idValue} className="checkbox_location_hireserver invisible" name={cardName} id={idValue} type="radio" />
       
        <div className="tick_default">
    
        </div>
        <div className="tick text-center">
            <i className="fa fa-check"></i>
        </div>
    
        <div className="">
          {children}
        </div>
    </label>
    </div>
      )
}

export default Selectdedicatedcpu