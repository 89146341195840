import React from "react";
import { Alert } from "react-bootstrap";
import ticktoaster from '../../../images/hireserver/imgs/ticktoaster.png';
import dangeralert from '../../../images/hireserver/imgs/dangeralert.png';
export function ToastCustomAlert_hs({ variant, text,type }) {
    const [imgStateName,setImgStateName] = React.useState('');
    function getSrc(){
        // if(type === '' || type === undefined || type === null){
        //     // setImgStateName('backgroundalertHsBarGreen')
        //     return ticktoaster;
        // }
        let isFalse  = isCheck();
        if(isFalse){
            return ticktoaster;
        }

        return dangeralert;
    }

    function isCheck(){
        if(type === '' || type === undefined || type === null){
            // setImgStateName('backgroundalertHsBarGreen')
            return true;
        }
    }

    React.useEffect(function(){
    
       let isFalse =  isCheck();
       if(isFalse){
        setImgStateName('backgroundalertHsBarGreen')
       }else{
        setImgStateName('backgroundalertHsBarRed')
       }
    },[]);

    return (
        <Alert
            key={1}
            variant={''}
            style={{borderRadius:'4px'}}
            className='alert-dismissible alertBackground shadow-sm align-items-start fade show m-0 border-0 h-100 d-flex py-1 d-gap-5 p-0 align-items-center justify-content-center'
        >
            <div className={`alertBar_hs ${imgStateName}`}></div>
            <div className='pt-1 d-flex d-gap-10 align-items-center'>
                {/* <div style={{ height: '18px', width: '18px', display: 'inline-block' }}>
                    <svg
                        viewBox='0 0 24 24'
                        width='100%'
                        height='100%'
                        stroke='currentColor'
                        strokeWidth='2'
                        fill='none'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='mr-2'
                    >
                        <polyline points='9 11 12 14 22 4'></polyline>
                        <path d='M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11'></path>
                    </svg>
                </div> */}
                <div className={(type=='danger' || type==='delete') ? 'bg-danger' : 'text-success'} style={{
                    width:'2px',
                    height:'50px'
                }}></div>
                <div className="h-35p w-35p">
                    <img className="w-100 h-100" src={getSrc()} alt={variant} />
                </div>
                <div>
                <div className="text-primary d-none">{variant}</div>
                <div>
                  {( type === 'danger' || type==='delete' ) && <div className="text-danger font-16">Uh oh, something went wrong</div>}
                  { ( type !== 'danger' && type!=='delete' )  && <div className="text-dark font-w600 font-16">Success</div>}
                    <div className="font-14 hs_text-light wx-200p text-break" >{text}</div>
                </div>
                </div>
            </div>
            {/* <img className="mt-2" style={{width:'8px'}}  src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e" alt="" /> */}
            {/* <div className="font-w700  font-12" >X</div> */}
            <div className="p-3 font-14 closeAlertButton">
                Close
            </div>
        </Alert>
    )
}

