import { GET_DETAIL_ITEM, SET_DETAIL_ITEM } from "../../types/InstancesTypes/DetailsInstances";

export function setDetailInstances(payload){
    return {
        type:SET_DETAIL_ITEM,
        payload
    }
}

export function getDetailInstances(payload){
    return {
        type:GET_DETAIL_ITEM,
        payload
    }
}