import React from 'react'
import { connect } from 'react-redux';
import { getTimeLineDataService } from '../../../../../../../../services/InstancesServices/InstancesListService';
import { checkResData } from '../../../../../../../../utils/Helpers';
import LoaderHS from '../../../../../../hireserver/components/LoaderHS';
import HeadingHS from '../../../../../../hireserver/controls/HeadingHS';
import SpacerHS_ONE from '../../../../../../hireserver/Spaces/SpacerHS_ONE';
import SpacerHS_TWO from '../../../../../../hireserver/Spaces/SpacerHS_TWO';
import { handlerAlert } from '../../../../InstancesListComponents/DropDownInstancesList';
import TimeLineItem from './TimeLineComponents/TimeLineItem/TimeLineItem';

function TimeLine({currDetailsItem}) {
    const [timeLineDataState,setTimeLineDataState] = React.useState(null);
    const [loading,setLoading] = React.useState(true);;

    React.useEffect(function (){
        getTimeLineData();
    },[]);

    function formatTimeLineData(data){
        const newData = {};
        for(let i=0;i<data.length;i++){
            const {OldValue,NewValue,UpdatedColumnName,EventTemplateNote,CreatedDate,UserName} = data[i];
          
            if(!EventTemplateNote?.hasOwnProperty('NotesText')) {
                
            }else{
                let notesText = EventTemplateNote['NotesText'];
                notesText = notesText.replace('{{oldvalue}}',OldValue);
                notesText = notesText.replace('{{newvalue}}',NewValue);
    
                const isThere = newData[CreatedDate]
    
                if(!isThere){
                  
                    newData[CreatedDate] = {
                        dataArr:[notesText],
                        groupname:UpdatedColumnName,
                        createdDate:CreatedDate,
                        userName:UserName
                    }
                    
                }else{
                  
                    newData[CreatedDate]?.dataArr.push(notesText)
                }
            }
           
        }

    
        return newData;
    }

    async function getTimeLineData(){
        if(!loading) setLoading(true); 
        const {versionguid,installedappid} = currDetailsItem;
        const resData = await getTimeLineDataService(versionguid,installedappid);
        const isValid = checkResData(resData);
        if(!isValid){
            handlerAlert("danger","Something Went Wrong in Timeline","danger");
            setLoading(false);
            return;
        }

        const data = JSON.parse(resData.data.result);



  
       const newData =  formatTimeLineData(data)
       setLoading(false);
       setTimeLineDataState(newData);
    }

    function getTimeLineHTML(){
        const timeLineHTML = [];
        for(let item in timeLineDataState){
            let list = [];
            const arr = timeLineDataState[item]?.dataArr;
            const groupName =  timeLineDataState[item]?.groupname;
            const createdDate = timeLineDataState[item]?.createdDate;
            const userName = timeLineDataState[item]?.userName;
            // for(let i=0;i<arr.length;i++){
            //     list.push(<div>{arr[i]}</div>)
            // }
            // let htmlDiv = <div>
            //     <div>
            // {groupName}
            //     </div>
            //     {list}
            //     <div>
            //         {createdDate}
            //     </div>
            // </div>
           
            timeLineHTML.push(<TimeLineItem  arr={arr} userName={userName} groupName={groupName} createdDate={createdDate} />);
        }

        return timeLineHTML;
   
    }

 if(loading){
    return <LoaderHS/>
 }else{
    return (
        <div>
            <div>
                <HeadingHS as="h3" title="TimeLine" />
            </div>
            <SpacerHS_TWO/>
            <div className='timelineParentContainer'>
            {getTimeLineHTML()}
            </div>
        </div>
      )
 }
}

const mapStateToProps = ({ instanceDetails }) => {
    return {
      currDetailsItem: instanceDetails.currDetailsItem,
    };
  };
export default connect(mapStateToProps)(TimeLine);