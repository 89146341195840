import React from "react";
import { Button } from "react-bootstrap";
import { Link, useRouteMatch } from "react-router-dom";
import supportBee from '../../../../../images/hireserver/imgs/bee/support.png';

function EmptyListContainer({ emptyListContainerData,classes }) {
    let { path, url } = useRouteMatch();
    const [urlnew,setURL] = React.useState('');
   React.useEffect(function (){
    if(url == '/'){
        setURL('/instances')
    }else{
        setURL(url);
    }
   },[]);
    const { icon, title, content, button, svg,img } = emptyListContainerData;
    function getContentContainer() {
        return content.map(({ text, color }, index) => {
            return <div key={index} className={`text-${color}`}>{text}</div>;
        });
    }

    

    return (
        <div
            className={`emptyListContainer container bg-white text-center d-flex flex-column align-items-center justify-content-center ${classes}`}
            style={{ gap: "0px" }}
        >
            <div
            className="pb-4 mr-3"
                style={{
                    width: 180,
                    height: 'max-content',
                    fontSize: "70px",
                }}
                // className="icon_bg"
            >
                {/* <i className={`text-green  ${icon}`}></i> */}
                {/* <div className="icon_col">{svg}</div> */}
                <img className="w-100" src={img} alt={title} />
            </div>
            <div className="text-dark-0 fs-24 font-w600">{title}</div>
            <div className="contentContainer d-flex flex-column" >{getContentContainer()}</div>
            {button.isClickHandler && button.isTrue && (
                 <Button onClick={button.clickHandler} className="hs_borderRadius-4 mr-2 mt-2 mt-lg-3 bg-primary border-0 menu_title_btn" style={{padding:'8px 30px'}}>{button.text}</Button>
            )}
            {button.isTrue && !button.isClickHandler && (

                <Link to={`${urlnew}/create`} >
                    <Button className="hs_borderRadius-4  mr-2 mt-2 mt-lg-3 bg-primary border-0 menu_title_btn" style={{padding:'8px 30px'}}>{button.text}</Button>
                </Link>
            )}
        </div>
    );
}

export default React.memo(EmptyListContainer);