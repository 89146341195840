import React from 'react'
import { getInstancesBizgazeService } from '../../../../services/InstancesServices/InstancesListService';
import CustomTable from '../components/CustomTable/CustomTable'

function TestPage() {

    const [dataComp, setData] = React.useState([]);
    const [dataHeader,setHeaderData] = React.useState([]);

    React.useEffect(function () {
        getData();

    }, []);

    async function getData() {
        const { data } = await getInstancesBizgazeService();

        let fullData = [];
        if (data.result != '') {
            fullData = JSON.parse(data.result);
        }

        //// console.log(fullData);
        setData(fullData);
        setHeaderData([{len:'1',name:'check'},{len:'3'}])
    }

    return (
    <div>
            <CustomTable dataHeader={dataHeader} data={dataComp} />
            
        </div>
    )
}

export default TestPage