import axios from 'axios';
import _ from 'lodash';
import React from 'react'
import { connect, useDispatch } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { postExecScriptURL, SEVERNAME } from '../../../../../../../../API_ENDPOINTS/API_ENDPOINTS';
import { postscriptmarketService } from '../../../../../../../../services/InstancesServices/InstanceCurrListNewDropdownService';
import { getAllScriptBymachineService, getSessionAndUserId, getViewOutputMachineORScriptService } from '../../../../../../../../services/InstancesServices/InstancesListService';
import { openAuthModal } from '../../../../../../../../store/actions/AuthAlertActions/AuthAlertActions';
import { checkResData, isJsonStringHelper } from '../../../../../../../../utils/Helpers';
import LoaderHS from '../../../../../../hireserver/components/LoaderHS';
import NoDataCard from '../../../../../../hireserver/SharedComponents/NoDataCard';
import SpacerHS_TWO from '../../../../../../hireserver/Spaces/SpacerHS_TWO';
import DeleteModalScript from '../../../../../MarketPlace/Components/ScriptsMarket/DeleteModal/DeleteModalScript';
import DetailScripts from '../../../../../MarketPlace/Components/ScriptsMarket/detailsscripts/DetailScripts';
import { ScriptCardTempItem } from '../../../../../MarketPlace/Components/ScriptsMarket/ScriptCardItem';
import ViewoutputTable from '../../../../../MarketPlace/Components/ScriptsMarket/viewoutputtable/ViewoutputTable';
import { handlerAlert } from '../../../../InstancesListComponents/DropDownInstancesList';
import CreateScriptModalParent from './createscripts/CreateScriptModalParent';
import ScriptExecModal from './scriptexecmodal/ScriptExecModal';


function ScriptInstances({ currDetailsItem }) {
    const [scriptList, setScriptList] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [showDeleteModal, setDeleteShowModal] = React.useState(false);
    const [loadingDelete, setLoadingDelete] = React.useState(false);

    const [currItemState, setCurrItem] = React.useState(null);

    const [execModalLoading, setExecModalLoading] = React.useState(false);

    // execModal
    const [execModal, setExecModal] = React.useState(false);

    const [createScriptModal, setCreateScriptModal] = React.useState(false);

    //parent values
    const [parentValues, setParentValues] = React.useState(null);

    //output view table
    const [outputViewList, setOutputViewList] = React.useState([]);
    const [formErrors, setFormErrors] = React.useState({
        isError: false,
        message: ''
    });

    const [searchInput, setSearchInput] = React.useState('');
    const [dataList, setDataList] = React.useState([]);

    const dispatch = useDispatch();
    let { path, url } = useRouteMatch();


    const execAbortRef = React.useRef(null);

    const debouncedChangeHandler = React.useMemo(
        () => _.debounce((val) => filterData(val), 200),
        [scriptList]
      );

    React.useEffect(function () {
        getScripts();
        getOutTable();
        return () => {
            if (execAbortRef.current) {
                execAbortRef.current()
            }
        }
    }, []);

    async function getOutTable() {
        const res = await getViewOutputMachineORScriptService(currDetailsItem?.machineid, 99);
        const resParse = JSON.parse(res.data.result);
        setOutputViewList(resParse);
        console.log(resParse);
    }

    //delete
    async function handleOkayDeleteFun(val) {
     
        let res;
        setLoadingDelete(true);
        try {


            res = await postscriptmarketService({ ...val, machineid_machinename: '', code: '1' });
            setLoadingDelete(false);
            setDeleteShowModal(false);
            getScripts();


        } catch (error) {
            setLoadingDelete(false);
            console.log(error);
            handlerAlert("danger", JSON.stringify(error), true);
        }

    }

    async function getScripts() {
        const res = await getAllScriptBymachineService(currDetailsItem?.machineid);
        const isValid = checkResData(res);
        if (!isValid) {
            //alert("something went wrong");
            console.log(res);
            return;
        }

        const isSafe = isJsonStringHelper(res.data.result);
        if (!isSafe) {
            setLoading(false);
            handlerAlert("danger", "Something Went Wrong", "danger");
            return;
        }

        const resData = JSON.parse(res.data.result);
        console.log(resData, 'resresresresres');
        setScriptList(resData);
        setDataList(resData)
        setLoading(false);
    }

    function handleCloseDeleteModal() {
        setDeleteShowModal(false);
        setLoadingDelete(false);
    }



    const handleOkayDelete = React.useCallback(() => {
        handleOkayDeleteFun(currItemState)
    }, [currItemState])

    function openModalDelete(val) {
        setDeleteShowModal(true);
        console.log(val);
        setCurrItem(val);
    }

    // exec modal

    function handlerCloseExecModal() {
        setExecModal(false);
        if (execAbortRef.current) {
            execAbortRef.current()
        }
    }

    // code for exec fun call
    async function postExecScriptFun(scriptId, data) {

        let { userId, sessionId } = getSessionAndUserId();

        let CancelToken = axios.CancelToken;



        let config = {
            method: 'post',
            url: postExecScriptURL(scriptId),
            headers: {
                'Authorization': `Basic ${sessionId}`,
            },
            data: {
                ...data, 'authorization': `Basic ${sessionId}`,
            },
            cancelToken: new CancelToken(function executor(c) {
                execAbortRef.current = c;
            })

            //cancelToken: execAbortRef.current.source.token
        };




        let response;

        try {
            response = await axios(config);
            console.log(response);
            if (response.data.code === 504) {
                setExecModalLoading(false);
                setFormErrors({
                    isError: true,
                    message: 'Unable to connect. Please check your SSH details.'
                })
                return;
            }
        } catch (error) {
            setExecModalLoading(false);
            handlerAlert("danger", error?.message, "danger");
            handlerCloseExecModal();
            getScripts();
            getOutTable();
            return;
        }

        if (response?.data?.code === "417" && response?.data?.message === "User Authentication Failed") { dispatch(openAuthModal()); }



        const { code, message } = response?.data;
        if (code != '200') {
            setExecModalLoading(false);
            handlerAlert("danger", message, "danger");
            handlerCloseExecModal();
            getScripts();
            getOutTable();
            return;
        }

        handlerAlert("success", "Script has been Execute");
        setExecModalLoading(false);
        handlerCloseExecModal();
        getScripts();
        getOutTable();
     

    }

    async function handlerOkayExecModal(inputs, tokenHeader) {
     
        console.log(currItemState);

        const { scriptid, tokens, refstatusid } = currItemState;
        setExecModalLoading(true);
        if (refstatusid != 4) {
            setExecModalLoading(false);
            setFormErrors({
                isError: true,
                message: 'Unable to connect. Please check your SSH details.'
            })
            return;
        }

        const data = JSON.parse(tokens);
        console.log(inputs, tokenHeader);
        let newToken = [];
        for (let item in inputs) {
            const { key, value } = inputs[item];
            let obj = {
                key,
                value
            };

            newToken.push(obj);
        }
        let obj = {
            "params": newToken,
            sshpassword: tokenHeader.passwordVal,
            sshport: tokenHeader.portVal,
            sshuser: tokenHeader.shhUserVal,
            sship: tokenHeader.shhIpVal,
            "serverurl": `${SEVERNAME}`,

        }


        const newScriptObj = { ...currItemState, machineid_machinename: currItemState.machinename, tokens: obj, script: parentValues.script };
        console.log(newScriptObj, 'newScriptObjnewScriptObj');
        const resSaveScript = await postscriptmarketService(newScriptObj);
        console.log(resSaveScript);


        await postExecScriptFun(scriptid, obj);
        console.log('helloooooooooo');

    }

    function openExecModal(val) {
        setExecModal(true);

        setCurrItem(val);
    }

    function handleCreate() {
        setCreateScriptModal(true);
    }

    function callbackHandler(){
        getScripts();
      }
    


    if (loading) {
        return <LoaderHS />
    }

    
  function handleInputSearch(e) {
    let val = e.target.value;
    setSearchInput(val);
    debouncedChangeHandler(val);
  }

  function filterData(val) {
 
    if (val === '') {
    //   let newArr = [];
    //   for (let i = 0; i < scriptList.length; i++) {
    //     if (scriptList[i].machineid != '0') {
    //       newArr.push(scriptList[i])
    //     }
    //   }
      setDataList(scriptList);
      return;
    }
    let data = [];
    for (let i = 0; i < scriptList.length; i++) {
      let currItem = scriptList[i];
      
        if (currItem.name.includes(val)) {
          data.push(currItem);
        }
      

    }

    setDataList(data);
  }

    return (
        <React.Fragment>
            <Switch>
                <Route path={`${path}/details/:scriptid/scripts`}>
                   <DetailScripts callback={callbackHandler} />
                </Route>
                <Route path={path}>
                    <div className='d-flex justify-content-end'>
                        <button onClick={handleCreate} className='hs-signin-btn btn btn-sm hs_borderRadius-4 py-1'>Create Script</button>
                    </div>
                    <SpacerHS_TWO/>
                    <div className='hs-grey w-100'>

<input value={searchInput} onChange={handleInputSearch} placeholder='search' type="text" className="form-control hs_borderRadius-4 bg-transparent border-0 p-4 customInputSearch" />
</div>
<SpacerHS_TWO/>
                    <div className='d-flex flex-wrap'>
                       {
                        dataList.length ?  <React.Fragment>
                             
                            {dataList.map(function (item, index) {
                            return <ScriptCardTempItem link={true} urlLink={`scripts/details/${item.scriptid}/scripts`} execHander={openExecModal} execIsNeed={true} val={item} handlerDelete={openModalDelete} key={index} />
                        })}
                        </React.Fragment> : <NoDataCard title="No scripts" />
                       }
                    
                    </div>
                    <div>
                        <ViewoutputTable outputList={outputViewList} />
                    </div>
                </Route>
            </Switch>
            <ScriptExecModal setFormErrors={setFormErrors} formErrors={formErrors} setParentValues={setParentValues} loadingExec={execModalLoading} item={currItemState} closeModal={handlerCloseExecModal} okayModal={handlerOkayExecModal} showModal={execModal} />
            <DeleteModalScript deleteModal={showDeleteModal} handleCloseDeleteModal={handleCloseDeleteModal} handleOkayDelete={handleOkayDelete} loadingDelete={loadingDelete} />
            <CreateScriptModalParent getScripts={getScripts} setShow={setCreateScriptModal} show={createScriptModal} />

        </React.Fragment>
    )
}
const mapStateToProps = ({ instanceDetails }) => {
    return {
        currDetailsItem: instanceDetails.currDetailsItem,
    };
};
export default connect(mapStateToProps)(ScriptInstances);
