import React from 'react'
import SpacerHS_TWO from '../../../../../../hireserver/Spaces/SpacerHS_TWO';
import BoundItem from './BoundItem';

function BoundMaster({boundTypes,data,boundName,isFirst=true,boundItemDeleteHandler,itemsData,itemDataSetter}) {
    const [inBoundHeaders, setInBoundHeaders] = ['Type', 'Protocol', 'port range', 'Source', 'Description - optional'];


    function getBoundsItems(){
        const htmlTemp = [];
      
            let index = 1;
          for(let item in data){
           
          
            htmlTemp.push(<React.Fragment>

<BoundItem boundName={boundName} index={index++} itemDataSetter={itemDataSetter} fullItemData={itemsData} boundItemDeleteHandler={boundItemDeleteHandler} itemData={data[item]}  boundTypes={boundTypes} />
<hr/>
            </React.Fragment>);
          }

          return htmlTemp;
    }

    if(isFirst){
        if(Object.keys(data).length === 0){
            return <React.Fragment>
                <div className='w-100 text-center py-5'>
                {`This security group has no ${boundName} rules.`}
                </div>
            </React.Fragment>
        }
    }

    

    return (
        <div>

            <div className='d-none d-lg-block'>
            <div className="row">
                <div className="col-sm-6 col-md-4 col-lg-2">
                    Type
                </div>
                <div className="col-sm-6 col-md-4 col-lg-1">
                    Protocol
                </div>
                <div className="col-sm-6 col-md-4 col-lg-2">
                Port
                </div>
                <div className="col-sm-6 col-md-4 col-lg-2">
                Source
                </div>
                <div className="col-sm-6 col-md-4 col-lg-2">
                    <div className="d-lg-none">
                        Destion
                    </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-2">
                    Description<span className='font-7'>(optional)</span>

                </div>
                <div className="col-sm-6 col-md-4 col-lg-1 d-none">
                    <span> <i className="fa fa-trash"></i> </span>
                </div>
            </div>
            </div>
            <SpacerHS_TWO/>
            {getBoundsItems()}
          
            {/* <div className='headerBound row'>
                <div className="col-lg-6 col-md-12">
                    <div className="row">
                        <div className="col-lg-5">
                            <input type="text" className="form-control" />
                        </div>
                        <div className="col-lg-2">
                            <input type="text" className="form-control" />
                        </div>
                        <div className="col-lg-5">
                            <input type="text" className="form-control" />
                        </div>
                    </div>

                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="row">
                        <div className="col-lg-2">
                            <input type="text" className="form-control" />
                        </div>
                        <div className="col-lg-5">
                            <input type="text" className="form-control" />
                        </div>
                        <div className="col-lg-5">
                            <input type="text" className="form-control" />
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default BoundMaster