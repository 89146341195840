import React from 'react'
import { Dropdown } from 'react-bootstrap';
import { twoStopURL } from '../../../../../../API_ENDPOINTS/API_ENDPOINTS';
import { getInstanceDetailsService } from '../../../../../../services/InstancesServices/InstancesListService';
import { VM_ACTION_PERFORM } from '../../../../../../store/actions/InstancesActions/InstanceCurrItemAction';
import CustomDropDown from '../../CustomDropDown';

function InstancesDropDownTable({ statusid = 1, itemData, setLoadingStatus, setItemData }) {
  const [actionMachineData, setActionMachineData] = React.useState([{ title: 'Start', handler: (e) => startHandler(e, statusid), dataTags: { 'data-machineaction': 1 } },
  { title: 'Stop', handler: (e) => startHandler(e, statusid), dataTags: { 'data-machineaction': 2 } },
  { title: 'Reboot', handler: (e) => startHandler(e, statusid), dataTags: { 'data-machineaction': 3 } },
  { title: 'Delete', handler: (e) => startHandler(e, statusid), dataTags: { 'data-machineaction': 4 } }, { title: 'ReInstall', handler: (e) => startHandler(e, statusid), dataTags: { 'data-machineaction': 99 } }]);

  async function startHandler(e, statusC) {
    setLoadingStatus(true);
    let machineAction = parseInt(e.target.getAttribute('data-machineaction'));
    if (machineAction === 2) {
      const url = twoStopURL(itemData.vmid, itemData.hostname, itemData.branchid)
      const res = await VM_ACTION_PERFORM(url, 'post', itemData?.branchid, itemData?.hostname, itemData?.machineid, 2);

      const machineDetails = await getInstanceDetailsService(itemData?.machineid);
      
      const machineDetailsToNormal = JSON.parse(machineDetails.data.result);
      setItemData(machineDetailsToNormal[0]);
      setLoadingStatus(false);
    }

    if(machineAction === 1){
      const url = twoStopURL(itemData.vmid, itemData.hostname, itemData.branchid);
      const res = await VM_ACTION_PERFORM(url, 'post', itemData?.branchid, itemData?.hostname, itemData?.machineid, 1);

      const machineDetails = await getInstanceDetailsService(itemData?.machineid);
      
      const machineDetailsToNormal = JSON.parse(machineDetails.data.result);
      setItemData(machineDetailsToNormal[0]);

      setLoadingStatus(false);
    }
  }
  return (
    <CustomDropDown classNameForDom={`instacesClass ${'disabledClass'} listDropdownHS data-class-${452}`} statusid={null} items={actionMachineData} >
      <Dropdown.Toggle variant className="table-dropdown i-false" >
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" >
            <rect x="0" y="0" width="24" height="24"></rect>
            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
          </g>
        </svg>
      </Dropdown.Toggle>
    </CustomDropDown>
  )
}

export default InstancesDropDownTable;