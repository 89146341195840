import React from 'react'
import { withRouter } from 'react-router-dom';

function BackButton({ history, classesnames,backUrl=undefined,callback }) {
  
  function handlerGoBack() {

    if(callback){
      callback();
    }
    if(backUrl){
      
      history.push(backUrl);
    }else{
      history.goBack();
    }
  }

  return (
    // <span  onClick={handlerGoBack} className='font-30 hsBack cursor-pointer text-center d-flex align-items-center'><i className='fa fa fa-arrow-circle-o-left'></i></span>
    <div className={`d-flex align-items-center ${classesnames}`}>
      <span onClick={handlerGoBack}  className='font-18 justify-content-center hasBackButton cursor-pointer text-center d-flex align-items-center'><i className='fa fa fa-angle-left'></i></span>
    </div>
    // <div className={`d-flex align-items-center ${classesnames}`}>
    //   <span onClick={handlerGoBack}  className='font-30 hsBack cursor-pointer text-center d-flex align-items-center'><i className='fa fa fa-arrow-circle-o-left'></i></span>
    // </div>
  )
}

export default withRouter(BackButton)