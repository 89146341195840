import React from 'react'
import RunningStatus from "./RunningStatus"
import HSLatencyCharts from "../HsCharts/HsLatencyCharts";
import HsWeeklyCharts from "../HsCharts/HsWeeklyCharts";
import { Sparklines, SparklinesLine, SparklinesBars } from "react-sparklines";
import AnalyticsTab from './AnalyticsTab';

const Overview = () => {
  return (
      <div className='Overview'>
        <div><AnalyticsTab/></div>
       
      </div>
      
  )
}

export default Overview