import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { getpaymentInfoService, submitPaymentFormService } from '../../../../../services/Profile/PaymentFormServices';
import { closePaymentInfoFormAction, putPaymentInfoStatus } from '../../../../../store/actions/PaymentInfoActions/PaymentInfoAction';
import useInputHook from '../../../../hooks/InputHook/useInputHook';
import CountriesList from '../../../../indexComponents/PaymentInfoFormComponents/components/CountriesList';
import CurrenciesList from '../../../../indexComponents/PaymentInfoFormComponents/components/CurrenciesList';
import StatesList from '../../../../indexComponents/PaymentInfoFormComponents/components/StatesList';
import FormInputError from '../../../hireserver/components/FormInputError';
import InputErrorBackground from '../../../hireserver/components/InputErrorBackground';
import LoaderHS from '../../../hireserver/components/LoaderHS';
import SpacerHS_TWO from '../../../hireserver/Spaces/SpacerHS_TWO';
import { handlerAlert } from '../../Instances/InstancesListComponents/DropDownInstancesList';
import InputInfoFormLabel from './components/InputInfoFormLabel';

function IndividualInfoTable({ userDetails, paymentData }) {

  const dispatch = useDispatch();
  const [loading,setLoading] = React.useState(false);
  const { value: contactPerson, setValue: setContactPerson, error: contactPersonError, setError: setContactPersonError, resetAll: contactPersonReset } = useInputHook();

  const { value: companyName, setValue: setCompanyName, error: companyNameError, setError: setCompanyNameError, resetAll: companyNameReset } = useInputHook();

  const { value: addressVal, setValue: setAddressVal, error: addressValError, setError: setAddressValError, resetAll: addressValReset } = useInputHook();


  const { value: taxNumber, setValue: setTaxNumber, error: taxNumberError, setError: setTaxNumberError, resetAll: taxNumberReset } = useInputHook();

  const { value: emailVal, setValue: setEmailVal, error: emailValError, setError: setEmailValError, resetAll: emailValReset } = useInputHook();


  const { value: phoneContactVal, setValue: setPhoneContactVal, error: phoneContactValError, setError: setPhoneContactValError, resetAll: phoneContactValReset } = useInputHook();


  const { value: zipcode, setValue: setZipCodeVal, error: zipcodeError, setError: setZipCodeValError, resetAll: zipcodeReset } = useInputHook();

  const [currencyVal, setCurrencyVal] = React.useState(null);
  const [currencyState, setCurrencyState] = React.useState(0);

  const [currencyStateError, setCurrencyStateError] = React.useState({
    isError: false,
    errorMessage: ''
  })

  const [isUpdate, setIsUpdate] = React.useState(false);

  const [serverError, setServerError] = React.useState({
    isError: false,
    errorMessage: ''
  })



  const [country, setCountry] = React.useState(0);
  const [states, setStates] = React.useState(0);
  const [countryError, setErrorCountry] = React.useState({
    isError: false,
    errorMessage: ''
  })
  const [stateError, setErrorState] = React.useState({
    isError: false,
    errorMessage: ''
  });

  React.useEffect(function () {
    if (serverError.isError) {
      setTimeout(function () {
        setServerError({
          isError: false,
          serverError: ''
        })
      }, 2000);
    }
  }, [serverError.isError]);




  React.useEffect(function () {
    if (!paymentData) return;

    initForm();
  }, [paymentData]);

  function initForm() {
    if (paymentData === null) return;

    if (!paymentData['currencyname']) {
      return;
    }
    setIsUpdate(true);
    console.log(paymentData,'paymentData');
    const { countryid, countryname, cityid, cityname, currencyname, contactpersonname, phonenumber, gstindetailid,gstinno, organizationname, addressline, zipcode } = paymentData;
    setCountry({
      value: countryid,
      label: countryname,
    });

    setStates({
      value: cityid,
      label: cityname,
    });

    setContactPerson(contactpersonname);
    setCompanyName(organizationname);
    setPhoneContactVal(phonenumber);
    setZipCodeVal(zipcode)
    setTaxNumber(gstinno);
    setAddressVal(addressline);

    setEmailVal(userDetails?.userEmail)
    setCurrencyVal(currencyname);
    setCurrencyState({
      label: paymentData['currencyname'],
      value: paymentData['currencyid']
    });

    
  }

  function handlerContactPerson(e) {
    setContactPerson(e.target.value);
  }

  function handlerCompanyName(e) {
    setCompanyName(e.target.value);
  }

  function handlerZipCode(e) {
    if(e.target.value.length>=7) return;
    setZipCodeVal(e.target.value);
  }
  function handlerAddress(e) {
    setAddressVal(e.target.value);
  }

  function handlerTaxNumber(e) {
    setTaxNumber(e.target.value);
  }

  function handlerEmail(e) {
    return;
  }

  function handlerPhone(e) {
    if(e.target.value.length>=11) return;
    setPhoneContactVal(e.target.value);
  }

  function currencyHandler(id) {
    setCurrencyState(id)
  }

  

  function formValidation() {
    let isError = false;
 

    if (companyName === '') {
      isError = true;
      setCompanyNameError({
        isError: true,
        message: 'Please fill in required field'
      })
    }

    if (country?.value == 0 || country == 0) {
      isError = true;

      setErrorCountry({
        isError: true,
        message: 'Please fill in required field'
      })
    }

    if (country?.label === 'India') {

      if (zipcode.length < 6 ||zipcode.length>6 ) {
        isError = true;
        //   setZipCodeState({
        //     ...zipCodeState,
        //     val: zipCodeState.val,
        //     isError: true,
        //     message: 'Pin Code should be more than 6'
        //   });

        setZipCodeValError({
          isError: true,
          message: 'Pin Code should be more than 6'
        })
      }
    }


    if (states?.value == 0 || states == 0 || states == null) {
      isError = true;
      setErrorState({
        isError: true,
        message: 'Please fill in required field'
      })
    }

    if (!addressVal) {
      isError = true;
      setAddressValError({
        isError: true,
        message: 'Please fill in required field'
      })
    }

    


    if (!zipcode) {
      isError = true;
      setZipCodeValError({
        isError: true,
        message: 'Please fill in required field'
      })
    }
    
    if (!phoneContactVal || phoneContactVal.length != 10) {

      isError = true;
      if(phoneContactVal.length != 10){
        setPhoneContactValError({
          isError: true,
          message: 'Contact Number is not valid'
        })
        
      }else{
        setPhoneContactValError({
          isError: true,
          message: 'Please fill in required field'
        })
      }
     
    }
    return isError;
  }


  function clearErrors(){
    setContactPersonError({
        isError:false,
        message:''
    })
    setCompanyNameError({
        isError:false,
        message:''
    })
    setTaxNumberError({
        isError:false,
        message:''
    })
    setEmailValError({
        isError:false,
        message:''
    })
    setPhoneContactValError({
        isError:false,
        message:''
    })
    setZipCodeValError({
        isError:false,
        message:''
    })
    setCurrencyStateError({
        isError:false,
        message:''
    })
    setServerError({
        isError:false,
        message:''
    })
    setErrorCountry({
        isError:false,
        message:''
    })
    setErrorState({
        isError:false,
        message:''
    })
  }

  async function submitHandler() {
    debugger;
    const isError = formValidation();
    if (isError) {
      return;
    }


    let updateForm = false;
    if (paymentData && paymentData['currencyname']) {
      updateForm = true;
    }

    setLoading(true);
    let cityId = states.__isNew__ ? 0 : states?.value;
    let formType = 2;
    let currPayload = {
      "cityid": Number(cityId),
      "CityName": states?.label,
      "addressline1": addressVal,
      "zipcode": zipcode,
      "OrganizationType": formType,
      "organizationname": companyName,
    
      "ContactName": contactPerson,
      "ContactId": 0,
      "countryname": country.label,
      "countryid": country.value,
      "statename": states?.label,
      "phonenumber": phoneContactVal,
      
      "addressline1": addressVal,
      "currencyid": currencyState?.value,
      "taxno": taxNumber,
      "BillingInfoExists": updateForm,
      "lobid": paymentData['lobid'],
      "gstindetailid": paymentData['gstindetailid'] || 0
    }

   



    let res = '';
    console.log(currPayload);

    try {
      res = await submitPaymentFormService(true, currPayload);
      if (res.data.code != '0') {
        handlerAlert("danger",res.data.message,"danger")
        setLoading(false);
        return;
      }

      if (res.data.result.includes('Billing info saved succesfully')) {
        let isFilled = false;
        let data = null;
        const checkPaymentStatus = await getpaymentInfoService();
        data = JSON.parse(checkPaymentStatus.data.result);
        isFilled = data[0]?.defaultaddressid == 0;
        dispatch(putPaymentInfoStatus(data[0]));
        setLoading(false);
   
        handlerAlert("success", "Billing info has been updated!")
        clearErrors();
     
      } else {
        setServerError({
          isError: true,
          message: res?.data?.message
        })
        setLoading(false);
      }
    } catch (error) {
     console.log(error);
     handlerAlert("danger","Something went wrong","danger")
     setLoading(false);
     clearErrors();
    }
  }




  return (
    <div>
      {/* <InputInfoFormLabel onChange={handlerContactPerson} value={contactPerson} label="Contact Person" type="text" />
      {contactPersonError.isError && <FormInputError message={contactPersonError.message} />}
      <SpacerHS_TWO /> */}

      <InputInfoFormLabel onChange={handlerCompanyName} value={companyName}   label="Contact Person" type="text" />
      {companyNameError.isError && <FormInputError message={companyNameError.message} />}
      <SpacerHS_TWO />

      <div>
        <div className='card-title font-16'>Country</div>
        <CountriesList isDisable={isUpdate} countryError={countryError} setErrorCountry={setErrorCountry} country={country} setCountry={setCountry} />
      </div>
      <SpacerHS_TWO />

      <div>
        <div className='card-title font-16'>State</div>
        <StatesList isUpdate={isUpdate} isDisable={isUpdate || !country?.value} stateError={stateError} setErrorState={setErrorState} country={country} states={states} setStates={setStates} />
      </div>
      <SpacerHS_TWO />

      <InputInfoFormLabel value={addressVal} onChange={handlerAddress} label="Address" type="text" />
      {addressValError.isError && <FormInputError message={addressValError.message} />}
      <SpacerHS_TWO />

      {/* <InputInfoFormLabel onChange={handlerTaxNumber} value={taxNumber} label="Tax Number" type="text" />
      {taxNumberError.isError && <FormInputError message={taxNumberError.message} />}
      <SpacerHS_TWO /> */}

      <InputInfoFormLabel onChange={handlerZipCode} value={zipcode} label="Zipcode" type="number" />
      {zipcodeError.isError && <FormInputError message={zipcodeError.message} />}
      <SpacerHS_TWO />

      <InputInfoFormLabel onChange={handlerEmail} disable={true} value={emailVal} label="Email" type="Email" />
      {emailValError.isError && <FormInputError message={emailValError.message} />}
      <SpacerHS_TWO />

      <InputInfoFormLabel onChange={handlerPhone} value={phoneContactVal} label="Contact Number" type="number" />
      {phoneContactValError.isError && <FormInputError message={phoneContactValError.message} />}
      <SpacerHS_TWO />

      {/* <InputInfoFormLabel onChange={currencyHandler} value={currencyVal} disable={true} label="Currency" type="text" />
      <SpacerHS_TWO /> */}

      <div>
        <div className='card-title font-16'>Currency</div>
      <CurrenciesList country={country} isDisable={isUpdate} currencyStateError={currencyStateError} setCurrencyStateError={setCurrencyStateError} currencyHandler={currencyHandler} currencyState={currencyState} />
      <SpacerHS_TWO />
      </div>
      {
            serverError.isError && <InputErrorBackground message={serverError.errorMessage} />
          }
      <div>
        <button onClick={submitHandler} className='btn cursor-pointer hs_borderRadius-4  hsButton-bg text-white w-120p'>
        {loading ? <React.Fragment>
                      <div className="spinner-border text-light" role="status">

                      </div> 
                    </React.Fragment> : 'Update'}
        </button>
      </div>


    </div>
  )
}
const mapStatesToProps = ({ authAlertReducer, auth, PaymentInfoReducer }) => {
  return {
    userDetails: auth.auth,
    paymentData: PaymentInfoReducer?.paymentData,
  };
};
export default connect(mapStatesToProps)(IndividualInfoTable);

