import { ADD_PAYMENT_DASHBOARD_MODAL, CLOSE_USER_DASHBOARD_MODAL, OPEN_USER_DASHBOARD_MODAL, RESET_USER_DASHBOARD, SET_DETAILS_USER_DASHBOARD } from "../../types/UserDashboardTypes/UserDashboardTypes"

const initialState = {
    dashboardDetails:null,
    paymentAmountModal:false,
    userPaymentVal:0
}

function UserDashboardReducer(state = initialState, {type,payload}) {
    switch (type) {
        case SET_DETAILS_USER_DASHBOARD:{
            return {
                ...state,
                dashboardDetails:payload
            }
        }
        case OPEN_USER_DASHBOARD_MODAL:{
            return {
                ...state,
                paymentAmountModal:true
            }
        }
        case CLOSE_USER_DASHBOARD_MODAL:{
            return {
                ...state,
                paymentAmountModal:false
            }
        }
        case ADD_PAYMENT_DASHBOARD_MODAL:{
            return {
                ...state,
                userPaymentVal:payload
            }
        }
        case RESET_USER_DASHBOARD:{
            return initialState;
        }

       
        default:
            return state;
    }

}

export default UserDashboardReducer;