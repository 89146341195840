import React from 'react'
import HeadingHS from '../../../../hireserver/controls/HeadingHS'
import SelectCard from '../../../../hireserver/SelectCard'

function Selectlabel({ name, handler, content,dataName }) {
    function handlerClick(e){
        handler(e)
    }
    return (
        <label  className='d-flex d-gap-10 align-items-center'>
          
            <input type="radio" data-name={dataName+name} onClick={handlerClick} className=' tick_SelectlabelInput d-none' name={name} />
           
            <div className='tick_Selectlabel'></div>
           
            <div className="tick_Check_tick_Selectlabel text-center">
                <i className="fa fa-check"></i>
            </div>
            <div className='hs_text-light'>
                <div>{content.title}</div>
                <div>{content.subTitle}</div>
            </div>

        </label>
    )
}


function NumberOfNodes({clickHandler}) {
    const [data, setData] = React.useState([
        {
            title: '1 Nodes',
            subTitle: '$0/month $0/hr'
        }
        ,
        {
            title: '3 Nodes - High Availability (recommended)',
            subTitle: '$0/month $0/hr'
        }
    ]);
    function handlerClick(e) {
        let name = e.target.getAttribute('data-name');
       
        clickHandler(name);
        // console.log(name)
    }

    function getNodeOptions() {
        return data.map((content,i) => {
            return <div className='col-12'><Selectlabel name="nodesOptions" dataName={i} handler={handlerClick} content={content} /></div>
        })
    }
    return (
        <div>
            <HeadingHS as="h3" title="Set Number of Nodes" />
            <div className='hs_text-light'>
                We recommend 3 nodes in a database cluster to avoid downtime during upgrades and maintenance.
            </div>
            <div className='pt-3 row d-gap-10'>
                {getNodeOptions()}
            </div>
        </div>
    )
}
export default  React.memo(NumberOfNodes);
{/* <div className='d-flex d-gap-10 align-items-center'>
            <div >
                <div className='d-flex justify-content-center align-items-center'>
                    <label style={{width:'35px',height:"35px"}} className='tableCustomCircle light_borderColor' ></label>
                </div>
                <input type="radio" hidden name={name} />
            </div>
            <div>
                <div>1 Node</div>
                <div>$0/month $0/hr</div>
            </div>
        </div> */}