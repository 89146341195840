import React from 'react'
import { Dropdown } from 'react-bootstrap'

function DropDownMenuSelectSearch({displayData,loading,onSelectOption}) {
    function getHtmlItem(){
        const items = []
        for(let i=0;i<displayData.length;i++){
            const {label,value} = displayData[i];
              items.push(<Dropdown.Item data-name={label} onClick={onSelectOption} key={value} data-label={value}>{label}</Dropdown.Item>);
        }

        return items;
    }
    
    console.log('rending')
  return (
    <Dropdown.Menu className='removeDefaultDropMenu w-100'>
        {/* <Dropdown.Item href="#/action-1">Action</Dropdown.Item> */}
       {displayData.length>=1 && displayData.map(function ({label,value},i){

        return <Dropdown.Item data-name={label} onClick={onSelectOption} key={value} data-label={value}>{label}</Dropdown.Item>
       })}
       {displayData.length===0 && !loading &&  <div className='px-2'>No Data</div>}
       {displayData.length===0 && loading && <div className='px-2'>Loading</div>}
      </Dropdown.Menu>
  )
}

export default React.memo(DropDownMenuSelectSearch);