import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

function TicketIdView({id,ticketno}) {
    const { url, path } = useRouteMatch();
  return (
    <Link to={`${path}/${id}/opentickets`}>{ticketno}</Link>
  )
}

export default TicketIdView