import React from 'react'
import HeaderCFSection from '../../Instances/components/HeaderCFSection'
import ClusterFormContext from '../clusterscontext/ClusterFormContext'
import serverImg from './../../../../../images/hireserver/serverimg.png';
import TotalAvaCFSection from '../../Instances/components/TotalAvaCFSection';
import { connect, useDispatch } from 'react-redux';
import { Card, Col, Form, Row } from 'react-bootstrap';
import LocationCFSection from '../../Instances/components/LocationCFSection';
import SelectionsOptions from '../../../hireserver/SelectionsOptions';
import RootPasswordCFSection from '../../Instances/components/RootPasswordCFSection';
import SSHSection from '../../Instances/components/SSHSection';
import IPCFSection from '../../Instances/components/IPCFSection';
import SpacerHS_TWO from '../../../hireserver/Spaces/SpacerHS_TWO';
import Tenure from '../../Instances/components/Tenure/Tenure';
import ChangeInputControl from '../../../hireserver/controls/ChangeInputControl';
import CurrencyFC from '../../../hireserver/components/Currency/CurrencyFC';
import StickyFooter from '../../../hireserver/StickyFooter';
import AlertComponent from '../../../hireserver/AlertComponent';
import DeployModalInstances from '../../Instances/components/sharedComponents/DeployModalInstances';
import { blockInvalidChar } from '../../../../../utils/Helpers';
import { MINUS_BTN, PLUS_BTN } from '../../../hireserver/BlocksCard';
import { withRouter } from 'react-router-dom';
import LocationClusterSection from '../components/LocationClusterSection';
import Clustercapacity from '../components/clustercapacity/Clustercapacity';
import InstanceFormDatabase from '../../Instances/components/databases/InstanceFormDatabase';
import HeadingHS from '../../../hireserver/controls/HeadingHS';
import ClusterTenure from '../components/clustertenants/ClusterTenure';
import DepolyPopup from '../../../hireserver/components/DepolyPopup/DepolyPopup';
import DeployRecItem from '../components/deployitem/DeployRecItem';
import AlertChangeLocation from '../components/alertchangelocation/AlertChangeLocation';



function ClusterForm({ isShowPayment, currencyname, history }) {
    const dispatch = useDispatch();
    const {
      privateIpRef,
      SShkeyRef,
      osList,
      blockidStage,
      setOslist,
      createRootPasswordRef,
      submitErrors,
      setSubmitErrors,
      selectTagOption,
      locationState,
      createInstanceNameRef,
      setSelectTagOption,
      blocksInput,
      isMore,
      setRootPassword,
      rootPassword,
      setRootPasswordAgain,
      rootPasswordAgain,
      setPrivateKey,
      privateKey,
      setSShKey,
      SShkey,
      checkIsCreateFormValid,
      setIsMore,
      selectOs,
      setSelectOs,
      onChangeBlocksHandler,
      setBlocksInput,
      blocksHandler,
      users,
      cores,
      setCores,
      locations,
      optionsArr,
      totalCores,
      show,
      createInstanceName,
      setCreateInstanceName,
      onChangeMachine,
      handleFormClick,
      onChangeLocation,
      setTenure,
      tenure,
      deployLoading, setDeployLoading,
      setSshkeyHash,
      locationDataLoading,
      summaryPrice, setSummaryPrice,
      blockInputRef,
      replicaRef,
      databasesList, setDatabasesList, marketplaceStateid, setMarketPlaceStateId, setSelectOsHandler, selectOsDB, setSelectOsDB, handleOsClickDB,
      marketPlacesList,marketPlacesItemId,setMarketPlacesItemId,
      selectOsMarketPlace, setSelectMarketPlace,handleOsClickMarket,specs,
      clustercapacityData,
      deployOpen,closeDeployModal,openDeployModal,summaryMachineInfo,setSummaryMachineInfo,
      submitHandler
    } = React.useContext(ClusterFormContext);

    const [displayPrice,setDisplayTotalPrice] = React.useState(0);
  
    React.useEffect(function () {
        document.addEventListener("wheel", removeWheelInput);
    
        return () => {
          document.removeEventListener("wheel", removeWheelInput)
    
        }
      }, []);
    
      function removeWheelInput(event) {
    
        if (document.activeElement.type === "number" &&
          document.activeElement.classList.contains("noscroll")) {
          document.activeElement.blur();
        }
    
      }
    
      function removeArrowKeys(e) {
    
        if (e.which === 38 || e.which === 40) {
          e.preventDefault();
        }
      }

      React.useEffect(function (){
        if(!Object.keys(clustercapacityData).length) return;
        let values = 0;
        // debugger;
        console.log(clustercapacityData,"clustercapacityData");
        for(let item in clustercapacityData){
          let curr = clustercapacityData[item];
          let {price,num} = curr['nodevalues'];
          values += (summaryPrice.price*num);
       

        }

        values += summaryPrice.price;
        setDisplayTotalPrice(values);
      },[summaryPrice.price,clustercapacityData]);
    
    
      React.useEffect(function () {
    
        if (isShowPayment == 0) {
          //history.push('/');
          return;
        }
      }, [isShowPayment]);

      const renderCustomHTMLDeploy = React.useCallback(() => {

        if(!deployOpen) return;
        console.log(clustercapacityData);
        let html =[];
        for(let item in clustercapacityData){
          html.push(<DeployRecItem key={`${item}_clustercapacityitem`}{...clustercapacityData[item]}/>)
        }

        return <React.Fragment>
          <div className="row">
            <div className="col-4 ">Name</div>
            <div className="col-4 ">Location</div>
            <div className="col-4 text-center">Blocks</div>
          </div>
          {html}
         
        </React.Fragment>
      },[clustercapacityData,deployOpen]);
    
      return (
        <React.Fragment>
          <div style={{ position: "relative" }}>
            <Form
              className="mb-5 mb-lg-0 backbroundoverlay"
              onSubmit={(e) => handleFormClick(e)}
              style={{ paddingBottom: "150px" }}
            >
    
    
              <Form.Group className="d-none">
                <Row>
                  <Col xs lg="5">
                    <Form.Text className="d-flex">
    
                      <h2 className="">What type of machine you want?</h2>{" "}
                    </Form.Text>
                    <div className="py-2 d-flex d-gap-20">
                      <div className="d-flex align-items-center d-gap-10">
                        <label className="cursor-pointer" htmlFor="shared">
                          Shared
                        </label>
                        <input
                          type="radio"
                          onChange={onChangeMachine}
                          name="machinetype"
                          id="shared"
                          data-machinetype="1"
                        />
                      </div>
                      <div className="d-flex align-items-center d-gap-10 ">
                        <label className="cursor-pointer" htmlFor="dedicated">
                          Dedicated
                        </label>
                        <input
                          onKeyDown={blockInvalidChar}
                          type="radio"
                          onChange={onChangeMachine}
                          name="machinetype"
                          id="dedicated"
                          data-machinetype="2"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form.Group>
    
    
              <HeaderCFSection
                setSubmitErrors={setSubmitErrors}
                submitErrors={submitErrors}
                instancesNameErr={submitErrors.instancesNameErr}
                ref={createInstanceNameRef}
                serverImg={serverImg}
                createInstanceName={createInstanceName}
                setCreateInstanceName={setCreateInstanceName}
                headerTitle="Enter Cluster Name"
              />
              <hr />
              <TotalAvaCFSection
                blockInputError={submitErrors.blocksErr}
                ref={blockInputRef}
                onChangeBlocksHandler={onChangeBlocksHandler}
                isMore={isMore}
                setIsMore={setIsMore}
                setCores={setCores}
                cores={cores}
                totalCores={totalCores}
                blocksInput={blocksInput}
                setBlocksInput={setBlocksInput}
                specs={specs}
              />
    
              <hr />
              <LocationClusterSection/>

              <hr />
   
              <Clustercapacity    specs={specs} ref={replicaRef} />
              <hr/>
              <Form.Group className="m-0 tabsSelection softwareSelectionPart ">
   
                <HeadingHS title="Databases" />

<InstanceFormDatabase databasesList={databasesList} setDatabasesList={setDatabasesList} marketplaceStateid={marketplaceStateid} setMarketPlaceStateId={setMarketPlaceStateId} setSelectOsHandler={setSelectOsHandler} selectOsDB={selectOsDB} setSelectMarketPlace={setSelectMarketPlace} setSelectOsDB={setSelectOsDB} handleOsClickDB={handleOsClickDB} setMarketPlacesItemId={setMarketPlacesItemId}  />
              </Form.Group>
    
              <hr />
              <RootPasswordCFSection
                passwordNotMatchErr={submitErrors.passwordNotMatchErr}
                passwordNotValid={submitErrors.passwordNotValid}
                confirmPasswordErr={submitErrors.confirmPasswordErr}
                rootPasswordErr={submitErrors.rootPasswordErr}
                ref={createRootPasswordRef}
                setRootPasswordAgain={setRootPasswordAgain}
                rootPasswordAgain={rootPasswordAgain}
                setRootPassword={setRootPassword}
                rootPassword={rootPassword}
              />
              <hr />
             
              <hr />
              <IPCFSection
                ref={privateIpRef}
                privateIpErr={submitErrors.privateIpErr}
                setPrivateKey={setPrivateKey}
                privateKey={privateKey}
              />
              <SpacerHS_TWO />
    
              {/* <hr />
    
              <CodeCommandSection /> */}
             <hr />
          {/* <Tenure blockidStage={blockidStage} summaryPrice={summaryPrice} setSummaryPrice={setSummaryPrice} blocksInput={blocksInput} tenure={tenure} setTenure={setTenure} /> */}

          <ClusterTenure  blockidStage={blockidStage} summaryPrice={summaryPrice} setSummaryPrice={setSummaryPrice} blocksInput={blocksInput} tenure={tenure} setTenure={setTenure} />

            {/* for mobile */}
            <Card className="d-lg-none mt-4 d-block pb-100">
            <Card.Body className="d-flex flex-column displaycreatefooterform">
              <div className="d-flex  justify-content-between w-100 align-items-center">
                <div>
                  <ChangeInputControl
                    onChangeBlocksHandler={onChangeBlocksHandler}
                    value={blocksInput}
                    minus={MINUS_BTN}
                    plus={PLUS_BTN}
                    clickHandler={blocksHandler}
                  />
                </div>
                <div>
                  <div className="pb-2">Total:</div>
                  <div style={{ color: "#37B5AE" }}>
                    {/* <span style={{ fontSize: "35px" }}> <JustCurrencyFC name={currencyname} /> {blocksInput * 20}</span>{" "}
                    <span style={{ fontSize: "13px" }}>/month</span>{" "} */}
                    {
                      summaryPrice.price && summaryPrice.tenure && <React.Fragment>
                        <span>{currencyname && <CurrencyFC name={currencyname} val={displayPrice} />}</span>
                        {/* <span>{currencyname && getCurrencySymbol(currencyname, summaryPrice.price)}</span> */}
                        <span>/{summaryPrice.tenure}</span>
                      </React.Fragment>
                    }
                  </div>
                </div>
              </div>
              <div className="text-center py-1">
                <button
                  disabled={false}
                  style={{ padding: "15px 100px" }}
                  type="button"
                  onClick={handleFormClick}
                  className="btn bg-primary text-white"
                >
                  Deploy
                </button>
              </div>
            </Card.Body>
          </Card>
          {/* desktop */}
          <StickyFooter>
            <div className="d-flex d-gap-40 align-items-center ">
              <div className="d-flex d-gap-5 align-item-center justify-content-center">
                <div className=" d-flex justify-content-center align-items-center"><span>Blocks Qty:</span> </div>
                <ChangeInputControl
                  onChangeBlocksHandler={onChangeBlocksHandler}
                  value={blocksInput}
                  minus={MINUS_BTN}
                  plus={PLUS_BTN}
                  clickHandler={blocksHandler}
                />
              </div>
              <div className="d-flex d-gap-5 align-item-center justify-content-center">
                <div className="pb-2 ">Total:</div>
                <div className="font-w500" style={{ color: "#37B5AE" }}>
                  {
                    summaryPrice.price && summaryPrice.tenure && <React.Fragment>
                      <span>{currencyname && <CurrencyFC name={currencyname} val={displayPrice} />}</span>
                      {/* <span>{currencyname && getCurrencySymbol(currencyname, summaryPrice.price)}</span> */}
                      <span>/{summaryPrice.tenure}</span>
                    </React.Fragment>
                  }
                </div>
              </div>
            </div>
            <div className="d-none">
              <div className="">
                <span>Blocks Qty:</span>
                <span>Total:</span>
              </div>
              <div>
                <div>
                  <ChangeInputControl
                    onChangeBlocksHandler={onChangeBlocksHandler}
                    value={blocksInput}
                    minus={MINUS_BTN}
                    plus={PLUS_BTN}
                    clickHandler={blocksHandler}
                  />
                </div>
                <div className="">
                  <div style={{ color: "#37B5AE" }}>
                    <span>{blocksInput * 20}</span>{" "}
                    <span>/month</span>{" "}
                  </div>
                </div>
              </div>
            </div>
            {<button
              style={{ padding: "15px 100px" }}
              type="submit"
              className="btn hsButton-bg text-white hs_borderRadius-4"
              disabled={deployLoading}
            >
              {!deployLoading && 'Deploy'}
              {
                deployLoading && <React.Fragment>
                  <div class="spinner-border" role="status">

                  </div> Deploy
                </React.Fragment>
              }
            </button>}

          </StickyFooter>
            
            </Form>
    
            <AlertComponent
              show={show}
              color="danger"
              text="Form must be fully filled before creating."
            />
          </div>
          <AlertChangeLocation />
          {/* <DeployModalInstances price={summaryPrice.price} setDeployLoading={setDeployLoading} /> */}
          <DepolyPopup submitHandler={submitHandler} totalPrice={displayPrice} renderCustomHTML={renderCustomHTMLDeploy} summaryMachineInfo={summaryMachineInfo}  closeDeployModal={closeDeployModal} deployOpen={deployOpen} />
        </React.Fragment>
      );
}

const mapStatesToProps = ({ authAlertReducer, auth, PaymentInfoReducer }) => {
    return {
  
      isShowPayment: PaymentInfoReducer?.paymentData?.defaultaddressid,
      currencyname: PaymentInfoReducer?.paymentData?.currencyname
    };
  };
  


export default connect(mapStatesToProps)(withRouter(ClusterForm));