import React from 'react'
import { SEVERNAME } from '../../../../../../API_ENDPOINTS/API_ENDPOINTS';
import EmptyBee from '../../../../hireserver/components/Empty/EmptyBee';
import HeadingHS from '../../../../hireserver/controls/HeadingHS';
import MiniCard from '../../../../hireserver/SharedComponents/MiniCard/MiniCard';
import SpacerHS_TWO from '../../../../hireserver/Spaces/SpacerHS_TWO';
import DropdownOs from '../databases/DropdownOs';
import RenderOs from '../databases/RenderOs';

function MarketPlacesInstances({ marketPlacesList, marketPlacesItemId, setSelectOsDB, handleOsClickMarket, setSelectOsHandler, setMarketPlacesItemId, setMarketPlaceStateId, setSelectMarketPlace, selectOsMarketPlace }) {
  // React.useEffect(function (){
  //   console.log(marketPlacesList,"djasdklasdlaskldasjdkla hellooooooooooooooooooooooooooooooooooooooo");
  // },[marketPlacesList]);

  function clickHandler(e, a, osfilename, marketplaceid, OSImage, groupname) {
    let defaultOs = null;

    for (let i = 0; i < marketPlacesList.length; i++) {
      let currItem = marketPlacesList[i];
      if (currItem.marketplaceid === marketplaceid) {
        setMarketPlacesItemId(currItem);
        const osData = JSON.parse(currItem?.osobj);
        defaultOs = osData[0];

      }
    }



    if (setSelectOsHandler) {
      setSelectOsHandler({
        name: "",
        type: "win10",
        osfilename: '',
        idValue: '0',
        image: '',
        groupname: ''
      });
    }

    // if (selectOsDB.name == '') {

    // }

    if (setSelectOsDB) {
      setSelectOsDB({


        name: "",
        type: "win10",
        osfilename: '',
        id: '0',
        image: '',
        groupname: ''
      });
    }
    if (setMarketPlaceStateId) {
      setMarketPlaceStateId(0);
    }

    setSelectMarketPlace({


      name: defaultOs?.OperatingSystemName,
      type: "win10",
      osfilename: defaultOs?.OSFileName,
      id: defaultOs?.OperatingSystemId,
      image: defaultOs?.OsIconPath,
      groupname: defaultOs?.GroupName
    })


  }


  function preProcessGroup(data) {
    //// console.log(data,'ossssssssssssssssssssssssssssss');
    let processData = [];
    let hashObj = {};

    for (let i = 0; i < data?.length; i++) {
      let currItem = data[i];
      if (!hashObj[currItem.appname]) {
        hashObj[currItem.appname] = [currItem];
      } else {
        hashObj[currItem.appname].push(currItem);
      }
    }


    return hashObj;
  }

  function getMarketPlacesHTML() {

    if (marketPlacesList?.length === 0) {
      return <EmptyBee message="No Apps and Panels" />;
    }

    const processData = preProcessGroup(marketPlacesList);

    const htmlDB = [];
    for (let DB in processData) {
      const { appname, icon, os, os_text, osobj, type } = processData[DB][0];
      const osArr = os.split('|');
      const osTextArr = os_text.split('|');
      const ids = [];
      let checkIs = false;
      // for (let i = 0; i < osArr.length; i++) {
      //     ids.push({ nameV: osTextArr[i], id: osArr[i] });
      // }

      const DBVersionArr = processData[DB];
      for (let i = 0; i < DBVersionArr.length; i++) {
        ids.push({ nameV: DBVersionArr[i]['appversion'], id: DBVersionArr[i]["marketplaceid"] });

        if (marketPlacesItemId?.["marketplaceid"] == DBVersionArr[i]?.marketplaceid) {
          checkIs = true;
        }
      }


      htmlDB.push(<MiniCard
        isChecked={checkIs}
        key={icon}
        groupname={appname}
        name={"databases"}
        dataName={appname}
        isDropdown
        dropDownList={ids}
        id={DBVersionArr[0]["marketplaceid"]}
        osfilename={appname}
        otherattr={{ 'data-type': type }}
        cardName={appname}
        handler={clickHandler}
        OSImage={SEVERNAME + `/${icon}`}
        nextname={null}
      />)
    }
    return htmlDB;
  }

  return (
    <React.Fragment>
      <div className='text-center row d-gap-15 m-0 justify-content-lg-start justify-content-center'>
        {getMarketPlacesHTML()}

      </div>
      {/* {marketPlacesItemId != 0 && <React.Fragment>
                <SpacerHS_TWO />
                <div>
                    Supported operating systems
                </div>
                <SpacerHS_TWO />
            </React.Fragment>} */}
      <div>
        <React.Fragment>
          {marketPlacesList?.length ?
            <React.Fragment>
              <SpacerHS_TWO />

              <HeadingHS as="h3 text-primary" title="Choose a Distribution" />
              <SpacerHS_TWO />
              <DropdownOs handler={handleOsClickMarket} itemData={marketPlacesItemId} selectOsDB={selectOsMarketPlace} />
            </React.Fragment> : null}
        </React.Fragment>

      </div>
      {/* <RenderOs handleOsClickDB={handleOsClickMarket} selectOsDB={selectOsMarketPlace}  itemData={marketPlacesItemId}  /> */}
    </React.Fragment>
  )
}

export default MarketPlacesInstances;