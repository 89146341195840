import React, { Fragment } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Firewallrules from "./Firewallrules";
import Firewallinstance from "./Firewallinstance";

const Firewalldetails = () => {
  return (
    <Fragment>
         {/* <div className='row justify-content-between align-items-center px-3 pb-2'>
              <div className='fs-18 font-w600 text-dark-0 pb-2'><span className="font-w500 text-black-50">Firewalls/ </span><span>test</span></div>
              <div className='d-flex justify-content-around'>
                <div>
                <button className="border-0 bg-white font-w500 text-blue fs-16 pt-1">Docs</button>
                </div>
              </div>
            </div> */}
      <div className="running_tabs">
        <Tabs
          defaultActiveKey="rules"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="rules" title="Rules">
            <Firewallrules />
          </Tab>
          <Tab eventKey="instance" title="Instance">
            <Firewallinstance/>
          </Tab>
        </Tabs>
      </div>
    </Fragment>
  );
};

export default Firewalldetails;
