import React from 'react'
import {Alert,Button} from 'react-bootstrap';

export default function AlertComponent({show,color,text}) {
  return (
    <div style={{    Margin: 'auto',
      position: 'fixed',
      bottom: '100px',
     
      right: '0',
      marginLeft: 'auto',
      marginRight: 'auto'
      }} className='col-sm-12 col-md-4'>
      <Alert show={show}  className="text-center " variant={color}>
      {text}
  </Alert>
    </div>

  )
}
