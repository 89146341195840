import React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import HeadingHS from './HeadingHS';

export default function LabelInput({displayOne=false, heading, id,placeholder,inputValue,setInputValue,type,headingFont="h4" }) {
    let iType = type === undefined ? 'text' : type;
    function OnInputHandler(e){
        setInputValue(e.target.value)
    }
    return (
        <div>
            <div className={`py-2 ${displayOne && 'd-none'}`}>
                <label htmlFor={id}  className="">
                    <HeadingHS title={heading} as={headingFont} />
                </label>
            </div>
            <div className='form-group pb-0 mb-0'>
                <input
                className='form-control labelInputComponent form-control-lg'
                    type={iType}
                    placeholder={placeholder}
                    value={inputValue}
                    onInput={OnInputHandler}
                    style={{borderRadius:'4px'}}
                   
                />
            </div>

        </div>
    )
}
