import getInstancesListService from "../../../services/InstancesServices/InstancesListService";
import { CLOSE_STOP_HS_MODAL_BAREMETAL, SELECT_ALL_LIST_ITEM_BAREMETAL, CURR_SELECT_ITEM_BAREMETAL, DELETE_CONFIRM_MODAL_BAREMETAL, FINISH_BAREMETAL_LOADING, OPEN_STOP_HS_MODAL_BAREMETAL, REBOOT_HS_HIDEMODAL_BAREMETAL, REBOOT_HS_SHOWMODAL_BAREMETAL, SET_BAREMETAL, SHOW_DELETE_CONFIRM_MODAL_BAREMETAL, START_BAREMETAL_LOADING, UN_SELECT_ALL_LIST_ITEM_BAREMETAL, SELECT_CURRENT_LIST_ITEM_BAREMETAL, UN_SELECT_CURRENT_LIST_ITEM_BAREMETAL } from "../../types/BareMetalTypes";
import produce from "immer";
import cloneDeep from 'lodash/cloneDeep';
import getBareMetalListService from "../../../services/BareMetalServices/BareMetalListService";
import AppError from "../../../services/ServiceErrorHandlers/CustomError";
import { handlerAlert } from "../../../jsx/components/Dashboard/Instances/InstancesListComponents/DropDownInstancesList";
import { CONFIGURE_STAGE, CREATE_STAGE, PREDELETE_STAGE, PRESTART_STAGE, PRESTOP_STAGE, REBOOT_STAGE, REFRESH_TIMER, REINSTALL_STAGE } from "../../../utils/Emuns";
import StaticTimer from "../../../utils/StaticTimer";
import StaticInstancesList from "../../../utils/StaticsClasses.js/StaticInstancesList";
import StaticBareMetalList from "../../../utils/StaticsClasses.js/StaticBareMetalList";

export function setBareMetalList_action(payload) {
    return {
        type: SET_BAREMETAL,
        payload
    }
}

export function startLoadingBareMetal() {
    return {
        type: START_BAREMETAL_LOADING
    }
}

export function finishLoadingBareMetal() {
    return {
        type: FINISH_BAREMETAL_LOADING
    }
}

export function putCurrSelectItemBareMetal(payload) {
    return {
        type: CURR_SELECT_ITEM_BAREMETAL,
        payload
    }
}

export function closeStopBareMetal() {
    return {
        type: CLOSE_STOP_HS_MODAL_BAREMETAL
    }
}

export function openStopBareMetal() {
    return {
        type: OPEN_STOP_HS_MODAL_BAREMETAL
    }
}

export function openDeleteModalBareMetal() {
    return {
        type: SHOW_DELETE_CONFIRM_MODAL_BAREMETAL
    }
}

export function closeDeleteModalBareMetal() {
    return {
        type: DELETE_CONFIRM_MODAL_BAREMETAL
    }
}

export function closeRebootModalBareMetal() {
    return {
        type: REBOOT_HS_HIDEMODAL_BAREMETAL
    }
}

export function openRebootModalBareMetal() {
    return {
        type: REBOOT_HS_SHOWMODAL_BAREMETAL
    }
}

// export function formatDataFun(baseState){

//     const nextState = produce(baseState, draftState => {
//         for (let i = 0; i < draftState.length; i++) {

//             draftState[i]['loadingInfo'] = {
//                 loading: false,
//                 statusId: draftState[i].statusid,
//                 text: '',
//                 color: ''
//             }
//         }
//  // console.log(nextState,'nextStasaste')
//         return draftState.filter(function (item,i){
//             return item.machinetype == 1;
//         });
//     });
//     // console.log(nextState,'nextState')
//     return nextState;
// }

export function getBareMetalList(payload) {
    return async (dispatch) => {
        try {

            const { data } = await getBareMetalListService(1, dispatch);
            if (data.errors != null) {
                throw new AppError(data.errors[0].message, 999);
            }
            let fullData = [];
            if (data.result != '') {
                fullData = JSON.parse(data.result);
            }
       

            let newArrList = [];
            let isNeedToSetTimer = false;
            let liteActions = false;
            for (let i = 0; i < fullData.length; i++) {
                fullData[i]['loadingInfo'] = {
                    loading: false,
                    statusId: fullData[i].statusid,
                    text: '',
                    color: '',
                    prev: "",
                }
                fullData[i]['code'] = fullData[i].vmid;
                // fullData[i]['code'] = fullData[i].VMID;
                fullData[i]['machineid'] = fullData[i].machineid;
                //fullData[i]['machinename'] = fullData[i]['machinename'];
                fullData[i]['regionname'] = fullData[i]['branchname'];
                //fullData[i]['machinetype'] = '2';

                if(fullData[i].refstatusid === PRESTART_STAGE ||  fullData[i].refstatusid === CONFIGURE_STAGE || fullData[i].refstatusid === REINSTALL_STAGE || fullData[i].refstatusid === CREATE_STAGE || fullData[i].refstatusid === PRESTOP_STAGE || fullData[i].refstatusid === PREDELETE_STAGE || fullData[i].refstatusid === REBOOT_STAGE){
                    
        
                    liteActions = true;
                    isNeedToSetTimer = true;
                    fullData[i]['consoleDisabled'] = 'viewConsoleDisable';
                }

                fullData[i]['isChecked'] = false;

                newArrList.push(fullData[i]);

            }

            const isUnMounted = StaticBareMetalList.isUnMounted;
            const TIMER = liteActions ? REFRESH_TIMER : 30000;
            if(isNeedToSetTimer && !isUnMounted){
                if(StaticTimer.TimerNumber != null){
                     clearTimeout(StaticTimer.TimerNumber)
                     let newTimer = setTimeout(function (){
                        // console.log("running")
                        if(!StaticBareMetalList.isUnMounted){
                            dispatch(getBareMetalList());
                        }
                    },TIMER)
                    StaticTimer.TimerNumber = newTimer;
                }else{
                    let newTimer = setTimeout(function (){
                        // console.log("running noneed")
                        if(!StaticBareMetalList.isUnMounted){
                            dispatch(getBareMetalList());
                        }
                    },TIMER);
                    StaticTimer.TimerNumber = newTimer;
                }
            }




            dispatch(setBareMetalList_action({
                data: newArrList,
                error: null
            }));
            dispatch(finishLoadingBareMetal())

        } catch (error) {
            // handlerAlert('danger', error.message, 'danger')
            // dispatch(setBareMetalList_action({
            //     data: [],
            //     error: error
            // }))

            // handlerAlert('danger', error.message, 'danger')
            dispatch(setBareMetalList_action({
                data: [],
                error: error.message
            }))
            dispatch(finishLoadingBareMetal())
        }
    };
}

export function selectAllBareMetalItem(payload) {
    return (dispatch, getState) => {
        const { bareMetalList: { data } } = getState().bareMetal;
        let newData = cloneDeep(data);
        const needItemLength = Object.getOwnPropertyNames(payload.data);
        let itemIndex = 0;

        let selectedData = {};

        for (let i = 0; i < newData.length; i++) {
            //let currItem = newData[i];
            if (itemIndex > needItemLength) break;
            if (payload.data[newData[i]['code']]) {
                newData[i].isChecked = true;
                itemIndex++;
                selectedData[newData[i]['code']] = newData[i];

            }
        }


        dispatch(selectAllBareMetalItemsAction(selectedData));
        dispatch(setBareMetalList_action({
            data: newData,
            error: null
        }));
    }
}

export function unCheckAllBareMetal() {
    return (dispatch, getState) => {
        const { bareMetalList: { data } } = getState().bareMetal;
        const newData = produce(data, draft => {
            for (let i = 0; i < draft.length; i++) {

                draft[i].isChecked = false;
            }

            return draft;
        });

        // let newData = [...data]
        // for (let i = 0; i < newData.length; i++) {
        //     newData[i].isChecked = false;
        // }

        dispatch(unCheckAllBareMetalAction());
        dispatch(setBareMetalList_action({
            data: newData,
            error: null
        }))
    }
}

export function selectAllBareMetalItemsAction(payload) {
    return {
        type: SELECT_ALL_LIST_ITEM_BAREMETAL,
        payload
    }
}

export function unCheckAllBareMetalAction() {
    return {
        type: UN_SELECT_ALL_LIST_ITEM_BAREMETAL,
    }
}

export function selectCurrentBareMetalListItem(payload) {
    // return {
    //     type:SELECT_CURRENT_LIST_ITEM_IN,
    //     payload
    // }

    return (dispatch, getState) => {

        const { selectItems } = getState().bareMetal.selectedListItems;
        const { bareMetalList: { data } } = getState().bareMetal;
        //    const newData = produce(data,draft => {

        //     for(let i=0;i<draft.length;i++){
        //         if(draft[i].code == payload.code){
        //             draft[i].isChecked = true;
        //         }
        //     }
        //     return draft;
        //    });

        // let newData = [...data];
        // for (let i = 0; i < newData.length; i++) {
        //     if (newData[i].code == payload.code) {
        //         newData[i].isChecked = true;
        //     }
        // }

        let newData = produce(data, draft => {
            for (let i = 0; i < draft.length; i++) {
                if (draft[i].code == payload.code) {
                    draft[i].isChecked = true;
                }
            }
        });



        dispatch(setBareMetalList_action({
            data: newData,
            error: null
        }))
        // // console.log(selectItems)
        let nesselectItems = cloneDeep(selectItems)
        // // console.log(nesselectItems, '.nesselectItems')
        nesselectItems[payload.code] = payload.fullData;
        // // console.log(newSelectItem)
        dispatch(selectDispatchBareMetal(nesselectItems))
    }
}

export function unCheckCurrentBareMetalListItem(payload) {
    const { code, fullData } = payload;

    return (dispatch, getState) => {
        const { bareMetalList: { data } } = getState().bareMetal;

        const { selectItems } = getState().bareMetal.selectedListItems;
        let newObj = produce(selectItems, draft => {
            let newOb = {};
            for (let item in draft) {
                if (item != code) {
                    newOb[item] = draft[item];
                }
            }

            return newOb;
        })
        let newItems = produce(data, draft => {
            for (let i = 0; i < draft.length; i++) {
                if (draft[i].code === code) {
                    draft[i].isChecked = false;
                    break;
                }
            }
        });

        dispatch(setBareMetalList_action({
            data: newItems,
            error: null
        }))
        dispatch(unSelectDispatchBareMetal(newObj))
    }
}

export function selectDispatchBareMetal(payload) {
    return {
        type: SELECT_CURRENT_LIST_ITEM_BAREMETAL,
        payload
    }
}

export function unSelectDispatchBareMetal(payload) {
    return {
        type: UN_SELECT_CURRENT_LIST_ITEM_BAREMETAL,
        payload
    }
}