import { getAssestsURL, getSEVERNAME } from "../API_ENDPOINTS/API_ENDPOINTS";
import { handlerAlert } from "../jsx/components/Dashboard/Instances/InstancesListComponents/DropDownInstancesList";
import isEmail from 'isemail';
import uuid from "react-uuid";
import cronParser from 'cron-parser';
import { CRON_ENABLE, CRON_TIME } from "./Emuns";


export function unixDateToNormalDate(date) {
  let newData = new Date(date * 1000).toLocaleDateString("en-US");
  newData = `${newData}`.split('/');
  newData = `${newData[1]}/${newData[0]}/${newData[2]}`
  return newData;
}

export function unixDateToNormalTime(unix_timestamp) {
  let date = new Date(unix_timestamp * 1000);
  let hours = date.getHours();
  let minutes = "0" + date.getMinutes();
  let seconds = "0" + date.getSeconds();
  let formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
  return formattedTime;
}

export function checkResData(resData, emptyOkay = false) {

  if (!resData) return false;
  if (resData.data === null) {
    handlerAlert("danger", "No response Data", "danger")
    // console.log(resData);
    return false;
  }

  // if (resData.data.result === '' || resData.data.result === null) {
  //   handlerAlert("danger", "Response result is empty", "danger")
  //   // console.log(resData);
  //   return false;
  // }
  const isJsonString = isJsonStringHelper(resData.data.result);
  if (isJsonString === false) {
    if (emptyOkay === false) {
      handlerAlert("danger", "Response result is empty", "danger");
    }
    return false;
  }

  // let parseData = JSON.parse(resData.data.result);

  if (resData.data.errors?.length >= 1) {
    handlerAlert("danger", resData.data.errors[0], "danger");
    // console.log(resData.data.errors);
    return false;

  }

  if (resData.data.code != "0") {
    handlerAlert("danger", resData.data.message, "danger");
    return false;
  }

  return true;

}


export function checkPasswordValidHelper(password) {
  let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
  return Boolean(regex.test(password));
}

export function checkEmailValidHelper(email) {
  // // console.log(email,'emailemail')
  let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
  // // console.log(email.match(mailformat),'email.match(mailformat)email.match(mailformat)')
  //'';
  return isEmail.validate(email, { minDomainAtoms: 2 });
  // return Boolean(email.match(mailformat));
}


export function blockInvalidChar(e) {
  if (['e', 'E', '+', '-'].includes(e.key)) {
    e.preventDefault();
  }

  if (e.keyCode >= 65 && e.keyCode <= 90) {
    e.preventDefault();
  }


}
export function decodeBase64(text) {
  let base64ToString = Buffer.from(text, "base64").toString()?.replaceAll('\u0000', '');

  return base64ToString;
}

export function formatDataSelect2(data, valueField, labelField) {

  const arr = [];
  for (let i = 0; i < data.length; i++) {
    arr.push({
      value: data[i][valueField],
      label: data[i][labelField],

    })
  }
  return arr;
}


export function isJsonStringHelper(str) {

  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function isSafeCronTosave(cronString){
  try{
    if(! window['cronstrue']) return;

     window['cronstrue'].toString(cronString);
     return true;
  }catch(err){
    return false;
  }
}

export function removeCronTokens(params=[]){
  let vals = [];
  let data = params.filter((item,i)=>{
    const{key,value} = item;
    if(key!=CRON_ENABLE && key != CRON_TIME) return item;
   
    if(key==CRON_ENABLE){
      vals[0] = value
    }else if(key == CRON_TIME){
      vals[1] = value
    }
  });

  return [ data,
    vals];
}

export function isValidCron(cronExpression) {
  try {
    cronParser.parseExpression(cronExpression);
    return true;
  } catch (err) {
    return false;
  }
  // const cronRegex = /^(\*(\/[1-5])?|[0-5]?[0-9]|\*(\/[1-2]?[0-9])?|[0-2]?[0-3]|\*(\/[1]?[0-9])?|[1-2]?[0-9]|3[01]|\*(\/1?[0-2])?|[1]?[0-9]|1[0-2]|\*(\/[0-7])?|[0-6])$/;
  // const fields = cronExpression.split(' ');

  // // Basic check for 5 fields
  // if (fields.length !== 5) {
  //     return false;
  // }

  // for (const field of fields) {
  //     if (!cronRegex.test(field)) {
  //         return false;
  //     }
  // }

  // return true;
}

export function cronToHuman(cronString) {
  //if(cronString=='custom') debugger;
  if(isValidCron(cronString)){
    if(! window['cronstrue']) return;

    return window['cronstrue'].toString(cronString);
  }else{
    return "";
  }
 

}

function removeTokenBrackets(item) {
  let temp = item.replaceAll("{{", "");
  temp = temp.replaceAll("}}", "")
  return temp;
}

export function getTokenFilter(tokensStr) {
  const tokenArr = tokensStr.match(/\{{.+?\}}/g);
  // const newFilterArr= tokenArr.map(function (item,i){
  //   return {name:removeTokenBrackets(item),id:uuid()};
  // });
  if (!tokenArr) {
    return {};
  }
  const newFilterArr = {};
  for (let i = 0; i < tokenArr.length; i++) {
    const item = tokenArr[i];
    newFilterArr[item] = {
      name: removeTokenBrackets(item),
      id: uuid(),
      val: "",
      error: {
        isError: false,
        message: ''
      }
    }
  }

  return newFilterArr;
}

export function getNextDate(dateStr, type) {
  // Parse date
  const parts = dateStr.split('/');
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // months are 0-indexed in JS
  const year = parseInt(parts[2], 10);

  const date = new Date(year, month, day);

  // Increment based on type
  if (type === 'month') {
    date.setMonth(date.getMonth() + 1);
  } else if (type === 'year') {
    date.setFullYear(date.getFullYear() + 1);
  } else {
    throw new Error('Invalid type. Use either "month" or "year".');
  }

  // Format and return date
  const formattedDate = [
    ('0' + date.getDate()).slice(-2),
    ('0' + (date.getMonth() + 1)).slice(-2), // Increment by 1 to get 1-indexed month
    date.getFullYear()
  ].join('/');

  return formattedDate;
}










export function getCurrentDate(num = 1, yearNum = 0) {
  let currentDate = new Date()
  let day = currentDate.getDate()
  let month = currentDate.getMonth() + num
  let year = currentDate.getFullYear() + yearNum;
  let news = `${day}/${month}/${year}`
  return `${day}/${month}/${year}`;
}

export function getNMonthDate(n) {
  return getCurrentDate(n + 1);
}

export function getNYearDate(n) {
  return getCurrentDate(1, n)
}


// https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
export function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}

export function percentageHelper(percent, total) {

  return ((percent / 100) * total).toFixed(2)
}

export function calcPertanageWhichToWhichHelper(iss, off) {
  return (iss / off) * 100
}

export function mbToGbHelper(num) {
  return num / 1000;
}

export function containsSpecialChars(str) {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(str);
}

export function containsSpecialCharsExceptDotAndDash(str){
  const specialChars = /^(?!.*[-.]{2})[A-Za-z0-9-.]+$/;
  // const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return !specialChars.test(str);
}

export const isUpperCase = (string) => /^(?=.*[A-Z])/.test(string);
export const isLowerCase = (string) => /^(?=.*[a-z])/.test(string);
export const isNumberContains = (string) => /\d/.test(string);



export function hasWhiteSpace(s) {
  return (/\s/).test(s);
}


// graphs

export function byteToGbHelper(num) {
  return (num / 1e+9).toFixed(2);
}

export function mbToGb(mbValue) {
  const ONE_MB = 0.0009765625;
  return mbValue * ONE_MB;
}

export function getDiskConvertHelper(value) {
  return (value / 1000);
}

function diskHelperUnit(value) {
  const M = 1000000;
  const B = 1000000000;
  const T = 1000000000000;
  if (value < 1000) {
    return {
      val: value,
      sym: null
    };
  } else if (value >= 1000 && value < M) {
    return {
      val: getDiskConvertHelper(value),
      sym: 'K'
    };
  } else if (value >= M && value < B) {
    return {
      val: getDiskConvertHelper(value),
      sym: 'M'
    };
  } else if (value >= B && value < T) {
    return {
      val: getDiskConvertHelper(value),
      sym: 'B'
    };
  } else {
    return {
      val: getDiskConvertHelper(value),
      sym: 'T'
    };
  }
}

function ramHelperUnit(value, index) {

  if (index == 0) {
    return {
      val: value,
      sym: 'i'
    }
  } else {
    return {
      val: value,
      sym: 'Gi'
    }
  }


}

export function getUnitsByClass(value, unit, index) {

  switch (unit) {
    case ('disk'): {
      return diskHelperUnit(value);
    }
    case 'ram': {
      return ramHelperUnit(value, index);
    }
    case 'net': {
      return diskHelperUnit(value);
    }
  }

}


export function imageReplaceHashHelper(imgSrc) {
  if (imgSrc?.includes(getAssestsURL())) {
    return imgSrc;
  }
  let fullPath = getAssestsURL() + '/' + imgSrc?.replace('~', '');

  return fullPath;
}

export function isValidVMName(name) {
  if (!name) {
    return false;
  }

 // Ensure the name starts and ends with alphanumeric characters
 if (!/^[a-zA-Z0-9].*[a-zA-Z0-9]$/.test(name)) {
  return false;
}

// Ensure no two consecutive special characters
if (/[^a-zA-Z0-9]{2,}/.test(name)) {
  return false;
}

return true;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getObjKeyLengthHelper(obj) {
  return Object.keys(obj).length;
}

// export function addPreFixServerName(imgSrc){

// }

// profile

export function extractNumberAfterServerId(errorMessage) {
  const regex = /id:(\d+)/;
  const match = errorMessage.match(regex);
  if (match) {
    const numberAfterId = parseInt(match[1], 10);
    return numberAfterId;
  } else {
    return null; // Return null if the number after "id:" is not found in the error message.
  }
}




export async function fileToGetBase64(event) {
  let file = event.target.files[0];
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // Wait till complete
    reader.onloadend = function () {
      let base64String = reader.result
        .toString()
        .replace(/^data:.+;base64,/, "");
      let fileReq = {
        FileData: base64String,
        FileName: file.name.split(".").slice(0, -1).join("."),
        FileType: file.name.split(".").pop(),
        ByteData: null,
      };
      resolve(fileReq);
    };
    // Make sure to handle error states
    reader.onerror = function (e) {
      reject(e);
    };
  });
}



export async function getMulFileToGetBase64(event) {
  let files = event.target.files;
  let filePromise = [];
  for (let i = 0; i < files.length; i++) {
    let currFile = files[i];
    let currFilePromise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(currFile);
      // Wait till complete
      reader.onloadend = function () {
        let base64String = reader.result
          .toString()
          .replace(/^data:.+;base64,/, "");
        let fileReq = {
          FileData: base64String,
          FileName: currFile.name.split(".").slice(0, -1).join("."),
          FileType: currFile.name.split(".").pop(),
          RefId: 0,
          "ByteData": null,
          "FilePath": ""
        };
        resolve(fileReq);
      };
      // Make sure to handle error states
      reader.onerror = function (e) {
        reject(e);
      };
    });
    filePromise.push(currFilePromise)
  }

  return Promise.all(filePromise);
}

export const stringToHTMLHelpers = function (str) {
  var parser = new DOMParser();
  var doc = parser.parseFromString(str, 'text/html');
  return doc.body;
};

export function checkIsObjectThere(obj, name) {
  if (typeof obj != 'object') return false;
  const isThere = obj.hasOwnProperty(name)

  if (isThere) {
    return (isThere === '' || isThere === null) ? false : true;
  }

  return false;
}

// export const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();


