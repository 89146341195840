import React from 'react';
import ViewMachine from '../../../hireserver/controls/ViewMachine';
import CustomDropDown from '../../../hireserver/components/CustomDropDown';
import HsTable from '../../../hireserver/components/LibTable/HsTable';

export default function ListDatabases() {
  const [data, setData] = React.useState(
    {
      columns: [
        { label: "Machine", field: "machine", sort: 'asc', width: 100 },
        { label: "IP Address", field: "ipAddress", sort: 'asc' },
        { label: "Location", field: "location", sort: 'asc' },
        { label: "Console", field: "console", sort: 'asc' },
        { label: "Status", field: "status", sort: 'asc' },
        { label: "Action", field: "dropdown", sort: 'asc' },
      ],
      rows: [
        {
          "machine": "Wendall Gripton",
          "ipAddress": "wg@creative.org",
          "location": "Male",
          "console": <ViewMachine handler={handleClick} title="View" />,
          "status": 'Running',
          "dropdown": <CustomDropDown items={[{title:'Run',handler:handleClick}, {title:'Stop',handler:handleClick}]}  ><svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" >
              <rect x="0" y="0" width="24" height="24"></rect>
              <circle fill="#000000" cx="5" cy="12" r="2"></circle>
              <circle fill="#000000" cx="12" cy="12" r="2"></circle>
              <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
          </svg></CustomDropDown>
        },
        {
          "machine": "samuel Mollitt",
          "ipAddress": "smol@github.io",
          "location": "Male",
          "console": <ViewMachine handler={handleClick} title="View" />,
          "status": 'Stop',
          "dropdown": <CustomDropDown  items={[{title:'Run',handler:handleClick}, {title:'Stop',handler:handleClick}]}  ><svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" >
              <rect x="0" y="0" width="24" height="24"></rect>
              <circle fill="#000000" cx="5" cy="12" r="2"></circle>
              <circle fill="#000000" cx="12" cy="12" r="2"></circle>
              <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
          </svg></CustomDropDown>
        },

      ]
    }
  );
  function handleClick(e) {
    // console.log('click')
  }
  return (
    <div>
      <HsTable columns={data.columns} rows={data.rows} />
    </div>
  )
}
