import React from 'react'
import HsTable from '../../../../../hireserver/tables/HsTable'
import uuid from 'react-uuid';
import { cloneDeep } from 'lodash';
import AreaScriptOutput from '../AreaScriptOutput/AreaScriptOutput';
import { getSearchByScriptLogsNameService } from '../../../../../../../services/SearchTableServices/SearchTableServices';
import { getSearchByScriptLogsNameURL } from '../../../../../../../API_ENDPOINTS/API_ENDPOINTS';
import { getLogOutputByIdLogService, getLogOutputByIdLog_Page_Service } from '../../../../../../../services/InstancesServices/InstancesListService';
import { isJsonStringHelper } from '../../../../../../../utils/Helpers';
import Scriptoutputpage from '../Scriptoutputpage/Scriptoutputpage';
import { handlerAlert } from '../../../../Instances/InstancesListComponents/DropDownInstancesList';


function OutViewScriptTable({ outputList, getOutTable }) {


    const [showOutModal, setOutModal] = React.useState(false);
    const [fullData, setFullData] = React.useState(null);
    const [output, setoutput] = React.useState('');
    const [outputPage,setOutputpage] = React.useState(1);

    const [cols, setCols] = React.useState([
        {
            label: "Name",
            field: "name",
            sort: "asc",
            id: uuid(),
            attributes: { className: " col-3" },
        },
        {
            label: "Start Time",
            field: "starttime",
            sort: "asc",
            id: uuid(),
            attributes: { className: " col-3" },
        }, {
            label: "End Time",
            field: "endtime",
            sort: "asc",
            id: uuid(),
            attributes: { className: "col-3" },

        },
        {
            label: "output",
            field: "output",
            sort: "asc",
            id: uuid(),
            attributes: { className: "col-3 text-truncate px-0 mx-0 text-center" },

        },
        {
            label: "status",
            field: "status",
            sort: "asc",
            id: uuid(),
            attributes: { className: "col-3 text-truncate, " },

        }
    ]);
    const [formatData, setFormatData] = React.useState({
        cols: cols,
        rows: []
    });

    const [currScriptLogId,serCurrScriptLogId] = React.useState(null);


    function getStatus(item) {
        if (!item) return null;

        let { scriptstatus } = item;
        let text = 'Fail'
        let isSuccess = 'danger'
        if (scriptstatus == '1') {
            text = 'Inprogress'
            isSuccess = 'warning'
        } else if (scriptstatus == '2') {
            text = 'Success';
            isSuccess = 'success'
        }
        return <div className='d-flex align-items-center d-gap-10'>
            <div className={`bg-${isSuccess}`} style={{
                width: '10px',
                height: '10px',
                borderRadius: '25px'
            }}></div>
            <span>  {text}</span>
        </div>

    }

    function setTableData() {
        if (!outputList) {
            return;
        }
        const rowComps = [];

        for (let i = 0; i < outputList.length; i++) {

            const { scriptid, name, starttime, endtime, output, scriptstatus,scriptlogid,logid } = outputList[i];
debugger;
            let rowData = {
                name: name,
                // search_name: name,
                starttime: starttime,
                endtime: scriptstatus != 1 ? endtime : '',
                output: scriptstatus ? <div onClick={() => openOutputModal(scriptlogid)} className=' cursor-pointer text-center'><u className='text-primary text-center'>View</u></div> : '',
                status: getStatus(outputList[i])


            }
            // let rowData = {
            //     starttime: starttime,
            //     endtime: scriptstatus != 1 ? endtime : '',
            //     output: scriptstatus != 1 ? <div onClick={() => openOutputModal(output, outputList[i])} className=' cursor-pointer'><u className='text-primary'>View</u></div> : '',
            //     status: getStatus(innerList[i])


            // }

            rowComps.push(rowData);
        }

        setFormatData({
            cols: cloneDeep(cols),
            rows: cloneDeep(rowComps)
        });
    }

    React.useEffect(function () {
        // console.log(outputList);
        setTableData();
    }, [outputList]);

    async function openOutputModal(logid, full) {
      
        console.log(full);
        //const res = await getLogOutputByIdLogService(logid,1);
         const res = await getLogOutputByIdLog_Page_Service(logid,outputPage);
        setOutputpage(2);
      if(!res){
        handlerAlert("danger","Server Error","danger");
        return;
      }
        if(!res.data.result) return;
        let ans = JSON.parse(res.data.result);
        console.log(ans,"output");
        let resultOutput = '';
        for(let i=0;i<ans.length;i++){
            let {output} = ans[i];
            let isSafe = isJsonStringHelper(output);
            
            if(isSafe){
                output = JSON.parse(output);
                for(let j=0;j<output.length;j++){
                    resultOutput+=output[j];
                }
            }else{
                resultOutput += output;
            }
            
        }
     
        serCurrScriptLogId(logid)
        setoutput(resultOutput);
        setFullData(ans);
        setOutModal(true);
    }

   

    function closeOutputModal() {
        setOutModal(false);

    }

    async function moreHandler(page) {
     
        const res = await getOutTable(page);
        if (res) {
            return res;
        }
        return;
    }

    async function searchHandler(value,page){
   
       const res = await getSearchByScriptLogsNameService(value,page,outputList?outputList[0]?.scriptid : null);
    //    console.log(res);
    }

    return (
        <React.Fragment>
            <hr />
            <div className='text-center text-primary'>Scripts Logs</div>
            <HsTable  apiFun={getSearchByScriptLogsNameURL} searchHandler={searchHandler} paging data={formatData} moreHandler={moreHandler} />
            {/* <Scriptoutputpage/> */}
            <AreaScriptOutput outputPage={outputPage} setOutputpage={setOutputpage} currScriptLogId={currScriptLogId} fullData={fullData} output={output} handleOutputClose={closeOutputModal} showOutModal={showOutModal} />
        </React.Fragment>
    )
}

export default OutViewScriptTable