import { cloneDeep } from 'lodash';
import { MDBDataTable } from 'mdbreact';
import React from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getDueDateDataService } from '../../../../services/Profile/ProfileOrdersServices';
import { paginationLabel, TABLE_ENTIRES } from '../../../../utils/Emuns';
import { checkResData } from '../../../../utils/Helpers';
import RenewalBtn from '../../Dashboard/Instances/InstancesListComponents/RenewalBtn';
import CurrencyFC from '../../hireserver/components/Currency/CurrencyFC';
import RenewalModal from '../../Renewal/Renewalmodal/RenewalModal';
import RenewalView from '../../Renewal/RenewalView';

function DueDataHome({ data, currencyname, onlyLimit = false }) {
    const [col, setCols] = React.useState([
        {
            label: "name",
            field: "name",
            sort: "asc",

            attributes: { className: " col-3" },
        }, {
            label: "Expires on",
            field: "duedate",
            sort: "asc",
            attributes: { className: "text-center col-3" },

        },
        {
            label: "Due amount",
            field: "duedays",
            sort: "asc",
            attributes: { className: "text-center col-3" },

        },
        {
            label: "Tenure",
            field: "tenure",
            sort: "asc",

            attributes: { className: "text-center col-3" },
        },


    ]);

    const [formatData, setFormatData] = React.useState({
        columns: [],
        rows: []
    });

    const [pagging, setPagging] = React.useState(false);


    React.useEffect(function () {
debugger;
        getOrdersList(data);
        console.log(data,"data");
    }, [data]);






    async function getOrdersList(parseData) {

        let rowComps = [];

        //   setLoading(false);
        //   return;
        let sumDueAmount = 0;
        for (let i = 0; i < parseData.length; i++) {
            console.log(parseData[i],'parseData[i]');
            const { duedays, machineid, duedate, dueamount, machinename, noofblocks } = parseData[i];
            console.log(dueamount, 'dueamountdueamount');
            let rowData = {
                name: <div className='d-flex w-100 '>
                    <div className='text-primary font-w600'>{machinename}</div>
                    {/* <span className='px-1'>-</span>
                    <div className='font-14 d-flex d-gap-10'>
                    
                        <span>127.154.10.3</span>
                    </div> */}
                </div>,
                duedate: <div className='text-center'>
                    <span className='text-danger'>{duedate}</span>
                </div>,
                duedays: <div className='text-center'>
                    <CurrencyFC name={currencyname} val={parseData[i].dueamount} />
                </div>,
                tenure: <div className='text-center'>
                    <RenewalView item={parseData[i]} />
                </div>,
                search_name: machinename,


            }
            sumDueAmount += dueamount;
            rowComps.push(rowData);
        }


        setFormatData({
            columns: cloneDeep(col),
            rows: cloneDeep(rowComps)
        });





    }

    function onSearchHandler(value) {
        const newData = cloneDeep(formatData);
        setFormatData(newData);
    }

    function onPageChangeHandler({ activePage, pagesAmount }) {
        const newData = cloneDeep(formatData);
        setFormatData(newData);


    }






    return (
        <React.Fragment>
            <div className='row'>
                <div className="col-xl-12" style={{ position: 'relative' }}>
                    <div className="table-responsive">
                        <div className="display mb-4 dataTablesCard customer-list-table  instances-table-card">
                            {onlyLimit ?
                                <React.Fragment>
                                    {Boolean(formatData.rows.length > TABLE_ENTIRES) ? <Link className='text-decoration-none text-primary  font-w600  p-3' to="/profile/renewal">View all</Link> : null}
                                    <MDBDataTable entries={5} pagesAmount={5} paging={true} disableRetreatAfterSorting={true}
                                        entriesOptions={[5]} className='instanceListTable duetable' data={formatData} />
                                </React.Fragment>
                                :<MDBDataTable paging={true}   paginationLabel={paginationLabel} onSearch={onSearchHandler} disableRetreatAfterSorting={true} onPageChange={onPageChangeHandler} className='instanceListTable' entriesOptions={[1, 5, 20, 25]} entries={TABLE_ENTIRES}   data={formatData} />}

{/* <MDBDataTable paging={Boolean(formatData.rows.length > TABLE_ENTIRES)} paginationLabel={paginationLabel} onSearch={onSearchHandler} disableRetreatAfterSorting={true} onPageChange={onPageChangeHandler} className='instanceListTable' entriesOptions={[1, 5, 20, 25]} entries={TABLE_ENTIRES} data={formatData} /> */}


                        </div>
                    </div>
                </div>
            </div>
            <RenewalModal />
        </React.Fragment>
    )
}

const mapStatesToProps = ({ PaymentInfoReducer, userDashboard }) => {
    return {
        currencyname: PaymentInfoReducer?.paymentData?.currencyname,

    }
};


export default connect(mapStatesToProps)(DueDataHome);

