import React from 'react';
import windows from '../../../../../images/os/os5.svg';

import { getSEVERNAME } from '../../../../../API_ENDPOINTS/API_ENDPOINTS';
import { imageReplaceHashHelper } from '../../../../../utils/Helpers';


export default function OSList({ title,imgSrc,groupname }) {
    // let imgURl = imgSrc.replace('~', '');
    let [image,setImage] = React.useState('');
    React.useEffect(function (){
        
        // console.log(imgSrc,'imgSrcimgSrcimgSrcimgSrcimgSrc')
       if(typeof image === 'string' && typeof imgSrc === 'string' ){
        if(imgSrc === ''){
            setImage(windows);
        }else{
            setImage(imageReplaceHashHelper(imgSrc));
        }
       }
    },[imgSrc])
    return (
        <div className="w-100 text-center">
            <img  src={image} className="os w-35p h-35p"></img>
           <div className="d-flex-center d-gap-5 hs-lightgrey">
           <div>{groupname}</div>
            <div>{title}</div>
           </div>
        </div>
    )
}

