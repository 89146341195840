import { bareMetalViewURL, getBareMetalDetailsURL, getBareMetalListURL } from "../../API_ENDPOINTS/API_ENDPOINTS";
import { API_CALL, API_SERVICES } from "../API_SERVICES";
import { getSessionAndUserId } from "../InstancesServices/InstancesListService";


export default function getBareMetalListService(page){
    let  { userId, sessionId } = getSessionAndUserId();
  

    let config = {
        method: 'get',
        url:getBareMetalListURL(userId),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
       
    };


   return  API_SERVICES(config);
    // return API_CALL(config);
    
}

export function  getBareMetalDetailsService(machineId){
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'get',
        url:getBareMetalDetailsURL(machineId),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
    };

    return API_SERVICES(config);
}


export function getBareMetalViewLoginSessionService(payload,hostname){
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'post',
        url:bareMetalViewURL(hostname),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
        data:payload
    };
    return API_SERVICES(config);
}