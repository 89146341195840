import { cloneDeep } from "lodash";
import { getPastNotifationService, getUnReadNoticeCountService } from "../../services/Profile/NotifationsService";

const initialState = {
   count:null,
   notifs:[],
   markAsRead:{},
   selectAllNoti:false,
   showNotfiModal:false,
   clearAllModal:false
};

export const OPEN_CLEAR_ALL_MODAL = 'OPEN_CLEAR_ALL_MODAL';
export const CLOSE_CLEAR_ALL_MODAL = 'CLOSE_CLEAR_ALL_MODAL';

export const GET_NOTIFS = 'GET_NOTIFS';
export const GET_COUNT_NOF = 'GET_COUNT_NOF';

export const PUT_NOTIFS = 'PUT_NOTIFS';
export const PUT_COUNT_NOF = 'PUT_COUNT_NOF';

export const PUT_IN_MARK_READ = 'PUT_IN_MARK_READ';
export const CLEAR_MARK_READ = 'CLEAR_MARK_READ';

export const REMOVE_ONE_NOTIF = 'REMOVE_ONE_NOTIF';

export const CLEAR_NOTIFICATION_REDUCER = 'CLEAR_NOTIFICATION_REDUCER';
export const CHECK_ALL_NOTICS = 'CHECK_ALL_NOTICS';

export const SELECT_ALL_NOTI = 'SELECT_ALL_NOTI';

export const HIDE_NOTFI_MODAL = 'HIDE_NOTFI_MODAL';

export const SHOW_NOTFI_MODAL = 'SHOW_NOTFI_MODAL';

export const RESET_FORM_NOTIS = 'RESET_FORM_NOTIS';


export function clearNotifsReducerAction(){
    return {
        type:CLEAR_NOTIFICATION_REDUCER
    }
}

export function openClearAllModalAction(){
    return {
        type:OPEN_CLEAR_ALL_MODAL
    }
}

export function closeClearAllModalAction(){
    return {
        type:CLOSE_CLEAR_ALL_MODAL
    }
}

export function showNotifiModal(){
    return {
        type:SHOW_NOTFI_MODAL,
    }
}

export function hideNotifiModal(){
    return {
        type:HIDE_NOTFI_MODAL
    }
}


export function clearNotisArrAction(){
    return {
        type:CLEAR_MARK_READ
    }
}

export function putNotifsAction(payload){
    return {
        type:PUT_NOTIFS,
        payload:payload
    }
}

export function putCountAction(payload){
    return {
        type:PUT_COUNT_NOF,
        payload:payload
    }
}

export function selectAllNotiAction(payload){
    return{
        type:SELECT_ALL_NOTI,
        payload
    }
}

export function allCheckAction(payload){
    return   (dispatch, getState) => {
        
        let data = getState()?.notificationReducer?.notifs
        // let notis = data.map(function (e,i){
        //     return e['ReminderId'];
        // });
        let notis = {}
        for(let i=0;i<data.length;i++){
            notis[data[i]['ReminderId']] = data[i]['ReminderId'];
        }
        
        dispatch({
            type:PUT_IN_MARK_READ,
            payload:notis
        })
    }
}


export function getNotifsAction(){
    return async  (dispatch, getState) => {
       
       try {
        const notifsData = await getPastNotifationService();
        if(notifsData.data.result == ''){
            throw new Error("notifsData.data");
        }

        if(notifsData.data.code != '0'){
            throw new Error(notifsData.data?.message);
        }

        const data = JSON.parse(notifsData.data.result);

        dispatch(putNotifsAction(data));


       } catch (error) {
        console.log(error);
        dispatch(putNotifsAction([]));
       }
      
    }
}

export function getCountNotifs(){
    return async  (dispatch, getState) => {
       
        try {
         const countNotifs = await getUnReadNoticeCountService();
         if(countNotifs.data.result == ''){
            
             throw new Error('countNotifs.data');
         }
 
         if(countNotifs.data.code != '0'){
            
             throw new Error('countNotifs.data');
         }
 
         let dataJSON = JSON.parse(countNotifs.data.result)
         const count = dataJSON.NotificationCount;
 
         dispatch(putCountAction(count));
 
 
        } catch (error) {
         console.log(error);
         dispatch(putCountAction(null));
        }
       
     }
}

export function resetNotice(){
  
    return {
        type:RESET_FORM_NOTIS,
    
    }
}

export function putNoticeArrAction(id){
    return async  (dispatch, getState) => {
       
        //const notis = [...getState()?.notificationReducer?.markAsRead,id];
        const notis = cloneDeep(getState()?.notificationReducer?.markAsRead);
        notis[id] = {
            value:id
        }
        let data = [...getState()?.notificationReducer?.notifs];
        for(let i=0;i<data.length;i++){
            let currID = data[i]['ReminderId'];
            if(currID == id || currID == notis[data]){
                data[i]['isCheck'] = true;
            }else{
                data[i]['isCheck'] = false;
            }
        }

        dispatch(putNotifsAction(data));

        dispatch({
            type:PUT_IN_MARK_READ,
            payload:notis
        })
       
     }
}

export function removeOneNoticeArrAction(id){
    return async  (dispatch, getState) => {
    
    // const notis = [...getState()?.notificationReducer?.markAsRead];
    const notis = cloneDeep(getState()?.notificationReducer?.markAsRead);
    let newNotis = {};
    for(let obj in notis){
        if(obj != id){
            newNotis[obj] = notis[obj];
        }
    }
    // const filterArr = [];
    // for(let i=0;i<notis.length;i++){
    //     if(id != notis[i]){
    //         filterArr.push(notis[i])
    //     }
    // }
    dispatch({
        type:PUT_IN_MARK_READ,
        payload:newNotis
    })
}
}




function NotificationReducer(state = initialState, {type,payload}) {
    switch (type) {
        case CLEAR_NOTIFICATION_REDUCER:{
            return initialState;
        }

        case OPEN_CLEAR_ALL_MODAL:{
            return {
                ...state,
                clearAllModal:true
            }
        }

        case CLOSE_CLEAR_ALL_MODAL:{
            return {
                ...state,
                clearAllModal:false
            }
        }

        case PUT_NOTIFS:{
            return {
                ...state,
                notifs:payload
            }
        }
        case PUT_COUNT_NOF:{
            return {
                ...state,
                count:payload
            }
        }
        case PUT_IN_MARK_READ:{
            return{
                ...state,
                markAsRead:payload
            }
        }

        case CLEAR_MARK_READ:{
            return{
                ...state,
                markAsRead:[]
            }
        }

        case RESET_FORM_NOTIS:{
            return {
                count:null,
                notifs:[],
                markAsRead:{},
                selectAllNoti:false,
                showNotfiModal:false
            }
        }

        case SELECT_ALL_NOTI:{
            return {
                ...state,
                selectAllNoti:payload
            }
        }

        case SHOW_NOTFI_MODAL:{
            return {
                ...state,
                showNotfiModal:true
            }
        }

        case HIDE_NOTFI_MODAL:{
            return{
                ...state,
                showNotfiModal:false
            }
        }
       
       
        default:
            return state;
    }

}




export default NotificationReducer;
