import React from 'react'
import { getCountriesListService } from '../../../../services/Profile/PaymentFormServices';
import { checkResData } from '../../../../utils/Helpers';
import DropDownSearch from '../../../components/hireserver/controls/DropDownSearch';
import Form from 'react-bootstrap/Form'
import FormInputError from '../../../components/hireserver/components/FormInputError';
import StaticPaymentDetails from '../../../../utils/StaticsClasses.js/StaticPaymentDetails';

function CountriesList({ country,setCountry,isDisable,setErrorCountry,countryError }) {
  const [values, setValues] = React.useState([])
  React.useEffect(function () {
    getCountries();
  }, []);

  function formatData(data) {
    
    const arr = [];
    for (let i = 0; i < data.length; i++) {
      arr.push({
        value: data[i].countryid,
        label: data[i].countryname
      })
    }
    return arr;
  }

  function handleChange(e) {
    debugger;
    StaticPaymentDetails.isCountry = false;
    setCountry(e)
    setErrorCountry({
      isError:false,
      message:''
    })
  }
  function selectTwoGetClass(dataObj,dataMainObj){
    return `${dataObj?.isError && 'inputAlertValSelect2'} ${dataObj?.isError===false && dataMainObj!=0 && 'validFormInputSelect2'}`
  }


  async function getCountries() {
    try {
      const res = await getCountriesListService();
      const isValid = checkResData(res);
      //if (!isValid) return;
      let parseData = [];
      if(res.data.result == '' || res.data.result == null){
        parseData =[];
      }else{
        parseData = JSON.parse(res.data.result);
      }
    
      // console.log(parseData);



      const formattedData = formatData(parseData);
      // console.log(formattedData, 'formattedDataformattedData')
      setValues(formattedData);
      // setCountry({
      //   value:0,
      //   label:'Country'
      // })

    } catch (error) {
      // console.log(error);
    }
  }
  return (
    <Form.Group className='mb-0 d-flex billingFormFlex' controlId="formBasicSelect">
      {/* <Form.Label className='col-5 p-0 hs_sub_font'>Select Your Country<sup><i className='fa fa-asterisk text-danger font-5 px-1'></i></sup></Form.Label> */}

     <div className='col-sm-12  p-0'>
     <DropDownSearch placeholder="Select Country"  isDisable={isDisable} parentClass="p-0" selectClasses={`${selectTwoGetClass(countryError,country)}  p-0 hs_sub_font`}  value={country} setValue={handleChange} data={values} />
      {countryError.isError && <FormInputError message={countryError.errorMessage} />}
     </div>

    </Form.Group>

  )
}

export default CountriesList