import React from 'react';
import './MiniCard.css';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ImageHs from '../../ImageHs';

export function SelectCardMini({ OSImage, groupname, idValue, isNeedShow, isPadding = 'p-3', isTickOption = true, children, isChecked, handler, osfilename, isRequired, cardName, isHeight, isWidth, dataName, otherattr }) {
  function onClickHandler(e) {
    // console.log('hadnler')

    if (handler) {
      handler(e, cardName, osfilename, idValue, OSImage, groupname, isNeedShow);
    }
  }

  const isReq = isRequired?.required == true ? isRequired : null;


  function getClass() {
    if (isNeedShow == false) {

    }
    const isDisplay = isNeedShow == false ? 'removeCardDisable' : ''
    return `bg-white hs_borderRadius-4 boxlocation_hireserver m-0 instance_location ${isDisplay}`
  }
  return (
    <label htmlFor={idValue} {...otherattr} style={{ height: isHeight, width: isWidth }} className={getClass()} id={idValue + '_location'} data-locationVal={idValue}>

      <div className={`${isPadding} h-100 ${isChecked?'activeselectcardStyle':''}`}>
        <input onChange={()=>{}} checked={isChecked}  {...isReq} onClick={onClickHandler} style={{ zIndex: '-99' }} data-name={dataName} data-location={idValue} className="checkbox_location_hireserver" name={cardName} id={idValue} type="radio" />

        {isTickOption && <>
          <div className="tick_defaultMini">

          </div>
          <div className="tick_Mini text-center">
            <i className="las la-check pt-1"></i>
          </div>
        </>}

        <div className="align-items-start contentLocation h-100 d-flex flex-column justify-content-between">
          {children}
        </div>
      </div>
      {
        isNeedShow == false && <div className='hiddenShowMiniCard font-12'>
          <div>
            Temporarily Out of Stock
          </div>
          <div>
            No instances available
          </div>
        </div>
      }
    </label>
  )
}


function MiniCard({ OSImage, id, name, groupname, nextname, dropDownList = [], cardName, handler, otherattr, isDropdown, dataName, isRequired, isNeedShow, isChecked, osfilename }) {

  // React.useEffect(function(){
  //   // console.log('rending')
  //   if(groupname === "ArchLinux"){
  //     alert("ArchLinux")
  //   }
  // },[name]);

  React.useEffect(function () {

    if (isDropdown && dropDownList.length != 0) {

      setSelectDropDown(dropDownList[0].nameV)
    } else {
  
      // setSelectDropDown(dropDownList[0].nameV)
      setSelectDropDown(groupname)
      // console.log(dropDownList,'dropDownListdropDownListdropDownListadasiodhasdhh')
    }
    // console.log(dropDownList,'dropDownListdropDownListdropDownListdropDownListdropDownListdropDownListdropDownListdropDownList')
  }, []);
  const [selectedDropDown, setSelectDropDown] = React.useState('');

  function handlerClickC(e) {

    if (handler) {

      handler(e, cardName, osfilename, id, OSImage, groupname)

    }
    // console.log("hds")
  }

  function setSelectDropDownFun(e, el) {
    debugger;
    e.stopPropagation();

    if (el != null) {
      setSelectDropDown(el.nameV);
      if (el.nameV === 'Ubuntu') {
        debugger;
      }
      handler(e, el.nameV, osfilename, el.id, OSImage, groupname)
    }
  }







  return (
    <SelectCardMini isNeedShow={isNeedShow} OSImage={OSImage} groupname={groupname} osfilename={osfilename} isChecked={isChecked} isRequired={{ 'required': isRequired }} otherattr={{ ...otherattr }} dataName={dataName} idValue={id} handler={handler} isHeight="auto" cardName={name} >
      <div style={{ width: '200px' }} className='d-flex justify-content-between d-gap-40'>
        <div className='d-flex justify-content-between d-gap-15 align-items-center'>
          {OSImage && <ImageHs classes="border-0" width="40px" height="40px" src={OSImage} alt={groupname} />}
          {/* {OSImage && <div style={{ width: '40px', height: '40px' }}>
            <img style={{ border: 'none' }} className='w-100 h-100 rounded-circle' src={OSImage} alt={groupname} />
          </div>} */}
          <div className='d-flex flex-column align-items-start justify-content-center' style={{ lineHeight: '20px' }}>
            <span className='font-w500 font-14'> {groupname}</span>
            {nextname && <span className='font-14 hs_text-light'>{nextname}</span>}
          </div>
        </div>

      </div>
      {
        isDropdown && <div className='w-100 pt-2' style={{ pointerEvents: 'auto' }}>
          {/* borderBottom: "1px solid #aa9b9b", height: '2px' */}
          <div className="w-100"></div>
          <DropdownButton data-name={dataName} data-locationVal={id} onClick={handlerClickC} align="center" className={`w-100 dropdownlistCard pt-1 fs-14 ${dataName}`} id="dropdown-basic-button" title={dropDownList.length === 0 ? dataName : selectedDropDown}>
            {/* <Dropdown.Item onClick={(e)=>setSelectDropDown('10')}  className='text-center'>10</Dropdown.Item>
              <Dropdown.Item onClick={(e)=>setSelectDropDown('7') }className='text-center'>7</Dropdown.Item>
              <Dropdown.Item  onClick={(e)=>setSelectDropDown('XP') }className='text-center'>XP</Dropdown.Item> */}


            {dropDownList.length === 0 && <Dropdown.Item active={true} onClick={(e) => {
              setSelectDropDownFun(e, dropDownList[0]);
              // console.log(dropDownList,'dropDownListdropDownListdropDownListdropDownListdasdsa')
            }} className='text-center'>{dataName}</Dropdown.Item>}

            {dropDownList.map(function (el, i) {
              // '';

              // console.log(dropDownList,'vdropDownListdropDownListdropDownList')
              let isActive = false;
              if (selectedDropDown === el.nameV) {
                isActive = true
              }
              return <Dropdown.Item active={isActive} onClick={(e) => setSelectDropDownFun(e, el)} className='text-center'>{el.nameV}</Dropdown.Item>
            })}
          </DropdownButton>

        </div>
      }



    </SelectCardMini >
  )
}

export default React.memo(MiniCard)


