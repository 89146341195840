import React from 'react'
import { Row,Col, OverlayTrigger,Tooltip } from 'react-bootstrap';
import CustomDropDown from '../CustomDropDown';
import ToolTipCustom from '../ToolTipCustom'

export default function SelectOptionHS({name,question,data,selecthandler,disabled}) {
  return (
    
    <Row className='w-100'>
        <Col xs lg="3" className='d-flex justify-content-between align-items-center'>
        <span>{name}</span>
        {question && <OverlayTrigger
    placement="bottom"
    delay={{ show: 250, hide: 400 }}
    overlay={<div  style={{background:'white',maxWidth:'100px',fontSize:'10px'}}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium nesciunt officiis blanditiis iure aut. Cupiditate cumque quaerat pariatur quasi veritatis.</div>}
  >
                <span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 23 22" className="svelte-1cmb9l6"><path id="helptext" d="M11,22A11,11,0,0,1,3.222,3.222,11,11,0,1,1,18.778,18.778,10.928,10.928,0,0,1,11,22Zm-.281-7.386a1.385,1.385,0,0,0-1.011.416,1.335,1.335,0,0,0-.412,1,1.353,1.353,0,0,0,.412,1.005,1.38,1.38,0,0,0,1.011.419,1.336,1.336,0,0,0,.7-.194,1.526,1.526,0,0,0,.519-.516,1.386,1.386,0,0,0,.2-.715,1.364,1.364,0,0,0-.433-1A1.382,1.382,0,0,0,10.719,14.613ZM10.95,7a1.6,1.6,0,0,1,.727.166A1.293,1.293,0,0,1,12.4,8.356a1.414,1.414,0,0,1-.173.7,2.1,2.1,0,0,1-.463.567,5.643,5.643,0,0,1-.658.492,4.084,4.084,0,0,0-.842.688,2.483,2.483,0,0,0-.531,1,6.25,6.25,0,0,0-.187,1.654v.2h2.248v-.2a3,3,0,0,1,.151-.978,1.932,1.932,0,0,1,.445-.7,3.879,3.879,0,0,1,.768-.581,4.912,4.912,0,0,0,.967-.744,3.015,3.015,0,0,0,.637-.949,3.14,3.14,0,0,0,.229-1.231,3.02,3.02,0,0,0-.517-1.774,3.27,3.27,0,0,0-1.423-1.124,5.306,5.306,0,0,0-2.088-.388A4.934,4.934,0,0,0,9,5.363,3.3,3.3,0,0,0,7.57,6.52,3.521,3.521,0,0,0,7,8.48H9.42a1.548,1.548,0,0,1,.238-.815,1.43,1.43,0,0,1,.563-.5A1.642,1.642,0,0,1,10.95,7Z" fill="currentColor"></path></svg></span>

  </OverlayTrigger>}
        </Col>
        <Col xs lg="9">
        <CustomDropDown disabled={disabled} data={data} selecthandler={selecthandler} />
        </Col>
    
    </Row>
  )
}
