import { cloneDeep } from 'lodash';
import React from 'react'
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { instancesActionURL } from '../../../../../../API_ENDPOINTS/API_ENDPOINTS';
import { instanceReinstallShowModal, putCurrSelectItem } from '../../../../../../store/actions/InstancesActions/InstanceCurrItemAction';
import { setDetailInstances } from '../../../../../../store/actions/InstancesActions/InstanceDetailsActions';
import { openHsModal, openHS_rebootModal, showDeleteConfirmModal } from '../../../../../../store/actions/InstancesActions/InstancesActions';
import { DELETE_STAGE, FAILED_STAGE, PRESTART_STAGE, REBOOT_STAGE, REINSTALL_STAGE, START_STAGE, STOP_STAGE } from '../../../../../../utils/Emuns';
import StaticActionAlert from '../../../../../../utils/StaticsClasses.js/StaticActionAlert';
import CustomDropDown from '../../../../hireserver/components/CustomDropDown';
import ConsoleViewIcon from '../../../../hireserver/icons/ConsoleViewIcon';
import DeleteHsIcon from '../../../../hireserver/icons/DeleteHsIcon';
import RebootHsIcon from '../../../../hireserver/icons/RebootHsIcon';
import ReInstallIcon from '../../../../hireserver/icons/ReInstallIcon';
import StartHsIcon from '../../../../hireserver/icons/StartHsIcon';
import StopHsIcon from '../../../../hireserver/icons/StopHsIcon';
import { handlerAlert } from '../../InstancesListComponents/DropDownInstancesList';
import { instancesViewHelper } from '../../InstancesListComponents/InstancesViewHelper';
import { preformDetailsAction } from '../sharedComponents/OverActionHelper';

function DetailsActionsList({ item, statusid,isSpinning, cancelAll, setCancelAll }) {
    const dispatch = useDispatch();
    const [clickView, setClickView] = React.useState(false);
    const [actionItemState, setActionItemState] = React.useState([]);
    const [actionClicksItems, setActionClicksItems] = React.useState([{
        title: "start",
        Icon: <StartHsIcon width="20px" />,
        handler: startHandler,
        dataTags: { 'data-machineaction': START_STAGE },
    },
    {
        title: "Stop",
        Icon: <StopHsIcon width="15px" />,
        handler: stopHandler,
        dataTags: { 'data-machineaction': STOP_STAGE },
    },
    {
        title: "Reboot",
        Icon: <RebootHsIcon width="15px" />,
        handler: rebootHandler,
        dataTags: { 'data-machineaction': REBOOT_STAGE }
    },
    {
        title: "Reinstall",
        Icon: <ReInstallIcon width="15px" />,
        handler: reinstallHandler,
        dataTags: { 'data-machineaction': REINSTALL_STAGE }
    },
    {
        title: "Delete",
        Icon: <DeleteHsIcon width="15px" />,
        handler: deleteHandler,
        dataTags: { 'data-machineaction': DELETE_STAGE },
        
    }]);

    React.useEffect(function () {
        if (statusid != FAILED_STAGE) {
            
            let newItems = [];
            for (let i = 0; i < actionClicksItems.length; i++) {
                let currItem = actionClicksItems[i];
                if (currItem.dataTags['data-machineaction'] != statusid) {

                    //currItem['handler'] = (e) => startHandler(e, statusid);
                    newItems.push(currItem);
                }
            }

            setActionItemState(newItems)
        }
    }, [statusid]);

    function stopHandler(e) {


        const { machineid, machinename } = item;
        dispatch(putCurrSelectItem({
            url: instancesActionURL(machineid, STOP_STAGE),
            method: 'post',
            machineid: machineid,
            num: STOP_STAGE,
            statusid: STOP_STAGE,
            machinename: machinename,
            nodeType: 'pve',
            item
        }))
        // dispatch(putCurrSelectItem({
        //     url: twoStopURL(code,itemData['hostname'],itemData['branchid']),
        //     method: 'post',
        //     machineid: machineid,
        //     num: STOP_VM,
        //     statusid: STOP_VM,
        //     machinename: machinename,
        //     nodeType,
        //     itemData
        // }))
        dispatch(openHsModal());
    }

    async function startHandler() {
        const { machineid, machinename } = item;
        // let startObj = {
        //     url: instancesActionURL(machineid,START_STAGE),
        //     // url: twoStartURL(code,itemData['hostname'],itemData['branchid']),
        //     method: 'post',
        //     machineid: machineid,
        //     num: START_STAGE,
        //     statusid: START_STAGE,
        //     machinename: machinename,
        //     nodeType:'pve',
        //     item
        // }
        // dispatch(putCurrSelectItem(startObj))
        let newCloneItem = cloneDeep(item);
        newCloneItem['refstatusid'] = PRESTART_STAGE;
        newCloneItem['statusid'] = PRESTART_STAGE;
        newCloneItem['consoleDisabled'] = 'viewConsoleDisable';
        StaticActionAlert.actionIds[machineid] = {
            statusId: START_STAGE,
            isTrigger: false,
            text: 'started'
        };
        dispatch(setDetailInstances(newCloneItem))
        const resAns = await preformDetailsAction(instancesActionURL(machineid, START_STAGE), 'post', machineid, dispatch);

   
    }


    function rebootHandler() {
        const { machineid, machinename } = item;
        if (item?.refstatusid === STOP_STAGE) {
            handlerAlert("danger", "Start the Machine before Rebooting it.", "danger");
            return;
        }
        let rebootObj = {
            url: instancesActionURL(machineid, REBOOT_STAGE),
            method: 'post',
            machineid: machineid,
            num: REBOOT_STAGE,
            statusid: REBOOT_STAGE,
            machinename: machinename,
            nodeType: 'pve',
            item
        }
        dispatch(putCurrSelectItem(rebootObj));
        dispatch(openHS_rebootModal());
    }

    function deleteHandler() {
        const { machineid, machinename } = item;
        if (item?.refstatusid == START_STAGE) {
            // console.log('Please stop the Machine');
            handlerAlert("danger", "Stop the Machine Before Delete.", "danger");
            return;
        }
        dispatch(putCurrSelectItem({
            url: instancesActionURL(machineid, DELETE_STAGE),
            method: 'post',
            machineid: machineid,
            num: DELETE_STAGE,
            statusid: DELETE_STAGE,
            machinename: machinename,
            nodeType: 'pve',
            item
        }))
        dispatch(showDeleteConfirmModal());
    }


    function reinstallHandler() {
        if (item?.refstatusid != STOP_STAGE) {
            handlerAlert("danger", "Stop the Machine before Reinstalling it.", "danger");
            return;
        }

        const { machineid, machinename } = item;

        let startObj = {
            url: instancesActionURL(machineid, REINSTALL_STAGE),
            method: 'post',
            machineid: machineid,
            num: REINSTALL_STAGE,
            statusid: REINSTALL_STAGE,
            machinename: machinename,
            nodeType: 'pve',
            item,

        }
        dispatch(putCurrSelectItem(startObj))
        dispatch(instanceReinstallShowModal());
        return;

    }

    async function viewHandler() {
        const { vmid, hostname, branchid,clusterid } = item;
        setClickView(true);
        const res = await instancesViewHelper(vmid, hostname, clusterid);
        if (res.isError) {
            handlerAlert('danger', res.message, 'danger')
        }
        setClickView(false);
    }

    function getItemsAction() {

    }

    // return (
    //     <React.Fragment>
    //         <div>
    //             <button disabled={clickView} onClick={viewHandler} className='btn-sm border-0 hs_borderRadius-4 hs-signin-btn py-1 cursor-pointer'>View</button>
    //         </div>
    //         <div>
    //             <CustomDropDown classNameForDom="vmDetailsDropdown" items={actionItemState} >

    //                 <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    //                     <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" >
    //                         <rect x="0" y="0" width="24" height="24"></rect>
    //                         <circle fill="#000000" cx="5" cy="12" r="2"></circle>
    //                         <circle fill="#000000" cx="12" cy="12" r="2"></circle>
    //                         <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    //                     </g>
    //                 </svg>

    //             </CustomDropDown>
    //         </div>
    //     </React.Fragment>
    // )

    return (
        <React.Fragment>
            <div title='view console' onClick={viewHandler}  className={clickView?'pointerEventNone ':'cursor-pointer'}>
                {/* <button disabled={clickView} onClick={viewHandler} className='btn-sm border-0 hs_borderRadius-4 hs-signin-btn py-1 cursor-pointer'>View</button> */}
                <ConsoleViewIcon width="15px" />
            </div>
            <div className='d-flex d-gap-10'>
                <div onClick={startHandler} className={`cursor-pointer ${(item?.refstatusid === START_STAGE) ? 'pointerEventNone hs_text-light' : ''}`}>
                    {/* <span className='fa fa-play-circle-o'></span> */}
                    <StartHsIcon width="15px" />
                </div>
                <div onClick={stopHandler} className={`cursor-pointer ${(item?.refstatusid === STOP_STAGE) ? 'pointerEventNone hs_text-light' : ''}`}>
                    {/* <span className='fa fa-stop-circle-o'></span> */}
                    {/* <StartHsIcon width="15px"/> */}
                    <StopHsIcon width="15px" />
                </div>
                <div onClick={rebootHandler} className={`cursor-pointer ${(item?.refstatusid === REBOOT_STAGE) ? 'pointerEventNone hs_text-light' : ''}`}>
                    {/* <span className='fa fa-refresh'></span> */}
                    <RebootHsIcon width="15px" />
                </div>
                <div onClick={reinstallHandler} className={`cursor-pointer ${(item?.refstatusid === REINSTALL_STAGE) ? 'pointerEventNone hs_text-light' : ''}`}>
                    {/* <span className='fa fa-retweet'></span> */}
                    <ReInstallIcon width="15px" />

                </div>
                <div onClick={deleteHandler} className={`cursor-pointer ${(item?.refstatusid === DELETE_STAGE) ? 'pointerEventNone hs_text-light' : ''}`}>
                    {/* <span className='fa fa-trash'></span> */}
                    <DeleteHsIcon width="15px" />
                </div>
            </div>
        </React.Fragment>
    )
}

export default DetailsActionsList