import React from 'react'

function useInputHook() {
    const [value,setValue] = React.useState('');
    const [error,setError] = React.useState({
        isError:false,
        message:null
    });

    function resetAll(){
      setValue('');
      setError({
        isError:false,
        message:null
    });
    }
  return {value,setValue,error,setError,resetAll};
}

export default useInputHook;