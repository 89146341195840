import React from 'react'
import { useDispatch } from 'react-redux'
import { openClearAllModalAction } from '../../../../../store/reducers/NotificationReducer';

function ClearAllNotification() {
    const dispatch = useDispatch();
    function handleClick(){
        dispatch(openClearAllModalAction());
    }
  
  return (
    <div onClick={handleClick} className='text-primary'>Clear All</div>
  )
}

export default ClearAllNotification