import React from 'react'

function useCronJobHook() {
    const [cronjobsList,setCronJobsList] = React.useState([
        {value:"* * * * *",label:'Every minute'},
        {value:"0 * * * *",label:'Every hour'},
        {value:"0 0 * * *",label:'Every day'},
        {value:"0 0 * * 1-5",label:'Every weekday(Mon-Fri)'},
        {value:"0 0 * * 0",label:'Every week'},
        {value:"0 0 1 * *",label:'Every month'},
        {value:"0 0 1 1,4,7,10 *",label:'Every quarter'},
        {value:"0 0 1 1 *",label:'Every year'},
        {value:'custom',label:"Custom"},
    ]);
    const [cronvalue,setCronValue] = React.useState( {value:"0 * * * *",label:'Every hour'});
    const [customCronValue,setCustomCronValue] = React.useState(null);
    const [enableCronJob, setEnableCronJob] = React.useState(false);
 
    return {cronjobsList,setCronJobsList,cronvalue,setCronValue,customCronValue,setCustomCronValue,enableCronJob, setEnableCronJob};
}

export default useCronJobHook;