import React from 'react'
import { Form, Button, Col, Row, Card } from 'react-bootstrap';
import { containsSpecialChars, containsSpecialCharsExceptDotAndDash, hasWhiteSpace, isValidVMName } from '../../../../../utils/Helpers';
import FormInputError from '../../../hireserver/components/FormInputError';
import HeadingHS from '../../../hireserver/controls/HeadingHS';
function HeaderCFSection({ headerTitle,serverImg, createInstanceName, setSubmitErrors, setCreateInstanceName, instancesNameErr, submitErrors }, ref) {
  const [isSpecail, setIsSpecail] = React.useState(false);
  const [timer, setTimer] = React.useState(null);
  // console.log(ref,'headredddddddddddd')
  function handlerInstancesName(e) {
    debugger;
    let value = e.target.value;
    let isTrue = containsSpecialCharsExceptDotAndDash(value);
    // if (!isValidVMName(value)) {
    if (isTrue || hasWhiteSpace(value)) {
      setSubmitErrors({
        ...submitErrors,
        instancesNameErr: true,
      });
      if(!hasWhiteSpace(value)){
        setIsSpecail(true);
      }else{
        setIsSpecail(false);
      }
      if (timer) {
        clearTimeout(timer);
      }
      let id = setTimeout(function () {
        setIsSpecail(false);
        setSubmitErrors({
          ...submitErrors,
          instancesNameErr: false,
        })
      }, 4000);
      setTimer(id);


    }

    setCreateInstanceName(value);
  }

  React.useEffect(function () {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    }
  }, []);
  return (
    <Form.Group ref={ref} className="m-0" controlId="formBasicEmail">
      <Row>
        <Col xs lg="4">
          <Form.Label className='d-flex pb-1 align-items-center'> <img src={serverImg}></img> <span className='pl-2'><HeadingHS as="font-16 text-primary" title={headerTitle} /></span> </Form.Label>
          <input className='form-control hs_borderRadius-4' name='instacesinput' autoComplete='off' style={{ borderRadius: '8px' }} value={createInstanceName} onInput={handlerInstancesName} type="text" placeholder="Enter without any spaces/special characters" />
          {instancesNameErr && !isSpecail && <FormInputError message="Whitespaces are not allowed" />}
          {
            instancesNameErr && isSpecail && <FormInputError message="Name must start and end alphanumerically without specials letters. Only '-' and '.' are allowed and there shouldn't be adjacent." />
          }
        </Col>
      </Row>

    </Form.Group>
  )
}

export default React.forwardRef(HeaderCFSection);


