import React from 'react'

function MachineNameInstanceTable({name,block,ip}) {
  return (
    <div className='w-200p'>
        <div className='text-primary font-w500'>{name}</div>
        <div className='d-flex hs_text-light'>
            <div>{block} Blocks</div> - <div>{ip}</div>
        </div>
    </div>
  )
}

export default MachineNameInstanceTable