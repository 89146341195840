import React from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { getOrderDetailsService, getPaymentWalletAmountService } from '../../../../../services/paymentServices/PaymentService';
import { checkIsObjectThere, checkResData, isJsonStringHelper, percentageHelper } from '../../../../../utils/Helpers';
import HeadingHS from '../../controls/HeadingHS';
import SpacerHS_ONE from '../../Spaces/SpacerHS_ONE';
import SpacerHS_TWO from '../../Spaces/SpacerHS_TWO';
import instancespayment from '../../../../../images/hireserver/imgs/brand/instancespayment.png';
import ImageHs from '../../ImageHs';
import { getCurrencySymbol } from '../../../../../utils/Currency';
import SmallTickLoader from '../Loaders/SmallTickLoader/SmallTickLoader';

import beeStand from '../../../../../images/hireserver/imgs/brand/beestand.png';
import { connect } from 'react-redux';
import SuccessSkeleton from './successpayment/SuccessSkeleton';
import CurrencyFC from '../Currency/CurrencyFC';
import { handlerAlert } from '../../../Dashboard/Instances/InstancesListComponents/DropDownInstancesList';
import { getBlockIdService, getTenureInstanceaService } from '../../../../../services/InstancesServices/InstancesFormService';

function DonePayment({ orderidVal, bankdetails, bankOtherDetails, paymentData }) {
  const [loading, setLoading] = React.useState(true);
  const [orderDesState, setOrderDesState] = React.useState({});
  const [totalAmountBalance, setTotalAmountBalance] = React.useState(0);

  const [taxState, setTaxState] = React.useState(0);
  const [address, setaddress] = React.useState({
    state: '',
    country: ''
  })

  React.useEffect(function () {
    getOrderDetails()
  }, []);


  // React.useEffect(function () {
  //   if (!bankOtherDetails) {
  //     return;
  //   }

  //   const isSafe = isJsonStringHelper(bankOtherDetails);
  //   if (!isSafe) {
  //     handlerAlert("danger", "Something Went Wrong", "danger");
  //     return;
  //   }

  //   let data = JSON.parse(bankOtherDetails);
  //   const { totalorderamount } = data;
  //   debugger;
  //   setTotalAmountBalance(totalorderamount);


  // }, [bankOtherDetails]);

  React.useEffect(function () {
    if (paymentData == null) return;
    const { statename, countryname } = paymentData;
    setaddress({
      state: statename,
      country: countryname
    })
  }, [paymentData]);

  async function getOrderDetails() {
 
    const res = await getOrderDetailsService(orderidVal);
    debugger
    const isValid = checkResData(res);

    if (!isValid) {
      setLoading(false);
      return;
    }

    const resData = JSON.parse(res.data.result)[0];

    let isSafe = checkIsObjectThere(resData, 'machineobject');
    if (!isSafe) {
      setLoading(false);
      return;
    }
    const { totalorderamount } = resData;
    setTotalAmountBalance(totalorderamount);
    let machineData = {};
    if (resData.machineobject) {
      machineData = JSON.parse(resData.machineobject);
    }


    const { machinename, orderid, noofblocks, isbaremetal } = machineData;



    let cardDetails = {};
    if (bankdetails?.payment_method
      ?.card) {
      cardDetails = {
        ...bankdetails?.payment_method
          ?.card
      }
    } else if (bankdetails?.payment_group == 'wallet' || bankdetails?.payment_group == 'upi') {
      cardDetails = {
        card_network: bankdetails?.payment_group
      }
    }

    let { currencytype, contactname, createddate, currencyid, totalamount, orderno } = resData;

    let paymentresponse = resData['paymentresponse'];
    let paymentresponseObj = '';
    let payment_amount = 0;
    if (paymentresponse != '' && paymentresponse != null && paymentresponse != undefined) {
      paymentresponseObj = JSON.parse(paymentresponse);
      payment_amount = paymentresponseObj['payment_amount'];
    }

    // console.log(currencytype, contactname, currencyid, createddate, totalamount, totalAmountBalance, 'currencytype, contactname, createddate, totalamount ,totalorderamount')
    // /////////////////////////////////////////////
    const blockIdRes = await getBlockIdService();
    let blockid = JSON.parse(blockIdRes.data.result)[0]
    blockid = blockid['blockid'];

    const newRes = await getTenureInstanceaService(currencyid, blockid);

    const taxesData = JSON.parse(JSON.parse(newRes.data.result)[0]?.taxes);
    
    setTaxState(taxesData);

    // /////////////////////////////////////////////
    setOrderDesState({
      machinename, orderno, noofblocks, payment_amount, isbaremetal,
      currencytype, contactname, createddate, totalamount, ...cardDetails
    })

    

  }



  return (
    <SuccessSkeleton>
      <div className=''>

        <div className='p-3 px-5 border-bottom font-16'>
          <span className='text-primary'> Order Summary</span>
        </div>
        <div className='p-3 px-5 border-bottom  row'>

          <div className='col-sm-6'>
            <div className="row">
              <div className='col-6'>
                <div>Order Date</div>
                <div>{orderDesState?.createddate}</div>
                {/* <div>{orderDesState?.createddate}</div> */}
              </div>
              <div className='col-6'>
                <div>Order Id</div>
                <div>{orderDesState?.orderno}</div>
              </div>
            </div>
          </div>
          <div className='col-sm-6'>
            <div className="row">
              {orderDesState?.card_number && <div className='col-6'>
                <div>Payment</div>
                <div>{orderDesState?.card_network} <span className='mx-1'>-</span>{orderDesState?.card_number?.replaceAll('X', '')}</div>
              </div>}
              {!orderDesState?.card_number && <div className='col-6'>
                <div>Payment</div>
                <div>{orderDesState?.card_network?.toUpperCase()}</div>
              </div>}
              <div className='col-6'>
                <div>Address</div>
                <div>
                  <div>{address.state}</div>
                  <div>{address.country}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='p-3 px-5 row border-bottom font-16 font-w500'>

          <div className="col-7 d-flex align-items-center d-gap-40">
            <ImageHs src={beeStand} alt={orderDesState?.machinename} width="100px" />

            <div className=''>
              <div className=' m-0'>
                <span>{orderDesState?.machinename}</span> <span>|</span> <span>HS Block</span>
              </div>
              <div className='font-12 d-none'>
                Hireserver @ Copyright
              </div>
            </div>
          </div>
          <div className="col-2 d-flex justify-content-center align-items-center">
            <span>{orderDesState?.noofblocks}</span>
          </div>
          <div className="col-3  d-flex justify-content-center align-items-center">
            <span className=''>
              {/* {getCurrencySymbol(orderDesState?.currencytype, orderDesState?.totalamount)} */}
              <CurrencyFC name={orderDesState?.currencytype} val={orderDesState?.totalamount} />
            </span>
          </div>
        </div>
        <div className='p-3 px-5 border-bottom'>
          <div className='d-flex justify-content-between'>
            <span>Order Amount</span>
            <span>
              {/* {getCurrencySymbol(orderDesState?.currencytype, orderDesState?.totalorderamount)} */}
              <CurrencyFC name={orderDesState?.currencytype} val={orderDesState?.totalamount} />
            </span>
          </div>
          {/* <div className='d-flex justify-content-between'>
            <span>Taxes</span>
           
          </div> */}
          <div className='d-flex justify-content-between'>
            <span>CGST <span className=''>{taxState?.CGST}%</span> </span>
            <span className=''>
              <CurrencyFC name={orderDesState?.currencytype} val={percentageHelper(taxState?.CGST, orderDesState?.totalamount)} />

            </span>
          </div>
          <div className='d-flex justify-content-between'>
            <span>SGST  <span className=''>{taxState?.SGST}%</span>  </span>
            <span className=''>
              <CurrencyFC name={orderDesState?.currencytype} val={percentageHelper(taxState?.SGST, orderDesState?.totalamount)} />

            </span>
          </div>
          <div className='font-w500'>
            <div className='d-flex justify-content-between'>
              <span>Sub Total</span>
              <span>
                {/* {getCurrencySymbol(orderDesState?.currencytype, orderDesState?.totalamount)} */}
                <CurrencyFC name={orderDesState?.currencytype} val={totalAmountBalance} />
                {/* <CurrencyFC name={orderDesState?.currencytype} val={orderDesState?.totalamount} /> */}

              </span>
            </div>
          </div>
          {<div className='d-flex justify-content-between'>
            <span>Amount deducted from Wallet</span>
            {/* <span>{getCurrencySymbol(orderDesState?.currencytype, orderDesState?.totalorderamount - orderDesState?.totalamount)} </span> */}
            <span>
              -
              {totalAmountBalance != 0 ? <CurrencyFC name={orderDesState?.currencytype} val={Math.abs(orderDesState?.payment_amount - totalAmountBalance)} /> : <CurrencyFC name={orderDesState?.currencytype} val={0} />}
              {/* <span>---</span>
              {orderDesState?.totalorderamount}
              <span>---</span>
              {orderDesState?.totalamount} */}
            </span>
          </div>}

          {/* <div className='d-flex justify-content-between'>
            <span>Amount Charged</span>
            <span>
              +
             
              <CurrencyFC name={orderDesState?.currencytype} val={orderDesState?.totalamount} />
            </span>
          </div> */}


        </div>
        <div className='p-3 px-5 border-bottom font-w500'>
          <div className='d-flex justify-content-between'>
            <span>Total</span>
            <span>
              {/* {getCurrencySymbol(orderDesState?.currencytype, orderDesState?.totalamount)} */}
              <CurrencyFC name={orderDesState?.currencytype} val={orderDesState?.payment_amount} />

            </span>
          </div>
        </div>
        <div className='p-3 px-5 border-bottom'>
          <div>We will notify you when machine is created.</div>
          <SpacerHS_TWO />
          <div className='text-center'>
            <Link className='btn hs-signin-btn hs_borderRadius-4 w-150p' to="/instances">
              Done
            </Link>
          </div>
        </div>
      </div>
    </SuccessSkeleton>
  )
}

const mapStateToProps = ({ PaymentInfoReducer }) => {
  return {
    paymentData: PaymentInfoReducer?.paymentData


  };
};
export default connect(mapStateToProps)(DonePayment);

// function DonePayment() {
//   return (
//     <Card className='p-3'>
//     <Card.Body>
//         <div className='d-flex justify-content-center  align-items-center flex-column p-5'>
//             <div className='text-center'>
//                <TickerLoader/>
//             </div>
//             <div className='text-primary font-30 font-w600'>
//                 Payment Made Successfully
//             </div>

//             <SpacerHS_TEN/>
//             <div className='hs_text-light text-center d-none'>
//                 {/* Your Machine is Created */}
//                 Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsum dignissimos, minus praesentium explicabo facilis asperiores. Id tempore aut consectetur dicta?
//             </div>
//             <SpacerHS_TEN/>
//             <SpacerHS_TEN/>

//           <div className='row d-gap-10 justify-content-center w-100'>
//           <Link style={{
//                 padding:'10px 15px'
//             }} className='btn col-12 col-md-3 viewInstancesCreated hs_borderRadius-4 hs-signin-btn hs_borderRadius-4' to="/instances/create">
//                 Create a other instance
//             </Link>
//           <Link style={{
//                 padding:'10px 15px'
//             }} className='btn col-12 col-md-3 viewInstancesCreated hs_borderRadius-4 hs-signin-btn hs_borderRadius-4' to="/">
//                 View Instances
//             </Link>
//           </div>
//         </div>
//     </Card.Body>
// </Card>
//   )
// }



