import React from 'react'

function ViewInstanceTable() {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center'>
        <button className='btn-hslineargradient btn-sm px-3 py-1 '>View</button>
    </div>
  )
}

export default ViewInstanceTable