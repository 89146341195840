import { lazy, Suspense, useEffect,useState } from 'react';
// import * as signalR from '@microsoft/signalr';

/// Components
import Index from './jsx/index';
import { connect, useDispatch } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import "./css/hs_style.css";
import Error503 from './jsx/pages/Error503';
import { getSEVERNAME, getSignalRConnectionURL, LOCALHOST, SEVERNAME } from './API_ENDPOINTS/API_ENDPOINTS';
import StaticSignalr from './utils/StaticsClasses.js/StaticSignalr';
import { getCountNotifs, getNotifsAction } from './store/reducers/NotificationReducer';

import nodeSound from './sounds/alert.mp3';
// import AuthWebSite from './jsx/pages/AuthWebSite/AuthWebSite';
import MainLoader from './jsx/components/hireserver/components/Loaders/MainLoader';

const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = lazy(()=>import('./jsx/pages/Login'));
// const Login = lazy(() => {
//     return new Promise(resolve => {
//         setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
//     });
// });




function App(props) {

    const dispatch = useDispatch();

    useEffect(function (){
       
        const script = document.createElement("script");
        script.src = process.env.PUBLIC_URL +"/signalr.js";
        script.async = true;
        document.body.appendChild(script);

        if(script.readyState) {  // only required for IE <9
            script.onreadystatechange = function() {
              if ( script.readyState === "loaded" || script.readyState === "complete" ) {
                script.onreadystatechange = null;
                SignalRFun();
              }
            };
          } else {  //Others
            script.onload = function() {
              SignalRFun();
            };
          }
   
    },[]);

    useEffect(() => {
    
        checkAutoLogin(dispatch, props.history);
    }, [dispatch, props.history]);

    useEffect(()=>{
        // SignalRFun();
        if(!props.userDetails?.hasOwnProperty('userId')) return;
      
        StaticSignalr.isBinding = true;
        StaticSignalr.userId  = props.userDetails?.userId;
        StaticSignalr.nameUsername  = props.userDetails?.name;;
        StaticSignalr.unibaseId  = props.userDetails?.unibaseId;
         StaticSignalr.tenantId  = props.userDetails?.tenantId;
         StaticSignalr.sessionId  = props.userDetails?.sessionId;
        SignalRFun();
    },[props.userDetails]);


    async function SignalRFun(){

        if(!window['signalR']) return;

        if(!StaticSignalr.isBinding) return;
        StaticSignalr.isBinding = false;
        let connection = new window['signalR'].HubConnectionBuilder().withUrl(`${SEVERNAME}/notificationhub`).build();
        // let connection = new window['signalR'].HubConnectionBuilder().withUrl(`${getSEVERNAME()}/notificationhub`).build();
        
        connection.on('sendnotification',(unibaseId,subject,message,reminderType)=>{
         
            if(Notification.permission != 'granted'){
                Notification.requestPermission();
                new Notification(subject,{body:message});
            }else if(Notification.permission === 'granted'){
                new Notification(subject,{body:message});
                
            }

            dispatch(getCountNotifs());
            dispatch(getNotifsAction())
          
            let audio = new Audio(nodeSound);
            audio.volume = 0.10;
            audio.autoplay = false;
            audio.play();
        });
        window['connectionhs'] = connection;
       
        const payload = {
            UserId: StaticSignalr.userId,
                    UserName: StaticSignalr.nameUsername,
                    UnibaseId: StaticSignalr.unibaseId,
                    TenantId: StaticSignalr.tenantId,
                    SessionId: StaticSignalr.sessionId
        }
       try {
        const stringConnection = await connection.start();
        
        await connection.invoke('Connect',payload);
       
       } catch (error) {
        console.log(error)
       }
        

    }

    let routes = (
        <Switch>
            <Route path='/login' component={Login} />
            <Route path='/signup' component={SignUp} />
            <Route path='/page-forgot-password' component={ForgotPassword} />
            {/* <Route path='/redirectwebsite/:data' component={AuthWebSite} /> */}
            <Route path='/no-network' component={Error503} />
        </Switch>
    );
    if (props.isAuthenticated) {
        return (
            <>
                <Suspense fallback={
                     <MainLoader/>
                }
                >
                    <Index />
                   
                </Suspense>
            </>
        );

    } else {
        return (
            <div className="vh-100 ">
                <Suspense fallback={
                 <MainLoader/>
                }
                >
                    {routes}
                </Suspense>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
        showLoading: state.auth.showLoading,
        userDetails:state.auth.auth
    };
};

export default withRouter(connect(mapStateToProps)(App)); 
