import produce from "immer";
import { SET_DETAIL_ITEM } from "../../types/InstancesTypes/DetailsInstances";

const initialState = {
   
    loading:false,
    error:{
        isError:false,
        erorrs:[]
    },
    currDetailsItem: {}

};

export default function DetailIntanceReducer(state = initialState, action){
    switch (action.type) {
        case SET_DETAIL_ITEM:
           return{
            ...state,
            currDetailsItem:action.payload
           };
           
        default:
            return state;
    }
}