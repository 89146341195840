import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'



function BoundsRulesLoaders({times,width=200}) {
    

    // function renderItems(){
    //     const items = [];
    //     for(let i=0;i<times;i++){
    //         items.push(<MachineLocationSkeletionCard/>);
    //     }

    //     return items;
    // }
  return (
    <Skeleton count={times}  containerClassName='d-flex d-gap-10 flex-wrap
    ' width={width} height={70} />
  )
}

export default BoundsRulesLoaders