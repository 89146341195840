import React from 'react'
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { getSEVERNAME, reInstallMachineUrl, twodeleteURL, twoStartURL, twoStopURL } from '../../../../../../API_ENDPOINTS/API_ENDPOINTS';
import { getDatabasesService, getInstancesOSList, getMarketPlacesService } from '../../../../../../services/InstancesServices/InstancesFormService';
import { closeAllModal, instanceReinstallHideModal, update_all_status_start, VM_ActionCall, VM_ActionStarted, VM_CALL_ACTION_STOP_ALL } from '../../../../../../store/actions/InstancesActions/InstanceCurrItemAction';
import { closeHsModal, getIntancesList, startLoadingInstaces } from '../../../../../../store/actions/InstancesActions/InstancesActions';
import ModalHs from '../../../../hireserver/ModalHs'
import { handlerAlert } from '../../InstancesListComponents/DropDownInstancesList';
import debianImg from '../../../../../../images/hireserver/imgs/centos.jpg';
// import SelectionsOption from "../../hireserver/SelectionsOptions";
import SelectionsOptions from '../../../../hireserver/SelectionsOptions';
import { getOrderRefIdService, postReinstallInstanceService } from '../../../../../../services/InstancesServices/InstancesListService';
import { updateCurrentMachine } from '../../../../../../services/InstancesServices/InstanceCurrListNewDropdownService';
import { checkMachinePromise } from '../../../../../../services/API_SERVICES';
import { preformDetailsAction } from './OverActionHelper';
import { REINSTALL_STAGE, START_STAGE } from '../../../../../../utils/Emuns';
import { cloneDeep } from 'lodash';
import { setDetailInstances } from '../../../../../../store/actions/InstancesActions/InstanceDetailsActions';
import StaticActionAlert from '../../../../../../utils/StaticsClasses.js/StaticActionAlert';
import { postCheckPaymentStatusService } from '../../../../../../services/paymentServices/PaymentService';
import { checkResData, imageReplaceHashHelper } from '../../../../../../utils/Helpers';
import LoaderHS from '../../../../hireserver/components/LoaderHS';
import MachineLocationsSkeleton from '../../../../hireserver/components/Loaders/Machines/MachineLocationsSkeleton';

function InstanceReInstall({ curSelectItem, showHsModal }) {
  const [osList, setOslist] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const [databasesList, setDatabasesList] = React.useState([]);

  const [marketPlacesList,setMarketPlacesList] = React.useState([]);
  const [marketPlacesItemId,setMarketPlacesItemId] = React.useState(0);
  const [marketplaceStateid, setMarketPlaceStateId] = React.useState(0);

  const [loadingFilter,setLoadingFilter] = React.useState(true);

  React.useEffect(function (){
    let id = null;
    if(showHsModal){
      id = setTimeout(function (){
        setLoadingFilter(false);
      },1000);
    }

    return () => {
      if(id){
        setLoadingFilter(true);
        clearTimeout(id);
      }
    }

  },[showHsModal]);


  const [modalStopInput, setModalStopInput] = React.useState({
    text: '',
    error: false
  });

  const [selectOs, setSelectOs] = React.useState({
    name: "",
    type: "win10",
    osfilename: '',
    id: '0',
    image: ''
  });

  const [selectOsDB, setSelectOsDB] = React.useState({
    name: "",
    type: "win10",
    osfilename: '',
    idValue: '0',
    image: '',
    groupname: ''
  });
  const [selectOsMarketPlace, setSelectMarketPlace] = React.useState({
    name: "",
    type: "win10",
    osfilename: '',
    idValue: '0',
    image: '',
    groupname: ''
  });



  async function reInstallMachine(machineid, osid,mpid) {

    let res = await postReinstallInstanceService(machineid, osid,mpid);
    debugger;
    if(res.data.code != '0'){
      handlerAlert("danger",res.data.message,"danger");
      dispatch(getIntancesList(true));
    }else{
      dispatch(getIntancesList());
    }
   

  }

  async function handlerReinstall(e) {
  
    const { itemData: { vmid, branchid, refstatusid, hostname, machineid, orderstatusid, orderid } } = curSelectItem;
debugger;

debugger;
    dispatch(instanceReinstallHideModal());
    StaticActionAlert.actionIds[machineid] = {
      statusId: START_STAGE,
      isTrigger: false,
      text: 'reinstalled',
      touched: false
    };
    let sitePath = window.location.href;

    if (sitePath.includes('vmdetails/overview')) {
      let newCloneItem = cloneDeep(curSelectItem);
      newCloneItem['item']['refstatusid'] = REINSTALL_STAGE;
      newCloneItem['item']['statusid'] = REINSTALL_STAGE;
      newCloneItem['item']['consoleDisabled'] = 'viewConsoleDisable';
      dispatch(setDetailInstances(newCloneItem.item))


      let vmCode = sitePath.split('instances/')[1].split('/vmdetails')[0];

      const resAns = await preformDetailsAction(reInstallMachineUrl(vmCode, selectOs.id), 'post', vmCode, dispatch);
     
      if (resAns.error) {
        handlerAlert('danger', resAns.data.data.message, 'danger');
        return;
      }

      return;

    }


    if (orderstatusid == 1) {
      try {
   
        const orderRefIdAPI = await getOrderRefIdService(orderid);
        //postCheckPaymentStatusService()
        const resOrderRefJson = JSON.parse(orderRefIdAPI.data.result)[0]?.reforderid;
        const checkpaymentMachineJSON = await postCheckPaymentStatusService(resOrderRefJson);
        const checkpaymentMachine = JSON.parse(checkpaymentMachineJSON.result.data);
        debugger;
        return
      } catch (error) {

      }
    } else {
      dispatch(VM_ActionStarted(curSelectItem));
      debugger;
      const opOPtions = getCurrentOsOptions(selectOs,marketplaceStateid,marketPlacesItemId,selectOsDB,selectOsMarketPlace);
      console.log(marketPlacesItemId)
      console.log(marketplaceStateid);
      console.log(selectOsMarketPlace);
      reInstallMachine(machineid, opOPtions.operatingsystemid,opOPtions.marketplaceid);
    }



  }

  function getCurrentOsOptions(sos,dbos,mkos,dbosData,mkosData){

    let obj = {
      osiconpath:'',
      groupname:'',
      marketplaceid:0,
      operatingsystemname:'',
      operatingsystemid:''
    }

    if(dbos!=0){
      obj = {
        osiconpath:dbosData.image,
        groupname:dbosData.groupname,
        marketplaceid:dbos.marketplaceid,
        operatingsystemname:dbosData.name,
        operatingsystemid:dbosData.id,
        osObj:dbosData
      }
    }else if(mkos != 0){
      obj = {
        osiconpath:mkosData.image,
        groupname:mkosData.groupname,
        marketplaceid:mkos.marketplaceid,
        operatingsystemname:mkosData.name,
        operatingsystemid:mkosData.id,
        osObj:mkosData
      }
    }else{
      obj={
        osiconpath:sos.image,
        groupname:sos.groupname,
        marketplaceid:0,
        operatingsystemname:sos.name,
        operatingsystemid:sos.id,
        osObj:sos
      }
    }

    return obj;
  }

  async function getDatabases() {

    const res = await getDatabasesService();
    const isValid = checkResData(res);
    if (!isValid) {
      handlerAlert("danger", "Can't get Databases", "danger");
      console.log("Can't get Databases");
    }

    const jsonParse = JSON.parse(res.data.result);
    setDatabasesList(jsonParse)
    //setMarketPlaceStateId(jsonParse[0]);
   

    if(curSelectItem.itemData?.marketplaceid){
      for(let i=0;i<jsonParse.length;i++){
        let currItem = jsonParse[i];
        if(currItem.marketplaceid === curSelectItem.itemData?.marketplaceid){
         
          //const osNames = os_text.split(' ');
          const parseObj = JSON.parse(currItem.osobj)[0];
          setSelectOsDB({
            name:parseObj.OperatingSystemName,
            type:currItem.type,
            osfilename:parseObj.OSFileName,
            id:parseObj.OperatingSystemId,
            image:imageReplaceHashHelper(parseObj.OsIconPath),
            groupname:parseObj.GroupName
          })
          setMarketPlaceStateId(currItem);
        }
      }
    }


  }


  async function getOsMachines() {
    setLoading(true);
    setOslist({
      name: "",
      options: [],
      loading: true
    })
    let osListFetch = await getInstancesOSList();

    let result = null;

    if (osListFetch?.data.result == '') {
      handlerAlert("danger", osListFetch.data.message, "danger");
      return;
    }
    if (osListFetch?.data.result != null || osListFetch?.data.result != '') {
      result = JSON.parse(osListFetch?.data.result);
    }
    let options = [];
    if (osListFetch?.data.errors != null && osListFetch?.data.errors?.length != 0 && osListFetch?.data.errors?.length != undefined) {
      handlerAlert("danger", osListFetch?.data.errors[0], "danger");
      return;
    }

    let currItem = 0;
    const orderDes = JSON.parse(curSelectItem.itemData.orderdescription);
    debugger;
    for (let i = 0; i < result?.length; i++) {

      if (result[i]['ostype'] == '64bit') {
        
        if (result[i]['operatingsystemid'] === curSelectItem.itemData.operatingsystemid&& !curSelectItem.itemData?.marketplaceid) {
          currItem++;
          setSelectOs({

            name: result[i]['operatingsystemname'],
            type: result[i]['ostype'],
            id: result[i]['operatingsystemid'],
            image:imageReplaceHashHelper( result[i]['osiconpath']),
            osfilename: result[i]['osfilename'],
            groupname: result[i]['groupname']
          })
        }
        let data = {
          dataName: result[i]['operatingsystemname'],
          type: result[i]['ostype'],
          id: result[i]['operatingsystemid'],
          image:imageReplaceHashHelper( result[i]['osiconpath']),
          osfilename: result[i]['osfilename'],
          groupname: result[i]['groupname']
        }
        if (result[i]['osiconpath'] == '') {
          data.image = debianImg;
        } else {
          data.image =  imageReplaceHashHelper(data?.image)
          // data.image =  `${getSEVERNAME()}\\` + data?.image?.replace('~', '');
        }

        options.push(data);
      }
    }

    let customData = [{
      name: "64 Bit",
      options,
      loading: false
    }]

    setOslist(customData)
    // console.log(customData)

  }

  function handleModalClose() {
    setModalStopInput({
      text: '',
      error: false
    });
    setSelectOs({
      name: "",
      type: "win10",
      osfilename: '',
      id: '0',
      image: ''
    });
    setSelectOsDB({
      name: "",
      type: "win10",
      osfilename: '',
      id: '0',
      image: ''
    });
    setMarketPlaceStateId(0);
    dispatch(instanceReinstallHideModal());
  }


  React.useEffect(function () {
    if (showHsModal) {

      getOsMachines();
      getDatabases();
      getMarketPlaces();
    }
  }, [showHsModal]);

  async function getMarketPlaces(){
    const res = await getMarketPlacesService();
    const isValid = checkResData(res);
    if (!isValid) {
      handlerAlert("danger", "Can't get Databases", "danger");
    
    }

    const jsonParse = JSON.parse(res.data.result);
    let newArr = [];
    // for(let i=0;i<jsonParse.length;i++){
   
    //   if(jsonParse[i]['appname']!="Cpanel" && jsonParse[i]['appname']!="Wordpress" ){
    //     newArr.push(jsonParse[i]);
    //   }
    // }
    setMarketPlacesList(jsonParse)
    //setMarketPlaceStateId(jsonParse[0]);
 
  }

  function handleOsClickDB(e, cardName, osfilename, idValue, OSImage, groupname) {
    // setSelectOs()
    
    setMarketPlacesItemId(0);
    let name = e.target.getAttribute("data-name");
    if (!name && cardName) {
      name = cardName;
    }
    let which = e.target.getAttribute("id");
    let type = "";
    if (name == "windows") {
      type = "win10";
    } else {
      type = "l26";
    }
   
    setSelectOsDB({
      name,
      type,
      osfilename: osfilename,
      id: idValue,
      image: OSImage,
      groupname
    });

    //setMarketPlaceStateId(0)

    // // console.log(selectOs)
  }

  function handleOsClick(e, cardName, osfilename, idValue, OSImage, groupname) {
    // setSelectOs()

    let name = e.target.getAttribute("data-name");
    if (!name && cardName) {
      name = cardName;
    }
    let which = e.target.getAttribute("id");
    let type = "";
    if (name == "windows") {
      type = "win10";
    } else {
      type = "l26";
    }
    
    setSelectOs({
      name,
      type,
      osfilename: osfilename,
      id: idValue,
      image: OSImage,
      groupname
    });

    setMarketPlaceStateId(0);
    setMarketPlacesItemId(0);

    // // console.log(selectOs)
  }

  function handleOsClickMarket(e, cardName, osfilename, idValue, OSImage, groupname) {
    // setSelectOs()
    debugger;
    setMarketPlaceStateId(0);
    let name = e.target.getAttribute("data-name");
    if (!name && cardName) {
      name = cardName;
    }
    let which = e.target.getAttribute("id");
    let type = "";
    if (name == "windows") {
      type = "win10";
    } else {
      type = "l26";
    } 
   
    setSelectMarketPlace({
      name,
      type,
      osfilename: osfilename,
      id: idValue,
      image: OSImage,
      groupname
    });

    //setMarketPlaceStateId(0)
   

    // // console.log(selectOs)
  }


 


  return (
    <ModalHs size="lg" handleClose={handleModalClose} okayHandler={handlerReinstall} showModalHS={showHsModal} btnTitle="ReInstall" >
      <div className='w-100'>

      {
        loadingFilter ?  <div className=''>
          
          <MachineLocationsSkeleton classes="align-items-center justify-content-center" times={9} />
          </div>   : <React.Fragment>

        <SelectionsOptions
     
     currentOs={selectOs}
     clickHandler={handleOsClick}
     title="Software Selections"
     OptionsCardArr={osList}
     databasesList={databasesList} 
     setDatabasesList={setDatabasesList} 
     marketplaceStateid={marketplaceStateid}
     setMarketPlaceStateId={setMarketPlaceStateId} 
     setSelectOsHandler={setSelectOs} 
     selectOsDB={selectOsDB} 
     setSelectOsDB={setSelectOsDB} 
     marketPlacesList={marketPlacesList}
     handleOsClickDB={handleOsClickDB}
     handleOsClickMarket={handleOsClickMarket}
     setSelectMarketPlace={setSelectMarketPlace}
     selectOsMarketPlace={selectOsMarketPlace}
     marketPlacesItemId={marketPlacesItemId}
     setMarketPlacesItemId={setMarketPlacesItemId}
   />
        </React.Fragment>
      }
      </div>
    </ModalHs>
  )


 
}

const mapStateToProps = (state) => {
  return {
    showHsModal: state.instances.instanceReinstall,
    curSelectItem: state.instances.curSelectItem,
  };
};

export default connect(mapStateToProps)(InstanceReInstall);

