import React from 'react'
import BoundsRulesLoaders from '../../../../../hireserver/components/Loaders/Firewall/BoundsRulesLoaders';
import ButtonHS from '../../../../../hireserver/controls/ButtonHS';
import HeadingHS from '../../../../../hireserver/controls/HeadingHS';
import SpacerHS_TWO from '../../../../../hireserver/Spaces/SpacerHS_TWO';
import FirewallContext from '../FirewallContext/FirewallContext';
import BoundMaster from './Bounds/BoundMaster';

function OutBoundFire() {
    const {inBoundLoading,boundTypes,outboundData,addRuleOutBoundHandler,deleteOutBoundItem} = React.useContext(FirewallContext);
    if(inBoundLoading){
      return <div>
        <BoundsRulesLoaders times={5} />
      </div>
    }
  return (
    <div>
      <HeadingHS  title="Outbound rules"as="h3 text-primary" />
      <BoundMaster boundName="outbound" boundItemDeleteHandler={deleteOutBoundItem}  data={outboundData} boundTypes={boundTypes} />
      <SpacerHS_TWO/>
      <ButtonHS handler={addRuleOutBoundHandler} classes="p-2" >
        Add rule
      </ButtonHS>
    </div>
  )
}

export default OutBoundFire