import React from 'react'
import { connect } from 'react-redux'


function ParentCheckBoxControlInstances({ id, dataAttr,handleClick,getIsAllSelect }) {

    return (<div className="sorting_1 p-0 text-center">
        <div className="custom-control custom-checkbox ml-2">
            <input
                type="checkbox"
                className="custom-control-input"
                id={id}
                checked={getIsAllSelect}
                data-code={`${dataAttr}`}
                onChange={(e) => handleClick(e,null,getIsAllSelect)}

            />
            <label
                className="custom-control-label"
                htmlFor={id}
            ></label>
        </div>
    </div>)
}


const mapsStateToProps = (state) => {
    return {
        getIsAllSelect:state.instances.selectedListItems.selectAll,
    }
}

export default connect(mapsStateToProps)(ParentCheckBoxControlInstances);