import React from 'react'
import ModalHs from '../../../../../hireserver/ModalHs';
import { postSaveCronJobService } from '../../../../../../../services/InstancesServices/InstancesListService';
import { removeCronTokens } from '../../../../../../../utils/Helpers';
import { handlerAlert } from '../../../../Instances/InstancesListComponents/DropDownInstancesList';
import { postscriptmarketService } from '../../../../../../../services/InstancesServices/InstanceCurrListNewDropdownService';
import uuid from 'react-uuid';

function DeleteCronConfirm({show,setShow,data,setData,callParentList}) {
  const [loading,setLoading] = React.useState(false);
  async function deleteHandler(){
    console.log(data);
    let {scriptid,tokens} = data;

    setLoading(true);
    tokens = JSON.parse(tokens);
    console.log(tokens);
    const [cronFilter,cronValues] = removeCronTokens(tokens.params);
     tokens.params = cronFilter;
    tokens["cronstatus"]= 0;
    data.tokens = tokens;

    //bizgaze
    try {
      const res = await postscriptmarketService(data);
      if(!res || res.data.code != '0'){
        handlerAlert("danger","Something went wrong","danger");
        setLoading(false);
        return;
      }
      console.log(res,"bizgaze");
    } catch (error) {
      handlerAlert("danger","Something went wrong","danger");
      setLoading(false);
      return;
    }
   
    //code exec
   try {
    // (fullItemDate.scriptid, {...fullItemDate.tokens,logid:uuid(),"cronstatus": 1,"crontime": findCronTime(fullItemDate.tokens.params)});
     const cronres = await postSaveCronJobService(scriptid,{...data.tokens,logid:uuid(),"cronstatus": 0,"crontime":cronValues[1]} );
     console.log(cronres,"code");
     if(cronres.data.code != '200'){
      handlerAlert("danger","Something went wrong","danger");
      setLoading(false);
      return;
     }
   } catch (error) {
    handlerAlert("danger","Something went wrong","danger");
    setLoading(false);
    return;
   }
    
    setLoading(false);
    setData(null);
    setShow(false);
    callParentList();
  }
  return (
    <ModalHs isNeed={false}   showModalHS={show}>
    <div className='w-100'>
      <div className='pb-4'>Are you sure? This will disable cron job too.</div>
      <div className='d-flex align-items-end justify-content-end d-gap-10'>
        <button className='btn btn-danger hs_borderRadius-4 hs_borderRadius-4 px-2 py-1' onClick={()=>setShow(false)}>Close</button>
        <button onClick={deleteHandler} className='w-100p bg-primary btn hs_borderRadius-4 px-2 py-1 text-white'>  {loading ? <React.Fragment>
                      <div className="spinner-border text-light" role="status">

                      </div> 
                    </React.Fragment> : 'Yes'}</button>
      </div>
    </div>
      </ModalHs>
  )
}

export default DeleteCronConfirm;