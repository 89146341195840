import React from 'react'
import LoaderHS from '../../../../../hireserver/components/LoaderHS';
import BoundsRulesLoaders from '../../../../../hireserver/components/Loaders/Firewall/BoundsRulesLoaders';
import ButtonHS from '../../../../../hireserver/controls/ButtonHS';
import HeadingHS from '../../../../../hireserver/controls/HeadingHS'
import SpacerHS_TWO from '../../../../../hireserver/Spaces/SpacerHS_TWO';
import FirewallContext from '../FirewallContext/FirewallContext';
import BoundMaster from './Bounds/BoundMaster'

function InBoundFire() {
  const {boundTypes,inBoundLoading,setInBoundLoading,inboundData,addRuleInBoundHandler,deleteInBoundItem,setInBoundDataFull,inBoundDataFull} = React.useContext(FirewallContext);
  if(inBoundLoading){
    return <div>
      <BoundsRulesLoaders times={5} />
    </div>
  }
  return (
    <div>
      <HeadingHS  title="Inbound rules"as="h3 text-primary" />
      <BoundMaster boundName="inbound"  boundItemDeleteHandler={deleteInBoundItem}  data={inboundData} boundTypes={boundTypes} />
      <SpacerHS_TWO/>
      <ButtonHS handler={addRuleInBoundHandler} classes="p-2" >
        Add rule
      </ButtonHS>
    </div>
  )
}

export default InBoundFire