import React from 'react';
import Nodebalance from '../../NodeBalance/Nodebalance';
import NodeBalanceContext from './NodeBalanceContext'

export default function CreateNodeBalanceContext({ children }) {
    const [selectTagOption, setSelectTagOption] = React.useState([{ value: 'one', label: 'One' },
    { value: 'two', label: 'Two' },{ value: 'dummy', label: 'dummy' }]);
    return (
        <NodeBalanceContext.Provider value={{ selectTagOption, setSelectTagOption }}>
            <Nodebalance/>
        </NodeBalanceContext.Provider>
    )
}
