import { getUserOverviewURL } from "../../API_ENDPOINTS/API_ENDPOINTS";
import { API_SERVICES } from "../API_SERVICES";
import { getSessionAndUserId } from "../InstancesServices/InstancesListService";

export function getUserOverviewService(){
    let  { userId, sessionId } = getSessionAndUserId();
 
    let config = {
        method: 'get',
        url:getUserOverviewURL(userId),
        headers: {
            'Authorization': `Basic ${sessionId}`,
        },
 
    };

    return API_SERVICES(config);
}
