import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RedirectPage from '../redirectPage/RedirectPage'

function RedirectPagePayment({showRedirect,redirectLink,router}) {
    const [timer,setTimer] = React.useState(null);
    const countRef = React.useRef(3);
    const [count,setCount] = React.useState(3);

    React.useEffect(function (){
        return () => {
            clearInterval(timer);
            setTimer(null);
            countRef.current =  3;
            setCount(0);
        }
    },[])

    React.useEffect(function (){
        if(showRedirect){
            if(timer){
                clearInterval(timer);
               
            }
           
            //// console.log(count)
            let t = setInterval(function (){
                '';
                // console.log('running redirect')
                if( countRef.current  === 0) {
                    clearInterval(t);
                    setTimer(null);
                    countRef.current =  0;
                    setCount(0);
                    if(redirectLink != ''){
                        //window.location.replace(redirectLink);
                        
                        window.location.href =redirectLink
                        //router.replace(redirectLink)
                    }
                   
                }else{
                    setCount(countRef.current-1);
                    countRef.current =  countRef.current-1;
                }
               

                
                },1000);
            setTimer(t)
        }
    },[showRedirect])
  return (
   <RedirectPage showRedirect={showRedirect}>
        <div className='w-100 h-100 d-flex justify-content-center align-items-center flex-column'>
        <div  class="spinner-border text-light hs-spinner-3" role="status"></div>
            <div className='text-white font-w700 font-12'> Please Don't refresh.</div>
            <div className='text-white font-w700 font-30 d-none'>
               Your been redirect to Payment Gateway page in {count}.
            </div>
            
        </div>
   </RedirectPage>  
  )
}
const mapStateToProps = ({PaymentRedirectPage}) => {
    return {
        showRedirect: PaymentRedirectPage.redirectPage,
        redirectLink: PaymentRedirectPage.redirectLink
        
    };
};
export default connect(mapStateToProps)(withRouter(RedirectPagePayment));