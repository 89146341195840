import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';


function MachineFirewallCard({ item, setShowFirewallModal }) {
    // console.log(item)

    const { firewallgroupid, groupname, description } = item;

    return (
        <Card className="col-12 col-lg-4 pt-4 px-2">
           <div className="row">
           <div className='col-10'>
                <div className='text-primary '>
                    <Link to={`/firewall/${firewallgroupid}`}>{groupname}</Link>
                </div>
                <div>
                    {description}
                </div>
            </div>

            <div className='col-2'>


                <div onClick={() => setShowFirewallModal({
                    isShow: true,
                    firewallId: firewallgroupid,
                    groupname: groupname
                })} className='w-100 text-center'><i className='cursor-pointer fa fa-trash'></i></div>

            </div>
           </div>
        </Card>
    )
}

export default MachineFirewallCard;