import { replace } from 'lodash';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { Instances_SSH_Service } from '../../../../../services/SSH/InstanceSHHServies';
import { genrateDotKeyFile } from '../../../../../utils/KeyFileGen';
import FormInputError from '../../../hireserver/components/FormInputError'
import SectionInput from '../../../hireserver/SharedComponents/SectionInput/SectionInput'
import SpacerHS_TWO from '../../../hireserver/Spaces/SpacerHS_TWO';
import { handlerAlert } from '../InstancesListComponents/DropDownInstancesList';




function SSHSection({ setSShKey, SShkey, sshKeyErr, setSshkeyHash }, ref) {
    const [loading, setLoading] = React.useState(false);
    const [customError, setCustomError] = React.useState(false);

    async function generateKeyHandler(e) {

        e.preventDefault(); //prevent form submit. Just in case. Button type === button should not trigger the submit event but Just in case.
   
        if (SShkey === '') {
            setCustomError(true);
            setTimeout(() => { setCustomError(false) }, 3000)
            return;
        }
        try {
            setLoading(true);
            const res = await Instances_SSH_Service();
            let s = JSON.parse(res.data.result)
            let news = s.privateKey.replaceAll('\n', '')
            let n = news.replaceAll('\r', '');

            let removea = n.replace('-----BEGIN RSA PRIVATE KEY-----', '');
            let removeb = removea.replace('-----END RSA PRIVATE KEY-----', '');


            let maxLen = 64;
            let str = '';
            for (let i = 0; i < removeb.length; i++) {
                if (((i + 1) % maxLen) == 0) {
                    str = str + '\n';
                }
                str = str + removeb[i];
            }



            let newSrtr = `-----BEGIN RSA PRIVATE KEY-----\n${str}\n-----END RSA PRIVATE KEY-----`;

            // console.log(newSrtr)
            //// console.log(s.privateKey)
            // console.log( encodeURIComponent(s.publicKey))
            let enCodeURL = encodeURI(s.publicKey)?.replaceAll('/', "%2F")?.replaceAll("+", "%2B")

            
            setSshkeyHash(enCodeURL)



            //let res = JSON.parse(resJSON.data);
            let privatekey = res.data.privatekey;
            // // console.log(privatekey)
            // // console.log(res.data.publickey, 'public');
            genrateDotKeyFile(newSrtr, `${SShkey}.key`);
            setLoading(false);
        } catch (error) {
            handlerAlert("danger", error.message, "danger");
            setLoading(false);
        }



    }
    return (
        <div className='' ref={ref}>
            <SectionInput heading={true} endingText="name" headingClasses="text-primary" title="SSH Key" secTitle="" subText="Please provide SSH Key file name in order to generate SSH key file." inputValue={SShkey} setInputValue={setSShKey} />
            {sshKeyErr && <FormInputError message="Please Enter your SSH Key" />}
            {customError && <FormInputError message="Please Enter your SSH Key" />}
            <SpacerHS_TWO />
            <button type='button' onClick={generateKeyHandler} className='btn w-150p btn-sm hsButton-bg text-white hs_border_radius'>
                {!loading && 'Create Key Pair'}
                {loading && <Spinner animation="border" role="status" style={{ width: '20px', height: '20px' }}>
                    <span className="visually-hidden"></span>
                </Spinner>}
            </button>
        </div>
    )
}

export default React.forwardRef(SSHSection)