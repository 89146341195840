import { cloneDeep } from 'lodash';
import React from 'react'
import { profileOrderListService } from '../../../../../../services/Profile/ProfileOrdersServices';
import { handlerAlert } from '../../../Instances/InstancesListComponents/DropDownInstancesList';
import { MDBDataTable } from 'mdbreact';
import { getCurrencySymbol } from '../../../../../../utils/Currency';
import ProfileOrderStatus from './Components/ProfileOrderStatus';
import { checkResData } from '../../../../../../utils/Helpers';
import LoaderHS from '../../../../hireserver/components/LoaderHS';
import InvoicesTable from './InvoicesTable';
import { paginationLabel, TABLE_ENTIRES } from '../../../../../../utils/Emuns';
import CurrencyFC from '../../../../hireserver/components/Currency/CurrencyFC';
import OrderMethod from './OrderMethod';
import SpacerHS_TWO from '../../../../hireserver/Spaces/SpacerHS_TWO';

function ProfileOrdersTab() {
  
  const [loading,setLoading] = React.useState(true);

  const [col, setCols] = React.useState([
    {
      label: "Created On",
      field: "createddate",
      sort: "asc",
     
      attributes: {className: " col-3"  },
    },{
      label: "Order Id",
      field: "orderid",
      sort: "asc",
      attributes: {className: "text-center col-3"  },
     
    },
    {
      label: "Amount",
      field: "amount",
      sort: "asc",
      attributes: {className: "text-center col-3"  },
     
    },
    {
      label: "Status",
      field: "orderstatus",
      sort: "asc",
     
      attributes: {className: "text-center col-3"  },
    },
    
   
  ]);

  const [formatData, setFormatData] = React.useState({
    columns: [],
    rows: []
  })
  
  React.useEffect(function (){
    getOrdersList();
  },[]);



  

  async function getOrdersList(){
    const resData = await profileOrderListService();
   
    const isValid = checkResData(resData,true);
    if(isValid === false){
      setLoading(false);
      setFormatData({
        columns: cloneDeep(col),
        rows: []
      });
      return;
    }
    // console.log(resData)


    const parseData = JSON.parse(resData.data.result);
    // console.log(parseData);

    let rowComps = [];

    for(let i=0;i<parseData.length;i++){
     
      const {orderid,totalamount,orderno,orderstatus,statusid,currencytype,createddate,reforderid,totalorderamount} = parseData[i];
      let rowData = {
        createddate:<div className=''>{createddate}</div>,
        orderid:<div className='text-center'>{orderno}</div>,
        amount:<div className='text-center'><CurrencyFC name={currencytype} val={totalorderamount} /></div>,
        // amount:<div className='text-center'>{`${getCurrencySymbol(currencytype,totalamount)}`}</div>,
        orderstatus:<ProfileOrderStatus orderid={reforderid} status={orderstatus} />,
        search_name:orderno

      }

      rowComps.push(rowData);
    }

    setFormatData({
      columns: cloneDeep(col),
      rows: cloneDeep(rowComps)
    });
    setLoading(false);

  }

  function onSearchHandler(value){
    const newData =  cloneDeep(formatData);
    setFormatData(newData);
  }

  function onPageChangeHandler({ activePage, pagesAmount }) {
    const newData =  cloneDeep(formatData);
    setFormatData(newData);
    
    // const ls = document.querySelectorAll('.instanceListTable .instacesClass');
    // // console.log(ls, 'ldsa')
  }


  if(loading){
    return <LoaderHS/>
  }

  return (
    <div className="row">
      <div className="col-xl-12" style={{ position: 'relative' }}>
      <OrderMethod/>
      </div>
      <div className='font-16 px-3 text-primary'>
                Payment Fail
            </div>
            <SpacerHS_TWO/>
    <div className="col-xl-12" style={{ position: 'relative' }}>
      <div className="table-responsive">
        <div className="display mb-4 dataTablesCard customer-list-table  instances-table-card">
          <MDBDataTable paging={Boolean(formatData.rows.length>TABLE_ENTIRES)} paginationLabel={paginationLabel} onSearch={onSearchHandler} disableRetreatAfterSorting={true} onPageChange={onPageChangeHandler} className='instanceListTable' entriesOptions={[1, 5, 20, 25]}     entries={TABLE_ENTIRES}  data={formatData}  />


        </div>
      </div>
    </div>
    <div className="col-xl-12" style={{ position: 'relative' }}>
      <InvoicesTable/>
      </div>
      
  </div>
  )
}

export default ProfileOrdersTab
