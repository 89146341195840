import React from 'react';
import bee from '../../../../../images/hireserver/imgs/bee/list.png';
import ImageHs from '../../ImageHs';

function EmptyBee({ message }) {
    return (
        <div className='w-100 align-items-center d-flex flex-column'>
            <ImageHs width="100px" alt={"bee"} src={bee} />
            <div>
                {message}
            </div>
        </div>
    )
}

export default EmptyBee