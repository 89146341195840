import React from "react";
import InputLabelUp from "./InputLabelUp/InputLabelUp";

function PasswordInputHS({ value, changeHandler, placeholder,inputStyles, labelStyles,error,focusOutHandler,focusHandler}) {
    
    const [typeVal,setTypeVal] = React.useState('password');
    const [errorVal,setErrorVal] = React.useState(false)
    const [timeOut,setTimeOut] = React.useState(null);
    // React.useEffect(function (){
    //   '';
    //     if(error != ''){
    //         setErrorVal(true);
    //         // if(timeOut){
    //         //     clearTimeout(timeOut);
    //         // }
    //         // let timerId = setTimeout(()=>{
    //         //     setErrorVal(false);
    //         //     changeHandler({
    //         //         val: value,
    //         //         error: false,
    //         //     })
    //         // },3000);
    //         // setTimeOut(timerId);
    //     }
    //     // console.log('running useEffect p')
    // },[error]);

    function handleClickToggle(e){
        if(typeVal === 'password'){
            setTypeVal('text');
        }else{
            setTypeVal('password');
        }
    }

//    // console.log(value)

  return (
    <React.Fragment>
        <label className={`position-relative ${labelStyles}`}>
    {
       value!='' &&   <span
          className="position-absolute z_index_9 cursor-pointer d-flex justify-content-center align-items-center"
          style={{
            top: 0,
            bottom: 0,
            right: '10px',
          }}
          onClick={handleClickToggle}
        >
          <i className={`fa passwordiconhs  text-black-50 ${typeVal == 'password' ? 'fa-eye-slash':'fa-eye passwordiconhs text-primary'}`}></i>
        </span>
    }
      {/* <input
        className={`form-control ${inputStyles} input-default form-control-sm p-2 hs_borderRadius-4 font-14 `}
        placeholder={placeholder}
        value={value}
        type={typeVal}
        onChange={changeHandler}
      /> */}
       {!focusHandler && !focusOutHandler && <InputLabelUp classNames={inputStyles} setValue={changeHandler}  value={value} placeholder={placeholder} id="passwordInput" type={typeVal}  />}
       {!focusOutHandler && focusHandler && <InputLabelUp classNames={inputStyles} setValue={changeHandler}  value={value} placeholder={placeholder} id="passwordInput" type={typeVal}  />}
       {focusOutHandler && !focusHandler && <InputLabelUp focusOutHandler={focusOutHandler} classNames={inputStyles} setValue={changeHandler}  value={value} placeholder={placeholder} id="passwordInput" type={typeVal}  />}
    </label>
    {/* {errorVal && <div className="text-danger">{"ldjajskl"}</div>} */}
    </React.Fragment>
  );
}

export default React.memo(PasswordInputHS);
