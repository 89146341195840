import { SEVERNAME } from "./API_ENDPOINTS";

export function getFirewallListURL(userId){
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getfirewallgroups/contactid/${userId}`
}

export function getUserAllMachinesURL(userId){
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/machinesautocomplete/contactid/${userId}`;
}

export function getBoundTypesURL(){
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/firewallruletypes`;
}

export function saveGroupInfoURL(){
    // return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/savefirewallgroupinfo`;
   return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/createfirewallgroups`
}

export function saveGroupRulesURL(){
    return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/savefirewallruleinfo`;
}

export function getFirewallRulesURL(firewallgroupid){
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getfirewallrules/firewallgroupid/${firewallgroupid}`;
}

export function getFireWallGroupsURL(firewallgroupid){
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getfirewallgroupinfo/firewallgroupid/${firewallgroupid}`;
}

export function postdeleteFirewallGroupURL(){
    return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/deletefirewallgroups`;
}

export function getFireWallMachinesByIdURL(firewallgroupid){
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getselectedmachienes/firewallgroupid/${firewallgroupid}`;
}