import React from 'react'
import HeadingHS from '../../../../hireserver/controls/HeadingHS'
import LabelInput from '../../../../hireserver/controls/LabelInput'
import DatabaseContext from '../../DatabaseContext/DatabaseContext';

export default function DatabaseHeading() {
  const {custerLabel,setCusterLabel} = React.useContext(DatabaseContext);

  return (
    <div className='col-12 col-lg-5'>
        <HeadingHS as="h3" title="Name your Cluster" />
        <LabelInput placeholder='Name' inputValue={custerLabel} setInputValue={setCusterLabel}  heading="Cluster Label" />
    </div>
  )
}
