import React from 'react'
import StaticFireWall from '../../../../../../../utils/StaticsClasses.js/StaticFirewall';
import FirewallContext from '../FirewallContext/FirewallContext';

function FireFooter() {
    const { handlerFireSubmit, boundDetailsLoading,submitLoading,setSubmitLoading } = React.useContext(FirewallContext);
    if (boundDetailsLoading) {
        return <React.Fragment></React.Fragment>
    }
    return (

        <React.Fragment>
            {
               !StaticFireWall.firewallEdit &&   <div className='text-right'>
                <button onClick={handlerFireSubmit} className='btn hs-signin-btn hs_borderRadius-4'>
                    
                    {submitLoading ? <React.Fragment>
                      <div className="spinner-border text-light" role="status">

                      </div> Creating..
                    </React.Fragment> : 'Create security group'}
                </button>
               
            </div>
            }
            {
                StaticFireWall.firewallEdit &&   <div className='text-right'>
                <button onClick={handlerFireSubmit} className='btn hs-signin-btn hs_borderRadius-4'>
                   
                    {submitLoading ? <React.Fragment>
                      <div className="spinner-border text-light" role="status">

                      </div> Saving..
                    </React.Fragment> : ' Save security group'}
                </button>
            </div>
            }
        </React.Fragment>
    )
}

export default FireFooter;