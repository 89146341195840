import React from 'react'
import { Card } from 'react-bootstrap'
import CurrencyFC from '../../../../hireserver/components/Currency/CurrencyFC'

function DisplayAmountCard({currencyname,amount,text}) {
  return (
   <Card className='col-12 col-md-3 mb-0 hs_borderRadius-4 p-5  shadow-lg justify-content-center align-items-center'>
    <div className='font-30 text-danger font-w700'>
      <CurrencyFC name={currencyname} val={amount} />
      </div>
    <div className='hs_text-light'>{text}</div>
   </Card>
  )
}

export default DisplayAmountCard