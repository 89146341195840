import { GetBranchService, twoViewService } from "../../../../../services/InstancesServices/InstancesListService";

export async function instancesViewHelper( machineCode,nodeType,clusterid){
   try {
    const response = await twoViewService(clusterid);
    const brunchURL = await GetBranchService(clusterid); 
        let brunchURLRes = '';
        //'';
        if(brunchURL.data.result != null ||brunchURL.data.result != '' ){
          brunchURLRes = JSON.parse(brunchURL.data.result)[0];
         
        }
        let branchid = brunchURLRes['branchid'];
        let branchurl = brunchURLRes['branchurl'];
        // console.log(brunchURLRes)
        
        // console.log(JSON.stringify(response.data));
        let r = JSON.stringify(response.data)
        let s = JSON.parse(r)
        let responsedata = JSON.parse(s.result)
        let token = responsedata.data.ticket
        // document.cookie = `PVEAuthCookie=${token};path="/";domain=bizgaze.com;`
        document.cookie = `PVEAuthCookie=${token};path=/;domain=hireserver.com;`
        //					let url = `https://proxmox.bizgaze.com:8006/?console=kvm&novnc=1&vmid=${machineid}&vmname=uservm&node=pve&resize=off&cmd=`
        let url = `https://${branchurl}/?console=kvm&novnc=1&vmid=${machineCode}&vmname=uservm&node=${nodeType}&resize=off&cmd=`

        window.open(url, '_blank');
        return {
            isError:false,
            message:''
        }
   } catch (error) {
       
        return {
            isError:true,
            message:error['message']
        }
   }


    // axios(config)
    //   .then(async function (response) {
    //     const brunchURL = await GetBranchService(loaction); 
    //     let brunchURLRes = '';
    //     //'';
    //     if(brunchURL.data.result != null ||brunchURL.data.result != '' ){
    //       brunchURLRes = JSON.parse(brunchURL.data.result)[0];
         
    //     }
    //     let branchid = brunchURLRes['branchid'];
    //     let branchurl = brunchURLRes['branchurl'];
    //     // console.log(brunchURLRes)
        
    //     // console.log(JSON.stringify(response.data));
    //     let r = JSON.stringify(response.data)
    //     let s = JSON.parse(r)
    //     let responsedata = JSON.parse(s.result)
    //     let token = responsedata.data.ticket
    //     // document.cookie = `PVEAuthCookie=${token};path="/";domain=bizgaze.com;`
    //     document.cookie = `PVEAuthCookie=${token};path="/";domain=hireserver.com;`
    //     //					let url = `https://proxmox.bizgaze.com:8006/?console=kvm&novnc=1&vmid=${machineid}&vmname=uservm&node=pve&resize=off&cmd=`
    //     let url = `https://${branchurl}/?console=kvm&novnc=1&vmid=${machineCode}&vmname=uservm&node=${nodeType}&resize=off&cmd=`

    //     window.open(url, '_blank')
    //   })
    //   .catch(function (error) {
    //     handlerAlert('danger',error['message'],'danger')
    //   });

}