import React from 'react'
import LoaderHS from '../../../../../hireserver/components/LoaderHS';
import CustomTabs from '../../../../../hireserver/CustomTabs';
import ModalHs from '../../../../../hireserver/ModalHs'
import { getLogOutputByIdLogService, getLogOutputByIdLog_Page_Service } from '../../../../../../../services/InstancesServices/InstancesListService';
import { isJsonStringHelper } from '../../../../../../../utils/Helpers';
import OutputView from './OutputView';




function AreaScriptOutput({ showOutModal, currScriptLogId, handleOutputClose, output, fullData, setOutputpage, outputPage }) {

  const [outputFilter, setOutPutFilter] = React.useState(null);

  const [detailCom, setDetailsCom] = React.useState(null);
  const [tabData, setTabData] = React.useState([
    {
      name: 'Output',
      component: <OutputView setOutputpage={setOutputpage} outputPage={outputPage} setOutput={setOutPutFilter} output={outputFilter} />
    },
    {
      name: 'Tokens',
      component: <div>he;p</div>
    }
  ]);

  const [loading, setLoading] = React.useState(false);

  function getTokenHTML(params = []) {
    return params.map(function ({ key, value }, i) {
      return <KeyValueScript parent={key} value={value} />
    })
  }

  function getDetailsHTML({ params, sshpassword, sshport, sshuser, sship }) {

    return <div>
      <ul>

        <KeyValueScript parent={'IP'} value={sship} />
        {/* <li>
        <div className="col-sm-4">User</div>
        <div className="col-sm-8">{{sshuser}}</div>
      </li> */}
        <KeyValueScript parent={'User'} value={sshuser} />
        {/* <li>
        <div className="col-sm-4">Port</div>
        <div className="col-sm-8">{{sshport}}</div>
      </li> */}
        <KeyValueScript parent={'Port'} value={sshport} />
      </ul>
      <div className='border-top'>Tokens</div>
      <ul>
        {getTokenHTML(params)}
      </ul>
    </div>
  }

  React.useEffect(function () {
    debugger;
    if (!output) {
      setOutPutFilter('');
      return;
    };
    if (!fullData) return;

    console.log(fullData);

    let newFullData = fullData['length'] ? fullData[0] : fullData;
    // setLoading(true);
    const { scriptstatus, description } = newFullData;
    const desRes = JSON.parse(description);
    const { params, sshpassword, sshport, sshuser, sship } = desRes;
    const detailHTML = getDetailsHTML({ params, sshpassword, sshport, sshuser, sship });
    setDetailsCom(detailHTML);
    if (scriptstatus == 3) {
      setOutPutFilter([<div className='font-12' style={{ whiteSpace: "pre-wrap" }}>{newFullData.output}</div>]);
      return;
    }

    let str = [];
    let res = '';
    const isSafe = isJsonStringHelper(output);
    if (isSafe) {
      res = JSON.parse(output);
    } else {
      res = output
    }


    // for (let i = 0; i <( res.length<5000 ? res.length : 5000); i++) {
    //   str.push(<div className='font-12' style={{ whiteSpace: "pre-wrap" }}>{res[i]}</div>);
    // }



    // setLoading(false);
    setOutPutFilter(res);

  }, [output, fullData]);

  React.useEffect(function () {
    console.log("okkasdhaskjdjk");
    setTabData([
      {
        name: 'Output',
        component: <OutputView setOutputpage={setOutputpage} outputPage={outputPage} currScriptLogId={currScriptLogId} setOutput={setOutPutFilter} output={outputFilter} />
      },
      {
        name: 'Tokens',
        component: <div>
          {detailCom}
        </div>
      }
    ])
  }, [outputFilter, detailCom,outputPage]);






  return (
    <ModalHs size="lg" classNameMain="hs_borderRadius-4 pb-0  position-relative" footerNone={true} isNeed={false} showModalHS={showOutModal} btnTitle="Yes" >
      {/* <div style={{
        top:0,
        right:0
      }} className='position-absolute align-items-end d-flex d-gap-10 justify-content-end'>
  
      
      </div> */}
      {loading ? <LoaderHS /> : <div className='w-100'>
        <CustomTabs tabData={tabData} />
        <div className='d-flex pt-2 justify-content-end'>
          <span className='p-2' onClick={handleOutputClose}>
            <button className='btn py-2 bg-danger text-white hs_borderRadius-4'>Close</button>
          </span>
        </div>
      </div>}
    </ModalHs>
  )
}

export default AreaScriptOutput;

export function KeyValueScript({ parent, value }) {
  return <li className='d-flex'>
    <div className="col-sm-4  text-break">{parent}</div>
    <div className="col-sm-8 text-break">{value}</div>
  </li>
}

