import React, { useState } from "react";
/// React router dom
import { Switch, Route } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Dashboard
import Home from "./components/Dashboard/Home/Home";
import Createinstance from "./components/Dashboard/Home/createinstance";


/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";


//Scroll To Top
import ScrollToTop from "./layouts/ScrollToTop";
//import Firewall from "./components/Firewalls/firewall";
import Firewall from "./components/Dashboard/Firewalls/Firewall";
import Firewalldetails from "./components/Dashboard/Firewalls/components/firewalldetails";
import Marketplacedetails from "./components/Dashboard/MarketPlace/Marketplacedetails";
import CreateDatabaseContext from "./components/Dashboard/Database/DatabaseContext/CreateDatabaseContext";
import CreateNodeBalanceContext from "./components/Dashboard/NodeBalance/NodeBalanceContext/CreateNodeBalanceContext";
import Instances from "./components/Dashboard/Instances/Instances";
import BareMetal from "./components/Dashboard/baremetal/BareMetal";
import Profile from "./components/Dashboard/Profile/Profile";
import AuthAlertModal from "./indexComponents/AuthAlertModal";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallBackComponent from "./components/Dashboard/ErrorFallBackComponent/ErrorFallBackComponent";
import PaymentMain from "./components/hireserver/components/payment/PaymentMain";
import RedirectPage from "./components/hireserver/components/redirectPage/RedirectPage";
import RedirectPagePayment from "./components/hireserver/components/payment/RedirectPagePayment";
import TestPage from "./components/hireserver/Test/TestPage";
import PaymentInfoForm from "./indexComponents/PaymentInfoForm";
import Support from "./components/Dashboard/Support/Support";
import NotificationPage from "./layouts/nav/HeaderComponents/Notification/NotificationPage";
import FooterBee from "./components/hireserver/components/FooterBee/FooterBee";
import NotificationSidebar from "./layouts/nav/HeaderComponents/Notification/NotificationSidebar";
import PaymentBillingPage from "./indexComponents/PaymentInfoFormComponents/PaymentBillingPage/PaymentBillingPage";
import ScriptsMarket from "./components/Dashboard/MarketPlace/Components/ScriptsMarket/ScriptsMarket";
import ScriptCreate from "./components/Dashboard/MarketPlace/Components/ScriptsMarket/ScriptCreate";
import HomeInstances from "./pages/Home/HomeInstances";
import ClearAllModal from "./layouts/nav/HeaderComponents/Notification/modalclearall/ClearAllModal";
import Clusters from "./components/Dashboard/clusters/Clusters";



const Markup = () => {
  
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");
  const [activeEvent, setActiveEvent] = useState(!path);
  
  const routes = [
    /// Dashboard
   // { url: 'watch', component: Customerlistnew },

   //billing test form
   {url:"paymentbilling",component:PaymentBillingPage},
   
    { url: "databases", component: CreateDatabaseContext },
    { url: "firewall", component: Firewall },
    { url: "nodebalance", component: CreateNodeBalanceContext },
    { url: "baremetal", component: BareMetal },
    { url: "dashboard", component: Home },
    
    // { url: "order", component: Order },
    // { url: "instances", component: Orderlist },
    // { url: "instances", component: Customerlistnew },
    { url: "instances", component: Instances },
    { url: "support", component: Support },
    // {url:'instance',component:Instances},
    //{ url: "customer-list", component: Customerlist },
    //{ url: "task", component: Task },
    { url: "createinstance", component: Createinstance },

    { url: "firewalldetails", component: Firewalldetails },
    { url: "marketplace", component: Marketplacedetails },

    {url:'notifications',component:NotificationPage},

    /// Apps
    { url: "profile", component: Profile },
  
    /// pages
    { url: "signup", component: Registration },
    { url: "page-lock-screen", component: LockScreen },
    { url: "login", component: Login },
    { url: "page-error-400", component: Error400 },
    { url: "page-error-403", component: Error403 },
    { url: "page-error-404", component: Error404 },
    { url: "page-error-500", component: Error500 },
    { url: "page-error-503", component: Error503 },
    {url:"payment",component:PaymentMain},
    {url:"testpage",component:TestPage},
    {url:"myscripts",component:ScriptsMarket},
    {url:"clusters",component:Clusters},
    // {url:"createscripts",component:ScriptCreate},
    // { url: "", component: Orderlist },
    { url: "/instances", component: Instances },
    { url: "/", component: HomeInstances },
{url:"*",component:Error400}
  ];
  const alertRef = React.useRef(null);
  const containerRef = React.useRef(null);

  function CalcAlertPostion(){
    let el = alertRef.current;
    let container = containerRef.current;
    //// .log(alertRef,'el')

    return 50;
  }



  return (
    <React.Fragment>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}`}
      >
        {!pagePath && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(false)}
            onClick3={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? "rightside-event" : ""} ${!pagePath ? "content-body" : ""
            }`}
        >
           <ErrorBoundary FallbackComponent={ErrorFallBackComponent} onError={(error)=> console.log(error)}>
          <div ref={containerRef} style={{ minHeight: '100vh' }}
            className={`${!pagePath ? "container-fluid" : ""} bg-white mainContainerHS`}
           //style={{ minHeight: window.screen.height - 60 }}
          >
          
           <Switch>
              {routes.map((data, i) => {
               
                let newPathUrl = data.url === '*' ? data.url : `/${data.url}`;
                return <Route
                key={i}
                path={newPathUrl}
                component={data.component}
              />
              })}
              

              
            </Switch>
           
          </div>
          </ErrorBoundary>
          {/* <FooterBee/> */}
        </div>
        {/* {!pagePath && <Footer />} */}
     
      </div>
      <ScrollToTop />
      {/* <ModalHs classNameMain="authModalAlert" size="lg" handleClose={handleModalClose} okayHandler={handleDone} showModalHS={authAlertReducer} btnTitle="Stop Machine" isNeed={false} >
        <div className='w-100 text-center'>
              <div><h1>Your Session was been expired!</h1></div>
              <div><h2>Your been redirect to login page in <span className="text-primary">5 seconds</span></h2></div>
              <img className="redirectAuthLogo" alt="logo_hireserver" src={beeImg} />
              
        </div>
      </ModalHs> */}
    <AuthAlertModal/>
    <PaymentInfoForm/>
    <RedirectPagePayment/>
    <NotificationSidebar/>
    <ClearAllModal/>
   
    </React.Fragment>
  );
};


export default Markup;
