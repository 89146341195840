import React from 'react'
import { getDocInstancesService } from '../../../../../../../services/Profile/ProfileOrdersServices';
import { handlerAlert } from '../../../../Instances/InstancesListComponents/DropDownInstancesList';
import { SEVERNAME } from '../../../../../../../API_ENDPOINTS/API_ENDPOINTS';


function DownloadInvoice({orderid}) {

    const [loading,setLoading] = React.useState(false);

    function download(url, fileName) {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = function (event) {
            if (this.status == 200) {
                _saveBlob(this.response, fileName);
            }
        };
        xhr.send();
    }

    function _saveBlob(response, fileName){
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(response, fileName);
            
        }else {
            _html5Saver(response, fileName);
            
        }
        
    }

    function _html5Saver(blob, fileName) {
        var a = document.createElement("a");
        document.body.appendChild(a);
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        document.body.removeChild(a);
        setLoading(false);
    }

    async function getHTMLDoc(){
        if(loading)return;
        setLoading(true);
        const res = await getDocInstancesService(orderid);
      
        const jsonRes = JSON.parse(res.data.result)['FilePath'];
       
        download(`${SEVERNAME}/${jsonRes}`,orderid);
      
    }
  return (
    <div className='text-center'>
        <span onClick={getHTMLDoc} className='cursor-pointer p-1 hs-signin-btn hs_borderRadius-4 font-16 px-2'><i className='fa fa-print'></i></span>
    </div>
  )
}

export default DownloadInvoice