import React from 'react'
import { Form } from 'react-bootstrap'
import NodeBalanceHeader from './Components/NodeBalanceHeader'
import NodeBalanceRegion from './Components/NodeBalanceRegion'
import NodeBalanceSettings from './Components/NodeBalanceSettings'
import NodeBalancerFooter from './Components/NodeBalancerFooter'

export default function NodeBalanceForm() {
  return (
    <div style={{ position: 'relative' }}>
      <Form style={{ minHeight: '100vh',paddingBottom:'100px' }}>
        <NodeBalanceHeader />
        <hr />
        <NodeBalanceRegion />
        <hr />
        <NodeBalanceSettings />

      </Form>
      <NodeBalancerFooter/>
    </div>

  )
}
