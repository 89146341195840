import React from 'react'
import { Link } from 'react-router-dom'

const Offcanvas = ({setHsModal,title,form}) => {
  return (
    <div className='bg-white shadow position-fixed firewall_form'>
    {/* <div class="firwall_overlay" onClick={() => setHsModal(false)}></div> */}
    
    <Link to="/firewall"><div class="firwall_overlay" ></div></Link>
   
  {/* <Offcanvas  setHsModal={setHsModal} title={'Create test'} form={<Createform  setHsModal={setHsModal}/>}/> */}
  <div>
     <div className='d-flex justify-content-between px-4 pt-4'>
      <div className='fs-24'>{title} </div>
     
     <Link to="/firewall">
     <div className='fs-24 text-blue cursor-pointer'>x</div>
      </Link>
   </div>

   {form}
 </div>
 </div>
  )
}

export default Offcanvas