import React from 'react';
import { Link, withRouter } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { openPaymentInfoFormAction } from '../../../../store/actions/PaymentInfoActions/PaymentInfoAction';

function HeaderCreateButton({ isShowPayment, history }) {
    const disPatch = useDispatch();
    function handleInstanceCreate() {

        if (isShowPayment == 0 || isShowPayment == undefined) {
            disPatch(openPaymentInfoFormAction());
            return;
        }

        history.push('/instances/create')
    }

    function redirectHandler(e) {

        if (isShowPayment == 0) {
            disPatch(openPaymentInfoFormAction());
            return;
        }

        const name = e.target.getAttribute('data-name');
        if (name === "baremetal") {
            // to="/baremetal/create"
            history.push('/baremetal/create')
        } else if (name === 'firewall') {
            //  to="/firewall/create"

            history.push('/firewall/create')
        }
    }

    return (
        <Dropdown className='createButtonDropdown'>
            {
                <>
                    {isMobile ? <Dropdown.Toggle id="dropdown-basic">
                        <div>+</div>
                    </Dropdown.Toggle> :
                        <Dropdown.Toggle id="dropdown-basic" className='hs_borderRadius-4'>
                            <div className="dropdown-toggle btn btn-primary d-flex justify-content-center flex-row-reverse ">Create</div>
                        </Dropdown.Toggle>}
                </>
            }
            <Dropdown.Menu>
                <Dropdown.Item className='HeaderCreateButtonDropDownItem'>
                    <div onClick={handleInstanceCreate} className="dropdown-item ai-icon d-flex px-4 py-2 align-items-center border-bottom ">
                        <div className="flaticon-381-layer-1 drop_icon"></div>
                        <div className="item_list">
                            <p className="drop_name">Instance</p>
                            {/* <p className="text-body fs-12">High performance SSD Linux servers</p> */}
                        </div>
                    </div>
                </Dropdown.Item>
                {/* <Dropdown.Item className='HeaderCreateButtonDropDownItem'>
                    <div onClick={redirectHandler} data-name="baremetal"  className="dropdown-item ai-icon d-flex px-4 py-2 align-items-center border-bottom ">
                        <div className="drop_icon pointerEventNone">
                            <svg height="24" viewBox="0 0 21 24" width="21" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.75 5.238L5.044 7.995l4.706 2.638zm1.5 13.547l4.735-2.787-4.735-2.758zM4.338 9.374v5.245l4.588-2.668zm11.588-1.379L11.25 5.238v5.395zm-10.94 8.003l4.764 2.787V13.21zm11.646-1.38V9.375l-4.588 2.577zm4.339-.569a.8.8 0 00-.897-.66.814.814 0 00-.648.66l.03 2.218-2.824-1.648v.27c0 .48-.235.899-.647 1.138l2.706 1.559-1.823 1.049c-.353.24-.477.723-.236 1.079.144.212.353.36.618.36.147 0 .265-.03.382-.09l2.97-1.739a.783.783 0 00.369-.689zM9.75 18.785v3.117l-1.853-1.109c-.03-.03-.059-.03-.088-.06-.412-.12-.83.148-.941.54-.094.328.03.659.294.839l3 1.768c.117.06.235.12.382.12.147 0 .265-.03.382-.12l2.971-1.768c.03-.03.059-.03.088-.06a.808.808 0 00.383-1.05.78.78 0 00-1.03-.389c-.088.03-.147.09-.206.15l-1.882 1.139v-3.117l-.147.09c-.382.24-.882.24-1.265 0zm6.882-9.411l2.853-1.589-.03 2.188c0 .42.354.78.766.78.411 0 .779-.36.779-.78V6.466a.82.82 0 00-.368-.689S17.515 3.98 17.456 3.95c-.412-.12-.826.149-.941.57-.09.328.059.659.323.838l1.794 1.05-2.735 1.528.059.03c.382.24.647.66.647 1.109v.3zM4.338 14.589l-2.823 1.648.03-2.218a.752.752 0 00-.883-.63c-.323.066-.603.3-.633.63v3.507c0 .27.133.54.368.69l2.97 1.738c.118.06.236.12.383.09.412-.03.765-.39.735-.81 0-.24-.147-.48-.353-.629l-1.794-1.049 2.677-1.558a1.29 1.29 0 01-.647-1.14v-.27zm.706-6.594L2.28 6.466l1.795-1.049a.797.797 0 00.235-.21.783.783 0 00-.206-1.078.7.7 0 00-.794-.03L.515 5.717a.694.694 0 00-.265.18.729.729 0 00-.25.57v3.506c0 .42.368.78.78.78.411 0 .764-.36.764-.78l-.03-2.218 2.854 1.589v-.21c0-.48.235-.9.647-1.139zm6.088-2.817a1.202 1.202 0 00-1.264 0l-.089.06V2.12L7.956 3.229c-.294.3-.794.3-1.088-.03a.786.786 0 01.03-1.108c.06-.059.117-.12.205-.15.03-.03.059-.03.088-.06L10.132.112c.236-.15.53-.15.765 0l2.97 1.769c.354.24.477.723.236 1.079-.144.212-.353.36-.618.36a.583.583 0 01-.382-.12l-1.824-1.08v3.088z" fill="currentColor" fillRule="evenodd"></path>
                            </svg>
                        </div>
                        <div className="item_list pointerEventNone">
                            <p className="drop_name">Bare Metal</p>
                            <p className="text-body fs-12"></p>
                        </div>
                    </div>
                </Dropdown.Item> */}
                <Dropdown.Item className='HeaderCreateButtonDropDownItem d-none'>
                    <Link to="/nodebalance/create" className="dropdown-item ai-icon d-flex px-4 py-2 align-items-center border-bottom ">
                        <div className=" drop_icon">
                            <svg height="24" viewBox="0 0 21 24" width="21" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.75 5.238L5.044 7.995l4.706 2.638zm1.5 13.547l4.735-2.787-4.735-2.758zM4.338 9.374v5.245l4.588-2.668zm11.588-1.379L11.25 5.238v5.395zm-10.94 8.003l4.764 2.787V13.21zm11.646-1.38V9.375l-4.588 2.577zm4.339-.569a.8.8 0 00-.897-.66.814.814 0 00-.648.66l.03 2.218-2.824-1.648v.27c0 .48-.235.899-.647 1.138l2.706 1.559-1.823 1.049c-.353.24-.477.723-.236 1.079.144.212.353.36.618.36.147 0 .265-.03.382-.09l2.97-1.739a.783.783 0 00.369-.689zM9.75 18.785v3.117l-1.853-1.109c-.03-.03-.059-.03-.088-.06-.412-.12-.83.148-.941.54-.094.328.03.659.294.839l3 1.768c.117.06.235.12.382.12.147 0 .265-.03.382-.12l2.971-1.768c.03-.03.059-.03.088-.06a.808.808 0 00.383-1.05.78.78 0 00-1.03-.389c-.088.03-.147.09-.206.15l-1.882 1.139v-3.117l-.147.09c-.382.24-.882.24-1.265 0zm6.882-9.411l2.853-1.589-.03 2.188c0 .42.354.78.766.78.411 0 .779-.36.779-.78V6.466a.82.82 0 00-.368-.689S17.515 3.98 17.456 3.95c-.412-.12-.826.149-.941.57-.09.328.059.659.323.838l1.794 1.05-2.735 1.528.059.03c.382.24.647.66.647 1.109v.3zM4.338 14.589l-2.823 1.648.03-2.218a.752.752 0 00-.883-.63c-.323.066-.603.3-.633.63v3.507c0 .27.133.54.368.69l2.97 1.738c.118.06.236.12.383.09.412-.03.765-.39.735-.81 0-.24-.147-.48-.353-.629l-1.794-1.049 2.677-1.558a1.29 1.29 0 01-.647-1.14v-.27zm.706-6.594L2.28 6.466l1.795-1.049a.797.797 0 00.235-.21.783.783 0 00-.206-1.078.7.7 0 00-.794-.03L.515 5.717a.694.694 0 00-.265.18.729.729 0 00-.25.57v3.506c0 .42.368.78.78.78.411 0 .764-.36.764-.78l-.03-2.218 2.854 1.589v-.21c0-.48.235-.9.647-1.139zm6.088-2.817a1.202 1.202 0 00-1.264 0l-.089.06V2.12L7.956 3.229c-.294.3-.794.3-1.088-.03a.786.786 0 01.03-1.108c.06-.059.117-.12.205-.15.03-.03.059-.03.088-.06L10.132.112c.236-.15.53-.15.765 0l2.97 1.769c.354.24.477.723.236 1.079-.144.212-.353.36-.618.36a.583.583 0 01-.382-.12l-1.824-1.08v3.088z" fill="currentColor" fillRule="evenodd"></path>
                            </svg>
                        </div>
                        <div className="item_list">
                            <p className="drop_name">NodeBalancers</p>
                            {/* <p className="text-body fs-12">Ensure your services are highly available</p> */}
                        </div>
                    </Link>
                </Dropdown.Item>
                <Dropdown.Item className='HeaderCreateButtonDropDownItem'>
                    <div onClick={redirectHandler} data-name="firewall" className="dropdown-item ai-icon d-flex px-4 py-2 align-items-center border-bottom ">
                        <div className="drop_icon pointerEventNone">
                            <svg viewBox="0 0 215 215" height="24" width="21" xmlns="http://www.w3.org/2000/svg"><path d="M214.84 136.33a31.58 31.58 0 00-28.76-28.18A51.33 51.33 0 0099.79 59.5v26.29h33.27v28.18h33.26v35.43H26v-28.18H10.75a39 39 0 0035.88 49.54H186.5a31.37 31.37 0 0028.34-34.43zM66.5 93.04h59.3v20.92H66.5zM33.26 64.86H92.5v20.92H33.26zM0 93.04h59.27v20.92H0zm33.26 28.18H92.5v20.92H33.26zm66.53 0h59.27v20.92H99.79z" fill="currentColor"></path></svg>
                        </div>
                        <div className="item_list pointerEventNone">
                            <p className="drop_name">Firewall</p>
                            {/* <p className="text-body fs-12">Control network access to your Instances</p> */}
                        </div>
                    </div>
                </Dropdown.Item>
                <Dropdown.Item className='HeaderCreateButtonDropDownItem d-none'>
                    <Link to="/databases/create" className="dropdown-item ai-icon d-flex px-4 py-2 align-items-center border-bottom ">
                        <div className="drop_icon">
                            <svg viewBox="0 0 76.5 83.5" height="24" width="21" xmlns="http://www.w3.org/2000/svg"><g fill="currentColor"><path d="M66.89 48.9c-7.7 2.1-17.7 3.3-28.4 3.3s-20.8-1.2-28.4-3.3c-2.2-.6-4.2-1.3-5.9-2v22c0 5.2 15.3 9.4 34.3 9.4s34.3-4.2 34.3-9.4v-22c-1.8.8-3.7 1.4-5.9 2zm-6.3 19.8c-2.6 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2 4.6-4.6 4.6zM38.49 5.1c-19 0-34.3 4.2-34.3 9.4v25.4c3.6 2.4 14.8 6.1 34.3 6.1 19.4 0 30.7-3.7 34.3-6.1V14.5c-.1-5.2-15.4-9.4-34.3-9.4zm22.1 31.7c-2.6 0-4.6-2.1-4.6-4.6 0-2.6 2.1-4.6 4.6-4.6s4.6 2.1 4.6 4.6c.1 2.5-2 4.6-4.6 4.6zm-22.1-14.3c-20.9 0-32.3-4.9-32.3-7.4s11.4-7.4 32.3-7.4 32.3 4.9 32.3 7.4-11.5 7.4-32.3 7.4z"></path></g></svg>
                        </div>
                        <div className="item_list">
                            <p className="drop_name">Database</p>
                            {/* <p className="text-body fs-12">High-performance managed database clusters</p> */}
                        </div>
                    </Link>
                </Dropdown.Item>
                <Dropdown.Item className='HeaderCreateButtonDropDownItem d-none'>
                    <Link to="/Marketplacedetails" className="dropdown-item ai-icon d-flex px-4 py-2 align-items-center ">
                        <div className="drop_icon">
                            <svg height="24" viewBox="0 0 20 24" width="21" xmlns="http://www.w3.org/2000/svg"><path d="M15.325 12.817L10 9.867l-5.325 2.95v5.901L10 21.668l5.325-2.95zM.5 23.5h19V8.035H.5zM15.437 6.64l-1.111-.043c.076-1.783-.74-3.455-2.13-4.363a3.965 3.965 0 00-4.392 0c-1.39.908-2.206 2.58-2.13 4.363l-1.111.044c-.091-2.15.911-4.176 2.616-5.289a5.095 5.095 0 015.642 0c1.705 1.113 2.707 3.14 2.616 5.289z" fill="currentColor" fillRule="evenodd"></path></svg>
                        </div>
                        <div className="item_list">
                            <p className="drop_name">MarketPlace</p>
                            {/* <p className="text-body fs-12">Deploy applications with ease</p> */}
                        </div>
                    </Link>
                </Dropdown.Item>

            </Dropdown.Menu>
        </Dropdown>
    )
}

const mapStatesToProps = ({ authAlertReducer, auth, PaymentInfoReducer }) => {
    return {

        isShowPayment: PaymentInfoReducer?.paymentData?.defaultaddressid,
        currencyname: PaymentInfoReducer?.paymentData?.currencyname
    };
};

export default connect(mapStatesToProps)(withRouter(HeaderCreateButton));