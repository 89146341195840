import React from 'react'

function InputSuccessBackground({message}) {
  return (
    <div className='bg-success-alt font-12 d-flex d-gap-10 fadeOutHs justify-content-center align-items-center p-2 hs_borderRadius-4 mb-3 '>
        <div>
       <span>
        <i className='fa fa-check-circle font-14 text-primary'></i>
    </span>
       </div>
       <div>{message}</div>
       
    </div>
  )
}

export default InputSuccessBackground