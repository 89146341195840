import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import reportWebVitals from "./reportWebVitals";

import ThemeContext from "./context/ThemeContext";
import { ErrorBoundary } from 'react-error-boundary'
import ToggleSideContext from "./context/toggleSideBar/ToggleSideContext";
function ErrorFallback({ error }) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre style={{ color: 'red' }}>{error.message}</pre>
        </div>
    )
}



ReactDOM.render(
    // <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter basename='/'>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <ToggleSideContext>
                    <ThemeContext>
                        <App />
                    </ThemeContext>
                    </ToggleSideContext>
                </ErrorBoundary>
            </BrowserRouter>
        </Provider>,
    // </React.StrictMode>,
    document.getElementById("root")
);
reportWebVitals();
