import React from 'react';
import { Dropdown, FormControl } from 'react-bootstrap';
import OptionCardDropDown from './OptionCardDropDown';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
  
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}

  </a>
));

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = React.useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >

        <ul className="addtest" style={{ maxHeight: '300px' }}>
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children?.toLowerCase().startsWith(value),
          )}
        </ul>
      </div>
    );
  },
);

function CustomOption({ item,isActive,thisHead }) {
  const { singleValue, name, price, subTitle, isHeading,subPrice } = item;
  const fontDark = isActive && !thisHead ? 'text-primary' : '';
  // const padding = subTitle ? 'CustomOption_hs px-3 py-2' : 'CustomOption_hs px-3 py-3'
  // const isPadding = subTitle ? 'py-1' : 'py-3';
  const addBorder = isHeading ? `CustomOption_hs px-3 ${'isPadding'} border d-flex flex-column justify-content-center` : 'CustomOption_hs px-3 py-3';
  const styles = isHeading ? {height:'50px'} : {};
  return (
    <div style={styles} className={addBorder}>
      <div className='d-flex justify-content-between'>
        <div>{name}</div>
        <div className={fontDark}><span>{price}</span> {thisHead && <i className='fa fa-angle-up'></i> } </div>
      </div>
      {
        subTitle && <div className='d-flex justify-content-between font-12'>
       <div>{subTitle}</div>
        <div>{subPrice}</div>
        </div>
      }
    </div>
  )
}


export default function CustomDropDown({ data,selecthandler,disabled }) {
 
  return (
    <Dropdown  className={`customDropSelect_hs ${disabled? 'pointerEventNone':'' }`}>
      <Dropdown.Toggle  as={CustomToggle} id="dropdown-custom-components">
       {/* <CustomOption item={data[0]} isActive="true"  /> */}
       {data.map(function (item,i){
          return item.isHeading ? <CustomOption thisHead={false} item={item} isActive={true} /> : null
       })}
      </Dropdown.Toggle>

      <Dropdown.Menu className="w-100" as={CustomMenu}>
        {/* <Dropdown.Item eventKey="1" active> <CustomOption name={'goku'} /></Dropdown.Item>
        <Dropdown.Item eventKey="2"> <CustomOption/></Dropdown.Item>
        <Dropdown.Item eventKey="3"><CustomOption/></Dropdown.Item> */}
        {data.map(function (el, i) {
          let isActive = el.isActive;
          return <Dropdown.Item onClick={(e)=>selecthandler(e,el)} active={isActive}  key={i * 8} eventKey={i}> <CustomOption item={el} isActive={isActive} /></Dropdown.Item>
        })}

      </Dropdown.Menu>
    </Dropdown>
  )
}

