import React from 'react';
import login_left from "../../../../images/login-left.png";
import logo from "../../../../images/login-logo.png";
import ImageHs from '../ImageHs';

import serverLoginImg from '../../../../images/hireserver/imgs/brand/serverlogin.png';
import clouduploadImg from '../../../../images/hireserver/imgs/brand/cloudupload.png';
import cloudwhiteImg from '../../../../images/hireserver/imgs/brand/cloudwhite.png';
import greycloudImg from '../../../../images/hireserver/imgs/brand/greycloud.png';
import beesuperImg from '../../../../images/hireserver/imgs/brand/beesuper.png';
import LogoHire from '../icons/LogoHire';



function LoginSkeletonScreen({ children, classes }) {
  return (
    <React.Fragment>
      <div className="align-items-center d-flex justify-content-end  login-main-page">

        {/* <div className="col-xl-6 d-none d-md-block p-0">
          <div className="banner-img-container" style={{ height: "100vh" }}>
            <img src={login_left} alt="img" className="h-100 w-100" />
          </div>
        </div> */}
        {/* <div className="col-xl-6 p-0 d-none d-md-block bg-login-right">
          <div className="float-right banner_img_down_container_hs">
            <img src={logo} className="banner-img-container_hs" alt="img" />
          </div>
        </div> */}
 <div className='logoHireClass'> 
          <LogoHire/>
          </div>
        <div className={`login-wrappers ${classes} loginSkeleton overflow-hidden`}>
          
       
          <div className="login-aside-right h-100">
            <div className="row m-0 justify-content-center h-100 align-items-center">
              <div className="col-xl-12 col-xxl-12 h-100">
                <div className="authincation-content h-100 px-4 py-2 p-sm-5">
                  <div className="row no-gutters maxWidthLoginContainer h-100 d-flex justify-content-center align-items-center">
                    <div className="col-xl-12">
                      <div className="auth-form-1">
                      <div>
                     <svg xmlns="http://www.w3.org/2000/svg" width="168.617" height="35" viewBox="0 0 168.617 35">
          <g id="Group_6352" className='notdark' data-name="Group 6352" transform="translate(-14.316 -57)">
            <g id="Group_6348" data-name="Group 6348" transform="translate(14.316 57)">
              <path id="Path_192" data-name="Path 192" d="M12.564.232A1.875,1.875,0,0,1,14.4.248L26,6.563a1.777,1.777,0,0,1,.944,1.559l.025,18.106a1.787,1.787,0,0,1-1.107,1.731L14.365,32.8a1.894,1.894,0,0,1-1.77-.021L1.1,27.938a1.784,1.784,0,0,1-1.109-1.73L.02,8.1A1.78,1.78,0,0,1,.962,6.542Z" transform="translate(0.01 -0.001)" fill="#000" />
              <path id="Path_193" data-name="Path 193" d="M87.681,53.889l6.8-2.456a1.665,1.665,0,0,0,1.1-1.568V36.5a1.67,1.67,0,0,0-1.037-1.547l-6.859-2.771a.186.186,0,0,0-.258.175V53.713a.188.188,0,0,0,.251.175Z" transform="translate(-72.092 -26.525)" fill="#fff" />
              <path id="Path_194" data-name="Path 194" d="M27.8,32.168,20.942,34.94A1.67,1.67,0,0,0,19.9,36.487v13.37A1.668,1.668,0,0,0,21,51.419l6.808,2.456a.189.189,0,0,0,.253-.175V32.342a.188.188,0,0,0-.267-.174Zm-6.46,5.964c0-.175.149-.277.323-.312l4.538-.949a.3.3,0,0,1,.323.312v2.175a.332.332,0,0,1-.323.309l-4.538.526a.307.307,0,0,1-.323-.312Zm5.215,11.851a.3.3,0,0,1-.319.3l-4.541-.7a.34.34,0,0,1-.321-.312V47.518a.314.314,0,0,1,.319-.309l4.543.2a.323.323,0,0,1,.321.312Zm0-5.229a.316.316,0,0,1-.319.305l-4.541-.14a.323.323,0,0,1-.321-.312V42.852a.328.328,0,0,1,.319-.309l4.543-.367a.309.309,0,0,1,.321.312Z" transform="translate(-16.408 -26.511)" fill="#fff" />
            </g>
            <g id="Group_6349" data-name="Group 6349" transform="translate(-5687.812 6325.701)">
              <path id="Path_12111" data-name="Path 12111" d="M538.289,181.09l3.781,4.384V181.09h.777v5.642h-.709l-3.742-4.343v4.343h-.777V181.09Z" transform="translate(5256.934 -6420.518)" fill="#000" />
              <path id="Path_12112" data-name="Path 12112" d="M610.931,181.09v.721H608.54v1.721h2.308v.719H608.54V186h2.465v.728H607.73V181.09Z" transform="translate(5196.768 -6420.518)" fill="#000" />
              <path id="Path_12113" data-name="Path 12113" d="M659.75,181.064l1.712,3.959,1.672-3.963h.8l-2.381,5.674h-.183l-2.431-5.674Z" transform="translate(5152.822 -6420.493)" fill="#000" />
              <path id="Path_12114" data-name="Path 12114" d="M726.207,181.09v.721h-2.391v1.721h2.3v.719h-2.3V186h2.465v.728H723V181.09Z" transform="translate(5097.848 -6420.518)" fill="#000" />
              <path id="Path_12115" data-name="Path 12115" d="M779.474,181.078a1.733,1.733,0,0,1,1.245.447,1.489,1.489,0,0,1-.475,2.519,2.451,2.451,0,0,1,.425.345,7.988,7.988,0,0,1,.609.831l.993,1.5h-.96l-.894-1.39a3.876,3.876,0,0,0-.658-.814.993.993,0,0,0-.665-.252h-.365v2.457h-.81v-5.647Zm-.745,2.474h.522a1.253,1.253,0,0,0,.86-.231.869.869,0,0,0,.248-.669.824.824,0,0,0-.24-.637.993.993,0,0,0-.709-.224h-.686Z" transform="translate(5050.718 -6420.506)" fill="#000" />
              <path id="Path_12116" data-name="Path 12116" d="M879.734,181.078a1.733,1.733,0,0,1,1.245.447,1.489,1.489,0,0,1-.475,2.519,2.45,2.45,0,0,1,.426.345,8,8,0,0,1,.609.831l.993,1.5h-.96l-.894-1.39a3.878,3.878,0,0,0-.658-.814.993.993,0,0,0-.665-.252h-.365v2.457h-.81v-5.647Zm-.745,2.474h.522a1.253,1.253,0,0,0,.86-.231.87.87,0,0,0,.248-.669.824.824,0,0,0-.24-.637.993.993,0,0,0-.709-.224h-.687Z" transform="translate(4964.678 -6420.506)" fill="#000" />
              <path id="Path_12117" data-name="Path 12117" d="M940.931,181.09v.721H938.54v1.721h2.309v.719H938.54V186h2.465v.728H937.73V181.09Z" transform="translate(4913.575 -6420.518)" fill="#000" />
              <path id="Path_12118" data-name="Path 12118" d="M993.956,180.977v.918a2.288,2.288,0,0,0-.617-.434,1.648,1.648,0,0,0-.709-.153.921.921,0,0,0-.606.184.583.583,0,0,0-.22.471,1.13,1.13,0,0,0,.709.956,6.683,6.683,0,0,1,1.187.806,1.486,1.486,0,0,1,.471,1.176,1.369,1.369,0,0,1-.455,1.054,1.678,1.678,0,0,1-1.183.426,2.355,2.355,0,0,1-.8-.142,2.878,2.878,0,0,1-.721-.386v-1.025a2.167,2.167,0,0,0,.686.613,1.7,1.7,0,0,0,.835.214.867.867,0,0,0,.591-.206.685.685,0,0,0,.237-.539.825.825,0,0,0-.253-.611,5.368,5.368,0,0,0-.939-.654,2.836,2.836,0,0,1-.935-.777,1.539,1.539,0,0,1-.248-.877,1.265,1.265,0,0,1,.471-1.006,1.773,1.773,0,0,1,1.191-.4,2.239,2.239,0,0,1,.678.1,2.519,2.519,0,0,1,.628.291Z" transform="translate(4867.872 -6420.082)" fill="#000" />
              <path id="Path_12119" data-name="Path 12119" d="M1046.256,181.09v.709h-1.969v4.93h-.811V181.8h-1.936v-.709Z" transform="translate(4824.489 -6420.518)" fill="#000" />
              <path id="Path_12120" data-name="Path 12120" d="M203.064,55.147h-2.576V48.041h-6.573v7.106H191.34V38.67h2.576v7.039h6.573V38.67h2.576Z" transform="translate(5554.098 -6298.299)" fill="#000" />
              <path id="Path_12121" data-name="Path 12121" d="M298.27,55.147V38.67h2.576V55.147Z" transform="translate(5462.335 -6298.299)" fill="#000" />
              <path id="Path_12122" data-name="Path 12122" d="M352.056,55.147h-2.909l-4.153-5.574h-1.886v5.574H340.53V38.67h4.889a5.264,5.264,0,0,1,4.113,1.644,6.111,6.111,0,0,1,0,7.617,4.611,4.611,0,0,1-1.909,1.265Zm-6.637-7.883a3.032,3.032,0,0,0,2.376-.888,3.276,3.276,0,0,0,.733-2.242,3.311,3.311,0,0,0-.733-2.269A3.024,3.024,0,0,0,345.419,41h-2.312v6.266Z" transform="translate(5426.069 -6298.299)" fill="#000" />
              <path id="Path_12123" data-name="Path 12123" d="M438.58,45.383H444.6v2.332H438.58v5.106h7.306v2.332H436V38.67h9.886v2.316H438.58Z" transform="translate(5344.14 -6298.299)" fill="#000" />
              <path id="Path_12124" data-name="Path 12124" d="M531.885,50.586a4.769,4.769,0,0,1-3.708,3.22,5.072,5.072,0,0,1-.993.089,5.345,5.345,0,0,1-1.953-.356A6.514,6.514,0,0,1,522.5,51.43l2.044-1.6a3.68,3.68,0,0,0,1.62,1.288,2.535,2.535,0,0,0,1.511.133,2.169,2.169,0,0,0,1.732-1.377,1.921,1.921,0,0,0-.755-1.865c-.733-.688-1.932-1.865-2.021-1.955L524.6,44.146a4.708,4.708,0,0,1-1.265-1.976,3.534,3.534,0,0,1-.044-2.088,3.762,3.762,0,0,1,1.221-1.844,4.3,4.3,0,0,1,1.976-.932,4.4,4.4,0,0,1,2.442.177,5.552,5.552,0,0,1,2.242,1.665l-2.02,1.621a2.193,2.193,0,0,0-2.176-.932,1.512,1.512,0,0,0-1.2.955c-.088.311-.067.821.621,1.494l2.753,2.642c.4.4.888.865,1.244,1.2a4.941,4.941,0,0,1,1.442,2.155,3.9,3.9,0,0,1,.045,2.3Z" transform="translate(5269.909 -6297.046)" fill="#000" />
              <path id="Path_12125" data-name="Path 12125" d="M610.036,45.383h6.018v2.332h-6.018v5.106h7.306v2.332H607.46V38.67h9.882v2.316h-7.306Z" transform="translate(5197 -6298.299)" fill="#000" />
              <path id="Path_12126" data-name="Path 12126" d="M710.1,55.147H707.2l-4.159-5.574H701.15v5.574h-2.57V38.67h4.882a5.263,5.263,0,0,1,4.113,1.644,6.111,6.111,0,0,1,0,7.617,4.611,4.611,0,0,1-1.909,1.265Zm-6.642-7.883a3.032,3.032,0,0,0,2.376-.888,3.276,3.276,0,0,0,.733-2.242,3.312,3.312,0,0,0-.733-2.269A3.024,3.024,0,0,0,703.462,41h-2.306v6.266Z" transform="translate(5118.804 -6298.299)" fill="#000" />
              <path id="Path_12127" data-name="Path 12127" d="M785.9,49.529l4.33-10.859h2.8L785.97,55.879,778.42,38.67h2.837Z" transform="translate(5050.289 -6298.299)" fill="#000" />
              <path id="Path_12128" data-name="Path 12128" d="M900.136,45.383h6.018v2.332h-6.018v5.106h7.306v2.332H897.56V38.67h9.882v2.316h-7.306Z" transform="translate(4948.047 -6298.299)" fill="#000" />
              <path id="Path_12129" data-name="Path 12129" d="M1000.2,55.147h-2.909l-4.153-5.574h-1.888v5.574H988.67V38.67h4.886a5.263,5.263,0,0,1,4.113,1.644,6.111,6.111,0,0,1,0,7.617,4.608,4.608,0,0,1-1.911,1.265Zm-6.64-7.883a3.032,3.032,0,0,0,2.376-.888,3.274,3.274,0,0,0,.732-2.242,3.31,3.31,0,0,0-.732-2.269A3.024,3.024,0,0,0,993.556,41h-2.311v6.266Z" transform="translate(4869.86 -6298.299)" fill="#000" />
            </g>
          </g>
        </svg>
                </div>
                        {children}
                      </div>
                    </div>
                  </div>
                </div>
               


              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="beeStyle">
        <ImageHs src={beesuperImg} alt="hireserver" width="600px" />
      </div>
      <div className="serverLoginImg">
        <ImageHs src={serverLoginImg} alt="hireserver" width="300px" />
      </div>
      <div className="clouduploadImg">
        <ImageHs src={clouduploadImg} alt="hireserver" width="200px" />
      </div>
      <div className="cloudwhiteImg">
        <ImageHs src={cloudwhiteImg} alt="hireserver" width="100px" />
      </div>
      <div className="greycloudImg">
        <ImageHs src={greycloudImg} alt="hireserver" width="150px" />
      </div>
      <div className="logoHireLogin d-none">
        {/* <ImageHs src={logoText} alt="hireserverlogo" width="200px" /> */}

        <svg xmlns="http://www.w3.org/2000/svg" width="168.617" height="35" viewBox="0 0 168.617 35">
          <g id="Group_6352" data-name="Group 6352" transform="translate(-14.316 -57)">
            <g id="Group_6348" data-name="Group 6348" transform="translate(14.316 57)">
              <path id="Path_192" data-name="Path 192" d="M12.564.232A1.875,1.875,0,0,1,14.4.248L26,6.563a1.777,1.777,0,0,1,.944,1.559l.025,18.106a1.787,1.787,0,0,1-1.107,1.731L14.365,32.8a1.894,1.894,0,0,1-1.77-.021L1.1,27.938a1.784,1.784,0,0,1-1.109-1.73L.02,8.1A1.78,1.78,0,0,1,.962,6.542Z" transform="translate(0.01 -0.001)" fill="#000" />
              <path id="Path_193" data-name="Path 193" d="M87.681,53.889l6.8-2.456a1.665,1.665,0,0,0,1.1-1.568V36.5a1.67,1.67,0,0,0-1.037-1.547l-6.859-2.771a.186.186,0,0,0-.258.175V53.713a.188.188,0,0,0,.251.175Z" transform="translate(-72.092 -26.525)" fill="#fff" />
              <path id="Path_194" data-name="Path 194" d="M27.8,32.168,20.942,34.94A1.67,1.67,0,0,0,19.9,36.487v13.37A1.668,1.668,0,0,0,21,51.419l6.808,2.456a.189.189,0,0,0,.253-.175V32.342a.188.188,0,0,0-.267-.174Zm-6.46,5.964c0-.175.149-.277.323-.312l4.538-.949a.3.3,0,0,1,.323.312v2.175a.332.332,0,0,1-.323.309l-4.538.526a.307.307,0,0,1-.323-.312Zm5.215,11.851a.3.3,0,0,1-.319.3l-4.541-.7a.34.34,0,0,1-.321-.312V47.518a.314.314,0,0,1,.319-.309l4.543.2a.323.323,0,0,1,.321.312Zm0-5.229a.316.316,0,0,1-.319.305l-4.541-.14a.323.323,0,0,1-.321-.312V42.852a.328.328,0,0,1,.319-.309l4.543-.367a.309.309,0,0,1,.321.312Z" transform="translate(-16.408 -26.511)" fill="#fff" />
            </g>
            <g id="Group_6349" data-name="Group 6349" transform="translate(-5687.812 6325.701)">
              <path id="Path_12111" data-name="Path 12111" d="M538.289,181.09l3.781,4.384V181.09h.777v5.642h-.709l-3.742-4.343v4.343h-.777V181.09Z" transform="translate(5256.934 -6420.518)" fill="#000" />
              <path id="Path_12112" data-name="Path 12112" d="M610.931,181.09v.721H608.54v1.721h2.308v.719H608.54V186h2.465v.728H607.73V181.09Z" transform="translate(5196.768 -6420.518)" fill="#000" />
              <path id="Path_12113" data-name="Path 12113" d="M659.75,181.064l1.712,3.959,1.672-3.963h.8l-2.381,5.674h-.183l-2.431-5.674Z" transform="translate(5152.822 -6420.493)" fill="#000" />
              <path id="Path_12114" data-name="Path 12114" d="M726.207,181.09v.721h-2.391v1.721h2.3v.719h-2.3V186h2.465v.728H723V181.09Z" transform="translate(5097.848 -6420.518)" fill="#000" />
              <path id="Path_12115" data-name="Path 12115" d="M779.474,181.078a1.733,1.733,0,0,1,1.245.447,1.489,1.489,0,0,1-.475,2.519,2.451,2.451,0,0,1,.425.345,7.988,7.988,0,0,1,.609.831l.993,1.5h-.96l-.894-1.39a3.876,3.876,0,0,0-.658-.814.993.993,0,0,0-.665-.252h-.365v2.457h-.81v-5.647Zm-.745,2.474h.522a1.253,1.253,0,0,0,.86-.231.869.869,0,0,0,.248-.669.824.824,0,0,0-.24-.637.993.993,0,0,0-.709-.224h-.686Z" transform="translate(5050.718 -6420.506)" fill="#000" />
              <path id="Path_12116" data-name="Path 12116" d="M879.734,181.078a1.733,1.733,0,0,1,1.245.447,1.489,1.489,0,0,1-.475,2.519,2.45,2.45,0,0,1,.426.345,8,8,0,0,1,.609.831l.993,1.5h-.96l-.894-1.39a3.878,3.878,0,0,0-.658-.814.993.993,0,0,0-.665-.252h-.365v2.457h-.81v-5.647Zm-.745,2.474h.522a1.253,1.253,0,0,0,.86-.231.87.87,0,0,0,.248-.669.824.824,0,0,0-.24-.637.993.993,0,0,0-.709-.224h-.687Z" transform="translate(4964.678 -6420.506)" fill="#000" />
              <path id="Path_12117" data-name="Path 12117" d="M940.931,181.09v.721H938.54v1.721h2.309v.719H938.54V186h2.465v.728H937.73V181.09Z" transform="translate(4913.575 -6420.518)" fill="#000" />
              <path id="Path_12118" data-name="Path 12118" d="M993.956,180.977v.918a2.288,2.288,0,0,0-.617-.434,1.648,1.648,0,0,0-.709-.153.921.921,0,0,0-.606.184.583.583,0,0,0-.22.471,1.13,1.13,0,0,0,.709.956,6.683,6.683,0,0,1,1.187.806,1.486,1.486,0,0,1,.471,1.176,1.369,1.369,0,0,1-.455,1.054,1.678,1.678,0,0,1-1.183.426,2.355,2.355,0,0,1-.8-.142,2.878,2.878,0,0,1-.721-.386v-1.025a2.167,2.167,0,0,0,.686.613,1.7,1.7,0,0,0,.835.214.867.867,0,0,0,.591-.206.685.685,0,0,0,.237-.539.825.825,0,0,0-.253-.611,5.368,5.368,0,0,0-.939-.654,2.836,2.836,0,0,1-.935-.777,1.539,1.539,0,0,1-.248-.877,1.265,1.265,0,0,1,.471-1.006,1.773,1.773,0,0,1,1.191-.4,2.239,2.239,0,0,1,.678.1,2.519,2.519,0,0,1,.628.291Z" transform="translate(4867.872 -6420.082)" fill="#000" />
              <path id="Path_12119" data-name="Path 12119" d="M1046.256,181.09v.709h-1.969v4.93h-.811V181.8h-1.936v-.709Z" transform="translate(4824.489 -6420.518)" fill="#000" />
              <path id="Path_12120" data-name="Path 12120" d="M203.064,55.147h-2.576V48.041h-6.573v7.106H191.34V38.67h2.576v7.039h6.573V38.67h2.576Z" transform="translate(5554.098 -6298.299)" fill="#000" />
              <path id="Path_12121" data-name="Path 12121" d="M298.27,55.147V38.67h2.576V55.147Z" transform="translate(5462.335 -6298.299)" fill="#000" />
              <path id="Path_12122" data-name="Path 12122" d="M352.056,55.147h-2.909l-4.153-5.574h-1.886v5.574H340.53V38.67h4.889a5.264,5.264,0,0,1,4.113,1.644,6.111,6.111,0,0,1,0,7.617,4.611,4.611,0,0,1-1.909,1.265Zm-6.637-7.883a3.032,3.032,0,0,0,2.376-.888,3.276,3.276,0,0,0,.733-2.242,3.311,3.311,0,0,0-.733-2.269A3.024,3.024,0,0,0,345.419,41h-2.312v6.266Z" transform="translate(5426.069 -6298.299)" fill="#000" />
              <path id="Path_12123" data-name="Path 12123" d="M438.58,45.383H444.6v2.332H438.58v5.106h7.306v2.332H436V38.67h9.886v2.316H438.58Z" transform="translate(5344.14 -6298.299)" fill="#000" />
              <path id="Path_12124" data-name="Path 12124" d="M531.885,50.586a4.769,4.769,0,0,1-3.708,3.22,5.072,5.072,0,0,1-.993.089,5.345,5.345,0,0,1-1.953-.356A6.514,6.514,0,0,1,522.5,51.43l2.044-1.6a3.68,3.68,0,0,0,1.62,1.288,2.535,2.535,0,0,0,1.511.133,2.169,2.169,0,0,0,1.732-1.377,1.921,1.921,0,0,0-.755-1.865c-.733-.688-1.932-1.865-2.021-1.955L524.6,44.146a4.708,4.708,0,0,1-1.265-1.976,3.534,3.534,0,0,1-.044-2.088,3.762,3.762,0,0,1,1.221-1.844,4.3,4.3,0,0,1,1.976-.932,4.4,4.4,0,0,1,2.442.177,5.552,5.552,0,0,1,2.242,1.665l-2.02,1.621a2.193,2.193,0,0,0-2.176-.932,1.512,1.512,0,0,0-1.2.955c-.088.311-.067.821.621,1.494l2.753,2.642c.4.4.888.865,1.244,1.2a4.941,4.941,0,0,1,1.442,2.155,3.9,3.9,0,0,1,.045,2.3Z" transform="translate(5269.909 -6297.046)" fill="#000" />
              <path id="Path_12125" data-name="Path 12125" d="M610.036,45.383h6.018v2.332h-6.018v5.106h7.306v2.332H607.46V38.67h9.882v2.316h-7.306Z" transform="translate(5197 -6298.299)" fill="#000" />
              <path id="Path_12126" data-name="Path 12126" d="M710.1,55.147H707.2l-4.159-5.574H701.15v5.574h-2.57V38.67h4.882a5.263,5.263,0,0,1,4.113,1.644,6.111,6.111,0,0,1,0,7.617,4.611,4.611,0,0,1-1.909,1.265Zm-6.642-7.883a3.032,3.032,0,0,0,2.376-.888,3.276,3.276,0,0,0,.733-2.242,3.312,3.312,0,0,0-.733-2.269A3.024,3.024,0,0,0,703.462,41h-2.306v6.266Z" transform="translate(5118.804 -6298.299)" fill="#000" />
              <path id="Path_12127" data-name="Path 12127" d="M785.9,49.529l4.33-10.859h2.8L785.97,55.879,778.42,38.67h2.837Z" transform="translate(5050.289 -6298.299)" fill="#000" />
              <path id="Path_12128" data-name="Path 12128" d="M900.136,45.383h6.018v2.332h-6.018v5.106h7.306v2.332H897.56V38.67h9.882v2.316h-7.306Z" transform="translate(4948.047 -6298.299)" fill="#000" />
              <path id="Path_12129" data-name="Path 12129" d="M1000.2,55.147h-2.909l-4.153-5.574h-1.888v5.574H988.67V38.67h4.886a5.263,5.263,0,0,1,4.113,1.644,6.111,6.111,0,0,1,0,7.617,4.608,4.608,0,0,1-1.911,1.265Zm-6.64-7.883a3.032,3.032,0,0,0,2.376-.888,3.274,3.274,0,0,0,.732-2.242,3.31,3.31,0,0,0-.732-2.269A3.024,3.024,0,0,0,993.556,41h-2.311v6.266Z" transform="translate(4869.86 -6298.299)" fill="#000" />
            </g>
          </g>
        </svg>

      </div>
    </React.Fragment>
  )
}

export default LoginSkeletonScreen;