import React from 'react'
import ModalHs from '../../../../../hireserver/ModalHs'

function DeleteModalScript({handleCloseDeleteModal,handleOkayDelete,deleteModal,loadingDelete}) {
  return (
    <ModalHs footerNone={true} isNeed={false} showModalHS={deleteModal} btnTitle="Yes" >
        <div className='w-100'>
          <div>   Do want to delete this script?   </div>
          <div className='align-items-end d-flex d-gap-10 justify-content-end'>
            <button onClick={handleCloseDeleteModal} className='btn bg-dark-alt text-black hs_borderRadius-4 px-2 py-1'>Close</button>
            <button disabled={loadingDelete} onClick={handleOkayDelete} className='btn hs_borderRadius-4 px-2 w-100p py-1 bg-danger text-white'>

              {loadingDelete ? <div className="spinner-border  text-light" role="status">

              </div> : 'Yes'}
            </button>
          </div>
        </div>
      </ModalHs>
  )
}

export default DeleteModalScript