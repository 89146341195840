import React from 'react'
import MultiSelectTag from '../../../../../../hireserver/controls/MultiSelectTag/MultiSelectTag'

function SelectMachinesFire({values,options,changeHandler}) {
    function changeHandlerFun(selectedOption){
   
        changeHandler(selectedOption);
        // console.log(selectedOption);
    }
    console.log(options,'optionsoptionsoptionsoptionsoptionssjisdhi');
  return (
    <React.Fragment>
      <MultiSelectTag noOptions="Your machines assign to other firewall groups or you have zero machines. " values={values} changeHandler={changeHandlerFun} options={options}  />
      {/* {options?.length === 0 && <div className='font-12 pt-1'>
          You have no available machines to selected. It may because you have assign the machines to someother firewall groups or You have zero machines.
        </div>} */}
    </React.Fragment>
  )
}

export default SelectMachinesFire