import React from 'react'

export default function ButtonHS({ isDisable, styles, classes, handler, children, bgPrimary = true, type = "button" }) {
  function handleClick(e) {
    if (handler) {
      handler(e);
    }
  }
  return (
    <button disabled={isDisable} style={styles} type={type} onClick={handleClick} className={`btn cursor-pointer hs_borderRadius-4  ${bgPrimary ? 'hsButton-bg' : ''} text-white ${classes}`}>
      {children}
    </button>
  )
}
