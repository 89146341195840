import React from 'react';
import { passwordGenHelper } from '../../../../../utils/PasswordGen';
import FormInputError from '../../../hireserver/components/FormInputError';
import HeadingHS from '../../../hireserver/controls/HeadingHS';
import LabelInput from '../../../hireserver/controls/LabelInput';
import PasswordInputHS from '../../../hireserver/controls/PasswordInputHS';
import PasswordWrapper from '../../../hireserver/controls/PasswordWrapper/PasswordWrapper';
import SpacerHS_ONE from '../../../hireserver/Spaces/SpacerHS_ONE';
import SpacerHS_TWO from '../../../hireserver/Spaces/SpacerHS_TWO';


function RootPasswordCFSection({setRootPassword,rootPassword,setRootPasswordAgain,rootPasswordAgain,rootPasswordErr,passwordNotValid,confirmPasswordErr,passwordNotMatchErr},ref) {
  const [passwordCheckShowUpdateUpdate, setPasswordCheckShowUpdateUpdate] = React.useState(false);
  function handlePassword(e){
      
        setRootPassword(e.target.value);
        setPasswordCheckShowUpdateUpdate(true);
    
  }

  function getStrongPassword(){
    //console.log(passwordGenHelper())
    setRootPassword(passwordGenHelper())
  }

  return (
    <div ref={ref}>
         <div className='d-flex align-item-center'>
                <HeadingHS as="h3 text-primary" title="Root Password" />
                {/* <div className='hs_text-light'>
                   Please set your root password
                </div> */}
                <span onClick={getStrongPassword} className='text-center font-16 py-1 pl-3'><u className='text-primary cursor-pointer'>
                Generate Password</u></span>
            </div>
            <SpacerHS_ONE/>
           <div className='col-12 col-lg-5 pl-0'>
         {/* <LabelInput displayOne="true" type="password" heading="" id="passwordbare" placeholder="Enter Your password" inputValue={rootPassword} setInputValue={setRootPassword}  />
         {rootPasswordErr &&  <FormInputError message="You need to enter a root password" />} */}
 <PasswordWrapper onlyLength={true} classes="" value={rootPassword} passwordCheckShow={passwordCheckShowUpdateUpdate} >
         <PasswordInputHS  focusOutHandler={(e) => {
              
              setPasswordCheckShowUpdateUpdate(false);
            }} value={rootPassword} changeHandler={handlePassword} placeholder={"Please Enter Password"} labelStyles={` w-100`}  />
         </PasswordWrapper>

             {/* <LabelInput type="password" heading="Conform Password" id="conformpasswordbare" placeholder="Enter Your password Again" inputValue={rootPasswordAgain} setInputValue={setRootPasswordAgain}  /> */}
             {rootPasswordErr &&  <FormInputError message="You need to enter a root password with min length of 8" />}
           {confirmPasswordErr && <FormInputError message="You need to enter a root password again to check" />  }
           {passwordNotMatchErr && <FormInputError message="Password doesn't match." />  }
           {passwordNotValid && <FormInputError message="Password must be atleast 8 characters long which may include 1 captial and 1 small and 1 number and 1 special character. "/>}
           </div>
           <div className='h-0' style={{height:'0px'}}></div>
    </div>
  )
}

export default React.forwardRef(RootPasswordCFSection);
