import React from 'react'

export default function SelectCard({idValue,children,handler,cardName,isHeight,isWidth,dataName,isCheck}) {
  function onClickHandler(e){
    handler(e)
  }
  return (
    <label htmlFor={idValue}  style={{height:isHeight,width:isWidth}} className="boxlocation_hireserver hs_borderRadius-4 mx-0 " id={idValue+ '_location'} data-locationVal={idValue}>
    <input checked={isCheck} onClick={onClickHandler} data-name={dataName} data-location={idValue} className="checkbox_location_hireserver invisible" name={cardName} id={idValue} type="radio" />
   
    <div className="tick_default">

    </div>
    <div className="tick text-center">
        <i className="fa fa-check"></i>
    </div>

    <div className="align-items-start contentLocation h-100 d-flex flex-column justify-content-between">
      {children}
    </div>
</label>
  )
}

