import React from 'react'
import Selectdedicatedcpu from './Selectdedicatedcpu'

export default function Dedicatedcpucard(props) {
    const { price,setSelectServer,perhour, data } = props;
    const { name, values,prices} = data;
    function handler(){
        setSelectServer({
            name,price,perhour
        });
        //// console.log(name)
    }

    function getValues(){
        let valuesArr = [];
        for(let item in values){
            
        valuesArr.push(<ListItem item={item} value={values[item]} />);
        }

        return valuesArr;
    }
  return (
    <Selectdedicatedcpu {...props} handler={handler} cardName="serverNames" isHeight="auto">
    
    <div>
        <div className='border-bottom pt-4'>
            <p className='text-center fs-18 '>{name}</p>
        </div>
        <div className='pt-4'>
        {getValues()}
        </div>
       
        <div className='text-center pt-3'>
            <div className='hs_secondaryColor'>
                <span style={{fontSize:'25px'}} className="font-w500">{prices.monthly}</span> <span>/month</span>
            </div>
            <div>
                <span className='fs-12 text-body text-center'>{prices.hourly}/hour</span>
            </div>
        </div>
    </div>
</Selectdedicatedcpu>
  )
}

function ListItem({item,value}){
    return (
        <div className='d-flex justify-content-between py-1 px-3 border-bottom'>
                <div className='fs-16'>{item}</div>
                <div className='fs-14 text-body'>{value}</div>
        </div>
    )
}

{/* <div className='d-flex flex-column align-items-center pb-5 px-4 pt-4'>
        <div className='d-flex flex-column align-items-center'>
            <div className='font-w500'> 
                {name}
            </div>
            <div className='hs_secondaryColor'>
                <span style={{fontSize:'25px'}} className="font-w500">${price}</span> <span>/month</span>
            </div>
            <div>
                <span className='fs-12 text-body text-center'>${perhour}/hour</span>
            </div>
        </div>
        <div className='my-3' style={{ width: '50px', height: '2px', backgroundColor: '#068D8A' }}></div>
        <div className='d-flex flex-column align-items-center font-12'>
            <div>
                <span className='text-dark font-w600 fs-14 pr-1'>2 x 960 GB </span><span className='fs-14 text-body'>SSD</span>
                
            </div>
            <div>
                <span className='text-dark font-w600 fs-14 pr-1'>6</span> cores / <span className='text-dark font-w600 fs-14 pr-1'>12</span>
                <span className='fs-14 text-body'>threads @4.0 GHz</span>
            </div>
            <div>
                <span className='text-dark font-w600 fs-14 pr-1'>
                    32 GB 
                </span>
                <span className='fs-14 text-body'>
                    Memory
                </span>
            </div>
            <div>
                <span className='text-dark font-w600 fs-14 pr-1'>10 TB</span>
                <span className='fs-14 text-body'>Bandwidth</span>
            </div>
            <div>
                <span className='text-dark font-w600 fs-16 pr-1'>10 Gbps</span>
                <span className='fs-14 text-body'>Network</span>
            </div>
        </div>
    </div> */}