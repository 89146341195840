import React from 'react'
import { Card } from 'react-bootstrap'
import CustomInputLabelSubText from '../../../../hireserver/controls/CustomInputLabelSubText'
import HeadingHS from '../../../../hireserver/controls/HeadingHS'
import Select, { components } from 'react-select';
import NodeBalanceContext from '../../NodeBalanceContext/NodeBalanceContext';
import PortConfiguration from './InnerComponents/PortConfiguration';
import ActiveHealthChecks from './InnerComponents/ActiveHealthChecks';
import BackEndNodes from './InnerComponents/BackEndNodes';
export default function NodeBalanceSettingsCard() {
    // isReq,children,title,subText
    const { selectTagOption, setSelectTagOption } = React.useContext(NodeBalanceContext);

    function setSelectedOption(){

    }

    return (
        <Card className='shadow mt-2'>
            <Card.Body>

                <div className='pb-4'>
                    <HeadingHS as="h3" title="Port Configuration" />
                </div>
                <PortConfiguration />
                <hr/>
               <ActiveHealthChecks/>
                <hr/>
                <BackEndNodes/>
            </Card.Body>
        </Card>
    )
}
