import { cloneDeep } from 'lodash';
import React from 'react'
import { blockInvalidChar, isSafeCronTosave, isValidCron } from '../../../../../../../utils/Helpers';
import useInputHook from '../../../../../../hooks/InputHook/useInputHook';
import FormInputError from '../../../../../hireserver/components/FormInputError';
import InputErrorBackground from '../../../../../hireserver/components/InputErrorBackground';
import JustInputText from '../../../../../hireserver/components/JustInput/JustInputText';
import PasswordInputHS from '../../../../../hireserver/controls/PasswordInputHS';
import PasswordJustEye from '../../../../../hireserver/controls/PasswordJustEye/PasswordJustEye';
import ModalHs from '../../../../../hireserver/ModalHs';
import { handlerAlert } from '../../../../Instances/InstancesListComponents/DropDownInstancesList';
import ScriptMachineAuto from '../autoselectmachine/ScriptMachineAuto';
import ScriptMarketContext from '../ScriptMarketContext/ScriptMarketContext';
import TokenMaster from './TokenMaster';
import SaveAndExecOutput from './SaveAndExecOutput';
import { FormCheck, Tab, Tabs } from 'react-bootstrap';
import CronJob from './CronJob';
import Toggle from 'react-toggle';
import "react-toggle/style.css"
import { CRON_ENABLE, CRON_TIME } from '../../../../../../../utils/Emuns';

function SaveAndExecModal() {
    const { execModalShow, setExeModalShow, handleExecCloseModal, handleExecAndSave, execModalLoading, tokenOptions, setTokenOptions, execAbortRef, initDataTextArea, machinetagState, autoCompleteMachineError, selectMachineTag, onAutoMachineTagChange, defaultValues, setTempNameVal, TempDesVal, setTempDesVal, tempNameVal, tempNameError, setTempNameError, setTempDesError, TempDesError, setAutoCompleteMachineError, resetTempDes, resetTempName, setMachineTagState, passwordVal, setPasswordVal, passwordError, setPasswordError, resetPass, portVal, setPortVal, portError, setPortError, resetPort, shhUserVal, setSshUserval, shhuserError, setSshUserError, resetSshUser, shhIpVal, setSshIpval, shhIpError, setSshIpError, resetSshIp, tokenState, exeFormError, setExeFormError, setTokenState, execMode, setExecMode, showoutputInExecModal, tokenoutputid, timerState, getOutputData, getScriptList, setShowOutputInExecModal, outputView, setOutputView, cronjobsList, setCronJobsList, cronvalue, setCronValue, editSaveAndExecOptions = false, customCronValue, setCustomCronValue, enableCronJob, setEnableCronJob, handlerExec, saveExecModal,completeOutput,setCompleteOutput,
        execSaveLoading, setExecSaveLoading, setDefaultValue,saveAndExecError,setSaveAndExecError
    } = React.useContext(ScriptMarketContext);


    const [disabledInput, setDisabledInput] = React.useState(false);


    const [modifedScript, setModifedScript] = React.useState("");

    React.useEffect(function () {
        setDisabledInput(editSaveAndExecOptions);
    }, [editSaveAndExecOptions]);

    React.useEffect(function (){
        console.log(shhIpVal,"shhIpValshhIpValshhIpVal");
    },[shhIpVal]);

    // React.useEffect(function (){
    //     setShowOutputInExecModal(false);
    // },[]);


    React.useEffect(function () {
        debugger;
        if (!editSaveAndExecOptions) {
            if (!defaultValues) return;
            const { script } = defaultValues;
            setModifedScript(script);

            return;
        };

        if (defaultValues) {
            let res = JSON.parse(defaultValues.tokens);
            console.log(res);
            let newScript = defaultValues?.script;
            for (let i = 0; i < res.params.length; i++) {
                const { key, value } = res.params[i];
                if (key == CRON_ENABLE || key == CRON_TIME) {

                    if (key == CRON_ENABLE && value != "0") {
                        setEnableCronJob(true);
                    } else {

                        const labelandvalue = getSetValueCronJob(cronjobsList, value);
                        setCronValue(labelandvalue)
                        setCustomCronValue(value)
                    }

                } else {
                    newScript = newScript.replaceAll(`{{${key}}}`, value);
                }

            }

            setModifedScript(newScript);
        }
        console.log(defaultValues, 'defaultValuesdefaultValuesdefaultValues');


    }, [defaultValues]);

    function getSetValueCronJob(cronlist, value) {
        for (let i = 0; i < cronlist.length; i++) {
            let item = cronlist[i];
            if (item?.value == value) {
                return item;

            }

        }

        return {
            value: "custom",
            label: "Custom"
        }
    }

    React.useEffect(function () {
        if (!execModalShow) {


            if (execMode) {
                setExecMode(false);
            }
            setPasswordVal('');
            resetTempDes();
            resetTempName();
            resetSshIp();
            resetSshUser();
            resetPort();
            resetPass();
            setAutoCompleteMachineError({
                isError: false,
                message: ''
            })
            setMachineTagState(null);
            setTokenState({});
            setTokenOptions({});

            setExeFormError({
                isError: false,
                message: ''
            })
            setEnableCronJob(false);
            setCronValue({ value: "0 * * * *", label: 'Every hour' });
            setCustomCronValue(null);
            setDefaultValue(null);
            setModifedScript('');
            setExecSaveLoading(false);
            setSaveAndExecError({
                isError:false,
                message:null
            })
     
        } else {
            setExeFormError({
        isError: false,
        message: ''
    })

            // console.log(tokenState,'tokenstate true');
            // console.log(tokenOptions,'tokenOption true');
            // console.log(initDataTextArea,'initDataTextAreainitDataTextAreainitDataTextArea');
            // debugger;
            // const { sship,sshpassword,sshport,sshuser} = initDataTextArea;
            // setPasswordVal(sshpassword);
            // setPortVal(sshport);
            // setSshUserval(sshuser);
            // setSshIpval(sship);
        }
    }, [execModalShow]);

    React.useEffect(function () {
        if (machinetagState) {debugger;

            setSshIpval(machinetagState.ip);
        }
    }, [machinetagState])

    React.useEffect(function () {

        renderTokens(tokenOptions);

    }, [tokenOptions]);


    function renderTokens(tokenOP) {
        let obj = {};
        for (let token in tokenOP) {
            let { name, id, val } = tokenOP[token];
            obj[id] = {
                ...tokenOP[token],
                val: val
            };
        }

        setTokenState(obj)
    }


    //onInputchange for token inputs
    function onInputTokenInput(e, item) {

        console.log(e.target.value);
        setTokenOptions((prev) => {
            const copy = { ...prev };
            const { id, name } = item;
            let currValue = e.target.value;
            copy[`{{${name}}}`].val = currValue;
            copy[`{{${name}}}`].error = {
                isError: false,
                message: ''
            }
            return copy;
        })
    }

    function handlerpasswordVal(e) {
        setPasswordVal(e.target.value);
        setPasswordError({
            isError: false,
            message: ""
        })
    }

    function handlerPortVal(e) {

        if (e.which != 8 && e.which != 0 && e.which < 48 || e.which > 57) {
            e.preventDefault();
            return;
        }
        setPortVal(e.target.value);
        setPortError({
            isError: false,
            message: ""
        })
    }

    function handlerShhUserVal(e) {
        setSshUserval(e.target.value);
        setSshUserError({
            isError: false,
            message: ""
        })
    }

    function handlerSshIpChange(e) {
        setSshIpval(e.target.value);
        setSshIpError({
            isError: false,
            message: ""
        })
    }

    function handleTempName(e) {
        setTempNameVal(e.target.value)
        if (tempNameError.isError) {
            setTempNameError({
                isError: false,
                message: ""
            })
        }

    }

    function handleTempDes(e) {
        setTempDesVal(e.target.value)
        if (TempDesError.isError) {
            setTempDesError({
                isError: false,
                message: ''
            })
        }
    }

    function simpleValidForm() {

        let isError = false;
        if (!passwordVal) {

            isError = true;
            setPasswordError({
                isError: true,
                message: "SSH Password can't be empty"
            })
        }
        if (!portVal) {

            setPortError({
                isError: true,
                message: "SSH Port can't be empty"
            })
            isError = true;
        }
        if (!shhUserVal) {

            setSshUserError({
                isError: true,
                message: "SSH User can't be empty"
            })
            isError = true;
        }
        if (!shhIpVal) {

            setSshIpError({
                isError: true,
                message: "SSH IP can't be empty"
            })
            isError = true;
        }
        return isError;
    }

    function validScriptForm() {
        let isError = false;

        if (!tempNameVal) {
            setTempNameError({
                isError: true,
                message: 'Name cannot be empty'
            });
            isError = true;
        }

        if (!TempDesVal) {
            setTempDesError({
                isError: true,
                message: 'Description cannot be empty'
            });
            isError = true;
        }

        if (!machinetagState) {
            setAutoCompleteMachineError({
                isError: true,
                message: 'Please select the machine'
            });
            isError = true;
        }

        if (!shhIpVal) {
            setSshIpError({
                isError: true,
                message: 'SSH IP cannot be empty'
            });
            isError = true;
        }

        if (!portVal) {
            setPortError({
                isError: true,
                message: 'Port cannot be empty'
            });
            isError = true;
        }

        if (!shhUserVal) {
            setSshUserError({
                isError: true,
                message: 'User cannot be empty'
            });
            isError = true;
        }

        if (!passwordVal) {
            setPasswordError({
                isError: true,
                message: 'Password cannot be empty'
            });
            isError = true;
        }

        let newTokens = cloneDeep(tokenOptions);
        let isChanged = false;
        for (let token in newTokens) {
            let currItem = newTokens[token];
            if (!currItem.val) {
                isChanged = true;
                newTokens[token] = {
                    ...currItem,
                    error: {
                        isError: true,
                        message: `Value cannot be empty`
                    }
                }
            }
        }

        if (isChanged) {
            isError = true;
            setTokenOptions(newTokens);
        }
        return isError;
    }

    function handlerConfirm() {
        const isError = validScriptForm();
        if (isError) {
            return;
        }

        // if (machinetagState.id != 4) {
        //     setExeFormError({
        //         isError: true,
        //         message: 'Machine is not running. Please select running machine'
        //     })
        //     return;
        // }


        handleExecAndSave({
            tokenState,
            sshpassword: passwordVal, sshport: portVal, sshuser: shhUserVal, sship: shhIpVal, scriptid: defaultValues.scriptid,
            enableCronJob,
            defaultCrobJob: cronvalue?.value,
            customCronValue: customCronValue
        }, execMode ? false : true);


    }

    function handlerSave() {
        debugger;
        setExecSaveLoading(true);
        const isError = validScriptForm();
        if (isError) {
            setExecSaveLoading(false);
            return;
        }

        // if (machinetagState.id != 4) {
        //     setExecSaveLoading(false);
        //     setExeFormError({
        //         isError: true,
        //         message: 'Machine is not running. Please select running machine'
        //     })
        //     return;
        // }

        if (enableCronJob) {
            let cronValueCheck = customCronValue ? customCronValue : cronvalue?.value;

            if (!isValidCron(cronValueCheck)) {
                handlerAlert("danger", "Something went wrong", true);
                setExecSaveLoading(false);

                setExeFormError({
                    isError: true,
                    message: 'Please input correct cron value'
                });
                return;
            }
        }


        saveExecModal({
            tokenState,
            sshpassword: passwordVal, sshport: portVal, sshuser: shhUserVal, sship: shhIpVal, scriptid: defaultValues.scriptid,
            enableCronJob,
            defaultCrobJob: cronvalue?.value,
            customCronValue: customCronValue
        }, execMode ? false : true);
    }



    return (
        <ModalHs size="lg" classNameMain="hs_borderRadius-4" footerNone={true} isNeed={false} showModalHS={execModalShow} btnTitle="Yes" >




            <div className="softwareSelectionPart position-relative w-100">


                <div className="tabsSelection">
                    {showoutputInExecModal ? <SaveAndExecOutput completeOutput={completeOutput} setCompleteOutput={setCompleteOutput}  output={outputView} setOutputView={setOutputView} showoutputInExecModal={showoutputInExecModal} setShowOutputInExecModal={setShowOutputInExecModal} getOutputData={getScriptList} getScriptList={getScriptList} timerState={timerState} idscriptoutput={tokenoutputid} scriptid={defaultValues?.scriptid} /> : <Tabs
                        onSelect={(e) => console.log(e)}
                        defaultActiveKey={editSaveAndExecOptions ? "Tokens" : "Tokens"}
                        // defaultActiveKey={editSaveAndExecOptions ? "Execute" : "Tokens"}
                        id="uncontrolled-tab-example"
                        className="mb-3 w-100 softwareTabsOver"
                    >
                        {/* {editSaveAndExecOptions ? <Tab className="no-word-break"
                            eventKey={"Execute"}
                            key={"Execute"}
                            title={"Execute"}>
                            <div className='col-sm-6'>
                                <div>
                                    SSH Password
                                </div>
                                <PasswordJustEye onChange={handlerpasswordVal} value={passwordVal} />
                                {passwordError.isError && <FormInputError message={passwordError.message} />}

                            </div>
                            <div className='align-items-end d-flex d-gap-10 mt-5 justify-content-end'>
                                <button onClick={handleExecCloseModal} className='btn bg-dark-alt text-black hs_borderRadius-4 px-2 py-1'>Close</button>
                                <button className='btn hs_borderRadius-4 px-2 w-200p py-1 hs-signin-btn text-white' onClick={handlerSave}>{execSaveLoading?<div className="spinner-border  text-light" role="status">

</div> :'Save'}</button>
                                {showoutputInExecModal ? null : <button disabled={execModalLoading} onClick={handlerConfirm} className='btn hs_borderRadius-4 px-2 w-200p py-1 hs-signin-btn text-white'>

                                    {execModalLoading ? <div className="spinner-border  text-light" role="status">

                                    </div> :('Save and execute')}
                                </button>}
                            </div>
                        </Tab> : null} */}
                        <Tab
                            className="no-word-break"
                            eventKey={"Tokens"}
                            key={"Tokens"}
                            title={"Tokens"}
                        >
                            <div className='w-100 d-flex flex-column d-gap-15'>

                                <React.Fragment>
                                    {
                                        editSaveAndExecOptions ?
                                            <React.Fragment>
                                                <div>
                                                    <ul className='scripteditable'>
                                                        <li className='d-flex d-gap-10'>
                                                            <span>Name :</span>
                                                            <span className='text-hs-break'>{tempNameVal}</span>
                                                        </li>
                                                        <li className='d-flex d-gap-10'>
                                                            <span>Description :</span>
                                                            <span className='text-hs-break'>{TempDesVal} </span>
                                                        </li>
                                                        <li className='d-flex d-gap-10'>
                                                            <span>Machine List :</span>
                                                            <span>{machinetagState?.label}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </React.Fragment>

                                            : <React.Fragment>
                                                <div className='row'>
                                                    <div className='col-sm-4'>
                                                        <div>Name</div>
                                                        <input disabled={disabledInput} autoComplete="new-password" value={tempNameVal} onChange={handleTempName} className='form-control hs_borderRadius-4' type="text" />
                                                        {tempNameError.isError && <FormInputError message={tempNameError.message} />}
                                                    </div>
                                                    {/* TempDesVal,setTempDesVal */}
                                                    <div className='col-sm-8'>
                                                        <div>Description</div>
                                                        <input disabled={disabledInput} autoComplete="new-password" value={TempDesVal} onChange={handleTempDes} className='form-control hs_borderRadius-4' type="text" />
                                                        {TempDesError.isError && <FormInputError message={TempDesError.message} />}
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <span>Machine List</span>
                                                        <ScriptMachineAuto isDisabledOptions={disabledInput} values={machinetagState} changeHandler={onAutoMachineTagChange} options={selectMachineTag} />
                                                        {autoCompleteMachineError.isError && <FormInputError message={autoCompleteMachineError.message} />}
                                                    </div>

                                                </div>
                                            </React.Fragment>
                                    }

                                    {
                                        editSaveAndExecOptions ? <div>
                                            <ul className='scripteditable'>
                                                <li className='d-flex d-gap-10'>
                                                    <span>SSH IP :</span>
                                                    <span className='text-hs-break'>{shhIpVal}</span>
                                                </li>
                                                <li className='d-flex d-gap-10'>
                                                    <span>SSH Port :</span>
                                                    <span className='text-hs-break'>{portVal} </span>
                                                </li>
                                                <li className='d-flex d-gap-10'>
                                                    <span>SSH User</span>
                                                    <span>{shhUserVal}</span>
                                                </li>

                                            </ul>
                                        </div> :
                                            <React.Fragment>
                                                <div>   Provide the following inputs</div>
                                                <div className="row">
                                                    <div className='col-sm-6'>
                                                        <div>
                                                            SSH IP
                                                        </div>
                                                        <div>
                                                            <input disabled={disabledInput} autoComplete="new-password" type="text" value={shhIpVal} onChange={handlerSshIpChange} className={`form-control border-radius-hs `} />
                                                        </div>
                                                        {shhIpError.isError && <FormInputError message={shhIpError.message} />}
                                                    </div>

                                                    <div className='col-sm-6'>
                                                        <div>
                                                            SSH Port
                                                        </div>
                                                        <div>
                                                            <input disabled={disabledInput} autoComplete="new-password" onKeyDown={blockInvalidChar} type="number" value={portVal} onChange={handlerPortVal} className={`form-control border-radius-hs `} />
                                                        </div>
                                                        {portError.isError && <FormInputError message={portError.message} />}
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div>
                                                            SSH User
                                                        </div>
                                                        <div>
                                                            <input disabled={disabledInput} autoComplete="new-password" type="text" value={shhUserVal} onChange={handlerShhUserVal} className={`form-control border-radius-hs `} />
                                                        </div>
                                                        {shhuserError.isError && <FormInputError message={shhuserError.message} />}
                                                    </div>
                                                    {/* <div className='col-sm-6'>
                                                            <div>
                                                                SSH Password
                                                            </div>
                                                            <PasswordJustEye onChange={handlerpasswordVal} value={passwordVal} />
                                                            {passwordError.isError && <FormInputError message={passwordError.message} />}

                                                        </div> */}
                                                    {/* {editSaveAndExecOptions ? null : <div className='col-sm-6'>
                                                            <div>
                                                                SSH Password
                                                            </div>
                                                            <PasswordJustEye onChange={handlerpasswordVal} value={passwordVal} />
                                                            {passwordError.isError && <FormInputError message={passwordError.message} />}

                                                        </div>} */}
                                                </div>
                                            </React.Fragment>
                                    }




                                    {typeof tokenOptions === 'object' && Object.keys(tokenOptions || {}).length ? <div>Tokens</div> : null}
                                    <div className='row'>
                                        {/* {renderTokens()} */}
                                        <TokenMaster disabledInput={false} onInputTokenInput={onInputTokenInput} setTokenState={setTokenState} tokens={tokenOptions} tokenState={tokenState} />
                                    </div>
                                    <div className=''>
                                        <div>
                                            SSH Password
                                        </div>
                                        <PasswordJustEye onChange={handlerpasswordVal} value={passwordVal} />
                                        {passwordError.isError && <FormInputError message={passwordError.message} />}

                                    </div>

                                    <div style={{
                                        height: '1px'
                                    }} className='bg-light'></div>
                                    <div>
                                        <div className='d-flex  justify-content-between justify-content-around flex-column h-100'>
                                            <div className='d-flex d-gap-10 pb-2 cronjobtoggle'>
                                                <label htmlFor='cheese-status'>Enable Cron Job</label>
                                                <Toggle
                                                    id='cheese-status'
                                                    defaultChecked={enableCronJob}
                                                    value={enableCronJob}
                                                    onChange={() => setEnableCronJob(!enableCronJob)} />

                                            </div>
                                            {/* <FormCheck 
  id="switchEnabled"
  type="switch"
  checked={enableCronJob}
  onChange={()=>setEnableCronJob(!enableCronJob)}
  label="Enable Cron Job"
/> */}

                                            {enableCronJob ? <CronJob customCronValue={customCronValue} setCustomCronValue={setCustomCronValue} cronjobsList={cronjobsList} setCronValue={setCronValue} cronvalue={cronvalue} /> : null}
                                        </div>
                                    </div>

                                </React.Fragment>




                            </div>
                        </Tab>
                        <Tab className="no-word-break" eventKey="Script" key="Script" title="Script">


                            <div style={{
                                whiteSpace: 'pre-wrap'
                            }} className='border-radius-hs p-3  h-100 bg-light' type="text"  >
                                {modifedScript}
                            </div>
                        </Tab>




                    </Tabs>}
                    {
                        exeFormError.isError ? <InputErrorBackground message={exeFormError.message} /> : null
                    }
                    <div onClick={handleExecCloseModal} className='p-3 d-flex justify-content-end  position-absolute' style={{
                        top: 0,
                        right: 0
                    }}>
                        <span className='fa fa-close'></span>
                    </div>

                    <React.Fragment>
                        {/* <div className=''>
                                <div>
                                    SSH Password
                                </div>
                                <PasswordJustEye onChange={handlerpasswordVal} value={passwordVal} />
                                {passwordError.isError && <FormInputError message={passwordError.message} />}

                            </div> */}

                           {saveAndExecError?.isError ? <div>
                                <InputErrorBackground message={saveAndExecError.message} />
                            </div>:null}

                        <div className='align-items-end d-flex d-gap-10 mt-5 justify-content-end'>
                            <button onClick={handleExecCloseModal} className='btn bg-dark-alt text-black hs_borderRadius-4 px-2 py-1'>Close</button>
                            {showoutputInExecModal ? null : <React.Fragment>
                                <button className='btn hs_borderRadius-4 px-2 w-200p py-1 hs-signin-btn text-white' onClick={handlerSave}>{execSaveLoading ? <div className="spinner-border  text-light" role="status">

                                </div> : 'Save'}</button>
                                <button disabled={execModalLoading} onClick={handlerConfirm} className='btn hs_borderRadius-4 px-2 w-200p py-1 hs-signin-btn text-white'>

                                    {execModalLoading ? <div className="spinner-border  text-light" role="status">

                                    </div> : ('Save and execute')}
                                </button>
                            </React.Fragment>}

                        </div>

                    </React.Fragment>
                    {/* {editSaveAndExecOptions ?  <React.Fragment>
                        <div className=''>
                                <div>
                                    SSH Password
                                </div>
                                <PasswordJustEye onChange={handlerpasswordVal} value={passwordVal} />
                                {passwordError.isError && <FormInputError message={passwordError.message} />}

                            </div> <div className='align-items-end d-flex d-gap-10 mt-5 justify-content-end'>
                        <button onClick={handleExecCloseModal} className='btn bg-dark-alt text-black hs_borderRadius-4 px-2 py-1'>Close</button>
                        <button className='btn hs_borderRadius-4 px-2 w-200p py-1 hs-signin-btn text-white' onClick={handlerSave}>{execSaveLoading?<div className="spinner-border  text-light" role="status">

</div> :'Save'}</button>
                        {showoutputInExecModal ? null : <button disabled={execModalLoading} onClick={handlerConfirm} className='btn hs_borderRadius-4 px-2 w-200p py-1 hs-signin-btn text-white'>

                            {execModalLoading ? <div className="spinner-border  text-light" role="status">

                            </div> : ('Save and execute')}
                        </button>}
                    </div>

                    </React.Fragment> : null} */}





                </div>

            </div>


        </ModalHs>
    )
}

export default SaveAndExecModal