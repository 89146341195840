import React from 'react';

function FormInputError({ message,fontSizeClass="font-12",styles={} }) {

  return (
    
      <div className={`text-danger d-flex align-items-center `}>
        <div style={{ width: '15px', height: '10px' }} className="mr-1 d-flex justify-content-center align-items-center">
          
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.2646 3.41366C11.7212 3.15681 12.2788 3.15681 12.7354 3.41366L19.2354 7.06991C19.7077 7.33558 20 7.83536 20 8.37727V15.6227C20 16.1646 19.7077 16.6644 19.2354 16.9301L12.7354 20.5863C12.2788 20.8432 11.7212 20.8432 11.2646 20.5863L4.76461 16.9301C4.29229 16.6644 4 16.1646 4 15.6227V8.37727C4 7.83536 4.29229 7.33559 4.76461 7.06991L11.2646 3.41366Z" fill="#FF3A2E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11 7.4C11 7.17909 11.1791 7 11.4 7H12.6C12.8209 7 13 7.17909 13 7.4V12.6C13 12.8209 12.8209 13 12.6 13H11.4C11.1791 13 11 12.8209 11 12.6V7.4Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16Z" fill="white"/>
</svg>
        </div>
        <div style={styles}  className={`d-flex justify-content-center align-items-center ${fontSizeClass}`}>
          {message}
        </div>
      </div>
    
  )
}

export default React.memo(FormInputError);