import React from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import SpacerHS_TWO from '../../hireserver/Spaces/SpacerHS_TWO';
import styles from './MachinesCard.module.css';

function MachinesCard({ value, name, icon, svg, borderNeed, LinkUrl }) {

  if (isMobile) {
    return <React.Fragment>
      <div className='col-4'>
      <div>
      <Link className='text-decoration-none cursor-pointer d-flex justify-content-around' to={LinkUrl}>
            <span className={`ai-icon text-primary ${styles.iconmachinecard} font-30 d-flex justify-content-center align-items-center`}> {svg ? svg : <i className={`${icon}`}></i>}</span>
          </Link>
          <SpacerHS_TWO/>
          <Link className='flex-column d-flex justify-content-around text-decoration-none cursor-pointer  d-flex text-primary' to={LinkUrl}>
            <span className={`font-w700 text-center ${styles.cardValueDashboard}`}>{`${value}`.length === 1 && `${value}` != 0 ? `0${value}` : value}</span>
            <span className='font-w500 text-center text-body'>{name}</span>
          </Link>
      </div>
      </div>
    </React.Fragment>
  }

  return (
    <div className='col-sm-4'>
      <div className={`${styles.machineCardDashboard} ${borderNeed ? styles.borderNeed : ''} justify-content-center row `}>
        <div className='align-items-center col-6 d-flex justify-content-center p-0'>
          <Link className='text-decoration-none cursor-pointer' to={LinkUrl}>
            <span className={`ai-icon text-primary ${styles.iconmachinecard} font-30 d-flex justify-content-center align-items-center`}> {svg ? svg : <i className={`${icon}`}></i>}</span>
          </Link>

        </div>

        <div className='align-items-start col-6 flex-column  d-flex justify-content-center p-0'>
          <Link className='flex-column text-decoration-none cursor-pointer  d-flex text-primary' to={LinkUrl}>
            <span className={`font-w700 ${styles.cardValueDashboard}`}>{`${value}`.length === 1 && `${value}` != 0 ? `0${value}` : value}</span>
            <span className='font-w500 text-body'>{name}</span>
          </Link>
        </div>


      </div>
    </div>
  )
}

export default MachinesCard