import React from 'react'
import HeadingHS from '../../controls/HeadingHS'
import LabelInput from '../../controls/LabelInput'

export default function SectionInput({title,subText,heading=true, endingText,secTitle,inputValue,headingClasses,setInputValue}) {
  return (
    <div>
      {heading &&   <HeadingHS as={`h3 pb-1 ${headingClasses}`} title={title} />
       }
       {subText &&  <div className='hs_text-light pb-lg-1 font-14'>{subText}</div>}
       <div className='col-12 col-lg-5  pl-0'>
       <LabelInput  displayOne={true} heading={secTitle} id={secTitle} placeholder={`Enter Your ${endingText?endingText:secTitle}`} inputValue={inputValue} setInputValue={setInputValue}  />
       </div>

    </div>
  )
}
