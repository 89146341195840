import { getLastNotifationAllURL, getLastNotifationURL, getUnReadNoticeURL, postMarkAsReadNoticeURL, postMarkSelectNotifsURL } from "../../API_ENDPOINTS/API_ENDPOINTS";
import { API_SERVICES } from "../API_SERVICES";
import { getSessionAndUserId } from "../InstancesServices/InstancesListService";
const staticTooken= "09668af8-6877-48b0-b639-00949152ac96"
export function getPastNotifationService(size){
    const { userId, sessionId } = getSessionAndUserId();
    let url = getLastNotifationURL(size);

    let config = {
        method: 'get',
        url,
        // headers: {
        //     'Authorization': 'Basic ' + `${staticTooken}`
        // }
        headers: {
            'Authorization': 'Basic ' + sessionId
        }
    };

    return API_SERVICES(config);
}

export function getPastNotifationAllService(page){
    const { userId, sessionId } = getSessionAndUserId();
    let url = getLastNotifationAllURL(userId,page);

    let config = {
        method: 'get',
        url,
        // headers: {
        //     'Authorization': 'Basic ' + `${staticTooken}`
        // }
        headers: {
            'Authorization': 'Basic ' + sessionId
        }
    };

    return API_SERVICES(config);
}
export function getUnReadNoticeCountService(){
    const { userId, sessionId } = getSessionAndUserId();
    let url = getUnReadNoticeURL();

    let config = {
        method: 'get',
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        }
    };

    return API_SERVICES(config);
}
export function postMarkAsReadNoticeService(rememberId){
    const { userId, sessionId } = getSessionAndUserId();
    let url = postMarkAsReadNoticeURL(rememberId);

    let config = {
        method: 'post',
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        }
    };

    return API_SERVICES(config);
}

export function selectedMarkAsReadService(payload){
    const { userId, sessionId } = getSessionAndUserId();
    let url = postMarkSelectNotifsURL();

    let data = {
        'Reminders':payload
    }

    let config = {
        method: 'post',
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        },
        data
    };

    return API_SERVICES(config);

}