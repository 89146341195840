import React from 'react'

function PriceInstanceTable({price="$178",data}) {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center'>
        <div>{price}</div>
        <div>{data}</div>
    </div>
  )
}

export default PriceInstanceTable