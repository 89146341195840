import React from "react";
import { connect } from "react-redux";
import { getGraphInstancesDetailsService } from "../../../../../../../services/InstancesServices/InstancesListService";
import { handlerAlert } from "../../../InstancesListComponents/DropDownInstancesList";
import HsLatencyCharts from "../HsCharts/HsLatencyCharts";
import HsWeeklyCharts from "../HsCharts/HsWeeklyCharts";
import { Sparklines, SparklinesLine, SparklinesBars } from "react-sparklines";
import LoaderHS from "../../../../../hireserver/components/LoaderHS";
import GraphHS from "./NetworkTabComponents/GraphHs";
import { byteToGbHelper, calcPertanageWhichToWhichHelper, humanFileSize, mbToGb, unixDateToNormalDate, unixDateToNormalTime } from "../../../../../../../utils/Helpers";
import { GB_TO_TB } from "../../../../../../../utils/Emuns";


function NetworkTab({currDetailsItem}) {
  const [loading,setLoading] = React.useState(false);
  const [dataCpu,setDataCpu] = React.useState([]);
  const [ramData,setRamData] = React.useState([]);
  const [diskData,setDiskData] = React.useState([]);
  const [networkDataIN,setNetwork] = React.useState([]);
  const [networkDataOut,setNetworkDataOut] = React.useState([]);
  const [diskRead,setDiskRead] = React.useState([]);
  const [diskWrite,setDiskWrite] = React.useState([]);
  const [timeData,setTimeDate] = React.useState([]);
  const [totalAvRamState,setTotalAvRamState] = React.useState([]);
  React.useEffect(function (){
    getGraphPoints();
  },[currDetailsItem]);

  React.useEffect(function (){
    return () => {
      setDataCpu([]);
      setRamData([]);
      setDiskData([]);
      setNetwork([]);
      setNetworkDataOut([]);
      setDiskRead([]);
      setDiskWrite([]);
      setLoading(false);
      setTimeDate([]);
      setTotalAvRamState([]);
    };

    // return function reason React Error
    // https://stackoverflow.com/questions/54954385/react-useeffect-causing-cant-perform-a-react-state-update-on-an-unmounted-comp
  },[]);

 

  async function getGraphPoints(){
   
    setLoading(true);
   
    if(Object.keys(currDetailsItem).length ===0) return;
    const {vmid,hostname,branchid,clusterid} = currDetailsItem;
  
    let res = await getGraphInstancesDetailsService(vmid,hostname,clusterid);
    if(!res?.data){
      return;
    }
    if (res.data.code != "0") {
      handlerAlert("danger", res.data.code, "danger");
      setLoading(false)
      return;
    }

    if (res.data.errors !== null) {
      handlerAlert("danger", res.data.errors[0], "danger");
      setLoading(false)
      return;
    }

    if (res.data.result === "") {
      handlerAlert("danger", "Empty Data", "danger");
      setLoading(false)
      return;
    }
    let graphData = JSON.parse(res.data.result);
    
    graphData = graphData.data;
    //  console.log(graphData)
    // const result = JSON.parse(data.result);
    let newArr = [];
    let ramArr = [];
    let diskArr = [];
    let diskNetwork = [];
    let diskNetworkout = [];
    let diskRead = [];
    let diskWrite = [];
    let timeArr = [];
    let totalAvRam = [];
    
    
    for(let i=graphData.length-6;i<graphData.length;i++){
   
      if(graphData[i]['cpu'] || graphData[i]['cpu'] === 0){
        //newArr.push((graphData[i]['cpu']*100));
    
        // newArr.push(calcPertanageWhichToWhichHelper(graphData[i]['cpu'],graphData[i]['maxcpu']));
        newArr.push(graphData[i]['cpu']*100)
        
      }else{
        newArr.push(0)
      }

      if(graphData[i]['mem']|| graphData[i]['mem'] === 0){
        ramArr.push(calcPertanageWhichToWhichHelper((graphData[i]['mem']),(graphData[i]['maxmem'])));
        
        // ramArr.push(byteToGbHelper(Number(graphData[i]['mem'])));
        
      }else{
        ramArr.push(0)
      }

      if(graphData[i]['maxmem']){
        totalAvRam.push(calcPertanageWhichToWhichHelper(graphData[i]['maxmem'],graphData[i]['maxmem']))
      }
      
      if(graphData[i]['disk']|| graphData[i]['disk'] === 0){
   
        diskArr.push(calcPertanageWhichToWhichHelper((graphData[i]['disk']),(graphData[i]['maxdisk'])));
        // diskArr.push(graphData[i]['disk']);
      }else{
   
        diskArr.push(0)
      }

      if(graphData[i]['netin']|| graphData[i]['netin'] === 0){
        diskNetwork.push(graphData[i]['netin']/1000);
      }else{
        diskNetwork.push(0)
      }

      if(graphData[i]['netout']|| graphData[i]['netout'] === 0){
        diskNetworkout.push(graphData[i]['netout']/1000);
      }else{
        diskNetworkout.push(0)
      }

      if(graphData[i]['diskread']|| graphData[i]['diskread'] === 0){
        diskRead.push(calcPertanageWhichToWhichHelper(graphData[i]['diskread'],graphData[i]['maxdisk']));
        // diskRead.push(graphData[i]['diskread']);
      }else{
        diskRead.push(0);
      }

      if(graphData[i]['diskwrite']|| graphData[i]['diskwrite'] === 0){
        //diskWrite.push(graphData[i]['diskwrite']);
        diskWrite.push(calcPertanageWhichToWhichHelper(graphData[i]['diskwrite'],graphData[i]['maxdisk']));
      }else{
        diskWrite.push(0)
      }

      if(graphData[i]['time'] || graphData[i]['time'] === 0){
        let data = unixDateToNormalDate(graphData[i]['time'])
        let time = unixDateToNormalTime(graphData[i]['time']);
        let Dt = (`${data}  (${time?.replace(':00','')})`);
        
        // let Dt = (`${data} ${time}`);
        // Dt.replace(/(\r\n|\n|\r)/gm, "")
        timeArr.push([data,time])
      }else{
        timeArr.push(0)
      }
      
      
    }
    
    setTimeDate(timeArr);
    setDataCpu(newArr);
    setRamData(ramArr);
    setDiskData(diskArr);
    setNetwork(diskNetwork);
    setNetworkDataOut(diskNetworkout);
    setDiskRead(diskRead);
    setDiskWrite(diskWrite);
    setTotalAvRamState(totalAvRam)
     
    setLoading(false);
    // console.log(timeArr,'timeArrtimeArr')
  }

  if(loading){
    // console.log("loaddddddddddddddd")
    return <LoaderHS/>
  }

  return (
   <React.Fragment>
     <div className="row">
      <div className="col-6">
      
      </div>
     </div>
     <div className="row">
      <div className="col-md-4 col-12 mb-5">
        <div className="card ">
          <div className="card-header">
            <h3 className="card-title">CPU</h3>
          </div>
          <div className="card-body p-2 h-300p">
          {/* <Sparklines data={dataCpu}>
              <SparklinesLine style={{ fill: "#c7fffe",height: "50px",width: "100%"}} />
            </Sparklines> */}
            <GraphHS timeData={timeData} name1={`CPU (${currDetailsItem?.noofblocks} ${currDetailsItem.noofblocks==1?'Core':'Cores'})`}  dataOne={dataCpu} />
            {/* <HsLatencyCharts height={200} /> */}
          </div>
        </div>
      </div>
      <div className="col-md-4 col-12 mb-5 ">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">RAM</h3>
          </div>
          <div className="card-body p-2 h-300p">
          {/* <Sparklines data={ramData}>
              <SparklinesLine style={{ fill: "#c7fffe",height: "50px",width: "100%"}} />
            </Sparklines> */}
             {/* <GraphHS isUnits={true} units="ram"  timeData={timeData}  name1="RAM" dataTwo={totalAvRamState} name2="Total" dataOne={ramData} /> */}
             <GraphHS isUnits={true} units="ram"  timeData={timeData}  name1={`RAM (${mbToGb(parseInt(currDetailsItem?.ram))} GB)`}  dataOne={ramData} />
            
          </div>
        </div>
      </div>
      <div className="col-md-4 col-12 mb-5 ">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">DISK</h3>
          </div>
          <div className="card-body p-2 h-300p">
          {/* <Sparklines data={diskData}>
              <SparklinesLine style={{ fill: "#c7fffe",height: "50px",width: "100%"}} />
            </Sparklines> */}
               <GraphHS isUnits={true} units="disk" timeData={timeData} name2={`Disk Write (${humanFileSize(currDetailsItem?.storage*GB_TO_TB,true)})`}  name1={`Disk Read (${humanFileSize(currDetailsItem?.storage*GB_TO_TB,true)})`} dataOne={diskRead} dataTwo={diskWrite} />
          </div>
        </div>
      </div>
      <div className="col-md-4 col-12 mb-5 ">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">NETWORK</h3>
          </div>
          <div className="card-body p-2 h-300p">
          {/* <Sparklines data={networkDataIN}>
              <SparklinesLine style={{ fill: "#c7fffe",height: "50px",width: "100%"}} />
            </Sparklines> */}
            <GraphHS isPer={true} isUnits={false} units="net" timeData={timeData} name1="Net In" name2="Net Out" dataOne={networkDataIN} dataTwo={networkDataOut} />
          </div>
        </div>
      </div>
    </div>
   
   </React.Fragment>
  );
}

const mapStateToProps = ({ instanceDetails }) => {
  return {
    currDetailsItem: instanceDetails.currDetailsItem,
  };
};
export default connect(mapStateToProps)(NetworkTab);