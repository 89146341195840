import React from 'react';
import { Link } from 'react-router-dom';
import { CONFIGURE_STAGE, CREATE_STAGE, DELETE_STAGE, FAILED_STAGE, ONHOLD_STAGE, PREDELETE_STAGE, PRESTART_STAGE, PRESTOP_STAGE, REBOOT_STAGE, REINSTALL_STAGE, STOP_STAGE } from '../../../../../utils/Emuns';
import startImg from '../../../../../images/hireserver/imgs/actions/start.png';
import stopImg from '../../../../../images/hireserver/imgs/actions/stop.png';
import susImg from '../../../../../images/hireserver/imgs/actions/sus.png';
import { isMobile } from 'react-device-detect';

export default function StatusShow({ machineid, isDetails, loadingInfo, statusid, isSpinning, isText, isColor = "bgl-success" }) {
    // if(isSpinning){
    //     
    // }
    
        
    
    function getClasses(isColorGiven,textColor){
        if(isMobile){
          return `p-0 d-flex align-items-center d-flex justify-content-end   ${isColorGiven} ${textColor} rounded-sm w-80p`
        }

        return  `btn pointerEventNone  btn-sm d-flex align-items-center   ${isColorGiven} ${textColor} rounded-sm w-120p`
    }

    let modeStatusId = loadingInfo?.loading ? loadingInfo.statusId : statusid;


    if (machineid == 0 || machineid == undefined) {

        let isActionText = 'Inactive';
        // let isActionText = isSpinning ? isText : 'Stopped';
        let isColorGiven = isDetails ? 'px-0 justify-content-start' : 'bgl-danger  justify-content-center';
        let textColor = 'text-danger'
        return (
            // <Link
            //     to={"#"}
            //     className={`btn btn-sm d-flex align-items-center   ${isColorGiven} ${textColor}   rounded-sm w-120p`}
            // >
            //     <span className='imgFullClass w-20p pr-1'>
            //         <img className='imgFullClassInner' src={susImg} alt="" /></span><span>{"Failed"}</span>

            // </Link>

            <Link
                to={"#"}
                className={getClasses(isColorGiven,textColor)}
                // className={`btn btn-sm d-flex align-items-center   ${isColorGiven} ${textColor} rounded-sm w-120p`}
            >

                {"Failed"}
            </Link>
        )
    }


    if (modeStatusId == CREATE_STAGE) {
        let isActionText = isSpinning ? '' : 'Creating';
        //let isActionText = isSpinning ? isText : 'Reboot';
        //let isColorGiven = isSpinning ? isColor : 'bgl-success';
        let isColorGiven = isDetails ? 'px-0 justify-content-start' : 'bgl-success justify-content-center';
        let textColor = isSpinning ? 'text-success' : 'text-success';
        return (
            <Link
                to={"#"}
                className={getClasses(isColorGiven,textColor)}
                // className={`btn btn-sm d-flex align-items-center   ${isColorGiven} ${textColor} rounded-sm w-120p`}
            >
                {true && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>}
                {isActionText}
            </Link>
        )
    }
    if (modeStatusId == CONFIGURE_STAGE) {
        let isActionText = isSpinning ? 'Configureing' : 'Configureing';
        //let isActionText = isSpinning ? isText : 'Reboot';
        //let isColorGiven = isSpinning ? isColor : 'bgl-success';
        let isColorGiven = isDetails ? 'px-0 justify-content-start' : 'bgl-success justify-content-center';
        let textColor = isSpinning ? 'text-success' : 'text-success';
        return (
            <Link
                to={"#"}
                className={getClasses(isColorGiven,textColor)}
                // className={`btn btn-sm d-flex align-items-center   ${isColorGiven} ${textColor} rounded-sm w-120p`}
            >
                {true && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>}
                {isActionText}
            </Link>
        )
    }


    if (modeStatusId === PREDELETE_STAGE) {
        let isActionText = isSpinning ? 'Deleting' : 'Deleting';
        // let isActionText = isSpinning ? isText : 'Stopped';
        let isColorGiven = isDetails ? 'px-0 justify-content-start' : 'bgl-danger justify-content-center';
        //let isColorGiven = isSpinning ? isColor : 'bgl-danger';
        let textColor = isSpinning ? 'text-danger' : 'text-danger';

        return (
            <Link
                to={"#"}
                className={getClasses(isColorGiven,textColor)}
                // className={`btn btn-sm d-flex align-items-center   ${isColorGiven} ${textColor} rounded-sm w-120p`}
            >
                {true && <span><span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span></span>}
                {isActionText}
            </Link>
        )
    }

    if (modeStatusId == DELETE_STAGE) {
        let isActionText = isSpinning ? 'Deleting' : 'Destory';
        // let isActionText = isSpinning ? isText : 'Destory';
        let textColor = isSpinning ? 'text-danger' : 'text-danger';
        let isColorGiven = isDetails ? 'px-0 justify-content-start' : 'bgl-danger justify-content-center';
        //let isColorGiven = isSpinning ? isColor : 'bgl-danger';
        return (
            <Link
                to={"#"}
                className={getClasses(isColorGiven,textColor)}
                // className={`btn btn-sm d-flex align-items-center   ${isColorGiven} ${textColor} rounded-sm w-120p`}
            >
                {isSpinning && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>}
                {isActionText}
            </Link>
        )
    }
    if (modeStatusId == REBOOT_STAGE) {
        let isActionText = isSpinning ? 'Rebooting' : 'Rebooting';
        //let isActionText = isSpinning ? isText : 'Reboot';
        let isColorGiven = isDetails ? 'px-0 justify-content-start' : 'bgl-success justify-content-center';
        //let isColorGiven = isSpinning ? isColor : 'bgl-success';
        let textColor = isSpinning ? 'text-success' : 'text-success';
        return (
            <Link
                to={"#"}
                className={getClasses(isColorGiven,textColor)}
                // className={`btn btn-sm d-flex align-items-center   ${isColorGiven} ${textColor} rounded-sm w-120p`}
            >
                {true && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>}
                <span>{isActionText}</span>
            </Link>
        )
    }
    if (modeStatusId == STOP_STAGE) {
        //let isActionText = isSpinning ? '' : 'Stopped';
        let isActionText = isSpinning ? loadingInfo?.text : 'Stopped';
        let isColorGiven = isDetails ? 'px-0 justify-content-start' : 'bgl-danger justify-content-center';
        let textColor = isSpinning ? 'text-danger' : 'text-danger';

        return (
            <Link
                to={"#"}
                className={getClasses(isColorGiven,textColor)}
                // className={`btn btn-sm d-flex align-items-center   ${isColorGiven} ${textColor} rounded-sm w-120p`}
            >
                {isSpinning && <span><span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span></span>}
                {/* {!isSpinning && <span className='imgFullClass w-20p pr-1'>
                    <img className='imgFullClassInner' src={stopImg} alt="" /></span>} */}
                    
                    <span>{isActionText}</span>
            </Link>
        )
    }
    if (modeStatusId == PRESTOP_STAGE) {
        let isActionText = isSpinning ? '' : 'Stopping';
        // let isActionText = isSpinning ? isText : 'Stopped';
        let isColorGiven = isDetails ? 'px-0 justify-content-start' : 'bgl-danger justify-content-center';
        // let isColorGiven = isSpinning ? isColor : 'bgl-danger';
        let textColor = isSpinning ? 'text-danger' : 'text-danger';

        return (
            <Link
                to={"#"}
                className={getClasses(isColorGiven,textColor)}
                // className={`btn btn-sm d-flex align-items-center   ${isColorGiven} ${textColor} rounded-sm w-120p`}
            >
                {true && <span><span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span></span>}
                {isActionText}
            </Link>
        )
    }
    if (modeStatusId == PRESTART_STAGE) {
        let isActionText = isSpinning ? '' : 'Starting';
        // let isActionText = isSpinning ? isText : 'Stopped';
        let isColorGiven = isDetails ? 'px-0 justify-content-start' : 'bgl-success justify-content-center';
        //let isColorGiven = isSpinning ? isColor : 'bgl-success';
        let textColor = isSpinning ? 'text-success' : 'text-success';

        return (
            <Link
                to={"#"}
                className={getClasses(isColorGiven,textColor)}
                // className={`btn btn-sm d-flex align-items-center   ${isColorGiven} ${textColor} rounded-sm w-120p`}
            >
                {true && <span><span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span></span>}
                {isActionText}
            </Link>
        )
    }

    if (modeStatusId == REINSTALL_STAGE) {
        let isActionText = isSpinning ? 'Reinstalling' : 'Reinstalling';
        // let isActionText = isSpinning ? isText : 'Stopped';
        let isColorGiven = isDetails ? 'px-0 justify-content-start' : 'bgl-success justify-content-center';
        //let isColorGiven = isSpinning ? isColor : 'bgl-success';
        let textColor = isSpinning ? 'text-success' : 'text-success';

        return (
            <Link
                to={"#"}
                className={getClasses(isColorGiven,textColor)}
                // className={`btn btn-sm d-flex align-items-center   ${isColorGiven} ${textColor} rounded-sm w-120p`}
            >
                {true && <span><span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span></span>}
                {isActionText}
            </Link>
        )
    }

    if (modeStatusId === 5) {
        let isActionText = '';
        // let isActionText = isSpinning ? isText : 'Stopped';
        let isColorGiven = 'text-success';
        let textColor = 'text-success'
        return (
            <Link
                to={"#"}
                className={getClasses(isColorGiven,textColor)}
                // className={`btn btn-sm d-flex align-items-center   ${isColorGiven} ${textColor} rounded-sm w-120p`}
            >
                {true && <span><span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span></span>}
                {isActionText}
            </Link>
        )

    }

    if (modeStatusId === 6) {
        let isActionText = '';
        // let isActionText = isSpinning ? isText : 'Stopped';
        let isColorGiven = 'bgl-danger';
        let textColor = 'text-success'
        return (
            <Link
                to={"#"}
                className={getClasses(isColorGiven,textColor)}
                // className={`btn btn-sm d-flex align-items-center   ${isColorGiven} ${textColor} rounded-sm w-120p`}
            >
                {true && <span><span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span></span>}
                {isActionText}
            </Link>
        )

    }

    if (modeStatusId == FAILED_STAGE) {
        let isActionText = 'Failed';
        // let isActionText = isSpinning ? isText : 'Stopped';
        let isColorGiven = isDetails ? 'px-0 justify-content-start' : 'bgl-danger justify-content-center';
        let textColor = 'text-danger'
        return (
            <Link
                to={"#"}
                className={getClasses(isColorGiven,textColor)}
                // className={`btn btn-sm d-flex align-items-center   ${isColorGiven} ${textColor} rounded-sm w-120p`}
            >
                {false && <span><span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span></span>}
                {isActionText}
            </Link>
        )
    }

    if (modeStatusId == ONHOLD_STAGE) {
        let isActionText = 'Failed';
        // let isActionText = isSpinning ? isText : 'Stopped';
        let isColorGiven = isDetails ? 'px-0 justify-content-start' : 'bgl-warning justify-content-center';
        let textColor = 'text-danger'
        return (
            <Link
                to={"#"}
                className={`btn btn-sm d-flex align-items-center   ${isColorGiven} ${textColor}   rounded-sm w-120p`}
            >
                <span className='imgFullClass w-20p pr-1'>
                    <img className='imgFullClassInner' src={susImg} alt="" /></span><span>{"Suspend"}</span>

            </Link>
        )
    }


    if (modeStatusId === 404) {

        let isActionText = 'Failed';
        let isColorGiven = isDetails ? 'px-0 justify-content-start' : 'bgl-danger justify-content-center';
        let textColor = 'text-danger'
        return (
            <Link
                to={"#"}
                className={getClasses(isColorGiven,textColor)}
                // className={`btn btn-sm d-flex align-items-center   ${isColorGiven} ${textColor} rounded-sm w-120p`}
            >
                {false && <span><span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span></span>}
                {isActionText}
            </Link>
        )
    }
    let isActionText = isSpinning ? loadingInfo?.text : 'Running';
    //let isActionText = isSpinning ? isText : 'Running';
    let isColorGiven = isDetails ? 'px-0 justify-content-start' : 'bgl-success justify-content-center';
    let textColor = isSpinning ? 'text-success' : 'text-success';

    return (

        <Link
            to={"#"}
        className={getClasses(isColorGiven,textColor)}
            // className={`btn btn-sm w-120p  d-flex align-items-center ${isColorGiven} ${textColor} rounded-sm w-100p`}
        >

            {isSpinning && <span><span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span></span>}
            {/* {!isSpinning && <span className='imgFullClass w-20p pr-1'>
                <img className='imgFullClassInner' src={startImg} alt="" /></span>} */}
                
                <span>{isActionText}</span>
        </Link>
    )
}