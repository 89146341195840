import React from 'react'
import HeadingHS from '../../../../hireserver/controls/HeadingHS'
import SpacerHS_TWO from '../../../../hireserver/Spaces/SpacerHS_TWO'
import InputLabelUp from '../../../../hireserver/controls/InputLabelUp/InputLabelUp'
import DropDownWithImgGroup from '../../../../hireserver/components/dropdowns/DropDownWithImgGroup';
import ClusterItem from './ClusterItem';
import ClusterFormContext from '../../clusterscontext/ClusterFormContext';
import ButtonHS from '../../../../hireserver/controls/ButtonHS';

function Clustercapacity({specs},ref) {
  const { clustercapacityData,
    setClustercapacityData, editClusterItem, locationState,locations,removeIndividualError,blocksInput,alertReplicaModalOpen,setIndividualError, deleteItemCluster, addNewItemCluster,summaryPrice } = React.useContext(ClusterFormContext);

  const  renderClusterItems = React.useCallback(() => {

    const htmlTemp = [];
    let i = 0;

    for (let item in clustercapacityData) {
    
      htmlTemp.push(<ClusterItem removeIndividualError={removeIndividualError} allLocations={locations} setIndividualError={setIndividualError} alertReplicaModalOpen={alertReplicaModalOpen} fullData={clustercapacityData} parentCore={blocksInput} specs={specs} parentLocation={locationState}  price={summaryPrice} deleteHandler={deleteItemCluster} handler={editClusterItem} {...clustercapacityData[item]} blockscount={blocksInput} key={clustercapacityData[item].id} />);
      i++;
    }

    return htmlTemp;
  },[clustercapacityData,blocksInput])

  // function renderClusterItems(){
  //   const htmlTemp = [];
  //   let i = 0;
  //   debugger;
  //   for (let item in clustercapacityData) {
    
  //     htmlTemp.push(<ClusterItem   price={summaryPrice} deleteHandler={deleteItemCluster} data={clustercapacityData[item]} handler={editClusterItem} {...clustercapacityData[item]} blockscount={blocksInput} key={clustercapacityData[item].id} />);
  //     i++;
  //   }

  //   return htmlTemp;
  // }

  // React.useEffect(function (){
  //   console.log(summaryPrice,'summaryPricesummaryPricesummaryPricesummaryPrice');
  // },[summaryPrice])



  return (
    <div  ref={ref}>

      <HeadingHS title="Choose Replica Node" />
      <SpacerHS_TWO />
      {Object.keys(clustercapacityData).length?<React.Fragment>
        <div className='row'>
          <div className="col-3">
            Node name
          </div>
          <div className="col-3">
            Location
          </div>
          {/* <div className="col-4">
            Plan
          </div> */}
          <div className="col-1 text-center">Blocks</div>
          <div className="col-2 text-center">Nodes</div>
   
          <div className="col-2 text-center">Monthly Rate</div>
          <div className="col-1 "></div>

        </div>

        {renderClusterItems()}
        <SpacerHS_TWO />
      </React.Fragment> :<div className='py-2'>No data</div>}
     <div>
     <ButtonHS handler={addNewItemCluster} classes={'px-2 py-1'}>
        Add Node
      </ButtonHS>
     </div>
      <SpacerHS_TWO />
     
    </div>
  )
}

export default React.forwardRef(Clustercapacity);