import { getFireWallGroupsURL, getFireWallMachinesByIdURL, getFirewallRulesURL } from "../../API_ENDPOINTS/FIREWALL_ENDPOINTS";
import { API_SERVICES } from "../API_SERVICES";
import { getSessionAndUserId } from "../InstancesServices/InstancesListService";

export function getBoundRulesService(fireId){
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'get',
        url:getFirewallRulesURL(fireId),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
    };

    return API_SERVICES(config);
}

export function getFireWallGroupsService(firewallId){
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'get',
        url:getFireWallGroupsURL(firewallId),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
    };

    return API_SERVICES(config);
}

export function getFireWallMachinesByIdService(firewallId){
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'get',
        url:getFireWallMachinesByIdURL(firewallId),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
    };

    return API_SERVICES(config);
}