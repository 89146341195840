import { SEVERNAME } from "./API_ENDPOINTS";

export function getTemperaturesSessionURL(){
    return `https://192.168.88.137/redfish/v1/SessionService/Sessions/`;
}

export function getTemperaturesDetailsURL({hostname}){
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hpbaremetal/temperaturesensors/ilourl/1001`;
    // return `${SEVERNAME}/apis/v4/hireserver/integrations/hpbaremetal/temperaturesensors/ilourl/${hostname}`;
    // return `https://192.168.88.137/redfish/v1/Chassis/1/Thermal`;
}


export function getBareMetalServersURL(userId){
    return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/getbaremetaldetails/currencytypeid/0/contactid/${userId}`;
}

export function getBareMetalServersTenureURL(serverId,userId){
    return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/getbaremetalprice/itemid/${serverId}/contactid/${userId}/currencyid/0`;
}
export function getBareMetalLocationFilterURL(itemId,userId){
    return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/getserveravailability/noofcores/0/isbaremetal/true/itemid/${itemId}/blockid/0/contactid/${userId}`;
}
