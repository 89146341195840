import React from 'react'
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { getPaymentWalletAmountService, postAddToWalletService } from '../../../../services/paymentServices/PaymentService';
import { openPaymentInfoFormAction } from '../../../../store/actions/PaymentInfoActions/PaymentInfoAction';
import { putRedirectLinkAction, showPagementGateWayAction } from '../../../../store/actions/RedirectPageActions/RedirectPageAction';
import { getCurrencySymbol, justGetCurrencySymbol } from '../../../../utils/Currency'
import { checkResData } from '../../../../utils/Helpers';
import CurrencyFC from '../../hireserver/components/Currency/CurrencyFC';
import { CURRENCY_FONT } from '../../hireserver/components/Currency/CurrencyFont';
import JustCurrencyFC from '../../hireserver/components/Currency/JustCurrencyFC';
import FormInputError from '../../hireserver/components/FormInputError';

function TopDetailsWallet({paymentData,userDetails,walletDetails,setAddWalletPaymentValue,addWalletPaymentValue,isShowPayment,walletValue,setIsOpen,isOpen}) {
    //const [walletValue, setWalletValue] = React.useState(null);
    const [loadingAddAmount, setLoadingAddAmount] = React.useState(false);
    
    
    const [isError,setIsError] = React.useState(false);
    // const [walletDetails, setWalletDetails] = React.useState(null);
    const dispatch = useDispatch();
    async function handlerAddAmountToWalletSubmit() {

      if(isShowPayment == 0){
        dispatch(openPaymentInfoFormAction());
        return; 
      }

     debugger

     if(!addWalletPaymentValue){
      setIsError(true);
        setTimeout(function(){
          setIsError(false);
        },2000);

        return;
     }

      if(addWalletPaymentValue[0] == '0' || Number(addWalletPaymentValue)<100 ){
        setIsError(true);
        setTimeout(function(){
          setIsError(false);
        },2000);

        return;
  
      }

      setIsOpen(true);
      return;

        setLoadingAddAmount(true);
        const wId = walletDetails?.walletid ? walletDetails?.walletid : 0;
        const payload = {
          "orderamount": addWalletPaymentValue,
          "currencytypeid": paymentData?.currencyid,
          "contactid": userDetails?.userId,
          "walletid":wId,
          "iswalletcredit": true
        }
        
 
        const res = await postAddToWalletService(payload);
    
        if (res.data.code != '0') {
          setLoadingAddAmount(false);
          return;
        }
    
    
        //handleCloseAddAmount();
        const paymentLinkJSON = JSON.parse(res.data.result);
        const paymentlink = paymentLinkJSON?.paymentlink;
        dispatch(putRedirectLinkAction(paymentlink));
        dispatch(showPagementGateWayAction());
    
      }
    // async function getWallet() {
    //     let res = await getPaymentWalletAmountService();
    //     let isValid = checkResData(res, true)
    //     if (isValid === false) {
    //       setWalletValue(0);
    //       // setLoading(false);
    //       return;
    //     }
    
    //     let resJSON = JSON.parse(res.data.result)[0];
    //     setWalletValue(resJSON?.walletvalue);
    //     setWalletDetails(resJSON);
    //   }
      // React.useEffect(function () {
      //   getWallet();
      // }, []);

  function onChangeWalletInputHandler(e){
    const val = e.target.value;
    setAddWalletPaymentValue(val);

    // if(val === '' || Number(val)<100){
    //   // setIsDisable(true);
     
    // }else{
    //   // setIsDisable(false);
    // }

  }
  return (
    <div className="col-12">
    <div className=" col-md-7 p-0">
      <div className="rounded-sm border mb-3 px-3 mr-md-2">
      <div className="justify-content-between px-0 py-3 text-success d-flex">
          <div>Credit</div>
          {/* <div>{getCurrencySymbol(paymentData?.currencyname, walletValue)}</div> */}
          <div><CurrencyFC name={paymentData?.currencyname} val={walletValue} /></div>
        </div>
        <div className="bg-transparent border-bottom d-flex justify-content-between px-0 py-3 align-items-center walletboxContainerAccount">
          <h5 className="m-0">Account Balance</h5>
         <div className='d-flex d-gap-10 z'>
            {/* <input value={addWalletPaymentValue} onInput={(e) => setAddWalletPaymentValue(e.target.value)}  type="number" className='form-control hs_borderRadius-4' /> */}
            <div className="position-relative font-21">
                <span className="position-absolute align-items-center d-flex h-100 pl-2">
                  <JustCurrencyFC name={paymentData?.currencyname} />
                  {/* {justGetCurrencySymbol(paymentData?.currencyname)} */}
                </span>
                <input value={addWalletPaymentValue} onInput={onChangeWalletInputHandler} type="number" className="form-control pl-4 hs_borderRadius-4 font-21" />
              </div>
         <button className="btn-sm w-150p hs-signin-btn hs_borderRadius-4 w-space-no border-0" onClick={handlerAddAmountToWalletSubmit} >  {loadingAddAmount ? <React.Fragment>
                    <div className="spinner-border text-light" role="status">

                    </div> Proceed to add
                  </React.Fragment> : 'Proceed to add'}</button>
         </div>
        </div>
       
      </div>
     {isError && <FormInputError styles={CURRENCY_FONT} message={`Please add more than ${getCurrencySymbol(paymentData?.currencyname, 100)}`}  />}
    </div>
  </div>
  )
}

const mapStatesToProps = ({ auth, PaymentInfoReducer }) => {
    return {
      currencyname: PaymentInfoReducer?.paymentData?.currencyname,
      paymentData: PaymentInfoReducer?.paymentData,
      userDetails: auth.auth,
      isShowPayment:PaymentInfoReducer?.paymentData?.defaultaddressid,
    }
  };

export default connect(mapStatesToProps)(TopDetailsWallet);