import React from 'react'

function ReInstallIcon({width}) {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width={width} viewBox="0 0 32.000000 32.000000"
    preserveAspectRatio="xMidYMid meet">
   
   <g transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
   fill="#000000" stroke="none">
   <path d="M122 258 c3 -8 27 -14 61 -16 l57 -3 0 -64 c0 -51 -3 -65 -15 -65
   -21 0 -19 -14 5 -35 20 -18 21 -18 48 8 21 22 23 27 9 27 -15 0 -17 11 -17 80
   l0 80 -76 0 c-56 0 -75 -3 -72 -12z"/>
   <path d="M35 240 c-16 -18 -16 -20 -2 -20 15 0 17 -13 17 -85 l0 -85 76 0 c56
   0 75 3 72 13 -3 7 -27 13 -61 15 l-57 3 0 69 c0 59 3 70 17 70 14 0 14 2 -2
   20 -10 11 -23 20 -30 20 -7 0 -20 -9 -30 -20z"/>
   </g>
   </svg>
  )
}

export default ReInstallIcon