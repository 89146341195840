import { DUE_TABLE_DATA, DUE_TABLE_RENEWAL_CLOSE, DUE_TABLE_RENEWAL_OPEN } from "../../types/RenewalTypes/RenewalTypes";


const RENEWAL_PAYMENT_OPEN = 'RENEWAL_PAYMENT_OPEN';
const RENEWAL_PAYMENT_CLOSE = 'RENEWAL_PAYMENT_CLOSE';


 
const CALL_LIST_RENEWAL = 'CALL_LIST_RENEWAL';

export function openModalRenewalPayment(){
    return {
       type: RENEWAL_PAYMENT_OPEN
    }
}

export function closeModalRenewalPayment(){
    return {
        type:RENEWAL_PAYMENT_CLOSE
    }
}

export function callListRenewalToggle(){
    return {
        type: CALL_LIST_RENEWAL,
    
    };
}





const initialState = {
  isShow:false,
  callListRenewal:false,
  dueTableRenewal:false,
  dueTableData:{
   
          "machineid": null,
          "dueamount": null,
          "duedate": null,
          "machinename": null,
          "noofblocks":null,
          // "duedays": 18,
          "tenuretypename": null,
          "publicip": null,
          "branchname": null,
          "groupname": null,
          "operatingsystemname": null,
          "osiconpath": null,
          "icon": null
  }
};

function RenewalPaymentReducer(state = initialState,action) {
    switch (action.type) {
        case RENEWAL_PAYMENT_CLOSE: return{
            ...state,          
            isShow:false
        }
        case RENEWAL_PAYMENT_OPEN:{
            return {
                ...state,
                isShow:true
            }
        }

        case DUE_TABLE_RENEWAL_OPEN:{
            return {
                ...state,
                dueTableRenewal:true
            }
        }

        case DUE_TABLE_DATA:{
            return {
                ...state,
                dueTableData:action.payload
            }
        }


        case DUE_TABLE_RENEWAL_CLOSE:{
            return {
                ...state,
                dueTableRenewal:false
            }
        }
        case CALL_LIST_RENEWAL:{
            return {
                ...state,
                callListRenewal:!state.callListRenewal
            }
        }
        

        default:
            return state;
    }

}




export default RenewalPaymentReducer;
