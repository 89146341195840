import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function MachineLocationSkeletionCard(){
    return   <Skeleton className='d-flex' width={200} height={70} />
}

function MachineLocationsSkeleton({times,classes}) {
    

    // function renderItems(){
    //     const items = [];
    //     for(let i=0;i<times;i++){
    //         items.push(<MachineLocationSkeletionCard/>);
    //     }

    //     return items;
    // }
  return (
    <Skeleton count={times}  containerClassName={`d-flex d-gap-10 flex-wrap ${classes}`} width={200} height={70} />
  )
}

export default MachineLocationsSkeleton