import React from 'react';
import { withRouter } from 'react-router-dom';
import './SideModalHS.css';

function SideModalHS({handler,history,backToRouteName,children}) {
  function handleClick(e){
    if(!handler){
      console.error('No Handler provider SideModalHS')
    };
  
    if(e.target.classList.contains('sideModalComponent')){
      history.push(backToRouteName);
      return;
    }

    if(e.target.classList.contains('closeHsModal')){
      history.push(backToRouteName);
      return;
    }
    
  }
  return (
    <div onClick={handleClick} className='position-fixed sideModalComponent'>
        <div className='sideModalComponentBox'>
          <div className='closeHsModal'>X</div>
          <div className='childrenSideModalHs'>
          {children}
          </div>
        </div>
    </div>
  )
}

export default withRouter(SideModalHS);