import React from 'react';
import './SearchInputCustomTable.css';

function SearchInputCustomTable({data}) {
  return (
    <div>
         <input type="text" className='form-control searchInputCustomTable  w-100 hs_borderRadius-4' placeholder='Search' />
    </div>
  )
}

export default SearchInputCustomTable