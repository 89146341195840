import produce from "immer";
import { CLEAR_PAYMENT_REDIRECT_PAGE, CREATED_MACHINE_ALERT, HIDE_AMOUNT_ADDED_TO_WALLET, HIDE_PAYMENT_REDIRECT_PAGE, PUT_ORDER_TOKEN, PUT_REDIRECT_PAGE, SHOW_AMOUNT_ADDED_TO_WALLET, SHOW_PAYMENT_REDIRECT_PAGE } from "../../types/RedirectPageTypes/PaymentRedirectPageTypes";



const initialState = {
   redirectPage:false,
   redirectLink:'',
   orderId:'',
   isAmountAdded:false,
   isCreatedMachine:false
};

function PaymentRedirectReducer(state = initialState, {type,payload}) {
    switch (type) {
        case SHOW_PAYMENT_REDIRECT_PAGE:{
            return produce(state,draft => {
                draft.redirectPage = true;
            });
        }

        case HIDE_PAYMENT_REDIRECT_PAGE:{
            return produce(state,draft => {
                draft.redirectPage = false;
            });
        }

        case PUT_REDIRECT_PAGE:{
            return produce(state,draft => {
                draft.redirectLink = payload;
            });
        }

        case PUT_ORDER_TOKEN:{
            return produce(state,draft => {
                draft.orderId = payload;
            }); 
        }

        case SHOW_AMOUNT_ADDED_TO_WALLET:{
            return produce(state,draft => {
                draft.isAmountAdded = true;
            });
        }

        case HIDE_AMOUNT_ADDED_TO_WALLET:{
            return produce(state,draft => {
                draft.isAmountAdded = false;
            });
        }

        case CREATED_MACHINE_ALERT:{
            return produce(state,draft => {
                draft.isCreatedMachine = true;
            });
        }

        case CLEAR_PAYMENT_REDIRECT_PAGE:{
            return initialState;
        }

        default:
            return state;
    }

}




export default PaymentRedirectReducer;
