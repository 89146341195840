import React, { useState } from "react";
import { isMobile } from 'react-device-detect';
/// React router dom
import { Link } from "react-router-dom";
import { ToggleSideContextState } from "../../../context/toggleSideBar/ToggleSideContext";

/// images
// import logo from "../../../images/logo.png";
import logoText from "../../../images/hireserver/imgs/logotext.png";
import logoSingle from "../../../images/hireserver/svg/logo_single.svg";

import hireservermobile from '../../../images/hireserver/imgs/hireservermobile.svg';


const NavHader = () => {
   // const [toggle, setToggle] = useState(false);
   const {toggle, setToggle} = React.useContext(ToggleSideContextState);
   
   return (
      <div className="nav-header">
         <Link to="/" className={`brand-logo d-flex  d-gap-15 ${isMobile?'brand-logo-mobile':''}`}>
           

           

            {isMobile ?  <img src={hireservermobile} alt="" className="w-100 h-100 mobileImgLogoMain" /> :<React.Fragment>
            <img className="logo-abbr" src={logoSingle} alt="" /> 
        <img className="brand-title" src={logoText} style={{width:'150px'}} alt="" /> 
               
               </React.Fragment>}
           
         </Link>

         <div className="nav-control" onClick={() => setToggle(!toggle)}>
            <div className={`hamburger ${toggle ? "is-active" : ""}`}>
               <span className="line"></span>
               <span className="line"></span>
               <span className="line"></span>
            </div>
         </div>
      </div>
   );
};

export default NavHader;
