import React from 'react';

export default function useDeployHook (){
    const [deployOpen,setDeployOpen] = React.useState(false);
    const [summaryMachineInfo,setSummaryMachineInfo] = React.useState({
        location:{
            image:null,
            name:null
        },
        os:{
            groupname:null,
            name:null
        },
        machineName:null,
        blocks:null,
        tenure:null,
        specs:null,
        database:{
            icon:null,
            appname:null,
            appversion:null
        }
    })

    function openDeployModal(){
        setDeployOpen(true);
    }

    function closeDeployModal(cb){
        if( typeof cb === 'function'){
            cb();
        }
        setDeployOpen(false);
    }

    return {deployOpen,openDeployModal,closeDeployModal,summaryMachineInfo,setSummaryMachineInfo};
}