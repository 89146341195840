import React from 'react'
import Select, { components } from 'react-select';
import HeadingHS from '../controls/HeadingHS';

// const animatedComponents = makeAnimated();

const Input = (props) => {
    // console.log(props)
    const [obj, setObj] = React.useState([]);
    const { selectTagOption, setSelectTagOption } = props.selectProps.components['Input'];
    function onInput(e) {
       
        let currVal = e.target.value;
        //let newarr = [...selectTagOption.slice(0, -1), { value: currVal, label: currVal }]
        let newarr = [...selectTagOption, { value: currVal, label: currVal }]
        setSelectTagOption(newarr)
    }
    if (props.isHidden) {
        return <components.Input  {...props} />;
    }
    return (
        <div >
            <div content={'Custom Input'}>
                <components.Input    onInput={onInput} {...props} />
            </div>
        </div>
    );
};

function TagsComponent({selectTagOption, setSelectTagOption,title,titleClasses}) {
    //const {  } = React.useContext(NodeBalanceContext);
    //const [inputCom,setInputCom] = React.useState(null);
    React.useEffect(function(){
        Input['selectTagOption'] = selectTagOption;
        Input['setSelectTagOption'] = setSelectTagOption
    },[]);
    function logChange(val) {
        //// console.log(val)
    }
    return (
        <div className=''>
            <HeadingHS as={`${titleClasses} h3 pb-1`} title={title} />
            <Select
                closeMenuOnSelect={false}
                placeholder={<div>Type to choose or create a tag.</div>}
                isMulti
                onChange={logChange}
                components={{Input}}
                options={selectTagOption}
                className="selectInputHs"
            />

        </div>
    )
}

export default React.memo(TagsComponent)