import React from 'react'
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { checkMachinePromise } from '../../../../../services/API_SERVICES';
import { getOrderDetailsService, getpaymentCheckStatusService, getPaymentWalletAmountService, postCheckPaymentStatusService, postconfirmWalletAddedService } from '../../../../../services/paymentServices/PaymentService';
import { amountAddToWalletAction_SHOW, createdActionMachine_alert, putOrderIdAction } from '../../../../../store/actions/RedirectPageActions/RedirectPageAction';

import { checkResData } from '../../../../../utils/Helpers';
import { handlerAlert } from '../../../Dashboard/Instances/InstancesListComponents/DropDownInstancesList';
import LoaderHS from '../LoaderHS';
import DonePayment from './DonePayment';
import FailPayment from './FailPayment';
import PaymentFail from './failPayment/PaymentFail';
import ResErrorPayment from './ResErrorPayment';
import RenewalSuccess from './successpayment/RenewalSuccess';

function PaymentInner({ userDetails, history }) {
    let parms = useParams();
    const [loading, setLoading] = React.useState(true);
    const [status, setStatus] = React.useState({
        name:'',
        val:''
    });
    const [paymentDone, setPaymentDone] = React.useState(false);
    const [orderidVal, setOrderIdVal] = React.useState('');
    const [bankdetails, setBankDetails] = React.useState(null);
    const [bankOtherDetails,setBankOtherDetails] = React.useState(null);
    const dispatch = useDispatch();
    React.useEffect(function () {
      
        checkStatusFun();
    }, []);

    // if(loading){
    //     return <LoaderHS/>
    // }

    async function checkStatusFun() {
      
        let ordertokenVal = parms?.ordertoken.split("=")[1];
        let orderidVal = parms?.orderid.split("=")[1];
        let formWhere = parms?.from.split("=")[1];
   
    debugger;
        if (formWhere == 'wallet') {
             
            history?.push("/profile/transaction");
            dispatch(putOrderIdAction({
                orderidVal,
                formWhere
            }));
            dispatch(amountAddToWalletAction_SHOW());
            return;



        } else if (formWhere == 'baremetal') {
            history?.push("/baremetal");
            dispatch(putOrderIdAction({
                orderidVal,
                formWhere
            }));
            dispatch(createdActionMachine_alert());
            return;
        } else if (formWhere == 'instance') {
            try {
             
                setOrderIdVal(orderidVal);
                //const ress = await getOrderDetailsService(orderidVal);
                const res = await getpaymentCheckStatusService(orderidVal);
           
                if (res.data === '') {
                    handlerAlert("danger", "No Data", "danger");
                    return;
                }

                if (res.data?.result === '') {
                    handlerAlert("danger", "No Data", "danger");
                    return;
                }

                let data = JSON.parse(res.data.result);
                // console.log(data)
                if (data.code === '404') {
              
                    handlerAlert("danger", data.message, "danger");
                    return;
                }
                // bankdetails,
                setBankDetails(data[0]);
            
               // let s = JSON.parse(ress.data.result);
                let dataRes = data[0]?.payment_status;
                if (dataRes === "SUCCESS") {
                    // setLoading(true);
                    // setStatus(dataRes);
                    
                    let resDataSucces = await postCheckPaymentStatusService(orderidVal);
                   
                    setLoading(false);
                    setStatus({
                        name:'SUCCESS',
                        val:'instance',

                    });
                    
                    setBankOtherDetails(resDataSucces.data.result)
                    dispatch(createdActionMachine_alert());

                    if (resDataSucces.data.code === '404') {
                        handlerAlert("danger", resDataSucces.data.message, "danger");
                        setLoading(false);
                        // setStatus('error');
                        setPaymentDone(true);
                        // history?.push("/");

                        // console.log(resDataSucces)
                        return;
                    }
                    let parseRes = '';

                    //history?.push("/");
                    // if(resDataSucces.data?.result != ''){
                    //     parseRes = JSON.parse(resDataSucces.data?.result);
                    // }

                    // console.log(JSON.parse(resDataSucces.data.result))

                    // const {ProxmoxTaskId} = parseRes;
                    // checkMachinePromise(ProxmoxTaskId,HostName)
                } else {
                    setStatus({
                        name:'FAILED',
                        val:'instance'
                    });
                    setLoading(false);
                }

            } catch (error) {
                setLoading(false);
                // setStatus('error');

                setStatus({
                    name:'error',
                    val:'instance'
                });
            }
        }else if(formWhere == 'renewal'){
          
           try {
            setOrderIdVal(orderidVal);
            const res = await getpaymentCheckStatusService(orderidVal);
            
            if (res.data === '') {
                handlerAlert("danger", "No Data", "danger");
                return;
            }

            if (res.data?.result === '') {
                handlerAlert("danger", "No Data", "danger");
                return;
            }

            let dataa = JSON.parse(res.data.result);
            // console.log(data)
            if (dataa.code === '404') {
              
                handlerAlert("danger", dataa.message, "danger");
                return;
            }
            // bankdetails,
       
            setBankDetails(dataa[0]);
            let resDataSucces = await postCheckPaymentStatusService(orderidVal,true);
          
           
            if (resDataSucces.data === '') {
                handlerAlert("danger", "No Data", "danger");
                return;
            }

            if (resDataSucces.data?.result === '') {
                handlerAlert("danger", "No Data", "danger");
                return;
            }

            let data = JSON.parse(resDataSucces.data.result);
            // console.log(data)
            if (data.code === '404') {
              
                handlerAlert("danger", data.message, "danger");
                return;
            }
            // bankdetails,
      
            if (data === "Payment For Renewal is success") {
                setLoading(false);
                //setStatus('renewal');
                setStatus({
                    name:'renewal',
                    val:'renewal'
                });
            }else{
                setLoading(false);
                setStatus({
                    name:'FAILED',
                    val:'renewal'
                });
                // setStatus({
                //     name:'renewal',
                //     val:'renewal'
                // });
            }
           } catch (error) {
            setLoading(false);
            setStatus({
                name:'error',
                val:'renewal'
            });
           }
        }



    }

    if (loading) {
        return <LoaderHS />
    }

    if (status.name === 'error') {
        return <ResErrorPayment paymentDone={paymentDone} />
    }

    if (status.name === "SUCCESS") {
        return <DonePayment bankOtherDetails={bankOtherDetails} bankdetails={bankdetails} orderidVal={orderidVal} />
    }

    if(status.name === "FAILED"){
        return <PaymentFail  which={status.val}  />
    }

    if(status.name == 'renewal'){
        return <RenewalSuccess bankdetails={bankdetails}  orderidVal={orderidVal} />
    }


    //   return (
    //    <FailPayment/>
    //   )
    return <React.Fragment>

    </React.Fragment>
}



export default withRouter(PaymentInner);