import React from 'react'
import { connect, useDispatch } from 'react-redux';
import { VM_ActionCall, VM_ActionStarted } from '../../../../../../store/actions/InstancesActions/InstanceCurrItemAction';
import { deleteShowConfirmModal, getIntancesList } from '../../../../../../store/actions/InstancesActions/InstancesActions';
import ModalHs from '../../../../hireserver/ModalHs'
import { handlerAlert } from '../../InstancesListComponents/DropDownInstancesList';
import warming from '../../../../../../images/hireserver/imgs/warming.jpg';
import { DELETE_STAGE, PREDELETE_STAGE } from '../../../../../../utils/Emuns';
import _, { cloneDeep } from 'lodash';
import { setDetailInstances } from '../../../../../../store/actions/InstancesActions/InstanceDetailsActions';
import { preformDetailsAction } from './OverActionHelper';
import { withRouter } from 'react-router-dom';
import StaticActionAlert from '../../../../../../utils/StaticsClasses.js/StaticActionAlert';
import { deleteInstanceFailService } from '../../../../../../services/InstancesServices/InstancesListService';
import { Button } from 'react-bootstrap';

function DeleteInstancesModal({ curSelectItem, showDeleteModal, history }) {
    const [modalInput, setModalInput] = React.useState('');
    const dispatch = useDispatch();
    const [showModalInputError, setShowModalInputError] = React.useState(false);
    const [disabledBtn, setDisabledBtn] = React.useState(false);
    const [counter,setCounter] = React.useState({
        count:5,
        isShow:false,
        disabled:false
    });
    const intervalRef = React.useRef(null);

    React.useEffect(function (){
        
            setDisabledBtn(false);
            setCounter({
                count:5,
                isShow:false,
                disabled:false
            });
            setShowModalInputError(false);
            intervalRef.current = null;

        
    },[showDeleteModal]);


    // const debouncedChangeHandler = React.useMemo(
    //     () => _.debounce(() => setTimer(), 100),
    //     [counter]
    // );
    // React.useEffect(() => {
    //     return () => {
    //         debouncedChangeHandler.cancel();

    //     };
    // }, [debouncedChangeHandler]);

    function setTimer(cb){
        /// setDisabledBtn(false)
        if(intervalRef.current){
            clearInterval(intervalRef.current)
        }
       
        intervalRef.current = setInterval(function (){
            console.log("timmmmmerrrrrr");
            setCounter(prevCounter => {
                if(prevCounter.count < 1){
                    if(!intervalRef.current) return prevCounter;
                    clearInterval(intervalRef.current)
                    intervalRef.current = null;
                    cb();
                    return {
                        count:5 ,
                        isShow:true,
                        disabled:true
                    }
                }else{
                    return {
                        count:prevCounter.count -1 ,
                        isShow:true,
                        disabled:true
                    }
                }
               
            });
          
           
        
           
           
            
        },1000);
    }

    // React.useEffect(function (){
    //     console.log(counter,"counter");
    //     if(counter.count<1){
    //         debugger;
    //         clearInterval(intervalRef.current);
    //         setCounter({
    //             count:5,
    //     isShow:false,
    //     disabled:false
    //         });
    //         setDisabledBtn(false);
    //     }
    // },[counter])


    function handleClose() {
        setModalInput('');
        setShowModalInputError(false);
        dispatch(deleteShowConfirmModal());
        if(intervalRef.current){
            clearInterval(intervalRef.current)
        }
        setCounter({
            count:5,
            isShow:false,
            disabled:false
        });
        setDisabledBtn(false);
    }

    function handlerModalInput(e) {
        setModalInput(e.target.value);
        console.log(disabledBtn,'disabledBtndisabledBtndisabledBtn');
            // setCounter({
            //     count:5,
            //     isShow:false,
            //     disabled:true
            // })
            // setDisabledBtn(true);
        // debouncedChangeHandler();
        // if(timerRef){
        //     clearTimeout(timerRef);
        // }
        // timerRef.current = setTimeout(function (){
        //     console.log("dashdhsh timer");
        //     setDisabledBtn(false);
        // },500);

    }


    async function okayHandler(){
        const { url, method, machineid, num, statusId, machinename, nodeType } = curSelectItem;
        const { itemData: { orderstatusid, orderid } } = curSelectItem;
        console.log(machineid,'curSelectItem',curSelectItem);
  
        if (machinename != modalInput) {
            setShowModalInputError(true);
            return
        }
        dispatch(deleteShowConfirmModal());
        setModalInput('');
        StaticActionAlert.actionIds[machineid] = {
            statusId: DELETE_STAGE,
            isTrigger: false,
            text: 'deleted',
            touched: false
        };
        let sitePath = window.location.href;

        if (sitePath.includes('vmdetails/overview')) {
            // history.push("/instances");
            // return 
            let newCloneItem = cloneDeep(curSelectItem);
            newCloneItem['item']['refstatusid'] = PREDELETE_STAGE;
            newCloneItem['item']['statusid'] = PREDELETE_STAGE;
            newCloneItem['item']['consoleDisabled'] = 'viewConsoleDisable';
            dispatch(setDetailInstances(newCloneItem.item))


            let vmCode = sitePath.split('instances/')[1].split('/vmdetails')[0];

            const resAns = await preformDetailsAction(url, method, vmCode, dispatch);

            //alert("Dasdn")
            if (resAns.error) {
                handlerAlert('danger', resAns.data.data.message, 'danger');
                return;
            }

            dispatch(getIntancesList());
            history.push("/instances");
            //handlerAlert('success','Your Machine is Deleted')
            return;

        }
debugger;
        if (orderstatusid == 1) {
            try {

                const resDeleteJSON = await deleteInstanceFailService(orderid);
                setShowModalInputError(false);

                dispatch(getIntancesList());
                return
            } catch (error) {

            }
        } else {
            dispatch(VM_ActionStarted(curSelectItem));
            // dispatch(getIntancesList());
          dispatch(VM_ActionCall(url, nodeType, method, machineid, num, statusId, handlerAlert, curSelectItem));
         // alert("olkay")
            setShowModalInputError(false);
            setDisabledBtn(false);
        }
    }

    function deleteHandler() {
        //okayHandler();
     

        const { url, method, machineid, num, statusId, machinename, nodeType } = curSelectItem;
      
       
  
        if (machinename != modalInput) {
            setShowModalInputError(true);
            
            return;
        }

        setDisabledBtn(true);
        setTimer(()=>{
            okayHandler();
        });
       




    }
    return (
        <ModalHs isNeed={false}  showModalHS={showDeleteModal}  >
            <div style={{ height: '20px', width: '50px' }}>
                <img className='w-100 h-100' src={warming} alt="Delete Machine" />
            </div>
            <div className='d-flex flex-column  d-gap-20'>
                <div>
                    <div>Delete Server</div>
                    <div className='font-w600'>{curSelectItem.machinename}</div>
                </div>
                <div className='d-flex flex-column  d-gap-10'>

                    <div className='hs_text-light '>By deleting this Bare Metal Cloud Server you won't be able to log in and access it's content. This change can not be undone.
                    </div>
                    <div className='hs_text-light '>
                        Are you sure you want to continue and delete this server?
                    </div>
                    <div>
                        <div>
                            Enter the selected server's hostname (any case) to confirm:
                        </div>
                    </div>
                    <input className='form-control' style={{ borderRadius: '4px' }} value={modalInput} onInput={handlerModalInput} />
                    {showModalInputError && <div className='text-danger'>Hostname doesn't match. Enter the selected server's hostname (any case).</div>}
                </div>
                <div className='d-flex justify-content-end d-gap-10'>
                    <Button style={{ borderRadius: '4px' }} className="btn-sm bg-white text-dark" variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button disabled={disabledBtn} style={{ borderRadius: '4px' }} className={`btn-sm danger w-150p`} variant="danger" onClick={deleteHandler}>
                  {counter.isShow ?  <span> Wait for {counter.count}s  </span>: <span>Destory Machine</span> } 
                    </Button>
                </div>
            </div>

        </ModalHs>
    )
}

const mapStateToProps = (state) => {
    return {
        showDeleteModal: state.instances.showDeleteModal,
        curSelectItem: state.instances.curSelectItem,
    };
};

export default connect(mapStateToProps)(withRouter(DeleteInstancesModal));