import React from 'react'
import { Link } from 'react-router-dom'

function WalletHeaderShow() {
  return (
    <li className="nav-item wallHeaderItem" >

      <Link to="/profile/transaction">
        <span className="font-30 text-center icon_wallet d-flex cursor-pointer hs_text-light p-1 themeButton font-21">

          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="25" viewBox="0 0 32.000000 32.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
              fill="#3e4954" stroke="none">
              <path d="M113 256 c-23 -14 -53 -26 -65 -28 -22 -3 -23 -7 -23 -93 l0 -90 124
-3 c132 -3 141 0 141 45 0 19 -6 22 -42 25 -38 3 -43 6 -43 28 0 22 5 25 43
28 32 2 42 7 42 21 0 20 -19 41 -36 41 -7 0 -25 11 -40 25 -33 31 -48 31 -101
1z m22 -16 c-3 -5 -14 -10 -23 -9 -14 0 -13 2 3 9 27 11 27 11 20 0z"/>
              <path d="M210 140 c0 -17 7 -20 45 -20 38 0 45 3 45 20 0 17 -7 20 -45 20 -38
0 -45 -3 -45 -20z"/>
            </g>
          </svg>


        </span>
      </Link>
    </li>
  )
}

export default WalletHeaderShow