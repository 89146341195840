import React from 'react'

function RebootHsIcon({width}) {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width={width} viewBox="0 0 32.000000 32.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M234 286 c-4 -11 -11 -11 -33 -3 -64 24 -152 -23 -171 -92 -13 -49 2
-99 41 -137 26 -25 38 -29 89 -29 48 0 63 4 85 25 31 29 40 44 49 83 5 23 3
27 -14 27 -13 0 -20 -7 -20 -20 0 -11 -13 -34 -29 -52 -41 -47 -101 -47 -142
0 -40 44 -40 93 0 133 23 23 38 29 70 29 40 0 41 -1 35 -27 -7 -25 -6 -26 31
-20 50 8 56 15 48 60 -6 37 -28 51 -39 23z"/>
</g>
</svg>

  )
}

export default RebootHsIcon