import React from 'react'
import { connect, useDispatch } from 'react-redux';
import { closeHS_rebootModal, getIntancesList } from '../../../../../../store/actions/InstancesActions/InstancesActions';
import { VM_ActionCall,VM_ActionStarted } from '../../../../../../store/actions/InstancesActions/InstanceCurrItemAction';
import ModalHs from '../../../../hireserver/ModalHs'
import { handlerAlert } from '../../InstancesListComponents/DropDownInstancesList';
import { REBOOT_STAGE, START_STAGE } from '../../../../../../utils/Emuns';
import { cloneDeep } from 'lodash';
import { preformDetailsAction } from './OverActionHelper';
import { setDetailInstances } from '../../../../../../store/actions/InstancesActions/InstanceDetailsActions';
import StaticActionAlert from '../../../../../../utils/StaticsClasses.js/StaticActionAlert';

function RebootInstancesModal({curSelectItem,rebootShowModal}) {
    const dispatch = useDispatch();
    const [rebootText, setRebootText] = React.useState({
        text: '',
        error: false
      });
      function handleRebootModalClose() {
        setRebootText({
          text: '',
          error: false
        });
        dispatch(closeHS_rebootModal());
      }
      async function handleReboot(e) {

        const { url, method, machineid, num, statusId, machinename,nodeType } = curSelectItem;
        // if (machinename != rebootText.text) {
        //   setRebootText({
        //     text: rebootText.text,
        //     error: true
        //   });
        //   return
        // }
        dispatch(closeHS_rebootModal());
        
      StaticActionAlert.actionIds[machineid] = {
        statusId:START_STAGE,
        isTrigger:false,
        text:'rebooted',
        touched:false
    };
        let sitePath = window.location.href;
        if(sitePath.includes('vmdetails/overview')){
          let newCloneItem = cloneDeep(curSelectItem);
          newCloneItem['item']['refstatusid'] = REBOOT_STAGE;
          newCloneItem['item']['statusid'] = REBOOT_STAGE;
          newCloneItem['item']['consoleDisabled'] = 'viewConsoleDisable';
          dispatch(setDetailInstances(newCloneItem.item))
   
       
          let vmCode = sitePath.split('instances/')[1].split('/vmdetails')[0];
          
          const resAns = await preformDetailsAction(url,method,vmCode,dispatch);
   
          if(resAns.error){
            handlerAlert('danger',resAns.data.data.message,'danger');
            return;
          }
       
          return;
         
      }
    
        //dispatch(startLoadingInstaces());
         dispatch(VM_ActionStarted(curSelectItem));
        // dispatch(getIntancesList());
        dispatch(VM_ActionCall(url,nodeType, method, machineid, num, statusId, handlerAlert, curSelectItem));
        setRebootText({
          text: '',
          error: false
        });
    
      }
  return (
    <ModalHs handleClose={handleRebootModalClose} okayHandler={handleReboot} showModalHS={rebootShowModal} btnTitle="Reboot Machine" >
    <div className='w-100'>
      <div className='w-100 d-flex'>  <div>Are you sure to Reboot this <span className='font-w600'>{curSelectItem.machinename}</span> Machine?  </div> </div>
      {/* <input className='form-control mt-2' value={rebootText.text} placeholder='Type the machine name to stop the machine' style={{ borderRadius: '4px' }} onInput={(e) => setRebootText({
        text: e.target.value,
        error: false
      })} />
      {rebootText.error && <div className='text-center text-danger mt-2'>The Input is wrong</div>} */}
    </div>
  </ModalHs>
  )
}

const mapStateToProps = (state) => {
    return {
        rebootShowModal: state.instances.rebootShowModal,
        curSelectItem: state.instances.curSelectItem,
    };
};

export default connect(mapStateToProps)(RebootInstancesModal);