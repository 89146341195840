import React from 'react'

export default function CustomInputLabelSubText({isReq,children,title,subText}) {
    return (
        <div>
            <div>
                <span className='font-w600'>{title}</span> {isReq && <span className="hs_text-light">({isReq})</span>}
            </div>
            <div className='py-2'>
                {children}
            </div>
            {
                subText && <div className='hs_text-light'>
                {subText}
            </div>
            }
        </div>
    )
}
