import { getBareMetalLocationFilterURL, getBareMetalServersTenureURL, getBareMetalServersURL } from "../../API_ENDPOINTS/BARE_METAL_END_POINTS";
import { getUserCookieAuth } from "../../store/actions/AuthActions";
import { API_SERVICES } from "../API_SERVICES";

export function getBareMetalServerService(){
    let userCookie = getUserCookieAuth(); 
    if(userCookie === null) return;
    let { userId, sessionId } = userCookie;
    let url = getBareMetalServersURL(userId);
    let config = {
        method: 'get',
        url,
        headers: { 'Authorization': `Basic ${sessionId}` }
    };

    return API_SERVICES(config);
}

export function getBareMetalServersTenureService(serverId){
    let userCookie = getUserCookieAuth(); 
    if(userCookie === null) return;
    let { userId, sessionId } = userCookie;
    let url = getBareMetalServersTenureURL(serverId,userId);
    let config = {
        method: 'get',
        url,
        headers: { 'Authorization': `Basic ${sessionId}` }
    };

    return API_SERVICES(config);
}

export function getBareMetalLocationFilterService(itemId){
    let userCookie = getUserCookieAuth(); 
    if(userCookie === null) return;
    let { userId, sessionId } = userCookie;
    let url = getBareMetalLocationFilterURL(itemId,userId);
    let config = {
        method: 'get',
        url,
        headers: { 'Authorization': `Basic ${sessionId}` }
    };

    return API_SERVICES(config);
}