import React from 'react'
import { connect } from 'react-redux'
import BareMetalSVG from '../../hireserver/icons/svg/BareMetalSVG';
import FirewallSVG from '../../hireserver/icons/svg/FirewallSVG';
import MachinesCard from './MachinesCard';

import {mainContainer} from './MachineList.module.css';
import SupportSVG from '../../hireserver/icons/svg/SupportSVG';

function MachinesList({dashboardDetails}) {
    const [machinesState,setMachinesState] = React.useState(null);
    React.useEffect(function (){
       
        setMachinesState(dashboardDetails)
    },[dashboardDetails]);
    const instancesLink = machinesState?.instancescount ? '/instances' : '/instances/create'
  return (
    <div className={`row mx-0 py-5 hs-boxshadow ${mainContainer}`}>
        <MachinesCard LinkUrl={instancesLink} value={machinesState?.instancescount||0} icon="flaticon-381-layer-1" name="Instances" />
        <MachinesCard LinkUrl="/baremetal" borderNeed={true} svg={<BareMetalSVG color="#FF6464" />} value={machinesState?.baremetalcount||0}  name="Bare Metal" />
        <MachinesCard LinkUrl="support/opentickets" svg={<SupportSVG color="#FED049" />} value={machinesState?.support}  name="Tickets" />
    </div>
  )
}
const mapStateToProps = ({ userDashboard}) => {
    return {
        dashboardDetails:userDashboard.dashboardDetails
    };
  };
export default connect(mapStateToProps)(MachinesList)