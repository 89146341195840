import React from 'react'
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CustomDropDown from '../../../../hireserver/components/CustomDropDown';

import ScriptMarketContext from './ScriptMarketContext/ScriptMarketContext';

function ScriptCardItem({ val,execIsNeed=false}) {

    const { setEditScript, handleDeleteOpen, saveAndExec } = React.useContext(ScriptMarketContext);

    // React.useEffect(function (){
    //     let newItems = [];
    //     const list = ['']
    //     for(let i =0;i<2;i++){
    //         let currItem = {}


    //         currItem['title'] = ""

    //     }

    //     setActionItemState(newItems)
    // },[]);

    function handlerItem() {
        setEditScript(val)
    }
    return (
        <ScriptCardTempItem execIsNeed={execIsNeed} val={val} execHander={saveAndExec} handlerDelete={handleDeleteOpen}  link={true} />

    )
}

export default ScriptCardItem;

export const ScriptCardTempItem = ({ val,execHander, link = false, urlLink,handlerDelete,execIsNeed=false }) => {
    return (
        <li className="col-sm-6 col-lg-3 col-xxl-3 p-1" >
            <div className="px-3 py-2 card hs-boxshadow-1 m-0 h-100 border-radius-4">
                <div className="d-flex">

                    <div className='w-100'>
                        <div className="font-w500 d-flex justify-content-between">
                            {/* <u className='text-primary  cursor-pointer'>{val.name}</u> */}
                           {link ? 
                            <React.Fragment>
                                 {link && urlLink ?<Link className='text-primary' to={`${urlLink}`}>
                                {val.name}
                            </Link>  : <Link className='text-primary' to={`/myscripts/details/${val.scriptid}`}>
                                {val.name}
                            </Link>}
                            </React.Fragment>
                            :null
                           }

                            {
                                !link ? <div className='text-primary'>
                                    {val.name}
                                </div> : null
                            }


                            <div onClick={() => handlerDelete(val)} className=' cursor-pointer'>
                                <i className="fa fa-trash"></i>
                            </div>
                        </div>
                        <div className='w-100 text-truncate'>
                            {val.description}
                        </div>
                        <div className='d-flex justify-content-between align-items-baseline'> <span className='font-12 d-flex justify-content-center align-items-center'>{val.createddate}</span>
                       
                            {execIsNeed ? <span>
                                <button onClick={()=>execHander(val)} className='btn btn-sm hs-signin-btn hs_borderRadius-4 p-0 px-1'>Execute</button>
                            </span> : null}
                            {!execIsNeed ? <span>
                                <button onClick={()=>execHander(val)} className='btn btn-sm hs-signin-btn hs_borderRadius-4 p-0 px-1'>Create Script</button>
                            </span> : null}
                        </div>
                        
                    </div>
                </div>


            </div>
        </li>
    )
}