import React from 'react'

function StopHsIcon({width}) {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width={width}  viewBox="0 0 32.000000 32.000000"
    preserveAspectRatio="xMidYMid meet">
   
   <g transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
   fill="#000000" stroke="none">
   <path d="M91 282 c-93 -50 -93 -194 0 -244 125 -67 258 66 191 191 -22 41 -74
   71 -122 71 -19 0 -51 -8 -69 -18z m129 -122 l0 -60 -60 0 -60 0 0 60 0 60 60
   0 60 0 0 -60z"/>
   </g>
   </svg>
  )
}

export default StopHsIcon