import React from 'react'
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { connect, useDispatch } from 'react-redux';
import { getpaymentInfoService, submitPaymentFormService } from '../../../services/Profile/PaymentFormServices';
import { closePaymentInfoFormAction, putPaymentInfoStatus } from '../../../store/actions/PaymentInfoActions/PaymentInfoAction';
import StaticPaymentDetails from '../../../utils/StaticsClasses.js/StaticPaymentDetails';
import { handlerAlert } from '../../components/Dashboard/Instances/InstancesListComponents/DropDownInstancesList';
import FormInputError from '../../components/hireserver/components/FormInputError';
import InputErrorBackground from '../../components/hireserver/components/InputErrorBackground';
import DropDownSearch from '../../components/hireserver/controls/DropDownSearch';
import SpacerHS_ONE from '../../components/hireserver/Spaces/SpacerHS_ONE';
import SpacerHS_TWO from '../../components/hireserver/Spaces/SpacerHS_TWO';
import CountriesList from './components/CountriesList';
import CurrenciesList from './components/CurrenciesList';
import StatesList from './components/StatesList';
import './BillingPaymentInfoForm.css';
import InputLabelUp from '../../components/hireserver/controls/InputLabelUp/InputLabelUp';
import DropDownSelectSearch from '../../components/hireserver/controls/dropdownselectsearch/DropDownSelectSearch';






function IndividualPaymentInfo({ successHandler, userDetails, setUpdateHandler, paymentData }) {
  const [firstName, setFirstName] = React.useState({
    val: '',
    isError: '',
    touched: false,
    errorMessage: ''
  });

  const [taxNumber, setTaxNumber] = React.useState({
    val: '',
    isError: '',
    touched: false,
    errorMessage: ''
  });
  const [desBilling, setDesBilling] = React.useState({
    val: '',
    isError: '',
    touched: false,
    errorMessage: ''
  });
  const [addressState, setAddressState] = React.useState({
    val: '',
    isError: '',
    touched: false,
    errorMessage: ''
  });
  const [cityState, setCityState] = React.useState({
    val: '',
    isError: '',
    touched: false,
    errorMessage: ''
  });
  const [type, setType] = React.useState("Dictamen");
  const [currencyState, setCurrencyState] = React.useState(0);
  const [country, setCountry] = React.useState(0);

  const [states, setStates] = React.useState(0);
  const [isLoading, setIsloading] = React.useState(false);
  const [zipCodeState, setZipCodeState] = React.useState({
    val: '',
    isError: '',
    touched: false,
    errorMessage: ''
  });
  const [successMessage, setSuccessMessage] = React.useState({
    isTrue: false,
    errorMessage: ''
  })
  const [telephone, setTelephone] = React.useState({
    val: '',
    isError: '',
    touched: false,
    errorMessage: ''
  });
  const [serverError, setServerError] = React.useState({
    isError: false,
    errorMessage: ''
  })

  const [countryError, setErrorCountry] = React.useState({
    isError: false,
    errorMessage: ''
  })
  const [stateError, setErrorState] = React.useState({
    isError: false,
    errorMessage: ''
  });
  const [currencyStateError, setCurrencyStateError] = React.useState({
    isError: false,
    errorMessage: ''
  })
  const dispatch = useDispatch();

  const [isUpdate, setIsUpdate] = React.useState(false);


  const [height, setHeight] = React.useState(600);

  const [topError,setTopError] = React.useState(false);


  // React.useEffect(function(){
  //   setTimeout(function (){
  //     setHeight('auto')
  //   },100);
  // },[]);



  React.useEffect(function () {
    setHeight('auto')
    return () => {
      const payload = {
        val: '',
        isError: '',
        touched: false,
        errorMessage: ''
      }

      const errorPayload = {
        isError: false,
        errorMessage: ''
      }
      setFirstName(payload);

      setTaxNumber(payload);
      setTelephone(payload);
      setZipCodeState(payload);
      setAddressState(payload);
      setCityState(payload)
      setCurrencyState(0);
      setCountry(0);
      setStates(0);


      setCurrencyStateError(errorPayload);
      setErrorState(errorPayload);
      setErrorCountry(errorPayload);
      setServerError(errorPayload);
      StaticPaymentDetails.isEditing = false;
    }
  }, []);

  // React.useEffect(function (){
  //   document.querySelector(`input[type="number"]`).addEventListener("keypress", removeInputNumberAp);

  //   document.querySelector(`input[type="number"]`).removeEventListener('keypress',removeInputNumberAp)
  // },[]);  

  // function removeInputNumberAp(e){
  //   if (e.which != 8 && e.which != 0 && e.which < 48 || e.which > 57)
  //     {
  //         e.preventDefault();
  //     }
  // }

  React.useEffect(function () {

    if (paymentData === null) return;

    if (!paymentData['currencyname']) {
      return;
    }
    setIsUpdate(true);

    setUpdateHandler(true);

    // console.log(paymentData, 'paymentDatapaymentDatapaymentDatapaymentDatapaymentDatapaymentDatapaymentDatapaymentData');
    debugger;
    StaticPaymentDetails.isEditing = true;
    setFirstName({
      val: paymentData['organizationname'],
      isError: false,
      touched: !paymentData['organizationname'] ? false : true,
      errorMessage: ''
    });



    setZipCodeState({
      val: paymentData['zipcode'],
      isError: false,
      touched: !paymentData['zipcode'] ? false : true,
      errorMessage: ''
    });

    setTelephone({
      val: paymentData['phonenumber'],
      isError: false,
      touched: !paymentData['phonenumber'] ? false : true,
      errorMessage: ''
    })



    setStates({
      value: paymentData['cityid'],
      label: paymentData['cityname'],
    });

    if (paymentData['countryid'] != '' || paymentData['countryid'] != undefined || paymentData['countryid'] != null) {
      StaticPaymentDetails.isCountry = true;
    }
    setCountry({
      value: paymentData['countryid'],
      label: paymentData['countryname'],
    });




    setAddressState({
      val: paymentData['addressline'],
      isError: false,
      touched: true,
      errorMessage: ''
    })

    setCurrencyState({
      label: paymentData['currencyname'],
      value: paymentData['currencyid']
    })

  }, []);

  function currencyHandler(id) {
    setCurrencyState(id)
  }

  function countryHandler() {
    //setStates()
  }

  function handleModalClose() {
    dispatch(closePaymentInfoFormAction())
  }


  function checkValidtions() {
    let isTrue = false;
   


    if (firstName.val == '' || firstName.val == null) {
      isTrue = true;
      setFirstName({
        ...firstName,
        val: firstName.val,
        isError: true,
        errorMessage: 'Please fill in required field',

      })
    }

    if (addressState.val == '' || addressState.val == null) {
      isTrue = true;
      setAddressState({
        ...addressState,
        val: addressState.val,
        isError: true,
        errorMessage: 'Please fill in required field',

      })
    }

    if (telephone.val == '' || telephone.val == null) {
      isTrue = true;
      setTelephone({
        ...telephone,
        val: telephone.val,
        isError: true,
        errorMessage: 'Please fill in required field',
      })
    }

    if (telephone.val != '' && telephone.val.length != 10) {
      isTrue = true;
      setTelephone({
        ...telephone,
        val: telephone.val,
        isError: true,
        errorMessage: 'Invalid telephone',
      })
    }




    if (country?.value == 0 || country == 0) {
      isTrue = true;

      setErrorCountry({
        isError: true,
        errorMessage: 'Please fill in required field'
      })
    }

    if (zipCodeState.val == '' || zipCodeState.val == null) {
      isTrue = true;
      setZipCodeState({
        ...zipCodeState,
        val: zipCodeState.val,
        isError: true,
        errorMessage: 'Please fill in required field'
      })
    }

    if (country?.label === 'India') {

      if (zipCodeState.val.length < 6) {
        isTrue = true;
        setZipCodeState({
          ...zipCodeState,
          val: zipCodeState.val,
          isError: true,
          errorMessage: 'Pin Code should be more than 6'
        })
      }
    }




    if (states?.value == 0 || states == 0 || states == null) {
      isTrue = true;
      setErrorState({
        isError: true,
        errorMessage: 'Please fill in required field'
      })
    }

    if (currencyState?.value == 0 || currencyState == 0) {
      isTrue = true;
      setCurrencyStateError({
        isError: true,
        errorMessage: 'Please fill in required field'
      })
    }

    // if (telephone?.val == '' || telephone?.val == null) {
    //   isTrue = true;
    //   setTelephone({
    //     ...telephone,
    //     isError: true,
    //     errorMessage: 'Please fill in required field'
    //   })
    // }

    // setCompanyname({
    //   ...companyName,
    //   val: companyName.val,
    //   isError: true,
    //   errorMessage: 'Please fill in required field',
    // })



    return isTrue;
  }

  async function okayHandler(e) {

    let isTrue = true;
    document.querySelectorAll('.billingFormParent  .radiotabInput').forEach(function (el){
     if(el.checked){
      isTrue = false;
     }
          
    })

    if(isTrue){
      setTopError(true);
      return;
    }

    setIsloading(true);
    // console.log({
    //   firstName,
    //   addressState,
    //   currencyState,
    //   country: country?.value,
    //   states: states?.value,
    //   telephone
    // })

    let isNotValid = checkValidtions();
  
    if (isNotValid) {
      setTopError(false);
      setIsloading(false)
      return;
    }


    // let payload = {
    //   "cityid": states?.value,
    //   "addressline1": addressState.val,
    //   "zipcode": zipCodeState.val

    // }

    let updateForm = false;
    let formType = 2;
    let contactVal = firstName.val;

    

    if (paymentData && paymentData['currencyname']) {
      updateForm = true;
    }
    let cityId = states.__isNew__ ? 0 : states?.value;
    let payload = {
      "cityid": Number(cityId),
      "CityName": states?.label,
      "addressline1": addressState.val,
      "zipcode": zipCodeState.val,
      "OrganizationType": formType,
      "organizationname": contactVal,
      //"OrganizationId":,
      "ContactName": firstName.val,
      "ContactId": 0,
      "countryname": country.label,
      "countryid": country.value,
      "statename": states?.label,
      "phonenumber": telephone?.val,
      "zipcode": zipCodeState.val,
      "addressline1": addressState.val,
      "currencyid": currencyState?.value,
      // "currencyid": currencyState?.value,
      "taxno": taxNumber.val,
      "BillingInfoExists": updateForm,
      "lobid": paymentData['lobid'],
      "gstindetailid": paymentData['gstindetailid'] || 0

    }
    // console.log(payload)

    // console.log(payload, 'payloadpayloadpayloadpayloadpayloadpayload')
    let res = '';
    try {
      res = await submitPaymentFormService(updateForm, payload);
      if (res.data.code != '0') {
        setIsloading(false);
        setServerError({
          isError: true,
          errorMessage: res?.data?.message
        })
        return;
      }
      if (res.data.result.includes('Billing info saved succesfully')) {
        let isFilled = false;
        let paymentData = null;
        const checkPaymentStatus = await getpaymentInfoService();
        paymentData = JSON.parse(checkPaymentStatus.data.result);
        isFilled = paymentData[0]?.defaultaddressid == 0;
        dispatch(putPaymentInfoStatus(paymentData[0]));

        setIsloading(false);
        setSuccessMessage({
          isTrue: true,
          message: 'Form filled successful'
        });
        dispatch(closePaymentInfoFormAction())
        handlerAlert("success", "Billing info has been updated!")

        //successHandler(true);
        // setTimeout(function () {
        //   handleModalClose();
        // }, 3000);
        setTopError(false);
      } else {
        setServerError({
          isError: true,
          message: res?.data?.message
        })
      }
      setTopError(false);
    } catch (error) {
      setIsloading(false);
      // console.log(error);
      // alert(error?.message);
      setServerError({
        isError: true,
        errorMessage: res?.data?.message
      })
      setTopError(false);
    }
    // console.log(res);

  }

  function simpleValidInput(val, setterFunction) {
    let payload = {
      val,
      isError: false,
      errorMessage: '',
      touched: true
    }

    if (val === '' || val === null) {
      payload.isError = true;
      payload.errorMessage = 'Please fill in required field'
    }

    setterFunction(payload);
  }

  function notValidInput(val, setterFunction) {
    let payload = {
      val,
      isError: false,
      errorMessage: '',
      touched: true
    }
    setterFunction(payload);
  }


  function simpleValidNumberInput(e, val, setterFunction, len = 0,) {
    if (len != 0) {
      if (e.target.value.length > len) return;
    }
    let payload = {
      val,
      isError: false,
      errorMessage: '',
      touched: true
    }

    if (e.which != 8 && e.which != 0 && e.which < 48 || e.which > 57) {
      e.preventDefault();
      return;
    }

    if (val === '' || val === null) {
      payload.isError = true;
      payload.errorMessage = 'Please fill in required field'
    }



    setterFunction(payload);
  }

  function getClass(dataObj) {
    return `form-control ${dataObj.isError === true ? 'inputAlertVal' : ''} hs_borderRadius-4 ${dataObj.touched && dataObj.isError === false ? 'validFormInput' : ''}`
  }

  function notImpGetClass(dataObj) {
    return `form-control ${dataObj.isError === true ? '' : ''} hs_borderRadius-4 ${dataObj.touched && dataObj.isError === false ? 'validFormInput' : ''}`
  }
  // const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

  function blockInvalidChar(e) {
    if (['e', 'E', '+', '-'].includes(e.key)) {
      e.preventDefault();
    }

    if (e.keyCode >= 65 && e.keyCode <= 90) {
      e.preventDefault();
    }


  }


  React.useEffect(function () {
    if (serverError.isError) {
      setTimeout(function () {
        setServerError({
          isError: false,
          serverError: ''
        })
      }, 2000);
    }
  }, [serverError.isError]);

  return (
   <React.Fragment>
     <div className='d-flex justify-content-center '>
        <div className='billingFromDiv card p-3'>
        {topError ? <div className='text-center text-danger font-12 addresstoperror' >Please select Individual or Business</div> : null}
          {
            serverError.isError && <InputErrorBackground message={serverError.errorMessage} />
          }

          {
            isUpdate && <React.Fragment>
              {/* <div className='d-flex billingFormFlex justify-content-between w-100'>
              <label className='w-100 d-flex' htmlFor='firstname'>
                <div className='col-sm-5 p-0 hs_sub_font'>Email</div>
                <div className='col-sm-7 p-0'>
                  <input disabled={true} id="email" className={`bg-light form-control hs_borderRadius-4 validFormInput`} value={userDetails?.userEmail} type="text" />

                </div>
              </label>


            </div> */}
              <InputLabelUp disabled={true} classNames={`bg-light form-control hs_borderRadius-4 validFormInput`} placeholder="Email" value={userDetails?.userEmail} id="email" type="text" />
              <SpacerHS_ONE />
            </React.Fragment>
          }

          <div className='d-flex justify-content-between w-100'>
            {/* <label className='w-100 billingFormFlex d-flex' htmlFor='telephone'>
            <div className='col-sm-5 p-0 hs_sub_font'>Company Name</div>
            <div className='col-sm-7 p-0'>
              <input data-name="telephone" onChange={(e) => notValidInput(e.target.value, setCompanyname)} id="FullName" className={`${notImpGetClass(companyName)}  `} value={companyName.val} type="text" placeholder='Enter Company Name' />
             
              {
                companyName.isError && <FormInputError message={companyName.errorMessage} />

              }
            </div>
          </label> */}

          </div>
          {/* <div className='d-flex justify-content-between w-100'>
        <label className='w-100 d-flex' htmlFor='firstname'>
          <div className='col-4 p-0'>Tax Number</div>
          <input  onChange={(e) => simpleValidInput(e.target.value, setTaxNumber)} id="FullName" className={`${getClass(taxNumber)} col-8 `} value={taxNumber.val} type="text" placeholder='Enter Tax Number' />
          {
            taxNumber.isError && <FormInputError message={taxNumber.errorMessage} />

          }
        </label>

      
      </div> */}

          <SpacerHS_ONE />

          <div className='  w-100'>
            {/* <label className=' col-12 billingFormFlex p-0 d-flex' htmlFor='firstname'>
            <div className='col-sm-5 p-0 hs_sub_font'>Contact Person<sup><i className='fa fa-asterisk text-danger font-7'></i></sup></div>
            <div className='col-sm-7 p-0'>
              <input onChange={(e) => simpleValidInput(e.target.value, setFirstName)} id="FullName" className={`${getClass(firstName)}`} value={firstName.val} type="text" placeholder='Enter contact person name' />
              {
                firstName.isError && <FormInputError message={firstName.errorMessage} />

              }
            </div>
          </label> */}

            <div className='row p-0 m-0'>
              <div className="col-sm-6 p-0 pr-md-1 pb-md-0 pb-2">
                <InputLabelUp classNames={`${getClass(firstName)}`} placeholder="Contact Person" setValue={(e) => simpleValidInput(e.target.value, setFirstName)} value={firstName.val} id="firstname" type="text" />
                {
                  firstName.isError && <FormInputError message={firstName.errorMessage} />

                }
              </div>
              <div className="col-sm-6 p-0  pl-md-1">
                <InputLabelUp onKeyDown={blockInvalidChar} classNames={`${getClass(telephone)}`} placeholder="Telephone Number" setValue={(e) => simpleValidNumberInput(e, e.target.value, setTelephone, 10)} value={telephone.val} id="telephone" type="number" />
                {telephone.isError && <FormInputError message={telephone.errorMessage} />}
              </div>
            </div>
            {/* <label className='col-12 billingFormFlex p-0 d-flex' htmlFor='telephone'>
            <div className='col-sm-5 p-0 hs_sub_font'>Telephone<sup><i className='fa fa-asterisk text-danger font-7'></i></sup></div>
           
            <div className='col-sm-7 p-0'>
              <input onKeyDown={blockInvalidChar} onChange={(e) => simpleValidNumberInput(e, e.target.value, setTelephone)} value={telephone.val} id="telephone" className={`${getClass(telephone)} `} type="number" placeholder='Enter  Telephone Number' />
              {telephone.isError && <FormInputError message={telephone.errorMessage} />}
            </div>
          </label> */}


          </div>

          <SpacerHS_ONE />
          <div className='d-flex  justify-content-between pb-md-0 pb-1'>
            {/* <label className='w-100 d-flex billingFormFlex' htmlFor='address'>
            <div className='col-sm-5 p-0 hs_sub_font'>Address<sup><i className='fa fa-asterisk text-danger font-7'></i></sup></div>
            <div className='col-sm-7 p-0'>
              <input onChange={(e) => simpleValidInput(e.target.value, setAddressState)} id="address" value={addressState.val} className={`${getClass(addressState)}`} type="text" placeholder='Enter  Address' />
              {
                addressState.isError && <FormInputError message={addressState.errorMessage} />
              }
            </div>
          </label> */}
            <InputLabelUp classNames={`${getClass(addressState)}`} placeholder="Address" setValue={(e) => simpleValidNumberInput(e, e.target.value, setAddressState)} value={addressState.val} id="address" type="text" />

          </div>
          {
            addressState.isError && <FormInputError message={addressState.errorMessage} />
          }

          {/* 
      // <SpacerHS_ONE />
      <div className='d-flex justify-content-between'>
        <label className='w-100 d-flex ' htmlFor='address'>
          <div className='col-sm-5 p-0 hs_sub_font'>Address<sup><i className='fa fa-asterisk text-danger font-7'></i></sup></div>
          <div className='col-sm-7 p-0'>
            <input onChange={(e) => simpleValidInput(e.target.value, setAddressState)} id="address" value={addressState.val} className={`${getClass(addressState)}`} type="text" placeholder='Enter  Address' />
            {
              addressState.isError && <FormInputError message={addressState.errorMessage} />
            }
          </div>
        </label>


      </div> */}





          <div className="">


            <SpacerHS_ONE />
            <div className="row m-0 p-0 ">
              <div className="col-sm-6 p-0 pr-md-1 pb-md-0 pb-2">
                <CountriesList isDisable={isUpdate} countryError={countryError} setErrorCountry={setErrorCountry} country={country} setCountry={setCountry} />

              </div>


              <div className="col-sm-6 p-0 pl-md-1 pb-md-0 pb-1">
                <StatesList isUpdate={isUpdate} isDisable={isUpdate || !country?.value} stateError={stateError} setErrorState={setErrorState} country={country} states={states} setStates={setStates} />
              </div>

            </div>




            {/* <label className='d-flex w-100 billingFormFlex' htmlFor='pincode'>
            <div className='col-sm-5 p-0 hs_sub_font'>Pin Code<sup><i className='fa fa-asterisk text-danger font-7'></i></sup></div>
            <div className='col-sm-7 p-0'>
              <input style={{
                height: '37px'
              }} onChange={(e) => simpleValidInput(e.target.value, setZipCodeState)} id="zipcode" value={zipCodeState.val} className={`${getClass(zipCodeState)} `} type="number" placeholder='Pin Code' />
              {
                zipCodeState.isError && <FormInputError message={zipCodeState.errorMessage} />

              }
            </div>
          </label> */}




            <SpacerHS_ONE />
          </div>


          <div className='row m-0 p-0'>


            <div className="col-sm-6 p-0 pr-md-1 pb-md-0 pb-2">
              <InputLabelUp onKeyDown={blockInvalidChar} classNames={`${getClass(zipCodeState)}`} placeholder="Pin Code" setValue={(e) => simpleValidNumberInput(e, e.target.value, setZipCodeState, 6)} value={zipCodeState.val} id="address" type="number" />
              {
                zipCodeState.isError && <FormInputError message={zipCodeState.errorMessage} />

              }
            </div>

            <div className="col-sm-6  p-0 pl-md-1">



              <CurrenciesList country={country} isDisable={isUpdate} currencyStateError={currencyStateError} setCurrencyStateError={setCurrencyStateError} currencyHandler={currencyHandler} currencyState={currencyState} />

            </div>

          </div>
          {/* <SpacerHS_ONE />
        <div className='d-flex justify-content-between   w-100'>
         
          <InputLabelUp classNames={`${notImpGetClass(taxNumber)}`} placeholder="Tax Number" setValue={(e) => notValidInput(e.target.value, setTaxNumber)} value={taxNumber.val} id="taxnumber" type="number" />
          {
                taxNumber.isError && <FormInputError message={taxNumber.errorMessage} />

              }


        </div> */}
          {/* <SpacerHS_ONE />
        <div className='d-flex justify-content-between  w-100'>
          <label className='w-100 d-flex billingFormFlex  flex-column' htmlFor='desbilling'>
           
            <div className=' p-0'>
              <textarea onChange={(e) => simpleValidInput(e.target.value, setDesBilling)} id="desbilling" className={`${notImpGetClass(desBilling)} `} value={desBilling.val} type="text" placeholder='Enter Description' />
              
            </div>
          </label>
          {
                desBilling.isError && <FormInputError message={desBilling.errorMessage} />

              }

        </div> */}

          {/* {
        serverError.isError && <div className='text-center text-danger'>{serverError.errorMessage}</div>
      } */}
          {
            successMessage.isTrue && <div className='text-center text-primary'>{successMessage.errorMessage}</div>
          }
          <SpacerHS_TWO />
          <div className='d-flex justify-content-end    d-gap-10 '>
            <Button style={{ borderRadius: '4px' }} className="btn-sm bg-white  text-dark" variant="secondary" onClick={handleModalClose}>
              Close
            </Button>
            <Button style={{ borderRadius: '4px' }} className={`btn-sm hs-signin-btn w-120p `} variant={'success'} onClick={okayHandler}>
              {isLoading ? <React.Fragment>
                <div className="spinner-border text-light" role="status">

                </div> Submit
              </React.Fragment> : 'Submit'}
            </Button></div>
        </div>
      </div>
   </React.Fragment>
     
     
   
  )
}


const mapStatesToProps = ({ authAlertReducer, auth, PaymentInfoReducer }) => {
  return {

    isPaymentInfo: PaymentInfoReducer.isPayment,
    paymentData: PaymentInfoReducer?.paymentData,
    userDetails: auth.auth,
  };
};




export default connect(mapStatesToProps)(IndividualPaymentInfo);