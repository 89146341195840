import { getInstanceDetailsURL, getInstancesProxmoxURL, getListInstancesURL, twoStartURL } from "../../../API_ENDPOINTS/API_ENDPOINTS";
import getInstancesListService, { getInstanceLeftOutBizgazeService, getInstancesBizgazeService, getInstancesListProxmoxService, getServersListInstances } from "../../../services/InstancesServices/InstancesListService";
import { CALL_LIST_RENEWAL, CLEAR_INSTANCES_STORE, CLOSE_HS_MODAL, CREATING_MACHINE_INSTANCE_TOGGLE, DELETE_CONFIRM_MODAL, FINISH_INSTANCES_LOADING, GET_INSTANCES, OPEN_HS_MODAL, PUT_CURRENT_ITEM_DELETE, REBOOT_HS_HIDEMODAL, REBOOT_HS_SHOWMODAL, SELECT_ALL_LIST_ITEM_IN, SELECT_CURRENT_LIST_ITEM_IN, SET_INSTANCES, SHOW_DELETE_CONFIRM_MODAL, START_INSTANCES_LOADING, TENURE_DATA_STORE, TOGGLE_FREEZE_SELECT_ALL_ACTION, UN_SELECT_ALL_LIST_ITEM_IN, UN_SELECT_CURRENT_LIST_ITEM_IN, UPDATE_INSTANCE_ITEM, UPDATE_SELECTED_ITEMS_STATE } from "../../types/InstancesTypes";
import { DO_NOTHING } from "./InstanceCurrItemAction";
import produce from "immer";
import cloneDeep from 'lodash/cloneDeep';
import History from "../actionUtils/History";
import { logout } from "../AuthActions";
import { handlerAlert } from "../../../jsx/components/Dashboard/Instances/InstancesListComponents/DropDownInstancesList";
import AppError from "../../../services/ServiceErrorHandlers/CustomError";
import { setCoresInstances } from "../../../services/InstancesServices/InstancesFormService";
import { BootVm, checkMachineStop, updateCurrentMachine } from "../../../services/InstancesServices/InstanceCurrListNewDropdownService";
import { checkMachinePromise } from "../../../services/API_SERVICES";
import { CONFIGURE_STAGE, CREATE_STAGE, DELETE_STAGE, FAILED_STAGE, ONHOLD_STAGE, PREDELETE_STAGE, PRESTART_STAGE, PRESTOP_STAGE, REBOOT_STAGE, REFRESH_TIMER, REINSTALL_STAGE, START, START_STAGE, STOP_STAGE } from "../../../utils/Emuns";
import StaticTimer from "../../../utils/StaticTimer";
import StaticInstancesList from "../../../utils/StaticsClasses.js/StaticInstancesList";
import StaticActionAlert from "../../../utils/StaticsClasses.js/StaticActionAlert";
import { toast } from "react-toastify";
import { postCheckPaymentStatusService } from "../../../services/paymentServices/PaymentService";
const CREATE_INSTANCE = 'CREATE';



export function createInstance(instance) {
    return {
        type: CREATE_INSTANCE,
        payload: instance,
    };
}

export function updateInstaces(payload) {
    return {
        type: UPDATE_INSTANCE_ITEM,
        payload
    };
}

export function setInstances(payload) {
    return {
        type: SET_INSTANCES,
        payload
    }
}

export function clearInstancesReducer() {

    return {
        type: CLEAR_INSTANCES_STORE
    }
}

export function toggleCreatingMachine() {
    return {
        type: CREATING_MACHINE_INSTANCE_TOGGLE
    }
}

export function startLoadingInstaces() {
    return {
        type: START_INSTANCES_LOADING,
        payload: true
    }
}


export function finishLoadingInstances() {
    return {
        type: FINISH_INSTANCES_LOADING,
        payload: false
    }
}

export function showDeleteConfirmModal() {
    return {
        type: SHOW_DELETE_CONFIRM_MODAL,
    }
}

export function deleteShowConfirmModal() {
    return {
        type: DELETE_CONFIRM_MODAL,
    }
}

export function putCurrentInstanceDelete(payload) {
    return {
        type: PUT_CURRENT_ITEM_DELETE,
        payload: payload
    }
}

export function selectDispatch(payload) {
    return {
        type: SELECT_CURRENT_LIST_ITEM_IN,
        payload
    }
}

export function unSelectDispatch(payload) {
    return {
        type: UN_SELECT_CURRENT_LIST_ITEM_IN,
        payload
    }
}

export function selectCurrentInstanceListItem(payload) {
    // return {
    //     type:SELECT_CURRENT_LIST_ITEM_IN,
    //     payload
    // }

    return (dispatch, getState) => {
        const { selectItems } = getState().instances.selectedListItems;
        const { instancesList: { data } } = getState().instances;
        //    const newData = produce(data,draft => {

        //     for(let i=0;i<draft.length;i++){
        //         if(draft[i].code == payload.code){
        //             draft[i].isChecked = true;
        //         }
        //     }
        //     return draft;
        //    });

        // let newData = [...data];
        // for (let i = 0; i < newData.length; i++) {
        //     if (newData[i].code == payload.code) {
        //         newData[i].isChecked = true;
        //     }
        // }

        let newData = produce(data, draft => {
            for (let i = 0; i < draft.length; i++) {
                if (draft[i].code == payload.code) {
                    draft[i].isChecked = true;
                }
            }
        });



        dispatch(setInstances({
            data: newData,
            error: null
        }))
        // // console.log(selectItems)
        let nesselectItems = cloneDeep(selectItems)
        // // console.log(nesselectItems, '.nesselectItems')
        nesselectItems[payload.code] = payload.fullData;
        // // console.log(newSelectItem)
        dispatch(selectDispatch(nesselectItems))
    }
}


export function unCheckCurrentInstanceListItem(payload) {
    const { code, fullData } = payload;


    return (dispatch, getState) => {
        const { instancesList: { data } } = getState().instances;
        const { selectItems } = getState().instances.selectedListItems;
        let newObj = produce(selectItems, draft => {
            let newOb = {};
            for (let item in draft) {
                if (item != code) {
                    newOb[item] = draft[item];
                }
            }
            return newOb;
        })
        let newItems = produce(data, draft => {
            for (let i = 0; i < draft.length; i++) {
                if (draft[i].code == code) {
                    draft[i].isChecked = false;
                    break;
                }
            }
        });

        dispatch(setInstances({
            data: newItems,
            error: null
        }))
        dispatch(unSelectDispatch(newObj))

    }
}

export function unCheckAllInstancesAction() {
    return {
        type: UN_SELECT_ALL_LIST_ITEM_IN,
    }
}

export function toggleFreezeSelectAllAction() {
    return {
        type: TOGGLE_FREEZE_SELECT_ALL_ACTION
    }
}



export function unCheckAllInstances() {
    return (dispatch, getState) => {
        const { instancesList: { data } } = getState().instances;
        const newData = produce(data, draft => {
            for (let i = 0; i < draft.length; i++) {

                draft[i].isChecked = false;
            }

            return draft;
        });

        // let newData = [...data]
        // for (let i = 0; i < newData.length; i++) {
        //     newData[i].isChecked = false;
        // }

        dispatch(unCheckAllInstancesAction());
        dispatch(setInstances({
            data: newData,
            error: null
        }))
    }
}

export function selectAllInstanceItemsAction(payload) {
    return {
        type: SELECT_ALL_LIST_ITEM_IN,
        payload
    }
}

export function updateSelectedItem(payload) {
    return {
        type: UPDATE_SELECTED_ITEMS_STATE,
        payload
    }
}

export function selectAllInstanceItems(payload) {
    return (dispatch, getState) => {
        const { instancesList: { data } } = getState().instances;
        let newData = cloneDeep(data);
        const needItemLength = Object.getOwnPropertyNames(payload.data);
        let itemIndex = 0;

        let selectedData = {};

        for (let i = 0; i < newData.length; i++) {
            //let currItem = newData[i];
            if (itemIndex > needItemLength) break;
            if (payload.data[newData[i]['code']]) {
                newData[i].isChecked = true;
                itemIndex++;
                selectedData[newData[i]['code']] = newData[i];

            }
        }


        dispatch(selectAllInstanceItemsAction(selectedData));
        dispatch(setInstances({
            data: newData,
            error: null
        }));





    }
}

export function closeHsModal() {
    return {
        type: CLOSE_HS_MODAL
    }
}

export function openHsModal() {
    return {
        type: OPEN_HS_MODAL
    }
}

export function updateCurrentInstanceItem(machineIdItem, statusId, isDel = false) {
    return (dispatch, getState) => {
        //// console.log("Dasjdahsdjkh")
        const { data } = getState().instances.instancesList;
        let payloadData = null
        if (isDel === true) {
            payloadData = data.filter(function ({ machineid }) {
                return machineid != machineIdItem;
            });
        } else {
            payloadData = data.map(function (item) {

                let currentStatus = parseInt(item.machineid);
                if (currentStatus === machineIdItem) {
                    // 
                    let putStatusId = statusId == 1 ? 2 : 1;
                    item['statusid'] = putStatusId;
                }

                return item;
            });
        }

        // console.log(payloadData)
        dispatch(setInstances({
            data: payloadData,
            error: null
        }))
    }
}

export function openHS_rebootModal() {
    return {
        type: REBOOT_HS_SHOWMODAL
    }
}

export function closeHS_rebootModal() {
    return {
        type: REBOOT_HS_HIDEMODAL
    }
}

function generateRandom(min = 10000, max = 99999) {

    // find diff
    let difference = max - min;

    // generate random number 
    let rand = Math.random();

    // multiply with difference 
    rand = Math.floor(rand * difference);

    // add with min value 
    rand = rand + min;

    return rand;
}

// export function getIntancesList(history) {
//     return async (dispatch, getState) => {
//         try {
//             //             const { selectAll } = getState().instances.selectedListItems;
//             // // console.log(selectAll,'selectAllsss')
//             
//             const serversList = await getServersListInstances();
//             if(serversList.data.errors?.length){
//                 throw new AppError(serversList.data.errors[0],'999');
//                return;
//             }
//             let serversFullList = [];
//             let serverIds = [];
//             let fullData = [];
//             if(serversList.data.code === "0"){
//                 serversFullList = JSON.parse(serversList.data.result)
//             }
//             for(let i=0;i<serversFullList.data.length;i++){
//                 serverIds.push(serversFullList.data[i].node);
//                 let serID = serversFullList.data[i].node;
//                 let res = await getInstancesListProxmoxService(serID,dispatch);

//                 
//                if(res.data.code != "0") {
//                 dispatch(setInstances({
//                     data: [],
//                     error: null
//                 }))
//                 return;
//                }



//                let data = JSON.parse(res.data.result).data;
//                for(let i=0;i<data.length;i++){
//                 data[i]['nodeType'] = serID;
//                }
//                fullData = fullData.concat(data);




//             }

//             // console.log(fullData);

//             // const response = await getInstancesListService(null,dispatch)
//             // // 
//             // const newResponse = await getInstancesListProxmoxService(serverIds[0],dispatch);
//             // let  newResponseData = JSON.parse(newResponse.data.result);
//             // 
//             // newResponseData = newResponseData.data

//             // let {code,message} = response.data;
//             // if(code==='417' && message === "User Authentication Failed"){
//             //     dispatch(logout())

//             //     return;
//             // }

//             // if (response.data.result == null) {
//             //     dispatch(setInstances({
//             //         data: [],
//             //         error: null
//             //     }))
//             //     return;
//             // }
//             // let result = JSON.parse(response.data.result);
//             // if (result.ReportJsonData.Result.Data == '') {
//             //     dispatch(setInstances({
//             //         data: [],
//             //         error: null
//             //     }))
//             //     return;
//             // }

//             // let listData = JSON.parse(result.ReportJsonData.Result.Data)
//             let newArrList = [];
//             for (let i = 0; i < fullData.length; i++) {
//                 fullData[i]['loadingInfo'] = {
//                     loading: false,
//                     statusId: fullData[i].statusid,
//                     text: '',
//                     color: '',
//                     prev:"",
//                 }
//                 fullData[i]['code'] = fullData[i].vmid;
//                 fullData[i]['machineid'] = generateRandom();
//                 fullData[i]['machinename'] = fullData[i]['name'];
//                 fullData[i]['regionname'] = 'vizag';
//                 fullData[i]['machinetype'] = '2';

//                 if( fullData[i]['status'] === 'stopped'){
//                     fullData[i]['statusid']  = 2;
//                 } else{
//                     fullData[i]['statusid'] = 1;
//                 }

//                 let obj = {
//                     code: "452",
//                     createdby: 100011190000532,
//                     createddate: "05/07/2022",
//                     description: "",
//                     deviceid: 0,
//                     installedappid: 100010260000270,
//                     ipaddress: "127.0.1.1",
//                     lastupdatedby: 100011190000532,
//                     lastupdateddate: "05/07/2022",
//                     latid: 0,
//                     lngid: 0,
//                     machineid: 100015940000385,
//                     machinename: "bare",
//                     machinetype: 1,
//                     machinetypename: "dedicated",
//                     name: null,
//                     ownerid: 0,
//                     pk_id: 100015940000385,
//                     regionimage: "~/_files/documents/1/d006a038-79d3-438c-b59f-7db54305012f/WhatsApp Image 2022-04-17 at 9.49",
//                     regionname: "vizag",
//                     remoteid: "0",
//                     seriesid: 100010470000309,
//                     serverid: 100013670000046,
//                     servername: "Keyboard1",
//                     sourceid: 0,
//                     statusid: 1,
//                     tenantid: 1,
//                     userid: 100011190000532,
//                     username: "Sai Sudheer Babu",
//                     versionguid: "3453d589-8268-4abd-9ed8-6a5d19851f3a",
//                 }
//                 // commented start
//                 // if(selectAll){
//                 //     listData[i]['isChecked'] = true;
//                 // }else{
//                 //     listData[i]['isChecked'] = false;
//                 // }
//                  // commented end
//                  fullData[i]['isChecked'] = false;
//                 dispatch(unCheckAllInstancesAction());
//                 //listData[i]['isChecked'] = false;
//                 if (fullData[i].machinetype == 2) {
//                     newArrList.push(fullData[i]);
//                 }
//             }

//             // Do not remove this
//             // for (let i = 0; i < listData.length; i++) {
//             //     listData[i]['loadingInfo'] = {
//             //         loading: false,
//             //         statusId: listData[i].statusid,
//             //         text: '',
//             //         color: ''
//             //     }
//             //     // commented start
//             //     // if(selectAll){
//             //     //     listData[i]['isChecked'] = true;
//             //     // }else{
//             //     //     listData[i]['isChecked'] = false;
//             //     // }
//             //      // commented end
//             //     listData[i]['isChecked'] = false;
//             //     dispatch(unCheckAllInstancesAction());
//             //     //listData[i]['isChecked'] = false;
//             //     if (listData[i].machinetype == 2) {
//             //         newArrList.push(listData[i]);
//             //     }
//             // }

//             dispatch(setInstances({
//                 data: newArrList,
//                 error: null
//             }))
//             dispatch(finishLoadingInstances());
//             //console.table(listData)
//         } catch (error) {
//             // console.log(error)
//             handlerAlert('danger',error.message,'danger')
//             dispatch(setInstances({
//                 data: [],
//                 error: error.message
//             }))
//             dispatch(finishLoadingInstances());
//         }
//     };
// }




export function getIntancesList(isShowAlert) {
    return async (dispatch, getState) => {
        try {

            const { data } = await getInstancesBizgazeService(dispatch);
            debugger;
            if (data.errors != null) {
                throw new AppError(data.errors[0].message, 999);
            }
            if(data.code != '0'){
                throw new AppError(data.message, 999);
            }
            let fullData = [];
            if (data.result != '') {
                fullData = JSON.parse(data.result);
            }


            let newArrList = [];
            let isCreateMode = [];
            let isConfigMode = [];
            let errorMode = [];
            let isNeedToSetTimer = false;
            let pendingDataArr = [];


            const machineZeroCheck = [];
            // fullData[1] = fullData[0];
            // fullData[2] = fullData[0];
            // fullData[3] = fullData[0];
            // fullData[4] = fullData[0];
            // fullData[5] = fullData[0];
            // fullData[6] = fullData[0];
            // fullData[7] = fullData[0];
            // fullData[8] = fullData[0];
            // fullData[9] = fullData[0];


            let liteActions = false;
            let bigActions = false;
            console.log(fullData,"fullData");
            for (let i = 0; i < fullData.length; i++) {
                let currItem = fullData[i];
                if (currItem?.machineid === 0) {
                    let dataMachineZero = currItem?.orderdescription;
                    if (dataMachineZero.trim() === '') {
                        continue;
                    }
                    let orderidTemp = currItem['orders_orderid'];
                    // console.log(JSON.parse(dataMachineZero))
                    currItem = JSON.parse(dataMachineZero)
                    currItem['orders_orderid'] = orderidTemp;
                    machineZeroCheck.push(fullData[i].reforderid);
                    // continue;
                }
                currItem['loadingInfo'] = {
                    loading: false,
                    statusId: currItem.statusid,
                    text: '',
                    color: '',
                    prev: "",
                }
                currItem['code'] = currItem.vmid;
                // currItem['code'] = currItem.VMID;
                currItem['machineid'] = currItem.machineid;
                //currItem['machinename'] = currItem['machinename'];
                currItem['regionname'] = currItem['branchname'];
                //currItem['machinetype'] = '2';

                if (currItem['stagename'] === 'Stopped') {
                    currItem['statusid'] = 2;
                } else if (currItem['stagename'] === 'Running') {
                    currItem['statusid'] = 1;
                } else {
                    currItem['statusid'] = currItem.refstatusid;
                }

                if (currItem['orderstatusid'] == 1 || currItem.machineid === undefined) {
                    currItem['statusid'] = FAILED_STAGE;
                    currItem.refstatusid = FAILED_STAGE;
                }


                if (StaticActionAlert.actionIds[currItem['machineid']]) {
                    StaticActionAlert.actionIds[currItem['machineid']].touched = true;
                    if (currItem.refstatusid == StaticActionAlert.actionIds[currItem['machineid']]?.statusId) {
                        //console.log( StaticActionAlert.actionIds[currItem['machineid']]," StaticActionAlert.actionIds[currItem['machineid']]")


                        StaticActionAlert.actionIds[currItem['machineid']].isTrigger = true

                    }
                }

                //  || currItem.machineid === undefined
                if (currItem.refstatusid === PRESTART_STAGE || currItem.refstatusid === CONFIGURE_STAGE || currItem.refstatusid === REINSTALL_STAGE || currItem.refstatusid === CREATE_STAGE || currItem.refstatusid === PRESTOP_STAGE || currItem.refstatusid === PREDELETE_STAGE || currItem.refstatusid === REBOOT_STAGE) {

                    if (currItem.refstatusid === PRESTART_STAGE) {
                        StaticActionAlert.actionIds[currItem['machineid']] = {
                            statusId: START_STAGE,
                            isTrigger: false,
                            touched: true,
                            text: 'starting'
                        }
                    } else if (currItem.refstatusid === CONFIGURE_STAGE) {
                        StaticActionAlert.actionIds[currItem['machineid']] = {
                            statusId: START_STAGE,
                            isTrigger: false,
                            touched: true,
                            text: 'configure'
                        }
                    } else if (currItem.refstatusid === REINSTALL_STAGE) {
                        StaticActionAlert.actionIds[currItem['machineid']] = {
                            statusId: START_STAGE,
                            isTrigger: false,
                            touched: true,
                            text: 'reinstalling'
                        }
                    } else if (currItem.refstatusid === CREATE_STAGE) {
                        StaticActionAlert.actionIds[currItem['machineid']] = {
                            statusId: START_STAGE,
                            isTrigger: false,
                            touched: true,
                            text: 'creating'
                        }

                    } else if (currItem.refstatusid === REBOOT_STAGE) {
                        StaticActionAlert.actionIds[currItem['machineid']] = {
                            statusId: START_STAGE,
                            isTrigger: false,
                            touched: true,
                            text: 'rebooting'
                        }
                    } else if (currItem.refstatusid === PRESTOP_STAGE) {
                        StaticActionAlert.actionIds[currItem['machineid']] = {
                            statusId: STOP_STAGE,
                            isTrigger: false,
                            touched: true,
                            text: 'stopping'
                        }
                    } else if (currItem.refstatusid === PREDELETE_STAGE) {
                        StaticActionAlert.actionIds[currItem['machineid']] = {
                            statusId: DELETE_STAGE,
                            isTrigger: false,
                            touched: true,
                            text: 'deleting'
                        }
                    }
                    // else if (currItem.machineid === undefined) {
                    //     currItem['statusid'] = FAILED_STAGE

                    // }
                    // else if (currItem.refstatusid === ONHOLD_STAGE) {
                    //     StaticActionAlert.actionIds[currItem['machineid']] = {
                    //         statusId: DELETE_STAGE,
                    //         isTrigger: false,
                    //         touched: true,
                    //         text: 'Suspend'
                    //     }
                    // }
                    liteActions = true;
                    isNeedToSetTimer = true;
                    currItem['consoleDisabled'] = 'viewConsoleDisable';
                }

                if (currItem.refstatusid === ONHOLD_STAGE) {
                    StaticActionAlert.actionIds[currItem['machineid']] = {
                        statusId: DELETE_STAGE,
                        isTrigger: false,
                        touched: true,
                        text: 'Suspend'
                    }
                }

                // else if (currItem.machineid === undefined) {
                //     currItem['statusid'] = ONHOLD_STAGE
                // }



                // commented start
                // if(selectAll){
                //     listData[i]['isChecked'] = true;
                // }else{
                //     listData[i]['isChecked'] = false;
                // }
                // commented end
                currItem['isChecked'] = false;
                //dispatch(unCheckAllInstancesAction());
                //listData[i]['isChecked'] = false;
                // if (currItem.machinetype == 2) {
                newArrList.push(currItem);
                // }
            }

            const isUnMounted = StaticInstancesList.isUnMounted;
            const TIMER = 10000;
            let sitePath = window.location.pathname;
            // const TIMER = liteActions ? REFRESH_TIMER : 30000;
            if (isNeedToSetTimer && !isUnMounted || isNeedToSetTimer &&
                (sitePath == '/' || sitePath == '/instances')) {
                if (StaticTimer.TimerNumber != null) {
                    clearTimeout(StaticTimer.TimerNumber)
                    let newTimer = setTimeout(function () {
                        // console.log("running")
                        if (!StaticInstancesList.isUnMounted) {
                            dispatch(getIntancesList());
                        }

                    }, TIMER)
                    StaticTimer.TimerNumber = newTimer;
                } else {
                    let newTimer = setTimeout(function () {
                        // console.log("running noneed")
                        if (!StaticInstancesList.isUnMounted) {
                            dispatch(getIntancesList());
                        }
                    }, TIMER);
                    StaticTimer.TimerNumber = newTimer;
                }
            }



            // Do not remove this
            // for (let i = 0; i < listData.length; i++) {
            //     listData[i]['loadingInfo'] = {
            //         loading: false,
            //         statusId: listData[i].statusid,
            //         text: '',
            //         color: ''
            //     }
            //     // commented start
            //     // if(selectAll){
            //     //     listData[i]['isChecked'] = true;
            //     // }else{
            //     //     listData[i]['isChecked'] = false;
            //     // }
            //      // commented end
            //     listData[i]['isChecked'] = false;
            //     dispatch(unCheckAllInstancesAction());
            //     //listData[i]['isChecked'] = false;
            //     if (listData[i].machinetype == 2) {
            //         newArrList.push(listData[i]);
            //     }
            // }
            //    
           

            dispatch(setInstances({
                data: [...newArrList],
                error: null
            }))
            // dispatch(setInstances({
            //     data: [...newFinalPendingArr, ...newArrList],
            //     error: null
            // }))
            // execPromoisePayment(machineZeroCheck, dispatch);
            dispatch(finishLoadingInstances());





            for (let item in StaticActionAlert.actionIds) {
                let currItem = StaticActionAlert.actionIds[item];
                if (currItem?.touched === false) {
                    if(!isShowAlert){
                        handlerAlert('success', `Your Machine as been Deleted`)
                    }
                    StaticActionAlert.cleanActionIds(item);
                }
                if (currItem?.isTrigger) {
                    toast.dismiss();
                    StaticActionAlert.cleanActionIds(item);
                    let name = '';
                    if(!isShowAlert){
                        handlerAlert('success', `Your Machine as been ${currItem?.text}`)
                    }
                }
            }
        } catch (error) {
            // console.log(error, 'errorerrorerrorerrorerrorerror')
            // if (error.message != "Cannot destructure property 'userId' of 'Object(...)(...)' as it is null.") {
            //     handlerAlert('danger', error.message, 'danger')
            // }

            console.log(error);

            dispatch(setInstances({
                data: [],
                error: error.message
            }))
            dispatch(finishLoadingInstances());
        }
    };
}
let timerInstances = null;
async function callIsMore({ isCreateMode, errorMode, isConfigMode, dispatch, fullData }) {
    // while (isCreateMode.length != 0) {
    //     let item = isCreateMode[0];
    //     isCreateMode.shift();
    //     const { machineid, vmid, branchid, hostname } = item;
    //     const pCheck = await checkMachinePromise(JSON.parse(item.proxmoxtaskid).data, hostname, branchid);

    //     const resCores = await setCoresInstances(machineid);

    //     if (resCores.data.code != 0) {
    //         handlerAlert('danger', resCores.data.message, 'danger');
    //         // dispatch(getIntancesList());
    //         //    dispatch(setInstances({
    //         //     data: fullData,
    //         //     error: null
    //         // }))
    //         //     dispatch(finishLoadingInstances());
    //         continue;

    //     }
    //     const UPIDCores = JSON.parse(JSON.parse(resCores.data.result).ProxmoxTaskId).data;
    //     await checkMachinePromise(UPIDCores, hostname, branchid)
    //     // console.log(resCores, "configggggggggggg")
    //     if (resCores.data.code === '404') {
    //         errorMode.push({
    //             id: machineid,
    //             message: resCores.data.message
    //         });
    //         continue;
    //     }


    //     if (resCores['data']['result'] === '') {
    //         errorMode.push(machineid);
    //         continue;
    //     }

    //     isConfigMode.push(item);

    //     const updateResponse = await updateCurrentMachine(machineid, 6);


    // }
    if (isConfigMode.length === 0 && isCreateMode.length > 0) {
        if (timerInstances != null) {
            clearTimeout(timerInstances);
        }

        timerInstances = setTimeout(function () {
            dispatch(getIntancesList())
        }, 30000);
    }
    let editStart = false;
    while (isConfigMode.length != 0) {
        let item = isConfigMode[0];
        isConfigMode.shift();
        editStart = true;
        const { machineid, vmid, branchid, hostname } = item;
        // const pCheck = await checkMachinePromise(JSON.parse(item.proxmoxtaskid).data, hostname, branchid);
        const url = twoStartURL(vmid, hostname, branchid);



        const res = await BootVm(url, 'POST');
        // const UPIDCores = JSON.parse(res.data.result)
        // await checkMachinePromise(UPIDCores, hostname, branchid)

        if (res.data.code === '404') {
            errorMode.push({
                id: machineid,
                message: res.data.message
            })
            continue;
        }


        if (res['data']['result'] === '') {
            errorMode.push(machineid)
            continue;
        }



        const updateResponse = await updateCurrentMachine(machineid, START_STAGE);

    }



    if (errorMode.length >= 1) {
        let newFullData = JSON.stringify(fullData);
        newFullData = JSON.parse(newFullData);
        for (let i = 0; i < errorMode.length; i++) {
            for (let j = 0; j < newFullData.length; j++) {

                if (errorMode[i].id === newFullData[j].machineid) {
                    newFullData[j]['statusid'] = 404;
                    newFullData[j]['refstatusid'] = 404;
                    handlerAlert("danger", `${newFullData[j].machinename} ${errorMode[i].message}`, "danger")
                }
            }
        }

        dispatch(setInstances({
            data: newFullData,
            error: null
        }))
    }

    if (editStart) {
        dispatch(getIntancesList(null))
    }
    // console.log("hello")




}

export function execPromoisePayment(arr, dispatch) {
    if (arr.length === 0) {
        return;
    }
    const promises = [postCheckPaymentStatusService(arr[0])];
    // arr.forEach(el => {
    //     promises.push(postCheckPaymentStatusService(el));
    // });
    // debugger;
    Promise.all(promises).then(function (res) {
        // debugger;
        // console.log(res)
        dispatch(getIntancesList());
    }).catch(err => console.log(err));
}


export function store_tenureData_Action(payload) {
    return {
        type: TENURE_DATA_STORE,
        payload
    }
}


export function getInstancesListPage(page) {
    return async (dispatch, getState) => {
        try {
            //             const { selectAll } = getState().instances.selectedListItems;
            // // console.log(selectAll,'selectAllsss')
            const response = await getInstancesListService(page, dispatch)
            // let response = await responseCU;
            // console.log(response, 'dasdkljkl')
            const { instancesList: { data } } = getState().instances;
            // '';
            //'';
            if (response.data.result == null && data.length == 0) {
                dispatch(setInstances({
                    data: [],
                    error: null
                }))
                return;
            }
            let result = JSON.parse(response.data.result);
            if (result.ReportJsonData.Result.Data == '' && data.length == 0) {
                dispatch(setInstances({
                    data: [],
                    error: null
                }))
                return;
            }
            let listData
            if (result.ReportJsonData.Result.Data != '') {
                listData = JSON.parse(result.ReportJsonData.Result.Data)

            } else {
                dispatch(DO_NOTHING());
                return;
            }
            let newArrList = [];
            for (let i = 0; i < listData.length; i++) {
                listData[i]['loadingInfo'] = {
                    loading: false,
                    statusId: listData[i].statusid,
                    text: '',
                    color: ''
                }
                // if(selectAll){
                //     listData[i]['isChecked'] = true;
                // }else{
                //     listData[i]['isChecked'] = false;
                // }
                listData[i]['isChecked'] = false;
                dispatch(unCheckAllInstancesAction());
                //listData[i]['isChecked'] = false;
                if (listData[i].machinetype == 2) {
                    newArrList.push(listData[i]);
                }
            }
            dispatch(setInstances({
                data: [...data, ...newArrList],
                error: null
            }))
            //console.table(listData)
        } catch (error) {
            dispatch(setInstances({
                data: [],
                error: error
            }));
            // console.log(error, 'error')
        }
    };
}


