import React from 'react';

import ModalHs from '../../../../../../hireserver/ModalHs';
import { handlerAlert } from '../../../../InstancesListComponents/DropDownInstancesList';

function BackUpRemoveModal({ curSelectItem, showHsModal,getSnapShotList,snapShotCurr ,setRemoveModal}) {

    // const [modalStopInput, setModalStopInput] = React.useState({
    //     text: '',
    //     error: false
    // });

    React.useEffect(function(){
        // console.log("calling snapremoce")
    },[]);


    async function handleRemove(e) {
        const { vmid, hostname, branchid } = curSelectItem;
        // console.log(snapShotCurr)
        setRemoveModal(false);
        try {
            // const res = await instancesSnapShotRemoveService(vmid, hostname, branchid,snapShotCurr)
            // // console.log(res,'removed')
            //getSnapShotList();
        } catch (error) {
            handlerAlert("danger",error.message,"danger");
        }



        // if Input Confirmation Need
        // const { url, method, machineid, num, statusId, machinename,nodeType,itemData:{VMID,HostName}} = curSelectItem;
        // if (machinename != modalStopInput.text) {
        //     setModalStopInput({
        //         text: modalStopInput.text,
        //         error: true
        //     });
        //     return
        // }

        // dispatch(closeHsModal());
        // //dispatch(startLoadingInstaces());
        // dispatch(VM_ActionStarted(curSelectItem));
        // dispatch(VM_ActionCall(url,nodeType, method, machineid, num, statusId, handlerAlert, curSelectItem));
       
        // setModalStopInput({
        //     text: '',
        //     error: false
        // });
    }

    function handleModalClose() {
        // dispatch(closeHsModal());
        setRemoveModal(false);
    }
    return (
        <ModalHs handleClose={handleModalClose} okayHandler={handleRemove} showModalHS={showHsModal} btnTitle="Remove SnapShot" >
            <div className='w-100'>
                <div className='w-100 d-flex'>  <div>Are you sure to Remove  <span className='font-w600'>{snapShotCurr}</span> Snapshot?  </div> </div>
                {/* <input className='form-control mt-2' value={modalStopInput.text} placeholder='Type the machine name to stop the machine' style={{ borderRadius: '4px' }} onInput={(e) => setModalStopInput({
                    text: e.target.value,
                    error: false
                })} />
                {modalStopInput.error && <div className='text-center text-danger mt-2'>The Input is wrong</div>} */}
            </div>
        </ModalHs>
    )
}

export default React.memo(BackUpRemoveModal);


