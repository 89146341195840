import React from "react";
import { Link } from "react-router-dom";
import ImageHs from "../components/hireserver/ImageHs";

import sleepBee from '../../images/hireserver/imgs/bee/fail.png';
import ButtonHS from "../components/hireserver/controls/ButtonHS";
import SpacerHS_TWO from "../components/hireserver/Spaces/SpacerHS_TWO";

const Error400 = () => {
   return (
      <div className="w-100 d-flex justify-content-center align-items-center flex-column">
     
         <ImageHs width="200px" alt="404 Page Not Found" src={sleepBee} />
         <div className="text-center font-80 text-primary   ">
            404 Error.
         </div>
         <div>
            We can't find the page you're looking for
         </div>
         <SpacerHS_TWO/>
         <ButtonHS>
              <Link to="/" className="text-white">
              Go Home</Link>
            </ButtonHS>
      </div>
   );
};

export default Error400;
