import React from 'react'

function PasswordJustEye({value,onChange}) {
  const [typeVal,setTypeVal] = React.useState('password');
  function handleClickToggle(e){
    if(typeVal === 'password'){
        setTypeVal('text');
    }else{
        setTypeVal('password');
    }
}
  return (
    <div className='position-relative '>
        <input autoComplete="new-password" value={value} onChange={onChange} className=' font-14 form-control hs_borderRadius-4  w-100 ' type={typeVal} />
        {
       value!='' &&   <span
          className="position-absolute z_index_9 cursor-pointer d-flex justify-content-center align-items-center"
          style={{
            top: 0,
            bottom: 0,
            right: '10px',
          }}
          onClick={handleClickToggle}
        >
          <i className={`fa passwordiconhs  text-black-50 ${typeVal == 'password' ? 'fa-eye-slash':'fa-eye passwordiconhs text-primary'}`}></i>
        </span>
    }
    </div>
  )
}

export default PasswordJustEye;