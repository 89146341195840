import React from 'react';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { getDocInstancesService, postOrderRetryService } from '../../../../../../../services/Profile/ProfileOrdersServices';
import { putRedirectLinkAction, showPagementGateWayAction } from '../../../../../../../store/actions/RedirectPageActions/RedirectPageAction';
import { checkResData } from '../../../../../../../utils/Helpers';
import { handlerAlert } from '../../../../Instances/InstancesListComponents/DropDownInstancesList';







function RetryBtn({ handleClickRetry, isDisableBtn }) {
    if (isDisableBtn) {
        return <div className='text-center'>
            <button disabled={isDisableBtn} onClick={handleClickRetry} className='bg-danger w-120p retryBtn border-0 btn-sm text-white'>
                <div className="spinner-border text-light" role="status">

                </div>
            </button>

        </div>
    }
    return (
        <div className='text-center'>
            <button disabled={isDisableBtn} onClick={handleClickRetry} className='w-120p bg-danger retryBtn border-0 btn-sm text-white'>Retry</button>
            {/* <div>Payment Failed!</div> */}
        </div>
    )
}

function ProfileOrderStatus({ status, orderid }) {

    
    const [isFailed, setIsFailed] = React.useState(false);
    const [isDisableBtn, setIsDisableBtn] = React.useState(false);
    const dispatch = useDispatch();

    React.useEffect(function () {
        
        // console.log("dassssssssssss")
        if (status != 'SUCCESS') {
            setIsFailed(true);
        }else{
            //getHTMLDoc();
        }
    }, [status]);

    async function getHTMLDoc(){
        const res = await getDocInstancesService();
        // const isValid = checkResData(res);
        // if(!isValid) return;
        if(!res) {
            handlerAlert("danger","Something went wrong",'danger');
            return;
        }; 
        const resData = JSON.parse(res.data.result); 
        const DOC_HTML = resData.PrintHTML;

        let myWindow = window.open("", "MsgWindow", "width="+window.screen.availWidth+",height="+window.screen.availHeight);
        var content = `<html><head><title>${orderid}</title> <style>
        .table-print{
            border:1px solid rgba(0,0,0,0.2)
        }
        .table-print td{
            border:1px solid rgba(0,0,0,0.2)
        }
    </style> </head><body> ${DOC_HTML} </body></html>`;
        myWindow.document.write(content);
    }

    async function handleClickRetry(e) {
        
        setIsDisableBtn(true);
        const res = await postOrderRetryService(orderid);
        // console.log(res)
        let isValid = checkResData(res);
        if (isValid === false) {
            setIsDisableBtn(false);
            return;
        }

        const resJSON = JSON.parse(res.data.result);
        const paymentLink = resJSON.paymentlink;
        dispatch(putRedirectLinkAction(paymentLink));
        dispatch(showPagementGateWayAction());
        setIsDisableBtn(false);
    }

   



    return (
        <div className='text-center d-flex align-items-center justify-content-center'>
            {isFailed && <RetryBtn isDisableBtn={isDisableBtn} handleClickRetry={handleClickRetry} />}
        {/* {!isFailed && <div className='badge text-white bg-primary'>{status}</div>} */}
        {/* {!isFailed && <button className='btn-sm w-120p border-0 hs-signin-btn' onClick={getHTMLDoc}>
                <span className='pr-1'><i className='fa fa-clipboard'></i></span>
                <span>Invoice</span>
            </button>} */}
            {!isFailed && <span class="bgl-success text-success rounded-sm  btn btn-sm w-120p justify-content-around  d-flex align-items-center">{'Success'}</span>}
        </div>
    )
}

// const mapStateToProps = ({ PaymentRedirectPage }) => {
//     return {
//       currDetailsItem: PaymentRedirectPage.currDetailsItem,
//     };
// };

export default connect()(ProfileOrderStatus)