import React from 'react'
import HeadingHS from '../../../../hireserver/controls/HeadingHS'
import CustomTabs from '../../../../hireserver/CustomTabs';
import SelectCard from '../../../../hireserver/SelectCard';
import TabCpu from './InnerComponents/TabCpu';

export default function DatabaseChooseAPlan() {
    const [tabData,setTabData] = React.useState([
        {
            name: 'Dedicated CPU',
            component: <TabCpu/>
        },
        {
            name: 'Shared CPU',
            component: <TabCpu/>
        }
    ]);
   
  return (
    <div>
        <HeadingHS as="h3" title="Choose a Plan" />
        <CustomTabs tabData={tabData}/>
        
    </div>
  )
}
