import React from 'react';
import './SmallTickLoader.css';

function SmallTickLoader() {
  return (
    <div className="main-containerSmallTickLoader">
	<div className="check-containerSmallTickLoader">
		<div className="check-backgroundSmallTickLoader">
			<svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M7 25L27.3077 44L58.5 7" stroke="white" stroke-width="13" stroke-linecap="round" stroke-linejoin="round" />
			</svg>
		</div>
		<div className="check-shadowSmallTickLoader"></div>
	</div>
</div>
  )
}

export default SmallTickLoader