import { applyMiddleware, combineReducers, compose, createStore, } from 'redux';
import thunk from 'redux-thunk';
import AuthAlertReducer from './reducers/AuthAlertReducer';
import { AuthReducer } from './reducers/AuthReducer';
import BareMetalReducer from './reducers/BareMetalReducer';
import DeployReducer from './reducers/DeployReducer';
import FireWallListReducer from './reducers/firewall/FirewallListReducer';
import DetailsInstanceReducer from './reducers/Instances/DetailsInstanceReducer';


import InstancesReducer from './reducers/InstancesReducer';
import NotificationReducer from './reducers/NotificationReducer';
import PaymentInfoReducer from './reducers/PaymentInfo/PaymentInfoReducer';
import PaymentRedirectReducer from './reducers/PaymentRedirect/PaymentRedirectReducer';
import RenewalPaymentReducer from './reducers/RenewalPaymentReducer/RenewalPaymentReducer';
import UserDashboardReducer from './reducers/UserDashboard/UserDashboardReducer';


const middleware = applyMiddleware(thunk);


const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const reducers = combineReducers({
  auth: AuthReducer,
  instances: InstancesReducer,
  bareMetal: BareMetalReducer,
  instanceDetails: DetailsInstanceReducer,
  authAlertReducer: AuthAlertReducer,
  PaymentRedirectPage: PaymentRedirectReducer,
  PaymentInfoReducer: PaymentInfoReducer,
  deployReducer:DeployReducer,
  notificationReducer:NotificationReducer,
  renewalPaymentReducer:RenewalPaymentReducer,
  fireWallListReducer:FireWallListReducer,
  userDashboard:UserDashboardReducer
});

//const store = createStore(rootReducers);

export const store = createStore(reducers, composeEnhancers(middleware));
