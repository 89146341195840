import React from 'react'
import ButtonHS from '../../../hireserver/controls/ButtonHS';
import ChangeInputControl from '../../../hireserver/controls/ChangeInputControl'
import StickyFooter from '../../../hireserver/StickyFooter'
import BareMetalContext from '../BareMetalContext/BareMetalContext';
import { Card } from 'react-bootstrap'
import { connect } from 'react-redux';
import { getCurrencySymbol } from '../../../../../utils/Currency';

function BareMetalFooter({paymentData,currencyname,isShowPayment,userDetails}) {
  const { selectServer, setSelectServer, onChangeLocation, locations, handleFormClick, onChangeBlocksHandler, optionsArr, MINUS_BTN, blocksHandler, PLUS_BTN, blocksInput, setBlocksInput,summaryPrice,deployLoading } = React.useContext(BareMetalContext);

  return (
    <div>

      <Card className='d-lg-none d-block'>
        <Card.Body className='d-flex flex-column displaycreatefooterform'>
          <div className='d-flex  justify-content-between w-100 align-items-center'>
            <div>
              <div>Blocks Qty:</div>
              <ChangeInputControl onChangeBlocksHandler={onChangeBlocksHandler} value={blocksInput} minus={MINUS_BTN} plus={PLUS_BTN} clickHandler={blocksHandler} />              </div>
            <div>
              <div className='pb-2'>Total:</div>
              <div style={{ color: '#37B5AE' }}><span style={{ fontSize: '35px' }}>${blocksInput * 20}</span> <span style={{ fontSize: '13px' }}>/month</span> </div>
            </div>
          </div>
          <div className='text-center py-1'>

            <ButtonHS isDisable={blocksInput <= 0} styles={{ padding: "15px 100px" }} handler={handleFormClick} >
              Deploy
            </ButtonHS>
          </div>
        </Card.Body>
      </Card>
      <StickyFooter>
        <div className='d-flex d-gap-40 align-items-center '>
          <div className='d-flex d-gap-5 align-item-center justify-content-center'>
            <div className='pb-2'>
              Blocks Qty:
            </div>
            <ChangeInputControl onChangeBlocksHandler={onChangeBlocksHandler} value={blocksInput} minus={MINUS_BTN} plus={PLUS_BTN} clickHandler={blocksHandler} />
          </div>
          <div>
            <div className='pb-2'>Total:</div>
            <div style={{ color: '#37B5AE' }}><span style={{ fontSize: '35px' }}>{getCurrencySymbol(paymentData?.currencyname, blocksInput * summaryPrice.price)}</span> <span style={{ fontSize: '13px' }}>/month</span> </div>
          </div>
        </div>

        {/* <ButtonHS isDisable={blocksInput <= 0} styles={{ padding: "15px 100px" }} handler={handleFormClick} >
          Deploy
        </ButtonHS> */}
         {<button
              style={{ padding: "15px 100px" }}
              type="submit"
              className="btn bg-primary text-white hs_borderRadius-4"
              disabled={deployLoading}
            >
              {!deployLoading && 'Deploy'}
              {
                deployLoading && <React.Fragment>
                  <div class="spinner-border" role="status">

                  </div> Deploy
                </React.Fragment>
              }
            </button>}
      </StickyFooter>
    </div>
  )
}

const mapStatesToProps = ({ auth, PaymentInfoReducer }) => {
  return {
    userDetails: auth.auth,
    isShowPayment: PaymentInfoReducer?.paymentData?.defaultaddressid,
    currencyname: PaymentInfoReducer?.paymentData?.currencyname,
    paymentData: PaymentInfoReducer?.paymentData,

  };
};

export default connect(mapStatesToProps)(BareMetalFooter);
