import React from 'react'
import { SEVERNAME } from '../../../../../../../API_ENDPOINTS/API_ENDPOINTS';
import { getDocInstancesService } from '../../../../../../../services/Profile/ProfileOrdersServices';
import { handlerAlert } from '../../../../Instances/InstancesListComponents/DropDownInstancesList';

function ProfileViewInvoice({orderid}) {
    const [downloadurl,setDownloadURl] = React.useState('');
    const [loading,setLoading] = React.useState(false);
    const aTagRef = React.createRef(null);
    React.useEffect(async function (){
      if(aTagRef.current){
        aTagRef?.current?.click();
    setDownloadURl('');
      }
    },[aTagRef]);

    async function handleClick(){
      setLoading(true);
      const res = await getDocInstancesService(orderid);
    
      if(res.data.code != '0'){
        setLoading(false);
        handlerAlert("danger",res.data.message,"danger");
      }
      const jsonRes = JSON.parse(res.data.result)['FilePath'];
      setDownloadURl(`${SEVERNAME}/${jsonRes}`);
     
      setLoading(false);

          
     
      
    }
    

   

  return (
    <div className='text-center'>
       {downloadurl && !loading && <a ref={aTagRef} href={downloadurl} target="_blank" className='cursor-pointer d-none p-1 hs-signin-btn hs_borderRadius-4 font-16 px-2'><i className='fa fa-eye'></i></a>}
       
       {!loading && <span onClick={handleClick} className='cursor-pointer p-1 hs-signin-btn hs_borderRadius-4 font-16 px-2'><i className='fa fa-eye'></i></span>}

       {loading && <React.Fragment>
                      <div className="spinner-border hs-signin-btn text-light" role="status">

                      </div> 
                    </React.Fragment>}
    </div>
  )
}

export default ProfileViewInvoice