import React from 'react'
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { getBlockIdService, getTenureInstanceaService } from '../../../../../../../services/InstancesServices/InstancesFormService';
import { getPaymentWalletAmountService, postPayRenewalService } from '../../../../../../../services/paymentServices/PaymentService';
import { getIntancesList, startLoadingInstaces } from '../../../../../../../store/actions/InstancesActions/InstancesActions';
import { putRedirectLinkAction, showPagementGateWayAction } from '../../../../../../../store/actions/RedirectPageActions/RedirectPageAction';
import { callListRenewalToggle, closeModalRenewalPayment } from '../../../../../../../store/reducers/RenewalPaymentReducer/RenewalPaymentReducer';
import { checkResData, imageReplaceHashHelper, percentageHelper } from '../../../../../../../utils/Helpers';
import CurrencyFC from '../../../../../hireserver/components/Currency/CurrencyFC';
import ImageHs from '../../../../../hireserver/ImageHs';
import ModalHs from '../../../../../hireserver/ModalHs';
import SpacerHS_TWO from '../../../../../hireserver/Spaces/SpacerHS_TWO';
import { handlerAlert } from '../../../InstancesListComponents/DropDownInstancesList';

import deployImage from './../../../../../../../images/hireserver/imgs/bee/deploy.png';


function RenewalPayment({ showHsModal, currDetailsItem, currencyid, callListRenewal, currencyname }) {
  const [walletValue, setWalletValue] = React.useState(null);
  const [walletDetails, setWalletDetails] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [taxState, setTaxState] = React.useState(null);
  const [price, setPrice] = React.useState(0);
  const [spec, setSpecs] = React.useState(null);

  const dispatch = useDispatch();

  React.useEffect(function () {
    getWallet();
  }, []);

  React.useEffect(function () {
    if (currencyid) {
      getTaxes();
    }
  }, [currencyid]);

  React.useEffect(function () {
    if (currDetailsItem && taxState) {
      console.log(currDetailsItem,'currDetailsItemcurrDetailsItemcurrDetailsItemcurrDetailsItem');
      setPrice(currDetailsItem?.totalamount);
    }
  }, [currDetailsItem, taxState]);

  async function getTaxes() {
    const blockIdRes = await getBlockIdService();

    let blockid = JSON.parse(blockIdRes.data.result)[0]
    setSpecs(blockid);
    blockid = blockid['blockid'];
    const newRes = await getTenureInstanceaService(currencyid, blockid);
    if(!newRes||newRes?.data.code != '0'){
      handlerAlert("danger",newRes.data.message,"danger");
      return;
    }
    const taxesData = JSON.parse(JSON.parse(newRes.data.result)[0]?.taxes);
    setTaxState(taxesData);

  }


  function handleModalClose() {
    dispatch(closeModalRenewalPayment());

  }
  async function okayHandler() {

    setLoading(true);
    try {
      const response = await postPayRenewalService(currDetailsItem?.machineid);
  


      if (response.data.result == null) {
        handlerAlert("danger", "Bad Response Create Again", "danger");
        setLoading(false);
        dispatch(closeModalRenewalPayment());
        return;
      }
      let data = JSON.parse(response.data.result);
      //console.log(data,'instainstainstainstainstainstainstainstainsta')
      setLoading(false)

      const { paymentlink } = data;
      if (paymentlink) {
        handleModalClose();

        dispatch(putRedirectLinkAction(paymentlink));
        dispatch(showPagementGateWayAction());

        return;
      }
      if (callListRenewal) {
        dispatch(startLoadingInstaces());
        dispatch(getIntancesList());
        dispatch(callListRenewalToggle());
      }

      handlerAlert("success", 'Renewal was successfull');
      handleModalClose();
    } catch (error) {
  
      setLoading(false);
      console.log(error);
      handlerAlert("danger", "Something went wrong", "danger");
    }
  }

  async function getWallet() {
    let res = await getPaymentWalletAmountService();
    let isValid = checkResData(res, true)
    if (isValid === false) {
      setWalletValue(0);
      // setLoading(false);
      return;
    }

    let resJSON = JSON.parse(res.data.result)[0];
    if (!resJSON) {
      setWalletValue(0);
      // setLoading(false);
      return;
    }
    setWalletValue(resJSON?.walletvalue);
    setWalletDetails(resJSON);
  }
  return (
    <ModalHs size="lg" bodyClasses="p-0 overflow-hidden" isNeed={false} okayHandler={okayHandler} showModalHS={showHsModal} btnTitle="Renewal Machine" >
      <div className='w-100'>
        <div>
          <div className='p-3 px-5 border-bottom text-primary'>
            Renewal Summary
          </div>
          <div className='border-bottom row py-3'>
            <div className="col-7  px-5 d-flex align-items-center d-gap-40 ">

              <ImageHs width="100px" height="100px" src={deployImage} />

              <div className='font-w500'>
                <div className=' m-0'>
                  <span>Machine</span> <span>:</span> <span>{currDetailsItem?.machinename}</span>
                </div>
                <hr className='my-1' />
                <div className='font-12'>
                  {currDetailsItem?.noofblocks * 1} CPU, {currDetailsItem?.noofblocks * parseInt(spec?.ram)} Ram, {currDetailsItem?.noofblocks * parseInt(spec?.storage)} Gb Storage
                </div>
                <hr className='my-1' />
                <div className='d-flex d-gap-10 font-12'>
                  <ImageHs src={imageReplaceHashHelper(currDetailsItem?.icon)} width="15px" />
                  <div>
                    {currDetailsItem?.branchname}
                  </div>
                </div>
                <hr className='my-1' />
                <div className='d-flex d-gap-10 font-12'>
                  <ImageHs src={imageReplaceHashHelper(currDetailsItem?.osiconpath)} width="15px" />
                  <div>
                    {currDetailsItem?.groupname} {currDetailsItem?.operatingsystemname}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2 d-flex flex-column align-items-center">
              <div className='hs_text-light'>Blocks</div>
              <div className='font-w500'>{currDetailsItem?.noofblocks}</div>
            </div>
            <div className="col-3  d-flex flex-column align-items-center">
              <div className='hs_text-light'>
                Tenure
              </div>
              <div className='font-w500'>
                {/* <CurrencyFC name={paymentData?.currencyname} val={price} /> */}
                {currDetailsItem?.tenuretypename}
              </div>
            </div>
          </div>

          <div className='d-flex px-5  flex-column p-3 justify-content-between border-bottom'>

            <div className='d-flex w-100 justify-content-between'>
              <span>Blocks x {currDetailsItem?.noofblocks}</span>
              <span><CurrencyFC name={currencyname} val={currDetailsItem?.totalamount} /></span>
            </div>

            <div className='d-flex  justify-content-between'>
              <span>SGST {taxState?.SGST}%</span>
              <span>  <CurrencyFC name={currencyname} val={percentageHelper(taxState?.SGST, price)} />  </span>

            </div>
            <div className='d-flex  justify-content-between'>
              <span>CGST {taxState?.CGST}%</span>
              <span>  <CurrencyFC name={currencyname} val={percentageHelper(taxState?.CGST, price)} />  </span>

            </div>

            {/* <span>Due Amount
              <span className='font-12'>(Tax Included 18%)</span>\
            </span> 
            <span>
              <CurrencyFC val={currDetailsItem?.dueamount} name={currencyname} />
              </span> */}
          </div>

        </div>

        <div className='p-3 px-5 border-bottom'>
          <div className='d-flex justify-content-between'>


            <span>{Boolean(walletValue) && walletValue < parseInt(price + parseInt(percentageHelper(taxState?.CGST * 2, price))) && 'Sub'}Total</span>
            <span>   <CurrencyFC name={currencyname} val={parseInt(percentageHelper(taxState?.SGST, price))+parseInt(percentageHelper(taxState?.CGST, price))+(currDetailsItem?.totalamount)} /></span>
            {/* <span>   <CurrencyFC name={currencyname} val={Math.abs((parseInt(price + parseInt(percentageHelper(taxState?.SGST, price)) + parseInt(percentageHelper(taxState?.CGST, price)))))} /></span> */}
          </div>

          {Boolean(walletValue) && walletValue < parseInt(price + parseInt(percentageHelper(taxState?.CGST * 2, price))) &&
            <React.Fragment>
              <div className='d-flex justify-content-between'>
                <span className='text-danger'>wallet</span>
                <span className='text-danger'>   <span className='px-1'>-</span><CurrencyFC name={currencyname} val={walletValue} /></span>
              </div>

              <div className='d-flex justify-content-between'>
                <span>Total</span>
                <span className=''>  <CurrencyFC name={currencyname} val={parseInt(percentageHelper(taxState?.SGST, price))+parseInt(percentageHelper(taxState?.CGST, price))+(currDetailsItem?.totalamount)} /></span>
                {/* <span className=''>  <CurrencyFC name={currencyname} val={Math.abs(walletValue - parseInt(price + parseInt(percentageHelper(taxState?.SGST, price)) + parseInt(percentageHelper(taxState?.CGST, price))))} /></span> */}
               
              </div>
            </React.Fragment>
          }
        </div>


        {walletValue < Math.abs((currDetailsItem?.totalamount)+(parseInt(price + parseInt(percentageHelper(taxState?.SGST, price)) + parseInt(percentageHelper(taxState?.CGST, price))))) && <div className='px-5 py-3'>
          <div>Your wallet doesn't have enough money. You will redirect to payment gateway page.</div>
        </div>}
        {walletValue >= Math.abs((currDetailsItem?.totalamount)+(parseInt(price + parseInt(percentageHelper(taxState?.SGST, price)) + parseInt(percentageHelper(taxState?.CGST, price))))) && <div className='px-5 py-3'>
          <div>Your have <span className='text-primary font-w500'><CurrencyFC name={currencyname} val={walletValue} /></span>  You have enough balance in your wallet. By clicking renewal your machine will be renewed.</div>
        </div>}
        <div className='mt-3 px-3 px-5 d-flex justify-content-end d-gap-15'>
          <button onClick={handleModalClose} className='bg-danger border-0 btn-sm hs_borderRadius-4 text-white '>Close</button>
          <button onClick={okayHandler} className='w-150p hs-signin-btn border-0 btn-sm hs_borderRadius-4 text-white'>
            {loading ? <React.Fragment>
              <div className="spinner-border text-light" role="status">

              </div> Renewal
            </React.Fragment> : 'Renewal'}
          </button>
        </div>
      </div>

    </ModalHs>
  )
}

const mapStateToProps = ({ renewalPaymentReducer, instanceDetails, PaymentInfoReducer }) => {
  return {
    showHsModal: renewalPaymentReducer.isShow,
    currDetailsItem: instanceDetails.currDetailsItem,
    currencyname: PaymentInfoReducer.paymentData?.currencyname,
    currencyid: PaymentInfoReducer.paymentData?.currencyid,
    callListRenewal: renewalPaymentReducer.callListRenewal,
  };
};

export default connect(mapStateToProps)(RenewalPayment);