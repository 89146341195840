import React from 'react';
import { FAILED_STAGE, START_STAGE } from '../../../../../utils/Emuns';
import { instancesViewHelper } from './InstancesViewHelper';
import { handlerAlert } from './DropDownInstancesList';

export default function ViewConsoleBtn({ statusid, handleData,disabledClass='' }) {
    const [disable,setDisable] = React.useState(false);
    const [isLoading,setLoading] = React.useState(false);

    React.useEffect(function (){
        if(statusid != START_STAGE ){
            setDisable(true);
        }else{
            setDisable(false);
        }
      
    },[statusid])

    async function onClickViewBtn(e) {
        const {code,hostname,clusterid} = handleData;
        setLoading(true);
        try {
            const res = await instancesViewHelper(code, hostname, clusterid);
            if (res.isError) {
            handlerAlert('danger', res.message, 'danger');
            return;
            }
        } catch (error) {
            handlerAlert("danger","Something went wrong","danger");
        } finally{
            setLoading(false);
        }
    
    
    }

    return (
        <button disabled={disable} type='button' style={{width:'85px'}} onClick={onClickViewBtn} className={`text-center btn-hslineargradient btn-sm px-3 py-1 ${disabledClass}`}>
            {isLoading ? <React.Fragment>
                      <div className="spinner-border text-light" role="status">

                      </div> 
                    </React.Fragment> : 'View'}
        </button>
    )
}

