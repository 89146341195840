import React from 'react'
import { getPastNotifationAllService, getPastNotifationService } from '../../../../../services/Profile/NotifationsService';
import { checkResData } from '../../../../../utils/Helpers';
import ImageHs from '../../../../components/hireserver/ImageHs';
import SpacerHS_ONE from '../../../../components/hireserver/Spaces/SpacerHS_ONE';
import NotificationCard from './NotificationCard';

import bellImg from '../../../../../images/hireserver/imgs/notification.png';
function NotificationPage() {

  const [notices, setNotices] = React.useState([]);
  const [page, setpage] = React.useState(1);
  const [loading,setLoading] = React.useState(false);
  
  const [nomore, setnomore] = React.useState(false);


  React.useEffect(function () {
    getNotics();

    return ()=>{
      setnomore(false);
      setLoading(false);
    }
  }, [page]);

  async function getNotics() {
    setLoading(true);
    const notice = await getPastNotifationAllService(page);
    let isValid = checkResData(notice, true);

    if (!isValid) {
      setnomore(true);
      setLoading(false);
      console.log('isValid false')
      return;
    }

    if (notice.data.code === '0' && notice.data.result === '') {
      setNotices([]);
    } else {
      const data = JSON.parse(notice.data.result);

      if(data === '') {
        setnomore(true);
        setLoading(false);
        return;
      }

      //setNotices(data);
        setNotices((prev) => {

          return [...prev,...data]
        })
      }

      setLoading(false);
  }

  function moreClick(){
    setpage((prev) => prev+1);
  }

  return (
    <div className='d-flex flex-column'>

      <div className='row d-gap-10 flex-wrap'>
        {notices.length === 0 && <div className='d-flex w-100 h-100  align-items-center justify-content-center flex-column'>
          <div className='d-flex flex-column align-items-center justify-content-center'>
            <ImageHs width="200px" height="200px" alt="notification are zero" src={bellImg} />
            <SpacerHS_ONE />
            <div>You have 0 notifications currently</div>
          </div>



        </div>}
        {notices.length != 0 && notices.map(function (item, i) {
          return <div className='col-12 col-md-6 col-lg-4'>
            <NotificationCard isNeed={false} item={item} />
          </div>
        })}



      </div>
      {!nomore ? <React.Fragment>
        <div className='w-100 text-center mt-2'>
      <button onClick={moreClick} className='btn hs-signin-btn border-radius-4 px-4 py-1 w-100p'>
        
        {loading ? <React.Fragment>
          <div className="spinner-border text-light" role="status">

</div> 
                    </React.Fragment> : 'More'}
      </button>
      </div>
      </React.Fragment>:null}
    </div>

  )
}

export default NotificationPage