import { getSearchByScriptLogsNameURL } from "../../API_ENDPOINTS/API_ENDPOINTS";
import { API_SERVICES } from "../API_SERVICES";
import { getSessionAndUserId } from "../InstancesServices/InstancesListService";

export function getSearchByScriptLogsNameService(searchbyname,page = 1,scriptid){
    let  { userId, sessionId } = getSessionAndUserId();
 
    let config = {
        method: 'get',
        url:getSearchByScriptLogsNameURL(searchbyname,page = 1,scriptid),
        headers: {
            'Authorization': `Basic ${sessionId}`,
        },
 
    };

    return API_SERVICES(config);
}

// export function getSearchByTableService(apiFun,searchBy,...rest){
//     let  { userId, sessionId } = getSessionAndUserId();
 
//     let config = {
//         method: 'get',
//         url:apiFun(searchBy,...rest),
//         headers: {
//             'Authorization': `Basic ${sessionId}`,
//         },
 
//     };

//     return API_SERVICES(config);
// }