import React from 'react'
import { useDispatch } from 'react-redux';
import { setDetailInstances } from '../../../../../store/actions/InstancesActions/InstanceDetailsActions';
import { callListRenewalToggle, openModalRenewalPayment } from '../../../../../store/reducers/RenewalPaymentReducer/RenewalPaymentReducer';

function RenewalBtn({fullData}) {
    const dispatch = useDispatch();
    function onClickRenwal(){
        debugger;
        dispatch(callListRenewalToggle())
        dispatch(setDetailInstances(fullData))
        dispatch(openModalRenewalPayment());
    }
  return (
    <button onClick={onClickRenwal} className="btn-hslineargradient btn-renwal btn-sm px-3 py-1">Renew</button>
  )
}

export default RenewalBtn;