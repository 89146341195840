import { cloneDeep } from 'lodash';
import React from 'react'
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { BootVm } from '../../../../../../services/InstancesServices/InstanceCurrListNewDropdownService';
import { getInstanceDetailsService } from '../../../../../../services/InstancesServices/InstancesListService';
import { closeAllModal, update_all_status_start, VM_ActionCall, VM_ActionStarted, VM_CALL_ACTION_STOP_ALL } from '../../../../../../store/actions/InstancesActions/InstanceCurrItemAction';
import { setDetailInstances } from '../../../../../../store/actions/InstancesActions/InstanceDetailsActions';
import { closeHsModal, getIntancesList, startLoadingInstaces } from '../../../../../../store/actions/InstancesActions/InstancesActions';
import { STOP_STAGE } from '../../../../../../utils/Emuns';
import { checkResData } from '../../../../../../utils/Helpers';
import StaticActionAlert from '../../../../../../utils/StaticsClasses.js/StaticActionAlert';
import ModalHs from '../../../../hireserver/ModalHs'
import { handlerAlert } from '../../InstancesListComponents/DropDownInstancesList';
import { preformDetailsAction } from './OverActionHelper';

function CloseInstancesModal({ curSelectItem, showHsModal }) {
    
    const dispatch = useDispatch();
    const [modalStopInput, setModalStopInput] = React.useState({
        text: '',
        error: false
    });



    async function handlerStop(e) {
        const { url, method, machineid, num, statusId, machinename,nodeType} = curSelectItem;
        // if (machinename != modalStopInput.text) {
        //     setModalStopInput({
        //         text: modalStopInput.text,
        //         error: true
        //     });
        //     return
        // }

        let sitePath = window.location.href;
        StaticActionAlert.actionIds[machineid] = {
            statusId:STOP_STAGE,
            isTrigger:false,
            text:'stopped',
            touched:false
        };
        dispatch(closeHsModal());
        
        if(sitePath.includes('vmdetails/overview')){
            let newCloneItem = cloneDeep(curSelectItem);
            newCloneItem['item']['refstatusid'] = 5;
            newCloneItem['item']['statusid'] = 5;
            newCloneItem['item']['consoleDisabled'] = 'viewConsoleDisable';
           
            dispatch(setDetailInstances(newCloneItem.item))
     
         
            let vmCode = sitePath.split('instances/')[1].split('/vmdetails')[0];
            
            const resAns = await preformDetailsAction(url,method,vmCode,dispatch);
            
            if(resAns.error){
                handlerAlert('danger',resAns.data.data.message,'danger');
                return;
              }
         
            return;
           
        }
       
        //dispatch(startLoadingInstaces());
        // console.log(curSelectItem,'curSelectItemcurSelectItemcurSelectItem')
       
        // console.log(StaticActionAlert.actionIds)
        dispatch(VM_ActionStarted(curSelectItem));
        //dispatch(getIntancesList());
        dispatch(VM_ActionCall(url,nodeType, method, machineid, num, statusId, handlerAlert, curSelectItem));
       
        setModalStopInput({
            text: '',
            error: false
        });
    }

    function handleModalClose() {
        setModalStopInput({
            text: '',
            error: false
        })
        dispatch(closeHsModal());
    }
    return (
        <ModalHs handleClose={handleModalClose} okayHandler={handlerStop} showModalHS={showHsModal} btnTitle="Stop Machine" >
            <div className='w-100'>
                <div className='w-100 d-flex'>  <div>Are you sure to stop this <span className='font-w600'>{curSelectItem.machinename}</span> Machine?  </div> </div>
                {/* <input className='form-control mt-2' value={modalStopInput.text} placeholder='Type the machine name to stop the machine' style={{ borderRadius: '4px' }} onInput={(e) => setModalStopInput({
                    text: e.target.value,
                    error: false
                })} />
                {modalStopInput.error && <div className='text-center text-danger mt-2'>The Input is wrong</div>} */}
            </div>
        </ModalHs>
    )
}

const mapStateToProps = (state) => {
    return {
        showHsModal: state.instances.closeInstanceModal,
        curSelectItem: state.instances.curSelectItem,
    };
};

export default connect(mapStateToProps)(CloseInstancesModal);

