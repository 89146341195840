import React from 'react'
import MultiSelectTag from '../../../../../hireserver/controls/MultiSelectTag/MultiSelectTag';

function ScriptMachineAuto({changeHandler,values,options,isDisabledOptions=false}) {
    function changeHandlerFun(selectedOption){
   
        changeHandler(selectedOption);
        // console.log(selectedOption);
    }

    
  return (
    <React.Fragment>
      <MultiSelectTag isMulti={false} noOptions="You have zero machines. " values={values} changeHandler={changeHandlerFun} options={options} isDisabledOptions={isDisabledOptions}  />
      {/* {options?.length === 0 && <div className='font-12 pt-1'>
          You have no available machines to selected. It may because you have assign the machines to someother firewall groups or You have zero machines.
        </div>} */}
    </React.Fragment>
  )
}

export default ScriptMachineAuto