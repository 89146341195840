import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ButtonHS from '../../../../hireserver/controls/ButtonHS';

function TemplateCard({item}) {
    const {scriptid,description,name,createddate} = item;
  return (
    
    <div className='col-sm-6 col-lg-3 col-xxl-3 p-1'>
      <Link  className="text-primary scriptcardtemplate" to={`/myscripts/scripts/template/${scriptid}/${name}/scripts`}>
        <div className='px-3 py-2 hs-boxshadow-1 card m-0 h-100 border-radius-4'>
        <div className='d-flex justify-content-between'>
         <div>
         <u>{name}</u>
         </div>
         <div className='text-dark'>
                {createddate}
         </div>
        </div>
        <div className='text-truncate text-dark' title={description}>{description}</div>
       {/* <div className='d-flex justify-content-end'>
       <ButtonHS classes="px-1 py-1 font-12">
          View
        </ButtonHS>
       </div> */}
        </div>
        </Link>
    </div>
    
  )
}

export default TemplateCard