
//  Create = 1, Configure, PreStart, Start, PreStop, Stop, Reboot, Reinstall, PreDelete, Delete, Failed, OnHold



export const CREATE_STAGE = 1;
export const CONFIGURE_STAGE = 2;
export const PRESTART_STAGE = 3;
export const START_STAGE = 4;
export const PRESTOP_STAGE = 5;
export const STOP_STAGE = 6;
export const REBOOT_STAGE = 7;
export const REINSTALL_STAGE = 8;
export const PREDELETE_STAGE = 9;
export const DELETE_STAGE = 10;
export const FAILED_STAGE = 11;
export const ONHOLD_STAGE = 12;


export const REFRESH_TIMER = 10000;

export const GB_TO_TB = 1000000000;
export const MB_TO_GB = 1000000;

export const TABLE_ENTIRES = 10;

export const paginationLabel=['‹','›'];

export const PAGE_INDEX_LIMIT = 5;

export const CRON_ENABLE = "_cronstatus";
export const CRON_TIME = '_crontime';

export const testdasdas = "tetst"