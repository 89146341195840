import React from 'react'
import { connect, useDispatch } from 'react-redux';
import { openPaymentInfoFormAction } from '../../../store/actions/PaymentInfoActions/PaymentInfoAction';
import { addPaymentHomeDashboard, openDashboardModal } from '../../../store/actions/UserDashboardActions/UserDashboardActions';
import { blockInvalidChar } from '../../../utils/Helpers';
import CurrencyFC from '../hireserver/components/Currency/CurrencyFC';
import JustCurrencyFC from '../hireserver/components/Currency/JustCurrencyFC';
import InputErrorBackground from '../hireserver/components/InputErrorBackground';
import styles from './Totalbalance.module.css';

function Totalbalance({total,currencyname,isShowPayment,dashboardDetails}) {
  const [money,setMoney] = React.useState(0);
  const [errorShow,setErrorShow] = React.useState({
    isError:false,
    message:''
  })
  const dispatch = useDispatch();
  function moneyHandlerInput(e){
    const val = e.target.value;
    setMoney(val);
  }

  React.useEffect(function (){
    return () => {
      setErrorShow({
        isError:false,
        message:``
      })
    }
  },[]);


  async function handlerAddAmountToWalletSubmit() {
debugger;
    if(isShowPayment == 0){
      dispatch(openPaymentInfoFormAction());
      return; 
    }



   if(!money){
   
      setErrorShow({
        isError:true,
        message:`Please provide mimum 100 ${currencyname}`
      })
      setTimeout(()=>{
        setErrorShow({
          isError:false,
          message:``
        })
      },3000)
      return;
   }

    if(Number(money)<100 ){
     
      setErrorShow({
        isError:true,
        message:`Please provide minimum 100 ${currencyname}`
      })
      setTimeout(()=>{
        setErrorShow({
          isError:false,
          message:``
        })
      },3000)
      return;
    

    }

    dispatch(openDashboardModal());
    dispatch(addPaymentHomeDashboard(money))
    }

  return (
    <div className='hs-boxshadow hs_borderRadius-4 px-4'>
      <div className='font-21 py-4 border-bottom text-primary'>
      Your Wallet
      </div>
      <div className='py-4 border-bottom'>
      {/* <div className='d-flex justify-content-between py-2'>
              <span>Total Overdue</span>
              <span className='text-danger'>
                <CurrencyFC val={dashboardDetails?.dueamount} name={currencyname} />
              </span>
            </div> */}
          <div className='h1 m-0 p-0'>
           
          <CurrencyFC val={total} name={currencyname} />
          
          </div>
          <div className='text-black-50 font-w500'>Your Current Balance</div>
            
            {/* <div  className='font-10'>
              <span>You have </span>   <span className='text-danger'> <CurrencyFC val={dashboardDetails?.dueamount} name={currencyname} /></span> overdue. We recommend to recharge your Wallet.
            </div> */}
      </div>
      <div className='py-4'>
        <div>Add money to wallet</div>
        <div className='font-10'>
              <span className='text-black-50 font-w500'>We recommend to recharge your Wallet with  </span>
             <span className='text-danger'> <CurrencyFC val={dashboardDetails?.dueamount} name={currencyname} /></span>
            </div>
       <div className='py-4 position-relative'>
       <span className="align-items-center pl-2 position-absolute pt-2">
                  <JustCurrencyFC name={currencyname} />
                  {/* {justGetCurrencySymbol(paymentData?.currencyname)} */}
                </span>
       <input  onKeyDown={blockInvalidChar} onChange={moneyHandlerInput} type="number" className="form-control hs_borderRadius-4 pl-4" />
       </div>
      {
        errorShow.isError ?  <div>
        <InputErrorBackground message={errorShow.message} />
       </div> : null
      }
        <button onClick={handlerAddAmountToWalletSubmit} className={`btn w-100 hs-boxshadow hs-signin-btn hs_borderRadius-4 ${styles.btnAnimate}`}>
          <span>Proceed</span>
        </button>
      </div>
    </div>
  )
}

const mapStatesToProps = ({  PaymentInfoReducer, userDashboard}) => {
    return {
      currencyname: PaymentInfoReducer?.paymentData?.currencyname,
      isShowPayment:PaymentInfoReducer?.paymentData?.defaultaddressid,
      dashboardDetails:userDashboard.dashboardDetails,
    }
  };

  
export default connect(mapStatesToProps)(Totalbalance);
{/* <CurrencyFC val={total} name={currencyname} /> */}