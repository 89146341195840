import React from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from 'react-redux';
import MachineFirewallCard from './MachineFirewallCard';

function FirewallCardView({firewallList,setShowFirewallModal}) {
    const [dataView, setDataView] = React.useState([]);
    const [searchInput, setSearchInput] = React.useState("");
    const [pagenation, setPagenation] = React.useState(1);

  
  
    React.useEffect(
      function () {
        setDataView(firewallList);
  
        // // console.log(firewallList, "firewallListlll");
      },
      [firewallList]
    );
  
    function handleDebounceFn(inputValue) {
      if (inputValue == "") {
        setDataView(firewallList);
        return;
      }
      let filterSearchRes = [];
      for (let i = 0; i < firewallList.length; i++) {
  
        if (firewallList[i].groupname.includes(inputValue)) {
          filterSearchRes.push(firewallList[i]);
        }
      }
  
      //// console.log(filterSearchRes)
      setDataView(filterSearchRes);
    }
  
    function onInputHandler(e) {
      let machineSearchInput = e.target.value;
      setSearchInput(machineSearchInput);
      handleDebounceFn(e.target.value);
    }
  
    function loadMoreList() {
      // let pageNumber = pagenation + 1;
      // dispatch(getfirewallListPage(pageNumber));
      // setPagenation(pageNumber)
  
    }
  
    return (
      <React.Fragment>
        <div className="px-3">
          <div className="position-relative">
            <input
              placeholder="Search Machine"
              onInput={onInputHandler}
              value={searchInput}
              type="text"
              className="form-control searchComponentCustomInput"
            />
            <div
              className="position-absolute"
              style={{ top: "20%", right: "15px" }}
            >
              <span>
                <i className="fa fa-search hs_secondaryColor"></i>
              </span>
            </div>
          </div>
          <div className="d-none">
            <div>
              <span>
                <i className="fa fa-search"></i>
              </span>
            </div>
            <div>Sort By</div>
            <div>Name</div>
            <div>
              <div>
                <span>
                  <i className="fa fa-search"></i>
                </span>
                <span>Filters</span>
              </div>
            </div>
          </div>
          {/* <div className="row">
          {dataView.map(function (item, i) {
            return <MachineInstanceCard key={i * 99} item={item} />;
          })}
        </div> */}
          <InfiniteScroll inverse={false} dataLength={dataView.length} hasMore={true} next={loadMoreList} className="row">
            {dataView.map(function (item, i) {
           
              return <MachineFirewallCard key={item?.firewallgroupid} setShowFirewallModal={setShowFirewallModal} item={item} />;
            })}
          </InfiniteScroll>
        </div>
  
      
  
      </React.Fragment>
    );
}


const mapStateToProps = (state) => {
    return {
        firewallList: state.fireWallListReducer.firewallList,
     
      curSelectItem: state.instances.curSelectItem,
      
    };
  };
  
export default connect(mapStateToProps)(FirewallCardView);
  


  
