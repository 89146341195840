import React, { createContext, useEffect, useState } from "react";

export const ThemeContext = createContext();
const ThemeContextProvider = (props) => {
   const body = document.querySelector("body");
    const [windowWidth, setWindowWidth] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);
    const [colorTheme,setColorTheme] = React.useState('light');

    function toggleThemeSet(){
   
      if(colorTheme === 'light'){
        setColorTheme("dark");
        localStorage.setItem('theme',JSON.stringify('dark'));
      }else{
        setColorTheme("light");
        localStorage.setItem('theme',JSON.stringify('light'));
      }
    }

    function logoutHandlerTheme(){
      
        setColorTheme("light");
        localStorage.setItem('theme',JSON.stringify('light'));
       
    }

    React.useEffect(function (){
      let theme = localStorage.getItem('theme');
      if(theme == '' || theme == null) return
    
      theme = JSON.parse(theme);
      if((theme === 'light' || theme === 'dark')){
        setColorTheme(theme)
        return;
      }else{
        setColorTheme('light')
      }
      
    },[]);

    React.useEffect(function (){
    
    
      body.setAttribute("data-theme-version", colorTheme);
    },[colorTheme])
	
    useEffect(() => {
		const body = document.querySelector("body");
		let resizeWindow = () => {
			setWindowWidth(window.innerWidth);
			setWindowHeight(window.innerHeight);
  
			// window.innerWidth >= 768 && window.innerWidth < 1198
			// ? body.setAttribute("data-sidebar-style", "overlay")
			// : window.innerWidth <= 768
			// ? body.setAttribute("data-sidebar-style", "overlay")
			// : body.setAttribute("data-sidebar-style", "full");
      if(window.innerWidth >= 768 && window.innerWidth < 1195){
        body.setAttribute("data-sidebar-style", "overlay")
      }else if(window.innerWidth <= 768){
        body.setAttribute("data-sidebar-style", "overlay")
      }else{
        body.setAttribute("data-sidebar-style", "full");
      }
		};
        body.setAttribute("data-typography", "Poppins");
      //   body.setAttribute("data-theme-version", "light");
      //  body.setAttribute("data-theme-version", "dark");
      body.setAttribute("data-theme-version", colorTheme);
        body.setAttribute("data-layout", "vertical");
        body.setAttribute("data-nav-headerbg", "color_hs1");
        body.setAttribute("data-headerbg", "color_1");
        body.setAttribute("data-sidebar-style", "overlay");
        body.setAttribute("data-sibebarbg", "color_hs1");
        body.setAttribute("data-primary", "color_1");
        body.setAttribute("data-sidebar-position", "fixed");
        body.setAttribute("data-header-position", "fixed");
        body.setAttribute("data-container", "wide");
        body.setAttribute("direction", "ltr");
        resizeWindow();

        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);
    return (
    <ThemeContext.Provider
      value={{
        body,
		windowWidth,
		windowHeight,
    toggleThemeSet,
    logoutHandlerTheme,
    toggleTheme:colorTheme
	}}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
