import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import { USER_COOKIE } from '../../store/types/AuthTypes/AuthTypes';


class CookieHelper {
    static #secretKey = "uuisagdasdaqddas";

    static getCookie() {
        const encryptedCookie = Cookies.get(USER_COOKIE);
        const bytes = CryptoJS.AES.decrypt(encryptedCookie, CookieHelper.#secretKey);
        const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
        // Parse the decrypted string back into an object
        return JSON.parse(decryptedValue);
    }

    static setCookie(data) {
        // Stringify the object data
        const stringData = JSON.stringify(data);
        const valueToStore = CryptoJS.AES.encrypt(stringData, CookieHelper.#secretKey).toString();
        Cookies.set(USER_COOKIE, valueToStore);
    }
}

export default CookieHelper;