import React from "react";
import { useDispatch } from "react-redux";
import { getBoundTypesService, getUserAllMachinesFirewallService } from "../../../../../../../services/FirewallServices/FirewallListService";
import { checkResData, isJsonStringHelper } from "../../../../../../../utils/Helpers";
import { handlerAlert } from "../../../../Instances/InstancesListComponents/DropDownInstancesList";
import FirewallContext from "./FirewallContext";
import uuid from 'react-uuid';
import {saveGroupRulesService } from "../../../../../../../services/FirewallServices/FirewallFormService";
import { getBoundRulesService, getFireWallGroupsService } from "../../../../../../../services/FirewallServices/FirewallOverviewService";
import StaticFireWall from "../../../../../../../utils/StaticsClasses.js/StaticFirewall";
import { withRouter } from "react-router-dom";
import { cloneDeep } from "lodash";
import * as validator  from 'is-my-ip-valid';
import { getFirewallListAction } from "../../../../../../../store/actions/FirewallActions/FirewallAction";


function CreateFireWallContext({ children, history }) {
    const dispatch = useDispatch();

    const [securityGroupName, setSecurityGroupName] = React.useState("");
    const [firesDescription, setFireDescription] = React.useState("");
    const [selectMachineTag, setSelectMachineTag] = React.useState([

    ]);
    const [machinesTagSelect, setMachinesTagSelect] = React.useState([]);
    const securityGroupNameRef = React.createRef();
    const firesDescriptionRef = React.createRef();
    const selectMachineTagRef = React.createRef();

    const [inBoundLoading,setInBoundLoading] = React.useState(false);
    const [boundDetailsLoading,setBoundDetailsLoading] = React.useState(false);


    const [submitLoading,setSubmitLoading] = React.useState(false);

    const [boundTypes, setBoundTypes] = React.useState([]);

    const [typesHelpers, setTypesHelpers] = React.useState({});

    const [inboundData, setinboundData] = React.useState({});
    const [outboundData, setOutboundData] = React.useState({});

    const [firewallformid, setFirewallFormid] = React.useState(0);
    const [deleteRules, setDeleteRules] = React.useState([]);
    const [deleteMachines, setDeleteMachines] = React.useState([]);

    const [firewallErrors,setFirewallErrors] = React.useState({
        firewallGroupName:{
            isError:false,
            message:''
        },
        description:{
            isError:false,
            message:''
        },
        inboundErr:{
            isError:false,
            message:''
        },
        outboundErr:{
            isError:false,
            message:''
        },
    })

    function applyErrorFirewall(name,message){
         
        if(name ===  'firewallname'){
            setFirewallErrors(prev => {
                return {...prev,firewallGroupName:{
                    isError:true,
                    message
                }}
            });
        }else if(name === 'description'){
            setFirewallErrors(prev => {
                return {...prev,description:{
                    isError:true,
                    message
                }}
            });
        }else if(name === 'inbound'){
            setFirewallErrors(prev => {
                return {...prev,inboundErr:{
                    isError:true,
                    message
                }}
            });
        }else if(name === 'outbound'){
            setFirewallErrors(prev => {
                return {...prev,outboundErr:{
                    isError:true,
                    message
                }}
            });
        }
    }

    // overview state start

    // const [overviewGroupName,setOverviewGroupName] = React.useState('');
    // const [overviewDescription,setoverviewDescription] = React.useState('');


    // overview state end



    const [inBoundDataFull, setInBoundDataFull] = React.useState([]);


    function handleFireDescription(e) {
        if(e == '') {
            setFireDescription('')
            return;
        };
        let val = e?.target?.value;
        
        setFireDescription(val)
        setFirewallErrors((prev)=>{
            return {...prev,description:{
                isError:false,
                message:""
            }}
        })
    }

    async function getRulesDataFun(fireId) {
        setInBoundLoading(true);
         ;
        const ress = await getBoundTypesService();
        const isValidd = checkResData(ress);
        if (!isValidd) {
           // alert("something went wrong");
            console.log(ress);
            return;
        }
        const isSafer = isJsonStringHelper(ress.data.result);
        if (!isSafer) {
            handlerAlert("danger", "Something Went Wrong", "danger");
            setInBoundLoading(false);
            return;
        }
        const resJson = JSON.parse(ress.data.result);
        // setBoundTypes({
        //     fullData:resJson,
        //     formatedData:
        // });
        // console.log(resJson);
        const helpObjTypes = {};
        for (let i = 0; i < resJson.length; i++) {
            const currItem = resJson[i];
            helpObjTypes[currItem?.ruletype] = {
                port: currItem['port'],
                ruletype: currItem['protocol'],
                value: currItem['ruletypeid']
            }
        }

        const res = await getBoundRulesService(fireId);
        const isValid = checkResData(res);

        if (!isValid) {
            console.log(res)
            setInBoundLoading(false);
            return;
        }

        const isSafe = isJsonStringHelper(res.data.result);
        if (!isSafe) {
            console.log(res);
            setInBoundLoading(false);
            return;
        }
        const dataRes = JSON.parse(res.data.result);
        // console.log(dataRes,'dataResdataResdataRes')
        let inBoundArr = {};
        let outBoundArr = {};

        for (let i = 0; i < dataRes.length; i++) {
            const currItem = dataRes[i];
            let newId = uuid();

            if (currItem['ruletype'] === 1) {
                inBoundArr[newId] = {
                    id: newId,
                    type: {},
                    typesHelpers: helpObjTypes,
                    firewallgroupid: currItem?.firewallgroupid,
                    firewallruleid: currItem?.firewallruleid,
                    ruletype: currItem?.ruletype,
                    src: currItem?.src,
                    dst: 0,
                    srcport: currItem?.destport,
                    destport:currItem?.srcport,
                    // srcport: currItem?.srcport,
                    // destport:currItem?.destport,
                    description: currItem?.description,
                    ruletypeid:currItem?.ruletypeid,
                    protocol:currItem?.portname,
                    protocolType:currItem?.protocol

                }

                
            } else {
                outBoundArr[newId] = {
                    id: newId,
                    type: {},
                    typesHelpers: helpObjTypes,
                    firewallgroupid: currItem?.firewallgroupid,
                    firewallruleid: currItem?.firewallruleid,
                    ruletype: currItem?.ruletype,
                    src: currItem?.src,
                    dst: currItem?.dst,
                    srcport: currItem?.destport,
                    destport: currItem?.srcport,
                    description: currItem?.description,
                    ruletypeid:currItem?.ruletypeid,
                    protocol:currItem?.portname,
                    protocolType:currItem?.protocol
                }

                // description: "sd",
                // destport: "",
                // dst: "",
                // errors:,
                // firewallgroupid: 0,
                // firewallruleid: 0,
                // id: "98a12107-2c89-c740-a,078-4e87259550cd",
                // protocol: "HTTP",
                // protocolType: "TCP",
                // ruletype: 1,
                // ruletypeid: 100015480000002,
                // src: "any",
                // srcport: "80",
                // type: {},
                // typesHelpers:g,
            }
        }



        setOutboundData(outBoundArr);
        setinboundData(inBoundArr);
        setInBoundLoading(false);

    }


    async function getFirewallGroups(firewallId) {
      //  boundDetailsLoading,setBoundDetailsLoading
      setBoundDetailsLoading(true);
        const res = await getFireWallGroupsService(firewallId);

        if (!checkResData(res)) {
            setBoundDetailsLoading(false);
            return;
        }

        const resData = JSON.parse(res.data.result)[0];
        // console.log(resData);
        setFireDescription(resData?.description);
        setSecurityGroupName(resData?.groupname);
        setFirewallFormid(resData?.firewallgroupid)
        setBoundDetailsLoading(false);
    }



    async function getUserSelectFirewallIdMachines(firewallId) {
        const res = await getUserAllMachinesFirewallService();
        const isValid = checkResData(res);
        if (!isValid) {
            //alert("something went wrong");
            console.log(res);
            return;
        }

        const isSafe = isJsonStringHelper(res.data.result);
        if (!isSafe) {
            handlerAlert("danger", "Something Went Wrong", "danger");
            return;
        }

        const resJson = JSON.parse(res.data.result);
         console.log(resJson, 'daskdhjkresJsonresJsonresJson');
        let newArr = [];
        let allArr = [];
        for (let i = 0; i < resJson.length; i++) {
            if (resJson[i]?.firewallgroupid == firewallId) {
                let val = {
                    value: resJson[i]?.machineid,
                    label: resJson[i]?.machinename
                }

                newArr.push(val);
                allArr.push(val);
            }else if(resJson[i]?.firewallgroupid == 0){
                let val = {
                    value: resJson[i]?.machineid,
                    label: resJson[i]?.machinename
                }

                allArr.push(val);
            }

        }
debugger;
        setSelectMachineTag(allArr);
        setMachinesTagSelect(newArr);
    }


    async function fetchBoundTypes() {
         ;
        const res = await getBoundTypesService();
        const isValid = checkResData(res);
        if (!isValid) {
            //alert("something went wrong");
            console.log(res);
            return;
        }
        const isSafe = isJsonStringHelper(res.data.result);
        if (!isSafe) {
            handlerAlert("danger", "Something Went Wrong", "danger");
            return;
        }
        const resJson = JSON.parse(res.data.result);
        // setBoundTypes({
        //     fullData:resJson,
        //     formatedData:
        // });
        // console.log(resJson);
        const helpObjTypes = {};
        for (let i = 0; i < resJson.length; i++) {
            const currItem = resJson[i];
            helpObjTypes[currItem?.ruletype] = {
                port: currItem['port'],
                ruletype: currItem['protocol'],
                value: currItem['ruletypeid']
            }
        }

        setTypesHelpers(helpObjTypes);
         ;
        // const justID =  helpObjTypes[resJson[0]].ruletypeid;
        let newObj = {};
        let newId = uuid();
        newObj[newId] = {
            id: newId,
            type: {},
            typesHelpers: helpObjTypes,
            firewallgroupid: 0,
            firewallruleid: 0,
            ruletypeid: helpObjTypes['All Traffic']?.value,
            ruletype: 2,
            src: '',
            dst: 'any',
            srcport: '',
            destport:  helpObjTypes['All Traffic']?.port,
            description: '',
            protocolType:helpObjTypes['Custom TCP']?.ruletype,
            protocol:'All Traffic'
        }
        setOutboundData(newObj)

        let newObjTwo = {};
        let newIdTwo = uuid();
        newObjTwo[newIdTwo] = {
            id: newIdTwo,
            type: {},
            typesHelpers: helpObjTypes,
            firewallgroupid: 0,
            firewallruleid: 0,
            ruletypeid: helpObjTypes['Custom TCP']?.value,
            ruletype: 1,
            src: '',
            dst: '',
            srcport: '',
            destport: '',
            description: '',
             protocolType:helpObjTypes['Custom TCP']?.ruletype,
             protocol:'Custom TCP'
        }
        setinboundData({})



    }


    function deleteInBoundItem(id) {
        let newObj = {};
        let deleteItem = '';
        for (let item in inboundData) {
            if (item != id) {
                newObj[item] = inboundData[item]
            } else {
                deleteItem = inboundData[item].firewallruleid
            }
        }
        setDeleteRules((prev) => {
            return [...prev, Number(deleteItem)];
        })
        setinboundData(newObj);
    }
    function deleteOutBoundItem(id) {
         ;
        let newObj = {};
        let newCopyData = cloneDeep(outboundData);
        let deleteItem = '';
        for (let item in newCopyData) {
            if (item == id) {
                deleteItem = newCopyData[item].firewallruleid
              
            } else {
                newObj[item] = newCopyData[item]
            }
        }
        setDeleteRules((prev) => {
            return [...prev, Number(deleteItem)];
        })


        setOutboundData(newObj);
    }

    function addRuleInBoundHandler() {
        let newObj = { ...inboundData };
        let newId = uuid();
        newObj[newId] = {
            id: newId,
            type: {},
            typesHelpers: typesHelpers,
            firewallgroupid: 0,
            firewallruleid: 0,
            ruletypeid: typesHelpers['Custom TCP']?.value,
            ruletype: 1,
            src: '',
            dst: '',
            srcport: '',
            destport: '',
            description: '',
             protocolType:typesHelpers['Custom TCP']?.ruletype,
             protocol:'Custom TCP'
        }


        setinboundData(newObj);
    }

    function reSetValuesFirewallForm() {
        StaticFireWall.firewallEdit = false;
        setSelectMachineTag([]);
        setFireDescription('');
        setSecurityGroupName('');
        setinboundData({});
        setOutboundData({});
        setFirewallFormid(0);
        setDeleteRules([]);
        setDeleteMachines([]);
        setInBoundDataFull([]);
        setMachinesTagSelect([]);

    }

    function addRuleOutBoundHandler() {
        let newObj = { ...outboundData };
        let newId = uuid();
        newObj[newId] = {

            id: newId,
            type: {},
            typesHelpers: typesHelpers,
            firewallgroupid: 0,
            firewallruleid: 0,
            ruletypeid: typesHelpers['Custom TCP']?.value,
            ruletype: 2,
            src: '',
            dst: '',
            srcport: '',
            destport: '',
            description: '',
             protocolType:typesHelpers['Custom TCP']?.ruletype,
             protocol:'Custom TCP'
        }


        setOutboundData(newObj);
    }

    function editRuleBound(id, propValue, propName, bound) {



        if (bound === 'inbound') {

            const newObj = { ...inboundData };
            if (propName == 'optionPort') {
                // newObj[id]
                newObj[id]['srcport'] = propValue?.port;
                newObj[id]['protocol'] = propValue?.protocol
                    ;  
                    newObj[id]['protocolType'] = propValue?.protocolType;
                newObj[id]['ruletypeid'] = propValue?.ruletypeid
                newObj[id]['errors'] = {
                    ...newObj[id]['errors'],
                    srcportErr:{
                        isError:false
                    }
                }
            } else {
              
                
                if(propName === 'srcport'){
                    newObj[id]['errors'] = {
                        ...newObj[id]['errors'],
                        srcportErr:{
                            isError:false
                        }
                    }
                    newObj[id][propName] = propValue?.value;
                    newObj[id]['protocol'] = propValue?.protocol;
                    newObj[id]['protocolType'] = propValue?.protocolType;
                    newObj[id]['ruletypeid'] = propValue?.ruletypeid
                    
                }else if(propName === 'src'){
                    newObj[id]['errors'] = {
                        ...newObj[id]['errors'],
                        srcErr:{
                            isError:false
                        }
                    }
                    newObj[id][propName] = propValue
                }else{
                    newObj[id][propName] = propValue
                }
            }

            setinboundData(newObj);
        } else {

            const newObj = { ...outboundData };
            if (propName == 'optionPort') {
                // newObj[id]
                newObj[id]['destport'] = propValue?.port;
                newObj[id]['protocol'] = propValue?.protocol;
                newObj[id]['protocolType'] = propValue?.protocolType;
                newObj[id]['ruletypeid'] = propValue?.ruletypeid
                    ;
                    newObj[id]['error'] = {
                        ...newObj[id]['error'],
                        destportErr:{
                            isError:false
                        }
                    }
            } else {
               
                if(propName === 'destport'){
                    newObj[id]['errors'] = {
                        ...newObj[id]['errors'],
                        destportErr:{
                            isError:false
                        }
                    }
                    newObj[id][propName] = propValue?.value;
                    newObj[id]['protocol'] = propValue?.protocol;
                    newObj[id]['ruletypeid'] = propValue?.ruletypeid
                    newObj[id]['protocolType'] = propValue?.protocolType;
                }else if(propName === 'dst'){
                    newObj[id]['errors'] = {
                        ...newObj[id]['errors'],
                        srcErr:{
                            isError:false
                        }
                    }
                    newObj[id][propName] = propValue;
                }else{
                    newObj[id][propName] = propValue
                }
            }

            setOutboundData(newObj);
        }
    }

    async function getUserMachinesList() {
         debugger;
        if (StaticFireWall.firewallEdit) return;
        const res = await getUserAllMachinesFirewallService();
        const isValid = checkResData(res);
        if (!isValid) {
           //alert("something went wrong");
            console.log(res);
            return;
        }

        const isSafe = isJsonStringHelper(res.data.result);
        if (!isSafe) {
            handlerAlert("danger", "Something Went Wrong", "danger");
            return;
        }

        const resJson = JSON.parse(res.data.result);
    ;
        let newArr = [];
        for (let i = 0; i < resJson.length; i++) {
            if (resJson[i]?.firewallgroupid == 0) {
                let val = {
                    value: resJson[i]?.machineid,
                    label: resJson[i]?.machinename
                }

                newArr.push(val);
            }

        }

        setSelectMachineTag(newArr)



    }



    function handleSecurityName(e) {
         ;
        if(e == '') {
            setSecurityGroupName('');
            return;
        };
        let val = e?.target?.value;
        setSecurityGroupName(val)

        setFirewallErrors((prev)=>{
            return {...prev,firewallGroupName:{
                isError:false,
                message:""
            }}
        })
    }



    function formatData(firewallgroupId = 654654,payloadRes) {
        let rulesAdded = [];
        let mergeBounds = { ...inboundData, ...outboundData };
       

        for (let item in mergeBounds) {
            let currItem = mergeBounds[item];
            const { description, destport, id,protocolType,ruletypeid , protocol, ruletype, dst, src, firewallruleid, srcport
            } = currItem
            

            const srPort = ruletype == 1 ? srcport : destport
            let createItemPayload = {
             //   "firewallgroupid": `${firewallgroupId}`,//remove this
                "firewallruleid": firewallruleid,
                "ruletype": ruletype,
                "src": ruletype == 1 ? `${src}` : "any",
                "dst": ruletype == 2 ? `${dst}` : "any",
                "srcport":srPort,
                "destport":"any",
                // "srcport": ruletype == 1 ? `${srcport}` : "any",
                // "destport": ruletype == 2 ? `${destport}` : "any",
                "description": description,
                "protocol":protocolType?.toLowerCase(),
                "ruletypeid":ruletypeid
            }
            rulesAdded.push(createItemPayload)
        }
  

        const payload = {
            rulesadded: rulesAdded,
            rulesremoved: deleteRules,
            ...payloadRes
        }

        return payload;
    }

    function validFirewallForm() {
     
        if (securityGroupName == '') {
            securityGroupNameRef.current.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest",
            });
        }

        if (firesDescription) {

        }
        return true;
    }

    function checkBoundItemData(boundName, boundDataObj, boundHandler) {
         ;
        let isBool = false;
        let copyData = cloneDeep(boundDataObj);
        for (let item in copyData) {
            let currItem = copyData[item];
            let port = '';
            const { srcport, destport, src, dst } = currItem;
             ;
            if (boundName == 'inbound') {
                if (srcport === '') {
                    currItem['errors'] = {
                        ...currItem['errors'],
                        srcportErr: {
                            isError: true,

                        }
                    }
                    isBool= true;
                }
                if (src === '') {
                    currItem['errors'] = {
                        ...currItem['errors'],
                        srcErr: {
                            isError: true,

                        }
                    }
                    isBool= true;
                }

                if(src != 'any'){
                    const validate4 = validator({ version: 4 })
                    if (!validate4(src)) {
                        currItem['errors'] = {
                            ...currItem['errors'],
                            srcErr: {
                                isError: true,
                                message:'Only IPV4'
                            }
                        }
                        isBool= true;
                    }
                }
                

            } else {
                
                if (destport === '') {
                    currItem['errors'] = {
                        ...currItem['errors'],
                        destportErr: {
                            isError: true,

                        }
                    }
                    isBool= true;
                }
                if(dst === ''){
                    currItem['errors'] = {
                        ...currItem['errors'],
                        srcErr: {
                            isError: true,

                        }
                    }
                    isBool= true;
                }
                if(dst != 'any'){
                    const validate4 = validator({ version: 4 })
                    if (!validate4(dst)) {
                        currItem['errors'] = {
                            ...currItem['errors'],
                            srcErr: {
                                isError: true,
                                message:'Only IPV4'
                            }
                        }
                        isBool= true;
                    }
                }
            }
        }
         ;
        // console.log(boundDataObj,boundName);
        // console.log(copyData,boundName);
         boundHandler(copyData);
        return isBool;
    }

    function fireValidition() {
        let isBool = false;

         const inInbounderr = checkBoundItemData('inbound', inboundData, setinboundData);
        const outInbounderr = checkBoundItemData('outbound', outboundData, setOutboundData);
        //  ;
        // return {
        //     isError: false,
        //     errorMessage: ``,
        //     errorName: ''
        // }
        if(inInbounderr || outInbounderr){
            return {
                isError: true,
                errorMessage: ``,
                errorName: ''
            }
        }
        if (securityGroupName == '') {
            isBool = true;
            securityGroupNameRef.current.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest",
            });
            applyErrorFirewall('firewallname','Name is required')
          
        }

        // if (firesDescription == '') {
        //     isBool = true;
        //     firesDescriptionRef.current.scrollIntoView({
        //         behavior: "smooth",
        //         block: "end",
        //         inline: "nearest",
        //     });
        //     applyErrorFirewall('description','description is required')
        //     // return {
        //     //     isError: true,
        //     //     errorMessage: `description can't be empty`,
        //     //     errorName: 'firesDescription'
        //     // }
        // }

        if(isBool){
            return {
                isError: true,
                errorMessage: `Name can't be empty`,
                errorName: 'securityGroupName'
            }
        }



        return {
            isError: isBool,
            errorMessage: ``,
            errorName: ''
        }

    }


    async function handlerFireSubmit(e) {
        // if (fireValidition().isError) {
        //     return;
        // }
        // const mainPayloads = formatData();
        // console.log(mainPayloads,'background: #222; color: #bada55');
        // return;
   
        setSubmitLoading(true);
        if (fireValidition().isError) {
            setSubmitLoading(false);
            return;
        }


        if(Object.keys(outboundData).length ===0 ){
            handlerAlert("danger","out bound rule can't be empty","danger");
            setSubmitLoading(false);
            return;
        }
    //     alert("hello")
    //    return;

        const selectedMachinesData = machinesTagSelect.map(function (el, i) {
            return Number(el.value);
        })

        const payload = {
            "groupname": securityGroupName,
            "description": firesDescription,
            "machinesadded": selectedMachinesData,
            "machinesremoved": deleteMachines.filter(id => id!=0),
            "firewallgroupid": firewallformid
        }
        // const res = await saveGroupInfoService(payload);

   
        // const isValid = checkResData(res);
        // if (!isValid) {
        //     setSubmitLoading(false);
        //     console.log('something went wrong');
        //     handlerAlert("danger","Something went wrong","danger");
        //     return;
        // }
        //const firewallGroupId = res.data.result;
      

        const mainPayload = formatData(firewallformid,payload);
        const mainRes = await saveGroupRulesService(mainPayload); //this
        const firewallid = mainRes.data.result;
   
        if(mainRes.data.code != '0'){
            setSubmitLoading(false);
            handlerAlert('danger', mainRes.data.message,'danger');
            return;
        }
        setSubmitLoading(false);
        dispatch(getFirewallListAction());
        const urlArr = window.location.pathname.split('/');
        if(urlArr[urlArr.length-1]==='firewall'){
            history.push('/firewall');
        }else{
            history.push(`/firewall/${firewallid}`)
        }
      
        handlerAlert('success', "Your Firewall was been saved");
 
        return;

        // if (fireValidition().isError) {
        //     securityGroupNameRef.current.scrollIntoView({
        //         behavior: "smooth",
        //         block: "end",
        //         inline: "nearest",
        //     });
        //     return;
        // }
        // console.log("hello");

    }

    function handlerSetMachinesTag(values) {

        let deleteMachine = 0;
        let isFlag = false;


       
        if (values == null) {

            setMachinesTagSelect([]);
            let delteArr = [];
            for (let i = 0; i < machinesTagSelect.length; i++) {
                delteArr.push(Number(machinesTagSelect[i].value))
            }
            setDeleteMachines(delteArr)
        } else {
            for (let i = 0; i < machinesTagSelect.length; i++) {
                let currItemValue = machinesTagSelect[i].value;

                for (let j = 0; j < values.length; j++) {
                    if (currItemValue === values[j].value) {
                        isFlag = true;
                        break;
                    }
                }
                if (!isFlag) {
                    deleteMachine = currItemValue;
                    break;
                }

                isFlag = false;

            }
            setMachinesTagSelect(values);
        }
        setDeleteMachines(prev => [...prev, Number(deleteMachine)]);
    }

    return (
        <FirewallContext.Provider
            value={{submitLoading,setSubmitLoading,setFirewallFormid,setDeleteRules,setDeleteMachines,boundDetailsLoading,setBoundDetailsLoading,inBoundLoading,setInBoundLoading,firewallErrors, typesHelpers, reSetValuesFirewallForm, getRulesDataFun, fetchBoundTypes, getUserMachinesList, getUserSelectFirewallIdMachines, getFirewallGroups, deleteOutBoundItem, addRuleOutBoundHandler, inBoundDataFull, setInBoundDataFull, setinboundData,deleteInBoundItem, addRuleInBoundHandler, outboundData, setOutboundData, boundTypes, inboundData, machinesTagSelect, setMachinesTagSelect: handlerSetMachinesTag, selectMachineTagRef, firesDescriptionRef, securityGroupNameRef, securityGroupName, setSecurityGroupName: handleSecurityName, firesDescription, setFireDescription: handleFireDescription, selectMachineTag, editRuleBound, setSelectMachineTag, handlerFireSubmit }}
        >
            {children}
        </FirewallContext.Provider>
    );
}

export default withRouter(CreateFireWallContext);