import React from 'react'
import StaticFireWall from '../../../../../../utils/StaticsClasses.js/StaticFirewall'
import SpacerHS_TWO from '../../../../hireserver/Spaces/SpacerHS_TWO'
import FirewallContext from './FirewallContext/FirewallContext'
import BasicDetails from './Sections/BasicDetails'
import FireFooter from './Sections/FireFooter'
import InBoundFire from './Sections/InBoundFire'
import OutBoundFire from './Sections/OutBoundFire'
// import InputBoundFire from './Sections/InputBoundFire'
// import OutputBoundFire from './Sections/OutputBoundFire'

function FirewallForm() {
  const {reSetValuesFirewallForm,  fetchBoundTypes,getUserMachinesList} = React.useContext(FirewallContext);
  React.useEffect(function (){
   
 
    fetchBoundTypes();
    getUserMachinesList();

    reSetValuesFirewallForm();
   
  },[]);
  return (
    <div className='mb-5' style={{
        minHeight:'max-content',

    }}>
        <BasicDetails/>
        {/* <SpacerHS_TWO/> */}
        <hr/>
        <InBoundFire/>
        <hr />
        <OutBoundFire/>
        
        <hr />
        <FireFooter/>
    </div>
  )
}

export default FirewallForm