import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { ThemeContext } from '../../../../context/ThemeContext';
import profile from "../../../../images/hireserver/imgs/logoprofile.png";
import { logout } from '../../../../store/actions/AuthActions';
import { clearInstancesReducer } from '../../../../store/actions/InstancesActions/InstancesActions';
import { resetNotice } from '../../../../store/reducers/NotificationReducer';
import LogoutPage from '../Logout';



function HeaderProfile({userDetails,history,username}) {
    const {toggleTheme,toggleThemeSet,logoutHandlerTheme} = React.useContext(ThemeContext);
    const dispatch = useDispatch();
    function onLogout() {
        //     '';
        
        dispatch(clearInstancesReducer());
            dispatch(logout(history));
            logoutHandlerTheme();
            localStorage.setItem('theme',JSON.stringify('light'));
                       dispatch(resetNotice())
          //  dispatch(clearInstancesReducer());
        // console.log('logout')
           // window.location.reload();
        }

    const name = username?username:userDetails?.name
    return (
        <React.Fragment>
            <Dropdown className='header-profile headerProfile'>
                <Dropdown.Toggle className=''>
                    <Link to={"/profile/username"} className="d-flex align-items-center  text-decoration-none d-gap-5">
                        <div className="header-info d-flex flex-column align-items-end">
                       
                        <span className='text-dark'>{name}</span>
                            <small className='hs_text-light'>Welcome</small>
                        </div>
                        <div className='p-2 px-3 d-lg-none icon_backGround_header'>
                            
                           <i className='fa fa-user text-body'></i>
                        </div>
                        <img className='d-none' src={profile} width="20" alt="" />
                    </Link>
                </Dropdown.Toggle>

                {/* <Dropdown.Menu>

                    <Dropdown.Item>
                        <Link to="/profile/username" className="dropdown-item ai-icon">
                            <svg
                                id="icon-user1"
                                xmlns="http://www.w3.org/2000/svg"
                                className="text-primary"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                            <span className="ml-2">Profile </span>
                        </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                        <Link
                            to="/email-inbox"
                            className="dropdown-item ai-icon"
                        >
                            <svg
                                id="icon-inbox"
                                xmlns="http://www.w3.org/2000/svg"
                                className="text-success"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                <polyline points="22,6 12,13 2,6"></polyline>
                            </svg>
                            <span className="ml-2">Inbox </span>
                        </Link>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={onLogout} >
                        <LogoutPage />
                        
                    </Dropdown.Item>

                </Dropdown.Menu> */}
            </Dropdown>
        </React.Fragment>
    )
}



export default withRouter(connect(null)(HeaderProfile))