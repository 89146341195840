import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect, useDispatch } from "react-redux";
import warming from '../../../../images/hireserver/imgs/warming.jpg';
import { closeAllModal, VM_ActionCall, VM_ActionStarted } from "../../../../store/actions/InstancesActions/InstanceCurrItemAction";
import { closeHsModal, deleteShowConfirmModal, getInstancesListPage } from "../../../../store/actions/InstancesActions/InstancesActions";
import ModalHs from "../../hireserver/ModalHs";
import MachineInstanceCard from "./components/CardView/MachineInstanceCard";
import CloseInstancesModal from "./components/sharedComponents/CloseInstancesModal";
import DeleteInstancesModal from "./components/sharedComponents/DeleteInstancesModal";
import RebootInstancesModal from "./components/sharedComponents/RebootInstancesModal";
import { handlerAlert } from "./InstancesListComponents/DropDownInstancesList";
// import _debounce from 'lodash/debounce';
// import cloneDeep from 'lodash/cloneDeep';

function InstancesCardView({ instancesList, curSelectItem, showDeleteModal, showHsModal }) {
  const [dataView, setDataView] = React.useState([]);
  const [searchInput, setSearchInput] = React.useState("");
  const [pagenation, setPagenation] = React.useState(1);
  let dispatch = useDispatch();
  // const debounceFn = React.useCallback(_debounce(handleDebounceFn, 500), []);

  React.useEffect(
    function () {
      setDataView(instancesList);

      // // console.log(instancesList, "instancesListlll");
    },
    [instancesList]
  );

  function handleDebounceFn(inputValue) {
    if (inputValue == "") {
      setDataView(instancesList);
      return;
    }
    let filterSearchRes = [];
    for (let i = 0; i < instancesList.length; i++) {

      if (instancesList[i].machinename.includes(inputValue)) {
        filterSearchRes.push(instancesList[i]);
      }
    }

    //// console.log(filterSearchRes)
    setDataView(filterSearchRes);
  }

  function onInputHandler(e) {
    let machineSearchInput = e.target.value;
    setSearchInput(machineSearchInput);
    handleDebounceFn(e.target.value);
  }

  function loadMoreList() {
    // let pageNumber = pagenation + 1;
    // dispatch(getInstancesListPage(pageNumber));
    // setPagenation(pageNumber)

  }

  return (
    <React.Fragment>
      <div className="px-3">
        <div className="position-relative">
          <input
            placeholder="Search Machine"
            onInput={onInputHandler}
            value={searchInput}
            type="text"
            className="form-control searchComponentCustomInput"
          />
          <div
            className="position-absolute"
            style={{ top: "20%", right: "15px" }}
          >
            <span>
              <i className="fa fa-search hs_secondaryColor"></i>
            </span>
          </div>
        </div>
        <div className="d-none">
          <div>
            <span>
              <i className="fa fa-search"></i>
            </span>
          </div>
          <div>Sort By</div>
          <div>Name</div>
          <div>
            <div>
              <span>
                <i className="fa fa-search"></i>
              </span>
              <span>Filters</span>
            </div>
          </div>
        </div>
        {/* <div className="row">
        {dataView.map(function (item, i) {
          return <MachineInstanceCard key={i * 99} item={item} />;
        })}
      </div> */}
        <InfiniteScroll inverse={false} dataLength={dataView.length} hasMore={true} next={loadMoreList} className="row pt-3">
          {dataView.map(function (item, i) {
     
            return <MachineInstanceCard key={item.machineid} item={item} />;
          })}
        </InfiniteScroll>
      </div>

      <DeleteInstancesModal />
      <CloseInstancesModal />
      <RebootInstancesModal />

    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    getInstances: state.instances.instancesList,
    showDeleteModal: state.instances.showDeleteModal,
    currDeleteItem: state.instances.currDeleteItem,
    showHsModal: state.instances.closeInstanceModal,
    curSelectItem: state.instances.curSelectItem,
    rebootShowModal: state.instances.rebootShowModal,
    deleteAllModal: state.instances.deleteAllModal
  };
};

export default connect(mapStateToProps)(InstancesCardView);
