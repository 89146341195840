import React from 'react'
import { Form } from 'react-bootstrap';
import DatabaseHeading from './components/DatabaseHeading';
import EngineAndRegion from './components/EngineAndRegion';
// import DatabaseChooseAPlan from './components/DatabaseChooseAPlan';
import NumberOfNodes from './components/NumberOfNodes';
import AddAccessControl from './components/AddAccessControl';
import ButtonHS from '../../../hireserver/controls/ButtonHS';
import DatabaseChooseAPlan from './components/DatabaseChooseAPlan';
import DatabaseContext from '../DatabaseContext/DatabaseContext';
export default function DatabaseCreateForm() {
  // const [selectedOption, setSelectedOption] = React.useState(null);
  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ]
  // isDisable,styles,handler,children
  

  const {onClickBtnSubmit,setNodeHandler,ipaddress,setIpaddress} = React.useContext(DatabaseContext);
  return (
    <div>
      <div className='formContainer'>
       
            <Form onSubmit={(e)=>e.preventDefault()}>
              <DatabaseHeading/>
              <hr />
              <EngineAndRegion/>
              <hr />
              <DatabaseChooseAPlan/>
              <hr />
              <NumberOfNodes clickHandler={setNodeHandler}/>
              <hr />
              <AddAccessControl inputValue={ipaddress} setInputValue={setIpaddress} />
              <div className='w-100 d-flex justify-content-center align-items-center'>
              <ButtonHS isDisable={false} handler={onClickBtnSubmit}  styles={{ padding: "15px 100px" }}>
                Create DataBase
              </ButtonHS>
              </div>
            </Form>
         
      </div>

    </div>
  )
}
