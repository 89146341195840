import React from "react";
import { Sparklines, SparklinesLine, SparklinesBars } from "react-sparklines";
import { getStatusByCode } from "../../../../../../../API_ENDPOINTS/API_ENDPOINTS";
import { getStatusDetailsService } from "../../../../../../../services/InstancesServices/InstancesListService";
import { connect, useSelector } from "react-redux";
import LoaderHS from "../../../../../hireserver/components/LoaderHS";
import RunningStatus from "./RunningStatus";
import { useRouteMatch,  useLocation } from "react-router-dom";
import { handlerAlert } from "../../../InstancesListComponents/DropDownInstancesList";
import ErrorClass from "../../../../../../../services/ServiceErrorHandlers/ErrorClass";

const sampleData = [
  10, 24, 30, 10, 15, 0, 20, 30, 65, 12, 10, 3, 6, 8, 2, 6, 10, 24, 30, 10, 15,
  0, 20, 30, 65, 12, 10, 3, 6, 8, 2,
];

function AnalyticsTab({currDetailsItem}) {
  const [loading,setLoading] = React.useState(false);
  const [dataCpu,setDataCpu] = React.useState([]);
  const [ramData,setRamData] = React.useState([]);
  const [diskData,setDiskData] = React.useState([]);
  const { url:siteUrl  } = useRouteMatch();

 
  //React.useEffect(function (){
  //   // console.log(currDetailsItem,'currDetailsItemcurrDetailsItemcurrDetailsItemcurrDetailsItemcurrDetailsItemcurrDetailsItemcurrDetailsItemcurrDetailsItemcurrDetailsItemcurrDetailsItemcurrDetailsItem')
  //  let code
  //   if(!currDetailsItem?.code && siteUrl){
  //     code = siteUrl.split("/")[2];
  //   }else{
  //     code =  currDetailsItem.code;
  //   }
   
  //   const url = getStatusByCode(code);
  //   getDateStatus(url);
   
  // },[]);

  React.useEffect(function (){
    return () => {
      setDataCpu([]);
      setRamData([]);
      setDiskData([]);
    };

    // return function reason React Error
    // https://stackoverflow.com/questions/54954385/react-useeffect-causing-cant-perform-a-react-state-update-on-an-unmounted-comp
  },[]);

  // React.useEffect(function (){
  //   const url = getStatusByCode(437);
  //   getDateStatus(url);
  // },[]);

  function getConfig(series) { return {
    title: {text: ""},
    xAxis: {visible: false},
    yAxis: {visible: false},
    tooltip: {
      enabled: false
    },
    credits: false,
    series: series,
    chart: {
      type: 'line',
      height: 60,
      width: 200
    }
  }}

  async function getDateStatus(url){
    
    setLoading(true);
    let res = null;
    let data = '';
   try {
   res = await getStatusDetailsService(url);
   if(!res['data']){
    return;
  }
   } catch (error) {
    handlerAlert("danger",error,'danger')
    return;
   }
  
   

    data = res.data;
    // '';
    // console.log(data)

    if(data.result =='' || data.result === null || data.errors !== null) {
      setLoading(false);
      if(data?.errors){
       
        handlerAlert("danger",  data?.errors[0],'danger')
      }else{
        // handlerAlert("danger",  data?.errors[0],'danger')
        handlerAlert("danger", 'SomeThing Went Wrong','danger')
        // console.log(data,'🚨 Error!!')
      }

     
     
      return;
    };
    const result = JSON.parse(data.result);
    let newArr = [];
    let ramArr = [];
    let diskArr = [];
    // console.log(data,url)
    for(let i=0;i<result.data.length;i++){
      if(result.data[i]['cpu']){
        newArr.push(result.data[i]['cpu']);
      }

      if(result.data[i]['mem']){
        ramArr.push(result.data[i]['mem']);
      }
      
      if(result.data[i]['disk']){
        diskArr.push(result.data[i]['disk']);
      }
      
     
    }

    setDataCpu(newArr);
    setRamData(ramArr);
    setDiskData(diskArr);
    setLoading(false);
  }

  // React.useEffect(function (){
  //   // console.log(diskData,'diskData');
  //   // console.log(ramData,'ramData');
  //   // console.log(dataCpu,'dataCpu');
  // },[diskData,ramData,dataCpu]);
  return (
   <React.Fragment>
   { loading ? <LoaderHS/> :
     <React.Fragment>
      <div className="row d-none">
      <div className="col-12 col-md-4 usage-graphs">
        <div className="card" style={{background:"#F1FFFF"}}>
          <div className="card-header border-0 px-3 pt-3 pb-0">
            <div>
              <h4>Ram</h4>
              <div className="d-flex">
                <h3>19 GB</h3>
                <span className="fs-16 text-body font-w300 py-1">/2000GB</span>
              </div>
            </div>
          </div>
          <div className="card-body p-0">
            <Sparklines data={ramData}>
              <SparklinesLine style={{ fill: "#c7fffe",height: "50px",width: "100%"}} />
            </Sparklines>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-4 usage-graphs">
        <div className="card" style={{background:"#F1FFFF"}}>
          <div className="card-header border-0 px-3 pt-3 pb-0">
            <div>
              <h4>CPU Usage</h4>
              <div className="d-flex">
                <h3>3%</h3>
              </div>
            </div>
          </div>
          <div className="card-body p-0">
            <Sparklines data={dataCpu}>
              <SparklinesLine style={{ fill: "#c7fffe",height: "50px",width: "100%"}} />
            </Sparklines>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-4 usage-graphs">
        <div className="card" style={{background:"#F1FFFF"}}>
          <div className="card-header border-0 px-3 pt-3 pb-0">
            <div>
              <h4>Disk</h4>
              <div className="d-flex">
                <h3>19 GB</h3>
                <span className="fs-16 text-body font-w300 py-1">/2000GB</span>
              </div>
            </div>
          </div>
          <div className="card-body p-0">
            <Sparklines data={diskData}>
              <SparklinesLine style={{ fill: "#c7fffe" }} />
            </Sparklines>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-3 usage-graphs d-none">
        <div className="card" style={{background:"#F1FFFF"}}>
          <div className="card-header border-0 px-3 pt-3 pb-0">
            <div>
              <h4>Band Width</h4>
              <div className="d-flex">
                <h3>3%</h3>
              </div>
            </div>
          </div>
          <div className="card-body p-0">
            <Sparklines data={sampleData}>
              <SparklinesLine
                style={{ fill: "#C7FFFE", "fill-opacity": "1" }}
              />
            </Sparklines>
          </div>
        </div>
      </div>
    </div>
     <div><RunningStatus currDetailsItem={currDetailsItem} /></div>
      </React.Fragment>}
   </React.Fragment>
  );
}
const mapStateToProps = ({instanceDetails}) => {
  return{
    currDetailsItem:instanceDetails.currDetailsItem
  }
}
export default connect(mapStateToProps)(AnalyticsTab)