import React from 'react'
import HeadingHS from '../../../../hireserver/controls/HeadingHS'
import DisplayCard from '../../../../hireserver/DisplayCard'
import DatabaseContext from '../../DatabaseContext/DatabaseContext';

function EngineAndRegion() {
    
    const {DatabaseEngine,setDatabaseEngine,region,setRegion} = React.useContext(DatabaseContext);
    const [fakeArr, setFakeArr] = React.useState([
        {
            img: 'https://bs-uploads.toptal.io/blackfish-uploads/components/skill_page/content/logo_file/logo/195568/sql-64a6e0f07773cf17581e76ca09e17dbc.png',
            name: 'SQL'
        },
        {
            img: 'https://assets-global.website-files.com/6064b31ff49a2d31e0493af1/610d75bbd917363ac7a1fec0_mongoDB.svg',
            name: 'NoSQL'
        }
    ])
    return (
        <React.Fragment>
            <div className='col-12 col-lg-5 '>
                <div>
                    <HeadingHS title="Select Engine And Region" as="h3" />
                </div>

                <DisplayCard classNames="d-flex d-gap-10 " clickHandler={setDatabaseEngine}  heading="Database Engine" arr={fakeArr} cardName="DatabaseEngine" />
                <DisplayCard classNames="d-flex d-gap-10 " clickHandler={setDatabaseEngine} heading="Region" arr={fakeArr} cardName="Region" />
            </div>
            
        </React.Fragment>
    )
}

export default React.memo(EngineAndRegion);