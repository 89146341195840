import { formatDistance } from "date-fns";
import React from "react";
import { useDispatch } from "react-redux";
import notificationImg from "../../../../../images/hireserver/imgs/notification.png";
import { postMarkAsReadNoticeService } from "../../../../../services/Profile/NotifationsService";
import { putNoticeArrAction, removeOneNoticeArrAction } from "../../../../../store/reducers/NotificationReducer";
import { checkResData } from "../../../../../utils/Helpers";
import { handlerAlert } from "../../../../components/Dashboard/Instances/InstancesListComponents/DropDownInstancesList";
import ImageHs from "../../../../components/hireserver/ImageHs";


function NotificationCard({ checked,item,isNeed=true,getNotics,callNoticesCount }) {
  const { Subject, Message, ReminderDate, ReminderId } = item;

  const [isCheck,setIsCheck] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(function (){
    if(checked){
      setIsCheck(true);
    }else{
      setIsCheck(false);
    }
  },[checked]);

    async function handleMarkAsRead(){
        const resData = await postMarkAsReadNoticeService(ReminderId);
        let isValid  = checkResData(resData);

        if(!isValid){
            return;
        }
        getNotics();
        callNoticesCount();
        const dataJSON = JSON.parse(resData.data.result);

        handlerAlert("success","Marked as Read");
    }

    function checkBoxHandler(){
      if(isCheck == false){
        dispatch(putNoticeArrAction(ReminderId));
      }else{
    
        dispatch(removeOneNoticeArrAction(ReminderId));
      }
      

     
      
    }

  return (
    <div className={`d-flex justify-content-between align-items-center ${isNeed && 'pr-3' } notificationCard py-1`}>
      <div>
      <ImageHs
        src={notificationImg}
        alt="Notification"
        width="44px"
        height="44px"
      />
      </div>
      <div style={{
        flexGrow:1,
        padding:"0 14px"
      }}>
        <div className="font-16 hsBack">{Subject}</div>
        <div className="font-12 ">{Message}</div>
        <div className="font-10 font-w600 d-flex justify-content-between">
          <div className="text-darkgrey font-w400">
            
            {formatDistance(new Date(ReminderDate), new Date(), {
              addSuffix: true,
            })?.replace("about", "")}
          </div>
         {isNeed && <div onClick={handleMarkAsRead} className="text-darkgrey cursor-pointer font-w400">Mark as read</div>}
        </div>
      </div>
            {
                isNeed &&   <div className="align-self-baseline">
                <input checked={isCheck} onChange={checkBoxHandler} className="cursor-pointer" type="checkbox" name="notificheck" />
              </div>
            }
    </div>
  );
}

export default NotificationCard;
