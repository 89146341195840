import React from 'react'
import { useDispatch } from 'react-redux';
import { openDueTableRenewalAction, putDueTableDataAction } from '../../../store/actions/renewalActions/renewalActions';

function RenewalView({item}) {
    const dispatch = useDispatch();
    function onClickRenwal(){
      
        dispatch(openDueTableRenewalAction());
        const {noofblocks,tenuretypename,operatingsystemname,groupname,totalamount,machinename,osiconpath,icon,branchname,machineid,duedate
        } = item
        let obj = {
          "machineid": machineid,
          "duedate": duedate,
          totalamount:totalamount,
          "machinename": machinename,
          "noofblocks":noofblocks,
          // "duedays": 18,
          "tenuretypename": tenuretypename,
          "publicip": null,
          "branchname": branchname,
          "groupname": groupname,
          "operatingsystemname": operatingsystemname,
          "osiconpath": osiconpath,
          "icon": icon
      }
        dispatch(putDueTableDataAction(obj));
        console.log(item);
        console.log("Dd");
    }
  return (
    <button onClick={onClickRenwal} className="btn-hslineargradient btn-renwal btn-sm px-3 py-1">Renew</button>
  )
}

export default RenewalView