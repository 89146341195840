import { getOrderDetailsURL, postPayRenewalURL } from "../../API_ENDPOINTS/PAYEMENT_FORM_ENDPOINTS";
import { confirmWalletAddedURL, getPaymentCheckStatusURL, getPaymentWalletAmountURL, postCheckPaymentStatusURL, postPaymentAddToWalletURL, transactionWalletURL } from "../../API_ENDPOINTS/PAYMENT_ENDPOINTS";
import { API_SERVICES } from "../API_SERVICES";
import { getSessionAndUserId } from "../InstancesServices/InstancesListService";

export function getpaymentCheckStatusService(orderId){
    const { userId, sessionId } = getSessionAndUserId();
    let url = getPaymentCheckStatusURL(orderId,userId);

    var config = {
        method: 'get',
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        }
    };

    return API_SERVICES(config);
}

export function postCheckPaymentStatusService(orderId,isTrue = false){
    const { userId, sessionId } = getSessionAndUserId(); 
    let url = postCheckPaymentStatusURL(orderId,userId,isTrue);

    let config = {
        method: 'post',
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        }
    };

    return API_SERVICES(config);
}


export function getPaymentWalletAmountService(){
    const { userId, sessionId } = getSessionAndUserId(); 
    let url = getPaymentWalletAmountURL(userId);

    let config = {
        method: 'get',
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        }
    };

    return API_SERVICES(config);
}

export function getTransactionWalletService(){
    const { userId, sessionId } = getSessionAndUserId(); 
    let url = transactionWalletURL(userId);

    let config = {
        method: 'get',
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        }
    };

    return API_SERVICES(config);
}

export function getOrderDetailsService(orderid){
    const { userId, sessionId } = getSessionAndUserId(); 
    let url = getOrderDetailsURL(orderid);

    let config = {
        method: 'get',
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        }
    };

    return API_SERVICES(config);
}

export function postAddToWalletService(payload){
    
    const { userId, sessionId } = getSessionAndUserId(); 
    let url = postPaymentAddToWalletURL();

    let config = {
        method: 'post',
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        },
        data:payload
    };

    return API_SERVICES(config);
}

export function postconfirmWalletAddedService(payload){
    
    const { userId, sessionId } = getSessionAndUserId(); 
    let url = confirmWalletAddedURL();

    let config = {
        method: 'post',
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        },
        data:payload
    };

    return API_SERVICES(config);
}

export function postPayRenewalService(machineid){
    const { userId, sessionId } = getSessionAndUserId(); 
    let url = postPayRenewalURL(machineid);

    let config = {
        method: 'post',
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        },
        
    };

    return API_SERVICES(config);
}