import React from 'react'
import { checkMachinePromise } from '../../../../../services/API_SERVICES';
import CheckBoxInstances from './ItemComponents/CheckBoxInstances';
import InstancesDropDownTable from './ItemComponents/InstancesDropDownTable';
import MachineNameInstanceTable from './ItemComponents/MachineNameInstanceTable';
import OSInstanceTable from './ItemComponents/OSInstanceTable';
import PriceInstanceTable from './ItemComponents/PriceInstanceTable';
import StatusShowInstanceTable from './ItemComponents/StatusShowInstanceTable';
import ViewInstanceTable from './ItemComponents/ViewInstanceTable';

function CustomTableItem({itemDataCurr}) {
    const [itemData,setItemData] = React.useState(null);
    const [loading,setLoadingStatus] = React.useState(false);
    React.useEffect(function (){
      
        checkMachineStutus();
        setItemData(itemDataCurr)
        // console.log(itemDataCurr)
    },[itemDataCurr]);

    async function checkMachineStutus(){
       // const taskId = itemDataCurr.proxmoxtaskid;
       debugger;
        const {proxmoxtaskid,hostname,branchid} = itemDataCurr;
        setLoadingStatus(true);
        //await checkMachinePromise(proxmoxtaskid,hostname,branchid);
        setLoadingStatus(false);
    }
  return (
    
        <React.Fragment>
            {itemData  && <React.Fragment>
            {/* <td className='checkboxTable'><CheckBoxInstances/></td> */}
        <td><MachineNameInstanceTable name={itemData?.machinename} block={itemData?.noofblocks} ip={itemData?.privateip} /></td>
        <td>
            <OSInstanceTable subtitle={itemData?.operatingsystemname}/>
        </td>
        <td>
            <OSInstanceTable icon={itemData?.icon} subtitle={itemData?.branchname} />
        </td>
        <td>
            <PriceInstanceTable price="$128.6" data={itemData?.createddate} />
        </td>
        <td>
            <ViewInstanceTable/>
        </td>
        <td>
            <div className='d-flex flex-column justify-content-center align-items-center'> 
            <StatusShowInstanceTable loading={loading} setLoadingStatus={setLoadingStatus} isColor={''} isText={''} isSpinning={false} statusid={itemData?.refstatusid} />
            </div>
        </td>

        <td>
            <InstancesDropDownTable setItemData={setItemData} setLoadingStatus={setLoadingStatus} itemData={itemData}/>
        </td>
      
        </React.Fragment>}
        </React.Fragment>
    
  )
}

export default CustomTableItem