import React from 'react'
import { blockInvalidChar } from '../../../../utils/Helpers'

export default function ChangeInputControl({value,minus,plus,clickHandler,onChangeBlocksHandler}) {
  // function handleKeyDown(e){
  //   let num = e.which;
   
  //   if(37 == num || 39 == num || 38 == num || 40 == num){
  //     e.preventDefault();
  //   }
  // }
  return (
    <div className='d-flex d-gap-30 border p-2 pointerEventNone'>
        
        <span className="d-none justify-content-center align-items-center changeInputControlBtns" onClick={clickHandler} data-name={minus} >
            -
        </span>
        <span className='inputshowValue'>
        <input onKeyDown={blockInvalidChar} onWheel={ event => event.currentTarget.blur() } onChange={onChangeBlocksHandler} style={{width:'50px'}} data-taprev="0" value={value} className='totalAvaWidget_hs removenumbercontrols' type="number" />
        </span>
        <span className="d-none justify-content-center align-items-center changeInputControlBtns"  onClick={clickHandler} data-name={plus} >
            +
        </span>
    </div>
  )
}
