import React from "react";
import { connect } from "react-redux";
import ModalHs from "../components/hireserver/ModalHs";
import beeImg from "../../images/hireserver/imgs/bee.png";
import ButtonHS from "../components/hireserver/controls/ButtonHS";
import { useDispatch } from "react-redux";
import { withRouter } from 'react-router'
import { closeAuthModal, loginAuthAlert, setErrAuthModal } from "../../store/actions/AuthAlertActions/AuthAlertActions";
import InputLabelUp from "../components/hireserver/controls/InputLabelUp/InputLabelUp";
import PasswordInputHS from "../components/hireserver/controls/PasswordInputHS";
function AuthAlertModal({ authAlertReducer, userEmail, authError, history, authSuccess }) {

  const [password, setPassword] = React.useState('');
  const [loading,setLoading] = React.useState(false);
  const [passwordErr, setPasswordErr] = React.useState({
    isErr: false,
    text: ''
  });
  const dispatch = useDispatch();
  //const history = useHistory();

  React.useEffect(function () {
    if (authError.isError) {
      // console.log("authEEEEEEEEOr")
      setTimeout(function () {
        dispatch(setErrAuthModal({
          isError: false,
          message: ''
        }))
      }, 3000);
    }
  }, [authError])

  function handleModalClose() {
    dispatch(closeAuthModal());
  }

  function handleDone() { }

  function formHandler(e) {
    e.preventDefault();

    let { href, origin } = window.location;
    let url = href.replace(origin, '');

    if (password == '') {
      setPasswordErr({
        isErr: true,
        message: 'Please enter your valid password'
      })
      setTimeout(function () {
        setPasswordErr({
          isErr: false,
          message: ''
        })
      }, 3000);
      return;
    }

    dispatch(loginAuthAlert(userEmail, password, history, url,setPassword,setLoading))
    //history.push('/')
    // login
  }


  return (
    <ModalHs
      backdrop="static"
      classNameMain="authModalAlert"
      handleClose={handleModalClose}
      okayHandler={handleDone}
      showModalHS={authAlertReducer}

      btnTitle="Stop Machine"
      isNeed={false}
    >
      <div className="w-100 text-center">
        <img className="redirectAuthLogo" alt="logo_hireserver" src={beeImg} />
        <h4>Your Session was been expired!</h4>
        <h4>Login to continue</h4>
        {authSuccess.isDone && <div className="text-primary">{authSuccess.message}</div>}
        {authError.isError && <div className="text-danger">{authError.message}</div>}
        {passwordErr.isErr && <div className="text-danger">Please Enter Valid Password</div>}
        <div className="py-2"></div>
        <form onSubmit={formHandler} className="authModalAlertForm px-lg-4">
          <div>
            <div className="position-relative">
              <div className="position-absolute hs_positionFull p-2 d-none justify-content-center align-items-center">
                <i className="fa fa-user text-primary font-21"></i>
              </div>
              {/* <input
            disabled 
              value={userEmail}
            //   onChange={(e) =>
            //     setEmail({
            //       val: e.target.value,
            //       error: false,
            //     })
            //   }
              type="email"
              className="p-4 pl-5 form-control hs_borderRadius-4"
              placeholder="Enter your email"
            /> */}

              <InputLabelUp placeholder="Email" setValue={() => {}} value={userEmail} id="emailAuth" type="text" disabled={true} />



            </div>


          </div>
          <div className="py-2"></div>
          <div className="position-relative">
            <div className="position-absolute hs_positionFull p-2 d-flex justify-content-center align-items-center">
              <i className="fa fa-lock text-primary font-21"></i>
            </div>
            {/* <input
              value={password.val}
              onChange={(e) =>
                setPassword(e.target.value)
              }
              type="password"
              className="p-4 pl-5  form-control hs_borderRadius-4"
              placeholder="Enter your email"
            /> */}
            {/* <InputLabelUp placeholder="Password" setValue={(e) =>
              setPassword(e.target.value)} value={password} id="emailAuth" type="password" /> */}

            <PasswordInputHS value={password} changeHandler={(e) =>
              setPassword(e.target.value)} placeholder={"Password"} labelStyles={` w-100`} />
          </div>
          <div className="py-2"></div>
          <ButtonHS
            styles={{
              borderRadius: "4px",
              padding: "10px 40px",
            }}

            type="submit"
          >
            {loading ? <React.Fragment>
                                    <div className="spinner-border text-light" role="status"></div> 
                                  </React.Fragment> : "Login"}
          </ButtonHS>
        </form>
      </div>
    </ModalHs>
  );
}

const mapStatesToProps = ({ authAlertReducer, auth }) => {
  return {
    authAlertReducer: authAlertReducer.isAlert,
    authError: authAlertReducer.authAlert_Error,
    userEmail: auth.auth.userEmail,
    authSuccess: authAlertReducer.authAlert_Success
  };
};

export default connect(mapStatesToProps)(withRouter(AuthAlertModal));
