import { getDocInstanceURL, getDueDateDataURL, getInvoicesProfileURL, getOrderMethodURL, orderRetryURL, profileOrderListURL } from "../../API_ENDPOINTS/PROFILE_ENDPOINTS";
import { API_SERVICES } from "../API_SERVICES";
import { getSessionAndUserId } from "../InstancesServices/InstancesListService";

export function profileOrderListService(orderId){
    const { userId, sessionId } = getSessionAndUserId();
    let url = profileOrderListURL(userId);

    let config = {
        method: 'get',
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        }
    };

    return API_SERVICES(config);
}

export function postOrderRetryService(orderId){
    const { userId, sessionId } = getSessionAndUserId();
    const url = orderRetryURL(orderId,userId);

    let config = {
        method: 'post',
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        }
    };

    return API_SERVICES(config);
}



export function getDocInstancesService(orderId){
    const { userId, sessionId } = getSessionAndUserId();
    const url = getDocInstanceURL(orderId);

    let config = {
        method: 'post',
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        }
    };

    return API_SERVICES(config);
}

export function getInvoicesProfileService(orderId){
    const { userId, sessionId } = getSessionAndUserId();
    const url = getInvoicesProfileURL(userId);

    let config = {
        method: 'get',
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        }
    };

    return API_SERVICES(config);
}

export function getOrderMethodService(orderId){
    const { userId, sessionId } = getSessionAndUserId();
    const url = getOrderMethodURL(userId);

    let config = {
        method: 'get',
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        }
    };

    return API_SERVICES(config);
}

export function getDueDateDataService(){
    const { userId, sessionId } = getSessionAndUserId();
    const url = getDueDateDataURL(userId);
    
    let config = {
        method: 'get',
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        }
    };

    return API_SERVICES(config);
}