import { getSessionAndUserId } from "./InstancesListService";
import axios from 'axios';
import https from 'https';
import { checkMachineStopURL, checkMachineURL, getScriptMarketListURL, postscriptmarketURL, updateMachineURL } from "../../API_ENDPOINTS/API_ENDPOINTS";
import { API_SERVICES } from "../API_SERVICES";

export function BootVm(url, methodType) {
    const { userId, sessionId } = getSessionAndUserId();

    const config = {
        method: methodType,
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        }
    };

    return API_SERVICES(config);

}

export async function updateCurrentMachine(machineidC, type) {

    const { userId, sessionId } = getSessionAndUserId();
    let url = updateMachineURL(machineidC, type);

    var config = {
        method: 'post',
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        }
    };

    return API_SERVICES(config);

}

export async function postscriptmarketService(payload) {

    const { userId, sessionId } = getSessionAndUserId();
    let url = postscriptmarketURL();

    var config = {
        method: 'post',
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        },
        data:{...payload,contactid:userId}
    };

    return API_SERVICES(config);

}

export async function getScriptMarketService(){
  
    
    const { userId, sessionId } = getSessionAndUserId();
    let url = getScriptMarketListURL(userId);

    var config = {
        method: 'get',
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        }
       
    };

    return API_SERVICES(config);
}



export async function checkMachineStop(taskid,node,loaction){
  
    const { userId, sessionId } = getSessionAndUserId();
 
    // console.log(taskid)
    // let url = checkMachineStopURL(code,id);
    let url = checkMachineURL(taskid,node,loaction);
    var config = {
        method: 'get',
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        }
    };

    return axios(config);

}