import { getBoundTypesURL, getFirewallListURL, getUserAllMachinesURL, postdeleteFirewallGroupURL } from "../../API_ENDPOINTS/FIREWALL_ENDPOINTS";
import { API_SERVICES } from "../API_SERVICES";
import { getSessionAndUserId } from "../InstancesServices/InstancesListService";

export function getFirewallListService(){
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'get',
        url:getFirewallListURL(userId),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
    };

    return API_SERVICES(config);
}

export function getUserAllMachinesFirewallService(){
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'get',
        url:getUserAllMachinesURL(userId),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
    };

    return API_SERVICES(config);
}

export function getBoundTypesService(){
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'get',
        url:getBoundTypesURL(),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
    };

    return API_SERVICES(config);
}

export function postdeleteFirewallGroupService(payload){
    
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'post',
        url:postdeleteFirewallGroupURL(),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
        data:payload
    };

    return API_SERVICES(config);
}