import React from 'react'
import ModalHs from '../../../../hireserver/ModalHs'
import ClusterFormContext from '../../clusterscontext/ClusterFormContext'

function AlertChangeLocation() {
    const {alertReplicaModal, alertReplicaModalOpen,
        alertReplicaModalClose} = React.useContext(ClusterFormContext);

        function handleClose(){
            alertReplicaModalClose();
           // console.log("hlloo");
        }
  return (
    <ModalHs isNeed={false} showModalHS={alertReplicaModal} handleClose={handleClose}>
       <div>
            <div>The location has already been chosen. Please increase the node count or select a different location</div>
            <div className='d-flex justify-content-end'><button onClick={handleClose} className='btn btn-danger hs_borderRadius-4 p-1 px-3'>Close</button></div>
       </div>
    </ModalHs>
  )
}

export default AlertChangeLocation