import React from 'react'

export default function StickyFooter({children}) {
  return (
    
    <div className='stickyfooter_hs d-none d-lg-block px-5 py-2'>
        <div className=' d-flex justify-content-between h-100 align-items-center pb-2'>
        
        {children}
    </div>
    </div>
  )
}
