import React from 'react'
import { useRouteMatch } from 'react-router-dom';
import { getBoundRulesService } from '../../../../../../services/FirewallServices/FirewallOverviewService';
import { checkResData, isJsonStringHelper } from '../../../../../../utils/Helpers';
import StaticFireWall from '../../../../../../utils/StaticsClasses.js/StaticFirewall';
import BackButton from '../../../../hireserver/BackButton';
import FirewallContext from '../FirewallForm/FirewallContext/FirewallContext';
import BasicDetails from '../FirewallForm/Sections/BasicDetails';
import FireFooter from '../FirewallForm/Sections/FireFooter';
import InBoundFire from '../FirewallForm/Sections/InBoundFire';
import OutBoundFire from '../FirewallForm/Sections/OutBoundFire';

function FirewallOverview() {
    const [rulesData,setRulesData] = React.useState([]);
    const { path, url, params:{firewallId} } = useRouteMatch();
    const {setOutboundData,setinboundData,setSelectMachineTag,setFireDescription,setSecurityGroupName,getFirewallGroups,getUserSelectFirewallIdMachines,getRulesDataFun,setInBoundDataFull,setDeleteMachines, setFirewallFormid,setMachinesTagSelect,setDeleteRules,  fetchBoundTypes} = React.useContext(FirewallContext);
    React.useEffect(function (){
        debugger;
        StaticFireWall.firewallEdit = true;
        getRulesDataFun(Number(firewallId));
        fetchBoundTypes();
        getFirewallGroups(Number(firewallId));
        getUserSelectFirewallIdMachines(firewallId);

        return () => {
          
            StaticFireWall.firewallEdit = false;
            // setSelectMachineTag([]);
            // setFireDescription('');
            // setSecurityGroupName('');
            // setinboundData({});
            // setOutboundData({});
            // setFirewallFormid(0);
            // setDeleteRules([]);
            // setDeleteMachines([]);
            // setInBoundDataFull([]);
            setSelectMachineTag([]);
            setFireDescription('');
            setSecurityGroupName('');
            setinboundData({});
            setOutboundData({});
            setFirewallFormid(0);
            setDeleteRules([]);
            setDeleteMachines([]);
            setInBoundDataFull([]);
            setMachinesTagSelect([]);
        }
    },[]);

    
  return (
    <div className='mb-5' style={{
        minHeight:'max-content',
        paddingBottom:300
    }}>
        {/* <div>
            <BackButton classesnames="justify-content-end" backUrl="/firewall" />
        </div> */}
        <BasicDetails/>
        {/* <SpacerHS_TWO/> */}
        <hr/>
        <InBoundFire/>
        <hr />
        <OutBoundFire/>
        <hr />
        <FireFooter/>
      
    </div>
  )
}

export default FirewallOverview