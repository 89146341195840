import React from 'react'
import { Dropdown } from 'react-bootstrap';

export default function CustomDropDown({ items,children,statusid,classNameForDom }) {
    function getDropDownItems() {

        return items?.map(function ({ title, Icon,handler,dataTags }, i) {
            return <Dropdown.Item active={statusid == i+1} {...dataTags} onClick={handler} key={i} className="text-dark">
                <span className='pointerEventNone'>
               {Icon && Icon}
                </span>
               <span className='pointerEventNone pl-1'> {title}</span>
            </Dropdown.Item>
        })
    }
    return (
        <Dropdown className={classNameForDom}>
            <Dropdown.Toggle variant className="table-dropdown i-false" >
                {children}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {getDropDownItems()}
            </Dropdown.Menu>
        </Dropdown>
    )
}
