import React from 'react'
import { justGetCurrencySymbol } from '../../../../../utils/Currency'
import { CURRENCY_FONT } from './CurrencyFont'

function JustCurrencyFC({name}) {
  return (
    <span  style={CURRENCY_FONT}>{justGetCurrencySymbol(name)}</span>
  )
}

export default JustCurrencyFC