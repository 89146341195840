import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { getActivityFeedService } from "../../../../../../../services/InstancesServices/InstancesListService";
import { connect } from "react-redux";
import { handlerAlert } from "../../../InstancesListComponents/DropDownInstancesList";

import { unixDateToNormalDate } from "../../../../../../../utils/Helpers";
import LoaderHS from "../../../../../hireserver/components/LoaderHS";
import { TABLE_ENTIRES } from "../../../../../../../utils/Emuns";
import TimeLine from "./ActivityFeedComponents.js/TimeLine";

function ActivityFeedTab({ currDetailsItem }) {
  const [loading,setLoading] = React.useState(false);
  const [headers, setHeaders] = React.useState([
    {
      label: "Start Date",
      field: "startdate",
      sort: "asc",
    },

    {
      label: "End Date",
      field: "enddate",
      sort: "asc",
    },

    // { label: "date", field: "date", sort: "asc" },
    // {
    //   label: "User Name",
    //   field: "username",
    //   sort: "asc",
    // },
    {
      label: "Description",
      field: "description",
      sort: "asc",
    },

    {
      label: "Status",
      field: "status",
      sort: "asc",
     // attributes: { className: "text-center" },
    },
  ]);
  const [formatData, setFormatData] = React.useState({
    columns: [],
    rows: [],
  });

  const [tableDate,setTableDate] = React.useState([]);

  // React.useEffect(function () {
  //   if (Object.keys(currDetailsItem).length === 0) return;
  //   let { vmid, branchid, hostname, createddate } = currDetailsItem;
  //   createddate = `${createddate}`.split("/");
  //   createddate = `${createddate[2]}/${createddate[1]}/${createddate[0]}`;
  //   createddate = Math.floor(new Date(createddate).getTime() / 1000);
  //   // // console.log(createddate)

  //   getActivityFeed(vmid, hostname, branchid, createddate);

  //   return () => {
  //     setLoading(false);
  //     setTableDate([]);
  //     setFormatData({});
  //   }
  // }, []);

  React.useEffect(function (){
    let rows = [];
    for(let i=0;i<tableDate.length;i++){
      const {starttime,endtime,node,id,user,status,type} = tableDate[i]
      let formatedDateStart = unixDateToNormalDate(starttime);
      let formatedDateEnd = unixDateToNormalDate(endtime)
      let newRow = {
        startdate:formatedDateStart,
        enddate:formatedDateEnd,
        // username:user,
        description:type,
        status:status,
      }
      rows.push(newRow);
    }

    setFormatData({
      columns:headers,
      rows
    })


  },[tableDate])

  async function getActivityFeed(vmid, node, location, since) {
    setLoading(true);
 
    let date = currDetailsItem?.createddate.split('/');
    date = `${date[1]/date[0],date[2]}`;
    date = Math.floor(new Date(date).getTime() / 1000)
    // '';
    
    const res = await getActivityFeedService(vmid, node, location, date);
    if (res.data.code != "0") {
      handlerAlert("danger", res.data.code, "danger");
      setLoading(false);
      return;
    }

    if (res.data.errors !== null) {
      handlerAlert("danger", res.data.errors[0], "danger");
      setLoading(false);
      return;
    }

    if (res.data.result === "") {
      handlerAlert("danger", "Empty Data", "danger");
      setLoading(false);
      return;
    }

    let tasksData = JSON.parse(res.data.result);
    setTableDate(tasksData.data);
    setLoading(false);
    // console.log(tasksData, "tasksDatatasksDatatasksDatatasksData");
  }

  if(loading){
    return <LoaderHS/>
  }

  return (
   <React.Fragment>
     {/* <div className="table-flow">
      <Card style={{ width: "100rem" }} className="detail-table">
        <ListGroup variant="flush">
          <ListGroup.Item className="p-0">
            <div>
              <MDBDataTable paginationLabel={['‹','›']}  searching={false}  disableRetreatAfterSorting={true}  entriesOptions={[1, 5, 20, 25]} entries={TABLE_ENTIRES}  data={formatData} />
            </div>
          </ListGroup.Item>
        </ListGroup>
      </Card>
    </div> */}
    <div>
      <TimeLine/>
    </div>
   </React.Fragment>
  );
}

const mapStateToProps = ({ instanceDetails }) => {
  return {
    currDetailsItem: instanceDetails.currDetailsItem,
  };
};
export default connect(mapStateToProps)(ActivityFeedTab);
{
  /* <div>
              <Table bordered className="mb-0">
                <thead className="bg-lightgrey-100">
                  <tr>
                    <th className="py-2 border">Start Time</th>
                    <th className="py-2 border">End Time</th>
                    <th className="py-2 border">User name</th>
                    <th className="py-2 border">Description</th>
                    <th className="py-2 border">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={3} className="text-center ">
                      No recent activity for this instance
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div> */
}
