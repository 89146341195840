import React from "react";
import { Link, withRouter } from "react-router-dom";
import HeadingHS from "../components/hireserver/controls/HeadingHS";
import ImageHs from "../components/hireserver/ImageHs";
import sleepBee from '../../images/hireserver/imgs/bee/fail.png';
import ButtonHS from "../components/hireserver/controls/ButtonHS";
import SpacerHS_TWO from "../components/hireserver/Spaces/SpacerHS_TWO";

const Error503 = (props) => {
   const [timer,setTimer] = React.useState(0);
   const timerRef = React.useRef(null);
   
   React.useEffect(function (){
      //setTimerExe();
   },[]);

   function setTimerExe(){
      let a = timer;
      const counterId = setInterval(function(){
         // if(timer === 10){
         //    props.history.push('/login');
         //    clearInterval(counterId);
         // }else{
         //    '';
         
         //    setTimer(a+1);
         // }

         let timerVal = parseInt(timerRef.current.getAttribute('data-timer'));
         if(timerVal >= 500) {
            clearInterval(counterId)
            window.location.pathname = '/'
            return;
         }
         setTimer(timerVal)
         //// console.log(timerVal);
         timerRef.current.setAttribute('data-timer',timerVal+1);
         
      },1000);
   }

  
   return (
      // <div className="authincation h-100 p-meddle">
      //    <div className="container h-100">
      //       {" "}
      //       <div className="row justify-content-center h-100 align-items-center">
      //          <div className="col-md-5">
      //             <div className="form-input-content text-center error-page">
      //                <HeadingHS title="Please try after sometime." as="h1" />
      //                <div ref={timerRef} data-timer={0}>
      //                    Something went wrong.
      //                </div>
      //                <p>Sorry, we are under maintenance!</p>
      //                <div>
      //                   <Link className="btn btn-primary" to="/login">
      //                      Back to Home
      //                   </Link>
      //                </div>
      //             </div>
      //          </div>
      //       </div>
      //    </div>
      // </div>
       <div className="w-100 h-100 d-flex justify-content-center align-items-center flex-column">
     
       <ImageHs width="200px" alt="404 Page Not Found" src={sleepBee} />
       <div className="text-center font-80 text-primary   ">
          No Network.  
       </div>
       <div>
         Sorry, we are under maintenance!   
       </div>
       <SpacerHS_TWO/>
   
       <Link className="btn hs-signin-btn  hs_borderRadius-4" to="/login">
                           Back to Login
                        </Link>
          
    </div>
   );
};

export default withRouter(Error503);
