import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { connect } from 'react-redux'
import { instancesSnapShotRemoveService, postInstanceSnapShotRollBackService } from '../../../../../../../../services/InstancesServices/InstancesListService';
import CustomDropDown from '../../../../../../hireserver/components/CustomDropDown'
import { handlerAlert } from '../../../../InstancesListComponents/DropDownInstancesList';
import {checkMachineStop} from '../../../../../../../../services/InstancesServices/InstanceCurrListNewDropdownService';
import { checkMachinePromise } from '../../../../../../../../services/API_SERVICES';
// src\services\InstancesServices\InstanceCurrListNewDropdownService.js

function DropDownSnapShot({currDetailsItem,name,setLoading,setSnapShotCurr,setRemoveModal}) {

    const [actionMachineData,setActionMachineData] = React.useState([{ title: 'Roll Back', handler: (e) => startHandler(e,1), dataTags: { 'data-machineaction': 1 } },
    { title: 'Remove', handler: (e) => startHandler(e,2), dataTags: { 'data-machineaction': 2 } },]);
    const [actionItemState,setActionItemState] = React.useState([]);

    

    React.useEffect(function (){
        setActionItemState(actionMachineData)
    },[]);


    async function rollBack() {
        if (Object.keys(currDetailsItem).length === 0) return;
        const { vmid, hostname, branchid } = currDetailsItem;
        try {
           
            setLoading(true);
            const res = await postInstanceSnapShotRollBackService(vmid, hostname, branchid, name);
            const {result,code,errors} = res.data;
            if(code != '0' || errors?.length >= 1){
                handlerAlert("danger",res.message,'danger');
            }

            if(result === ''){
                handlerAlert("danger",'The Result is empty','danger');
                return;
            }

            let resultData = JSON.parse(result)?.data;
            const isStop = await checkMachinePromise(resultData,hostname,branchid);
            // console.log(isStop);
            if(isStop.error != false){
                handlerAlert("danger",isStop.error.message,'danger');
                return;
            }

            

            
             handlerAlert("success","RollBack Added");
             setLoading(false);
            // // console.log(res,'Snapshot Created')
        } catch (error) {
            handlerAlert("danger",error.message,'danger');
            setLoading(false);
        }
    
        
    
    }

    async function removeSnapShot(){
        const { vmid, hostname, branchid } = currDetailsItem;
        let res = await instancesSnapShotRemoveService(vmid)
    }

    

    function startHandler(e,num){
       if(num == 1){
        rollBack();
        setSnapShotCurr(name);
       }else if(num == 2){
        setRemoveModal(true);
        setSnapShotCurr(name);
       }else{
        // console.log("Select Valid Option")
       }
    }

  return (
   <React.Fragment>
     <div>
         <CustomDropDown classNameForDom={`instacesClass listDropdownHS data-class-${currDetailsItem?.vmid}`} statusid={null} items={actionItemState} >
            <Dropdown.Toggle variant className="table-dropdown i-false" >
                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" >
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                    </g>
                </svg>
            </Dropdown.Toggle>
        </CustomDropDown>
       
    </div>
     
   </React.Fragment>
  )
}

const mapStateToProps = ({ instanceDetails }) => {
    return {
      currDetailsItem: instanceDetails.currDetailsItem,
    };
  };
export default connect(mapStateToProps)(DropDownSnapShot);