import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from "chart.js";
import { Line } from "react-chartjs-2";
import { getUnitsByClass } from "../../../../../../../../utils/Helpers";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function getOption(isUnits,units,isPer){

  return  {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        max:isPer?null:100,
        ticks: {
          // Include a dollar sign in the ticks
          callback: function(value, index, ticks) {
            if(isUnits){
              
            
              const getValuesSym = getUnitsByClass(value,units,index); 
              const isSym = getValuesSym?.sym === null ? '' : getValuesSym?.sym;
              return `${getValuesSym?.val} %`;
              // return `${getValuesSym?.val} ${isSym}`
              
            }else if(isPer){
              return value;
            }else{
              return `${value} %`;
              // return value
             
            }
          }
      }
      }
  
    }, elements: {
      line: {
        tension: 0.5,
      }
    },
    plugins: {
      legend: {
        position: "top",
        labels: {
          // color: "#24b7af",  
          
        }
      },
      title: {
        display: true,
        text: "",
      },
      // legend: {
      //   display: false
      // },
      scales: {
        xAxes: [{
          grid: {
            drawBorder: true,
            color: 'red',
          },
          ticks: {
            beginAtZero: true,
  
            color: 'red',
            fontSize: 12,
           
          }
        }],
        // yAxes: [{
         
        //   display: true,
        //   stacked: true,
        //   ticks: {
        //       min: 0, // minimum value
        //       max: 10 // maximum value
        //   }
        // }]
      }
    },
  
  };
  
}

export default function GraphHs({ dataOne, dataTwo, timeData, name1, name2, isUnits,units,isPer}) {
  const [data, setData] = React.useState({
    labels: timeData,

    datasets: [
      {
        label: name1,
        data: dataOne,
        borderColor: "rgba(75,192,192,1)",
        backgroundColor: "rgba(75,192,192,0.4)",
        fill: true,
        fontColor:'red'
      },
    
      // {
      //   label: "Dataset 2",
      //   data: [338, -926, -407, 221, -797, 529, -792],
      //   borderColor: "rgb(53, 162, 235)",
      //   backgroundColor: "rgba(53, 162, 235, 0.5)",
      // },
    ],
    
  })

  React.useEffect(function () {
    
    if (dataTwo === undefined) return;
    // let labels = data.labels
    // '';
    // if(timeData?.length>=1){
    //   labels = timeData;
    // } 
    let newConfig = {
      labels: data.labels,
      datasets: [...data.datasets, {
        label: name2,
        data: dataTwo,
        borderColor: "rgb(192,75,75,1)",
        backgroundColor: "rgb(192,75,75,0.4)",
        fill: true,
        rotation: 100,
      }],


    }
    setData(newConfig)
    // console.log(dataTwo)
  }, [dataTwo])
  return <Line className="hsGraph" width="400px" height="500px" options={getOption(isUnits,units,isPer)} data={data} />;
}

