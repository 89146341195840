import React from 'react';





import india from "./../../../../../images/hireserver/imgs/india.png";
import MiniCard from '../../../hireserver/SharedComponents/MiniCard/MiniCard';

import ClusterFormContext from '../clusterscontext/ClusterFormContext';
import MachineLocationsSkeleton from '../../../hireserver/components/Loaders/Machines/MachineLocationsSkeleton';
import NoDataCard from '../../../hireserver/SharedComponents/NoDataCard';
import { getSEVERNAME } from '../../../../../API_ENDPOINTS/API_ENDPOINTS';
import { Form } from 'react-bootstrap';
import FormInputError from '../../../hireserver/components/FormInputError';
import HeadingHS from '../../../hireserver/controls/HeadingHS';

function LocationClusterSection() {
  const { locations, locationState, onChangeLocation, submitErrors, locationDataLoading, locationRef } = React.useContext(ClusterFormContext);
  function getLocations() {

    // console.log(locations,'locations')


    // if(locations.length === 1 && locations[0] === 'loading'){
    //   return <LoaderHS/>
    // }

    // if (locationDataLoading) {
    //   return <div className="position-relative">
    //     <LoaderHS position="position-relative m-1" />
    //   </div>
    // }
    if (locationDataLoading) {
      return <MachineLocationsSkeleton times={3} />
    }
    if (locations.length === 0) {
      return <NoDataCard title="No Locations Available" />
    }



    return locations.map(function ({ branchname, branchid, icon, isNeedShow }, i) {

      let SelectText = '';
      let image = india;

      // console.log(locations)
      // // console.log(locations,'locations')
      // let isRequired = false;
      let isChecked = false;
      //// console.log(locationState,'locationState')
      //'';
      if (locationState == branchid) {
        // isRequired = true
        isChecked = true;
      }

      if (i == 0 && isNeedShow) {
        isChecked = true;
      }

      if (!isNeedShow) {
       // console.log(branchname, 'isNeedShowisNeedShowisNeedShowisNeedShowisNeedShowisNeedShowisNeedShowisNeedShow');
      }

      return (
        <MiniCard
          isRequired={false}
          isChecked={isChecked}
          handler={onChangeLocation}
          key={i + branchid + Math.random() * 9}
          OSImage={`${getSEVERNAME()}\\` + icon?.replace('~', '')}
          id={branchid}
          name="locationInstances"
          //   cardName={'branchname'}
          nextname={branchname}
          groupname={SelectText}
          isNeedShow={Boolean(isNeedShow)}
        />
      );
    });
  }


  return (
    <Form.Group className='m-0' ref={locationRef}>
      <Form.Text>
        <Form.Text className='d-flex my-2'> <HeadingHS as="h3 text-primary" title={'Locations'} />  </Form.Text>
      </Form.Text>

      <div className='align-items-start d-flex d-gap-15 flex-wrap justify-content-center justify-content-lg-start optionLocationhandler'>
        {getLocations()}
      </div>
      {submitErrors.locationErr && <FormInputError message="No available locations" />}
    </Form.Group>
  )
}

export default React.memo(LocationClusterSection);
