import React from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getPaymentWalletAmountService, postAddToWalletService } from "../../../../../services/paymentServices/PaymentService";
import { openPaymentInfoFormAction } from "../../../../../store/actions/PaymentInfoActions/PaymentInfoAction";
import { putRedirectLinkAction, showPagementGateWayAction } from "../../../../../store/actions/RedirectPageActions/RedirectPageAction";
import { getCurrencySymbol, justGetCurrencySymbol } from "../../../../../utils/Currency";
import { checkResData } from "../../../../../utils/Helpers";
import StaticPaymentDetails from "../../../../../utils/StaticsClasses.js/StaticPaymentDetails";
import LoaderHS from "../../../hireserver/components/LoaderHS";
import ButtonHS from "../../../hireserver/controls/ButtonHS";
import HeadingHS from "../../../hireserver/controls/HeadingHS";
import ModalHs from "../../../hireserver/ModalHs";

function BillingInfoTab({ setActiveToggle, paymentData, userDetails, activeToggle }) {
  const [loading, setLoading] = React.useState(true);
  const [walletValue, setWalletValue] = React.useState(null);
  const [walletDetails, setWalletDetails] = React.useState(null);
  const [addWalletPaymentValue, setAddWalletPaymentValue] = React.useState(null);
  const [showAddAmountModal, setShowAddAmountModal] = React.useState(false);
  const [loadingAddAmount, setLoadingAddAmount] = React.useState(false);

  const dispatch = useDispatch();
  React.useEffect(function () {
    getWalletInfo();
    return () => {
      setLoadingAddAmount(false);
      StaticPaymentDetails.isEditing = false
    }
  }, []);

  async function getWalletInfo() {

    let res = await getPaymentWalletAmountService();
    let isValid = checkResData(res, true)
    if (isValid === false) {
      setWalletValue(0);
      setLoading(false);
      return;
    }

    let resJSON = JSON.parse(res.data.result)[0];
    if(!resJSON){
      setWalletValue(0);
     setLoading(false);
      return;
    }
 
    setWalletValue(resJSON?.walletvalue);
    setWalletDetails(resJSON);
    setLoading(false);
  }

  if (loading) {
    return <LoaderHS />
  }
  function editProfileHandler() {
    StaticPaymentDetails.isEditing = true;
    dispatch(openPaymentInfoFormAction());
  }


  async function handlerAddAmountToWalletSubmit() {
    setLoadingAddAmount(true);
    const payload = {
      "orderamount": addWalletPaymentValue,
      "currencytypeid": paymentData?.currencyid,
      "contactid": userDetails?.userId,
      "walletid": walletDetails?.walletid,
      "iswalletcredit": true
    }

 

    const res = await postAddToWalletService(payload);

    if (res.data.code != '0') {

      return;
    }


    handleCloseAddAmount();
    const paymentLinkJSON = JSON.parse(res.data.result);
    const paymentlink = paymentLinkJSON?.paymentlink;
    dispatch(putRedirectLinkAction(paymentlink));
    dispatch(showPagementGateWayAction());

  }

  function handleCloseAddAmount() {
    setShowAddAmountModal(false);
  }

  return (
    <React.Fragment>
      <div className="h-100">
        <div className=" h-100 pt-0">
          <div className="row h-100 w-100 m-0">
            <div className="col-12 d-none">
              <div className="  p-0">
                <div className="rounded-sm border mb-3 px-3 mr-md-2">
                  <div className="bg-transparent border-bottom d-flex justify-content-between px-0 py-3 align-items-center">
                    <h5 className="m-0">Account Balance</h5>
                    <button className="btn-sm hs-signin-btn hs_borderRadius-4 border-0" onClick={() => setShowAddAmountModal(true)} >Add Amount</button>
                  </div>
                  <div className="justify-content-between px-0 py-3 text-success d-flex">
                    <div>Credit</div>
                    <div>{getCurrencySymbol(paymentData?.currencyname, walletValue)}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" col-md-6 p-0 d-none">
              <div className="rounded-sm border mb-3 px-3 ml-md-2">
                <div className="py-3 bg-transparent px-0 border-bottom">
                  <h5>Accrued Charges</h5>
                </div>
                <div className="d-flex justify-content-between px-0 py-3">
                  <div className="text-darkgrey font-14">Since last invoice</div>
                  <div>$3.46</div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="h-100  p-0">
                <div className="rounded-sm border mb-3 px-3 h-100 mr-md-2">
                  <div className="card-header bg-transparent pb-0 px-0 border-0 d-flex justify-content-between">
                    <h5>Billing Contact</h5>
                    <h6 onClick={editProfileHandler} className="text-blue cursor-pointer">Edit</h6>
                  </div>
                  <div className=" justify-content-between flex-column px-0 row m-0 pb-3">
                    <div className=" billingpContainer pl-0 text-darkgrey font-14 w-100">
                      {/* <p className="">Name: {userDetails?.name}</p> */}
                      {paymentData?.contactpersonname && 
                      <p className="border-top">Contact Person : {paymentData?.contactpersonname}</p>}
                      {paymentData?.organizationname && !paymentData?.contactpersonname && 
                      <p  className="border-bottom">Contact Person : {paymentData?.organizationname}</p>}
                      {paymentData?.organizationname && paymentData?.contactpersonname && 
                      <p  className="border-bottom">Company Name : {paymentData?.organizationname}</p>}
                      <p  className="border-bottom">Country: {paymentData?.countryname}</p>
                      <p  className="border-bottom">City: {paymentData?.cityname} </p>
                      <p  className="border-bottom"><span>Address: {paymentData?.addressline}</span></p>

                    </div>
                    <div className=" text-darkgrey billingpContainer font-14 text-break pl-0">
                      {paymentData?.gstinno && <p className="border-bottom">Tax number: {paymentData?.gstinno}</p>}
                      <p className="border-bottom">{userDetails?.userEmail}</p>
                      <p className="border-bottom">{paymentData?.phonenumber}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" col-md-6 p-0 d-none">
              <div className="rounded-sm border mb-3 px-3 ml-md-2">
                <div className="card-header bg-transparent px-0 border-0 d-flex justify-content-between">
                  <h5>Payment Methods</h5>
                  <h6 className="text-blue">Add Payment Method</h6>
                </div>
                <div className=" d-flex justify-content-between px-0 border">
                  <div className="p-2 text-darkgrey font-14">
                    {" "}
                    <i className="fa"></i> Visa ****3871 Expires 10/23{" "}
                    <span>Default</span>
                  </div>
                </div>
                <div className="card-body d-flex justify-content-between px-0 font-14 text-darkgrey">
                  <p>
                    {" "}
                    <i className="fa fa-paypal text-blue"></i> PayPal is now
                    available for recurring payments.
                    <Link to="#">Add PayPal.</Link>
                  </p>
                  <i className="fa fa-"> </i>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <ModalHs handleClose={handleCloseAddAmount} isNeed={false} showModalHS={showAddAmountModal}>
        <div className="d-flex flex-column d-gap-5">
          <HeadingHS as="h3" title="Add to wallet" />
          <div>
            <span> Current wallet balance</span> <span className="mx-1">:</span> <span className="text-primary">{getCurrencySymbol(paymentData?.currencyname, walletValue)}</span>
          </div>
          <div>
            <div className="d-flex flex-column d-gap-10">
              <div>
                Add amount to wallet
              </div>
              <div className="position-relative font-21">
                <span className="position-absolute align-items-center d-flex h-100 pl-2">
                  {justGetCurrencySymbol(paymentData?.currencyname)}
                </span>
                <input value={addWalletPaymentValue} onInput={(e) => setAddWalletPaymentValue(e.target.value)} type="number" className="form-control pl-4 hs_borderRadius-4 font-21" />
              </div>
              <div>
                <ButtonHS classes="px-2 py-2 w-200p" handler={handlerAddAmountToWalletSubmit} >

                  {loadingAddAmount ? <React.Fragment>
                    <div className="spinner-border text-light" role="status">

                    </div> Proceed to add
                  </React.Fragment> : 'Proceed to add'}
                </ButtonHS>
              </div>
            </div>
          </div>
        </div>
      </ModalHs>
    </React.Fragment>
  );
}


const mapStatesToProps = ({ auth, PaymentInfoReducer }) => {
  return {
    userDetails: auth.auth,
    isShowPayment: PaymentInfoReducer?.paymentData?.defaultaddressid,
    currencyname: PaymentInfoReducer?.paymentData?.currencyname,
    paymentData: PaymentInfoReducer?.paymentData,

  };
};
export default connect(mapStatesToProps)(BillingInfoTab);
