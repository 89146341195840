import React from 'react'

function TokensShow({tokens}) {
  return (
    <div className='hs_borderRadius-4'>{
        <React.Fragment>
            {tokens.map(function ({key,value},index){
                return <div className={`row ${index %2==0?'bg-dark-alt':'bg-light '}`}>
                    <div className="col-6 font-w600">{key}</div>
                    <div className="col-6 text-primary font-w600">{value}</div>
                </div>
                return <li>{key} <span className="mx-1"></span>{value}</li>
            })}
        </React.Fragment>
        }</div>
  )
}

export default TokensShow