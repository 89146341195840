import React from 'react'
import { SEVERNAME } from '../../../../../../API_ENDPOINTS/API_ENDPOINTS';
import { imageReplaceHashHelper } from '../../../../../../utils/Helpers';
import EmptyBee from '../../../../hireserver/components/Empty/EmptyBee';
import HeadingHS from '../../../../hireserver/controls/HeadingHS';
import MiniCard from '../../../../hireserver/SharedComponents/MiniCard/MiniCard';
import SpacerHS_TWO from '../../../../hireserver/Spaces/SpacerHS_TWO';
import InstancesFormContext from '../../InstancesContext/InstancesFormContext';
import DropdownOs from './DropdownOs';
import RenderOs from './RenderOs';

function InstanceFormDatabase({ setMarketPlacesItemId,databasesList,setSelectMarketPlace, setDatabasesList, marketplaceStateid, setMarketPlaceStateId, setSelectOsHandler, selectOsDB, setSelectOsDB, handleOsClickDB}) {
    // const { databasesList, setDatabasesList, marketplaceStateid, setMarketPlaceStateId, setSelectOsHandler, selectOsDB, setSelectOsDB, handleOsClickDB } = React.useContext(InstancesFormContext);
    function clickHandler(e, a, osfilename, marketplaceid, OSImage, groupname) {
        let defaultOs = null;
        for (let i = 0; i < databasesList.length; i++) {
            let currItem = databasesList[i];
            if (currItem.marketplaceid === marketplaceid) {
                setMarketPlaceStateId(currItem);
                const osData = JSON.parse(currItem?.osobj);
                defaultOs = osData[0];
         
            }
        }

        setSelectOsHandler({
            name: "",
            type: "win10",
            osfilename: '',
            idValue: '0',
            image: '',
            groupname: ''
        });
        setSelectMarketPlace({
            name: "",
            type: "win10",
            osfilename: '',
            idValue: '0',
            image: '',
            groupname: ''
        });

        setMarketPlacesItemId(0);
        setSelectOsDB({


            name: defaultOs?.OperatingSystemName,
            type: "win10",
            osfilename: defaultOs?.OSFileName,
            id: defaultOs?.OperatingSystemId,
            image: defaultOs?.OsIconPath,
            groupname: defaultOs?.GroupName
        })


    }




    function preProcessGroup(data) {
        //// console.log(data,'ossssssssssssssssssssssssssssss');
        let processData = [];
        let hashObj = {};

        for (let i = 0; i < data.length; i++) {
            let currItem = data[i];
            if (!hashObj[currItem.appname]) {
                hashObj[currItem.appname] = [currItem];
            } else {
                hashObj[currItem.appname].push(currItem);
            }
        }

       
        return hashObj;
    }
    function getDatabasesHTML() {
 
        if (databasesList.length === 0) {
            return <EmptyBee message="No Databases" />;
        }

        const processData = preProcessGroup(databasesList);

        const htmlDB = [];
        for (let DB in processData) {
            const { appname, icon, os, os_text, osobj, type } = processData[DB][0];
            const osArr = os.split('|');
            const osTextArr = os_text.split('|');
            const ids = [];
            let checkIs = false;
            // for (let i = 0; i < osArr.length; i++) {
            //     ids.push({ nameV: osTextArr[i], id: osArr[i] });
            // }

            const DBVersionArr = processData[DB];
            for (let i = 0; i < DBVersionArr.length; i++) {
                ids.push({ nameV: DBVersionArr[i]['appversion'], id: DBVersionArr[i]["marketplaceid"] });
               
                if (marketplaceStateid["marketplaceid"] == DBVersionArr[i]?.marketplaceid) {
                    checkIs = true;
                }
            }


            htmlDB.push(<MiniCard
                isChecked={checkIs}
                key={icon}
                groupname={appname}
                name={"databases"}
                dataName={appname}
                isDropdown
                dropDownList={ids}
                id={DBVersionArr[0]["marketplaceid"]}
                osfilename={appname}
                otherattr={{ 'data-type': type }}
                cardName={appname}
                handler={clickHandler}
                OSImage={SEVERNAME + `/${icon}`}
                nextname={null}
            />)
        }
        return htmlDB;
    }

    const handleDatabaseUnderOS = React.useCallback((item)=>{
     
        if(item === null){
            setSelectOsDB({
                name:'',
                type:'',
                osfilename: '',
                id: '',
                image: '',
                groupname:''
            });
            return;
        }
        const {GroupName,OperatingSystemName,OperatingSystemId,OSFileName,OsType,OsIconPath} = item;
   
        setSelectOsDB({
            name:OperatingSystemName,
            type:OsType,
            osfilename: OSFileName,
            id: OperatingSystemId,
            image: OsIconPath,
            groupname:GroupName
        });
    },[]);


    return (
        <React.Fragment>

            <div className='text-center row d-gap-15 m-0 justify-content-lg-start justify-content-center'>
                {getDatabasesHTML()}

            </div>
            {<React.Fragment>
                <SpacerHS_TWO />
               
                <HeadingHS as="h3 text-primary" title="Choose a Distribution" />
                <SpacerHS_TWO />
            </React.Fragment>}
            {/* {marketplaceStateid != 0 && <React.Fragment>
                <SpacerHS_TWO />
          
                <HeadingHS as="h3" title="Choose a Distribution" />
                <SpacerHS_TWO />
            </React.Fragment>} */}
            {/* <RenderOs handleOsClickDB={handleOsClickDB} itemData={marketplaceStateid} selectOsDB={selectOsDB} /> */}
            {/* <SpacerHS_TWO /> */}
            <DropdownOs  handler={handleDatabaseUnderOS} itemData={marketplaceStateid} selectOsDB={selectOsDB} />

        </React.Fragment>
    )
}

export default InstanceFormDatabase