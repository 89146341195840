import React, { Fragment } from "react";
import { useRouteMatch } from "react-router-dom";
import RouteCustomTabs from "../../../hireserver/Tabs/RouteCustomTabs";
import { connect } from "react-redux";
import HeadingHS from "../../../hireserver/controls/HeadingHS";
import SpacerHS_TWO from "../../../hireserver/Spaces/SpacerHS_TWO";
import { useDispatch } from "react-redux";
import { setDetailInstances } from "../../../../../store/actions/InstancesActions/InstanceDetailsActions";
import { getInstanceDetailsService } from "../../../../../services/InstancesServices/InstancesListService";
import SnapShotTab from "./InnerComponents/tabsInstances/SnapShotTab";
import LoaderHS from "../../../hireserver/components/LoaderHS";
import DetailsActionsList from "./DetailsActions/DetailsActionsList";
import StatusShow from "../InstancesListComponents/StatusShow";
import StaticDetails from "../../../../../utils/StaticsClasses.js/StaticDetails";
import { CONFIGURE_STAGE, CREATE_STAGE, PREDELETE_STAGE, PRESTART_STAGE, PRESTOP_STAGE, REBOOT_STAGE, REFRESH_TIMER, REINSTALL_STAGE } from "../../../../../utils/Emuns";
import { openModalRenewalPayment } from "../../../../../store/reducers/RenewalPaymentReducer/RenewalPaymentReducer";
import ImageHs from "../../../hireserver/ImageHs";

import { imageReplaceHashHelper } from '../../../../../utils/Helpers';
import BackButton from "../../../hireserver/BackButton";
import { isMobile } from "react-device-detect";
import { openDueTableRenewalAction, putDueTableDataAction } from "../../../../../store/actions/renewalActions/renewalActions";




const Vmdetails = ({ currDetailsItem,backUrl, tabsContent, tabsHeaders,isActionRequired=true }) => {
  const { path, url, params: { vmcode } } = useRouteMatch();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  const [cancelAll, setCancelAll] = React.useState(false);

  const [payLoading, setPayLoading] = React.useState(false);



  // React.useEffect(function (){
  //   '';
  //   let len = (Object.keys(currDetailsItem).length);
  //   if(len === 0){
  //     let data = instancesList?.find(function(el,i){
  //       return el.code == vmcode
  //     });
  //     // console.log(data,'dojdasiodioadataojdojiojioasdatadatadatadata')

  //     dispatch(setDetailInstances({...data}))

  //   }
  //   //// console.log(typeof currDetailsItem);

  // },[instancesList]);

  async function callInstanceData() {

   
    const data = await getInstanceDetailsService(vmcode);
    let res = '';
   
    if (data?.data.result != null && data?.data.result != '') {
      res = JSON.parse(data.data?.result)[0];
      
      res['loadingInfo'] = {
        loading: false,
        statusId: res.statusid,
        text: '',
        color: '',
        prev: "",
      }
      res['code'] = res.vmid;
      //res['code'] =res.VMID;
      res['machineid'] = res.machineid;
      //res['machinename'] =res['machinename'];
      res['regionname'] = res['branchname'];
      //res['machinetype'] = '2';

      if (res['stagename'] === 'Stopped') {
        res['statusid'] = 2;
      } else if (res['stagename'] === 'Running') {
        res['statusid'] = 1;
      } else {
        res['statusid'] = res.refstatusid;
      }
    }

    const refStatusId = res?.refstatusid;
    let isTimerNeed = false;
    if (refStatusId === PRESTART_STAGE || refStatusId === CONFIGURE_STAGE || refStatusId === REINSTALL_STAGE || refStatusId === CREATE_STAGE || refStatusId === PRESTOP_STAGE || refStatusId === PREDELETE_STAGE || refStatusId === REBOOT_STAGE) {
      isTimerNeed = true;
    }

    if (isTimerNeed && !StaticDetails.isUnmounted) {
      if (StaticDetails.instanceDetailsTimer) {
        clearTimeout(StaticDetails.instanceDetailsTimer)
        let newTimer = setTimeout(function () {
          // console.log("running")
          callInstanceData();
        }, REFRESH_TIMER)
        StaticDetails.instanceDetailsTimer = newTimer;
      } else {
        let newTimer = setTimeout(function () {
          // console.log("running")
          callInstanceData();
        }, REFRESH_TIMER)
        StaticDetails.instanceDetailsTimer = newTimer
      }
    }



    //dispatch(setDetailInstances(res)) 
    dispatch(setDetailInstances(res))
    setLoading(false);
    // console.log(res, 'getInstanceDetailsService')
  }

  React.useEffect(function () {
    // CatchAsync(callInstanceData());
    callInstanceData();

    return () => {
      StaticDetails.isUnmounted = true;
    }
  }, []);



  // const tabsHeaders = React.useMemo(()=>{
  //   // console.log('tabsHeaders')
  //   return [{
  //     name:'Overview',
  //     link:'overview',
  //     id:9999
  //   },{
  //     name:'Network',
  //     link:'network',
  //     id:9991
  //   },
  //   {
  //     name:'Backups',
  //     link:'backups',
  //     id:9994
  //   },{
  //     name:'Activity Feed',
  //     link:'activityfeed',
  //     id:9995
  //   },{
  //     name:'Settings',
  //     link:'settings',
  //     id:9996
  //   },
  //   {
  //     name:'Snap Shot',
  //     link:'snapshot',
  //     id:9997
  //   },
  // ]
  // },[]);
  // const tabsContent = React.useMemo(()=>{
  //   return [{
  //     component:<Overview/>,
  //     name:'overview',
  //     id:1000,
  //   },{
  //     component:<NetworkTab/>,
  //     name:'network',
  //     id:2000,
  //   },
  //   {
  //     component:<BackupsTab/>,
  //     name:'backups',
  //     id:5000
  //   },{
  //     component:<ActivityFeedTab/>,
  //     name:'activityfeed',
  //     id:6000,
  //   },{
  //     component:<SettingsTab/>,
  //     name:'settings',
  //     id:7000,
  //   },,{
  //     component:<SnapShotTab/>,
  //     name:'snapshot',
  //     id:8000,
  //   }]
  // },[]);

  // ,{
  //   name:'Storage',
  //   link:'storage',
  //   id:9992
  // },{
  //   name:'Configurations',
  //   link:'configurations',
  //   id:9993
  // },

  // ,{
  //   component:<StorageTab/>,
  //   name:'storage',
  //   id:3000,
  // },{
  //   component:<ConfigurationsTab/>,
  //   name:'configurations',
  //   id:4000,
  // },

  if (loading) {
    return <LoaderHS />
  }

  function handlerPayment() {
    //   setPayLoading(true);
    //  const res = await postPayRenewalService(currDetailsItem?.machineid);
    //  console.log(res,'paycurrDetailsItem');
    dispatch(openDueTableRenewalAction());

    const {noofblocks,tenuretypename,operatingsystemname,groupname,totalamount,machinename,osiconpath,icon,branchname,machineid,duedate
    } = currDetailsItem
    let obj = {
      "machineid": machineid,
      "duedate": duedate,
      totalamount:totalamount,
      "machinename": machinename,
      "noofblocks":noofblocks,
      // "duedays": 18,
      "tenuretypename": tenuretypename,
      "publicip": null,
      "branchname": branchname,
      "groupname": groupname,
      "operatingsystemname": operatingsystemname,
      "osiconpath": osiconpath,
      "icon": icon
  }
    dispatch(putDueTableDataAction(obj));

   // dispatch(openModalRenewalPayment());
  }

  return (
    <Fragment>
      <div className="w-100">
      <div className="d-block d-md-none">
        {/*  */}
            <BackButton  backUrl={backUrl} classesnames="h-100" />
           
            </div>
        <div className="align-items-center border-top d-flex d-gap-5 justify-content-between overviewMachineDetails pt-4 w-100">
       
          <div className="d-flex justify-content-between overviewMachineDetailsChild d-gap-10 flex-column flex-md-row w-100">
            <div className="d-none d-md-block">
            <BackButton  backUrl={backUrl} classesnames="h-100" />
           
            </div>
            <div className="d-flex p-0 px-md-2 justify-content-center justify-content-md-start align-items-center d-gap-10 w-100">
              <div>
                <ImageHs width="30px" src={imageReplaceHashHelper( currDetailsItem?.marketplaceicon ?currDetailsItem?.marketplaceicon :currDetailsItem?.osiconpath)} />
              </div>
              <HeadingHS fontSize="30px" title={currDetailsItem?.machinename} as="h1" />
              <div>
                <StatusShow machineid={currDetailsItem.machineid} isDetails={true} loadingInfo={currDetailsItem?.loadingInfo} isColor={currDetailsItem?.loadingInfo?.color} isText={currDetailsItem?.loadingInfo.text} isSpinning={currDetailsItem?.loadingInfo?.loading} statusid={currDetailsItem?.refstatusid} />
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="d-flex d-gap-30 nextDueBtnDiv hs_text-light flex-column flex-md-row align-items-center">
                {/* {currDetailsItem?.publicip ? <div>{currDetailsItem?.publicip}</div> : <div>{currDetailsItem?.privateip}</div>} */}
                {/* <div>{currDetailsItem?.branchname}</div> */}
                <div className="d-flex justify-content-center align-items-center">Next Date: {currDetailsItem?.duedate?.split('T')[0]}</div>
                <button onClick={handlerPayment} className="btn-sm  border-0 hs_borderRadius-4 hs-signin-btn py-1 cursor-pointer w-100p">{payLoading ? <React.Fragment>
                  <div className="spinner-border text-light" role="status">

                  </div> Renewal
                </React.Fragment> : 'Renewal'}</button>
              </div>

            </div>
          </div>
          
          {isActionRequired && <div className={`d-flex d-md-block align-items-center justify-content-between ${isMobile?'w-100 px-4':''}`}>
           
            <div className={`d-flex listactioncontainer  ${currDetailsItem?.consoleDisabled ? 'pointerEventNone' : ''} d-gap-10 ${currDetailsItem?.consoleDisabled && 'hs_text-light'}`}>
              <DetailsActionsList isSpinning={currDetailsItem?.loadingInfo?.loading} statusid={currDetailsItem?.refstatusid} cancelAll={cancelAll} setCancelAll={cancelAll} item={currDetailsItem} />
            </div>
          </div>}
        </div>

      </div>
      <SpacerHS_TWO />
      <div className="running_tabs">
        <RouteCustomTabs tabsContent={tabsContent} tabHeaders={tabsHeaders} parentPath={url} activeTab="overview" />
      </div>

    </Fragment>
  );
};


const mapStateToProps = ({ instanceDetails }) => {
  return {
    currDetailsItem: instanceDetails.currDetailsItem,

  }
}
export default connect(mapStateToProps)(Vmdetails);
