import React from 'react'
import { Form } from 'react-bootstrap'
import HeadingHS from '../../../../hireserver/controls/HeadingHS'
import NodeBalanceSettingsCard from './NodeBalanceSettingsCard'

export default function NodeBalanceSettings() {
    const [NodeBalanceSettingsCount,setNodeBalanceSettingsCount] = React.useState(1);
    
    function handleClickClose(){
        setNodeBalanceSettingsCount(NodeBalanceSettingsCount+1)
    }

    function getNodebalancesSettingCards(){
        let nodesSetting = [];
        for(let i=0;i<NodeBalanceSettingsCount-1;i++){
            nodesSetting.push(<NodeBalanceSettingsCard removeButton={true} key={i} />)
        }
        return nodesSetting;
    }

  return (
    <Form.Group>
        <HeadingHS as="h3" title="NodeBalancer Settings" />
        <NodeBalanceSettingsCard key={4545} />
        {getNodebalancesSettingCards()}
        <hr />
        <button className='btn btn-outline-primary' type='button' onClick={handleClickClose}>
            Add Other Configuration
        </button>
    </Form.Group>
  )
}
