import React from 'react'
import beetry from '../../../../images/hireserver/imgs/bee/fail.png';
import SpacerHS_TWO from '../../hireserver/Spaces/SpacerHS_TWO';
function ErrorFallBackComponent({error, resetErrorBoundary}) {
  return (
    <div className='text-center'>
      <img src={beetry} alt="Something went wrong" />
      <SpacerHS_TWO/>
      <h3>Something went wrong. Please try again.</h3>
    </div>
  )
}

export default ErrorFallBackComponent