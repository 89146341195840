class StaticActionAlert{
    static actionIds = {};

    static cleanActionIds(id){
        let newActionIds = {};
        for(let item in this.cleanActionIds){
           
            if(id != item){
                newActionIds[item] = this.cleanActionIds[item];
            }
        }

        this.actionIds = newActionIds;
    }
};

export default StaticActionAlert;