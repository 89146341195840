import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Offcanvas from './Offcanvas';
import FirewallInboundform from "./FirewallInboundform";
import Firewalloutboundform from "./Firewalloutboundform";
// import InputBoundFire from "./FirewallForm/Sections/InputBoundFire";
// import OutputBoundFire from "./FirewallForm/Sections/OutputBoundFire";
import SpacerHS_TWO from "../../../hireserver/Spaces/SpacerHS_TWO";

export default function Firewallrules() {
    const [hs_modal,setHsModal] = React.useState(false);
    const [modalComponent,setModalComponent] = React.useState(null);
    const [titleForm,setTitleForm] = React.useState('Add an Inbound Rule');
    function handleClick(e){
        let name = e.target.getAttribute('data-name');
        if(name === 'inbound'){
            setModalComponent(<FirewallInboundform setHsModal={setHsModal}/>);
            setTitleForm('Add an Inbound Rule')
        }else{
            setModalComponent(<Firewalloutboundform setHsModal={setHsModal}/>);
            setTitleForm('Add an outbound Rule')
        }
        setHsModal(true);
    }
    return (
        // <div className="firewall-rule">
        //     <div className="inbound-rule">
        //         <div className='row justify-content-between align-items-center px-3 pb-2'>
        //         <div className='fs-16 font-w600 text-dark-0'>Inbound Rules</div>
        //         <div className='d-flex justify-content-around'>
        //             <div>
        //             <button className="border-0 btn-hslineargradient p-2 px-4 rounded-0" data-name="inbound" onClick={handleClick}>Add an Inbound Rule</button>
        //             </div>
        //         </div>
        //         </div>
        //         <div className='table-flow'>
        //             <Table responsive bordered>
        //             <thead className="bg-lightgrey-100">
        //                 <tr>
        //                 <th className="border py-2">Label</th>
        //                 <th className="border py-2">Protocol</th>
        //                 <th className="border py-2">Port Range</th>
        //                 <th className="border py-2">Sources</th>
        //                 <th className="border py-2">Action</th>
        //                 </tr>
        //             </thead>
        //             <tbody>
        //                 <tr>
        //                 <td colSpan={5} className='text-center text-dark-0 fs-14'>No inbound rules have been added.</td>
        //                 </tr>
        //             </tbody>
        //             </Table>
        //             <div>
        //                 <Card style={{ width: "100rem" }} className="detail-table">
        //                 <ListGroup variant="flush">
        //                     <ListGroup.Item>
        //                     <div className="align-items-center">
        //                         <div className="text-center">
        //                             <p className="fs-14">
        //                                 <span className="font-w500 fs-16 text-darkgrey">Default inbound policy:</span> This policy applies to any traffic not covered by th inbound rules listed above.&nbsp;&nbsp;
        //                                 <select className="p-1 text-black-50">
        //                                     <option selected>Accept</option>
        //                                 </select>
        //                             </p>
        //                         </div>
        //                     </div>
        //                     </ListGroup.Item>
        //                 </ListGroup>
        //                 </Card>
        //             </div>
        //         </div>
        //     </div>
        //     <div className="outbound-rule pt-2">
        //         <div className='row justify-content-between align-items-center px-3 pb-2'>
        //         <div className='fs-16 font-w600 text-dark-0'>Outbound Rules</div>
        //         <div className='d-flex justify-content-around'>
        //             <div>
        //             <button className="border-0 btn-hslineargradient p-2 px-4 rounded-0" data-name="outbound" onClick={handleClick}>Add an Outbound Rule</button>
        //             </div>
        //         </div>
        //         </div>
        //         <div className='table-flow'>
        //             <Table responsive bordered>
        //             <thead className="bg-lightgrey-100">
        //                 <tr>
        //                 <th className="border py-2">Label</th>
        //                 <th className="border py-2">Protocol</th>
        //                 <th className="border py-2">Port Range</th>
        //                 <th className="border py-2">Sources</th>
        //                 <th className="border py-2">Action</th>
        //                 </tr>
        //             </thead>
        //             <tbody>
        //                 <tr>
        //                 <td colSpan={5} className='text-center text-dark-0 fs-14'>No outbound rules have been added.</td>
        //                 </tr>
        //             </tbody>
        //             </Table>
        //             <div>
        //                 <Card style={{ width: "100rem" }} className="detail-table">
        //                 <ListGroup variant="flush">
        //                     <ListGroup.Item>
        //                     <div className="align-items-center">
        //                         <div className="text-center">
        //                             <p className="fs-14">
        //                                 <span className="font-w500 text-darkgrey">Default outbound policy:</span> This policy applies to any traffic not covered by th outbound rules listed above.&nbsp;&nbsp;
        //                                 <select className="p-1 text-black-50">
        //                                     <option selected>Accept</option>
        //                                 </select>
        //                             </p>
        //                         </div>
        //                     </div>
        //                     </ListGroup.Item>
        //                 </ListGroup>
        //                 </Card>
        //             </div>
        //         </div>
        //     </div>
        //     <div className="row px-3 justify-content-end">
        //         <div>
        //         <button className="bg-white border-0 fs-16 pt-2 px-5 text-blue">Discard Changes</button>
        //         </div>
        //         <div>
        //         <button className="border-0 btn-hslineargradient p-2 px-4 rounded-0">Save Changes</button>
        //         </div>
        //     </div>
        //     { hs_modal && modalComponent && <Offcanvas title={titleForm} setHsModal={setHsModal} form={modalComponent}/>}
           
        // </div>
        <React.Fragment>
              {/* <InputBoundFire/> */}
              <SpacerHS_TWO/>
              {/* <OutputBoundFire/> */}
        </React.Fragment>
    );
  }