import { ErrorHandler } from "./ErrorHandler";

// export const CatchAsync = fn => {
//     return (...rest) => {
//       fn(...rest).catch((err) => {
//         ErrorHandler(err);
//       })
//     }
// }

// export const CatchAsync =  function(f) {
//   return   function() {
//       try {
//           f.apply(this, arguments);
//       } catch(e) {
//         ErrorHandler(e)
//       }
//   }
// }
export function CatchAsync(f) {
  return async function() {
      try {
          return await f.apply(this, arguments);

      } catch(e) {
      
        ErrorHandler(e)
      }
  }
}

// https://stackoverflow.com/questions/41349331/is-there-a-way-to-wrap-an-await-async-try-catch-block-to-every-function?noredirect=1&lq=1