import React from 'react';
import { connect } from 'react-redux';
import {getSessionTemperaturesService,getTemperaturesDetailsService} from '../../../../../services/BareMetalServices/BareMetalDetailsService';
import LoaderHS from '../../../hireserver/components/LoaderHS';
import { handlerAlert } from '../../Instances/InstancesListComponents/DropDownInstancesList';
import TempDetailsCard from './TempDetailsCard';

function TemperaturesDetails({currDetailsItem}) {
  let [tempData,setTempData] = React.useState([]);
  let [loading,setLoading] = React.useState(false);

    React.useEffect(function (){
      setLoading(true);
      getDetails();

   

      return () => {
        setLoading(false);
        setTempData([]);
      }
    },[currDetailsItem]);


    async function getSession(payload){
      const res = await getSessionTemperaturesService(payload);
      return res;
    }

    async function getDetails(){
     
      if(Object.keys(currDetailsItem).length === 0) {
        setLoading(false);
        return;
      };

      try {
        const {hostname} = currDetailsItem;
      let payload = JSON.stringify({"UserName": "administrator",
      "Password": "Qazplm@4#2"});
            
      const sessionId =  await getSession(payload);
      const detailsList = await getTemperaturesDetailsService(sessionId,hostname);
      const {data} = detailsList;
      const {result} = data;
      if(data.code != '0') {
        handlerAlert("danger",data.message,"danger");
        setLoading(false);
        return;
      }

      if(result === ''){
        handlerAlert("danger",'No Data Found',"danger");
        setLoading(false);
        return;
      }

      
      let dataParse = JSON.parse(result);
      const {Temperatures} = dataParse;
      // console.log(Temperatures)
      setTempData(Temperatures);
      setLoading(false);
      } catch (error) {
        // console.log(error);
        handlerAlert("danger",'something went wrong','danger');
        setLoading(false);
      }

      //// console.log(detailsList)
    }


    if(loading){
      return <LoaderHS/>
    }


    function getTempsCard(){
      return tempData.map(function(item,i){
        // // console.log(item)
        if(item.Status.State != 'Enabled') return;
        return <TempDetailsCard data={item} />
      })
    }

  return (
    <div className='row'>
        {getTempsCard()}
    </div>
  )
}


const mapStateToProps = ({ instanceDetails }) => {
  return {
    currDetailsItem: instanceDetails.currDetailsItem,
  };
};
export default connect(mapStateToProps)(TemperaturesDetails);
