import React from 'react';
import { Link } from 'react-router-dom';

export default function StatusShowInstanceTable({ setLoadingStatus,loading,statusid,isSpinning,isText, isColor="bgl-success" }) {
    // if(isSpinning){
    //     '';
    // }
    '';
    if (statusid == 4) {
        let isActionText = loading ? '' : 'Destory';
        // let isActionText = loading ? isText : 'Destory';
        let textColor = loading ? 'text-success' : 'text-danger';
        let isColorGiven = loading ? isColor : 'bgl-danger';
        return (
            <Link
                to={"#"}
                className={`btn btn-sm d-flex align-items-center  justify-content-center ${isColorGiven} ${textColor} rounded-sm w-100p`}
            >
                 {  loading && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>}
                 {isActionText}
            </Link>
        )
    }
    if (statusid == 3) {
        let isActionText = loading ? '' : 'Reboot';
        //let isActionText = loading ? isText : 'Reboot';
        let isColorGiven = loading ? isColor : 'bgl-danger';
        let textColor = loading ? 'text-success' : 'text-success';
        return (
            <Link
                to={"#"}
                className={`btn btn-sm d-flex align-items-center  justify-content-center ${isColorGiven} ${textColor} rounded-sm w-100p`}
            >
                 {  loading && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>}
                 {isActionText}
            </Link>
        )
    }
    if (statusid == 2) {
        let isActionText = loading ? '' : 'Stopped';
       // let isActionText = loading ? isText : 'Stopped';
        let isColorGiven = loading ? isColor : 'bgl-danger';
        let textColor = loading ? 'text-success' : 'text-danger';
       
        return (
            <Link
                to={"#"}
                className={`btn btn-sm d-flex align-items-center  justify-content-center ${isColorGiven} ${textColor} rounded-sm w-100p`}
            >
           {  loading && <span><span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span></span>}
                 {isActionText}
            </Link>
        )
    }

    if(statusid === 5){
        let isActionText = '';
        // let isActionText = isSpinning ? isText : 'Stopped';
         let isColorGiven = 'text-success';
         let textColor = 'text-success'
         return (
            <Link
                to={"#"}
                className={`btn btn-sm d-flex align-items-center  justify-content-center ${isColorGiven} ${textColor} rounded-sm w-100p`}
            >
           {  true && <span><span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span></span>}
                 {isActionText}
            </Link>
        )

    }

    if(statusid === 6){
        let isActionText = '';
        // let isActionText = isSpinning ? isText : 'Stopped';
         let isColorGiven = 'text-success';
         let textColor = 'text-success'
         return (
            <Link
                to={"#"}
                className={`btn btn-sm d-flex align-items-center  justify-content-center ${isColorGiven} ${textColor} rounded-sm w-100p`}
            >
           {  true && <span><span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span></span>}
                 {isActionText}
            </Link>
        )

    }

    if(statusid === 404){
      
         let isActionText = 'Failed';
         let isColorGiven = 'bgl-danger';
         let textColor = 'text-danger'
         return (
            <Link
                to={"#"}
                className={`btn btn-sm d-flex align-items-center  justify-content-center ${isColorGiven} ${textColor} rounded-sm w-100p`}
            >
           {  false && <span><span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span></span>}
                 {isActionText}
            </Link>
        )
    }
    let isActionText = loading ? '' : 'Running';
    //let isActionText = isSpinning ? isText : 'Running';
    let isColorGiven = loading ? isColor : 'bgl-success';
    let textColor ='text-success';
    
    return (
        
        <Link
            to={"#"}
            
            className={`btn btn-sm w-100p justify-content-center d-flex align-items-center ${isColorGiven} ${textColor} rounded-sm w-100p`}
        >
           
           {  loading && <span><span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span></span>}
           {isActionText}
        </Link>
    )
}