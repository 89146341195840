import React from 'react';
import './spacesStyles.css';

function SpacerHS_ONE() {
  return (
    <div className='SpacerHS_ONE'></div>
  )
}

export default SpacerHS_ONE;


