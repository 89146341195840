import produce from "immer";


const initialState = {
   submitPayload:'',
   showModal:false,
   bareMetalModal:false,
   summaryMachineInfo:null
};

const CLEAR_DELOY_REDUCER = 'CLEAR_DELOY_REDUCER';
const PUT_DELOY_REDUCER = 'PUT_DELOY_REDUCER';
const HIDE_MODAL = 'HIDE_MODAL';
const SHOW_MODAL = 'SHOW_MODAL';

const BARE_METAL_MODAL_SHOW = 'BARE_METAL_MODAL_SHOW';
const BARE_METAL_MODAL_HIDE = 'BARE_METAL_MODAL_HIDE';

const PUT_SUMMARY_INFO = 'PUT_SUMMARY_INFO';


export function clearDeloyReducerAction(){
    return {
        type:CLEAR_DELOY_REDUCER,
    }
}

export function putDeloyReducerAction(payload){
    return {
        type:PUT_DELOY_REDUCER,
        payload
    }
}

export function hideDeloyModalAction(){
    return {
        type:HIDE_MODAL
    }
}

export function showDeloyModalAction(){
    return {
        type:SHOW_MODAL
    }
}

export function showBareMetalModalAction(){
    return {
        type:BARE_METAL_MODAL_SHOW
    }
}

export function hideBareMetalModalAction(){
    return {
        type:BARE_METAL_MODAL_HIDE
    }
}

export function putSummaryInfoModal(payload){
    return {
        type:PUT_SUMMARY_INFO,
        payload
    }
}


function DeployReducer(state = initialState, {type,payload}) {
    switch (type) {
        case CLEAR_DELOY_REDUCER:{
            return initialState;
        }
        case PUT_DELOY_REDUCER:{
            return {
                ...state,
                submitPayload:payload
            }
        }
        case SHOW_MODAL:{
            return {
                ...state,
                showModal:true
            }
        }
        case HIDE_MODAL:{
            return {
                ...state,
                showModal:false
            }
        }
        case BARE_METAL_MODAL_SHOW:{
            return {
                ...state,
                bareMetalModal:true
            }
        }

        case BARE_METAL_MODAL_HIDE:{
            return {
                ...state,
                bareMetalModal:false
            }
        }

        case PUT_SUMMARY_INFO:{
            return {
                ...state,
                summaryMachineInfo:payload
            }
        }

        default:
            return state;
    }

}




export default DeployReducer;
