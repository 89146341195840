import { getTemperaturesDetailsURL, getTemperaturesSessionURL } from "../../API_ENDPOINTS/BARE_METAL_END_POINTS";
import { API_SERVICES } from "../API_SERVICES";
import { getSessionAndUserId } from "../InstancesServices/InstancesListService";


export function getSessionTemperaturesService(payload){
    let  { userId, sessionId } = getSessionAndUserId();
  
    return '73f6b8a910d3035e760caa7fb1cfed21';
    let config = {
        method: 'post',
        url:getTemperaturesSessionURL(),
        headers: {
            'Content-Type': 'application/json'
        },
        data:payload
       
    };


   return  API_SERVICES(config);
    // return API_CALL(config);
    
}

export function getTemperaturesDetailsService(sessionIdXAuthToken,hostname){
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'get',
        url:getTemperaturesDetailsURL({hostname}),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
        
       
    };


   return  API_SERVICES(config);
    // return API_CALL(config);
}