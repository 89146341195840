import React from 'react'
import { MDBDataTable } from 'mdbreact';
import CheckBoxControl from './InstancesListComponents/CheckBoxControl';
import ViewDetailsLink from './InstancesListComponents/ViewDetailsLink';
import OSList from './InstancesListComponents/OSList';
import LocationList from './InstancesListComponents/LocationList';
import StatusShow from './InstancesListComponents/StatusShow';
import ViewConsoleBtn from './InstancesListComponents/ViewConsoleBtn';


import DropDownInstancesList, { handlerAlert } from './InstancesListComponents/DropDownInstancesList';
import { twoViewURL, updateMachineURL } from '../../../../API_ENDPOINTS/API_ENDPOINTS';
import axios from 'axios';
import { connect, useDispatch } from 'react-redux';
import { deleteShowConfirmModal, openHsModal, selectAllInstanceItems, selectCurrentInstanceListItem, setInstances, startLoadingInstaces, unCheckAllInstances, unCheckCurrentInstanceListItem } from '../../../../store/actions/InstancesActions/InstancesActions';
import { closeAllModal, hide_deleteAllMachinesModal, openAllModal, update_all_status_end, update_all_status_start, VM_ActionCall, VM_ActionStarted, VM_CALL_ACTION_DELETE_ALL, VM_CALL_ACTION_STOP_ALL } from '../../../../store/actions/InstancesActions/InstanceCurrItemAction';
import { GetBranchService, getSessionAndUserId } from '../../../../services/InstancesServices/InstancesListService';
import ModalHs from '../../hireserver/ModalHs';
import _sortBy from 'lodash/sortBy';
import ParentCheckBoxControlInstances from './InstancesListComponents/ParentCheckBoxControlInstances';
import CloseInstancesModal from './components/sharedComponents/CloseInstancesModal';
import DeleteInstancesModal from './components/sharedComponents/DeleteInstancesModal';
import RebootInstancesModal from './components/sharedComponents/RebootInstancesModal';
import { cloneDeep } from 'lodash';
import InstanceReInstall from './components/sharedComponents/InstanceReInstall';
import StaticInstancesList from '../../../../utils/StaticsClasses.js/StaticInstancesList';
import { instancesViewHelper } from './InstancesListComponents/InstancesViewHelper';
import { TABLE_ENTIRES } from '../../../../utils/Emuns';
import FooterBee from '../../hireserver/components/FooterBee/FooterBee';
import CurrencyFC from '../../hireserver/components/Currency/CurrencyFC';
import RenewalBtn from './InstancesListComponents/RenewalBtn';
import ImageHs from '../../hireserver/ImageHs';



function InstancesList({ deleteAllModal, instancesList, showDeleteModal, userDetails, rebootShowModal, curSelectItem, getIsAllSelect, allActionModal, currDeleteItem, getSelectedInstances, currencyname }) {

  const dispatch = useDispatch();
  const [actionModalAllInput, setActionModalAllInput] = React.useState({
    text: '',
    error: false
  });
  const instancesContainerRef = React.useRef(null);
  const [deleteAllMachinesConfirm, setDeleteAllMachinesConfirm] = React.useState({
    text: '',
    error: false
  });
  const [col, setCols] = React.useState([
    // {
    //   label:<ParentCheckBoxControlInstances handleClick={handlerCheckItem} dataAttr="allitems" id="787" />,
    //   field: "check",
    // },

    {
      label:"Machine" ,
     
      field: "name",
      sort: "asc",
     
      attributes: { className: "needarrowtable" },
    },

    // { label: "date", field: "date", sort: "asc" },
    {
      label: "OS",
      field: "os",
      sort: "asc",
      attributes: { "data-head": "os", className: "text-center needarrowtable" },
    },
    {
      label: "Location",
      field: "location",
      sort: "asc",
      attributes: {
        "data-head": "location",
        className:'needarrowtable'
      },
    },
    { label: "Due date", field: "amount", sort: "asc", attributes: { className: "text-center needarrowtable" } },
    // {
    //   label: "Due date", field: "duedate", sort: "asc", attributes: { className: "text-center needarrowtable" }
    // },
    // {label:"Due Date", field:"dueDate",sort: "asc", attributes: { className: "col-3" },},
    { label: "Console", field: "console", sort: "asc", attributes: { className: "text-center" } },
    { label: "Status", field: "status", sort: "asc", attributes: { className: "text-center needarrowtable" } },
    { label: "", field: "dropdown", sort: "asc", attributes: { className: "text-center no" } },
  ]);
  const [options, setOptions] = React.useState({
    filterType: "checkbox",
  });

  const [formatData, setFormatData] = React.useState({
    columns: [],
    rows: []
  })

  React.useEffect(function () {
    StaticInstancesList.isUnMounted = false;


    let headerHeight = document.querySelector('.nav_header').getBoundingClientRect().height;
    let containerHeight = document.querySelector('.mainContainerHS').getBoundingClientRect().height;
    let windowHeightCus = window.innerHeight;

    // if (windowHeightCus > headerHeight + containerHeight) {
    //   if (document.querySelector('.mainListContainerhs')) {
    //     document.querySelector('.mainListContainerhs').style.height = `${containerHeight + (windowHeightCus - containerHeight - headerHeight) - 150}px`;
    //   }

    // }
    return () => {
      StaticInstancesList.isUnMounted = true;
    }
  }, []);

  // React.useEffect(function () {
  //   console.log(instancesContainerRef?.current?.clientHeight, 'instancesContainerRef?.current?.height')
  //   let containerHeight = document.querySelector('.mainListContainerhs').getBoundingClientRect()?.height;
  //   let headerHeight = document.querySelector('.nav_header').getBoundingClientRect()?.height;
  //   let windowHeightCus = window.innerHeight;

  //   if (windowHeightCus > containerHeight + headerHeight + 150) {
  //     console.log("askjdhhjkashdasjkdhjkh", containerHeight)
  //     document.querySelector('.beebox')?.classList.add('position-absolute')

  //   } else {
  //     document.querySelector('.beebox')?.classList.add('position-fixed')
  //   }

  // }, [instancesContainerRef?.current?.clientHeight])

  function lowercaseKeys(obj) {  
    if (!isObject(obj)) {
      throw new Error(`You must provide an object to "lowercaseKeys". Received "${typeof obj}"`)
    }
  
    return Object
      .entries(obj)
      .reduce((carry, [key, value]) => {
        carry[key.toLowerCase()] = value;
  
        return carry;
      }, {});
  }

  function isObject (value) {  
    return Object.prototype.toString.call(value) === '[object Object]'
  }

  React.useEffect(function () {
    // '';
    //deleteAll();
    if (!currencyname) return;
    console.log(instancesList)
    let rowComps = [];
    for (let i = 0; i < instancesList.length; i++) {

      const { code, ipaddress, icon, createddate, machinename, loadinginfo, machineid, machinetypename, operatingsystemname, osiconpath, regionname, hostname, statusid, refstatusid, dueamount, duedate, regionimage, machinetype, publicip, noofblocks, groupname,appname,marketplaceicon,appversion,clusterid } = lowercaseKeys(instancesList[i])

      debugger;
   
      let osFullItemName = {
        name:!appname?groupname:appname,
        fullName:!appname?operatingsystemname:appversion,
        icon:!appname?osiconpath:marketplaceicon
      }
      let incs = getSelectedInstances[code] != undefined;
      // // console.log(instancesList[i].isChecked,'instancesList[i].isChecked')
    let appCompBtn = refstatusid != 12 ? <DropDownInstancesList disabledClass={instancesList[i]?.consoleDisabled} nodeType={instancesList[i].nodeType} itemData={instancesList[i]} machinename={machinename} machinetype={machinetype} statusid={refstatusid} machineid={machineid} code={code} /> : <RenewalBtn fullData={instancesList[i]} />
      let newCol = {
        // check: <CheckBoxControl isCheck={instancesList[i].isChecked} fullData={instancesList[i]} id={code * 6} dataAttr={code} handleClick={handlerCheckItem} />,
        name: <ViewDetailsLink statusid={refstatusid} disabledClass={instancesList[i]?.consoleDisabled} privateip={instancesList[i].privateip} publicip={instancesList[i].publicip} machineId={instancesList[i].machineid} noofblocks={noofblocks} item={instancesList[i]} code={code} searchvalue={machinename} machinename={machinename} ipaddress={ipaddress}>{machinename}</ViewDetailsLink>,

        search_name: machinename?.toLowerCase(),
        search_location: regionname?.toLowerCase(),
        search_ip:instancesList[i].publicip,
        date: createddate,
        os: <OSList groupname={osFullItemName?.name} title={` ${osFullItemName?.fullName}`} imgSrc={osFullItemName?.icon} />,
        location: <LocationList locationName={regionname} imgSrc={icon} />,
        duedate: <div className='text-danger text-center'>{duedate}</div>,
        console: <div className='text-center'>
          <ViewConsoleBtn statusid={refstatusid} disabledClass={instancesList[i]?.consoleDisabled} handleData={{code,hostname,clusterid}}  />
          {/* <ViewConsoleBtn statusid={refstatusid} disabledClass={instancesList[i]?.consoleDisabled} handleData={code,hostname,clusterid} clickHandler={(e) => onClickViewBtn(e, code, hostname,clusterid)} /> */}
        </div>,
        amount: <div className='d-flex flex-column pt-2 align-items-center'><div></div><div><span className='pr-1'>
          <CurrencyFC name={currencyname} val={dueamount} />

        </span></div><div className='text-danger '>{duedate}</div></div>,
        status: <div className='d-flex justify-content-center align-items-center'>
          <StatusShow machineid={machineid} loadingInfo={loadinginfo} isColor={loadinginfo?.color} isText={loadinginfo?.text} isSpinning={loadinginfo?.loading} statusid={refstatusid} />
        </div>,
        dropdown: appCompBtn
        //dropdown: <Dropdownblog2  recordid={machineid} machineid={code} machinetype={machinetype}/>
      }
      rowComps.push(newCol)
    }

    // setFormatData({
    //   columns:col,
    //   rows:rowComps
    // });


    setFormatData({
      columns: cloneDeep(col),
      rows: cloneDeep(rowComps)
    });


  }, [instancesList, currencyname]);

 

  function handleClose() {
    dispatch(deleteShowConfirmModal());
  }

  function handlerCheckItem(e, fullData, getIsAllSelect) {
    // '';
    let code = e.target.getAttribute('data-code');

    if (code === 'allitems') {
      if (!getIsAllSelect) {


        const visibleListItem = document.querySelectorAll('.instanceListTable .instacesClass');

        let itemsCodes = {};
        let findIndex = null;
        visibleListItem.forEach(function (el, i) {
          let classN = el.classList.value.split(' ');
          if (findIndex === null) {
            findIndex = classN.findIndex(item => item.includes('data-class'));
          }
          if (findIndex != null) {
            let codeI = classN[findIndex].split('-');
            itemsCodes[codeI[codeI.length - 1]] = true;
          }
        });
        // // console.log(itemsCodes)
        dispatch(selectAllInstanceItems({
          data: itemsCodes
        }));
      } else {
        dispatch(unCheckAllInstances());


      }


    } else {
      let isCheck = e.target.checked;
      if (isCheck) {
        dispatch(selectCurrentInstanceListItem({ code, fullData }))
      } else {
        // console.log(isCheck, 'isCheck')
        dispatch(unCheckCurrentInstanceListItem({ code, fullData }));
      }
    }


  }

  async function deleteAll() {
    const { userId, sessionId } = getSessionAndUserId();
    //axios(config);
    for (let i = 0; i < instancesList.length; i++) {
      const { machineid } = instancesList[i];
      let url = updateMachineURL(machineid, 3);

      var config = {
        method: 'post',
        url,
        headers: {
          'Authorization': 'Basic ' + sessionId
        }
      };
      const deleteAll = await axios(config);
      // console.log(deleteAll);
    }
  }


  function onPageChangeHandler({ activePage, pagesAmount }) {
    const newData = cloneDeep(formatData);
    setFormatData(newData);

    const ls = document.querySelectorAll('.instanceListTable .instacesClass');
    // console.log(ls, 'ldsa')
  }

  function onSearchHandler(value) {
    const newData = cloneDeep(formatData);
    setFormatData(newData);
    // let isChecked = document.querySelector('[data-code=allitems]').checked;
    // if(isChecked){
    //   dispatch(unCheckAllInstances());
    // }
  }

  function setListByNameASC(sortbyname) {
  
    let newInstances = cloneDeep(instancesList);
    let newa = _sortBy(newInstances, [sortbyname]);


    dispatch(setInstances({
      data: newa,
      error: null
    }))
  }


  function setListByNameDESC(sortbyname) {
    let newInstances = cloneDeep(instancesList);
    let newa = _sortBy(newInstances, [sortbyname]);
    dispatch(setInstances({
      data: newa.reverse(),
      error: null
    }))
  }

  function sortOSAsc() {
    let newInstances = [...instancesList];
    let newa = _sortBy(newInstances, ['machinename']);

    dispatch(setInstances({
      data: newa,
      error: null
    }))
  }

  function handlerSort({ column, direction }) {
    if (column === undefined) return;
    if (column === 'console') return;

   

    let name = 'machinename';

  

    if (column === 'name') {
      name = 'machinename'
    } else if (column === 'os') {
      name = 'operatingsystemid'
    } else if (column === 'location') {
      name = 'branchname'
    } else if (column === 'amount') {
      name = 'duedate'
    } else if (column === 'status') {
      name = 'refstatusid'
    }else if(column === 'duedate'){
      name = 'duedate'
    }
   // const rootElemCss= document.querySelector(':root');
  
    if (direction == 'asc') {
      setListByNameASC(name);
     // document.documentElement.style.setProperty("--tableArrow", `"▲"`);
    } else {
      setListByNameDESC(name);
      //document.documentElement.style.setProperty("--tableArrow", `"▼"`);
      // ascCurrClass
    }


  }

  function handleAllStop() {
    dispatch(closeAllModal())
  }

  function handleAllActionModal() {
    dispatch(closeAllModal());
    dispatch(update_all_status_start(getSelectedInstances, 2));
    dispatch(VM_CALL_ACTION_STOP_ALL(getSelectedInstances));

    // console.log('okay all handler')

  }

  function handleAllDelete() {
    dispatch(hide_deleteAllMachinesModal());
    dispatch(update_all_status_start(getSelectedInstances, 3));
    dispatch(VM_CALL_ACTION_DELETE_ALL(getSelectedInstances));
  }

  function handleAllDeleteClose() {
    dispatch(hide_deleteAllMachinesModal());
  }


  return (
    <React.Fragment>
      {/* <MDBDataTable  disableRetreatAfterSorting={true}  className='instanceListTable'   data={formatData}  /> */}
      <div ref={instancesContainerRef} className="row mainListContainerhs  ">
        <div className="col-xl-12" style={{ position: 'relative' }}>
          <div className="table-responsive">
            <div className="display mb-4 dataTablesCard  customer-list-table  instances-table-card">

              <MDBDataTable paging={Boolean(instancesList.length>TABLE_ENTIRES)}   paginationLabel={['‹', '›']} onSearch={onSearchHandler} onSort={handlerSort} disableRetreatAfterSorting={true} onPageChange={onPageChangeHandler} className='instanceListTable ascCurrClass' entriesOptions={[1, 5, 20, 25]} entries={TABLE_ENTIRES} data={formatData} options={options} />

            </div>
          </div>

        </div>
      </div>

      {/* <DeleteInstancesModal/>
      <CloseInstancesModal/>
      <RebootInstancesModal/> */}
      {/* <InstanceReInstall/> */}

      <ModalHs handleClose={handleAllStop} okayHandler={handleAllActionModal} showModalHS={allActionModal['isBool']} btnTitle={allActionModal['name']} >
        <div className='w-100'>
          <div className='w-100 d-flex'>  <div>{allActionModal['des']}</div> </div>
          <div>
            {allActionModal['namesArr'] && allActionModal['namesArr'].map(function (item, i) {
              let last = allActionModal['namesArr'].length - 1 == i ? '.' : ','
              return <span key={i * 60}>{item}{last}</span>
            })}
          </div>
          <input className='form-control mt-2' value={actionModalAllInput.text} placeholder='Type the machine name to stop the machine' style={{ borderRadius: '4px' }} onInput={(e) => setActionModalAllInput({
            text: e.target.value,
            error: false
          })} />
          {actionModalAllInput.error && <div className='text-center text-danger mt-2'>The Input is wrong</div>}
        </div>
      </ModalHs>

      <ModalHs handleClose={handleAllDeleteClose} okayHandler={handleAllDelete} showModalHS={deleteAllModal['isBool']} btnTitle={deleteAllModal['name']} >
        <div className='w-100'>
          <div className='w-100 d-flex'>  <div>{deleteAllModal['des']}</div> </div>
          <div>
            {deleteAllModal['namesArr'] && deleteAllModal['namesArr'].map(function (item, i) {
              let last = deleteAllModal['namesArr'].length - 1 == i ? '.' : ','
              return <span key={i * 60}>{item}{last}</span>
            })}
          </div>
          <input className='form-control mt-2' value={deleteAllMachinesConfirm.text} placeholder='Type the machine name to stop the machine' style={{ borderRadius: '4px' }} onInput={(e) => setDeleteAllMachinesConfirm({
            text: e.target.value,
            error: false
          })} />
          {deleteAllMachinesConfirm.error && <div className='text-center text-danger mt-2'>The Input is wrong</div>}
        </div>
      </ModalHs>


      {/* <FooterBee /> */}
    
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    getInstances: state.instances.instancesList,
    getSelectedInstances: state.instances.selectedListItems.selectItems,
    allActionModal: state.instances.stopAllModal,
    loadingInstances: state.instances.loading,
    showDeleteModal: state.instances.showDeleteModal,
    currDeleteItem: state.instances.currDeleteItem,
    curSelectItem: state.instances.curSelectItem,
    deleteAllModal: state.instances.deleteAllModal,
    userDetails: state.auth.auth,
    currencyname: state.PaymentInfoReducer.paymentData?.currencyname
  };
};

export default connect(mapStateToProps)(InstancesList);

  // async function deleteAll(){
  //   const { userId, sessionId } = getSessionAndUserId();
  //   //axios(config);
  //   for(let i=0;i<instancesList.length;i++){
  //     const {machineid} = instancesList[i];
  //     let url = updateMachineURL(machineid, 3);

  //     var config = {
  //         method: 'post',
  //         url,
  //         headers: {
  //             'Authorization': 'Basic ' + sessionId
  //         }
  //     };
  //     const deleteAll = await axios(config);
  //     // console.log(deleteAll);
  //   }
  // }
