import React from 'react'
import { connect, useDispatch } from 'react-redux';
import { createMachineService } from '../../../../../../services/InstancesServices/InstancesFormService';
import { getPaymentWalletAmountService } from '../../../../../../services/paymentServices/PaymentService';
import { finishLoadingInstances, getIntancesList, startLoadingInstaces } from '../../../../../../store/actions/InstancesActions/InstancesActions';
import { putRedirectLinkAction, showPagementGateWayAction } from '../../../../../../store/actions/RedirectPageActions/RedirectPageAction';
import { clearDeloyReducerAction, hideDeloyModalAction } from '../../../../../../store/reducers/DeployReducer';
import { getCurrencySymbol } from '../../../../../../utils/Currency';
import { checkResData, imageReplaceHashHelper, percentageHelper } from '../../../../../../utils/Helpers';
import StaticInstanceForm from '../../../../../../utils/StaticsClasses.js/StaticsInstances';
import ModalHs from '../../../../hireserver/ModalHs';
import { handlerAlert } from '../../InstancesListComponents/DropDownInstancesList';
import walletPng from '../../../../../../images/hireserver/imgs/wallet.png';
import { Link, withRouter } from 'react-router-dom';
import SpacerHS_TWO from '../../../../hireserver/Spaces/SpacerHS_TWO';
import CurrencyFC from '../../../../hireserver/components/Currency/CurrencyFC';
import SuccessSkeleton from '../../../../hireserver/components/payment/successpayment/SuccessSkeleton';
import ImageHs from '../../../../hireserver/ImageHs';
import beeStand from '../../../../../../images/hireserver/imgs/bee/deploy.png';
import { parseInt } from 'lodash';
import StaticInstancesList from '../../../../../../utils/StaticsClasses.js/StaticInstancesList';
import { isMobile } from 'react-device-detect';

function DeployModalInstances({ isOpen, setDeployLoading, summaryMachineInfo, history, price, paymentData, submitPayload }) {
  const dispatch = useDispatch();
  const [walletValue, setWalletValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [disableClose, setDisableClose] = React.useState(false);

  async function fetchDetailsValues() {
    let res = await getPaymentWalletAmountService();
    let isValidPayment = checkResData(res, true)
    if (isValidPayment === false) {
      setWalletValue(0);
      // setLoading(false);
      return;
    }

    let resJSON = JSON.parse(res.data.result)[0];
    if (!resJSON) {
     
      setWalletValue(0);
      // setLoading(false);
      return;
    }

    setWalletValue(resJSON?.walletvalue);
  }

  function closeDeploy() {

    dispatch(clearDeloyReducerAction());
    setDeployLoading(false);
    setLoading(false);;
  }

  async function okayHandler() {

    setLoading(true);
    setDisableClose(true);

    const response = await createMachineService(submitPayload);
    if (StaticInstanceForm.isUnMounted) return;

    debugger;
    if (response.data.result == null && response.data.message != 'Machine creation started') {
      handlerAlert("danger", "Bad Response Create Again", "danger");
      // console.log(response)
      //setIsCreatedResCompleted(true);
      //dispatch(getIntancesList(props.history));
      //dispatch(finishLoadingInstances());
      setDeployLoading(false);
      setLoading(false);
      setDisableClose(false);
      dispatch(hideDeloyModalAction());
      return;
    }
    if (response.data.message == 'Machine creation started') {
    
      setTimeout(function () {
        dispatch(hideDeloyModalAction());
        dispatch(startLoadingInstaces());
        StaticInstancesList.isUnMounted = false;
        history.push("/instances");
        dispatch(getIntancesList(history));



      }, 3000);
      return;
    }
    let data = JSON.parse(response.data.result);
    //console.log(data,'instainstainstainstainstainstainstainstainsta')
    const { paymentlink } = data;
    if (paymentlink) {
      dispatch(hideDeloyModalAction());

      dispatch(putRedirectLinkAction(paymentlink));
      dispatch(showPagementGateWayAction());

      return;
    }

    //dispatch(toggleCreatingMachine());
    // dispatch(
    //   setInstances({
    //     data: newInst,
    //     error: null,
    //   })
    // );  
    dispatch(hideDeloyModalAction());
    dispatch(getIntancesList(history));
    history.push("/instances");
  }

  function getDeployHTML() {

    if (submitPayload == undefined || submitPayload == null || submitPayload == '') return;

    const {
      location,
      os,
      machineName,
      blocks,
      tenure,
      specs,
      database
    } = summaryMachineInfo;
    debugger;
    
    const tenureJsonRes = JSON.parse(tenure?.taxPercentage)

    return <div className=''>

      <div className='p-3 px-5 border-bottom font-16'>
        <span className='text-primary'> Order Summary</span>
      </div>

      <div className='p-3 px-5 row border-bottom font-16 font-w500'>

        <div className="col-sm-7 d-flex align-items-center d-gap-40">
          <ImageHs src={beeStand} alt={'orderDesState?.machinename'} width="100px"/>

          <div className=''>
            <div className=' m-0'>
              <span>Instance</span> <span>:</span> <span>{machineName}</span>
            </div>
            <hr className='my-1' />
            <div className='font-12'>
              {blocks * 1} CPU, {blocks * specs?.ram}  Gb Ram, {blocks * specs?.storage} Gb Storage
            </div>
            <hr className='my-1' />
            <div className='d-flex d-gap-10 font-12'>
              <ImageHs src={imageReplaceHashHelper(location.image)} width="15px" />
              <div>
                {location.name}
              </div>
            </div>
            {
              database === 0 && <React.Fragment>
                <hr className='my-1' />
                <div className='d-flex d-gap-10 font-12'>
                  <ImageHs src={imageReplaceHashHelper(os.image)} width="15px" />
                  <div>
                    {os.groupname} {os.name}
                  </div>
                </div>
              </React.Fragment>
            }
            {
              database != 0 &&
              <React.Fragment>
                <hr className='my-1' />
                <div className='d-flex d-gap-10 font-12'>
                  <ImageHs src={imageReplaceHashHelper(database.icon)} width="15px" />
                  <div>
                    {database.appname} {database.appversion}
                  </div>
                  <span className='font-12 font-w700 hsBack'>+</span>
                  <ImageHs src={imageReplaceHashHelper(os.image)} width="15px" />
                  <div>
                    {os.groupname} {os.name}
                  </div>

                </div>
              </React.Fragment>
            }
          </div>
        </div>
        {isMobile ? <SpacerHS_TWO/> : null}
        <div className="col-sm-5 ">
            <div className="row">
            <div className="col-sm-6 d-flex flex-column align-items-center">
          <div className='hs_text-light'>Blocks</div>
          <div>{blocks}</div>
        </div>
        <div className="col-sm-6  d-flex flex-column align-items-center">
          <div className='hs_text-light'>
            Tenure
          </div>
          <div>
            {/* <CurrencyFC name={paymentData?.currencyname} val={price} /> */}
            <span className='font-14'>{tenure.tenure}</span>
          </div>
        </div>
            </div>
        </div>
      </div>
      <div className='p-3 px-5 border-bottom'>

        <div className='d-flex justify-content-between'>
          <span>Blocks x {blocks}</span>
          <span><CurrencyFC name={paymentData?.currencyname} val={price} /></span>

        </div>
        <div className='d-flex justify-content-between'>
          <span>SGST {tenureJsonRes?.SGST}%</span>
          <span>  <CurrencyFC name={paymentData?.currencyname} val={percentageHelper(tenureJsonRes?.SGST, price)} />  </span>

        </div>
        <div className='d-flex justify-content-between'>
          <span>CGST {tenureJsonRes?.CGST}%</span>
          <span>  <CurrencyFC name={paymentData?.currencyname} val={percentageHelper(tenureJsonRes?.CGST, price)} />  </span>

        </div>

        {/* <div className='d-flex justify-content-between'>
          <span>Amount Charged</span>
          <span>  <CurrencyFC name={paymentData?.currencyname} val={Math.abs(walletValue-price)} /></span>
        </div> */}

        {/* <span className='text-primary mr-2'>-20%</span> */}
        {/* <div className='d-flex justify-content-between'>
          <span>Taxes</span>
          <span className=''>
      
            <span> -  {getCurrencySymbol('INR', 100)}</span>
          </span>
        </div> */}
      </div>
      <div className='p-3 px-5 border-bottom'>
        <div className='d-flex justify-content-between'>


          <span>{Boolean(walletValue) && walletValue < parseInt(price + parseInt(percentageHelper(tenureJsonRes.CGST * 2, price))) && 'Sub'}Total</span>
          <span>   <CurrencyFC name={paymentData?.currencyname} val={Math.abs((parseInt(price + parseInt(percentageHelper(tenureJsonRes?.SGST, price)) + parseInt(percentageHelper(tenureJsonRes?.CGST, price)))))} /></span>
        </div>

        {Boolean(walletValue) && walletValue < parseInt(price + parseInt(percentageHelper(tenureJsonRes.CGST * 2, price))) &&
          <React.Fragment>
            <div className='d-flex justify-content-between'>
              <span className='text-danger'>wallet</span>
              <span className='text-danger'>   <span className='px-1'>-</span><CurrencyFC name={paymentData?.currencyname} val={walletValue} /></span>
            </div>

            <div className='d-flex justify-content-between'>
              <span>Total</span>
              <span className=''>  <CurrencyFC name={paymentData?.currencyname} val={Math.abs(walletValue - parseInt(price + parseInt(percentageHelper(tenureJsonRes?.SGST, price)) + parseInt(percentageHelper(tenureJsonRes?.CGST, price))))} /></span>
            </div>
          </React.Fragment>
        }
        {/* <div className='d-flex justify-content-between'>
          <span>wallet</span>
          <span className=''>  - <CurrencyFC name={paymentData?.currencyname} val={walletValue-(walletValue-(parseInt(price+parseInt(percentageHelper(tenure.taxPercentage,price)))))} /></span>
        </div> */}

      </div>


      {walletValue < Math.abs((parseInt(price + parseInt(percentageHelper(tenureJsonRes?.SGST, price)) + parseInt(percentageHelper(tenureJsonRes?.CGST, price))))) && <div className='px-5 py-3'>
        <div>Your wallet doesn't have enough money. You will redirect to payment gateway page.</div>
      </div>}
      {walletValue >= Math.abs((parseInt(price + parseInt(percentageHelper(tenureJsonRes?.SGST, price)) + parseInt(percentageHelper(tenureJsonRes?.CGST, price))))) && <div className='px-5 py-3'>
        <div>Your have <span className='text-primary font-w500'><CurrencyFC name={paymentData?.currencyname} val={walletValue} /></span>  in your wallet. Your machine creations start when you click proceed.</div>
      </div>}

    </div>
  }

  React.useEffect(function () {
    fetchDetailsValues();
  }, []);



  return (
    <ModalHs bodyClasses="p-0 overflow-hidden" size="lg" backdrop="static" isNeed={false} handleClose={closeDeploy} okayHandler={okayHandler} showModalHS={isOpen} btnTitle="Deploy Machine" >
      <div className='w-100'>
        <div className='align-item-center d-flex flex-column justify-content-center w-100'>
          {/* <div className='d-flex justify-content-center'>
           <div className='w-50p'>

            <img className='w-100 h-100' src={walletPng} alt="wallet img" />
           </div>
        </div> */}
          {getDeployHTML()}
          {/* {requiredAmount>=1 &&  <div>
           You have {getCurrencySymbol(paymentData?.currencyname,walletValue)}  in your wallet balance. The amount {price} will be  You still need {getCurrencySymbol(paymentData?.currencyname,Math.abs((walletValue -price).toFixed(2)))} 
           </div>}
           {requiredAmount<=0 &&  <div>
           You have {getCurrencySymbol(paymentData?.currencyname,walletValue)}  in your wallet balance. You still need {getCurrencySymbol(paymentData?.currencyname,Math.abs((requiredAmount).toFixed(2)))} 
           </div>} */}

        </div>
        <div className=' justify-content-end d-gap-10 d-flex p-3 px-5 font-12'>
          <button disabled={disableClose} className={`bg-danger border-0 btn-sm hs_borderRadius-4 text-white ${disableClose ? 'd-none' : ''}`} onClick={closeDeploy}>
            Cancel
          </button>
          <button className='w-150p hs-signin-btn border-0 btn-sm hs_borderRadius-4 text-white' onClick={okayHandler}>
            {loading && <React.Fragment>
              <div className="spinner-border text-white text-light" role="status"></div> </React.Fragment>}
            {!loading && 'Proceed'}
          </button>
        </div>
        <div className=' p-3 px-5 font-12'>
        By checking out you agree with our Terms of Service. We will process your personal data for the fulfillment of your order and other purposes as per our Privacy Policy. You can cancel recurring payments at any time.
        </div>
      </div>
      <div>
    
      </div>
    </ModalHs>
  )
}


const mapStateToProps = ({ PaymentInfoReducer, deployReducer }) => {
  return {
    isOpen: deployReducer.showModal,
    paymentData: PaymentInfoReducer?.paymentData,
    submitPayload: deployReducer?.submitPayload,

    summaryMachineInfo: deployReducer.summaryMachineInfo,


  };
};

export default connect(mapStateToProps)(withRouter(DeployModalInstances));
