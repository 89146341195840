import React,{Fragment} from 'react'

const createinstance = () => {
  return (
   <Fragment>
       <div>test</div>
   </Fragment>
  )
}

export default createinstance