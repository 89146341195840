import React from "react";
import { connect, useDispatch } from "react-redux";
import {
  getCurrentPassword,
  updateProfilePasswordService,
  updateUserPassword,
} from "../../../../../services/AuthService";
import { openPaymentInfoFormAction } from "../../../../../store/actions/PaymentInfoActions/PaymentInfoAction";
import { fileToGetBase64 } from "../../../../../utils/Helpers";
import IndividualPaymentInfo from "../../../../indexComponents/PaymentInfoFormComponents/IndividualPaymentInfo";
import FormInputError from "../../../hireserver/components/FormInputError";
import ButtonHS from "../../../hireserver/controls/ButtonHS";
import PasswordInputHS from "../../../hireserver/controls/PasswordInputHS";
import PasswordWrapper from "../../../hireserver/controls/PasswordWrapper/PasswordWrapper";
import { handlerAlert } from "../../Instances/InstancesListComponents/DropDownInstancesList";
import InfoTableData from "../infotables/InfoTableData";
import BillingInfoTab from "./BillingInfoTab";

function ProfileTab({ setActiveToggle, activeToggle,username, userDetails }) {
  const dispatch = useDispatch();
  const name = username?username:userDetails?.name
  const [password, setPassword] = React.useState({
    val: '',
    isError: '',
    touched: false,
    errorMessage: ''
  });
  const [updatePassword, setUpdatePassword] = React.useState({
    val: '',
    isError: '',
    touched: false,
    errorMessage: ''
  });
  const [updatePasswordAgain, setUpdatePasswordAgain] = React.useState({
    val: '',
    isError: '',
    touched: false,
    errorMessage: ''
  });
  const [passwordCheckShowUpdate, setPasswordCheckShowUpdate] = React.useState(false);

  const [passwordCheckShowUpdateUpdate, setPasswordCheckShowUpdateUpdate] = React.useState(false);
  
  // const [password, setPassword] = React.useState({
  //   val: "",
  //   error: false,
  // });

  const profileElRef = React.useRef(null);
  async function handleProfileChange(e) {
    let promoseRes = await fileToGetBase64(e);

    let fliterObg = {
      FileData: promoseRes.FileData,
      FileName: promoseRes.FileName,
      FileType: promoseRes.FileType,
    };


    // console.log(profileElRef.current.files[0]);
  }

  function checkPassword(str) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
  }

  async function passwordUpdateHandler(e) {
    
    let pass = password["val"];
    let updatePass = updatePassword["val"];
    let updatePassAgain = updatePasswordAgain["val"];
    
    let isEdit = false;
    if (pass == "") {
      isEdit = true;
      // setPassword({
      //   val: "",
      //   error: "Please enter your current password",
      // });
      let payload = {
        val:'',
        isError: true,
        errorMessage: 'Please enter your current password',
        touched: true
      }
      setPassword(payload);
      return;
      // return;
    }
  

    if (updatePass == "") {
      isEdit = true;
      // setUpdatePassword({
      //   val: "",
      //   error: `Please enter new Password`,
      // });
      let payload = {
        val:'',
        isError: true,
        errorMessage: 'Please enter new Password',
        touched: true
      }
      setUpdatePassword(payload);
      // return;
    }

    if (updatePassAgain == "") {
      isEdit = true;
      // setUpdatePasswordAgain({
      //   val: "",
      //   error: `Please enter new Password again`,
      // });
      let payload = {
        val:'',
        isError: true,
        errorMessage: 'Please enter new Password again',
        touched: true
      }
      setUpdatePasswordAgain(payload);
      // return;
    }

    // // console.log(userPassword,'userPassword')
    // // console.log(password,'/password')

    if (updatePassword["val"] != updatePasswordAgain["val"]) {
      if(isEdit == false){
        isEdit = true;
      // setUpdatePassword({
      //   val: updatePassword.val,
      //   error: `Password don't match!`,
      // });
      let payload = {
        val:updatePassword.val,
        isError: true,
        errorMessage: `Password don't match!`,
        touched: true
      }
      setUpdatePasswordAgain(payload);
      }
      // return;
    }

    if (updatePassword["val"].length < 8 && !checkPassword(updatePassword.val)) {
      if(isEdit == true) return; 
      isEdit = true;
      // setUpdatePassword({
      //   val: updatePassword.val,
      //   error:"Minimum 8 characters required and Password must consist of one Uppercase, lower case, special character and number",
      // });
      let payload = {
        val:updatePassword.val,
        isError: true,
        errorMessage: `Minimum 8 characters required and Password must consist of one Uppercase, lower case, special character and number`,
        touched: true
      }
      setUpdatePasswordAgain(payload);
       return;
    }

    // let { userPassword, userNumName } = await getCurrentPassword(
    //   userDetails["userEmail"]
    // );
    // if (userPassword != pass) {
    //   isEdit = true;
    //   // setPassword({
    //   //   val: password.val,
    //   //   error: "You enterted current password wrong!",
    //   // });
    //   let payload = {
    //     val:updatePassword.val,
    //     isError: true,
    //     errorMessage: 'You enterted current password wrong!',
    //     touched: true
    //   }
    //   setPassword(payload);
    //   // return;
    // }

    // if(updatePass == userPassword){
    //   isEdit = true;
    //   // setPassword({
    //   //   val: password.val,
    //   //   error: "You enterted current password wrong!",
    //   // });
    //   let payload = {
    //     val:updatePasswordAgain.val,
    //     isError: true,
    //     errorMessage: 'You can`t enter your current password',
    //     touched: true
    //   }
    //   setUpdatePasswordAgain(payload);
    // }

    if(isEdit){
      return;
    }

    // let isAll = checkPassword(updatePassword.val)
    // // console.log(isAll);
    // const resData = await updateUserPassword(
    //   updatePassword.val,
    //   userNumName
    // );

    const resData = await updateProfilePasswordService(JSON.stringify({
      username:userDetails['unibaseId'],
      password:updatePassword.val,
      oldpassword:pass
    }))
    
    let isSuccessfully = resData.data.message === 'Password Updated Successfully';
    if (isSuccessfully) {
      handlerAlert("success", "Your password has been updated!");
      // console.log("done");
      setPassword({
        val:'',
        error:false
    });
    setUpdatePassword({
        val:'',
        error:false
    });
    setUpdatePasswordAgain({
        val:'',
        error:false
    })
    }else{
        handlerAlert("danger", resData.data.message,"danger");
    }
    
  }

  function simpleValidInput(val, setterFunction) {
    let payload = {
      val,
      isError: false,
      errorMessage: '',
      touched: true
    }

    if (val === '') {
      payload.isError = true;
      payload.errorMessage = 'Please fill in required field'
    }

    setterFunction(payload);
  }

  function getClass(dataObj) {
    return `${dataObj.isError === true ? 'inputAlertVal' : ''} hs_borderRadius-4 ${dataObj.touched && dataObj.isError === false ? 'validFormInput' : ''}`
  }

  

  return (
    <React.Fragment>
   
      <div className="row">

      <div className="col-sm-6">
      <InfoTableData/>
        {/* <BillingInfoTab /> */}
    
        {/* <IndividualPaymentInfo /> */}
        </div>
    
      <div className="pl-3 col-sm-6 border">
        <div className=" border-bottom align-items-center d-none">
          <div className=" d-flex">
            <div className="text-center d-flex align-items-center">
              <img
                className="card-img-top bg-danger-alt rounded-circle profile-img"
                src="https://www.business2community.com/wp-content/uploads/2017/08/blank-profile-picture-973460_640.png"
                alt="profile defailt"
              />
            </div>
            <div>
              <div className="card-body pt-4">
                <h5 className="card-title mb-1">Profile photo</h5>
                <p className="card-text text-black-50 mb-2 font-14">
                  Create, upload, and manage your globally recognized avatar
                  from a single place with Gravatar.
                </p>
                <div className="">
                  {/* <Link
                  to="#"
                  className="card-link mt-4 text-blue"
                >
                  Add photo{" "}
                  <i className="fa fa-external-link"></i>
                </Link> */}
                  <label
                    className="card-link text-blue cursor-pointer"
                    htmlFor="profileHs"
                  >
                    Add photo <i className="fa fa-external-link"></i>
                  </label>
                  <input
                    accept="image/png, image/jpeg"
                    onChange={handleProfileChange}
                    ref={profileElRef}
                    className="d-none"
                    type="file"
                    id="profileHs"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      
        <div className="py-4 row border-bottom pl-2 d-none">
          <div className=" col-12 p-0">
            <h5 className="card-title font-16">Username</h5>
            <input
              type="text"
              disabled={true}
              className="w-md-75 w-100 form-control hs_borderRadius-4 input-default form-control-sm p-2 font-14"
              value={`${name}`}
            ></input>
          </div>
          {/* <div className="align-items-center d-flex  col-12 profile-update p-0">
            <button className="p-2 border-0 update-btn text-white">
              Update Username
            </button>
          </div> */}
        </div>
        <div className="py-4 row px-2">
          <div className=" col-12 p-0">
            <h5 className="card-title font-16">Email</h5>
            <input
              type="text"
              disabled={true}
              className=" py-4 form-control input-default hs_borderRadius-4 form-control-sm p-2 font-14"
              value={`${userDetails?.userEmail}`}
            ></input>
          </div>
          {/* <div className="align-items-center d-flex  col-12 profile-update p-0">
            <button className="p-2 border-0 update-btn text-white">
              Update Email
            </button>
          </div> */}
        </div>
        <div className="py-4 row d-none">
          <div className=" col-12 p-0">
            <h5 className="card-title">Timezone</h5>
            <select className=" form-control form-control-sm p-2 font-14">
              <option>Select a Timezone</option>
              <option>IST</option>
              <option>PST</option>
              <option>UST</option>
            </select>
          </div>
          <div className="align-items-center d-flex  col-12 profile-update p-0">
            <button className="p-2 border-0 update-btn text-white">
              Update Timezone
            </button>
          </div>
        </div>
        <div className="py-4 row px-2">
          <div className=" col-12 p-0">
            <h5 className="card-title font-16">Update Password</h5>

           <div className="">
           <PasswordInputHS
              error={password.error}
              labelStyles={ `w-100`}
              inputStyles={getClass(password)}
              placeholder="Current Password"
              value={password.val}
              changeHandler={(e) => simpleValidInput(e.target.value, setPassword)}
            />
           </div>
            {
            password.isError && <FormInputError message={password.errorMessage} />}
            <div className="py-1"></div>

            <PasswordWrapper classes="" value={updatePassword.val} passwordCheckShow={passwordCheckShowUpdate} >
            <PasswordInputHS
              error={updatePassword.error}
              labelStyles={`w-100`} 
              inputStyles={getClass(updatePassword)}
              
              placeholder="New Password"
              value={updatePassword.val}
              //changeHandler={setUpdatePassword}
             
              // changeHandler={(e) => simpleValidInput(e.target.value, setUpdatePassword)}
              focusOutHandler={(e) => {
                
                setPasswordCheckShowUpdate(false);
              }}
              changeHandler={(e)=> {
                simpleValidInput(e.target.value, setUpdatePassword)
                setPasswordCheckShowUpdate(true);
              }}
            />
            </PasswordWrapper>
            
            {
            updatePassword.isError && <FormInputError message={updatePassword.errorMessage} />}
            <div className="py-1"></div>
            <PasswordWrapper classes="" value={updatePasswordAgain.val} passwordCheckShow={passwordCheckShowUpdateUpdate} >
            <PasswordInputHS
              error={updatePasswordAgain.error}
              labelStyles={ `w-100`}
              inputStyles={getClass(updatePasswordAgain)}
              placeholder="New Confirm Password"
              value={updatePasswordAgain.val}
              //changeHandler={setUpdatePasswordAgain}
              
              // changeHandler={(e) => simpleValidInput(e.target.value, setUpdatePasswordAgain)}

              focusOutHandler={(e) => {
              
                setPasswordCheckShowUpdateUpdate(false);
              }}
              changeHandler={(e)=> {
                simpleValidInput(e.target.value, setUpdatePasswordAgain)
                setPasswordCheckShowUpdateUpdate(true);
              }}

            />
                </PasswordWrapper>
            {
            updatePasswordAgain.isError && <FormInputError message={updatePasswordAgain.errorMessage} />}
            <div className="py-md-3"></div>
            <div className="align-items-center d-flex col-md-6 col-12 profile-update p-0">
            {/* <button
              onClick={(e)=>passwordUpdateHandler(e)}
              className="hs-lightGreen hs_borderRadius-4 cursor-pointer p-2 border-0 update-btn text-primary"
            >
              Update Password
            </button> */}
            <ButtonHS
            handler={(e)=>passwordUpdateHandler(e)}
        
          >
            Update Password
          </ButtonHS>
          </div>
          </div>
          
        </div>
      </div>
    
      
      </div>


        

    </React.Fragment>
  );
}

const mapStatesToProps = ({ auth, PaymentInfoReducer }) => {
  return {
    userDetails: auth.auth,
  };
};



export default connect(mapStatesToProps)(ProfileTab);
