import { ADD_PAYMENT_DASHBOARD_MODAL, CLOSE_USER_DASHBOARD_MODAL, OPEN_USER_DASHBOARD_MODAL, RESET_USER_DASHBOARD, SET_DETAILS_USER_DASHBOARD } from "../../types/UserDashboardTypes/UserDashboardTypes";

export function resetDashboardAction(){
    return {
      type: RESET_USER_DASHBOARD
    }
}

export function setDashboardDetailsAction(payload){
    return {
        type:SET_DETAILS_USER_DASHBOARD,
        payload
    }
}
export function closeDashboardModal(){
    return {
        type:CLOSE_USER_DASHBOARD_MODAL,
       
    }
}
export function openDashboardModal(){
    return {
        type:OPEN_USER_DASHBOARD_MODAL,
       
    }
}

export function addPaymentHomeDashboard(payload){
    return {
        type:ADD_PAYMENT_DASHBOARD_MODAL,
        payload
    }
}
