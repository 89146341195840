import React from "react";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Offcanvas from './Offcanvas';
import Firewallinstanceform from "./Firewallinstanceform";

export default function Firewallinstance() {
const [hs_modal,setHsModal] = React.useState(false);
    return (
            <div className="inbound-rule">
                <div>
                    <p className="text-darkgrey fs-16">The following Instance have been  assigned  to this Firewall. A Instance can only be assigned to a single Firewall</p>
                </div>
                <div className='row justify-content-end align-items-center px-3 pb-2'>
                    <div>
                        <button className="border-0 btn-hslineargradient p-2 px-4 rounded-0" onClick={() => setHsModal(true)}>Add Instance to Firewall</button>
                    </div>
                </div>
                <div>
                    <Table responsive bordered>
                    <thead className="bg-lightgrey-100">
                        <tr>
                        <th className="border py-2">Instance</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td className='d-flex justify-content-between'>
                            <div><Link to="#" className="fs-14">atlury</Link></div>
                            <div><button className="bg-white border-0 text-blue fs-14">Remove</button></div>
                        </td>
                        </tr>
                    </tbody>
                    </Table>
                </div>
                { hs_modal &&  <Offcanvas title={'Add Instance to Firewall: test'} setHsModal={setHsModal} form={<Firewallinstanceform setHsModal={setHsModal}/>}/>}
            </div>
    );
  }