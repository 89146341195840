export const GET_INSTANCES = 'GET_INSTANCES';
export const SET_INSTANCES = 'SET_INSTANCES';
export const VM_ACTION_TYPE = 'VM_ACTION_TYPE';
export const START_INSTANCES_LOADING = 'START_INSTANCES_LOADING';
export const FINISH_INSTANCES_LOADING = 'FINISH_INSTANCES_LOADING';
export const UPDATE_INSTANCE_ITEM = 'UPDATE_INSTANCE_ITEM';
export const SHOW_DELETE_CONFIRM_MODAL = 'SHOW_DELETE_CONFIRM_MODAL';
export const DELETE_CONFIRM_MODAL = 'DELETE_CONFIRM_MODAL';
export const PUT_CURRENT_ITEM_DELETE = 'PUT_CURRENT_ITEM_DELETE';
export const SELECT_CURRENT_LIST_ITEM_IN = 'SELECT_CURRENT_LIST_ITEM_IN';
export const SELECT_ALL_LIST_ITEM_IN = 'SELECT_ALL_LIST_ITEM_IN';
export const UN_SELECT_CURRENT_LIST_ITEM_IN = 'UN_SELECT_CURRENT_LIST_ITEM_IN';
export const UN_SELECT_ALL_LIST_ITEM_IN = 'UN_SELECT_ALL_LIST_ITEM_IN';
export const CLOSE_HS_MODAL = 'CLOSE_HS_MODAL';
export const OPEN_HS_MODAL = 'OPEN_HS_MODAL';
export const CURR_SELECT_ITEM = 'CURR_SELECT_ITEM';
export const CLEAR_CURR_SELECT_ITEM = 'CLEAR_CURR_SELECT_ITEM';
export const REBOOT_HS_SHOWMODAL = 'REBOOT_HS_SHOWMODAL';
export const REBOOT_HS_HIDEMODAL = 'REBOOT_HS_HIDEMODAL';

export const STOP_ALL_MODAL_START = 'STOP_ALL_MODAL_START';
export const STOP_ALL_MODAL_FINISH = 'STOP_ALL_MODAL_FINISH';

export const SHOW_DELETE_ALL_MACHINES_MODAL = 'SHOW_DELETE_ALL_MACHINES_MODAL';
export const HIDE_DELETE_ALL_MACHINES_MODAL = 'HIDE_DELETE_ALL_MACHINES_MODAL';

export const UPDATE_SELECTED_ITEMS_STATE  = 'UPDATE_SELECTED_ITEMS_STATE';

export const INSTANCE_REINSTALL_SHOW = 'INSTANCE_REINSTALL_SHOW';
export const INSTANCE_REINSTALL_HIDE = 'INSTANCE_REINSTALL_HIDE';


export const TOGGLE_FREEZE_SELECT_ALL_ACTION = 'TOGGLE_FREEZE_SELECT_ALL_ACTION';


export const CREATING_MACHINE_INSTANCE_TOGGLE = 'CREATING_MACHINE_INSTANCE_TOGGLE';

export const TENURE_DATA_STORE = 'TENURE_DATA_STORE';

export const CLEAR_INSTANCES_STORE = 'CLEAR_INSTANCES_STORE';

export const CALL_LIST_RENEWAL = 'CALL_LIST_RENEWAL';