import React from 'react'

function FirewallSVG({color}) {
    return (
        <svg viewBox="0 0 215 215" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M214.84 136.33a31.58 31.58 0 00-28.76-28.18A51.33 51.33 0 0099.79 59.5v26.29h33.27v28.18h33.26v35.43H26v-28.18H10.75a39 39 0 0035.88 49.54H186.5a31.37 31.37 0 0028.34-34.43zM66.5 93.04h59.3v20.92H66.5zM33.26 64.86H92.5v20.92H33.26zM0 93.04h59.27v20.92H0zm33.26 28.18H92.5v20.92H33.26zm66.53 0h59.27v20.92H99.79z"
                fill={`${color}`}
            ></path>
        </svg>
    )
}

export default FirewallSVG;