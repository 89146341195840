import React from 'react'
import { getScriptTableService } from '../../../../../../../services/InstancesServices/InstancesListService';
import ScriptMarketContext from '../ScriptMarketContext/ScriptMarketContext';
import ViewoutputTable from './ViewoutputTable';

function ParentViewOutput() {

    const {outputList,getOutputData} = React.useContext(ScriptMarketContext);
   

    React.useEffect(function (){
        getOutputData();
    },[]);

  return (
    <ViewoutputTable outputList={outputList} />
  )
}

export default ParentViewOutput