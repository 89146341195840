import React from 'react'
import ImageHs from '../../../../hireserver/ImageHs'
import { imageReplaceHashHelper } from '../../../../../../utils/Helpers'

function DeployRecItem({nodevalues,selectLocation,nodename}) {
  return (
    <div className='row'>
        <div className='col-4'>{nodename}</div>
        <div  className='col-4 '>
            <ImageHs width="15px"  src={imageReplaceHashHelper(selectLocation?.img)}/>
        </div>
        <div  className='col-4 text-center'>{nodevalues?.num}</div>
    </div>
  )
}

export default DeployRecItem