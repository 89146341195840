import React from 'react'

function DeleteHsIcon({width}) {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width={width} viewBox="0 0 32.000000 32.000000"
    preserveAspectRatio="xMidYMid meet">
   
   <g transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
   fill="#000000" stroke="none">
   <path d="M135 290 c-3 -5 -16 -10 -29 -10 -22 0 -46 -17 -46 -32 0 -4 45 -8
   100 -8 55 0 100 4 100 8 0 15 -24 32 -46 32 -13 0 -26 5 -29 10 -3 6 -15 10
   -25 10 -10 0 -22 -4 -25 -10z"/>
   <path d="M64 206 c-3 -8 -2 -51 3 -97 l8 -84 85 0 85 0 8 84 c5 46 6 89 3 97
   -8 20 -184 20 -192 0z m63 -118 c-2 -18 -4 -6 -4 27 0 33 2 48 4 33 2 -15 2
   -42 0 -60z m80 0 c-2 -18 -4 -6 -4 27 0 33 2 48 4 33 2 -15 2 -42 0 -60z"/>
   </g>
   </svg>
  )
}

export default DeleteHsIcon