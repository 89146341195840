class StaticSignalr{
    static isBinding = false;
    static userId = '';
    static nameUsername = '';
    static unibaseId = '';
    static tenantId = '';
    static sessionId = '';           
}


export default StaticSignalr;