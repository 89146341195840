import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { Link } from "react-router-dom";
import { GB_TO_TB, MB_TO_GB } from "../../../../../../../utils/Emuns";
import { humanFileSize } from "../../../../../../../utils/Helpers";
import PasswordInputHS from "../../../../../hireserver/controls/PasswordInputHS";
import ShowPasswordInputHS from "../../../../../hireserver/controls/ShowPasswordInputHS";

export default function RunningStatus({currDetailsItem}) {
  const {branchname,noofblocks,privateip,password,ram,groupname,appname,appversion,cpu,storage,publicip,username,operatingsystemname} = currDetailsItem;
  function copyHandler(syb,value){
    //console.log(value)
    navigator.clipboard.writeText(value);
   

        if(syb === 'pr'){
          setShow(true);
          setTimeout(function(){
            setShow(false)
          },300);
        }else{
          setPublicShow(true);
          setTimeout(function(){
            setPublicShow(false)
          },300);
        }
}
const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Copied!
  </Tooltip>
);

const [show,setShow] = React.useState(false);
const [publicShow,setPublicShow] = React.useState(false);
 const target = React.useRef(null);
  return (
    <div className="row">
      <div className="col-12 col-md-4 usage-graphs">
        <div className="card shadow-none">
          <div className="border-0 p-0">
              <div className='d-flex justify-content-between py-1 px-3 border-bottom'>
                  <div className='fs-16 font-w500 text-dark-0'>Location</div>
                  <div className='fs-14 text-body font-w300'>{branchname}</div>
              </div>
              <div className='d-flex justify-content-between py-1 px-3 border-bottom'>
                  <div className='fs-16 font-w500 text-dark-0'>Private IP</div>
                  <div className='fs-14 text-body font-w300'>{privateip}  
                  <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
      show={show}
    >
      <span onClick={()=>copyHandler('pr',privateip)} className='px-1'> <i className="fa fa-copy"></i></span>
    </OverlayTrigger>
    </div>
              </div>
              <div className='d-flex justify-content-between py-1 px-3 border-bottom'>
                  <div className='fs-16 font-w500 text-dark-0'>Public IP</div>
                  <div className='fs-14 text-body font-w300'>{publicip}  {publicip && <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
      show={publicShow}
    >
      <span onClick={()=>copyHandler('pu',publicip)} className='px-1'> <i className="fa fa-copy"></i></span>
    </OverlayTrigger>}</div>
              </div>
              <div className='d-flex justify-content-between py-1 px-3 border-bottom'>
                  <div className='fs-16 font-w500 text-dark-0'>UserName</div>
                  <div className='fs-14 text-body font-w300 text-capitalize'>{username}</div>
              </div>
              <div className='d-flex justify-content-between py-1 px-3 border-bottom'>
                  <div className='fs-16 font-w500 text-dark-0'>Password</div>
                  {/* <div className='fs-14 text-body font-w300'>*********</div> */}
                 <ShowPasswordInputHS value={password} />
              </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-4 usage-graphs">
        <div className="card shadow-none">
        <div className="border-0 p-0">
              <div className='d-flex justify-content-between py-1 px-3 border-bottom'>
                  <div className='fs-16 font-w500 text-dark-0'>CPU</div>
                  <div className='fs-14 text-body font-w300'>{noofblocks} Core</div>
              </div>
              <div className='d-flex justify-content-between py-1 px-3 border-bottom'>
                  <div className='fs-16 font-w500 text-dark-0'>RAM</div>
                  <div className='fs-14 text-body font-w300'>{humanFileSize(ram*MB_TO_GB,true)}</div>
              </div>
              <div className='d-flex justify-content-between py-1 px-3 border-bottom'>
                  <div className='fs-16 font-w500 text-dark-0'>Storage</div>
                  <div className='fs-14 text-body font-w300'> {humanFileSize(storage*GB_TO_TB,true)} Nvme</div>
              </div>
              <div className='d-flex justify-content-between py-1 px-3 border-bottom'>
                  <div className='fs-16 font-w500 text-dark-0'>Band width</div>
                  <div className='fs-14 text-body font-w300'>Unmetered</div>
              </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-4 usage-graphs">
        <div className="card shadow-none">
        <div className="border-0 p-0">
              <div className='d-flex justify-content-between py-1 px-3 border-bottom'>
                  <div className='fs-16 text-dark-0 font-w500'>Label</div>
                  <div className='fs-14 text-body font-w300'>{username}</div>
              </div>
              {/* <div className='d-flex justify-content-between py-1 px-3 border-bottom'>
                  <div className='fs-16 text-dark-0 font-w500'>Tag</div>
                  <div className='fs-14 text-body font-w300'>Production</div>
              </div> */}
             {!appname &&
               <div className='d-flex justify-content-between py-1 px-3 border-bottom'>
               <div className='fs-16 text-dark-0 font-w500'>OS</div>
               <div className='fs-14 text-body font-w300'><span className="px-1"> {groupname} </span>{operatingsystemname}</div>
           </div>
             }
             {appname&& <div className='d-flex justify-content-between py-1 px-3 border-bottom'>
                  <div className='fs-16 text-dark-0 font-w500'>OS</div>
                  <div className='fs-14 text-body font-w300'><span className="px-1"> {appname} {appversion } + </span>{groupname} {operatingsystemname}</div>
              </div>}
              {/* <div className='d-flex justify-content-between py-1 px-3 border-bottom'>
                  <div className='fs-16 text-dark-0 font-w500'>Application</div>
                  <div className='fs-14 text-body font-w300'>WordPress</div>
              </div> */}
        </div>
        </div>
      </div>
    </div>
    // <Card style={{ width: "100rem" }} className="detail-card">
    //   <ListGroup variant="flush">
    //     <ListGroup.Item>
    //       <div className="d-flex justify-content-between border-bottom">
    //         <div className="d-flex">
    //           <p className="dot"></p>
    //           <p>&nbsp;RUNNING</p>
    //         </div>
    //         <div className="d-flex">
    //           <Link to="#" className="px-3 detail-right">
    //             Power Off
    //           </Link>
    //           <Link to="#" className="px-3 detail-right">
    //             Reboot
    //           </Link>
    //           <Link to="#" className="px-3 detail-right">
    //             Launch LISH Console
    //           </Link>
    //           <Link to="#" className="px-3">
    //             <svg
    //               width="24px"
    //               height="24px"
    //               viewBox="0 0 24 24"
    //               version="1.1"
    //             >
    //               <g
    //                 stroke="none"
    //                 stroke-width="1"
    //                 fill="none"
    //                 fill-rule="evenodd"
    //               >
    //                 <rect x="0" y="0" width="24" height="24"></rect>
    //                 <circle fill="#2575d0" cx="5" cy="12" r="2"></circle>
    //                 <circle fill="#2575d0" cx="12" cy="12" r="2"></circle>
    //                 <circle fill="#2575d0" cx="19" cy="12" r="2"></circle>
    //               </g>
    //             </svg>
    //           </Link>
    //         </div>
    //       </div>
    //     </ListGroup.Item>
    //     <ListGroup.Item className="border-bottom">
    //       <div class="row">
    //         <div class="col-5 col-lg-3">
    //           <p className="font-weight-600 font-w600">Summary</p>
    //           <div className="d-flex summary-list">
    //             <ul className="pr-5 font-14">
    //               <li className="py-2 threshold-content">1 CPU Core</li>
    //               <li className="py-2 threshold-content">1 GB RAM</li>
    //             </ul>
    //             <ul className="pr-5 font-14">
    //               <li className="py-2 threshold-content">25 GB Storage</li>
    //               <li className="py-2 threshold-content">0 Volumes</li>
    //             </ul>
    //           </div>
    //         </div>
    //         <div class="col-7 col-lg-9 d-flex addresses">
    //           <div className="col">
    //             <p className="font-weight-600 font-w600">IP Addresses</p>
    //             <div>
    //               <ul className="d-flex mb-1">
    //                 <li className="px-3 py-2 font-14 run_data w-100 threshold-content text-black-50">
    //                   45.79.107.181
    //                 </li>
    //                 <li>
    //                   <i class="copy_content fa fa-copy"></i>
    //                 </li>
    //               </ul>
    //               <ul className="d-flex mb-1">
    //                 <li className="px-3 py-2 font-14 run_data w-100 threshold-content text-black-50">
    //                   2600:3c01:f03c:91ff:fed5:f8bf
    //                 </li>
    //                 <li>
    //                   <i class="copy_content fa fa-copy"></i>
    //                 </li>
    //               </ul>
    //             </div>
    //           </div>
    //           <div className="col">
    //             <p className="font-weight-600 font-w600">Access</p>
    //             <div>
    //               <ul className="d-flex mb-1">
    //                 <li className="px-3 py-2 font-14 w-100 access_feild threshold-content">
    //                   SSH Access
    //                 </li>
    //                 <li className="px-3 py-2 font-14 run_data w-100 threshold-content text-black-50">
    //                   SSH root@45.79.107.181
    //                 </li>
    //                 <li>
    //                   <i class="copy_content fa fa-copy"></i>
    //                 </li>
    //               </ul>
    //               <ul className="d-flex mb-1">
    //                 <li className="px-3 py-2 font-14 w-100 access_feild threshold-content">
    //                   LISH Console via SSH
    //                 </li>
    //                 <li className="px-3 py-2 font-14 w-100 run_data lish_ssh threshold-content text-black-50">
    //                   SSh -t atlury@lish - fremont.instance.com
    //                 </li>
    //                 <li>
    //                   <i class="copy_content fa fa-copy"></i>
    //                 </li>
    //               </ul>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </ListGroup.Item>
    //     <ListGroup.Item>
    //       <div class="row">
    //         <div class="col-6 col-md-2 p-0">
    //           <span className="font-w600">Plan : </span>
    //           <span className="font-14 font-weight-light text-black-50">
    //             Nanode 1 GB
    //           </span>
    //         </div>
    //         <div class="col-6 col-md-2 p-0">
    //           <span className="font-w600">Region : </span>
    //           <span className="font-14 font-weight-light text-black-50">
    //             Fremont, CA
    //           </span>
    //         </div>
    //         <div class="col-6 col-md-2 p-0">
    //           <span className="font-w600">Instance ID : </span>
    //           <span className="font-14 font-weight-light text-black-50">
    //             3109362
    //           </span>
    //         </div>
    //         <div class="col-6 col-md-3 p-0">
    //           <span className="font-w600">Created : </span>
    //           <span className="font-14 font-weight-light text-black-50">
    //             2017-05-14 17:01
    //           </span>
    //         </div>
    //       </div>
    //     </ListGroup.Item>
    //   </ListGroup>
    // </Card>
  );
}
