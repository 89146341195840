import React from 'react'
import NodeBalanceContext from '../../../NodeBalanceContext/NodeBalanceContext'
import Select, { components } from 'react-select';
import CustomInputLabelSubText from '../../../../../hireserver/controls/CustomInputLabelSubText';
import HeadingHS from '../../../../../hireserver/controls/HeadingHS';

export default function ActiveHealthChecks() {
    const { selectTagOption, setSelectTagOption } = React.useContext(NodeBalanceContext);
    function setSelectedOption(){

    }

  return (
    <div className="row">
    <div className="col-12 col-lg-6">
        <HeadingHS as="h3"  title="Active Health Checks" />
        <CustomInputLabelSubText title="Type" subText="Roundrobin. Least connections assigns connections to the backend with the least connections. Source uses the client's IPv4 address.">
            <Select
                defaultValue={selectTagOption[0]}
                onChange={setSelectedOption}
                options={selectTagOption}
                style={{
                    lineHeight: '40px',
                    color: '#7e7e7e',
                    paddingLeft: ' 15px',
                }}
            />
        </CustomInputLabelSubText>
    </div>
    <div className="col-12 col-lg-6">
        
        <HeadingHS as="h3"  title="Passive Checks" />
   
       <div className='py-lg-4'>
       <CustomInputLabelSubText title={''} subText="Source uses the client's IPv4 address.">
        <label className="switch"><input type="checkbox"/><span className="slider"></span></label>
        </CustomInputLabelSubText>
       </div>
    </div>
</div>
  )
}
