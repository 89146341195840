import { login, saveTokenInLocalStorage } from "../../../services/AuthService";
import { AUTH_ALERT_ERROR, AUTH_ALERT_SUCCESS, TOGGLE_AUTH_ALERT_MODAL } from "../../types/AuthTypes/AuthAlertTypes";
import { loginConfirmedAction, removeUserCookieAuth, setUserCookieAuth } from "../AuthActions";

export function openAuthModal() {
    return {
        type: TOGGLE_AUTH_ALERT_MODAL,
        payload: true
    }
}

export function closeAuthModal() {
    return {
        type: TOGGLE_AUTH_ALERT_MODAL,
        payload: false
    }
}

export function setErrAuthModal(payload) {
    return {
        type: AUTH_ALERT_ERROR,
        payload
    }
}

export function setSuccessAuthModal(payload){
    return{
        type:AUTH_ALERT_SUCCESS,
        payload
    }
}

export function loginAuthAlert(email, password,history,url,setPassword,setLoading) {
    return async (dispatch, state) => {
        try {
            setLoading(true);
            const res = await login(email, password);
            const { errors, message } = res.data;
            if (errors !=null && errors.length) {
                setLoading(false);
               dispatch(setErrAuthModal({
                isError: true,
                message
            }))
                return;
            }
            debugger;
            //removeUserCookieAuth();
            // let { unibaseId, sessionId, userId } = res.data.result;
            res.data.result['userEmail'] = email;
            let { unibaseId, sessionId, userId } = res.data.result;
            debugger;
            //CookieHelper.setCookie({unibaseId, sessionId, userId,userEmail:email,email});
          setUserCookieAuth({unibaseId, sessionId, userId,userEmail:email,email});
           /// setUserCookieAuth({ userEmail:email,...res.data.result });
            saveTokenInLocalStorage({ userEmail:email,...res.data.result});
            dispatch(loginConfirmedAction({
                userEmail:email,...res.data.result
            }))
           dispatch( setSuccessAuthModal({
            isDone:true,
            message:'Login Successfully'
        }));
            
            setTimeout(() => {
                setLoading(false);
                setSuccessAuthModal({
                    isDone:false,
                    message:''
                });
                setPassword('')
                dispatch(closeAuthModal());
                history.push('/hireloading')
                history.push(url);
            }, 2000);
            

        } catch (error) {
            // console.log(error)
            setLoading(false);
            dispatch(setErrAuthModal({
                isError: true,
                message:error
            }))
        }
    }
}