export default function ViewMachine({ handler, title }) {
    function handlerClick(e) {
      handler(e);
    }
    return (
      <span className="btn btn-sm px-3 py-1 text-white border-0"
        onClick={handlerClick}
        style={{ background: 'linear-gradient(to bottom, rgba(35,119,102,1) 25%, rgba(52,160,139,1) 75%)' }}>
        {title}
      </span>
    )
  }