import React from 'react'
import MultiSelectTag from '../../../../../hireserver/controls/MultiSelectTag/MultiSelectTag';

function CronJob({cronjobsList,setCronValue,cronvalue,customCronValue,setCustomCronValue }) {
    
  React.useEffect(()=>{
    if(cronvalue.value != 'custom' ){
      setCustomCronValue(null);
    }
  },[cronvalue.value])
   
  return (
    <div className='row'>
        <div className='col-sm-6'>
        <MultiSelectTag isMulti={false} noOptions="Your machines assign to other firewall groups or you have zero machines. " values={cronvalue} changeHandler={(e) => setCronValue(e)} options={cronjobsList}   />
        </div>
       
            {cronvalue.value == 'custom' ?  <div className='col-sm-6'><input value={customCronValue} onChange={(e)=>setCustomCronValue(e.target.value)} placeholder='0 */6 * * *' type="text" className="form-control hs_borderRadius-4" /></div> : null}
            
 </div>
    
  )
}

export default React.memo(CronJob)