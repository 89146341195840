import React from 'react'
import ImageHs from '../../../../components/hireserver/ImageHs';
import bellImg from '../../../../../images/hireserver/imgs/notification.png';
import ButtonHS from '../../../../components/hireserver/controls/ButtonHS';
import SpacerHS_TWO from '../../../../components/hireserver/Spaces/SpacerHS_TWO';
import SpacerHS_ONE from '../../../../components/hireserver/Spaces/SpacerHS_ONE';

function EmptyNotification({handleRedirect}) {
  return (
    <div className='d-flex h-100  align-items-center justify-content-center flex-column'>
        <div className='d-flex flex-column align-items-center justify-content-center'>
            <ImageHs width="200px" height="200px"  alt="notification are zero" src={bellImg}  />
            <SpacerHS_ONE/>
            <div>You have 0 notifications currently</div>
        </div>
        <SpacerHS_ONE/>
        <div  className='text-center'>
        <ButtonHS handler={handleRedirect} classes="hs-signin-btn px-3 py-2">
                    View All
                </ButtonHS>
        </div>
    </div>
  )
}

export default EmptyNotification