const obj = {
    "INR": "₹",
    "USD": "$"
}

export function getCurrencySymbol(type='INR', value=0) {
    
    try{
        if (type) {
            const ans = new Intl.NumberFormat('en-US', { style: 'currency', currency: type }).format(value);;
            const res = ans.split(/(\D+)/);
            const currencySymbol = res[1];
            const amount = res.slice(2,res.length).join('')
    
    
            return [currencySymbol,amount]
        } else {
            console.log("errrrrrrrrrrrrrrrrrrrrrrrrr",type);
            return ['',0];
        }
    }catch(err){
        console.log("errrrrrrrrrrrrrrrrrrrrrrrrr",err);
        return ['',0];
    }
}

export function justGetCurrencySymbol(type){
    return obj[type];
}