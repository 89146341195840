import React from 'react'
import { connect, useDispatch } from 'react-redux';
import { openPaymentInfoFormAction } from '../../../../../store/actions/PaymentInfoActions/PaymentInfoAction';
import StaticPaymentDetails from '../../../../../utils/StaticsClasses.js/StaticPaymentDetails';
import CompanyInfoTable from './CompanyInfoTable'
import IndividualInfoTable from './IndividualInfoTable';

function InfoTableData({ organizationtype, paymentData, isShowPayment }) {
  const dispatch = useDispatch();
  const [isNew, setNew] = React.useState(false);
  React.useEffect(function () {
   
    StaticPaymentDetails.isEditing = true;

    return () => {
      StaticPaymentDetails.isEditing = false;
    }
  }, []);

  function handlerNewAccount(){
    dispatch(openPaymentInfoFormAction());
  }

  return (
    <React.Fragment>
      {
        isShowPayment === 0 ? <div className='border px-2 py-4'>
          <button className='btn cursor-pointer hs_borderRadius-4  hsButton-bg text-white undefined' onClick={handlerNewAccount}>Update User Details</button>
        </div>
          :
          <div className='border px-2 py-4'>
            {organizationtype == 1 ? <CompanyInfoTable /> : <IndividualInfoTable/>}
          </div>
      }
    </React.Fragment>
  )
}
const mapStatesToProps = ({ auth, PaymentInfoReducer }) => {
  return {
    paymentData: PaymentInfoReducer?.paymentData,
    organizationtype: PaymentInfoReducer?.paymentData?.organizationtype,
    isShowPayment: PaymentInfoReducer?.paymentData?.defaultaddressid,
  };
};


export default connect(mapStatesToProps)(InfoTableData);