
import { postClusterCreateReplicaOrderURL, postServeravailAbilityClustersURL } from "../../API_ENDPOINTS/API_ENDPOINTS";
import { API_SERVICES } from "../API_SERVICES";
import { getSessionAndUserId } from "../InstancesServices/InstancesListService";

export function postClusterCreateReplicaOrderService(payload){
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'post',
        url:postClusterCreateReplicaOrderURL(),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
        data:payload
    };

    return API_SERVICES(config);
}

export function postServeravailAbilityClustersService(payload){
    let  { userId, sessionId } = getSessionAndUserId();
    let config = {
        method: 'post',
        url:postServeravailAbilityClustersURL(),
        headers: {
            'Authorization': `Basic ${sessionId}`,
            'Content-Type': 'application/json'
        },
        data:payload
    };

    return API_SERVICES(config);
}

