import axios from 'axios';
import { MDBDataTable } from 'mdbreact'
import React from 'react'
import { connect, useDispatch } from 'react-redux';
import { bareMetalViewURL, getViewBareMetal_IP_Name, twoViewURL, updateMachineURL } from '../../../../API_ENDPOINTS/API_ENDPOINTS';
import { closeDeleteModalBareMetal,selectAllBareMetalItem, closeRebootModalBareMetal, closeStopBareMetal, getBareMetalList, unCheckAllBareMetal, selectCurrentBareMetalListItem, unCheckCurrentBareMetalListItem } from '../../../../store/actions/BareMetalActions/BareMetalAction';
import { VM_ActionCall_BAREMETAL, VM_ACTION_BARE_STARTED } from '../../../../store/actions/BareMetalActions/BareMetalCurrItemAction';
import ModalHs from '../../hireserver/ModalHs';
import CheckBoxControl from '../Instances/InstancesListComponents/CheckBoxControl';
import { handlerAlert } from '../Instances/InstancesListComponents/DropDownInstancesList';
import LocationList from '../Instances/InstancesListComponents/LocationList';
import OSList from '../Instances/InstancesListComponents/OSList';
import StatusShow from '../Instances/InstancesListComponents/StatusShow';
import ViewConsoleBtn from '../Instances/InstancesListComponents/ViewConsoleBtn';
import ViewDetailsLink from '../Instances/InstancesListComponents/ViewDetailsLink';
import DropdownBareMetal from './ListComponents/DropdownBareMetal';
import warming from './../../../../images/hireserver/imgs/warming.jpg';
import { getSessionAndUserId } from '../../../../services/InstancesServices/InstancesListService';
import ParentCheckBoxControlBareMetal from './ListComponents/ParentCheckBoxControlBareMetal';
import { getBareMetalViewLoginSessionService } from '../../../../services/BareMetalServices/BareMetalListService';
import { API_CALL } from '../../../../services/API_SERVICES';
import StaticBareMetalList from '../../../../utils/StaticsClasses.js/StaticBareMetalList';
import RenewalBtn from '../Instances/InstancesListComponents/RenewalBtn';
import CurrencyFC from '../../hireserver/components/Currency/CurrencyFC';

 function BareMetalList({bareMetalList,currencyname,curSelectItem,stopModalBareMetal,showDeleteModal,rebootShowModal,userDetails}) {
  const dispatch = useDispatch();
  const listRef = React.useRef(null);
  const [modalStopInput,setModalStopInput] = React.useState({
    text:'',
    error:false
  })
  const [modalInputDelete,setModalInputDelete] = React.useState({
    text:'',
    error:false
  })
  const [rebootText,setRebootText] =  React.useState({
    text:'',
    error:false
  })
  const [col, setCols] = React.useState([
    // {
    //   label: <ParentCheckBoxControlBareMetal handleClick={handlerCheckItem} dataAttr="allitems" id="787" />,
    //   field: "check",
    // },

    {
      label: "Machine Name",
      field: "name",
      sort: "asc",
      width: 100,
      attributes: { className: "col-3 needarrowtable" },
    },
    
    // { label: "date", field: "date", sort: "asc" },
    {
      label: "OS",
      field: "os",
      sort: "asc",
      attributes: { "data-head": "os", className: "text-center needarrowtable" },
    },
    {
      label: "Location",
      field: "location",
      sort: "asc",
      attributes: {
        "data-head": "location",
        "className":'needarrowtable'
      },
    },
    { label: "Charges", field: "amount", sort: "asc needarrowtable",attributes:{
      className:'needarrowtable'
    } },
   // {label:"Due Date", field:"dueDate",sort: "asc", attributes: { className: "col-3" },},
    { label: "Console", field: "console"},
    { label: "Status", field: "status", sort: "asc", attributes: { className: "text-center needarrowtable" }},
    { label: "", field: "dropdown", sort: "asc" ,attributes: { className: "text-center " }},
  ]);
  const [options, setOptions] = React.useState({
    filterType: "checkbox",
  });

  const [formatData, setFormatData] = React.useState({
    columns: [],
    rows: []
  })

  React.useEffect(function (){
    StaticBareMetalList.isUnMounted = false;
    return () => {
      StaticBareMetalList.isUnMounted = true;
    }
  },[]);
  function onPageChangeHandler(){
    // console.log('onPageChangeHandler');
  }

  function handlerCheckItem(e,fullData,getIsAllSelect){
    let code = e.target.getAttribute('data-code');
    // '';
    if (code === 'allitems') {
      if(!getIsAllSelect){
       
      
       const visibleListItem = document.querySelectorAll('.bareMetalListTable .instacesClass');
 
       let itemsCodes = {};
       let findIndex = null;
       visibleListItem.forEach(function (el,i){
         let classN = el.classList.value.split(' ');
         if(findIndex === null){
           findIndex = classN.findIndex(item => item.includes('data-class'));
         }
         if(findIndex != null){
           let codeI = classN[findIndex].split('-');
           itemsCodes[codeI[codeI.length-1]] = true;
         }
       });
       // // console.log(itemsCodes)
       dispatch(selectAllBareMetalItem({
         data:itemsCodes
       }));
      }else{
       dispatch(unCheckAllBareMetal());
       
       
      }
      
       
     } else {
      // '';
       let isCheck = e.target.checked;
       if (isCheck) {
        
         dispatch(selectCurrentBareMetalListItem({ code, fullData }))
       } else {
        // '';
         // console.log(isCheck, 'isCheck')
         dispatch(unCheckCurrentBareMetalListItem({ code, fullData }));
       }
     }
  }

  async function onClickViewBtn(e,machineCode,hostname){
    let url = bareMetalViewURL(hostname);
    // console.log()
    // let userdetails = userDetails;
    let payload = JSON.stringify({"method":"login","user_login":"administrator","password":"Qazplm@4#2"});
     const {data} = await getBareMetalViewLoginSessionService(payload,hostname);
    if(data.result === ''){
      handlerAlert("danger","no result",'danger');
      return;
    }

    const session_key = JSON.parse(data.result).session_key;


 
    let ipoURL = getViewBareMetal_IP_Name();
  document.cookie = `sessionKey=${session_key};path="/";domain=hireserver.com;`
  let urlBare = `${hostname}/irc.html`
  window.open(urlBare, '_blank')


    // axios(config)
    //   .then(function (response) {
    //     let r = JSON.stringify(response.data)
    //     let s = JSON.parse(r)
    //     let responsedata = JSON.parse(s.result)
    //     let token = responsedata.ticket
       
    //   })
    //   .catch(function (error) {
    //     handlerAlert('danger',error['message'],'danger')
    //   });


  }

  async function deleteAll() {
    const { userId, sessionId } = getSessionAndUserId();
    //axios(config);
    for (let i = 0; i < bareMetalList.length; i++) {
      const { machineid } = bareMetalList[i];
      let url = updateMachineURL(machineid, 3);

      var config = {
        method: 'post',
        url,
        headers: {
          'Authorization': 'Basic ' + sessionId
        }
      };
      const deleteAll = await axios(config);
     // console.log(deleteAll);
    }
  }


  React.useEffect(function (){
    //deleteAll();
    let rowComps = [];
    for (let i = 0; i < bareMetalList.length; i++) {
   
      const { code, ipaddress,icon, createddate, machinename, loadingInfo, machineid, machinetypename,operatingsystemname,osiconpath, regionname, hostname,statusid,refstatusid, regionimage, machinetype ,dueamount,duedate,publicip,noofblocks,groupname} = bareMetalList[i];
    
      //let incs = getSelectedInstances[code] != undefined;
      let appCompBtn = refstatusid != 12 ? <DropdownBareMetal disabledClass={bareMetalList[i]?.consoleDisabled}  nodeType={bareMetalList[i].nodeType} itemData={bareMetalList[i]} machinename={machinename} machinetype={machinetype} statusid={refstatusid} machineid={machineid} code={code} />:<RenewalBtn fullData={bareMetalList[i]} />
      let newCol = {
        // check: <CheckBoxControl isCheck={bareMetalList[i].isChecked} fullData={bareMetalList[i]} id={code * 6} dataAttr={code} handleClick={handlerCheckItem} />,
        name: [<ViewDetailsLink isBare={true} disabledClass={bareMetalList[i]?.consoleDisabled} privateip={bareMetalList[i].privateip} machineId={bareMetalList[i].machineid} noofblocks={noofblocks} publicip={publicip} item={bareMetalList[i]} code={code} searchvalue={machinename} machinename={machinename} ipaddress={ipaddress}>{machinename}</ViewDetailsLink>],
       
         search_name: machinename.toLowerCase(),
        search_location: regionname.toLowerCase(),
        search_ip:publicip,
        date: createddate,
        os: <OSList groupname={groupname} title={operatingsystemname} imgSrc={osiconpath} />,
        location:<LocationList locationName={regionname} imgSrc={icon} />,
        dueDate:duedate,
        console: <ViewConsoleBtn clickHandler={(e) => onClickViewBtn(e, code,bareMetalList[i].hostname)} />,
        amount: <div className='d-flex flex-column align-items-center'><div></div><div><span className='pr-1'> <CurrencyFC name={currencyname} val={dueamount} /></span></div><div className='text-danger'>{duedate}</div></div>,
        status: <StatusShow machineid={machineid} isColor={loadingInfo.color} isText={loadingInfo.text} isSpinning={loadingInfo.loading} statusid={refstatusid} />,
        dropdown:appCompBtn
        //dropdown: <Dropdownblog2  recordid={machineid} machineid={code} machinetype={machinetype}/>
      }
      rowComps.push(newCol)
    }
    
    setFormatData({
      columns: col,
      rows: rowComps
    })
    // console.log(formatData)
  },[bareMetalList]);

  function handleModalClose() {
    // '';
    dispatch(closeStopBareMetal());
  }

  function handlerStop(e) {
    const { url, method, machineid, num, statusId, machinename } = curSelectItem;
    // if (machinename != modalStopInput.text) {
    //   setModalStopInput({
    //     text: modalStopInput.text,
    //     error: true
    //   });
    //   return
    // }

    dispatch(closeStopBareMetal());
    //dispatch(startLoadingInstaces());
    dispatch(VM_ACTION_BARE_STARTED(curSelectItem));
    dispatch(VM_ActionCall_BAREMETAL(url, method, machineid, num, statusId, handlerAlert, curSelectItem));
    setModalStopInput({
      text: '',
      error: false
    });

  }

  function deleteHandler(e){
    const { url, method, machineid, num, statusId, machinename } = curSelectItem;

    if (machinename != modalInputDelete.text) {
      setModalInputDelete({
        text:modalInputDelete.text,
        error:false
      });
      return
    }

    dispatch(closeDeleteModalBareMetal());
    //dispatch(startLoadingInstaces())
    dispatch(VM_ACTION_BARE_STARTED(curSelectItem));
    dispatch(VM_ActionCall_BAREMETAL(url, method, machineid, num, statusId, handlerAlert, curSelectItem));
    setModalInputDelete({
      text:'',
      error:false
    });
  }

  function handleCloseDelete(){
    dispatch(closeDeleteModalBareMetal())
  }

  function handleReboot(){

  }

  function handleRebootModalClose(){
    dispatch(closeRebootModalBareMetal())
  }



  return (
   <React.Fragment>
        <div className="row">
        <div className="col-xl-12" style={{ position: 'relative' }}>
          <div className="table-responsive">
            <div className="display mb-4 dataTablesCard customer-list-table instances-table-card">
              <MDBDataTable paginationLabel={['‹','›']}  disableRetreatAfterSorting={true} className='bareMetalListTable'  data={formatData} options={options} />
             
            </div>
          </div>  

        </div>
      </div>
  
      <ModalHs handleClose={handleModalClose} okayHandler={handlerStop} showModalHS={stopModalBareMetal} btnTitle="Stop Machine" >
        <div className='w-100'>
          <div className='w-100 d-flex'>  <div>Are you sure to stop this <span className='font-w600'>{curSelectItem.machinename}</span> Machine?  </div> </div>
          {/* <input className='form-control mt-2' value={modalStopInput.text} placeholder='Type the machine name to stop the machine' style={{ borderRadius: '4px' }} onInput={(e) => setModalStopInput({
            text: e.target.value,
            error: false
          })} />
          {modalStopInput.error && <div className='text-center text-danger mt-2'>The Input is wrong</div>} */}
        </div>
      </ModalHs>
      <ModalHs handleClose={handleCloseDelete} showModalHS={showDeleteModal} okayHandler={deleteHandler} btnTitle="Destory Machine" >
        <div style={{ height: '20px', width: '50px' }}>
          <img className='w-100 h-100' src={warming} alt="Delete Machine" />
        </div>
        <div className='d-flex flex-column  d-gap-20'>
          <div>
            <div>Delete Server</div>
            <div className='font-w600'>{curSelectItem.machinename}</div>
          </div>
          <div className='d-flex flex-column  d-gap-10'>

            <div className='hs_text-light '>By deleting this Bare Metal Cloud Server you won't be able to log in and access it's content. This change can not be undone.
            </div>
            <div className='hs_text-light '>
              Are you sure you want to continue and delete this server?
            </div>
            <div>
              <div>
                Enter the selected server's hostname (any case) to confirm:
              </div>
            </div>
            {/* <input className='form-control' style={{ borderRadius: '4px' }} value={modalInputDelete.text} onInput={(e)=>setModalInputDelete({
              text:e.target.value,
              error:false
            })} /> */}
            {/* {modalInputDelete.error && <div className='text-danger'>Hostname doesn't match. Enter the selected server's hostname (any case).</div>} */}
          </div>
        </div>
      </ModalHs>
      <ModalHs handleClose={handleRebootModalClose} okayHandler={handleReboot} showModalHS={rebootShowModal} btnTitle="Reboot Machine" >
        <div className='w-100'>
          <div className='w-100 d-flex'>  <div>Are you sure to stop this <span className='font-w600'>{curSelectItem.machinename}</span> Machine?  </div> </div>
          {/* <input className='form-control mt-2' value={rebootText.text} placeholder='Type the machine name to stop the machine' style={{ borderRadius: '4px' }} onInput={(e) => setRebootText({
            text: e.target.value,
            error: false
          })} /> */}
          {/* {rebootText.error && <div className='text-center text-danger mt-2'>The Input is wrong</div>} */}
        </div>
      </ModalHs>
   </React.Fragment>
  )
}

const mapsStateToProps = ({bareMetal,auth,PaymentInfoReducer}) => {
  return {
   
    curSelectItem:bareMetal.curSelectItem,
    stopModalBareMetal:bareMetal.closeStopBareMetalModal,
    showDeleteModal:bareMetal.showDeleteModal,
    rebootShowModal:bareMetal.rebootShowModal,
    userDetails:auth.auth,
    currencyname: PaymentInfoReducer.paymentData?.currencyname
    
  }
}

export default connect(mapsStateToProps)(BareMetalList);


