import React from 'react'
import ButtonHS from '../../../components/hireserver/controls/ButtonHS';
import DropDownSelectSearch from '../../../components/hireserver/controls/dropdownselectsearch/DropDownSelectSearch';
import InputLabelUp from '../../../components/hireserver/controls/InputLabelUp/InputLabelUp';
import SpacerHS_TWO from '../../../components/hireserver/Spaces/SpacerHS_TWO';

function PaymentBillingPage() {
    const [email, setEmail] = React.useState({
        val: '',
        isError: '',
        touched: false,
        errorMessage: ''
    });
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]

    function handleChange() {

    }

    return (
        <div className='row'>
            <div className="col-sm-9">
                <div className="card p-4 py-5 position-relative">
                    <div className='billingiconcircle'>
                        <span>
                            <i className='fa fa-user'></i>
                        </span>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">

                            <InputLabelUp type="Company" setValue={(e) => setEmail(prev => { return { ...prev, val: e.target.val } })} value={email.val} id="company" placeholder="Company" />

                        </div>
                    </div>
                    <SpacerHS_TWO />
                    <div className='row'>

                        <div className='col-sm-4'>
                            <InputLabelUp type="text" setValue={(e) => setEmail(prev => { return { ...prev, val: e.target.val } })} value={email.val} id="contactperson" placeholder="Contact Person" />
                        </div>
                        <div className='col-sm-4'>
                            <InputLabelUp type="text" setValue={(e) => setEmail(prev => { return { ...prev, val: e.target.val } })} value={email.val} id="phonenumber" placeholder="Mobile Number" />
                        </div>
                        <div className='col-sm-4'>
                            <InputLabelUp type="email" setValue={(e) => setEmail(prev => { return { ...prev, val: e.target.val } })} value={email.val} id="email" placeholder="Email" />
                        </div>


                    </div>
                    <SpacerHS_TWO />
                    <div className='row'>


                        <div className='col-sm-6'>
                            <InputLabelUp type="text" setValue={(e) => setEmail(prev => { return { ...prev, val: e.target.val } })} value={email.val} id="address" placeholder="Primary Address" />
                        </div>
                        <div className='col-sm-6'>
                            <InputLabelUp type="text" setValue={(e) => setEmail(prev => { return { ...prev, val: e.target.val } })} value={email.val} id="address" placeholder="Secondary Address" />
                        </div>
                    </div>
                    <SpacerHS_TWO />
                    <div className="row">
                        <div className='col-sm-4'>
                            <InputLabelUp type="text" setValue={(e) => setEmail(prev => { return { ...prev, val: e.target.val } })} value={email.val} id="country" placeholder="Country" />
                        </div>

                        <div className='col-sm-4'>
                            <InputLabelUp type="text" setValue={(e) => setEmail(prev => { return { ...prev, val: e.target.val } })} value={email.val} id="city" placeholder="City" />
                        </div>
                        <div className="col-sm-4">
                            <InputLabelUp type="text" setValue={(e) => setEmail(prev => { return { ...prev, val: e.target.val } })} value={email.val} id="pincode" placeholder="Pincode" />
                        </div>
                    </div>
                    <SpacerHS_TWO />
                    <div className="row">
                        <div className="col-sm-6">
                            <InputLabelUp type="text" setValue={(e) => setEmail(prev => { return { ...prev, val: e.target.val } })} value={email.val} id="taxnumber" placeholder="Tax Number" />
                        </div>
                        <div className="col-sm-6">
                            <DropDownSelectSearch placeholder={"Select Curreny"} searchValue={'options'} valueSetter={handleChange} data={options} />
                        </div>
                    </div>
                  
                </div>
            </div>
            <div className="col-sm-3">

                <div>
                    <div className='pl-4 font-16'>
                        Billing Form Type
                    </div>
                    <SpacerHS_TWO />
                    <div className="optionbillingformtype row ">
                        <div className='col-6'>
                            <label htmlFor="singleform" className='align-items-center d-flex justify-content-center'>
                                <input type="radio" className='mx-1' name="formtype" id="singleform" />
                                <span>
                                    Individal
                                </span>
                            </label>
                        </div>
                        <div className='col-6'>
                            <label htmlFor="bussinessform" className='align-items-center d-flex justify-content-center'>

                                <input className='mx-1' type="radio" name="formtype" id="bussinessform" />
                                <span>Business</span>
                            </label>
                        </div>
                    </div>
                    <hr/>
                    <div>
                        <ButtonHS classes="w-100">
                            Save
                        </ButtonHS>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PaymentBillingPage