import React from 'react'
import ModalHs from '../../components/hireserver/ModalHs';
import SpacerHS_TWO from '../../components/hireserver/Spaces/SpacerHS_TWO';
import InputBorderDown from './InputBorderDown';


function OTP_Modal({OTP_Modal, setOTP_Modal,email}) {
 
    function closeHandler() {
        setOTP_Modal(false);
    }
    return (
        <ModalHs isNeed={false} handleClose={closeHandler} size="lg" backdrop="static" okayHandler={() =>  console.log("log")} showModalHS={OTP_Modal} btnTitle={"OTP MODAL"} >
            <div className='w-100 text-center p-3'>
    
                <div className=' font-21 font-w600 text-darkgrey'>
                    Please enter the One-Time Password to verifty your account
                </div>
                <SpacerHS_TWO/>
                <div className='font-w400 text-darkgrey'>
                    A One-Time Password has been sent to <span className='font-w600 text-darkgrey'>email@ex.com</span>
                </div>
                <div className='p-3'>
<InputBorderDown/>

                </div>
                <SpacerHS_TWO/>
                <div>
                    <button className='btn-hsprimary hs_borderRadius-4 border-0'>Validate</button>
                </div>
                <SpacerHS_TWO/>
                <div className='font-w600 text-darkgrey'>
                    Resend One-Time Password
                </div>
            </div>

        </ModalHs>
    )
}

export default OTP_Modal