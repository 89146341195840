import React from 'react'
import { capitalizeFirstLetter, imageReplaceHashHelper } from '../../../../../../utils/Helpers';
import ImageHs from '../../../ImageHs';

function GroupItem({ name, data, handler, inputSearchHandler, setDefaultData,setShowAllOptions, currItemData }) {

    const handlerItem = React.useCallback((item)=>{
  
        return () => {
         

            handler(item);

        
        }
    },[data])

    function intoFun() {
        
        if (!data) {
            return <div>no data</div>;
        }

        return data.map(function (val, i) {
            return <CurrItem currItemData={currItemData}  setShowAllOptions={setShowAllOptions} setDefaultData={setDefaultData} inputSearchHandler={inputSearchHandler} handleClick={handlerItem(val)} name={name} item={val} />
        });
    }

    return (
        <div>
            <div className='font-16 font-w500 py-2'>{capitalizeFirstLetter(name)}</div>
            <div>
                {intoFun()}
            </div>
        </div>
    )
}

export default React.memo(GroupItem);

function CurrItem({ name, item, handleClick, inputSearchHandler,setDefaultData, setShowAllOptions,currItemData }) {

    function handlerMenu(){
        inputSearchHandler(null);
        handleClick();
        setDefaultData();
        setShowAllOptions(false);
    }

    return <div  onClick={handlerMenu} data-filename={item.OSFileName} id={item.OperatingSystemId} className='d-flex w-100 dropdownItemCustomImgEvent cursor-pointer'>
        <div className='d-flex pointerEventNone'>
            <div>{<ImageHs src={imageReplaceHashHelper(item?.OsIconPath)} width="30px" alt={item?.OperatingSystemName} />}</div>
            <div className={currItemData.id === item.OperatingSystemId?'text-primary':''}>{name} {item?.OperatingSystemName}</div>
        </div>
    </div>
}