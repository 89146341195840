import React from "react";
import { Button } from "react-bootstrap";
import { Link,  useRouteMatch } from "react-router-dom";

export default function EmptyDatabase({ emptyDatabaseContent }) {
  let { path, url } = useRouteMatch();
 
  const { icon, title, content, button,svg } = emptyDatabaseContent;
  function getContentContainer() {
    return content.map(({ text, color },index) => {
      return <div key={index} className={`text-${color}`}>{text}</div>;
    });
  }

  return (
    <div
      className="emptyDatabaseContainer container bg-white text-center d-flex flex-column align-items-center justify-content-center"
      style={{ width: 900, gap: "20px" }}
    >
      <div
        style={{
          width: 150,
          height: 150,
          fontSize: "70px",
        }}
        className="icon_bg"
      >
        {/* <i className={`text-green  ${icon}`}></i> */}
        <div className="icon_col">{svg}</div>
      </div>
      <div className="text-dark-0 fs-24 font-w600">{title}</div>
      <div className="contentContainer d-flex flex-column" style={{gap: '20px'}}>{getContentContainer()}</div>
      {button.isTrue && (
        
        <Link to={`${url}/create`} > 
        <Button className="mr-2 bg-primary border-0 menu_title_btn">{button.text}</Button>
        </Link>
      )}
    </div>
  );
}
