import React from 'react'
import { useRouteMatch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { getSupportCloseListURL, getSupportOpenListURL } from '../../../../API_ENDPOINTS/SUPPORT_ENDPOINTS';
import RouteCustomTabs from '../../hireserver/Tabs/RouteCustomTabs';
import CloseTickets from './components/closeTickets/CloseTickets';
import OpenTicket from './components/openTickets/OpenTicket';


function Support() {
    const {path} = useRouteMatch();

    const tabHeaders = React.useMemo(()=>{
        return [{
          name:'Open Tickets',
          link:'opentickets',
          id:9451
        },{
          name:'Closed Tickets',
          link:'closedtickets',
          id:7545
        }
      ]
      },[]);
      const tabsContent = React.useMemo(()=>{
        return [{
          component:<OpenTicket isClose={false} callbackURL={(userid)=>getSupportOpenListURL(userid)} />,
          name:'opentickets',
          id:9784,
        },{
          component:<OpenTicket isClose={true} callbackURL={(userid)=>getSupportCloseListURL(userid)} />,
          name:'closedtickets',
          id:4959,
        }
      ]
      },[]);

      
  return (
   <React.Fragment>
    <RouteCustomTabs activeTab="opentickets" parentPath={path} tabHeaders={tabHeaders}   tabsContent={tabsContent} />
    <ToastContainer className="toast-container"
                toastClassName="dark-toast"
              
                closeButton={false}
            />
   </React.Fragment>
  )
}

export default Support