import { CLOSE_PAYMENT_MODAL, OPEN_PAYMENT_MODAL, PUT_PAYMENT_INFO } from "../../types/PaymentInfoTypes";
import { HIDE_AMOUNT_ADDED_TO_WALLET, PUT_ORDER_TOKEN, SHOW_AMOUNT_ADDED_TO_WALLET } from "../../types/RedirectPageTypes/PaymentRedirectPageTypes";

export function putPaymentInfoStatus(payload){
    return {
      type:PUT_PAYMENT_INFO,
      payload
    }
  }

export function closePaymentInfoFormAction(){
  return {
    type:CLOSE_PAYMENT_MODAL
  }
}

export function openPaymentInfoFormAction(){
  return {
    type:OPEN_PAYMENT_MODAL
  }
}


