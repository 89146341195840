import React from 'react'
import { Card } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getOrderDetailsService } from '../../../../../../services/paymentServices/PaymentService'
import { getCurrencySymbol } from '../../../../../../utils/Currency'
import { checkIsObjectThere, checkResData, percentageHelper } from '../../../../../../utils/Helpers'
import ImageHs from '../../../ImageHs'
import SpacerHS_TEN from '../../../Spaces/SpacerHS_TEN'
import SpacerHS_TWO from '../../../Spaces/SpacerHS_TWO'
import TickerLoader from '../../Loaders/TickerLoader/TickerLoader'
import SuccessSkeleton from './SuccessSkeleton';
import beeStand from '../../../../../../images/hireserver/imgs/brand/beestand.png';
import CurrencyFC from '../../Currency/CurrencyFC'
import { getBlockIdService, getTenureInstanceaService } from '../../../../../../services/InstancesServices/InstancesFormService'
import { getpaymentInfoService } from '../../../../../../services/Profile/PaymentFormServices'

function RenewalSuccess({ orderidVal, bankdetails, paymentData }) {
    const [orderDesState, setOrderDesState] = React.useState({});
    const [totalAmountBalance, setTotalAmountBalance] = React.useState(0);
    const [taxState,setTaxState] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [address, setaddress] = React.useState({
        state: '',
        country: ''
    })


    async function getDetails() {
//         debugger;
//         const res = await getOrderDetailsService(orderidVal);
//         const isValid = checkResData(res);
    
//         if (!isValid) {
//             setLoading(false);
//             return;
//         }


        
//         debugger;
//         const resData = JSON.parse(res.data.result)[0];
//         const totalorderamountBalance = resData?.totalorderamount;
//         setTotalAmountBalance(totalorderamountBalance);

//         console.log(resData, 'RenewalSuccess')
//         const {contactname,createddate,currencytype,currencyid,orderno,orderstatus,paymentid,paymentresponse} = resData;

        
// // //////////////////////
//         const blockIdRes = await getBlockIdService();
//         let blockid = JSON.parse(blockIdRes.data.result)[0]
//         blockid = blockid['blockid'];
        
//         const   newRes =  await getTenureInstanceaService(currencyid,blockid);
      
//         const taxesData = JSON.parse(JSON.parse(newRes.data.result)[0]?.taxes);
        
//         setTaxState(taxesData);
//         // /////////////////////////////
//         let paymentRes = JSON.parse(paymentresponse);
//         let totalamount = paymentRes?.order_amount;
//         let cardDetails = {};
//         if (bankdetails?.payment_method
//           ?.card) {
//           cardDetails = {
//             ...bankdetails?.payment_method
//               ?.card
//           }
//         }else if(bankdetails?.payment_group == 'wallet'){
//           cardDetails = {
//             card_network:bankdetails?.payment_group
//           }
//         }else{
//             cardDetails = {
//                 card_network:bankdetails?.payment_group
//             }
//         }

//         setOrderDesState({
//             contactname,createddate,currencytype,orderno,orderstatus,paymentid,totalamount,...cardDetails,totalorderamountFull:resData?.totalorderamount
//         })

debugger
const res = await getOrderDetailsService(orderidVal);

const isValid = checkResData(res);

if (!isValid) {
  setLoading(false);
  return;
}

const resData = JSON.parse(res.data.result)[0];

let isSafe = checkIsObjectThere(resData, 'machineobject');
if (!isSafe) {
  setLoading(false);
  return;
}
const { totalorderamount } = resData;
setTotalAmountBalance(totalorderamount);
// let machineData = {};
// if (resData.machineobject) {
//   machineData = JSON.parse(resData.machineobject);
// }

// console.log(machineData, 'machineDatamachineDatamachineData');
// const { machinename, orderid, noofblocks, isbaremetal } = machineData;



let cardDetails = {};
if (bankdetails?.payment_method
  ?.card) {
  cardDetails = {
    ...bankdetails?.payment_method
      ?.card
  }
} else if (bankdetails?.payment_group == 'wallet' || bankdetails?.payment_group == 'upi') {
  cardDetails = {
    card_network: bankdetails?.payment_group
  }
}

let { currencytype, contactname, createddate, currencyid, totalamount, orderno } = resData;

let paymentresponse = resData['paymentresponse'];
let paymentresponseObj = '';
let payment_amount = 0;
if (paymentresponse != '' && paymentresponse != null && paymentresponse != undefined) {
  paymentresponseObj = JSON.parse(paymentresponse);
  payment_amount = paymentresponseObj['payment_amount'];
}

// console.log(currencytype, contactname, currencyid, createddate, totalamount, totalAmountBalance, 'currencytype, contactname, createddate, totalamount ,totalorderamount')
// /////////////////////////////////////////////
const blockIdRes = await getBlockIdService();
let blockid = JSON.parse(blockIdRes.data.result)[0]
blockid = blockid['blockid'];

const newRes = await getTenureInstanceaService(currencyid, blockid);

const taxesData = JSON.parse(JSON.parse(newRes.data.result)[0]?.taxes);
debugger;
setTaxState(taxesData);
// /////////////////////////////////////////////
setOrderDesState({
   orderno, payment_amount,
  currencytype, contactname, createddate, totalamount, ...cardDetails
})
    }



    React.useEffect(function () {
        if (paymentData == null) return;
        const { statename, countryname } = paymentData;
        setaddress({
            state: statename,
            country: countryname
        })
    }, [paymentData]);


    React.useEffect(function () {
        getDetails();
    }, []);

    return (
        <SuccessSkeleton>
            <div className=''>

                <div className='p-3 px-5 border-bottom font-16'>
                    <span className='text-primary'> Order Summary</span>
                </div>
                <div className='p-3 px-5 border-bottom  row'>

                    <div className='col-3'>
                        <div>Order Date</div>
                        <div>{orderDesState?.createddate}</div>
                    </div>
                    <div className='col-3'>
                        <div>Order Id</div>
                        <div>{orderDesState?.orderno}</div>
                    </div>
                    {orderDesState?.card_number && <div className='col-3'>
                        <div>Payment</div>
                        <div>{orderDesState?.card_network} <span className='mx-1'>-</span>{orderDesState?.card_number?.replaceAll('X', '')}</div>
                    </div>}
                    {!orderDesState?.card_number && <div className='col-3'>
                        <div>Payment</div>
                        <div>{orderDesState?.card_network}</div>
                    </div>}
                    <div className='col-3'>
                        <div>Address</div>
                        <div>
                            <div>{address.state}</div>
                            <div>{address.country}</div>
                        </div>
                    </div>
                </div>
                <div className='p-3 px-5 row border-bottom font-16 font-w500'>

                    <div className="col-7 d-flex align-items-center d-gap-40">
                        <ImageHs src={beeStand} alt={orderDesState?.machinename} width="100px" height="100px" />

                        <div className=''>
                            <div className=' m-0'>
                                <span>Your machine has been renewed</span> 
                            </div>
                            
                        </div>
                        {/* <div className=''>
                            <div className=' m-0'>
                                <span>{orderDesState?.machinename}</span> <span>|</span> <span>HS Block</span>
                            </div>
                            <div className='font-12'>
                                Hireserver @ Copyright
                            </div>
                        </div> */}
                    </div>
                   
                    <div className="col-3  d-flex justify-content-center align-items-center">
            <span className=''>
              {/* {getCurrencySymbol(orderDesState?.currencytype, orderDesState?.totalamount)} */}
              <CurrencyFC name={orderDesState?.currencytype} val={orderDesState?.totalamount} />
            </span>
          </div>
        </div>
        <div className='p-3 px-5 border-bottom'>
          <div className='d-flex justify-content-between'>
            <span>Order Amount</span>
            <span>
              {/* {getCurrencySymbol(orderDesState?.currencytype, orderDesState?.totalorderamount)} */}
              <CurrencyFC name={orderDesState?.currencytype} val={orderDesState?.totalamount} />
            </span>
          </div>
          {/* <div className='d-flex justify-content-between'>
            <span>Taxes</span>
        
          </div> */}
          <div className='d-flex justify-content-between'>
            <span>CGST <span className=''>{taxState?.CGST}%</span> </span>
            <span className=''>
              <CurrencyFC name={orderDesState?.currencytype} val={percentageHelper(taxState?.CGST, orderDesState?.totalamount)} />

            </span>
          </div>
          <div className='d-flex justify-content-between'>
            <span>SGST  <span className=''>{taxState?.SGST}%</span>  </span>
            <span className=''>
              <CurrencyFC name={orderDesState?.currencytype} val={percentageHelper(taxState?.SGST, orderDesState?.totalamount)} />

            </span>
          </div>
          <div className='font-w500'>
            <div className='d-flex justify-content-between'>
              <span>Sub Total</span>
              <span>
                {/* {getCurrencySymbol(orderDesState?.currencytype, orderDesState?.totalamount)} */}
                <CurrencyFC name={orderDesState?.currencytype} val={totalAmountBalance} />
                {/* <CurrencyFC name={orderDesState?.currencytype} val={orderDesState?.totalamount} /> */}

              </span>
            </div>
          </div>
          {<div className='d-flex justify-content-between'>
            <span>Amount deducted from Wallet</span>
            {/* <span>{getCurrencySymbol(orderDesState?.currencytype, orderDesState?.totalorderamount - orderDesState?.totalamount)} </span> */}
            <span>
              -
              {totalAmountBalance != 0 ? <CurrencyFC name={orderDesState?.currencytype} val={Math.abs(orderDesState?.payment_amount - totalAmountBalance)} /> : <CurrencyFC name={orderDesState?.currencytype} val={0} />}
              {/* <span>---</span>
              {orderDesState?.totalorderamount}
              <span>---</span>
              {orderDesState?.totalamount} */}
            </span>
          </div>}

          {/* <div className='d-flex justify-content-between'>
            <span>Amount Charged</span>
            <span>
              +
             
              <CurrencyFC name={orderDesState?.currencytype} val={orderDesState?.totalamount} />
            </span>
          </div> */}


        </div>
        <div className='p-3 px-5 border-bottom font-w500'>
          <div className='d-flex justify-content-between'>
            <span>Total</span>
            <span>
              {/* {getCurrencySymbol(orderDesState?.currencytype, orderDesState?.totalamount)} */}
              <CurrencyFC name={orderDesState?.currencytype} val={orderDesState?.payment_amount} />

            </span>
          </div>
        </div>
                <div className='p-3 px-5 border-bottom'>
                    <div>Thank you for renewed.</div>
                    <SpacerHS_TWO />
                    <div className='text-center'>
                        <Link className='btn hs-signin-btn hs_borderRadius-4 w-150p' to="/instances">
                            Done
                        </Link>
                    </div>
                </div>
            </div>
        </SuccessSkeleton>
    )



}
const mapStateToProps = ({ PaymentInfoReducer }) => {
    return {
        paymentData: PaymentInfoReducer?.paymentData
    };
}
export default connect(mapStateToProps)(RenewalSuccess)