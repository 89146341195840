import React from 'react';
import StaticFireWall from '../../../../../../../../utils/StaticsClasses.js/StaticFirewall';
import FormInputError from '../../../../../../hireserver/components/FormInputError';
import FirewallContext from '../../FirewallContext/FirewallContext';
import TypeFirePro from './../components/TypeFirePro';



function BoundItem({ boundTypes, itemData,boundName,index, boundItemDeleteHandler, fullItemData, itemDataSetter }) {
    const { editRuleBound } = React.useContext(FirewallContext);
    const [ruleTypes, setRuleTypes] = React.useState([]);
    const [sourceTypes, setSourceTypes] = React.useState([
        { label: 'Custom', value: 123456 },
        // {label:'Anywhere-IPv4',value:841575},
        // {label:'Anywhere-IPv6',value:648651315},
        { label: 'Any', value: 56548 }
    ]);

    const [selectedOptionSouces, setSelectedOptionSouces] = React.useState(null);
    const [selectedOption, setSelectedOption] = React.useState(null);

    const typesConfig = itemData.typesHelpers;
    const [protocol, setProtocol] = React.useState('');
    const [desControl, setDesControl] = React.useState('');
    const [port, setPort] = React.useState('');

    const [description, setDescription] = React.useState('');

    React.useEffect(function () {
        
        formatRulesTypes();
    }, [itemData]);

    

    function formatRulesTypes() {

        const items = itemData.typesHelpers;
        const newItems = [];
        for (let i in items) {
            newItems.push({
                value: items[i]?.value,
                label: i
            })
        }
        
        setRuleTypes(newItems)
      
       //if(StaticFireWall.firewallEdit){
            setDescription(itemData?.description)
            let proctol = itemData?.protocol? itemData?.protocol:'Custom TCP' ;
            setSelectedOption({
                label:proctol,
                value:itemData.typesHelpers[proctol]?.value
            });
            if(boundName == 'inbound'){
                setPort(itemData?.srcport);
            }else{
                setPort(itemData?.destport);
            }
            // if(itemData?.src === 'any'){
            
            // }else{
                
            // }
        
            if(boundName != 'outbound'){
                setDesControl(itemData?.src)
                
                if(itemData?.src === 'any'){
                    setSelectedOptionSouces({
                        value:123456,
                        label:'Any'
                    })
                }else{
                    setSelectedOptionSouces({
                        value:56548,
                        label:'Custom'
                    })
                }
            }else{
                setDesControl(itemData?.dst)
                if(itemData?.dst === 'any'){
                    setSelectedOptionSouces({
                        value:123456,
                        label:'Any'
                    })
                }else{
                    setSelectedOptionSouces({
                        value:56548,
                        label:'Custom'
                    })
                }
            }
        // }

    }


    function handleChange(selectedOption) {
        setSelectedOption(selectedOption);
        // setSelectedOption({
        //     ...selectedOption,
        //     value: selectedOption.value[selectedOption.label]
        // });


        let types = itemData.typesHelpers[selectedOption.label];
        setProtocol(selectedOption.label)
        setPort(types.port);
         let srcname = boundName === 'inbound'?'srcport':'dstport';
        editRuleBound(itemData.id,{
            port:types.port,
            protocol:selectedOption.label,
            ruletypeid:types.value,
            protocolType:types.ruletype
        },'optionPort',boundName);  
      
       
    }


    function isDisable(value, based = 'text') {
        if (based == 'text') {
            if (value === undefined || value == null || value === '') {
                return false;
            } else {
                return true;
            }

        }
    }

    function handleChangeSources(selectedOption) {
       
        setSelectedOptionSouces(selectedOption);
   
        let srcname = boundName === 'inbound'?'src':'dst';
        let value = selectedOption.label === 'Any' ? 'any':'';
        setDesControl(value);
      
        editRuleBound(itemData.id,value,srcname,boundName);
        
    }

    function handleDelete() {
        boundItemDeleteHandler(itemData.id)
    }

    function handleDescription(e){
        setDescription(e.target.value);

        editRuleBound(itemData.id,e.target.value,'description',boundName);
    }

    function handlerPort(e){
        
     if (e.which != 8 && e.which != 0 && e.which < 48 || e.which > 57) {
        e.preventDefault();
        return;
      }
        setPort(e.target.value);
  
        let srcname = boundName === 'inbound'?'srcport':'destport';
        
        editRuleBound(itemData.id,{
            value:e.target.value,
            protocol:itemData.protocol,
            ruletypeid:itemData.ruletypeid,
            protocolType:itemData.protocolType
        },srcname,boundName);
    }

    function srcHandler(e){
        setDesControl(e.target.value);
        let srcname = boundName === 'inbound'?'src':'dst';
        editRuleBound(itemData.id,e.target.value,srcname,boundName);
    }

    function blockInvalidChar(e){
  
        if( ['e', 'E', '+', '-'].includes(e.key)){
          e.preventDefault();
        }
    
        // if(e.keyCode>=65 && e.keyCode<=90 ){
        //   e.preventDefault();
        // }
        
        if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105 || e.keyCode == 8)) { 
            // 0-9 only
          }else{
            e.preventDefault();
          }
    
        
      }

    return (
       <React.Fragment>
        
        <div className='d-block d-lg-none'>

            <div className="d-flex w-100 justify-content-between">
            <span> {boundName} rule {index}</span>
            <span onClick={handleDelete}> <i className="fa fa-trash"></i> </span>
           
            </div>
           
        </div>
        <div className="row pb-1">
        <div className="col-sm-6 col-md-4 col-lg-2">
        <div className='d-lg-none'>Type</div>
            <TypeFirePro selectedOption={selectedOption} handleChange={handleChange} options={ruleTypes} />

        </div>
        <div className="col-sm-6 col-md-4 col-lg-1">
        <div className='d-lg-none'>Protocol</div>
            <input  disabled={true} value={typesConfig[selectedOption?.label]?.ruletype} className='form-control hs_borderRadius-4' type="text" placeholder='Proto' />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-2">
            <div className='d-lg-none'>Port</div>
            {/* onKeyDown={blockInvalidChar} */}
            <input  onKeyDown={blockInvalidChar}  disabled={isDisable(typesConfig[selectedOption?.label]?.port)} onChange={handlerPort} value={port} className='form-control hs_borderRadius-4' type="text" placeholder='All' />
            {itemData?.errors && itemData?.errors?.srcportErr?.isError &&<FormInputError message="Field can't be empty" /> }
            {itemData?.errors && itemData?.errors?.destportErr?.isError &&<FormInputError message="Field can't be empty" /> }

            
        </div>
        <div className="col-sm-6 col-md-4 col-lg-2">
        <div className='d-lg-none'>Source</div>
            <TypeFirePro handleChange={handleChangeSources} selectedOption={selectedOptionSouces} options={sourceTypes} />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-2">
        <div className='d-lg-none'>Source input</div>
            <input onChange={srcHandler} value={desControl} disabled={selectedOptionSouces?.label === 'Any' ? true : false} className='form-control hs_borderRadius-4' type="text" placeholder='Des control' />
            {itemData?.errors && itemData?.errors?.srcErr?.isError && <FormInputError message={itemData?.errors?.srcErr?.message ? itemData?.errors?.srcErr?.message: `Field can't be empty`} /> }
        </div>
        {/* <div className="col-sm-6 col-md-4 col-lg-2">
            <input onChange={srcHandler} value={selectedOptionSouces?.label === 'Any' ? '*' : desControl} disabled={selectedOptionSouces?.label === 'Any' ? true : false} className='form-control hs_borderRadius-4' type="text" placeholder='Des control' />
            {itemData?.errors && itemData?.errors?.srcErr?.isError &&<div>
                You have a error
            </div> }
        </div> */}
        <div className="col-sm-6 col-md-4 col-lg-2">
        <div className='d-lg-none'>Description</div>
            <input value={description} onChange={handleDescription} className='form-control hs_borderRadius-4' type="text" placeholder='Description' />
        </div>
        <div className="d-none d-lg-block col-lg-1">
            <span onClick={handleDelete}> <i className="fa fa-trash"></i> </span>
        </div>
    </div>
       
       {/* {
        boundName != 'inbound' &&  <div className="row pb-1">
        <div className="col-sm-6 col-md-4 col-lg-2">
            <TypeFirePro selectedOption={selectedOption} handleChange={handleChange} options={ruleTypes} />

        </div>
        <div className="col-sm-6 col-md-4 col-lg-1">
            <input disabled={true} value={typesConfig[selectedOption?.label]?.ruletype} className='form-control hs_borderRadius-4' type="text" placeholder='Proto' />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-2">
            <input disabled={isDisable(typesConfig[selectedOption?.label]?.port)} onChange={handlerPort} value={port} className='form-control hs_borderRadius-4' type="text" placeholder='Range' />
            {}
        </div>
        <div className="col-sm-6 col-md-4 col-lg-2">
            <TypeFirePro handleChange={handleChangeSources} selectedOption={selectedOptionSouces} options={sourceTypes} />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-2">
            <input onChange={srcHandler} value={selectedOptionSouces?.label === 'Any' ? '*' : desControl} disabled={selectedOptionSouces?.label === 'Any' ? true : false} className='form-control hs_borderRadius-4' type="text" placeholder='Des control' />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-2">
            <input value={description} onChange={handleDescription} className='form-control hs_borderRadius-4' type="text" placeholder='Description' />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-1">
            <span onClick={handleDelete}> <i className="fa fa-trash"></i> </span>
        </div>
    </div>
       } */}
       </React.Fragment>
    )
}

export default BoundItem