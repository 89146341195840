import React from 'react'
import CustomInputLabelSubText from '../../../../../hireserver/controls/CustomInputLabelSubText';
import Select, { components } from 'react-select';
import HeadingHS from '../../../../../hireserver/controls/HeadingHS'
import LabelInput from '../../../../../hireserver/controls/LabelInput';
import NodeBalanceContext from '../../../NodeBalanceContext/NodeBalanceContext';
import './BackEndNodes.css';

function NodeItem({setNodes,nodes}){
    const { selectTagOption, setSelectTagOption } = React.useContext(NodeBalanceContext);
    function setSelectedOption() {

    }

    function handleClickClose(){
        setNodes(nodes-1)
    }
    return (
        <div className='position-relative'>
            <div onClick={handleClickClose} style={{fontSize:'20px'}} className="position-absolute font-w900 closeNodes">X</div>
         <div className='col-lg-4  col-12 ml-0 pl-0 pt-lg-2'>
                <CustomInputLabelSubText title="Label" >
                    <input style={{ borderRadius: '0', height: '40px' }} type="number" className='form-control' />
                </CustomInputLabelSubText>
            </div>
            <div className='row px-md-3'>
                <div className="col-lg-4  col-12 ml-0 pl-lg-0">
                    <CustomInputLabelSubText title="IP Address" >
                        <Select
                            defaultValue={selectTagOption[0]}
                            onChange={setSelectedOption}
                            options={selectTagOption}
                            style={{
                                lineHeight: '40px',
                                color: '#7e7e7e',
                                paddingLeft: ' 15px',
                            }}
                        />
                    </CustomInputLabelSubText>
                </div>
                <div className="col-lg-2">
                    <CustomInputLabelSubText title="Port" >
                        <input style={{ borderRadius: '0', height: '40px' }} type="number" className='form-control' />
                    </CustomInputLabelSubText>
                </div>
                <div className="col-lg-2">
                    <CustomInputLabelSubText title="Weight" >
                        <input style={{ borderRadius: '0', height: '40px' }} type="number" className='form-control' />
                    </CustomInputLabelSubText>
                </div>
            </div>
        </div>
    )
}

export default function BackEndNodes() {
  
    
    const [nodes, setNodes] = React.useState(1);
    function getNodes(){
        let nodesArr = [];
        for(let i=0;i<nodes;i++){
            nodesArr.push(<NodeItem nodes={nodes} setNodes={setNodes} />)
        }

        return nodesArr;
    }

    function onClickHandler(){
        setNodes(nodes+1);
    }
    return (
        <div>
            <HeadingHS as="h3" title="Backend Nodes" />
            {getNodes()}
           <div>
           <button onClick={onClickHandler} type="button" style={{BorderRadius:'1px'}} className='btn py-2 btn-outline-primary'>
                Add Nodes
           </button>
           </div>
        </div>
    )
}
