import axios from "axios";
import https from "https";
import Cookies from "js-cookie";
import { getUserCookieAuth, logout } from "../store/actions/AuthActions";
import { openAuthModal } from "../store/actions/AuthAlertActions/AuthAlertActions";
import { USER_COOKIE } from "../store/types/AuthTypes/AuthTypes";
import { checkMachineStop } from "./InstancesServices/InstanceCurrListNewDropdownService";
import { CatchAsync } from "./ServiceErrorHandlers/CatchAsync";
import AppError from "./ServiceErrorHandlers/CustomError";
import ErrorClass from "./ServiceErrorHandlers/ErrorClass";
import { ErrorHandler } from "./ServiceErrorHandlers/ErrorHandler";

const agent = new https.Agent({
  rejectUnauthorized: false,
});

export function API_CALL(configOptions) {
  const config = {
    ...configOptions,
    httpsAgent: agent,
  };
  return axios(config);
}

export const commonOption = {
  httpsAgent: agent,
};

export const getCommonHeaders = () => {
  let userCookie = getUserCookieAuth();
  if (userCookie === null)
    return {
      data: {},
      error: ["no cookie"],
    };

  return {
    Authorization: `Basic ${userCookie["sessionId"]}`,
    "Content-Type": "application/json",
  };
};

// export async function API_SERVICES(configOptions, dispatch) {
//     const config = {
//         ...configOptions,
//         httpsAgent: agent,
//       };
//   try {
//     let response = await axios(config);
//     '';
//     if (
//       response.data.code === "417" &&
//       response.data.errors[0] === "User Authentication Failed"
//     ) {
//       dispatch(openAuthModal());
//       return;
//     }

//     return response;
//   } catch (error) {
//     // console.log(error);
//     return null;
//   }
// }
// export async function API_SERVICES(configOptions, dispatch) {
//     const config = {
//         ...configOptions,
//         httpsAgent: agent,
//       };
//   try {
//     let response = await axios(config);
//     '';
//     if (
//       response.data.code === "417" &&
//       response.data.errors[0] === "User Authentication Failed"
//     ) {
//       throw new AppError('User Authentication Failed',"417");

//     }

//     return response;
//   } catch (error) {
//     ErrorHandler(error);
//     return null;
//   }
// }

export function api_Wrapper(fun) {
  return fun().catch((err) => ErrorHandler(err))
}



export  const API_SERVICES =  CatchAsync(async (configOptions) => {
  
  let dispatch =  ErrorClass.dispatch;
  let history = ErrorClass.history;
  const config = {
    ...configOptions,
    httpsAgent: agent,
  };
 

  let response = await axios(config);

  
   
    if (
      response?.data?.code === "417" &&
      response?.data?.message === "User Authentication Failed"
    ) {
       dispatch(openAuthModal());
      throw new AppError('User Authentication Failed',"417");
      return;
    }

  
    // throw new Error('custom error')

  return response;
});



export const checkMachinePromise = (UPID,node,location,callback) => {
  return new Promise(function(resolve, reject) {
      // resolve(10);
      
      let timerReq = setInterval(async function (){
         try {
          const isStopRes = await checkMachineStop(UPID,node,location);let parseObj = JSON.parse(isStopRes.data.result)
          let newRes = parseObj['data']['status'];
        
          if (newRes == 'stopped' || newRes == '') {
              // console.log("DONE CheckMachinePromise")
              clearInterval(timerReq);
              resolve({
                  data:parseObj,
                  error:false
              });
              return;
          }
         } catch (error) {
          // console.log("DONE CheckMachinePromise")
          clearInterval(timerReq);
          resolve({
              data:[],
              error:error
          });
         }
      },2000);
   });
}

export async function callAPI(fun){
 return new Promise(async (resolve,reject) => {
  try {
    const res = await fun.apply(this, arguments);
    resolve({
      isError:false,
      result:res
    });
  } catch (error) {
    resolve({
      isError:true,
      result:error
    });
  }
 })
}