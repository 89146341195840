import React from 'react'
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


import { getPastNotifationService, selectedMarkAsReadService } from '../../../../../services/Profile/NotifationsService';
import { allCheckAction, clearNotifsReducerAction, clearNotisArrAction, getCountNotifs, getNotifsAction, hideNotifiModal, putNotifsAction, selectAllNotiAction } from '../../../../../store/reducers/NotificationReducer';
import { checkResData } from '../../../../../utils/Helpers';
import { handlerAlert } from '../../../../components/Dashboard/Instances/InstancesListComponents/DropDownInstancesList';
import SideModalNoRoute from '../../../../components/hireserver/components/SideModal/SideModalNoRoute/SideModalNoRoute'
import ButtonHS from '../../../../components/hireserver/controls/ButtonHS';
import ClearAllNotification from './ClearAllNotification';
import EmptyNotification from './EmptyNotification';
import NotificationCard from './NotificationCard';


function NotificationSidebar({markAsRead,isAllMark, data,noticeCount,count, isClose, history }) {

    const [isLoading,setIsLoading] = React.useState(true);
    const [allCheck,setAllCheck] = React.useState(false);
    const dispatch = useDispatch();
    
   

    function handlerClose() {

        setIsClose(false);
       // dispatch(clearNotifsReducerAction());
    }

    function setIsClose(bool){
       dispatch(hideNotifiModal());
    }

    function callNoticesCount(){
        dispatch(getCountNotifs());
    }

    async function getNotics() {
        
        setIsLoading(true);
           const notice =  await getPastNotifationService();
           let isValid = checkResData(notice);
           if(!isValid){

            dispatch(putNotifsAction([]));
            setIsLoading(false);
           
            return;
           }

           const data = JSON.parse(notice.data.result);
       

        
           setIsLoading(false);

        // dispatch(getNotifsAction());
        dispatch(putNotifsAction(data));
    }

    // React.useEffect(function (){
    //     console.log(markAsRead,'markAsReadmarkAsReadmarkAsReadmarkAsRead')
    // },[markAsRead]);

    React.useEffect(function (){

       
        if(isClose){
           
            document.body.classList.add('hideScrollBar');
            callNoticesCount();
            getNotics();
        }else{
            document.body.classList.remove('hideScrollBar');
        }
    },[isClose]);

    function handleRedirect(){
        setIsClose(false);
        history.push("/notifications")
    }

    async function clearSelectedNotifsHandler(e){
       try {
        setIsLoading(true);
        const res = await selectedMarkAsReadService(Object.keys(markAsRead));
      
        if(res.data.code != '0'){
            handlerAlert("danger","Something Went Wrong");
            setIsLoading(false);
            return;
        }

        handlerAlert("success","Selected Notifications marked as read.")

        selectAllMarkAsRead();

       // dispatch(clearNotisArrAction());
       dispatch(clearNotifsReducerAction())
        dispatch(getNotifsAction());
        dispatch(getCountNotifs());
        //dispatch(selectAllNotiAction(false));
        setIsLoading(false);
       } catch (error) {
        console.log(error);
        setIsLoading(false);
       }

    }

    function selectAllMarkAsRead(e){
       
        if(allCheck == false){
            dispatch(selectAllNotiAction(true));
            dispatch(allCheckAction());
        }else{
            dispatch(clearNotisArrAction())
            dispatch(selectAllNotiAction(false));
        }
        setAllCheck(!allCheck);
    }

    
    

    return (
   
        <SideModalNoRoute right={false} handlerClose={handlerClose} isClose={isClose} setIsClose={setIsClose} name="Notifications">
            <div className='pt-3 px-2 h-80'>
              <div className='d-flex flex-row-reverse justify-content-between'>
                 
                 {Boolean(count) &&  <span className='pr-3 d-flex align-items-center justify-content-center  d-gap-10 cursor-pointer'>
                 <ClearAllNotification/> 
                      <input checked={allCheck} onChange={selectAllMarkAsRead} type="checkbox" className='cursor-pointer' />
                     
                  </span>}
                  <div className='pl-4 d-flex justify-content-center align-items-center'>
                  <span className='font-w400 pl-2 px-1'>Notifications</span>
                 {Boolean(count)  && <span className='circle_span text-center font-12 d-flex justify-content-center align-items-center'>{count > 99 ? '99+' : count}</span>}
               
                  </div>
                 
              </div>
                <hr />
                <div className='notifyContianer'>
                    {data.map(function (item,i){
                        let checked = false;
                        if(isAllMark){
                            checked = true;
                        }else if(markAsRead[item['ReminderId']]){
                            checked = true;
                        }
                        return <NotificationCard checked={checked} key={item['ReminderId']} getNotics={getNotics} callNoticesCount={callNoticesCount} item={item} />
                    })}
                     
                    { !isLoading &&  data?.length ===0 && <EmptyNotification handleRedirect={handleRedirect}/>}
                </div>
            </div>
            {Object.keys(markAsRead)?.length ==0 && Boolean(count) &&<div className='text-center py-3'>
                <ButtonHS handler={handleRedirect} classes="hs-signin-btn px-3 py-2">
                    View All
                </ButtonHS>
            </div>}

            {Object.keys(markAsRead)?.length >= 1 && Boolean(count)  &&<div className='text-center'>
                <button disabled={isLoading} onClick={clearSelectedNotifsHandler} className='btn btn-sm hs_borderRadius-4 bg-danger text-white'>
                {isLoading ? <React.Fragment>
                                    <div className="spinner-border text-light" role="status">

                                  </div> Mark as read
                                  </React.Fragment> : 'Mark as read'}
                </button>
            </div>}

             
        </SideModalNoRoute>
        
    )
}
const mapStatesToProps = ({notificationReducer }) => {
    return {
        markAsRead:notificationReducer.markAsRead,
        isAllMark:notificationReducer.selectAllNoti,
        isClose:notificationReducer.showNotfiModal,
        data:notificationReducer.notifs,
        count:notificationReducer.count

    };
  };
  
export default connect(mapStatesToProps)(withRouter(NotificationSidebar));