class AppError extends Error{
    constructor(message,statusCode,callback) {
        super(message);
        
        this.statusCode = statusCode;
        this.status = `${statusCode}`.startsWith('4') ? 'fail' : 'error';
        this.callback = callback;
        this.isOperational = true;

        Error.captureStackTrace(this,AppError);
    }
    
};

export default AppError;