import React from 'react'
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { saveGroupInfoService, saveGroupRulesService } from '../../../../../../services/FirewallServices/FirewallFormService';
import { postdeleteFirewallGroupService } from '../../../../../../services/FirewallServices/FirewallListService';
import { getBoundRulesService, getFireWallMachinesByIdService } from '../../../../../../services/FirewallServices/FirewallOverviewService';
import { getFirewallListAction } from '../../../../../../store/actions/FirewallActions/FirewallAction';
import ModalHs from '../../../../hireserver/ModalHs';
import SpacerHS_TWO from '../../../../hireserver/Spaces/SpacerHS_TWO';
import { handlerAlert } from '../../../Instances/InstancesListComponents/DropDownInstancesList';

function DeleteModalFirewallListItem({showFirewallModal,setShowFirewallModal}) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState('');

    const [showModalInputError, setShowModalInputError] = React.useState(false);
    function handleClose() {
        setShowFirewallModal({
            isShow:false,
            firewallId:null
        });
    }


    async function deleteHandler() {
        const firewallgroupid = showFirewallModal.firewallId;
        try {
            setIsLoading(true);
            const getMachinesGET = await getFireWallMachinesByIdService(firewallgroupid);
        const getMachineResData = JSON.parse(getMachinesGET.data.result);
        if(getMachineResData?.length!=0){
          let machinesIDs = [];
          for(let i=0;i<getMachineResData.length;i++){
            let machineIDItem = getMachineResData[i]?.machineid
            machinesIDs.push(`${machineIDItem}`);
          }
    
          
    
          const payloadMachine = {
            "groupname": 're',
            "description": 'ss',
            "machinesadded": [],
            "machinesremoved": machinesIDs,
            "firewallgroupid": getMachineResData[0]?.firewallgroupid
        }
        const res = await saveGroupInfoService(payloadMachine);
        }
       
    
        // const boundRulesGet = await getBoundRulesService(firewallgroupid);
        // const boundRulesGetResData = JSON.parse(boundRulesGet.data.result);
        // if(boundRulesGetResData.length!=0){
        //   let rulesIds = [];
        //   for(let i=0;i<boundRulesGetResData.length;i++){
        //     rulesIds.push(`${boundRulesGetResData[i]?.firewallruleid}`);
        //   }
    
        //   const rulesDeleteRes = await saveGroupRulesService({
        //     "rulesadded":[],
        //     "rulesremoved":rulesIds
        //     });
    
           
        // } 
    
       
    
    
    
    
    
        const groupDeleteRes = await postdeleteFirewallGroupService(JSON.stringify({
          "groupid":[`${firewallgroupid}`]
        }));
        setIsLoading(false);
     
        handleClose();
        dispatch(getFirewallListAction())
        handlerAlert("success","Your Firewall group is deleted")
    

        } catch (error) {
            setIsLoading(false);
            handleClose();
            console.log(error,'boundRulesGet')
            handlerAlert("danger","Something went wrong","danger");   
        }
        
      
      
        
    }
    return (
        <ModalHs  bodyClasses="p-4" footerNone={true}  isNeed={false} handleClose={handleClose} showModalHS={showFirewallModal.isShow} okayHandler={deleteHandler} btnTitle="Destory Firewall Group" >
            <div className='w-100'>

            <div>Do you want to delete {showFirewallModal.groupname}??</div>
            <SpacerHS_TWO/>
            <div className='d-flex justify-content-end    d-gap-10 '>
              <Button style={{ borderRadius: '4px' }} className="btn-sm bg-white  text-dark" variant="secondary" onClick={handleClose}>
            Close
            </Button>
            <Button style={{ borderRadius: '4px' }} className={`btn-sm bg-danger w-200p `}  onClick={deleteHandler}>
              {isLoading ? <React.Fragment>
                <div className="spinner-border text-light" role="status">

                </div> Deleting...
              </React.Fragment> : 'Delete firewall group'}
            </Button>
          </div>
            </div>
        </ModalHs>
    )
}

export default DeleteModalFirewallListItem