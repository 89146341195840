import { cloneDeep } from 'lodash';
import { MDBDataTable } from 'mdbreact';
import React from 'react'
import { connect } from 'react-redux';
import { getPaymentWalletAmountService } from '../../../../../services/paymentServices/PaymentService';
import { getDueDateDataService } from '../../../../../services/Profile/ProfileOrdersServices';

import { paginationLabel, TABLE_ENTIRES } from '../../../../../utils/Emuns';
import { checkResData } from '../../../../../utils/Helpers';
import LoaderHS from '../../../hireserver/components/LoaderHS';
import SpacerHS_TWO from '../../../hireserver/Spaces/SpacerHS_TWO';
import DisplayAmountCard from './DueDataProfileComponents/DisplayAmountCard';

function DueDataProfileTab({currencyname,isNeed=true}) {
    const [loading, setLoading] = React.useState(true);
    const [charges,setCharges] = React.useState(0);
    const [walletValue,setWalletValue] = React.useState(null);
    const [col, setCols] = React.useState([
        {
            label: "name",
            field: "name",
            sort: "asc",

            attributes: { className: "text-center col-3" },
        }, {
            label: "Due Date",
            field: "duedate",
            sort: "asc",
            attributes: { className: "text-center col-3" },

        },
        {
            label: "Due Days",
            field: "duedays",
            sort: "asc",
            attributes: { className: "text-center col-3" },

        },
        {
            label: "Tenure",
            field: "tenure",
            sort: "asc",

            attributes: { className: "text-center col-3" },
        },


    ]);

    const [formatData, setFormatData] = React.useState({
        columns: [],
        rows: []
    });

    const [dueDateState,setDueDateState] = React.useState(null);

    React.useEffect(function () {
       
       
        getOrdersList();
    }, []);






    async function getOrdersList() {
       
     
        const resData = await getDueDateDataService();
        
    
        const isValid = checkResData(resData, true);
        if (isValid === false) {
            
            setLoading(false);
           
            setFormatData({
                columns: cloneDeep(col),
                rows: []
            });
            return;
        }
        // console.log(resData)


        const parseData = JSON.parse(resData.data.result);
      

        let rowComps = [];

        //   setLoading(false);
        //   return;
        let sumDueAmount = 0;
        for (let i = 0; i < parseData.length; i++) {

            const { duedays, machineid, duedate,dueamount, machinename, noofblocks } = parseData[i];
            let rowData = {
                name: <div className='d-flex w-100 justify-content-center'>
                    <div className='text-primary font-w600'>{machinename}</div>
                    {/* <span className='px-1'>-</span>
                    <div className='font-14 d-flex d-gap-10'>
                    
                        <span>127.154.10.3</span>
                    </div> */}
                </div>,
                duedate: <div className='text-center'>{duedate}</div>,
                duedays: <div className='text-center'>{duedays}</div>,
                tenure: <div className='text-center'>1 Year</div>,
                search_name:machinename,


            }
            sumDueAmount += dueamount;
            rowComps.push(rowData);
        }
        

        setFormatData({
            columns: cloneDeep(col),
            rows: cloneDeep(rowComps)
        });

        let res = await getPaymentWalletAmountService();
        let isValidPayment = checkResData(res,true)
        if(isValidPayment === false){
          setWalletValue(0);
         // setLoading(false);
          return;
        }

        let resJSON = JSON.parse(res.data.result)[0];
        if(!resJSON){
            setWalletValue(0);
            // setLoading(false);
            return;
          }
        let amount = 0;
  
        if(resJSON != undefined || resJSON != [] ||resJSON != ''|| resJSON != null){
            amount = resJSON?.walletvalue
        }
        let calValue = amount- sumDueAmount;
        if(calValue < 0){
            setDueDateState(Math.abs(calValue));
        }   
        setCharges(sumDueAmount);
        setWalletValue(amount);

        setLoading(false);
      

    }

    function onSearchHandler(value) {
        const newData = cloneDeep(formatData);
        setFormatData(newData);
    }

    function onPageChangeHandler({ activePage, pagesAmount }) {
        const newData = cloneDeep(formatData);
        setFormatData(newData);

        // const ls = document.querySelectorAll('.instanceListTable .instacesClass');
        // // console.log(ls, 'ldsa')
    }


    if (loading) {
        return <LoaderHS />
    }

    return (
        <div>
          {isNeed && dueDateState && <div className='row justify-content-between'>
                {/* Your balance currenty is {getCurrencySymbol(currencyname)}{walletValue} We recommend to recharge your account with  {getCurrencySymbol(currencyname)}{dueDateState}. You will then have enough funds for a renewal of all your services for one month. */}
                <DisplayAmountCard currencyname={currencyname} amount={charges} text="Charges This Month" />
               
                <div className='d-flex justify-content-center align-items-center font-30'>
                    +
                    </div>
                    <DisplayAmountCard currencyname={currencyname} amount={walletValue}   text="Wallet Balance" />
                <div className='d-flex justify-content-center align-items-center  font-30'>
                    =
                    </div>
                
                <DisplayAmountCard currencyname={currencyname} amount={dueDateState}   text="Estimated Balance"  />
            </div>}
            <SpacerHS_TWO/>
            <div className='row'>
                <div className="col-xl-12" style={{ position: 'relative' }}>
                    <div className="table-responsive">
                        <div className="display mb-4 dataTablesCard customer-list-table  instances-table-card">
                            <MDBDataTable paging={Boolean(formatData.rows.length>TABLE_ENTIRES)} paginationLabel={paginationLabel}  onSearch={onSearchHandler} disableRetreatAfterSorting={true} onPageChange={onPageChangeHandler} className='instanceListTable' entriesOptions={[1, 5, 20, 25]} entries={TABLE_ENTIRES} data={formatData} />


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



const mapStatesToProps = ({ auth, PaymentInfoReducer }) => {
    return {
        userDetails: auth.auth,
        isShowPayment: PaymentInfoReducer?.paymentData?.defaultaddressid,
        currencyname: PaymentInfoReducer?.paymentData?.currencyname,
        paymentData: PaymentInfoReducer?.paymentData
    };
};
export default connect(mapStatesToProps)(DueDataProfileTab);