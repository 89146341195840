import React from 'react'
import { Button, Form } from 'react-bootstrap'
import { getTickePrioritiesService, getTicketGoupsService, postSupportTicketService } from '../../../../../../services/support/SupportServices'
import { checkResData, formatDataSelect2, getMulFileToGetBase64 } from '../../../../../../utils/Helpers'
import FormInputError from '../../../../hireserver/components/FormInputError'
import LoaderHS from '../../../../hireserver/components/LoaderHS'
import DropDownSearch from '../../../../hireserver/controls/DropDownSearch'
import ModalHs from '../../../../hireserver/ModalHs'
import SpacerHS_ONE from '../../../../hireserver/Spaces/SpacerHS_ONE'
import SpacerHS_TWO from '../../../../hireserver/Spaces/SpacerHS_TWO'
import tickSuccessImg from '../../../../../../images/hireserver/imgs/tickpop.png';
import TickerLoader from '../../../../hireserver/components/Loaders/TickerLoader/TickerLoader'

function OpenTicketForm({ isShow, closeShowHandler, getSupporList }) {
    const [groupname, setGroupNames] = React.useState([]);
    const [currGroupName, setCurrGroupName] = React.useState(0);
    const [priorites, setPriorites] = React.useState([]);
    const [currPriorites, setcurrPriorites] = React.useState(0);
    const [isSuccess, setSuccess] = React.useState(false);
    const [filesInput,setFilesInput] = React.useState([]);

    const [titleSupport, setTitleSupport] = React.useState({
        val: '',
        isError: '',
        touched: false,
        errorMessage: ''
    });
    const [desSupport, setDesSupport] = React.useState({
        val: '',
        isError: '',
        touched: false,
        errorMessage: ''
    });

    const [groupnameError,setGroupNameError] = React.useState({
        isError:false,
        errorMessage:''
    });

    const [priortiesError,setPriortiesError] = React.useState({
        isError:false,
        errorMessage:''
    })


    const [formLoading, setFormLoading] = React.useState(false);

    function resetState(){
        setGroupNames([]);
        setCurrGroupName(0);
        setPriorites([]);
        setcurrPriorites(0);
        setSuccess(false);

        setTitleSupport({
            val: '',
            isError: '',
            touched: false,
            errorMessage: ''
        });

        setDesSupport({
            val: '',
            isError: '',
            touched: false,
            errorMessage: ''
        });

        setGroupNameError({
            isError:false,
            errorMessage:''
        })

        setPriortiesError({
            isError:false,
            errorMessage:''
        })
        setFilesInput([]);



    }

    function handleModalClose() {
        closeShowHandler(false)
        resetState();
    }

    function validation(){
        let isValid = false;
        // if (titleSupport.val === '') {
        //     isValid = true;
        //     setTitleSupport({
        //         ...titleSupport,       
        //         isError: true,
        //         errorMessage: 'Title is requried field'
        //     });
        // }

        if (desSupport.val === '') {
            isValid = true;
            setDesSupport({
                ...desSupport,      
                isError: true,
                errorMessage: 'Title is requried field'
            });
        }

        if(currGroupName == 0){
            isValid = true;
            setGroupNameError({
                isError:true,
                errorMessage: 'Regarding should be selected'
            })
        }

        if(currPriorites == 0){
            isValid = true;
            setPriortiesError({
                isError:true,
                errorMessage: 'Priorites should be selected'
            })
        }

        return isValid;
    }

   

    async function handlerSubmit(e) {
        
        setFormLoading(true);

        if(validation()){
            setFormLoading(false);
            return;
        }

        const payload = {
            ticketgroupid: currGroupName?.value,
            description: desSupport.val,
            documents:filesInput.length === 0 ? 0 : filesInput,
            PriorityId:currPriorites?.value,
            ticketid:0
        }
      
        const res = await postSupportTicketService(payload);
        
        const isError = res.data.code === '0';
        if (isError) {

        } else if (res.data.message === "Ticket created successfully") {

        }
        setCurrGroupName(0);
        setcurrPriorites(0);

        setTitleSupport({
            val: '',
            isError: '',
            touched: false,
            errorMessage: ''
        })

        setDesSupport({
            val: '',
            isError: '',
            touched: false,
            errorMessage: ''
        })

        setFormLoading(false);

        
       
        setSuccess(true);
        setTimeout(function (){
            resetState();
            closeShowHandler(false);
            getSupporList();
        },1000);
    }



    async function getTickets() {
        const res = await getTicketGoupsService();
        const isValid = checkResData(res);
        if (!isValid) return;

        const resData = JSON.parse(res.data.result);
        // console.log(resData, 'ticketsresDataresDataresDataresData');
        const formatted = formatDataSelect2(resData, 'ticketgroupid', 'ticketgroupname');
        setGroupNames(formatted)
    }

    async function getPriorites() {
        const res = await getTickePrioritiesService();
        const isValid = checkResData(res);
        if (!isValid) return;

        const resData = JSON.parse(res.data.result);
        // console.log(resData, 'sds');
        const formatted = formatDataSelect2(resData, 'priorityid', 'priorityname');
        setPriorites(formatted)
    }

    function handleChangeGroupName(e) {
        setCurrGroupName(e)
        setGroupNameError({
            isError:false,
            errorMessage:''
        });
    }

    function handleChangePriorties(e) {
        setcurrPriorites(e);
        setPriortiesError({
            isError:false,
            errorMessage:''
        })
    }

    React.useEffect(function () {
        if (isShow == true) {
            getTickets();
            getPriorites();
        }
    }, [isShow]);

    function handleTitle(e) {
        const value = e.target.value;
        if (value === '') {
            setTitleSupport({
                ...titleSupport,
                val: e.target.value,
                isError: true,
                touched: true,
                errorMessage: 'Title is requried field'
            });
        } else {
            setTitleSupport({
                ...titleSupport,
                val: e.target.value,
                isError: false,
                touched: true,
                errorMessage: ''
            })
        }

    }

    function handleDesSupport(e) {
        const value = e.target.value;
        if (value === '') {
            setDesSupport({
                ...desSupport,
                val: e.target.value,
                isError: true,
                touched: true,
                errorMessage: 'Description is requried field'
            });
        } else {
            setDesSupport({
                ...desSupport,
                val: e.target.value,
                isError: false,
                touched: true,
                errorMessage: ''
            })
        }

    }

    async function fileUploadSaveHandler(e){
        const promiseFiles = await getMulFileToGetBase64(e);
        // console.log(promiseFiles,'promisepromise');
        setFilesInput(promiseFiles);
    }   

    function removeInputHandler(e,name){
        let newData = filesInput.filter(function (el){
            return el?.FileName != name
        });
        setFilesInput(newData);
    }

    function getInputsList(){
        let htmlInputs = filesInput.map(function(el,i){
            const {FileName,FileType} = el;
            return <div className='bg-dark-alt px-2 py-1 rounded d-gap-5 d-flex justify-content-center align-items-center' key={i*66}>
               <span> {FileName}</span>
               <span onClick={(e) => removeInputHandler(e,FileName)} className='font-10 text-center bg-white rounded'>
                <i className='p-1  fa fa-close'></i>
               </span>
            </div>
        });

        return htmlInputs;
    }


    if(isShow === false){
        return <React.Fragment></React.Fragment>
    }


    return (
        <ModalHs isNeed={false} okayHandlerBtnColor="hs-signin-btn border-0 text-white" size="lg" classNameMain="position-absolute modalSupportHeight" handleClose={handleModalClose} okayHandler={handlerSubmit} showModalHS={isShow} btnTitle="Open Ticket" >
            {
                isSuccess && <div className='h-md-534p d-flex flex-column align-items-center justify-content-center w-100'>
                    {/* <div className='w-100p'>

                        <img className='w-100 h-100' src={tickSuccessImg} alt="ticket created"  />
                    </div> */}
                    <TickerLoader/>
                    <div>
                        Your ticket as been created
                    </div>
                </div>
            }
            {
                !isSuccess && <React.Fragment>
                    <div className='w-100'>
                        <div className='font-21 font-w600'>
                            Open a Support Ticket
                        </div>
                        <div className='text-dark-0'>
                            We love our customers, and we're here to help if you need us. Please keep in mind that not all topics are within the scope of out support. For overall system status,please see <a href='www.hireserver.com' target='_blank'>
                                www.hireserver.com
                            </a>
                        </div>
                        <SpacerHS_TWO />
                        <div>
                            <div className='row d-none'>
                                <div className="col-sm-6">
                                    <div>
                                        <span>Title</span>
                                    </div>
                                    <div>
                                        <input value={titleSupport.val} onChange={handleTitle} type="text" className={`form-control ${titleSupport.isError === true ? 'inputAlertVal' : ''} hs_borderRadius-4 ${titleSupport.touched && titleSupport.isError === false ? 'validFormInput' : ''}`} placeholder='Enter a title for your ticket.' />
                                        {
                                            titleSupport.isError && <FormInputError message={'Title is requried field'} />
                                        }
                                    </div>
                                </div>
                            </div>
                            <SpacerHS_TWO />

                            <div className='row'>
                                <div className="col-sm-6 p-1 pl-md-3 py-md-0 pr-3 pl-md-0 pr-md-1 ">
                                    <div>
                                        <span>What is this regarding?</span>
                                    </div>
                                    <div>
                                        <DropDownSearch
                                        placeholder="Regarding"
                                        selectClasses={`${groupnameError.isError && 'inputAlertValSelect2'} ${groupnameError.isError===false && currPriorites!=0 && 'validFormInputSelect2'}`}
                                          value={currGroupName} setValue={handleChangeGroupName} data={groupname} />
                                        {groupnameError.isError && <FormInputError message={groupnameError.errorMessage} />}
                                    </div>
                                </div>
                                <div className="col-sm-6 pl-1">
                                    <div>
                                        <span>What is you priority?</span>
                                    </div>
                                    <div>
                                        <DropDownSearch placeholder={'priority'} selectClasses={`${priortiesError.isError && 'inputAlertValSelect2'} ${priortiesError.isError===false && currPriorites!=0 && 'validFormInputSelect2'}`}
                                        
                                        value={currPriorites} setValue={handleChangePriorties} data={priorites} />
                                        {priortiesError.isError && <FormInputError message={priortiesError.errorMessage} />}
                                    </div>
                                </div>
                            </div>
                            <SpacerHS_TWO />

                            {/* <div className='row'>
        <div className="col-sm-6">
        <div>
             <span>What is you priority?</span>
         </div>
         <div>
         <DropDownSearch value={currPriorites} setValue={handleChangePriorties} data={priorites} />
         </div>
        </div>
     </div> */}
                            <SpacerHS_TWO />
                            <div className='row'>
                                <div className="col-sm-12">
                                    <div>
                                        <span>Description </span>
                                    </div>
                                    <div>
                                        <Form.Control value={desSupport.val} onChange={handleDesSupport} className={`form-control ${desSupport.isError === true ? 'inputAlertVal' : ''} hs_borderRadius-4 h-md-100p ${desSupport.touched && desSupport.isError === false ? 'validFormInput' : ''}`} as="textarea" />
                                        {
                                            desSupport.isError && <FormInputError message={'Description is requried field'} />
                                        }
                                    </div>
                                </div>
                            </div>
                            <SpacerHS_TWO />

                            <div className='text-primary cursor-pointer mb-md-4'>
                                <label htmlFor='supportattachfile' className='d-flex d-gap-10' >
                                    <span><i className='fa fa-file-archive-o'></i></span>
                                    <span>Attach a file</span>
                                    <input multiple={true} onChange={fileUploadSaveHandler} id="supportattachfile" type="file" className='d-none' />
                                </label>
                                <div className='d-flex d-gap-20 text-dark pt-2'>
                                    {getInputsList()}
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-end d-gap-10'>
                            <Button style={{ borderRadius: '4px' }} className="btn-sm bg-white text-dark" variant="secondary" onClick={handleModalClose}>
                                Close
                            </Button>
                            <Button style={{ borderRadius: '4px' }} className={`btn-sm ${'hs-signin-btn border-0 text-white'} w-150p`} onClick={handlerSubmit}>
                                {/* {formLoading ? <div className="spinner-border text-light" role="status">

                                </div> : 'Open Ticket'} */}

                                {formLoading ? <React.Fragment>
                                    <div className="spinner-border text-light" role="status">

                                    </div> Open Ticket
                                  </React.Fragment> : 'Open Ticket'}
                            </Button>
                        </div>
                    </div>
                   
                </React.Fragment>
            }

        </ModalHs>
    )
}

export default OpenTicketForm