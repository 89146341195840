import React from "react";
import { Card, Form, DropdownButton, Dropdown } from "react-bootstrap";
import ChangeInputControl from "../../hireserver/controls/ChangeInputControl";
import SelectOptionHS from "../../hireserver/controls/SelectOptionHS";
import LocationCard from "../../hireserver/LocationCard";
import SelectionsOptions from "../../hireserver/SelectionsOptions";
import StickyFooter from "../../hireserver/StickyFooter";
import BareMetalContext from "./BareMetalContext/BareMetalContext";
import BareMetalFooter from "./components/BareMetalFooter";
import LocationSection from "./components/LocationSection";
import ServerSideCard from "./components/ServerSideCard";
import ServerSideSection from "./components/ServerSideSection";
import SoftwareSelection from "./components/SoftwareSelection";

import india from "../../../../images/hireserver/imgs/india.png";
import japan from "../../../../images/hireserver/imgs/japan.png";
import england from "../../../../images/hireserver/imgs/england.jpg";
import china from "../../../../images/hireserver/imgs/china.png";
import usa from "../../../../images/hireserver/imgs/usa.png";
import LocationCardFlag from "../../hireserver/SharedComponents/LocationCardFlag";
import MiniCard from "../../hireserver/SharedComponents/MiniCard/MiniCard";
import ServerNameSelection from "./components/ServerNameSelection";
import RootPasswordSection from "./components/RootPasswordSection";
import IPSection from "./components/IPSection";
import { getSEVERNAME } from "../../../../API_ENDPOINTS/API_ENDPOINTS";
import NoDataCard from "../../hireserver/SharedComponents/NoDataCard";
import LoaderHS from "../../hireserver/components/LoaderHS";
import TenureBareMetal from "./components/TenureBareMetal";
import SpacerHS_TEN from "../../hireserver/Spaces/SpacerHS_TEN";
import SpacerHS_TWO from "../../hireserver/Spaces/SpacerHS_TWO";
import DeployBareMetal from "./components/modals/DeployBareMetal";

export default function BareMetalForm() {
  const {
    selectServer,
    setSelectServer,
    rootPasswordAgain,
    setRootPasswordAgain,
    rootPassword,
    setRootPassword,
    selectOs,
    rootPasswordErr,
    selectTagOption,
    serverNameRef,
    setSelectTagOption,
    submitErrors,
    setSubmitErrors,
    SShkey,
    setSShKey,
    setSelectOs,
    serverName,
    setServerName,
    locationState,
    onChangeLocation,
    bareMetalServers,
    locations,
    handleFormClick,
    onChangeBlocksHandler,
    optionsArr,
    MINUS_BTN,
    blocksHandler,
    PLUS_BTN,
    blocksInput,
    setBlocksInput,
    locationDataLoading,
    osList,
    setOslist,
    tenure, setTenure,
    summaryPrice,setSummaryPrice,
    setDeployLoading,
   
  } = React.useContext(BareMetalContext);
  // function getLocations() {
  //   return locations?.map(function ({ DisplayText, SelectText, Value }, i) {
  //     return <LocationCard onChangeLocation={onChangeLocation} state={DisplayText} country={SelectText} idValue={Value} />
  //   })
  // }
  const [inputValue, setInputValue] = React.useState("");
  function handlerClickLocation(e) {
    // console.log(e);
  }

  function getLocations() {
    
    // console.log(locations,'locations')
   

    // if(locations.length === 1 && locations[0] === 'loading'){
    //   return <LoaderHS/>
    // }
   
    if(locationDataLoading){
      return  <div className="position-relative"><LoaderHS position="position-relative m-1" /></div>
    }
    if(locations.length === 0){
      return <NoDataCard title="No Locations Available"  />
     
    }
   
    return locations.map(function ({ location,branchname, branchid,icon,isNeedShow }, i) {
      let SelectText = 'India';
      let image = india;
      // console.log(locations)
      // // console.log(locations,'locations')
      // let isRequired = false;
      let isChecked = false;
      //// console.log(locationState,'locationState')
      
      if (locationState == branchid) {
        // isRequired = true
        isChecked = true;
      }

      if (i == 0) {
        isChecked = true;
      }

      
     
      return (
        <MiniCard
          isRequired={false}
          isChecked={isChecked}
          handler={onChangeLocation}
          key={i + branchid + Math.random()*9}
          OSImage={getSEVERNAME()+'/'+icon.replace('~','')}
          id={branchid}
          name="locationInstances"
         //   cardName={'branchname'}
         nextname={branchname}
         groupname={location}
         isNeedShow={Boolean(isNeedShow)}
        />
      );
    });
  }

  return (
    <React.Fragment>
          <div  style={{ position: "relative",paddingBottom:'100px' }}>
      <Form onSubmit={handleFormClick}>
      <ServerNameSelection
          ref={serverNameRef}
          isNameError={submitErrors.serverNameErr}
          selectTagOption={selectTagOption}
          setSelectTagOption={setSelectTagOption}
          serverName={serverName}
          setServerName={setServerName}
        />
        <hr/>
        <ServerSideSection
          bareMetalServers={bareMetalServers}
          selectServer={selectServer}
          setSelectServer={setSelectServer}
          setTenure={setTenure}
        />
<hr/>
        <LocationSection
          getLocations={getLocations}
        />
<hr/>
        {/* <SoftwareSelection
          selectOs={selectOs}
          setSelectOs={setSelectOs}
          optionsArr={optionsArr}
        /> */}
         <Form.Group className="m-0 hwll world">
          {/* <SelectionsOptions
            typeOS="iso"
            currentOs={selectOs}
            clickHandler={setSelectOs}
            title="Software Selections"
            OptionsCardArr={osList}
          /> */}
        </Form.Group>
      
       
        {/* <RootPasswordSection
          
          ref={rootPasswordErr}

          rootPasswordErr={submitErrors.rootPasswordErr}
          passwordNotMatchErr={submitErrors.passwordNotMatchErr}
          passwordLength={submitErrors.passwordLength}
          confirmPasswordErr={submitErrors.confirmPasswordErr}

          rootPasswordAgain={rootPasswordAgain}
          setRootPasswordAgain={setRootPasswordAgain}
          rootPassword={rootPassword}
          setRootPassword={setRootPassword}
          
        /> */}
        <hr />
        <IPSection
          sshKeyErr={submitErrors.sshKeyErr}
          SShkey={SShkey}
          setSShKey={setSShKey}
        />
  <hr />
       <TenureBareMetal summaryPrice={summaryPrice} setSummaryPrice={setSummaryPrice} blocksInput={blocksInput} selectServer={selectServer}  tenure={tenure} setTenure={setTenure}  />
       
        {/* <SelectOptionHS/> */}
        <SpacerHS_TWO/>
      
        <BareMetalFooter   />
      </Form>
    </div>
    <DeployBareMetal price={summaryPrice.price} setDeployLoading={setDeployLoading} />
    </React.Fragment>
  );
}
