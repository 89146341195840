import Cookies from "js-cookie";
import { getCountriesURL, getCurrenciesListURL, getStatesURL, submitPaymentFormURL } from "../../API_ENDPOINTS/PAYEMENT_FORM_ENDPOINTS";
import { getpaymentInfoURL } from "../../API_ENDPOINTS/PAYMENT_ENDPOINTS";
import { getUserCookieAuth } from "../../store/actions/AuthActions";
import { USER_COOKIE } from "../../store/types/AuthTypes/AuthTypes";
import { API_SERVICES } from "../API_SERVICES";
import { getSessionAndUserId } from "../InstancesServices/InstancesListService";

export function getCurrenciesListService(){
    const { userId, sessionId } = getUserCookieAuth();
    let url = getCurrenciesListURL();

    let config = {
        method: 'get',
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        }
    };

    return API_SERVICES(config);
}

export function getCountriesListService(){
    const { userId, sessionId } = getUserCookieAuth();
    let url = getCountriesURL();

    let config = {
        method: 'get',
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        }
    };

    return API_SERVICES(config);
}
export function getStatesListService(countryId){
    const { userId, sessionId } = getUserCookieAuth();
    let url = getStatesURL(countryId);

    let config = {
        method: 'get',
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        }
    };

    return API_SERVICES(config);
}

export function getpaymentInfoService(){

    const { userId, sessionId } =   getUserCookieAuth();
    let url = getpaymentInfoURL(userId);

    let config = {
        method: 'get',
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        }
    };

    return API_SERVICES(config);
}

export function submitPaymentFormService(userYes,payload){

    const { userId, sessionId } = getUserCookieAuth();
    const url = submitPaymentFormURL();
    const ContactId = userYes ? userId : 0;
    let data  = {
        ...payload,
        OrganizationId:userId,
        ContactId
    }
    let config = {
        method: 'post',
        url,
        headers: {
            'Authorization': 'Basic ' + sessionId
        },
        data
    };

    return API_SERVICES(config);
}