import React from 'react'
import { connect, useDispatch } from 'react-redux';
import { getPaymentWalletAmountService, postAddToWalletService } from '../../../services/paymentServices/PaymentService';
import { putRedirectLinkAction, showPagementGateWayAction } from '../../../store/actions/RedirectPageActions/RedirectPageAction';
import { closeDashboardModal } from '../../../store/actions/UserDashboardActions/UserDashboardActions';
import { checkResData } from '../../../utils/Helpers';
import { sureImgBee } from '../Dashboard/Profile/confirmaddamount/Confirmaddamount';
import CurrencyFC from '../hireserver/components/Currency/CurrencyFC';
import ImageHs from '../hireserver/ImageHs';
import ModalHs from '../hireserver/ModalHs'

function PaymentHomeModal({addWalletPaymentValue,currencyname,paymentAmountModalShow,userPaymentVal,paymentData,userDetails}) {
    const [loading,setLoading] = React.useState(false);
    const [disableClose, setDisableClose] = React.useState(false);
    const [walletDetails,setWalletDetails] = React.useState(null);
    const dispatch = useDispatch();

    React.useEffect(function (){
        getWallet();
    },[]);

    function onCloseModal(){
        dispatch(closeDashboardModal());
        setLoading(false);
    }

    async function addAmountHandler(amount){
        // setLoading(false);
        // dispatch(closeDashboardModal());
debugger;
        setLoading(true);
         
        const wId = walletDetails?.walletid ? walletDetails?.walletid : 0;
        const payload = {
          "orderamount": amount,
          "currencytypeid": paymentData?.currencyid,
          "contactid": userDetails?.userId,
          "walletid":wId,
          "iswalletcredit": true
        }
       

    
        const res = await postAddToWalletService(payload);
    
        if (res.data.code != '0') {
          setLoading(false);
          return;
        }
    
    
        //handleCloseAddAmount();
        const paymentLinkJSON = JSON.parse(res.data.result);
        const paymentlink = paymentLinkJSON?.paymentlink;
      
        dispatch(putRedirectLinkAction(paymentlink));
        dispatch(showPagementGateWayAction());

    }

    async function getWallet() {
        let res = await getPaymentWalletAmountService();
        let isValid = checkResData(res, true)
        if (isValid === false) {
        //   setWalletValue(0);
          // setLoading(false);
          return;
        }
    
        let resJSON = JSON.parse(res.data.result)[0];
        if (!resJSON) {
        //   setWalletValue(0);
          // setLoading(false);
          return;
        }
     
        setWalletDetails(resJSON);
      }


  return (
    <ModalHs footerNone={true} isNeed={false} btnTitle="Procced" showModalHS={paymentAmountModalShow} >
        
        <div className='w-100'>
                <div className='align-item-center d-flex flex-column justify-content-center w-100'>
                    <div className='px-3 py-4'>
                        <div className='align-item-center d-flex justify-content-center'>
                        <ImageHs src={sureImgBee} height="150px" width="150px" />
                    
                        </div>
                        <div className='text-center'>
                            <div>Are you sure? </div>
                           <div className=''>
                           You want to add amount of <span className='text-primary font-w500' >{<CurrencyFC name={currencyname} val={userPaymentVal} />} </span> to your wallet?
                           </div>
                        </div>
                    </div>
                </div>
                <div className=' justify-content-end d-gap-10 d-flex p-3'>
                    <button disabled={disableClose} className={`bg-danger border-0 btn-sm hs_borderRadius-4 text-white ${disableClose ? 'd-none' : ''}`} onClick={onCloseModal}>
                        Cancel
                    </button>
                    <button onClick={()=>addAmountHandler(userPaymentVal)} className='w-150p hs-signin-btn border-0 btn-sm hs_borderRadius-4 text-white'>
                        {loading && <React.Fragment>
                            <div className="spinner-border text-white text-light" role="status"></div> Proceed</React.Fragment>}
                        {!loading && 'Proceed'}
                    </button>
                </div>
            </div>
       
    </ModalHs>
  )
}
const mapStatesToProps = ({ auth, PaymentInfoReducer,userDashboard }) => {
    return {
      currencyname: PaymentInfoReducer?.paymentData?.currencyname,
      isShowPayment:PaymentInfoReducer?.paymentData?.defaultaddressid,
      paymentAmountModalShow:userDashboard.paymentAmountModal,
      userPaymentVal:userDashboard.userPaymentVal,
      paymentData: PaymentInfoReducer?.paymentData,
      userDetails: auth.auth,
    }
  };

export default connect(mapStatesToProps)(PaymentHomeModal)