import React from 'react'
import { Form } from 'react-bootstrap';
import HeadingHS from '../../../hireserver/controls/HeadingHS';

export default function LocationSection({getLocations}) {
  return (
    <Form.Group>
    <Form.Text>
      <Form.Text className='d-flex my-1'>  <HeadingHS as="h3" title="Locations" /> </Form.Text>
    </Form.Text>
    <div className='align-items-start d-flex d-gap-15 flex-wrap justify-content-center justify-content-lg-start optionLocationhandler'>
      {getLocations()}
    </div>
  </Form.Group>
  )
}
