import React from 'react';

export default function useModalHsHook(){
    const [showModal,setShowModal] = React.useState(false);
    const [customData,setCustomData] = React.useState(null);
    
    function openModal(){
        setShowModal(true);
    }

    function closeModal(){
        setShowModal(false);
    }



    return {
        showModal,setShowModal,
        openModal,closeModal,
        customData,setCustomData
    }
}