import React from 'react'
import FormInputError from '../../../../../hireserver/components/FormInputError';
import TokenInput from './TokenInput';

function TokenMaster({ setTokenState, tokenState, tokens, onInputTokenInput,disabledInput=false }) {
    function renderTokens() {
        let tokenHTML = [];

        for (let token in tokens) {
            let item = tokens[token];

            tokenHTML.push(<div className='col-sm-4'>
                <TokenInput disabledInput={disabledInput} onInputTokenInput={onInputTokenInput} item={item} key={item.id} />
               {item.error.isError ? <FormInputError message={item.error.message} />:null}
            </div>)
        }
        // setTokenState(obj);
        return tokenHTML;
    }
    return (
        <React.Fragment>
            {renderTokens()}
        </React.Fragment>

    )
}

export default TokenMaster