import React from 'react'
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { connect, useDispatch } from 'react-redux';
import { getpaymentInfoService, submitPaymentFormService } from '../../../services/Profile/PaymentFormServices';
import { closePaymentInfoFormAction, putPaymentInfoStatus } from '../../../store/actions/PaymentInfoActions/PaymentInfoAction';
import StaticPaymentDetails from '../../../utils/StaticsClasses.js/StaticPaymentDetails';
import { handlerAlert } from '../../components/Dashboard/Instances/InstancesListComponents/DropDownInstancesList';
import FormInputError from '../../components/hireserver/components/FormInputError';
import InputErrorBackground from '../../components/hireserver/components/InputErrorBackground';
import DropDownSearch from '../../components/hireserver/controls/DropDownSearch';
import SpacerHS_ONE from '../../components/hireserver/Spaces/SpacerHS_ONE';
import SpacerHS_TWO from '../../components/hireserver/Spaces/SpacerHS_TWO';
import CountriesList from './components/CountriesList';
import CurrenciesList from './components/CurrenciesList';
import StatesList from './components/StatesList';
import './BillingPaymentInfoForm.css';
import InputLabelUp from '../../components/hireserver/controls/InputLabelUp/InputLabelUp';
import DropDownSelectSearch from '../../components/hireserver/controls/dropdownselectsearch/DropDownSelectSearch';


function PrivatePaymentInfo({ successHandler, userDetails, setUpdateHandler, paymentData }) {
  const [firstName, setFirstName] = React.useState({
    val: '',
    isError: '',
    touched: false,
    errorMessage: ''
  });
  const [companyName, setCompanyname] = React.useState({
    val: '',
    isError: '',
    touched: false,
    errorMessage: ''
  });
  const [taxNumber, setTaxNumber] = React.useState({
    val: '',
    isError: '',
    touched: false,
    errorMessage: ''
  });
  const [desBilling, setDesBilling] = React.useState({
    val: '',
    isError: '',
    touched: false,
    errorMessage: ''
  });
  const [addressState, setAddressState] = React.useState({
    val: '',
    isError: '',
    touched: false,
    errorMessage: ''
  });
  const [cityState, setCityState] = React.useState({
    val: '',
    isError: '',
    touched: false,
    errorMessage: ''
  });
  const [type, setType] = React.useState("Dictamen");
  const [currencyState, setCurrencyState] = React.useState(0);
  const [country, setCountry] = React.useState(0);

  const [states, setStates] = React.useState(0);
  const [isLoading, setIsloading] = React.useState(false);
  const [zipCodeState, setZipCodeState] = React.useState({
    val: '',
    isError: '',
    touched: false,
    errorMessage: ''
  });
  const [successMessage, setSuccessMessage] = React.useState({
    isTrue: false,
    errorMessage: ''
  })
  const [telephone, setTelephone] = React.useState({
    val: '',
    isError: '',
    touched: false,
    errorMessage: ''
  });
  const [serverError, setServerError] = React.useState({
    isError: false,
    errorMessage: ''
  })

  const [countryError, setErrorCountry] = React.useState({
    isError: false,
    errorMessage: ''
  })
  const [stateError, setErrorState] = React.useState({
    isError: false,
    errorMessage: ''
  });
  const [currencyStateError, setCurrencyStateError] = React.useState({
    isError: false,
    errorMessage: ''
  })
  const dispatch = useDispatch();

  const [isUpdate, setIsUpdate] = React.useState(false);
  const [height, setHeight] = React.useState(300);
  React.useEffect(function () {
    setHeight('auto')
    return () => {
      const payload = {
        val: '',
        isError: '',
        touched: false,
        errorMessage: ''
      }

      const errorPayload = {
        isError: false,
        errorMessage: ''
      }
      setFirstName(payload);
      setCompanyname(payload);
      setTaxNumber(payload);
      setTelephone(payload);
      setZipCodeState(payload);
      setAddressState(payload);
      setCityState(payload)
      setCurrencyState(0);
      setCountry(0);
      setStates(0);


      setCurrencyStateError(errorPayload);
      setErrorState(errorPayload);
      setErrorCountry(errorPayload);
      setServerError(errorPayload);

    }
  }, []);

  // React.useEffect(function (){
  //   document.querySelector(`input[type="number"]`).addEventListener("keypress", removeInputNumberAp);

  //   document.querySelector(`input[type="number"]`).removeEventListener('keypress',removeInputNumberAp)
  // },[]);  

  // function removeInputNumberAp(e){
  //   if (e.which != 8 && e.which != 0 && e.which < 48 || e.which > 57)
  //     {
  //         e.preventDefault();
  //     }
  // }

  React.useEffect(function () {

    if (paymentData === null) return;

    if (!paymentData['currencyname']) {
      return;
    }
    setIsUpdate(true);

    setUpdateHandler(true);


    
    StaticPaymentDetails.isEditing = true;
    if (paymentData['organizationtype'] === 2) {
      setFirstName({
        val: paymentData['organizationname'],
        isError: false,
        touched: !paymentData['contactpersonname'] ? false : true,
        errorMessage: ''
      });
      // setCompanyname({
      //   val: paymentData['organizationname'],
      //   isError: false,
      //   touched: !paymentData['organizationname'] ? false : true,
      //   errorMessage: ''
      // });

    } else {
      setFirstName({
        val: paymentData['contactpersonname'],
        isError: false,
        touched: !paymentData['contactpersonname'] ? false : true,
        errorMessage: ''
      });
      setCompanyname({
        val: paymentData['organizationname'],
        isError: false,
        touched: !paymentData['organizationname'] ? false : true,
        errorMessage: ''
      });
    }


    setCurrencyState({
      label: paymentData['currencyname'],
      value: paymentData['currencyid']
    })

    setZipCodeState({
      val: paymentData['zipcode'],
      isError: false,
      touched: !paymentData['zipcode'] ? false : true,
      errorMessage: ''
    });

    setTelephone({
      val: paymentData['phonenumber'],
      isError: false,
      touched: !paymentData['phonenumber'] ? false : true,
      errorMessage: ''
    })



    setStates({
      value: paymentData['cityid'],
      label: paymentData['cityname'],
    });
    
    if (paymentData['countryid'] != '' || paymentData['countryid'] != undefined || paymentData['countryid'] != null) {
      StaticPaymentDetails.isCountry = true;
    }
    setCountry({
      value: paymentData['countryid'],
      label: paymentData['countryname'],
    });



    setAddressState({
      val: paymentData['addressline'],
      isError: false,
      touched: true,
      errorMessage: ''
    })

    setTaxNumber({
      val: paymentData['gstinno'],
      isError: false,
      touched: true,
      errorMessage: ''
    })

  }, []);

  function currencyHandler(id) {
    setCurrencyState(id)
  }

  function countryHandler() {
    //setStates()
  }

  function handleModalClose() {
    dispatch(closePaymentInfoFormAction())
  }


  function checkValidtions() {
    let isTrue = false;



    if (firstName.val == '' || firstName.val == null) {
      isTrue = true;
      setFirstName({
        ...firstName,
        val: firstName.val,
        isError: true,
        errorMessage: 'Please fill in required field',

      })
    }

    if (addressState.val == '' || addressState.val == null) {
      isTrue = true;
      setAddressState({
        ...addressState,
        val: addressState.val,
        isError: true,
        errorMessage: 'Please fill in required field',

      })
    }

    if (telephone.val == '' || telephone.val == null) {
      isTrue = true;
      setTelephone({
        ...telephone,
        val: telephone.val,
        isError: true,
        errorMessage: 'Please fill in required field',
      })
    }

    if (telephone.val != '' && telephone.val.length != 10) {
      isTrue = true;
      setTelephone({
        ...telephone,
        val: telephone.val,
        isError: true,
        errorMessage: 'Invalid telephone',
      })
    }



    if (country?.value == 0 || country == 0) {
      isTrue = true;

      setErrorCountry({
        isError: true,
        errorMessage: 'Please fill in required field'
      })
    }

    if (zipCodeState.val == '' || zipCodeState.val == null) {
      isTrue = true;
      setZipCodeState({
        ...zipCodeState,
        val: zipCodeState.val,
        isError: true,
        errorMessage: 'Please fill in required field'
      })
    }

    if (country?.label === 'India') {
      if (zipCodeState.val.length < 6) {
        setZipCodeState({
          ...zipCodeState,
          val: zipCodeState.val,
          isError: true,
          errorMessage: 'Pin Code should be more than 6'
        })
      }
    }



    if (states?.value == 0 || states == 0  || states == null) {
      isTrue = true;
      setErrorState({
        isError: true,
        errorMessage: 'Please fill in required field'
      })
    }

    if (currencyState?.value == 0 || currencyState == 0) {
      isTrue = true;
      setCurrencyStateError({
        isError: true,
        errorMessage: 'Please fill in required field'
      })
    }

    // if (telephone?.val == '' || telephone?.val == null) {
    //   isTrue = true;
    //   setTelephone({
    //     ...telephone,
    //     isError: true,
    //     errorMessage: 'Please fill in required field'
    //   })
    // }

    // setCompanyname({
    //   ...companyName,
    //   val: companyName.val,
    //   isError: true,
    //   errorMessage: 'Please fill in required field',
    // })

    if (companyName?.val == '' || companyName?.val == null) {
      isTrue = true;
      setCompanyname({
        ...companyName,
        val: companyName.val,
        isError: true,
        errorMessage: 'Please fill in required field',
      })

      // isTrue = true;
      // setServerError({
      //   isError: true,
      //   errorMessage: 'You need to input number also'
      // })

      // if (taxNumber?.val == '') {
      //   isTrue = true;
      //   setServerError({
      //     isError: true,
      //     errorMessage: 'You need to input number also'
      //   })
      // }

    }

    if (taxNumber?.val == '' || taxNumber?.val == null) {



      isTrue = true;
      setTaxNumber({
        ...taxNumber,
        val: taxNumber.val,
        isError: true,
        errorMessage: 'Please fill in required field'
      })

      // if (companyName.val == '') {
      //   isTrue = true;
      //   setServerError({
      //     isError: true,
      //     errorMessage: 'You need to input Company Name also'
      //   })
      // }
    }

    return isTrue;
  }

  async function okayHandler(e) {
    setIsloading(true);
    // console.log({
    //   firstName,
    //   addressState,
    //   currencyState,
    //   country: country?.value,
    //   states: states?.value,
    //   telephone
    // })

    let isNotValid = checkValidtions();
    if (isNotValid) {
      setIsloading(false)
      return;
    }


    // let payload = {
    //   "cityid": states?.value,
    //   "addressline1": addressState.val,
    //   "zipcode": zipCodeState.val

    // }

    let updateForm = false;
    let formType = 2;
    let contactVal = companyName.val;

    if (companyName.val !== null && companyName.val != '' && taxNumber.val !== null && taxNumber.val != '') {
      // businessType = 1
      formType = 1;
    } else {
      contactVal = firstName.val;
    }

    if (paymentData && paymentData['currencyname']) {
      updateForm = true;
    }


    let cityId = states.__isNew__ ? 0 : states?.value;


    let payload = {
      "cityid": Number(cityId),
      "CityName": states?.label,
      "addressline1": addressState.val,
      "zipcode": zipCodeState.val,
      "OrganizationType": formType,
      "organizationname": contactVal,
      //"OrganizationId":,
      "ContactName": firstName.val,
      "ContactId": 0,
      "countryname": country.label,
      "countryid": country.value,
      "statename": states?.label,
      "phonenumber": telephone?.val,

      "addressline1": addressState.val,
      "currencyid": currencyState?.value,
      "taxno": taxNumber.val,
      "BillingInfoExists": updateForm,
      "lobid": paymentData['lobid'],
      "gstindetailid": paymentData['gstindetailid'] || 0
    }
    // console.log(payload)
   // console.log(payload);

    // console.log(payload, 'payloadpayloadpayloadpayloadpayloadpayload')
    let res = '';
    try {
      res = await submitPaymentFormService(updateForm, payload);
      if (res.data.code != '0') {
        setIsloading(false);
        setServerError({
          isError: true,
          errorMessage: res?.data?.message
        })
        return;
      }
      if (res.data.result.includes('Billing info saved succesfully')) {
        let isFilled = false;
        let paymentData = null;
        const checkPaymentStatus = await getpaymentInfoService();
        paymentData = JSON.parse(checkPaymentStatus.data.result);
        isFilled = paymentData[0]?.defaultaddressid == 0;
        dispatch(putPaymentInfoStatus(paymentData[0]));

        setIsloading(false);
        setSuccessMessage({
          isTrue: true,
          message: 'Form filled successful'
        });
        dispatch(closePaymentInfoFormAction())
        handlerAlert("success", "Billing info has been updated!")

        //successHandler(true);
        // setTimeout(function () {
        //   handleModalClose();
        // }, 3000);
      } else {
        setServerError({
          isError: true,
          message: res?.data?.message
        })
      }
    } catch (error) {
      setIsloading(false);
      // console.log(error);
      // alert(error?.message);
      setServerError({
        isError: true,
        errorMessage: res?.data?.message
      })
    }
    // console.log(res);

  }

  function simpleValidInput(val, setterFunction) {
    let payload = {
      val,
      isError: false,
      errorMessage: '',
      touched: true
    }

    if (val === '' || val === null) {
      payload.isError = true;
      payload.errorMessage = 'Please fill in required field'
    }

    setterFunction(payload);
  }

  function notValidInput(val, setterFunction) {
    let payload = {
      val,
      isError: false,
      errorMessage: '',
      touched: true
    }
    setterFunction(payload);
  }


  function simpleValidNumberInput(e, val, setterFunction, len = 0,) {
    if (len != 0) {
      if (e.target.value.length > len) return;
    }
    let payload = {
      val,
      isError: false,
      errorMessage: '',
      touched: true
    }

    if (e.which != 8 && e.which != 0 && e.which < 48 || e.which > 57) {
      e.preventDefault();
      return;
    }

    if (val === '' || val === null) {
      payload.isError = true;
      payload.errorMessage = 'Please fill in required field'
    }



    setterFunction(payload);
  }

  function getClass(dataObj) {

    return `form-control ${dataObj.isError === true ? 'inputAlertVal' : ''} hs_borderRadius-4 ${dataObj.touched && dataObj.isError === false ? 'validFormInput' : ''}`
  }

  function notImpGetClass(dataObj) {
    return `form-control ${dataObj.isError === true ? '' : ''} hs_borderRadius-4 ${dataObj.touched && dataObj.isError === false ? 'validFormInput' : ''}`
  }
  function blockInvalidChar(e) {

    if (['e', 'E', '+', '-'].includes(e.key)) {
      e.preventDefault();
    }

    if (e.keyCode >= 65 && e.keyCode <= 90) {
      e.preventDefault();
    }

    console.log(e.keyCode, 'dsdasjdhk')
  }

  // function blockInvalidChar(){

  // }


  React.useEffect(function () {
    if (serverError.isError) {
      setTimeout(function () {
        setServerError({
          isError: false,
          serverError: ''
        })
      }, 2000);
    }
  }, [serverError.isError]);

  return (
   <div>
     <div className='d-flex justify-content-center individualpaymetinfoaddHeight privatePaymentInfo'>
        <div className='billingFromDiv card p-3'>
        
          {
            serverError.isError && <InputErrorBackground message={serverError.errorMessage} />
          }

          {
            isUpdate && <React.Fragment>
           
              <InputLabelUp disabled={true} classNames={`bg-light form-control hs_borderRadius-4 validFormInput`} placeholder="Email" value={userDetails?.userEmail} id="email" type="text" />
              <SpacerHS_ONE />
            </React.Fragment>
          }

          <div className='d-flex justify-content-between w-100 flex-column'>

            <InputLabelUp classNames={`${getClass(companyName)}`} placeholder="Company Name" setValue={(e) => simpleValidInput(e.target.value, setCompanyname)} value={companyName.val} id="companyname" type="text" />
            {
              companyName.isError && <FormInputError message={companyName.errorMessage} />

            }
          </div>
         

          <SpacerHS_ONE />

        
          <div className='row m-0 w-100'>
         

           <div className="col-sm-6 p-0  pr-md-1 pb-md-0 pb-2">
           <InputLabelUp classNames={`${getClass(firstName)}`} placeholder="Contact Person" setValue={(e) => simpleValidInput(e.target.value, setFirstName)} value={firstName.val} id="firstname" type="text" />
            {
              firstName.isError && <FormInputError message={firstName.errorMessage} />

            }
           </div>
           
           

           <div className="col-sm-6 pl-md-1 p-0">
           <InputLabelUp onKeyDown={blockInvalidChar} classNames={`${getClass(telephone)}`} placeholder="Telephone Number" setValue={(e) => simpleValidNumberInput(e, e.target.value, setTelephone, 10)} value={telephone.val} id="telephone" type="number" />
            {telephone.isError && <FormInputError message={telephone.errorMessage} />}
           </div>
          </div>

          <SpacerHS_ONE />
          <div className="row  p-0 ">
          <div className="col-sm-12  ">
            <div className='d-flex  justify-content-between'>
           
           <InputLabelUp classNames={`${getClass(addressState)}`} placeholder="Address" setValue={(e) => simpleValidNumberInput(e, e.target.value, setAddressState)} value={addressState.val} id="address" type="text" />

         </div>
         {
           addressState.isError && <FormInputError message={addressState.errorMessage} />
         }
            </div>
          </div>
         

         




         <SpacerHS_ONE />
          <div className="row w-100 m-0 p-0">


          



          <div className='col-sm-6 m-0 p-0 pr-md-1 pb-md-0 pb-2'>
   {/* <SpacerHS_ONE /> */}
   <CountriesList isDisable={isUpdate} countryError={countryError} setErrorCountry={setErrorCountry} country={country} setCountry={setCountry} />
   </div>



            {/* <SpacerHS_ONE /> */}
            <div className='col-sm-6 p-0 pl-md-1'>
          <StatesList isUpdate={isUpdate} isDisable={isUpdate || !country?.value} stateError={stateError} setErrorState={setErrorState} country={country} states={states} setStates={setStates} />
          </div>
            {/* <SpacerHS_ONE /> */}
            


            {/* <SpacerHS_ONE /> */}
           

            

            {/* <SpacerHS_ONE /> */}
          </div>
          <SpacerHS_ONE />

         
<div className='row m-0'>
<div className='col-sm-6 p-0  pr-md-1 pb-md-0 pb-2'>
            <InputLabelUp onKeyDown={blockInvalidChar} classNames={`${getClass(zipCodeState)}`} placeholder="Pin Code" setValue={(e) => simpleValidNumberInput(e, e.target.value, setZipCodeState, 6)} value={zipCodeState.val} id="address" type="number" />
            {
              zipCodeState.isError && <FormInputError message={zipCodeState.errorMessage} />

            }
            </div>
   <div className="col-sm-6  m-0 p-0 pl-md-1 ">

<CurrenciesList country={country} isDisable={isUpdate} currencyStateError={currencyStateError} setCurrencyStateError={setCurrencyStateError} currencyHandler={currencyHandler} currencyState={currencyState} />

</div>


</div>

          <SpacerHS_ONE />
          <div className='d-flex justify-content-around'>
           
            <div className='col-sm-12 m-0 p-0'>
          
            <InputLabelUp classNames={`${getClass(taxNumber)}`} placeholder="Tax Number" setValue={(e) => simpleValidInput( e.target.value, setTaxNumber)} value={taxNumber.val} id="taxnumber" type="text" />
            {/* <InputLabelUp onKeyDown={blockInvalidChar} classNames={`${getClass(taxNumber)}`} placeholder="Tax Number" setValue={(e) => simpleValidNumberInput(e, e.target.value, setTaxNumber)} value={taxNumber.val} id="taxnumber" type="number" /> */}
            {
              taxNumber.isError && <FormInputError message={taxNumber.errorMessage} />

            }


          </div>

          </div>
          <SpacerHS_ONE />
          
          
          {
            successMessage.isTrue && <div className='text-center text-primary'>{successMessage.errorMessage}</div>
          }
          <SpacerHS_TWO />
          <div className='d-flex justify-content-end  d-gap-10 '>  <Button style={{ borderRadius: '4px' }} className="btn-sm bg-white  text-dark" variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
            <Button style={{ borderRadius: '4px' }} className={`btn-sm hs-signin-btn w-120p `} variant={'success'} onClick={okayHandler}>
              {isLoading ? <React.Fragment>
                <div className="spinner-border text-light" role="status">

                </div> Submit
              </React.Fragment> : 'Submit'}
            </Button></div>
        </div>
      </div>
   </div>
  )
}


const mapStatesToProps = ({ authAlertReducer, auth, PaymentInfoReducer }) => {
  return {

    isPaymentInfo: PaymentInfoReducer.isPayment,
    paymentData: PaymentInfoReducer?.paymentData,
    userDetails: auth.auth,
  };
};


export default connect(mapStatesToProps)(PrivatePaymentInfo);