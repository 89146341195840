import React from 'react'

function TokenInput({item,onInputTokenInput,disabledInput}) {
  return (
    <div className='w-100'>
        <div>{item.name}</div>
        <div>
            <input disabled={disabledInput} className='form-control hs_borderRadius-4' value={item.val} onChange={(e)=>onInputTokenInput(e,item)} type="text" />
        </div>
    </div>
  )
}

export default TokenInput