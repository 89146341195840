import React from 'react';
import FormInputError from '../../../hireserver/components/FormInputError';
import HeadingHS from '../../../hireserver/controls/HeadingHS';
import SectionInput from '../../../hireserver/SharedComponents/SectionInput/SectionInput';
import DropdownSelect from '../../Instances/components/InnerComponents/DropdownSelect';

function IPSection({SShkey,setSShKey,sshKeyErr}) {
    return (
        <div>
            {/* <div className='pb-2'>
                <SectionInput title="SSH Key" secTitle="Key" subText="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, fugiat?" inputValue={SShkey} setInputValue={setSShKey} />
                {sshKeyErr && <FormInputError message="Please enter your SSH Key" />}
            </div> */}
            {/* <hr />
            <div className='pb-2'>
                <SectionInput title="Private IP" secTitle="Private IP" subText="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, fugiat?" inputValue={inputValue} setInputValue={setInputValue} />

            </div> */}
              <HeadingHS title="Networking" as="h3 text-primary"  />
            <div>
                <DropdownSelect/>
            </div>
        </div>
    )
}

export default React.memo(IPSection);