import React from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import tick from '../../../../../../images/hireserver/imgs/ticksuccess.png'
import SpacerHS_TEN from '../../../../hireserver/Spaces/SpacerHS_TEN'
function CreatedMachine() {
    return (
        <Card className='p-3'>
            <Card.Body>
                <div className='d-flex justify-content-center align-items-center flex-column p-5'>
                    <div className='text-center'>
                        <img className='w-50p' src={tick} />
                    </div>
                    <div className='text-primary font-30 font-w600'>
                        Machine Created Successfully
                    </div>
                    
                    <SpacerHS_TEN/>
                    <div className='hs_text-light text-center'>
                        {/* Your Machine is Created */}
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsum dignissimos, minus praesentium explicabo facilis asperiores. Id tempore aut consectetur dicta?
                    </div>
                    <SpacerHS_TEN/>
                    <SpacerHS_TEN/>
                    
                  <div className='row d-gap-10 justify-content-center w-100'>
                  <Link style={{
                        padding:'10px 15px'
                    }} className='btn col-12 col-md-3 viewInstancesCreated hs_borderRadius-4 hs-signin-btn hs_borderRadius-4' to="/instances/create">
                        Create a other instance 
                    </Link>
                  <Link style={{
                        padding:'10px 15px'
                    }} className='btn col-12 col-md-3 viewInstancesCreated hs_borderRadius-4 hs-signin-btn hs_borderRadius-4' to="/">
                        View Instances 
                    </Link>
                  </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default CreatedMachine