import React from 'react'
import { connect } from 'react-redux';
import { createInstancesSnapShotService, getInstanceSnapShotService, postInstanceSnapShotRollBackService } from '../../../../../../../services/InstancesServices/InstancesListService';
import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";
import { MDBDataTable } from "mdbreact";
import { handlerAlert } from '../../../InstancesListComponents/DropDownInstancesList';

import LoaderHS from '../../../../../hireserver/components/LoaderHS';

import SpacerHS_TEN from '../../../../../hireserver/Spaces/SpacerHS_TEN';
import DropDownSnapShot from './SnapShotComponents/DropDownSnapShot';
import SnapShotRemoveModal from './SnapShotComponents/SnapShotRemoveModal';
import { cloneDeep } from 'lodash';
import { TABLE_ENTIRES } from '../../../../../../../utils/Emuns';


function SnapShotTab({ currDetailsItem }) {
  
  const [headers, setHeaders] = React.useState([
    {
      label: "Name",
      field: "name",
      sort: "asc",
    },

    {
      label: "Description",
      field: "description",
      sort: "asc",
    },


    {
      label: "Snaptime",
      field: "snaptime",
      sort: "asc",
    },

    {
      label: "Vmstate",
      field: "vmstate",
      sort: "asc",
      // attributes: { className: "text-center" },
    },
    {
      label: "",
      field: "rollback",
      sort: "asc",
      // attributes: { className: "text-center" },
    },
  ]);
  const [formatData, setFormatData] = React.useState({
    columns: [],
    rows: [],
  });

  const [loading,setLoading] = React.useState(false);
  const [snapshotName,setSnapShotName] = React.useState('');
  const [snapshotDes,setSnapShotDes] = React.useState('');

  const [snapShotCurr,setSnapShotCurr] = React.useState('');
  const [removeModal,setRemoveModal] = React.useState(false);

  async function getSnapShotList() {
    setLoading(true);
    if (Object.keys(currDetailsItem).length === 0) return;
    
    const { vmid, hostname, branchid } = currDetailsItem;

    let res = await getInstanceSnapShotService(vmid, hostname, branchid);
    if (res.data.error != null) return;
    if (res.data === '') {
      handlerAlert("danger", 'No Data Found', "danger");
      setFormatData({
        columns: headers,
        rows: []
      });
      setLoading(false)
      return;
    }
    if (res.data.code != "0") {
      handlerAlert("danger", res.data.code, "danger");
      setLoading(false)
      return;
    }

    if (res.data.errors !== null) {
      handlerAlert("danger", res.data.errors[0], "danger");
      setLoading(false)
      return;
    }

    if (res.data.result === "") {
      handlerAlert("danger", "Empty Data", "danger");
      setFormatData({
        columns: headers,
        rows: []
      });
      return;
    }
    let resData = JSON.parse(res.data.result)?.data;
   
    let newRows = [];
    for (let i = 0; i < resData.length; i++) {
      const { description, name, snaptime, vmstate } = resData[i];
      let isRoll = name === 'current' ? '' :<DropDownSnapShot setLoading={setLoading} setRemoveModal={setRemoveModal} setSnapShotCurr={setSnapShotCurr} name={name} />
      let newRow = {
        description,
        name,
        snaptime,
        vmstate,
        rollback: isRoll

      }
      newRows.push(newRow);
    }
    setFormatData({
      columns: headers,
      rows: newRows
    });
    setLoading(false);
  }
  React.useEffect(function () {

    
    return () => {
      setSnapShotDes('');
      setSnapShotName('');
      setLoading(false);
    }
  }, []);

  React.useEffect(function (){
    getSnapShotList();
  },[currDetailsItem]);


  async function createClickHandleSnapshot(e){
    const { vmid, hostname, branchid } = currDetailsItem;
    const res = await createInstancesSnapShotService(vmid,hostname,branchid,{
      snapname:snapshotName,
      description:snapshotDes
    });
   
    // console.log(res)
    setSnapShotDes('');
    setSnapShotName('');
    if(res.data.message !== "Success"){
      handlerAlert("danger",res.data.message,'danger');
      return;
    }
    handlerAlert("success",'SnapShotCreated');
    getSnapShotList();
  }

  if(loading){
    return <LoaderHS/>
  }

  function onPageChangeHandler(){
    const newData =  cloneDeep(formatData);
    setFormatData(newData);
  }

  return (
    <div>
      <div className='row'>
        <div className="col-md-3">
          <label className='font-w700'>Name</label>
          <input value={snapshotName} onInput={(e)=>setSnapShotName(e.target.value)} className='form-control border-radius-hs' type="text" />
        </div>
        <div className="col-md-7">
          <label className='font-w700'>Description</label>
          <input value={snapshotDes} onInput={(e) => setSnapShotDes(e.target.value)} className='form-control border-radius-hs' type="text" />
        </div>
        <div className="col-md-2 d-flex justify-content-center align-items-center">
          <button onClick={createClickHandleSnapshot} className='btn btn-primary btn-sm mt-4 hs-signin-btn border-0'>Create</button>
        </div>
      </div>
      <SpacerHS_TEN/>
      <div className="table-flow border-top">
        <Card style={{ width: "100rem" }} className="detail-table">
          <ListGroup variant="flush">
            <ListGroup.Item className="p-0">
              <div>
                <MDBDataTable paging={Boolean(formatData.rows.length>TABLE_ENTIRES)} onPageChange={onPageChangeHandler} className='snapshotTable' searching={false} disableRetreatAfterSorting={true} entriesOptions={[1, 5, 20, 25]} entries={TABLE_ENTIRES} data={formatData} />
              </div>
            </ListGroup.Item>
          </ListGroup>
        </Card>

      </div>
      <SnapShotRemoveModal getSnapShotList={getSnapShotList} setRemoveModal={setRemoveModal} snapShotCurr={snapShotCurr} curSelectItem={currDetailsItem} showHsModal={removeModal}  />
    </div>
  )
}

const mapStateToProps = ({ instanceDetails }) => {
  return {
    currDetailsItem: instanceDetails.currDetailsItem,
  };
};
export default connect(mapStateToProps)(SnapShotTab);