import axios from "axios";
import React, { Children } from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch, withRouter } from "react-router-dom";
import { postExecScriptURL, SEVERNAME } from "../../../../../../../API_ENDPOINTS/API_ENDPOINTS";
import { callAPI } from "../../../../../../../services/API_SERVICES";
import { getUserAllMachinesFirewallService } from "../../../../../../../services/FirewallServices/FirewallListService";
import { getScriptMarketService, postscriptmarketService } from "../../../../../../../services/InstancesServices/InstanceCurrListNewDropdownService";
import { getDetailsScriptService, getScriptOutputByIdService, getScriptTableService, getScriptsByParentIdService, getSessionAndUserId, postExecScriptService, postSaveCronJobService } from "../../../../../../../services/InstancesServices/InstancesListService";
import { openAuthModal } from "../../../../../../../store/actions/AuthAlertActions/AuthAlertActions";
import { checkIsObjectThere, checkResData, getTokenFilter, isJsonStringHelper, isSafeCronTosave } from "../../../../../../../utils/Helpers";
import useInputHook from "../../../../../../hooks/InputHook/useInputHook";
import { handlerAlert } from "../../../../Instances/InstancesListComponents/DropDownInstancesList";
import ScriptMarketContext from "./ScriptMarketContext";
import uuid from "react-uuid";
import useCronJobHook from "../hooks/useCronJobHook";
import useScriptOutPutPageHook from "../hooks/useScriptOutPutPageHook";




function ScriptMaketContextMain({ children, history }) {

    const dispatch = useDispatch();
    const [dataList, setDataList] = React.useState([]);
    const [scriptsList, setScriptList] = React.useState([]);
    const [toggleEditor, setToggleEditor] = React.useState(false);
    const [defaultValues, setDefaultValue] = React.useState(null);
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [currentDelData, setCurrenrDelData] = React.useState(null);

    const [saveAndExecError,setSaveAndExecError] = React.useState({
        isError:false,
        message:null
    })

    const [editSaveAndExecOptions,seteditSaveAndExecOptions] = React.useState(false);

    // init data for textArea inputs
    const [initDataTextArea, setInitDataTextArea] = React.useState({});

    const [outputList, setOutputList] = React.useState([]);

    const [tokenoutputid, settokenoutputid] = React.useState(null);

    const [timerState, setTimerState] = React.useState(null);

    const [outputView,setOutputView] = React.useState(false);

    const [completeOutput,setCompleteOutput] = React.useState('');

    const timerRef = React.useRef();

    const {cronjobsList,setCronJobsList,cronvalue,setCronValue,customCronValue,setCustomCronValue,enableCronJob, setEnableCronJob} = useCronJobHook();

    //loaders state
    const [loadingCreate, setLoadingCreate] = React.useState(false);
    const [loadingDelete, setLoadingDelete] = React.useState(false);
    const [optionsLoading, setOptionsLoading] = React.useState(false);
    const [mainLoading, setmainLoading] = React.useState(false);

    const [showoutputInExecModal, setShowOutputInExecModal] = React.useState(false);

    //forms inputs
    const { value: editorVal, setValue: setEditorVal, error: editorError, setError: setEditorError, resetAll: editorReset } = useInputHook();
    const { value: nameVal, setValue: setNameVal, error: nameError, setError: setNameError, resetAll: nameReset } = useInputHook();
    const { value: desVal, setValue: setDesVal, error: desError, setError: setDesError, resetAll: desReset } = useInputHook();

    //temp values;
    const { value: tempNameVal, setValue: setTempNameVal, error: tempNameError, setError: setTempNameError, resetAll: resetTempName } = useInputHook();
    const { value: TempDesVal, setValue: setTempDesVal, error: TempDesError, setError: setTempDesError, resetAll: resetTempDes } = useInputHook();

    const { value: passwordVal, setValue: setPasswordVal, error: passwordError, setError: setPasswordError, resetAll: resetPass } = useInputHook();
    const { value: portVal, setValue: setPortVal, error: portError, setError: setPortError, resetAll: resetPort } = useInputHook();
    const { value: shhUserVal, setValue: setSshUserval, error: shhuserError, setError: setSshUserError, resetAll: resetSshUser } = useInputHook();
    const { value: shhIpVal, setValue: setSshIpval, error: shhIpError, setError: setSshIpError, resetAll: resetSshIp } = useInputHook();

    const [openOutputPage,setOutputPage,outputPageData,setOutputPageData] = useScriptOutPutPageHook();

    const [exeFormError, setExeFormError] = React.useState({
        isError: false,
        message: ''
    })

    const [tokenState, setTokenState] = React.useState({});

    const [autoCompleteMachineError, setAutoCompleteMachineError] = React.useState({
        isError: false,
        message: ''
    })

    //exec modal
    const [execModalShow, setExeModalShow] = React.useState(false);
    //exec modal loading
    const [execModalLoading, setExecModalLoading] = React.useState(false);

    //exec for saving
    const [execSaveLoading,setExecSaveLoading] = React.useState(false);

    //exec options
    const [tokenOptions, setTokenOptions] = React.useState({});

    // ref
    const execAbortRef = React.useRef(null);

    //edit page table
    const [innerList, setInnerList] = React.useState([]);


    // to render options
    const [selectMachineTag, setSelectMachineTag] = React.useState([]);
    // to select value
    const [machinetagState, setMachineTagState] = React.useState(null);
    const [machineStateError, setMachineStateError] = React.useState({
        isError: false,
        message: ''
    });

    const [execMode, setExecMode] = React.useState(false);



    React.useEffect(function () {

        if (!window.location.pathname.includes('create')) {

            getScriptList();
        }

    }, [window.location.pathname]);

    function resetCreateInputs() {
        desReset();
        nameReset();
        editorReset();
        setDefaultValue(null);
    }

    async function getScriptList() {
        setmainLoading(true);
        const res = await getScriptMarketService();
        if (res.data.result == '') return;

        const dataList = JSON.parse(res.data.result);
       // console.log(dataList, 'dataList');

        if (res.data.code != '0') {
            handlerAlert("danger", res.data.message, true);
            return;
        }

        setScriptList(dataList);
        setmainLoading(false);
    }


    function setEditScript(payload) {
        // setToggleEditor(true);
        setDefaultValue(payload);
    }

    function clearDefaultValue() {
        setDefaultValue(null);
    }


    function backBtn() {
        setToggleEditor(false)
        clearDefaultValue();
        getScriptList();
    }

    async function saveHandler(isCreate = false) {

        const isError = checkErrorForm(isCreate);
        if (isError) {
            return;
        }
        setLoadingCreate(true);
        const payload = {
            "script": editorVal,
            "scriptid": 0,
            "tokens": "",
            "machineid_machinename": "",
            "machineid": "0",
            "code": 0,
            "name": nameVal,
            "description": desVal,
            "parentid": 0
        }
        //    setLoadingCreate(false);
        
        const res = await callAPI(postscriptmarketService.bind(this, payload));

        setLoadingCreate(false);
        getScriptList();
        handlerAlert("success", "Script has been created");
        history.push('/myscripts/templates');

    }



    async function draftHandler(data) {
        console.log(data);

        const isError = checkErrorForm();
        if (isError) {
            return;
        }
        setLoadingCreate(true);
        const payload = {
            "script": editorVal,

            "name": nameVal,
            "description": desVal
        }

        const res = await postscriptmarketService({ ...defaultValues, ...payload });

        setLoadingCreate(false);
        console.log(res);
    }

    function handleCreateScript() {
        clearDefaultValue();
        setToggleEditor(true);

    }


    function handleCloseDeleteModal() {
        setDeleteModal(false);
        setCurrenrDelData(null);
    }

    function handleExecCloseModal() {
        setExecModalLoading(false);
        if (execAbortRef.current) {
            execAbortRef.current()
        }

        // execAbortRef.current.abort();
        setShowOutputInExecModal(false);
        seteditSaveAndExecOptions(false);
        setExeModalShow(false);
        if(timerRef.current){
            clearInterval(timerRef.current);
        }
    }

    async function handleOkayDelete() {
        setLoadingDelete(true);
        let res;
       
        try {
            res = await postscriptmarketService({ ...currentDelData, machineid_machinename: '', code: '1' });
            console.log(res);
            setDeleteModal(false);;
            setLoadingDelete(false);
            getScriptList();
            // if ((isFinite(window.location.pathname.split('/')[2]))) {
            //     history.push('/scripts');
            // }


        } catch (error) {
            setLoadingDelete(false);
            handlerAlert("danger", JSON.stringify(error), true);
        }

    }

    function handleDeleteOpen(data) {
        setDeleteModal(true);
        setCurrenrDelData(data);
    }

    //option delete
    function handlerOptionDelete() {
        console.log(defaultValues, "jkhasjdjkasjkds");
        handleDeleteOpen(defaultValues)
    }


    //to exec
    async function handlerExec() {
        const { scriptid } = defaultValues;
        try {
            const res = await postExecScriptService(scriptid, {
                "params": [],
                "sshpassword": "Bizgaze@123",
                "sshport": "22",
                "sshuser": "root",
                "sship": "192.168.89.103",
                "serverurl": `${SEVERNAME}`,
                "authorization": "stat c0ad5a88a3e64acabee898b3c7701c25"
            });
            console.log(res)
        } catch (error) {
            console.log(error);
        }
    }

    //to get scriptid 
    async function callDetailScript(id) {
        try {

            const res = await getDetailsScriptService(id);

            const isValid = checkResData(res);
            if (!isValid) {

            }

            let data = JSON.parse(res.data.result)[0];

            setDefaultValue(data);
            if (data.machineid) {
                setMachineTagState({
                    label: data.machinename,
                    value: data.machineid
                })
            }


        } catch (error) {
            console.log(error);
        }
    }

    //to check error
    function checkErrorForm(isCreate) {
        let isError = false;
        if (!editorVal) {
            setEditorError({
                isError: true,
                message: 'Script cannot be empty'
            });
            isError = true;

        }

        if (!nameVal) {
            setNameError({
                isError: true,
                message: 'Name cannot be empty'
            });
            isError = true;


        }

        // if (!desVal) {
        //     setDesError({
        //         isError: true,
        //         message: 'Description cannot be empty'
        //     });
        //     isError = true;

        // }

        // if(!isCreate && !machinetagState){
        //     setAutoCompleteMachineError({
        //         isError:true,
        //         message: 'Machine cannot be empty'
        //     });
        //     isError = true;
        // }

        return isError;
    }

    //save helper function 
    async function helperSaveOption(obj,isNew = false) {
        return new Promise(async function (resolve, reject) {
debugger;
            if (isNew) {
                const isError = checkErrorForm();
                if (isError) {
                    return;
                }
            }

            setOptionsLoading(true);
            const payload = {
                "script": editorVal,
                "name": `${tempNameVal ? tempNameVal : nameVal}`,
                "description": `${TempDesVal ? TempDesVal : desVal}`,
                // "scriptid": machinetagState?machinetagState.value:0,
                // "code": "0",
                // "statusid": 1,
                "machineid_machinename": `${machinetagState ? machinetagState.label : 0}`,
                "machineid": `${machinetagState ? machinetagState.value : 0}`,


            }

            let scriptIdNew = isNew ? 0 : defaultValues.scriptid;
            let parentid = scriptIdNew === 0 ? { 'parentid': defaultValues.scriptid } : {}

            let isTokens = Object.keys(obj || {}).length === 0 ? defaultValues.tokens : { ...obj };
            try {
                const res = await postscriptmarketService({ ...defaultValues, "machineid_machinename": defaultValues.machinename, ...payload, tokens: isTokens, scriptid: scriptIdNew, ...parentid });
                setOptionsLoading(false);
                return resolve({
                    error: false,
                    res: res
                })
            } catch (error) {
                setOptionsLoading(false);
                return resolve({
                    error: true,
                    res: error
                })
            }

        })
    }

    //option save
    async function optionSave() {

        setOptionsLoading(true);

        const { res, error } = await helperSaveOption();

debugger;
        const isvalid = checkResData(res);
        if (!isvalid) {
            setOptionsLoading(false);
            handlerAlert("danger", "Something went wrong", true);
            return;
        }
        handlerAlert("success", "Your Script as been saved");
        history.push('/myscripts/templates');
        setOptionsLoading(false);
    }


    //save and exec call modal
    function saveAndExec(val) {

debugger;
        // const isError = checkErrorForm();
        // if (isError) {
        //     return;
        // }

        getUserMachinesList();
        console.log(val);

        let tokenInitData = {}
        if (val.tokens) {
            tokenInitData = JSON.parse(val.tokens);

        }
        setInitDataTextArea(tokenInitData);

        const { script, name, description, scriptid } = val;
        setDesVal(description);
        setNameVal(name);
        setEditorVal(script);
        setDefaultValue(val);
        const tokenArr = getTokenFilter(script);



        let filterObj = {};
        if (typeof tokenArr === 'object' && typeof tokenInitData === 'object' && Object.keys(tokenInitData || {}).length && Object.keys(tokenInitData?.params || {}).length) {
            for (let token in tokenArr) {
                if (tokenInitData.params[token]) {
                    filterObj[token] = tokenInitData.params[token];
                }

            }
        }

        console.log(val.params, '1234');
        console.log(tokenArr, '56789');
        if (tokenArr) {
            debugger
            console.log(tokenOptions);
            setTokenOptions((prev) => {
                return { ...tokenArr, ...filterObj }
            });
        }

        setExeModalShow(true)

    }

    async function ScriptExec(val,isDisable=false) {

        if(isDisable){
            seteditSaveAndExecOptions(true);
        }else{
            seteditSaveAndExecOptions(false);
        }

        // const isError = checkErrorForm();
        // if (isError) {
        //     return;
        // }

        const res = await getDetailsScriptService(val.parentid);

        const isValid = checkResData(res);
        if (!isValid) {

        }

        let data = JSON.parse(res.data.result)[0];
        let parentScript = data.script;
        const parentScriptTokens = getTokenFilter(parentScript);
        console.log(parentScriptTokens);
        setExecMode(true);
        getUserMachinesList();
        console.log(val);

        let tokenInitData = {}
        if (val.tokens) {
            tokenInitData = JSON.parse(val.tokens);

        }
        setInitDataTextArea(tokenInitData);

        const { script, name, description, scriptid, machineid, machinename, tokens, publicip, refstatusid } = val;

        let isSafe = isJsonStringHelper(tokens);
        let parseToken = {};
        if (!isSafe) {
            handlerAlert("danger", "The SSh values are empty", "danger");
            return;
        }

        parseToken = JSON.parse(tokens);
        const { params, sshport, sshuser, sship, sshpassword } = parseToken;
        setEditorVal(script);
        setSshIpval(sship);
        setPortVal(sshport);
        setSshUserval(sshuser);
        // setPasswordVal(sshpassword)
debugger;
        setTempNameVal(name);
        setTempDesVal(description);
        setMachineTagState({
            label: machinename,
            value: machineid,
            ip: publicip||sship,
            id: refstatusid
        });

        setEditorVal(data.script);
        setDefaultValue({
            ...val,
            script: data.script
        });
        const tokenArr = getTokenFilter(script);



        // let filterObj = {};
        let hashObj = {};
        let finalObj = {}
        if (tokenInitData.params) {
            for (let i = 0; i < tokenInitData.params.length; i++) {
                let { key, value } = tokenInitData.params[i];
                hashObj[key] = value;
            }


            // for(let token in tokenArr){
            //     let curr = tokenArr[token];
            //     tokenArr[token] = {
            //         ...curr,
            //         val:hashObj[tokenArr[token].name]
            //     }
            // }

            for (let token in parentScriptTokens) {
                let curr = tokenArr[token];
                if (curr) {
                    finalObj[token] = {
                        ...curr,
                        val: hashObj[tokenArr[token].name]
                    }
                } else {
                    finalObj[token] = parentScriptTokens[token];
                }
            }
        }



        // if (typeof tokenArr === 'object' && typeof tokenInitData === 'object' && Object.keys(tokenInitData || {}).length && Object.keys(tokenInitData?.params || {}).length) {
        //     for (let token in tokenArr) {
        //         if (hashObj[tokenArr[token].name]) {
        //             filterObj[token] = tokenInitData.params[token];
        //         }

        //     }
        // }

        console.log(val.params, '1234');
        console.log(tokenArr, '56789');
        if (tokenArr) {

            console.log(tokenOptions);
            setTokenOptions((prev) => {
                return { ...parentScriptTokens, ...finalObj }
            });
        }

        setExeModalShow(true)

    }

    function cleanToken(tokens) {
        let params = [];
        for (let token in tokens) {
            let item = tokens[token];
            let obj = {
                key: item.name,
                value: item.val
            }
            params.push(obj);
        }
        return params;
    }

    async function getOutputById(id, isBool = null) {
    
        let res = await getScriptOutputByIdService(id);
        // console.log(res.data);
        if(!res) return;
        if(!res.data) return; 
        res = JSON.parse(res.data.result);

        if(!res) return;
       
        
        console.log("logging");
        console.log(res);

        let resultOutput = '';
        for(let i=0;i<res.length;i++){
            let {output} = res[i];
            let isSafe = isJsonStringHelper(output);
            
            if(isSafe){
                output = JSON.parse(output);
                resultOutput += output.join('')
                // for(let j=0;j<output.length;j++){
                //     resultOutput+=output[j];
                // }
            }else{
                resultOutput += output;
            }
            
        }
        setShowOutputInExecModal(true);
       if(res.length){
        let completObjMachine = res[0];
        completObjMachine.output = resultOutput;

        setOutputView(res[0]);
       }else{
        setOutputView('');
       }
 
//         if(res.output && typeof(res.output)=="string"){
//             setShowOutputInExecModal(true);
//             if(!timerRef.current){
//                 // setOutputView(res);
//             }else{
//                 // res.output = `${res.output}\n Execting...`
//                 let temp = JSON.parse(res.output)
//                 temp.push('\n Execting....')
//                 res.output = JSON.stringify(temp);
                
//             }
            
//             //setCompleteOutput((prev) =>prev+)
//             // setOutputView(str => str+res);
// debugger
            
//         }
        

    }


    //api call abort
    async function postExecScriptFun(scriptId, data,uidcode) {

        let { userId, sessionId } = getSessionAndUserId();

        let CancelToken = axios.CancelToken;

        let config = {
            method: 'post',
            url: postExecScriptURL(scriptId),
            headers: {
                'Authorization': `Basic ${sessionId}`,
            },
            data: {
                ...data, 'authorization': `Basic ${sessionId}`
            },
            cancelToken: new CancelToken(function executor(c) {
                execAbortRef.current = c;
            }),
            timeout: 86400000 //1day
            //cancelToken: execAbortRef.current.source.token
        };

debugger;


        let id = defaultValues.scriptId
        let response;

        try {

          
            // setTimerState(true);
            if(timerRef.current){
                clearInterval(timerRef.current);
            }
            timerRef.current = setInterval(function (){
                getOutputById(uidcode);
            },3000);
           try{
            response = await axios(config);
           }catch(err){
            clearInterval(timerRef.current);
            setShowOutputInExecModal(false);
            setExecModalLoading(false);
            setExeFormError({
                isError: true,
                message: err.message
            })
            return;
           }
            clearInterval(timerRef.current);
            timerRef.current = null;
            // setTimerState(false);
            debugger;
            getOutputById(uidcode)
            // getOutputById(uidcode);
           
            if (response.data.code === 504) {
                setShowOutputInExecModal(false);
                setExecModalLoading(false);
                setOutputView(false);
                setExeFormError({
                    isError: true,
                    message: 'Unable to connect server. Please check your ssh details and try again.'
                })
                return;
            }
        } catch (error) {
            
            setShowOutputInExecModal(false)
            // setExecModalLoading(false);
            setExeModalShow(false);
            setOutputView(false);
            // setTimerState(false);
            if(axios.isCancel(error)){

                return;
            }
            handlerAlert("danger", error?.message, "danger");
            getScriptList();
            return;
        }

        if (response?.data?.code === "417" && response?.data?.message === "User Authentication Failed") { dispatch(openAuthModal()); }

        setExecModalLoading(false);

        const { code, message } = response?.data;
        console.log(data,'dasd',message,"dasdasdasdasdas")
        if (code != '200') {
            setShowOutputInExecModal(false);
            setExeModalShow(false);
            setOutputView(false);
            // getScriptInnerList(id);
            handlerAlert("danger", message, "danger");
            getScriptList();
            return;
        }
        // to close modal
        // setExeModalShow(false);



        // to timer off
        // setTimerState(false);

        getScriptList();
        //getOutputData();


        //     getScriptInnerList(id);
        //  callDetailScript(id)

    }

    async function getScriptInnerList(id) {
        const res = await getScriptTableService(id);
        const dataRes = JSON.parse(res.data.result);
        console.log(dataRes);
        setInnerList(dataRes);
    }


    async function getUserMachinesList() {

        const res = await getUserAllMachinesFirewallService();
        const isValid = checkResData(res);
        if (!isValid) {
            //alert("something went wrong");
            console.log(res);
            return;
        }

        const isSafe = isJsonStringHelper(res.data.result);
        if (!isSafe) {
            handlerAlert("danger", "Something Went Wrong", "danger");
            return;
        }

        const resJson = JSON.parse(res.data.result);
        console.log(resJson, 'resJsonresJsonresJsonresJson');
        let newArr = [];
        for (let i = 0; i < resJson.length; i++) {
            const { refstatusid, publicip } = resJson[i];

            let val = {
                value: resJson[i]?.machineid,
                label: resJson[i]?.machinename,
                id: refstatusid,
                ip: publicip
            }

            newArr.push(val);




        }

        setSelectMachineTag(newArr)



    }

    //save for execmodal

    async function saveExecModal({ tokenState, sshpassword, sshport, sshuser, sship, scriptid,enableCronJob }, isNew = false){
       
        const tokensArr = cleanToken(tokenState);

        let cronValueCheck = customCronValue?customCronValue:cronvalue?.value;

        // if(!isSafeCronTosave(cronValueCheck)){
        //     handlerAlert("danger", "Something went wrong", true);
        //     setExecSaveLoading(false);
        // }
      
        let newarr = enableCronJob ? [{key:'_cronstatus',value:enableCronJob?1:0},{key:'_crontime',value:cronValueCheck}]: [];
        const { res, error } = await helperSaveOption({
            "params": [...tokensArr,...newarr],
            sshpassword,
            sshport,
            sshuser,
            sship,
            "serverurl": `${SEVERNAME}`,
            
        },isNew);

        const isvalid = checkResData(res);
        if (!isvalid) {
            handlerAlert("danger", "Something went wrong", true);
            setExecSaveLoading(false);
            return;
        }

       
        const uid = uuid();
        settokenoutputid(uid);
        let execPayload =  {
            "params": [...tokensArr, {cronstatus:enableCronJob?1:0},{crontime:customCronValue?customCronValue:cronvalue?.value}],
            sshpassword,
            sshport,
            sshuser,
            sship,
            "serverurl": `${SEVERNAME}`,
            logid: uid,
            cronstatus:enableCronJob?1:0,
            crontime:customCronValue?customCronValue:cronvalue?.value
        };

       try{
        // if(enableCronJob){
            debugger;

            if(!isNew || enableCronJob){
                const cronRes = await postSaveCronJobService(res.data.result,execPayload);
                if(cronRes.data.code != '0' && cronRes.data.code != '200'){
                    setExeModalShow(false);
                    setExecSaveLoading(false);
                    handlerAlert("danger",cronRes.data.message,"danger");
                }
                
                console.log(cronRes);
            }
           
        // }
       }catch(err){
        setExecSaveLoading(false);
        handlerAlert("danger","Something went wrong","danger");
       }
       if(window.location.href.includes('/myscripts/scripts/template/') && window.location.pathname.split('/').length === 7){
        callScriptsAgain();
       }
      
       handlerAlert("success",`Cron job ${enableCronJob?'enabled':'disabled'} successfully!`)
       setExeModalShow(false);
    //    getScriptList();
    //    getOutputData();

        setExecSaveLoading(false);
    }

    //save and exec
    async function handleExecAndSave({ tokenState, sshpassword, sshport, sshuser, sship, scriptid,enableCronJob }, isNew = false) {
   debugger;
        setExecModalLoading(true);
        const tokensArr = cleanToken(tokenState);
        let shouldAddCron = enableCronJob ? [...tokensArr,{key:'_cronstatus',value:enableCronJob?1:0},{key:'_crontime',value:customCronValue?customCronValue:cronvalue?.value}] : tokensArr;
        const { res, error } = await helperSaveOption({
            "params":shouldAddCron,
            sshpassword,
            sshport,
            sshuser,
            sship,
            "serverurl": `${SEVERNAME}`,
            
        },isNew);


        const isvalid = checkResData(res);
        if (!isvalid) {
            handlerAlert("danger", "Something went wrong", true);
            setExecModalLoading(false);
            return;
        }

        // setInitDataTextArea({
        //     "params": tokensArr,
        //     sshpassword,
        //     sshport,
        //     sshuser,
        //     sship,
        //     "serverurl": `${SEVERNAME}`,
        // })
        const uid = uuid();
        settokenoutputid(uid);
        debugger;
        // let isEnable = {}
        // if(enableCronJob){
        //     isEnable['cronstatus'] = 1
        //     isEnable['crontime'] = customCronValue?customCronValue:cronvalue?.value
        // }

        let execPayload =  {
            "params": [...tokensArr, {cronstatus:enableCronJob?1:0},{crontime:customCronValue?customCronValue:cronvalue?.value}],
            sshpassword,
            sshport,
            sshuser,
            sship,
            "serverurl": `${SEVERNAME}`,
            logid: uid,
            cronstatus:enableCronJob?1:0,
            crontime:customCronValue?customCronValue:cronvalue?.value
        };
        console.log(execPayload);
       
        try {
            // if(enableCronJob){
                if(!isNew || enableCronJob){
                    const cronCheck =   await postSaveCronJobService(res.data.result,execPayload);
                    if(cronCheck.data.code != '0' && cronCheck.data.code != '200'){
                        setExecModalLoading(false);
                        setSaveAndExecError({
                            isError:true,
                            message:cronCheck.data.message 
                        })
                      handlerAlert("danger","Something went wrong","danger");
                      return;
                    }
      
                    handlerAlert("success",`Cron job ${enableCronJob ? 'enabled':'disabled'} successfully!`);
                    setExecModalLoading(false);
                }
             
            // }
          //  await postSaveCronJobService(res.data.result,execPayload);
            await postExecScriptFun(res.data.result,execPayload,uid);
        } catch (error) {
            handlerAlert("danger", "Something went wrong", true);
            setExecModalLoading(false);
            return;
        }


    }

    function onAutoMachineTagChange(e) {
        console.log(e);
        setMachineTagState(e);
        if (autoCompleteMachineError.isError) {
            setAutoCompleteMachineError({
                isError: false,
                message: ''
            })
        }
    }

    async function getOutputData() {
        const res = await getScriptTableService();
        const dataRes = JSON.parse(res.data.result);
        console.log(dataRes);
        setOutputList(dataRes)
    }

    async function callScriptsAgain(){
        const res = await getScriptsByParentIdService(window.location.pathname.split('/')[4]);
        if(!res.data.result) return;

        let parseDate = JSON.parse(res.data.result);
        console.log(parseDate,"dsskadsjk");
        setDataList(parseDate)
    }


    return (

        <ScriptMarketContext.Provider
            value={{
                tokenState, setTokenState,
                passwordVal, setPasswordVal, passwordError, setPasswordError, resetPass,

                portVal, setPortVal, portError, setPortError, resetPort,

                shhUserVal, setSshUserval, shhuserError, setSshUserError, resetSshUser,
                exeFormError, setExeFormError,
                shhIpVal, setSshIpval, shhIpError, setSshIpError, resetSshIp,
                ScriptExec,
                resetTempDes, resetTempName,
                tempNameError, setTempNameError,
                setTempDesError, TempDesError,
                resetCreateInputs, optionsLoading,
                machinetagState, setMachineTagState,
                machineStateError, setMachineStateError,
                onAutoMachineTagChange,
                selectMachineTag,
                getUserMachinesList,
                initDataTextArea, setInitDataTextArea,
                scriptsList,
                toggleEditor, setToggleEditor,
                setEditScript,
                defaultValues,
                clearDefaultValue,
                backBtn,
                saveHandler,
                editorVal, setEditorVal,
                nameVal, setNameVal,
                desVal, setDesVal,
                handleCreateScript,
                loadingCreate,
                desError,
                nameError,
                editorError,
                setDesError,
                setNameError,
                setEditorError,
                deleteModal, setDeleteModal,
                handleCloseDeleteModal,
                handleOkayDelete, handleDeleteOpen,
                loadingDelete, setLoadingDelete,
                handlerExec,
                draftHandler,
                callDetailScript,
                setDefaultValue,
                handlerOptionDelete,
                optionSave, execModalShow, setExeModalShow, handleExecCloseModal,
                handleExecAndSave,
                saveAndExec,
                execModalLoading, setExecModalLoading,
                tokenOptions, setTokenOptions,
                execAbortRef, mainLoading,
                getScriptList,
                innerList, setInnerList,
                getScriptInnerList,
                autoCompleteMachineError, setAutoCompleteMachineError,
                setTempNameVal, tempNameVal,
                TempDesVal, setTempDesVal,
                outputList, setOutputList, getOutputData,
                execMode, setExecMode,
                showoutputInExecModal, setShowOutputInExecModal,
                tokenoutputid, settokenoutputid,
                timerState, setTimerState,
                outputView,setOutputView,
                editSaveAndExecOptions,seteditSaveAndExecOptions,
                cronjobsList,setCronJobsList,cronvalue,setCronValue,
                customCronValue,setCustomCronValue,
                enableCronJob, setEnableCronJob,
                saveExecModal,
                execSaveLoading,setExecSaveLoading,
                dataList, setDataList,
                openOutputPage,setOutputPage,outputPageData,setOutputPageData,
                completeOutput,setCompleteOutput,
                saveAndExecError,setSaveAndExecError
            }}
        >
            {children}
        </ScriptMarketContext.Provider>
    );
}



export default withRouter(ScriptMaketContextMain);
