import React from 'react'
import { getBareMetalServersTenureService } from '../../../../../services/BareMetalServices/BareMetalFormService';
import { checkResData } from '../../../../../utils/Helpers';
import HeadingHS from '../../../hireserver/controls/HeadingHS';
import NoDataCard from '../../../hireserver/SharedComponents/NoDataCard';
import SpacerHS_TWO from '../../../hireserver/Spaces/SpacerHS_TWO';
import TenureCard from '../../Instances/components/Tenure/TenureCard'

function TenureBareMetal({ selectServer, tenure, setTenure, blocksInput, setSummaryPrice, summaryPrice }) {
    const [isEmpty, setIsEmpty] = React.useState(false);
    const [tenureInner, setTenureInner] = React.useState([]);

    const [blocks, setBlocks] = React.useState(1);


    // React.useEffect(function () {
    //     if (blocksInput === 0 || blocksInput === undefined || blocksInput === null || blocksInput === '') {
    //         setBlocks(1);
    //     } else {
    //         setBlocks(blocksInput);
    //     }
    //     if (tenureInner) {


    //         let currPrice = 0;

    //         for (let i = 0; i < tenureInner.length; i++) {
    //             let currItem = tenureInner[i];
    //             if (currItem['tenuretypename'] == summaryPrice.tenure) {
    //                 currPrice = currItem['price'];
    //             }
    //         }


    //         setSummaryPrice({
    //             price: currPrice * blocksInput,
    //             tenure: summaryPrice.tenure
    //         })
    //     }


    // }, [blocksInput]);

    React.useEffect(function () {
       
        if (selectServer?.value != null) {
            getTenurePrice(selectServer?.value);
        }

    }, [selectServer]);

    async function getTenurePrice(id) {
       
        const res = await getBareMetalServersTenureService(id);
        const isValid = checkResData(res);
        if (!isValid) {
            return;
        }

        const resData = JSON.parse(res.data.result);
      

        setTenureInner(resData)
        if(resData.length === 0){
            setIsEmpty(true);
        }else{
            setIsEmpty(false);
        }

    }

    // React.useEffect(function () {
    //     getTenures();
    // }, [tenureInner]);

    // React.useEffect(function (){
    //     getTenures();
    // },[tenure]);

    React.useEffect(function (){
  
        if(tenureInner){
          setSummaryPrice({
            price:tenureInner[0]?.price,
            tenure:tenureInner[0]?.tenuretypename
        })
        }

        setTenure(tenureInner[0]?.tenuretypeid)
    },[tenureInner])

    function getTenures() {
       
        return tenureInner.map(function (el, i) {


            let month = el.tenuretypename;


            // let days = el.noofdays;
            // let monthOrYearly = el.tenuretypename;
            // let month = 0;
            let isChecked = false;
            let isOffer = false;
            // if(monthOrYearly === 'Monthly'){
            //   month = Math.ceil(days/30);
            // }else{
            //   month = Math.floor(days/30);
            // }

            if (tenure == el.tenuretypeid) {
                isChecked = true
           
                
            }

            if (i == 1) {
                isOffer = true;
            }
            
            // return <TenureCard  isChecked={isChecked} id={el?.tenuretypeid} price={blocks<=0?Number(el.price):blocks * Number(el.price)} offer={isOffer} month={month} setTenure={setTenure}  />
            return <TenureCard setSummaryPrice={setSummaryPrice} isChecked={isChecked} id={el?.tenuretypeid} price={blocks <= 0 ? Number(el.price) : blocks * Number(el.price)} offer={isOffer} month={month} setTenure={setTenure} />
        })
    }

    return (
        <div>
            <div>
                <HeadingHS title={'Tenure'} as="h3 text-primary" />
            </div>
            <SpacerHS_TWO />
            {
                isEmpty ? <NoDataCard title="No Tenures" /> : <div className='d-flex d-gap-10 flex-wrap tenureListContainer'>

                    {
                        getTenures()
                    }
                </div>
            }
        </div>
    )
}

export default TenureBareMetal