import React from 'react'
import ModalHs from '../../../../../hireserver/ModalHs'
import { CRON_ENABLE } from '../../../../../../../utils/Emuns';
import { postSaveCronJobService } from '../../../../../../../services/InstancesServices/InstancesListService';
import { postscriptmarketService } from '../../../../../../../services/InstancesServices/InstanceCurrListNewDropdownService';
import { handlerAlert } from '../../../../Instances/InstancesListComponents/DropDownInstancesList';
import uuid from 'react-uuid';

function ConfirmCron({show,setModel,confirmCronData,closeHandler,callParentList}) {
    const [loading,setLoading] = React.useState(false);
    const [isOpen,setIsOpen] = React.useState(null);

    React.useEffect(function (){
        console.log(confirmCronData);
        if(Object.keys(confirmCronData).length){
            const {fullItemDate} = confirmCronData;
            let {tokens} = fullItemDate;
            if(tokens){
                debugger;
                tokens = JSON.parse(tokens);
         
                for(let i=0;i<tokens?.params.length;i++){
                    const {key,value} = tokens?.params[i];
                    if(key == CRON_ENABLE){
                        if(value == 0){
                            setIsOpen(false);
                        }else{
                            setIsOpen(true);
                        }

                        break;
                    }
                }
               
            }
        }
    },[confirmCronData]);

    function findCronTime(arr){
        debugger;
        for(let i=0;i<arr.length;i++){
            let {key,value} = arr[i];
            if(key == "_crontime"){
                return value;
            }
        }

        return '';
    }

    async function toggleCronHandler() {
        // setEnableCronJob(true);
        // return;
        debugger;
        const {fullItemDate,enableCronJob} = confirmCronData;
    
        setLoading(true);
        let isEnableToggle = !enableCronJob;
        console.log(isEnableToggle);

        const { tokens } = fullItemDate;

        if (!tokens) {
            setLoading(false)
            return;
        };

        fullItemDate.tokens = typeof (tokens) === 'string' ? JSON.parse(tokens) : tokens;
        let params = fullItemDate.tokens['params'];
        console.log(params);
        let newParmas = [];
        for (let i = 0; i < params.length; i++) {
            const { key, value } = params[i];
            if (key != CRON_ENABLE) {
                newParmas.push(params[i]);
            }
        }
        if (isEnableToggle) {
            newParmas.push({
                "key": "_cronstatus",
                "value": 1
            });
            fullItemDate['tokens']['params'] = newParmas;
            console.log(fullItemDate);
            debugger;
            try {

                // const res = await postscriptmarketService(fullItemDate);
                const cronres = await postSaveCronJobService(fullItemDate.scriptid, {...fullItemDate.tokens,logid:uuid(),"cronstatus": 1,"crontime": findCronTime(fullItemDate.tokens.params)});


                if ((cronres.data.message == 'Data saved successfully.' || cronres.data.message == 'Cron job operation completed successfully!') && (cronres.data.code == '0' || cronres.data.code == '200')) {
                    handlerAlert("success", "Cron Job Enable");
                    const res = await postscriptmarketService(fullItemDate);
                    callParentList();
                    setLoading(false)
                    closeHandler();
                    
                   // setEnableCronJob(true);
                } else {
                    // newParmas.pop();
                    // newParmas.push({
                    //     "key": "_cronstatus",
                    //     "value": 0
                    // });
                    // fullItemDate['tokens']['params'] = newParmas;
                    //  const res = await postscriptmarketService(fullItemDate);
                    handlerAlert("danger", "Something went wrong", "danger");
                 //   setEnableCronJob(false);
                }
            } catch (err) {
                //setEnableCronJob(false);
                handlerAlert("danger", "Something went wrong", "danger");
            } finally {
                setLoading(false)
            }

        } else {
            // newParmas.push({
            //     "key": "_cronstatus",
            //     "value": 0
            // });
            fullItemDate['tokens']['params'] = newParmas;
            console.log(fullItemDate); debugger;
            try {

                // console.log(res);
                // const res = await postscriptmarketService(fullItemDate);
                const cronres = await postSaveCronJobService(fullItemDate.scriptid, {...fullItemDate.tokens,logid:uuid(),"cronstatus": 0,"crontime": findCronTime(fullItemDate.tokens.params)});
                if ((cronres.data.message == 'Data saved successfully.'  || cronres.data.message == 'Cron job operation completed successfully!' )&& (cronres.data.code == '0'||cronres.data.code == '200')) {
                    handlerAlert("success", "Cron Job Disabled");
                    const res = await postscriptmarketService(fullItemDate);
                    callParentList();
                    setLoading(false)
                    closeHandler();
                   // setEnableCronJob(false);
                } else {
                    //     newParmas.pop();
                    //     console.log(cronres);
                    //     newParmas.push({
                    //         "key": "_cronstatus",
                    //         "value": 1
                    //     });
                    //     fullItemDate['tokens']['params'] = newParmas;
                    //    const res = await postscriptmarketService(fullItemDate);

                   // setEnableCronJob(true)
                    handlerAlert("danger", "Something went wrong", "danger");
                }
            } catch (err) {
               // setEnableCronJob(true)
                handlerAlert("danger", "Something went wrong", "danger");
            } finally {

                setLoading(false)
            }

            //console.log(fullItemDate);
        }
    }

  return (
   <ModalHs isNeed={false}   showModalHS={show}>
   <div className='w-100 '>
   <div>
    <div>
        <span>Machine Name :</span>
        <span>{confirmCronData?.fullItemDate
?.machinename}</span>
    </div>
    <div>
        <span>Script Name :</span>
        <span>{confirmCronData?.fullItemDate?.name}</span>
    </div>
        Are you sure you to {isOpen?'Disable':'Enable'}? 
    </div>
   <div className='d-flex align-items-end justify-content-end d-gap-10'>
    <button onClick={closeHandler} className='btn btn-danger px-3 py-2 hs_borderRadius-4'>
        Close
    </button>
    <button className='btn cursor-pointer hs_borderRadius-4 px-3 py-2 hs_borderRadius-4 hsButton-bg text-white w-100p' onClick={toggleCronHandler}>
        
        {loading ? <React.Fragment>
                      <div className="spinner-border text-light" role="status">

                      </div> 
                    </React.Fragment> : 'Confirm'}
    </button>
  
   </div>
   </div>
   </ModalHs>
  )
}

export default ConfirmCron